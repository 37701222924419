import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { _peopleClients } from '@timed/app';
import { ClientList } from '@timed/client';
import { Page } from '@timed/page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gap: theme.spacing(4),
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
      },
    },
  }),
);

const PeopleParticipants = () => {
  const classes = useStyles();

  return (
    <Page {..._peopleClients}>
      <Box className={classes.container}>
        <ClientList />
      </Box>
    </Page>
  );
};

export default PeopleParticipants;
