import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Checkbox } from '@timed/common';
import { Control } from 'react-hook-form';

type MemberAdminInputProps = {
  admin: boolean;
  control: Control<any>;
  disabled: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8),
      },
    },
    wrapper: {
      display: 'inline-grid',
      gridTemplateColumns: 'max-content auto',
      overflow: 'hidden',
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
        marginRight: theme.spacing(8),
      },
    },
  }),
);

const MemberAdminInput = ({ admin, control, disabled }: MemberAdminInputProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box>
        <Typography>Administrator</Typography>
        <Checkbox
          defaultChecked={admin}
          name="admin"
          control={control}
          disabled={disabled}
        ></Checkbox>
      </Box>
      <Typography>
        User inherits all permission levels and is able to:
        <br />
        &#8226; Assign permissions to other users.
        <br />
        &#8226; Add and remove administrators.
        <br />
        &#8226; Archive and delete the organisation.
        <br />
        &#8226; Archive and delete branches.
        <br />
        &#8226; View historical entity data.
        <br />
        &#8226; Read employee admin-only notes.
        <br />
        <br />
        Administrators cannot remove themselves.
      </Typography>
    </Box>
  );
};

export default MemberAdminInput;
