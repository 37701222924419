import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Avatar, formatPersonName, useRouter } from '@timed/common';
import { Permission } from '@timed/gql';
import { _peopleSupportCoordinators, _peopleSupportCoordinatorsProfile } from '@timed/app';
import { useAuth } from '@timed/auth';
import { Page } from '@timed/page';
import {
  SupportCoordinatorViewCloseButton,
  SupportCoordinatorContext,
} from '@timed/support-coordinator';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

type TabItem = {
  label: string;
  path: string;
  admin?: boolean;
  permissions?: Permission | Permission[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      flex: '0 1 auto',
      display: 'grid',
      gap: theme.spacing(4),
    },
    card: {
      display: 'grid',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
        gridTemplateColumns: 'max-content auto max-content',
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
        gridTemplateColumns: 'max-content auto max-content',
      },
    },
    avatar: {
      width: theme.spacing(16),
      height: theme.spacing(16),
      fontSize: '175%',
      lineHeight: theme.spacing(8) + 'px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
    },
    typography: {
      lineHeight: '32px',
    },

    tabs: {
      color: theme.palette.text.primary,
      '& .MuiTabs-flexContainer': {
        display: 'inline-grid',
        gridAutoFlow: 'column',
        [theme.breakpoints.down('md')]: {
          gap: theme.spacing(4),
        },
        [theme.breakpoints.up('md')]: {
          gap: theme.spacing(8),
        },
      },
    },
    tab: {
      textTransform: 'none',
      minWidth: 'inherit',
      '&.MuiTab-root': {
        padding: 0,
      },
      '& span': {
        padding: theme.spacing(1, 0),
      },
    },
    indicator: {
      height: theme.spacing(1),
    },
    section: {
      flex: '1 1 auto',
      display: 'grid',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        overflowY: 'auto',
        marginTop: theme.spacing(8),
        gap: theme.spacing(8),
      },
    },
  }),
);

const PeopleSupportCoordinatorsProfile = () => {
  const classes = useStyles();
  const { pathname, navigate } = useRouter();
  const { permissible } = useAuth();

  const supportCoordinator = useContext(SupportCoordinatorContext);

  const theme = useTheme();

  const currentTab = pathname.split('/').pop();

  const menuItems: TabItem[] = [
    {
      label: 'Overview',
      path: 'overview',
    },
    {
      label: 'Notes',
      path: 'notes',
    },
    {
      label: 'Participants',
      path: 'participants',
    },
  ];

  const handleClick = (link: string) => (_: React.KeyboardEvent | React.MouseEvent) =>
    link !== currentTab && navigate(link);

  const navigation: JSX.Element = false ? (
    <></>
  ) : (
    <Tabs
      value={currentTab}
      aria-label="Profile nav bar"
      className={classes.tabs}
      TabIndicatorProps={{ className: classes.indicator }}
    >
      {menuItems.map(
        (tab, index) =>
          permissible({ admin: tab.admin, permissions: tab.permissions }) && (
            <Tab
              disableRipple
              key={index}
              label={tab.label}
              className={classes.tab}
              value={tab.path}
              onClick={handleClick(tab.path)}
            />
          ),
      )}
    </Tabs>
  );

  return (
    <Page
      {..._peopleSupportCoordinatorsProfile}
      label={
        formatPersonName(supportCoordinator, { capitaliseLastName: true, lastNameFirst: true })!
      }
      path={`/people/support-coordinators/${supportCoordinator.id}`}
      redirect={`/people/support-coordinators/${supportCoordinator.id}/overview`}
      crumbs={{ before: [_peopleSupportCoordinators] }}
    >
      <Box className={classes.top}>
        <Box className={classes.card}>
          <Avatar
            content={[supportCoordinator.firstName, supportCoordinator.lastName]}
            variant="circular"
            color={theme.palette.type === 'light' ? '#fff' : theme.palette.background.paper}
            textColor={theme.palette.text.primary}
            className={classes.avatar}
          />
          <Box>
            <Typography variant="body1" color="textPrimary" className={classes.typography}>
              {formatPersonName(supportCoordinator, {
                middle: true,
                capitaliseLastName: true,
                lastNameFirst: true,
              })}
            </Typography>
            <Typography variant="body1" color="textSecondary" className={classes.typography}>
              Support Coordinator
            </Typography>
          </Box>
          <Box>
            <SupportCoordinatorViewCloseButton />
          </Box>
        </Box>
        {navigation}
      </Box>
      <Divider />
      <Box className={classes.section}>
        <Outlet />
      </Box>
    </Page>
  );
};

export default PeopleSupportCoordinatorsProfile;
