import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { OverridePermissionsFormModal } from '@timed/auth';
import { Protected } from '@timed/auth/components/Protected';
import { useAuth } from '@timed/auth/hooks';
import { Button } from '@timed/common';
import { useModal } from 'mui-modal-provider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'fixed',
      right: theme.spacing(26),
      top: theme.spacing(2),
      color: theme.palette.text.secondary,
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      zIndex: 10000,
      [theme.breakpoints.up('md')]: {
        top: theme.spacing(4),
      },
    },
    button: {
      backgroundColor: '#fafafadd',
      border: '1px solid white',
      alignSelf: 'start',
      padding: theme.spacing(1),
      '& .MuiButton-label': {
        fontSize: 10,
        lineHeight: 1,
        width: theme.spacing(0),
      },
    },
  }),
);

const OverridePermissionsOpenModalButton = () => {
  const classes = useStyles();

  const { showModal } = useModal();

  const { member } = useAuth();

  const handleOpenModal = () => {
    const modal: { hide: () => void } = showModal(OverridePermissionsFormModal, {
      onClose: () => {
        modal.hide();
      },
    });
  };

  return (
    <Protected admin={!member?.admin}>
      <Box className={classes.wrapper}>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => {
            handleOpenModal();
          }}
        >
          Admin Override
        </Button>
      </Box>
    </Protected>
  );
};

export default OverridePermissionsOpenModalButton;
