import { Box, createStyles, makeStyles, Switch, Theme } from '@material-ui/core';
import { ThemeContext } from '@timed/theme';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'fixed',
      right: theme.spacing(4),
      bottom: theme.spacing(4),
      color: theme.palette.text.secondary,
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    icon: {
      [theme.breakpoints.down('md')]: {
        width: 24,
        height: 24,
      },
      [theme.breakpoints.up('md')]: {
        width: 32,
        height: 32,
      },
    },
  }),
);

const ThemeToggle = () => {
  const classes = useStyles();

  const { palette, setPalette } = useContext(ThemeContext);

  const toggleChecked = (_: React.ChangeEvent<HTMLInputElement>) => {
    setPalette(palette === 'dark' ? 'light' : 'dark');
  };

  return (
    <Box className={classes.wrapper}>
      <Switch size="small" checked={palette === 'dark'} onChange={toggleChecked} color="default" />
    </Box>
  );
};

export default ThemeToggle;
