import {
  Box,
  createStyles,
  FormControl,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Chip, ChipProps, formatPersonName, roundNumber, Select, SelectProps } from '@timed/common';
import { Client, OrderBy, useScheduleControlMembersInputLazyQuery } from '@timed/gql';
import { addDays, startOfWeek } from 'date-fns';
import { useEffect } from 'react';
import { UseFormWatch } from 'react-hook-form';

type EventMemberInputProps = Omit<SelectProps, 'items'> & {
  chipProps: ChipProps;
  watch: UseFormWatch<any>;
  clientId: Client['id'];
  startAt: Date;
  endAt: Date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 185,
      backgroundColor: 'white',
    },
    loading: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.background.default,
      },
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    item: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      gap: theme.spacing(0),
      '& .MuiTypography-root': {
        [theme.breakpoints.up('md')]: {
          lineHeight: 1.2,
        },
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      height: 18,
    },
    chip: {
      // marginLeft: theme.spacing(0.5),
      height: 20,
    },

    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },

    unavailable: {
      // color: theme.palette.error.main,
    },

    available: {
      color: 'green',
    },

    unavailableDays: {
      display: 'grid',
      gridTemplateColumns: '24px repeat(7, 8px)',
      columnGap: theme.spacing(2),
      '& .MuiTypography-root': {
        fontSize: 10.5,
        fontWeight: theme.typography.fontWeightLight,
      },
    },

    description: {
      '& .MuiTypography-root': {
        fontSize: 10.5,
      },
    },
  }),
);

function EventMemberInput({
  chipProps,
  type,
  watch,
  clientId,
  startAt,
  endAt,
  ...props
}: EventMemberInputProps) {
  const classes = useStyles();

  const watched = watch(props.name);

  const payrollPeriodStartAt = startOfWeek(startAt, { weekStartsOn: 1 });
  const payrollPeriodEndAt = addDays(payrollPeriodStartAt, 7);

  const [getMembers, { loading, data }] = useScheduleControlMembersInputLazyQuery({
    variables: {
      input: {
        where: { schedulable: { _eq: true } },
        orderBy: [{ lastName: OrderBy.ASC_NULLS_FIRST }],
      },

      scheduleInput: { startAt: payrollPeriodStartAt, endAt: payrollPeriodEndAt },

      eventsInput: {
        limit: 1,
        where: {
          startAt: { _lt: endAt },
          endAt: { _gt: startAt },
        },
      },

      teamsInput: {
        limit: 1,
        where: { client: { id: { _eq: clientId } } },
      },

      unavailablesInput: {
        limit: 1,
        where: {
          startAt: { _lt: endAt },
          endAt: { _gt: startAt },
        },
      },
    },
  });

  useEffect(() => {
    if (!data && !loading) {
      getMembers();
    }
  }, [getMembers, data, loading]);

  const profiles = data?.members
    // Filter out blocked employees
    .filter((m) => !m.teams?.length || (m.teams?.length && m.teams[0].preferred));

  const selected = data?.members?.find((m) => m.id === watched);

  const descriptions = profiles?.map((p) => {
    let color:
      | 'inherit'
      | 'initial'
      | 'primary'
      | 'secondary'
      | 'textPrimary'
      | 'textSecondary'
      | 'error'
      | undefined = 'textSecondary';

    let text = undefined;

    if (p.teams?.length && p.teams[0].preferred) text = 'Preferred team member';

    if (p.events?.length) {
      text =
        '- Scheduling conflict (' +
        formatPersonName(p.events[0].client, { capitaliseLastName: true, lastNameFirst: true }) +
        ')';
      color = 'error';
    }

    if (p.unavailables?.length) {
      text = '- Unavailable';
      color = 'error';
    }

    const hours = p.schedule?.timeScheduled! / 60;

    return {
      id: p.id,
      message:
        !text && hours === 0 ? null : (
          <>
            {text && (
              <Typography variant="body2" color={color}>
                {text}
              </Typography>
            )}
            {hours > 0 && (
              <Typography variant="body2" color="textSecondary">
                - {roundNumber(hours, 2) + ' hours scheduled this week'}
              </Typography>
            )}
          </>
        ),
    };
  });

  const availableChar = '☒'; //'🗹';
  const unavailableChar = '☐';

  const profileList = profiles
    ? profiles.map((p) => {
        return {
          value: p.id!,
          label: (
            <Box className={classes.item}>
              <Typography variant="body1">
                {formatPersonName(p, { capitaliseLastName: true, lastNameFirst: true })!}
                {p.preferredName !== p.firstName && (
                  <> ({formatPersonName(p, { preferred: true })})</>
                )}
              </Typography>
              {/* <Box className={classes.unavailableDays}>
                <Typography></Typography>
                <Typography>M</Typography>
                <Typography>T</Typography>
                <Typography>W</Typography>
                <Typography>T</Typography>
                <Typography>F</Typography>
                <Typography>S</Typography>
                <Typography>S</Typography>

                <Typography>Morn</Typography>
                <Typography
                  className={p.unavailableMoMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableMoMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableTuMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableTuMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableWeMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableWeMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableThMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableThMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableFrMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableFrMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableSaMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableSaMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableSuMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableSuMorn ? unavailableChar : availableChar}
                </Typography>

                <Typography>Day</Typography>
                <Typography
                  className={p.unavailableMoMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableMoMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableTuMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableTuMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableWeMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableWeMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableThMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableThMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableFrMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableFrMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableSaMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableSaMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableSuMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableSuMorn ? unavailableChar : availableChar}
                </Typography>

                <Typography>Night</Typography>
                <Typography
                  className={p.unavailableMoMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableMoMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableTuMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableTuMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableWeMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableWeMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableThMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableThMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableFrMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableFrMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableSaMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableSaMorn ? unavailableChar : availableChar}
                </Typography>
                <Typography
                  className={p.unavailableSuMorn ? classes.unavailable : classes.available}
                >
                  {p.unavailableSuMorn ? unavailableChar : availableChar}
                </Typography>
              </Box> */}
              <Typography className={classes.description}>
                {descriptions?.find((d) => d.id === p.id)?.message}
              </Typography>
            </Box>
          ),
        };
      })
    : undefined;

  return loading ? (
    <FormControl className={classes.loading}>
      <TextField
        disabled
        label={type}
        variant={props.variant || 'outlined'}
        size="small"
        value="Loading..."
        className={props.className}
      />
    </FormControl>
  ) : (
    <Select
      items={profileList}
      renderValue={() =>
        selected && (
          <div className={classes.chips}>
            <Chip
              size="small"
              color="primary"
              label={formatPersonName(selected!, { capitaliseLastName: true, lastNameFirst: true })}
              className={classes.chip}
              onMouseDown={(event: any) => event.stopPropagation()}
              {...chipProps}
            />
          </div>
        )
      }
      {...props}
    />
  );
}

export default EventMemberInput;
