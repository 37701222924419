import { useTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { CSSProperties } from '@material-ui/styles';
import { useAuth } from '@timed/auth';
import { contrastingColor, formatPersonName, hexToRGB, useRouter } from '@timed/common';
import {
  EntityState,
  Event,
  EventsWhereInput,
  GetEventsOwnAndRelatedQuery,
  GetEventsQuery,
  Member,
  OrderBy,
  Permission,
  useGetEventsLazyQuery,
  useGetEventsOwnAndRelatedLazyQuery,
  useGetScheduleOrgSettingsLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import {
  getProfile,
  MouseState,
  ScheduleContext,
  ScheduleContextType,
  SelectedEvent,
  SetCellFn,
  SetEventFn,
  setProfile,
  Shift,
} from '@timed/schedule';
import {
  addHours,
  addMinutes,
  addWeeks,
  differenceInDays,
  differenceInMilliseconds,
  differenceInMinutes,
  format,
  getHours,
  isAfter,
  isBefore,
  isEqual,
  parse,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import { addDays, startOfMinute } from 'date-fns/esm';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

/*

MOUSE ACTIONS
-------------

Drag over cells to create event:
  - onMouseDown: set datetime1 from 'hovered cell'
  - onMouseUp: set datetime2 from 'hovered cell'
  Needed: 2 states: cell1 and cell2

Drag events to change start time
  - onMouseDown
  - Get the distance between the click point and the top of the
    shift (not event). This distance is used to determine accurate
    'from' date.
  Needed: 2 states: top offset distance and the eventId

Drag event top and bottom borders to change from and to times:
 - Create two invisible elements, one at the top and one at the
   bottom of the shift.
 - If the top element is onMouseDown and onMouseLeave, the 'from'
   time is altered
 - If the bottom element is onMouseDown and onMouseLeave, the 'to'
   time is altered.
   Needed: 

Double click event to edit
  Needed: 1 state: eventId


*/

type MemberColors = {
  id: Member['id'];
  color: string;
};

// // Not yet in use
// type Query = {
//   /**
//    * 'From' date
//    */
//   f: string;
//   /**
//    * Date range
//    */
//   r: string;
//   /**
//    * Employee id
//    */
//   e: string;
//   /**
//    * Participant id
//    */
//   p: string;
// };

type ScheduleProviderProps = React.PropsWithChildren<{}>;

const ScheduleProvider = ({ children }: ScheduleProviderProps) => {
  const {
    search: [searchParams, setSearchParams],
  } = useRouter();

  const { permissible, member } = useAuth();

  const theme = useTheme();

  // const [getEvents, { data, loading }] = useGetEventsLazyQuery({
  const [getEvents, eventsResponse] = useGetEventsLazyQuery({
    // pollInterval: 30000,
    // fetchPolicy: 'network-only',
  });

  const [getEventsOwnAndRelated, eventsOwnAndRelatedResponse] = useGetEventsOwnAndRelatedLazyQuery({
    // pollInterval: 30000,
    // fetchPolicy: 'network-only',
  });

  const [getOrgSettings, orgSettings] = useGetScheduleOrgSettingsLazyQuery();

  const isEvent = (
    e: GetEventsQuery['events'][0] | GetEventsOwnAndRelatedQuery['eventsOwnAndRelated'][0],
  ): e is Event => e.hasOwnProperty('conflictExists');

  useLoadingEffect(eventsResponse.loading);
  useLoadingEffect(eventsOwnAndRelatedResponse.loading);

  const [eventStates, setEventStates] = useState<EntityState[]>([
    EntityState.NORMAL,
    EntityState.CANCELLED,
  ]);

  const [eventAttributes, setEventAttributes] = useState<EventsWhereInput>();

  const [mouse, setMouseState] = useState<MouseState>('up');

  const [cellDown, setCellDownState] = useState<number>();

  const [cellOver, setCellOverState] = useState<number>();

  const [eventOver, setEventOverState] = useState<Event['id']>();

  const [, setEventSelectedState] = useState<ScheduleContextType['target']['event']['selected']>();

  /**
   * The current datetime
   */
  const [now, setNow] = useState<Date>(new Date());

  /**
   * Timer to refresh 'now' indicator on calendar
   */
  const timer = useRef<number>();

  /**
   * Default quantity of days between 'from' and 'to' dates
   */
  const defaultDateRange = 28;

  /**
   * First day of week to display on the calendar
   */
  const weekStartsOn = 1; // Monday

  // const employee = searchParams.get("e") || undefined;
  // const participant = searchParams.get("p") || undefined;

  /**
   * Start of the current day.
   * Memoisation is requred to prevent infinite loops
   */
  const today = useMemo(() => startOfDay(now), [now]);

  /**
   * Quantity of days between and 'from' and 'to' dates
   */
  const range =
    (searchParams.get('r') && parseInt(searchParams.get('r')!)) ||
    (localStorage.getItem('schedule.range') && parseInt(localStorage.getItem('schedule.range')!)) ||
    defaultDateRange;

  /**
   * First date to display on the calendar.
   * Memoisation is requred to prevent infinite loops.
   */
  const from = useMemo(
    () =>
      (searchParams.get('f') && parse(searchParams.get('f')!, 'ddMMyyyy', new Date())) ||
      (range < 7 ? today : startOfWeek(today, { weekStartsOn })),

    [searchParams, today, weekStartsOn, range],
  );

  /**
   * Day after the last date displayed on the calendar.
   * Memoisation is requred to prevent infinite loops.
   */
  const to = useMemo(() => addDays(from, range), [from, range]);

  /**
   * Date at which the auto-member-assign task is running.
   */
  const autoMemberAssign = useMemo(
    () =>
      addWeeks(new Date(), orgSettings.data?.me.member?.org.assignMemberToEventWeeksInAdvance ?? 0),
    [orgSettings.data?.me.member?.org.assignMemberToEventWeeksInAdvance],
  );

  const refetch = useCallback(() => {
    !!eventsResponse.data?.events
      ? eventsResponse.refetch()
      : eventsOwnAndRelatedResponse.refetch();
  }, [eventsResponse, eventsOwnAndRelatedResponse]);

  /**
   * Set quantity of days between 'from' and 'to' dates
   */
  const setRange = useCallback(
    (value: number, redirect = true): void => {
      value && localStorage.setItem('schedule.range', value.toString());
      searchParams.set('r', value.toString());
      redirect && setSearchParams(searchParams);
      // refetch();
    },
    [searchParams, setSearchParams],
  );

  /**
   * Set first date to display on the calendar
   */
  const setFrom = useCallback(
    (value: number | Date, redirect = true): void => {
      searchParams.set(
        'f',
        format(typeof value === 'number' ? addDays(from, value) : value, 'ddMMyyyy'),
      );

      redirect && setSearchParams(searchParams);
      // refetch();
    },
    [from, searchParams, setSearchParams],
  );

  /**
   * Wrapper around helper getProfile() functions. Get saved id of specified member or client.
   */
  const getProfileWrapper = useCallback(
    (type: 'client' | 'member'): string | undefined => getProfile(type, searchParams),
    [searchParams],
  );

  const clientId = getProfileWrapper('client');

  // Set memberId to the current user's member id if they do not have permission to read other members
  const memberId = !permissible({ permissions: Permission.MEMBER_READ })
    ? member!.id
    : getProfileWrapper('member');

  /**
   * Wrapper around helper function "setProfile". Save profile id of the
   * specified profile type.
   * @param id If blank, unsets existing value
   * @param redirect If true, apply new value to search params immediately.
   */
  const setProfileWrapper = useCallback(
    (type: 'client' | 'member', id?: string, redirect = true) => {
      setProfile(type, searchParams, id);
      redirect && setSearchParams(searchParams);
      // refetch();
    },
    [searchParams, setSearchParams],
  );

  const setEventSelected = (event: ScheduleContextType['target']['event']['selected']) => {
    setEventSelectedState(event);
  };

  const setEvent = ({ type, value }: SetEventFn) => {
    switch (type) {
      case 'selected':
        setEventSelected(value as SelectedEvent);
        break;
      case 'over':
        value?.id ? setEventOverState(value.id) : setEventOverState(undefined);
        break;
    }
  };

  const setCell = (data?: SetCellFn) => {
    if (!data) {
      setCellDownState(undefined);
      setCellOverState(undefined);
    } else {
      switch (data.type) {
        case 'down':
          // setCellUpState(undefined);
          setCellDownState(data.value);
          setCellOverState(data.value);
          break;
        case 'over':
          setCellOverState(data.value);
          break;
      }
    }
  };

  /**
   * Redirect on missing query parameters
   */
  useEffect(() => {
    let redirect: boolean = false;

    if (!searchParams.get('f')) {
      setFrom(from, false);
      redirect = true;
    }

    if (!searchParams.get('r')) {
      setRange(range, false);
      redirect = true;
    }

    if (!searchParams.get('m') && getProfileWrapper('member')) {
      setProfileWrapper('member', memberId, false);
      redirect = true;
    }

    if (!searchParams.get('c') && getProfileWrapper('client')) {
      setProfileWrapper('client', clientId, false);
      redirect = true;
    }

    redirect && setSearchParams(searchParams);
  }, [
    from,
    range,
    getProfileWrapper,
    clientId,
    setFrom,
    setRange,
    setProfileWrapper,
    setSearchParams,
    searchParams,
    memberId,
  ]);

  /**
   * "Now" indicator refresh timer
   */
  useEffect(() => {
    // Calc the difference in time between the page render
    // and the start of the current minute so that the timer
    // refreshes on the minutes mark.
    const offset = differenceInMilliseconds(now, startOfMinute(now));
    timer.current = window.setTimeout(() => {
      setNow(new Date());
    }, 60000 - offset);
    return () => clearTimeout(timer.current);
  }, [now, setNow]);

  /**
   * Fetch org settings.
   */
  useEffect(() => {
    if (permissible({ permissions: Permission.EVENT_READ })) {
      getOrgSettings();
    }
  }, [permissible, getOrgSettings]);

  /**
   * Fetch events
   */
  useEffect(() => {
    if (
      // !eventsResponse.data &&
      // !eventsOwnAndRelatedResponse.data &&
      // !eventsResponse.loading &&
      // !eventsOwnAndRelatedResponse.loading &&
      isBefore(from, to) &&
      (memberId || clientId)
    ) {
      const where = {
        endAt: { _gte: from },
        startAt: { _lte: to },
      };

      if (memberId || clientId) {
        if (
          permissible({
            permissions: [Permission.CLIENT_READ, Permission.MEMBER_READ, Permission.EVENT_READ],
          })
        ) {
          clientId && Object.assign(where, { client: { id: { _eq: clientId } } });
          memberId && Object.assign(where, { member: { id: { _eq: memberId } } });

          if (eventAttributes) Object.assign(where, { _or: eventAttributes });

          getEvents({
            variables: {
              input: {
                where,
                orderBy: [
                  { startAt: OrderBy.ASC },
                  { duration: OrderBy.ASC },
                  { createdAt: OrderBy.ASC },
                ],
                entityStates: eventStates,
              },
            },
          });
        } else {
          getEventsOwnAndRelated({
            variables: {
              input: {
                where,
                client: clientId ? { id: clientId } : undefined,
                orderBy: [{ startAt: OrderBy.ASC }, { duration: OrderBy.ASC }],
              },
            },
          });
        }
      }
    }
  }, [
    getEvents,
    getEventsOwnAndRelated,
    memberId,
    clientId,
    from,
    to,
    permissible,
    eventStates,
    eventAttributes,
  ]);

  /**
   * Convert events into shifts
   */
  const shifts: Array<Array<Shift>> | undefined = useMemo(() => {
    const memberColors: MemberColors[] = [];
    const usedColors: string[] = [];

    const eventColors = [
      '#ffcdd2', // Red
      '#bbdefb', // Blue
      '#fff9c4', // Yellow
      '#e1bee7', // Purple
      '#c8e6c9', // Green
      '#ffe0b2', // Orange
      '#f8bbd0', // Pink
      '#d1c4e9', // Deep Purple
      '#ffccbc', // Deep Orange
      '#b3e5fc', // Light Blue
      '#dcedc8', // Light Green
      '#ffecb3', // Amber
      '#b2ebf2', // Cyan
      '#f0f4c3', // Lime
      '#b2dfdb', // Teal
      '#c5cae9', // Indigo
    ];

    const allocateColor = (memberId: string): string => {
      if (memberColors.some(({ id }) => id === memberId)) {
        // Employee has already been allocated a colour
        // const index = employeeColors.indexOf({ id: employeeId })
        return memberColors.find((memberColor) => memberColor.id === memberId)?.color!;
      } else {
        const color = eventColors[usedColors.length];
        memberColors.push({ id: memberId, color });
        usedColors.push(color);
        return color;
      }
    };

    if (!(eventsResponse.data || !eventsOwnAndRelatedResponse.data) && !(memberId || !clientId)) {
      return undefined;
    }

    const shifts: Array<Array<Shift>> = [];

    for (let i = 0; i < range; i++) {
      shifts[i] = [];
    }

    // Step 1: Calculate base values for each event block
    (eventsResponse.data?.events || eventsOwnAndRelatedResponse.data?.eventsOwnAndRelated)?.forEach(
      (event) => {
        const startAt = new Date(event.startAt);
        const endAt = new Date(event.endAt);
        const passiveStartAt =
          event.passive && event.passiveStartAt ? new Date(event.passiveStartAt) : null;
        const passiveEndAt = event.passive && passiveStartAt ? addHours(passiveStartAt, 8) : null;
        const eventDateStart = startOfDay(startAt);
        const eventDateEnd = startOfDay(endAt);

        // If event ends at midnight and spans multiple days, subtrack 1 day
        const daySpan =
          differenceInDays(eventDateEnd, eventDateStart) -
          (differenceInDays(eventDateEnd, eventDateStart) > 0 && getHours(endAt) === 0 ? 1 : 0);

        let color = clientId
          ? event.member
            ? event.member.color
              ? event.member.color
              : allocateColor(event.member.id)
            : theme.palette.background.paper
          : event.client.color
          ? event.client.color
          : theme.palette.background.paper;

        let style: CSSProperties = event.cancel
          ? {
              backgroundColor: color,
              // Thin diagonal lines
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`,
            }
          : event.member
          ? (permissible({ permissions: Permission.EVENT_READ }) ||
              event.member.id === member!.id) &&
            isAfter(new Date(event.startAt), new Date('2022-08-29')) &&
            ((!event.clockedOnAt &&
              isAfter(new Date(), addMinutes(new Date(event.startAt), 5)) &&
              isBefore(new Date(), new Date(event.endAt))) ||
              (!event.clockedOffAt && isAfter(new Date(), new Date(event.endAt))))
            ? {
                // color: 'black',
                backgroundColor: '#FF69B4',
                backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23b8276f' fill-opacity='1'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
              }
            : event.member.color
            ? { backgroundColor: color }
            : { backgroundColor: color }
          : {
              backgroundColor: theme.palette.background.paper,

              // Thick disagonal lines
              // backgroundImage: `url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ccc' fill-opacity='0.6' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E")`,

              // Crosses
              // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ccc' fill-opacity='1'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,

              // Hexigon
              // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23fcdc00' fill-opacity='1' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,

              // Polka dots
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E")`,
            };

        Object.assign(style, { color: contrastingColor(hexToRGB(color)) });

        if (color.toLowerCase() === '#ffffff' || color.toLowerCase() === '#fff') {
          Object.assign(style, { border: '1px solid ' + grey[700] });
        }

        let i = 0;
        while (i <= daySpan) {
          // Start of day of block
          const blockDateStart = addDays(eventDateStart, i);
          // End of day of block
          const blockDateEnd = addDays(blockDateStart, 1);
          // Start datetime of block
          const blockStartAt = i === 0 ? startAt : blockDateStart;
          // End datetime of block
          const blockEndAt = i < daySpan && daySpan > 0 ? blockDateEnd : endAt;
          if (blockStartAt < addDays(from, range) && blockStartAt >= from) {
            shifts[differenceInDays(blockStartAt, from)].push({
              event: {
                id: event.id,
                createdAt: new Date(event.createdAt),
                startAt,
                endAt,
                duration: differenceInMinutes(endAt, startAt),
                clockedOn: isEvent(event) ? !!event.clockedOnAt : false,
                color,
                hasNotes: event.hasNotes,
                hasFiles: event.hasFiles,
                conflictExists: isEvent(event) ? event.conflictExists : false,
                publicHoliday: event.publicHoliday,
                memberAssignedAutomatically: event.memberAssignedAutomatically,
                passive: event.passive,
                passiveStartAt: passiveStartAt
                  ? isBefore(passiveStartAt, blockStartAt)
                    ? blockStartAt
                    : passiveStartAt
                  : undefined,
                passiveEndAt: passiveEndAt
                  ? isBefore(blockEndAt, passiveEndAt)
                    ? blockEndAt
                    : passiveEndAt
                  : undefined,
                activeAssist: event.activeAssist,
                cancelled: !!event.cancel,
                style,
                title: clientId
                  ? event.member
                    ? formatPersonName(event.member, { preferred: true })!
                    : '(TBA)'
                  : formatPersonName(event.client, { preferred: true })!,
                client: event.client,
                member: event.member,
              },
              startAt: blockStartAt,
              endAt: blockEndAt,
              duration: differenceInMinutes(blockEndAt, blockStartAt),
              width: 0,
              height: (differenceInMinutes(blockEndAt, blockStartAt) / 1440) * 100,
              leftOffset: 0,
              topOffset: (differenceInMinutes(blockStartAt, blockDateStart) / 1440) * 100,
              futureOverlaps: 0,
              pastOverlaps: 0,
              startOfOverlap: false,
              endOfOverlap: false,
              startOverlapsIndex: 0,
            });
          }
          i++;
        }
      },
    );

    // Sort shifts in chronological order
    for (let i = 0; i < shifts.length; i++) {
      shifts[i].sort((previous, current) => {
        if (isBefore(previous.startAt, current.startAt)) {
          return -1;
        }
        if (isEqual(previous.startAt, current.startAt)) {
          // Both start at same time, now sort based on duration
          if (previous.duration > current.duration) {
            // Longest shift first
            return -1;
          }
          if (previous.duration === current.duration) {
            // Both are the same duration, now sort based on createdAt
            if (isBefore(previous.event.createdAt, current.event.createdAt)) {
              return -1;
            }
          }
        }
        return 1;
      });
    }

    // Calculate future and past overlaps
    for (let i = 0; i < shifts.length; i++) {
      // Loop through each day
      for (let j = 0; j < shifts[i].length; j++) {
        // Loop through each shift for this day
        shifts[i][j].pastOverlaps = shifts[i].filter((shift) => {
          /**
           * To calculate a past shifts, both conditions must be true:
           * - End after current shift starts
           * - (Start before current shift
           *   OR ((start at same time as current shift
           *          AND is longer than current shift)
           *      OR (start at same time as current shift
           *         AND is same length
           *        AND is created before current shift))
           */
          return (
            (isBefore(shift.startAt, shifts[i][j].startAt) ||
              (isEqual(shift.startAt, shifts[i][j].startAt) &&
                (shift.duration > shifts[i][j].duration ||
                  (shift.duration === shifts[i][j].duration &&
                    isBefore(shift.event.createdAt, shifts[i][j].event.createdAt))))) &&
            isBefore(shifts[i][j].startAt, shift.endAt)
          );
        }).length;

        shifts[i][j].futureOverlaps = shifts[i].filter((shift) => {
          return (
            (isBefore(shifts[i][j].startAt, shift.startAt) ||
              (isEqual(shifts[i][j].startAt, shift.startAt) &&
                (shift.duration < shifts[i][j].duration ||
                  (shift.duration === shifts[i][j].duration &&
                    isBefore(shifts[i][j].event.createdAt, shift.event.createdAt))))) &&
            isBefore(shift.startAt, shifts[i][j].endAt)
          );
        }).length;
      }
    }

    // Calculate overlap groups
    for (let i = 0; i < shifts.length; i++) {
      // Only loop through days that have shifts
      if (shifts[i].length > 0) {
        // For first shift of the day set startOfOverlap = true
        shifts[i][0].startOfOverlap = true;
        // Loop through each shift for this day
        for (let j = 0; j < shifts[i].length; j++) {
          if (shifts[i][j].startOfOverlap) {
            shifts[i][j + shifts[i][j].futureOverlaps].endOfOverlap = true;
            // Check if there is a shift after previous endOfOverlap
            if (shifts[i][j + shifts[i][j].futureOverlaps + 1]) {
              // If there is, set it as startOfOverlap = true
              shifts[i][j + shifts[i][j].futureOverlaps + 1].startOfOverlap = true;
            }
          }
        }
      }
    }

    // Calculate widths
    for (let i = 0; i < shifts.length; i++) {
      // Only loop through days that have shifts
      if (shifts[i].length > 0) {
        // Loop through each shift for this day
        for (let j = 0; j < shifts[i].length; j++) {
          if (shifts[i][j].startOfOverlap) {
            // First shift in overlap group
            if (shifts[i][j].pastOverlaps > 0) {
              // Shift has pastOverlaps

              // Loop past overlaps and find the shift with the earliest endAt
              // 1. Create new array of past overlays
              const pastOverlays: Shift[] = shifts[i].filter(
                (shift) =>
                  (isBefore(shift.startAt, shifts[i][j].startAt) ||
                    (isEqual(shift.startAt, shifts[i][j].startAt) &&
                      (shift.duration > shifts[i][j].duration ||
                        (shift.duration === shifts[i][j].duration &&
                          isBefore(shift.event.createdAt, shifts[i][j].event.createdAt))))) &&
                  isBefore(shifts[i][j].startAt, shift.endAt),
              );
              const earliestEndAt = pastOverlays.sort((previous, current) =>
                previous.endAt > current.endAt ? -1 : 1,
              )[0];

              shifts[i][j].width = Math.min(
                // Immediate previous shift's width times current shift's past overlaps
                // 100 - shifts[i][j - 1].width * shifts[i][j].pastOverlaps,
                earliestEndAt.leftOffset,

                shifts[i][j].futureOverlaps
                  ? // This might need to be checking against the latest-ending shift of the previous
                    // overlap group instead of the immediate previous one.
                    isBefore(shifts[i][j - 1].endAt, shifts[i][j + 1].startAt) ||
                    isEqual(shifts[i][j - 1].endAt, shifts[i][j + 1].startAt)
                    ? 100 / (shifts[i][j].futureOverlaps + 1)
                    : 100 / (shifts[i][j].pastOverlaps + shifts[i][j].futureOverlaps + 1)
                  : 100,
              );
            } else {
              // Shift does not have pastOverlaps

              // shifts[i][j].width = 100 / (futureOverlapsThatOverlapCount + 1);
              shifts[i][j].width = 100 / (shifts[i][j].futureOverlaps + 1);
            }
          } else {
            // Width is always the same as any shift in same overlap group
            shifts[i][j].width = shifts[i][j - 1].width;
          }

          // Calculate leftOffsets
          if (!shifts[i][j].startOfOverlap) {
            shifts[i][j].leftOffset = shifts[i][j - 1].width + shifts[i][j - 1].leftOffset;
          }
        }
      }
    }
    return shifts;
  }, [
    eventsResponse.data,
    eventsOwnAndRelatedResponse.data,
    memberId,
    clientId,
    range,
    from,
    theme,
  ]);

  return (
    <ScheduleContext.Provider
      value={{
        refetch,
        setFrom,
        setRange,
        setCell,
        setEvent,
        setMouse: setMouseState,
        // setProfile: setProfileRedirect,
        client: {
          get: () => getProfileWrapper('client'),
          set: (id: string, redirect: boolean) => setProfileWrapper('client', id, redirect),
        },
        member: {
          get: () =>
            !permissible({ permissions: Permission.MEMBER_READ })
              ? member!.id
              : getProfileWrapper('member'),
          set: (id: string, redirect: boolean) => setProfileWrapper('member', id, redirect),
        },
        eventStates,
        setEventStates,
        eventAttributes,
        setEventAttributes,
        dates: {
          today,
          now,
          from,
          to,
          range,
          autoMemberAssign,
        },
        mouse,
        target: {
          // employee,
          // participant,
          cells: { down: cellDown, over: cellOver },
          event: {
            over: eventOver,
          },
        },
        lists: {
          // employees: employees.data?.getManyEmployees,
          // participants: participants.data?.getManyParticipants,
          shifts,
        },
        settings: orgSettings.data?.me.member?.org ?? {},
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export default ScheduleProvider;
