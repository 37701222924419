import {
  ChipProps,
  createStyles,
  FormControl,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { Chip, Select, SelectProps } from '@timed/common';
import { OrderBy, useGetVisaTypesLazyQuery } from '@timed/gql';
import { useEffect } from 'react';
import { UseFormWatch } from 'react-hook-form';

type MemberVisaTypeInputProps = Omit<SelectProps, 'items'> & {
  chipProps: ChipProps;
  watch: UseFormWatch<any>;
  // clientId: Client['id'];
  // startAt: Date;
  // endAt: Date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 185,
      backgroundColor: 'white',
    },
    loading: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.background.default,
      },
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    item: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      gap: theme.spacing(0),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      height: 18,
    },
    chip: {
      // marginLeft: theme.spacing(0.5),
      height: 20,
    },
  }),
);

function MemberVisaTypeInput({
  chipProps,
  // type,
  watch,
  // clientId,
  // startAt,
  // endAt,
  ...props
}: MemberVisaTypeInputProps) {
  const classes = useStyles();

  const watched = watch(props.name);

  // const payrollPeriodStartAt = startOfWeek(startAt, { weekStartsOn: 1 });
  // const payrollPeriodEndAt = addDays(payrollPeriodStartAt, 7);

  const [getVisaTypes, { loading, data }] = useGetVisaTypesLazyQuery({
    variables: {
      input: {
        orderBy: [{ name: OrderBy.ASC }],
      },
    },
  });

  useEffect(() => {
    if (!data && !loading) {
      getVisaTypes();
    }
  }, [getVisaTypes, data, loading]);

  const selected = data?.visaTypes?.find((v) => v.id === watched);

  // const descriptions = profiles?.map((p) => {
  //   let color:
  //     | 'inherit'
  //     | 'initial'
  //     | 'primary'
  //     | 'secondary'
  //     | 'textPrimary'
  //     | 'textSecondary'
  //     | 'error'
  //     | undefined = 'textSecondary';

  //   let text = undefined;

  //   if (p.teams?.length && p.teams[0].preferred) text = 'Preferred team member';

  //   if (p.events?.length) {
  //     text =
  //       'Scheduling conflict (' +
  //       formatPersonName(p.events[0].client, { capitaliseLastName: true, lastNameFirst: true }) +
  //       ')';
  //     color = 'error';
  //   }

  //   if (p.unavailables?.length) {
  //     text = 'Unavailable';
  //     color = 'error';
  //   }

  //   const hours = p.schedule?.timeScheduled! / 60;

  //   return {
  //     id: p.id,
  //     message:
  //       !text && hours === 0 ? null : (
  //         <>
  //           {text && (
  //             <Typography variant="body2" color={color}>
  //               {text}
  //             </Typography>
  //           )}
  //           {hours > 0 && (
  //             <Typography variant="body2" color="textSecondary">
  //               {hours + ' hours scheduled this week'}
  //             </Typography>
  //           )}
  //         </>
  //       ),
  //   };
  // });

  // const profileList = profiles
  //   ? profiles.map((p) => {
  //       return {
  //         value: p.id!,
  //         label: (
  //           <Box className={classes.item}>
  //             <Typography variant="body1">
  //               {formatPersonName(p, { capitaliseLastName: true, lastNameFirst: true })!}
  //               {p.preferredName !== p.firstName && (
  //                 <> ({formatPersonName(p, { preferred: true })})</>
  //               )}
  //             </Typography>
  //             {descriptions?.find((d) => d.id === p.id)?.message}
  //           </Box>
  //         ),
  //       };
  //     })
  //   : undefined;

  return !data || loading ? (
    <FormControl className={classes.loading}>
      <TextField
        disabled
        // label={type}
        variant={props.variant || 'outlined'}
        size="small"
        value="Loading..."
        className={props.className}
      />
    </FormControl>
  ) : (
    <Select
      items={data.visaTypes.map((v) => ({ label: v.name, value: v.id }))}
      renderValue={() =>
        selected && (
          <div className={classes.chips}>
            <Chip
              size="small"
              color="primary"
              label={selected.name}
              className={classes.chip}
              onMouseDown={(event: any) => event.stopPropagation()}
              {...chipProps}
            />
          </div>
        )
      }
      {...props}
    />
  );
}

export default MemberVisaTypeInput;
