import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { addServerErrors, Block, IconButtonMulti, Textarea } from '@timed/common';
import { Permission, useUpdateSupportCoordinatorsMutation } from '@timed/gql';
import { Protected } from '@timed/auth';
import { SupportCoordinatorContext } from '@timed/support-coordinator';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  patch: {
    notes: string;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      flex: '1 1 auto',
      display: 'flex',
    },
    paper: {
      flex: '1 1 auto',
      gridTemplateRows: 'min-content auto',
    },
    input: {
      padding: theme.spacing(4),
      overflowY: 'auto',
      outline: 'none',
      resize: 'none',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      fontFamily: 'inherit',
      WebkitTextFillColor: theme.palette.text.primary, // required for iOS
      opacity: 1, // required for iOS
      width: '100%',
      height: 'calc(100% - 3px)',
    },
    disabled: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  }),
);

const SupportCoordinatorViewNotes = () => {
  const classes = useStyles();

  const supportCoordinator = useContext(SupportCoordinatorContext);

  const [editing, setEditing] = useState<boolean>(false);

  const [updateSupportCoordinators, response] = useUpdateSupportCoordinatorsMutation();

  const defaultValues: FormData = { patch: { notes: supportCoordinator.notes || '' } };

  const { handleSubmit, watch, control, setError } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) =>
    updateSupportCoordinators({
      variables: { input: { ids: [supportCoordinator.id], ...values } },
    }).catch((e) => {});

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Block
        paperProps={{ className: classes.paper }}
        title="Notes"
        topRight={
          <Protected permissions={Permission.SUPPORTCOORDINATOR_WRITE}>
            <IconButtonMulti
              enabled={editing}
              changed={!isEqual(currentValues, defaultValues)}
              setEditing={setEditing}
              loading={response.loading}
              success={!!response.data}
            />
          </Protected>
        }
      >
        <Textarea
          name="patch.notes"
          control={control}
          disabled={!editing}
          className={editing ? classes.input : clsx(classes.input, classes.disabled)}
          style={{ height: '', overflow: '' }}
        />
      </Block>
    </form>
  );
};

export default SupportCoordinatorViewNotes;
