import { makeStyles, TextField } from '@material-ui/core';

type PlanManagerListSearchProps = React.PropsWithChildren<{
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}>;

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.common.white,
  },
}));

const PlanManagerListSearch = (props: PlanManagerListSearchProps) => {
  const classes = useStyles();

  return (
    <TextField
      autoFocus
      fullWidth
      name="search"
      variant="outlined"
      label={'Search plan managers'}
      className={classes.textField}
      onChange={props.handleSearch}
    />
  );
};

export default PlanManagerListSearch;
