import { Select, SelectProps, validateUuid } from '@timed/common';
import { OrderBy, useGetBranchesQuery } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';

const ClientBranchInput = ({ defaultValue, ...props }: Omit<SelectProps, 'items'>) => {
  const { data, loading } = useGetBranchesQuery({
    variables: { input: { orderBy: [{ name: OrderBy.ASC }] } },
  });

  useLoadingEffect(loading);

  return (
    <Select
      label="Branch"
      validation={validateUuid}
      items={
        data?.branches &&
        Object.values(data.branches).map((b) => {
          return { value: b.id, label: b.name };
        })
      }
      {...props}
    />
  );
};

export default ClientBranchInput;
