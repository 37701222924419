import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Protected } from '@timed/auth';
import { Button } from '@timed/common';
import { OrderBy, Permission, useGetMemberUnavailablesLazyQuery } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import {
  MemberContext,
  MemberUnavailabilityPeriod,
  MemberUnavailabilityPeriodCreateFormModal,
  MemberUpdateUnavailableDaysForm,
} from '@timed/member';
import { useModal } from 'mui-modal-provider';
import { useContext, useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flex: '1 1 auto',
      display: 'flex',
      overflowY: 'hidden',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    layoutGrid: {
      flex: '0 1 auto',
      overflowY: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
      },
    },
    content: {
      overflowY: 'auto',
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: theme.spacing(4),
    },
  }),
);

const MemberViewUnavailabilityPeriods = () => {
  const classes = useStyles();
  const member = useContext(MemberContext);
  const { showModal } = useModal();

  const [getUnavailables, { data, loading }] = useGetMemberUnavailablesLazyQuery();

  useEffect(() => {
    if (!data && !loading)
      getUnavailables({
        variables: {
          unavailablesInput: {
            orderBy: [{ startAt: OrderBy.DESC }],
            where: {
              deletedAt: { _eq: null },
              owner: { id: { _eq: member.id } },
              // startAt: { _gte: subDays(new Date(), 7) },
            },
          },
        },
      });
  }, [data, getUnavailables, loading, member.id]);

  useLoadingEffect(loading);

  const handleOpenModal = () => {
    const modal: { hide: () => void } = showModal(MemberUnavailabilityPeriodCreateFormModal, {
      onClose: () => modal.hide(),
      member,
    });
  };

  return (
    <Box className={classes.wrapper}>
      <MemberUpdateUnavailableDaysForm member={member} />
      <Box className={classes.layoutGrid}>
        <Protected requireAny permissions={[Permission.MEMBER_WRITE, Permission.EVENT_WRITE]}>
          <Button
            style={{ width: 'max-content', alignSelf: 'flex-end' }}
            onClick={() => handleOpenModal()}
            variant="contained"
            color="primary"
          >
            Add new
          </Button>
        </Protected>
        <Box className={classes.content}>
          {data?.memberUnavailables?.map((period) => (
            <MemberUnavailabilityPeriod period={period} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MemberViewUnavailabilityPeriods;
