import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { _schedule } from '@timed/app';
import { formatTimeDuration, useRouter } from '@timed/common';
import { ScheduleContext, Shift } from '@timed/schedule/context';
import { setProfile } from '@timed/schedule/helpers';
import { format, isToday, startOfWeek } from 'date-fns';
import { useContext, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    wrapper: {
      cursor: 'pointer',
      padding: theme.spacing(2),
      backgroundColor: red[50], // theme.palette.background.default,
      border: '1px solid ' + theme.palette.error.main,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      lineHeight: 'initial',
      '& .MuiTypography-root': {
        fontSize: 11,
      },
    },
    top: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
    },
    text: {
      '& .MuiTypography-root:first-child': {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    events: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    event: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      '& .MuiTypography-root:first-child': {
        fontSize: 10,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  }),
);

const ScheduleConflictingEvents = () => {
  const classes = useStyles();

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const [showEvents, setShowEvents] = useState<boolean>(false);

  const {
    lists: { shifts },
  } = useContext(ScheduleContext);

  const conflictingEvents = useMemo<Shift['event'][]>(() => {
    return (
      shifts
        ?.flat()
        .map(({ event }) => event)
        .filter(({ conflictExists }) => conflictExists)
        .filter((event, index, self) => self.findIndex((e) => e.id === event.id) === index) ?? []
    );
  }, [shifts]);

  const handleClickLink = (clientId: string, startAt: Date) => {
    setProfile('client', searchParams, clientId);
    setProfile('member', searchParams, undefined);

    const formattedDate = format(startOfWeek(startAt, { weekStartsOn: 1 }), 'ddMMyyyy');

    if (searchParams.get('f') !== formattedDate) {
      searchParams.set('f', formattedDate);
    }

    navigate(_schedule.path + '?' + searchParams);
  };

  if (!conflictingEvents.length) return <></>;

  return (
    <Box
      className={classes.wrapper}
      onClick={() => {
        setShowEvents(!showEvents);
      }}
    >
      <Box className={classes.top}>
        <ErrorRoundedIcon style={{ fill: red[800] }} />
        <Box className={classes.text}>
          <Typography variant="body1">Warning: Conflicting Shifts</Typography>
          <Typography variant="body2">{conflictingEvents.length} on this page.</Typography>
        </Box>
      </Box>

      {showEvents && (
        <Box className={classes.events}>
          {conflictingEvents.map((event) => (
            <Box
              className={classes.event}
              onClick={(e) => {
                handleClickLink(event.client.id, new Date(event.startAt));
                e.stopPropagation();
              }}
            >
              <Typography>
                {isToday(new Date(event.startAt)) ? 'Today' : 'Tomorrow'}
                {', '}
                {format(new Date(event.startAt), 'd MMM')}
                {', '}
                {formatTimeDuration({
                  start: new Date(event.startAt),
                  end: new Date(event.endAt),
                  options: {
                    militaryTime: true,
                    seperator: ' - ',
                  },
                })}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ScheduleConflictingEvents;
