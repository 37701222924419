import { Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useAuth } from '@timed/auth';
import { IconButton } from '@timed/common';
import { EventDeleteFormModal, EventUpdateFormModal } from '@timed/event';
import { EventCancelFormModal } from '@timed/event/components/CancelFormModal';
import { EventHistoryModal } from '@timed/event/components/HistoryModal';
import { EventUncancel } from '@timed/event/components/Uncancel';
import { GetEventByIdQuery } from '@timed/gql';
import { useModal } from 'mui-modal-provider';
import { useState } from 'react';

type EventSummaryModalMenuButtonProps = {
  event: GetEventByIdQuery['eventById'];
  closeSummaryPage: () => void;
};

const EventSummaryModalMenuButton = ({
  event,
  closeSummaryPage,
}: EventSummaryModalMenuButtonProps) => {
  const { showModal } = useModal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { permissible } = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditEventModal = () => {
    const modal: { hide: () => void } = showModal(EventUpdateFormModal, {
      onClose: () => {
        modal.hide();
      },
      closeSummaryPage,
      event,
    });
  };

  const handleOpenCancelEventModal = () => {
    const modal: { hide: () => void } = showModal(EventCancelFormModal, {
      onClose: () => {
        modal.hide();
      },
      event,
    });
  };

  const handleOpenUncancelEventModal = () => {
    const modal: { hide: () => void } = showModal(EventUncancel, {
      onClose: () => {
        modal.hide();
      },
      eventId: event.id,
    });
  };

  const handleOpenDeleteEventModal = () => {
    const modal: { hide: () => void } = showModal(EventDeleteFormModal, {
      onClose: () => {
        modal.hide();
      },
      eventId: event.id,
    });
  };

  const handleOpenHistoriesModal = () => {
    const modal: { hide: () => void } = showModal(EventHistoryModal, {
      onClose: () => {
        modal.hide();
      },
      eventId: event.id,
    });
  };

  return (
    <span>
      <IconButton aria-label="menu" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 60 * 4.5,
            width: '20ch',
          },
        }}
      >
        {!event.cancel && (
          <MenuItem
            onClick={() => {
              handleOpenEditEventModal();
              handleClose();
            }}
          >
            Edit event
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            !!event.cancel ? handleOpenUncancelEventModal() : handleOpenCancelEventModal();
            handleClose();
          }}
        >
          {!!event.cancel ? 'Uncancel' : 'Cancel'} event
        </MenuItem>

        {permissible({ admin: true }) && (
          <>
            <MenuItem
              onClick={() => {
                handleOpenDeleteEventModal();
                handleClose();
              }}
            >
              Delete event
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpenHistoriesModal();
                handleClose();
              }}
            >
              History
            </MenuItem>
          </>
        )}
      </Menu>
    </span>
  );
};

export default EventSummaryModalMenuButton;
