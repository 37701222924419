import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ScheduleContext } from '@timed/schedule';
import { differenceInMinutes, format } from 'date-fns';
import { useContext } from 'react';

type ScheduleCalendarNowIndicatorProps = {
  date: Date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      pointerEvents: 'none',
      width: '100%',
      whiteSpace: 'nowrap',
      borderBottom: '1px solid #FF0000',
      position: 'relative',
      zIndex: 100,
      color: 'rgba(255,0,0,0.8)',
      fontWeight: theme.typography.fontWeightBold,
      boxSizing: 'border-box',
      fontSize: '11px',
      paddingBottom: 1,
      lineHeight: 1,
    },
  }),
);

const ScheduleCalendarNowIndicator = (props: ScheduleCalendarNowIndicatorProps) => {
  const classes = useStyles();
  const {
    dates: { now },
  } = useContext(ScheduleContext);

  return (
    <Box
      className={classes.wrapper}
      style={{
        top: 'calc(-100% + ' + (differenceInMinutes(now, props.date) / 1440) * 100 + '% - 12.3px',
      }}
    >
      {format(now, 'h:mm')}
    </Box>
  );
};

export default ScheduleCalendarNowIndicator;
