import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  ClientContextType,
  ClientFileCategory,
  clientFilesMetadata,
  ClientFileTypeInput,
} from '@timed/client';
import {
  addServerErrors,
  DateInput,
  formatPersonName,
  FormModal,
  ModalProps,
  ProfileInput,
  TextField,
} from '@timed/common';
import { FileMetadata } from '@timed/file';
import {
  ClientFile,
  ClientFileType,
  Maybe,
  OrderBy,
  QueryByIdInput,
  UpdateClientFilesDocument,
  useUpdateClientFilesMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type ClientUpdateFileFormProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  client: ClientContextType;
  file: Pick<ClientFile, 'id' | 'type' | 'dateOfFile' | 'expiresAt' | 'value'>;
};

type FormData = {
  patch: {
    type: ClientFileType;
    owner: QueryByIdInput;
    dateOfFile?: Maybe<Date>;
    expiresAt?: Maybe<Date>;
    value?: Maybe<string>;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      height: 360,
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 256,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        width: 384,
      },
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    info: {
      padding: theme.spacing(2, 4),
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.divider,
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
    },
  }),
);

const ClientUpdateFileForm = ({
  client,
  file,
  onClose,
  ...modalProps
}: ClientUpdateFileFormProps) => {
  const classes = useStyles();

  const [updateFile, response] = useUpdateClientFilesMutation();

  const [metadata, setMetadata] = useState<FileMetadata<ClientFileType, ClientFileCategory>>(
    clientFilesMetadata.find((meta) => meta.id === file.type)!,
  );

  const defaultValues: FormData = {
    patch: {
      type: file.type,
      value: file.value,
      dateOfFile: file.dateOfFile,
      expiresAt: file.expiresAt ? new Date(file.expiresAt) : null,
      owner: { id: client.id },
    },
  };

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  useLoadingEffect(response.loading);

  const onSubmit = ({ patch }: FormData) => {
    updateFile({
      variables: {
        input: {
          ids: [file.id],
          patch,
        },
      },
    }).catch((e) => {});
  };

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientFiles(existing = []) {
          return [
            ...existing,
            cache.writeQuery({ data: response.data, query: UpdateClientFilesDocument }),
          ];
        },
      },
    });
  };

  /**
   * Display error messages from server response
   */
  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Edit file"
      description={`Edit a stored file for ${formatPersonName(client)}`}
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.inputs}>
        <ProfileInput
          required
          control={control}
          name="patch.owner.id"
          chipProps={{ onDelete: () => setValue('patch.owner.id', '') }}
          formControlProps={{ variant: 'outlined', size: 'small' }}
          type={'client'}
          orderBy={[{ lastName: OrderBy.ASC }]}
          watch={watch}
          label="Participant"
        />
        <ClientFileTypeInput
          required
          name="patch.type"
          control={control}
          error={!!errors.patch?.type}
          helperText={errors.patch?.type?.message}
          formControlProps={{ size: 'small', variant: 'outlined' }}
          onChange={(event) =>
            setMetadata(clientFilesMetadata.find((meta) => meta.id === event.target.value)!)
          }
        />
        {metadata?.hasDate && (
          <DateInput
            keyboard
            disableTime
            name="patch.dateOfFile"
            label="Date of File"
            control={control}
            inputVariant="outlined"
            size="small"
            error={!!errors.patch?.dateOfFile}
            helperText={errors.patch?.dateOfFile?.message}
          />
        )}
        {metadata?.hasExpiryDate && (
          <DateInput
            keyboard
            name="patch.expiresAt"
            label="Expiry Date"
            control={control}
            inputVariant="outlined"
            size="small"
            error={!!errors.patch?.expiresAt}
            helperText={errors.patch?.expiresAt?.message}
          />
        )}
        {metadata?.hasNote && (
          <TextField
            name="patch.value"
            variant="outlined"
            size="small"
            label="Notes"
            type="text"
            validation={{ maxLength: { value: 255, message: 'Too long' } }}
            control={control}
            error={!!errors.patch?.value}
            helperText={errors.patch?.value?.message}
          />
        )}
      </Box>
    </FormModal>
  );
};

export default ClientUpdateFileForm;
