import { Box, createStyles, Hidden, makeStyles, Theme } from '@material-ui/core';
import {
  ScheduleCalendarColumn,
  ScheduleCalendarDates,
  ScheduleCalendarFilters,
  ScheduleCalendarTimes,
  ScheduleContext,
  ScheduleDateSkipButtons,
} from '@timed/schedule';
import { addDays } from 'date-fns';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flex: '1 1 auto',
      display: 'flex',
      flexFlow: 'column',
      // gap: theme.spacing(4),
      height: '100%',
      userSelect: 'none',
      overflow: 'hidden',
      boxSizing: 'border-box',
      '& div': {
        boxSizing: 'border-box',
      },
    },
    header: {
      flex: '0 1 auto',
      display: 'flex',
      flexFlow: 'row',
    },
    content: {
      flex: '1 1 auto',
      display: 'flex',
      flexFlow: 'row',
      height: '100%',
    },
    indent: {
      flex: '1 1 ' + theme.spacing(12) + 'px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // "& p": {
      //   backgroundColor: theme.palette.secondary.main,
      //   color: theme.palette.common.white,
      //   height: 41,
      //   width: 41,
      //   margin: "4px auto",
      //   borderRadius: 22,
      //   paddingTop: 6,
      //   fontSize: 12,
      //   textAlign: "center",
      //   lineHeight: "14px",
      //   "& div:last-child": {
      //     fontWeight: "bold",
      //     fontSize: 14,
      //   },
      //   "& span": {
      //     fontSize: 10,
      //     position: "relative",
      //     top: -2,
      //   },
      // },
    },
    days: {
      flex: '1 1 auto',
      display: 'flex',
      flexFlow: 'row',
      width: '100%',
    },
  }),
);

const ScheduleCalendar = () => {
  const classes = useStyles();

  const {
    dates: { from, range },
    setCell,
  } = useContext(ScheduleContext);

  /**
   * Generate columns
   */
  let columns: Array<JSX.Element> = [];
  for (let i = 0; i < range; i++) {
    columns.push(<ScheduleCalendarColumn date={addDays(from, i)} key={i} />);
  }

  const handleMouseOut = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Unset hovered cell
    // Unset hovered event
    setCell();
  };

  return (
    <Box className={classes.wrapper}>
      <Hidden mdUp>
        <ScheduleCalendarFilters />
      </Hidden>
      <Box className={classes.header}>
        <Box className={classes.indent}>
          <Hidden smDown>
            <ScheduleDateSkipButtons />
          </Hidden>
        </Box>
        <ScheduleCalendarDates />
      </Box>
      <Box className={classes.content}>
        <ScheduleCalendarTimes />
        <Box className={classes.days} onMouseLeave={handleMouseOut}>
          {columns}
        </Box>
      </Box>
    </Box>
  );
};

export default ScheduleCalendar;
