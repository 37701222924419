import { _settingsOrganisationScheduling } from '@timed/app';
import { Page } from '@timed/page';
import { SettingsOrganisationSchedulingForm } from '@timed/settings';

const SettingsOrganisationScheduling = () => {
  return (
    <Page {..._settingsOrganisationScheduling}>
      <SettingsOrganisationSchedulingForm />
    </Page>
  );
};

export default SettingsOrganisationScheduling;
