import { ApolloQueryResult } from '@apollo/client';
import { GetMeQuery, Member, Org, Permission, User } from '@timed/gql';
import { createContext } from 'react';

export type AuthContextType = {
  /**
   * Refetch GetMeQuery results.
   */
  refetch: () => Promise<ApolloQueryResult<GetMeQuery>> | void;

  /**
   * Check if user has permission to preform an action.
   */
  permissible: (values: { permissions?: Permission | Permission[]; admin?: boolean }) => boolean;

  /**
   * User's active org
   */
  org?:
    | (Pick<Org, 'id' | 'legalName' | 'preferredName' | 'membersCanSeeClockedTimes'> & {
        schedulingContactPerson?: Pick<
          Member,
          'id' | 'firstName' | 'lastName' | 'preferredName' | 'phone'
        > | null;
        schedulingContactPerson2?: Pick<
          Member,
          'id' | 'firstName' | 'lastName' | 'preferredName' | 'phone'
        > | null;
      })
    | null;

  /**
   * User's active member
   */
  member?: Pick<
    Member,
    | 'id'
    | 'color'
    | 'admin'
    | 'permissions'
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'preferredName'
    | 'unavailableNotes'
    | 'unavailableMoMorn'
    | 'unavailableMoDay'
    | 'unavailableMoNight'
    | 'unavailableTuMorn'
    | 'unavailableTuDay'
    | 'unavailableTuNight'
    | 'unavailableWeMorn'
    | 'unavailableWeDay'
    | 'unavailableWeNight'
    | 'unavailableThMorn'
    | 'unavailableThDay'
    | 'unavailableThNight'
    | 'unavailableFrMorn'
    | 'unavailableFrDay'
    | 'unavailableFrNight'
    | 'unavailableSaMorn'
    | 'unavailableSaDay'
    | 'unavailableSaNight'
    | 'unavailableSuMorn'
    | 'unavailableSuDay'
    | 'unavailableSuNight'
  > | null;

  /**
   * User's personal data
   */
  user: Pick<User, 'id' | 'firstName' | 'middleName' | 'lastName' | 'preferredName'>;

  /**
   * Overridden permissions.
   */
  overriddenPermission?: Partial<Pick<Member, 'admin' | 'permissions'>>;

  /**
   * Temporarily change the current user's permissions
   */
  overridePermissions: React.Dispatch<
    React.SetStateAction<Partial<Pick<Member, 'admin' | 'permissions'>> | undefined>
  >;
};

const AuthContext = createContext<AuthContextType>({
  refetch: () => {},
  permissible: () => true,
  overridePermissions: () => {},
  org: null,
  user: { id: '', firstName: '' },
});

export default AuthContext;
