import { FormControl, FormControlProps } from '@material-ui/core';
import {
  KeyboardTimePicker,
  TimePicker as MuiTimePicker,
  TimePickerProps as MuiTimePickerProps,
} from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format, isValid } from 'date-fns';
import { Control, Controller } from 'react-hook-form';

type TimeInputProps = Omit<MuiTimePickerProps, 'value' | 'onChange'> & {
  formControlProps?: FormControlProps;
  control?: Control<any>;
  name: string;
  onChange?: (date: MaterialUiPickersDate, value?: string | null | undefined) => void;
  value?: ParsableDate;
  keyboard?: boolean;
};

const TimeInput = ({
  formControlProps,
  control,
  name,
  onChange,
  value,
  keyboard = false,
  ...props
}: TimeInputProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={props.defaultValue}
      render={({ field: { value: controllerValue, onChange: controllerOnChange, ...field } }) => {
        return (
          <FormControl {...formControlProps}>
            {keyboard ? (
              <KeyboardTimePicker
                autoOk
                value={value || controllerValue}
                onChange={(date: MaterialUiPickersDate) => {
                  if (isValid(date)) {
                    controllerOnChange(date);
                    !!onChange && onChange(date);
                  }
                }}
                onClick={(event) => {
                  (event.target as HTMLInputElement).select();
                }}
                id="time-picker"
                ampm={false}
                minutesStep={5}
                mask="__:__"
                KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                {...props}
                {...field}
              />
            ) : (
              <MuiTimePicker
                autoOk
                value={value || controllerValue}
                onChange={(date: MaterialUiPickersDate) => {
                  if (isValid(date)) {
                    controllerOnChange(date);
                    !!onChange && onChange(date);
                  }
                }}
                id="time-picker"
                minutesStep={5}
                labelFunc={(date) =>
                  date ? format(date, date.getMinutes() === 0 ? 'ha' : 'h:mma').toLowerCase() : ''
                }
                {...props}
                {...field}
              />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default TimeInput;
