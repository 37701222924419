import { createStyles, FormControl, makeStyles, Theme } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useRouter } from '@timed/common';
import { _schedule } from '@timed/app';
import { format, isValid, parse } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        left: theme.spacing(14),
        top: theme.spacing(2.75),
      },
      '& .MuiInput-underline:hover:not(.Nui-disabled)::before': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline::before': {
        borderBottom: 'none',
        transition: 'none',
      },
      '& .MuiInput-underline::after': {
        borderBottom: 'none',
        transition: 'none',
      },
    },
    input: {
      width: '11.75ch',
      userSelect: 'none',
      msUserSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      color: theme.palette.topBar.text,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        top: theme.spacing(0.25),
        color: 'rgba(255,255,255,0.4)',
        [theme.breakpoints.down('sm')]: {
          left: '6ch',
        },
        [theme.breakpoints.up('md')]: {
          left: '6.5ch',
        },
      },
    },
    dialog: {
      '& .MuiPickersCalendarHeader-dayLabel': {
        color: theme.palette.text.secondary,
      },
    },
  }),
);

const ScheduleControlsDateInput = () => {
  const classes = useStyles();
  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const handleChange = (date: MaterialUiPickersDate) => {
    if (!isValid(date)) return;

    const formattedDate = format(date as Date, 'ddMMyyyy');

    if (searchParams.get('f') !== formattedDate) {
      searchParams.set('f', formattedDate);
      navigate(_schedule.path + '?' + searchParams);
    }
  };

  return (
    <FormControl className={classes.datePicker}>
      <DatePicker
        showTodayButton
        disableToolbar
        autoOk
        aria-label="date picker"
        inputVariant="standard"
        size="small"
        format="dd MMM yyyy"
        id="date-picker"
        value={searchParams.get('f') && parse(searchParams.get('f')!, 'ddMMyyyy', new Date())}
        onChange={handleChange}
        inputProps={{ className: classes.input }}
        InputProps={{
          className: classes.input,
          endAdornment: <ArrowDropDownIcon />,
        }}
        DialogProps={{ className: classes.dialog }}
      />
    </FormControl>
  );
};

export default ScheduleControlsDateInput;
