import {
  Branch,
  HistoryRestorable,
  Member,
  MemberInvitation,
  MemberInvitationSender,
  PersonNamesFragment,
  RedactedMember,
  VisaType,
} from '@timed/gql';
import { createContext } from 'react';

export type MemberContextType = Pick<
  RedactedMember,
  'id' | 'firstName' | 'middleName' | 'lastName' | 'preferredName' | 'email' | 'phone' | 'color'
> &
  Partial<
    Pick<
      Member,
      | 'createdAt'
      | 'hasAccess'
      | 'lastActiveAt'
      | 'admin'
      | 'permissions'
      | 'schedulable'
      | 'gender'
      | 'salutation'
      | 'pronouns'
      | 'dob'
      | 'notes'
      | 'externalId'
      | 'unit'
      | 'street'
      | 'locality'
      | 'region'
      | 'postcode'
      | 'country'
      | 'latitude'
      | 'longitude'
      | 'workRights'
      | 'workRightsLastCheckedAt'
      | 'allowedFortnightlyWorkHours'
      | 'employmentStartDate'
      | 'basePayRate'
      | 'unavailableNotes'
      | 'unavailableMoMorn'
      | 'unavailableMoDay'
      | 'unavailableMoNight'
      | 'unavailableTuMorn'
      | 'unavailableTuDay'
      | 'unavailableTuNight'
      | 'unavailableWeMorn'
      | 'unavailableWeDay'
      | 'unavailableWeNight'
      | 'unavailableThMorn'
      | 'unavailableThDay'
      | 'unavailableThNight'
      | 'unavailableFrMorn'
      | 'unavailableFrDay'
      | 'unavailableFrNight'
      | 'unavailableSaMorn'
      | 'unavailableSaDay'
      | 'unavailableSaNight'
      | 'unavailableSuMorn'
      | 'unavailableSuDay'
      | 'unavailableSuNight'
    >
  > & {
    visaType?: Pick<VisaType, 'id' | 'name'> | null;
  } & {
    archive?:
      | (Pick<HistoryRestorable, 'id' | 'date' | 'reason'> & {
          actor?: Pick<
            Member,
            'id' | 'firstName' | 'middleName' | 'lastName' | 'preferredName'
          > | null;
        })
      | null;
  } & {
    delete?:
      | (Pick<HistoryRestorable, 'id' | 'date' | 'reason'> & {
          actor?: Pick<
            Member,
            'id' | 'firstName' | 'middleName' | 'lastName' | 'preferredName'
          > | null;
        })
      | null;
  } & { createdBy?: (Pick<Member, 'id'> & PersonNamesFragment) | null } & {
    branches?: Array<Pick<Branch, 'id' | 'name'>> | null;
  } & {
    invitationMostRecentlyReceived?:
      | (Pick<MemberInvitation, 'id' | 'deletedAt' | 'usedAt' | 'sentCount'> & {
          lastSender: Pick<MemberInvitationSender, 'sentAt'> & {
            sentBy: Pick<Member, 'id' | 'firstName' | 'lastName'>;
          };
        })
      | null;
  };

const MemberContext = createContext<MemberContextType>({
  id: '',
  firstName: '',
  admin: false,
});

export default MemberContext;
