import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { capitaliseFirstLetter, IconButton, Select, SelectProps } from '@timed/common';
import { MemberWorkRights } from '@timed/gql';
import { validateWorkRights } from '@timed/member';
import { UseFormSetValue } from 'react-hook-form';

type MemberWorkRightsInputProps = Omit<SelectProps, 'items'> & {
  setValue: UseFormSetValue<any>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      alignItems: 'center',
      height: 20,
      gap: theme.spacing(2),
    },
    label: {
      fontSize: '90%',
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    control: {
      flex: '0 0 auto',
    },
  }),
);

const MemberWorkRightsInput = ({
  defaultValue,
  setValue,
  name,
  ...props
}: MemberWorkRightsInputProps) => {
  const classes = useStyles();

  const handleClear = () => {
    setValue(name, null);
  };

  return (
    <Select
      name={name}
      formControlProps={{ size: 'small', variant: 'outlined' }}
      validation={validateWorkRights()}
      items={Object.values(MemberWorkRights)
        .map((name) => ({ value: name, label: capitaliseFirstLetter(name.toLowerCase()) }))
        .sort((a, b) => (a.label > b.label ? 1 : -1))}
      renderValue={(value) => (
        <div className={classes.content}>
          <Typography variant="body1" className={classes.label}>
            {capitaliseFirstLetter((value as string).toLowerCase())}
          </Typography>
          <IconButton
            size="small"
            className={classes.control}
            onClick={handleClear}
            onMouseDown={(event) => event.stopPropagation()}
          >
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </div>
      )}
      {...props}
    />
  );
};

export default MemberWorkRightsInput;
