import { parse } from 'date-fns';

/**
 * Format a date-only date as an age in years.
 */
export const formatAge = (dob: string): number => {
  const today = new Date();
  const birthDate = parse(dob, 'yyyy-MM-dd', new Date());
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};
