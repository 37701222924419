import { Box, createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      backgroundColor: theme.palette.topBar.main,
      display: 'flex',
      width: theme.spacing(16),
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

const DrawerLogo = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.logo}>
      <img src={'/' + theme.palette.logo.src} alt="Timed Logo" />
    </Box>
  );
};

export default DrawerLogo;
