import { _settingsOrganisationPayroll } from '@timed/app';
import { Page } from '@timed/page';
import { SettingsOrganisationPayrollForm } from '@timed/settings';

const SettingsOrganisationPayroll = () => {
  return (
    <Page {..._settingsOrganisationPayroll}>
      <SettingsOrganisationPayrollForm />
    </Page>
  );
};

export default SettingsOrganisationPayroll;
