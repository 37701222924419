import { TextField, TextFieldProps } from '@timed/common';
import { abnRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validateAbn: RegisterOptions = {
  pattern: { value: abnRegex, message: 'Invalid ABN' },
};

const AbnInput = (props: TextFieldProps) => (
  <TextField label="ABN" type="text" validation={validateAbn} {...props} />
);

export default AbnInput;
