import { roundNumber } from '@timed/common';
import { GetActivitySlipsQuery, GetPublicHolidaysQuery, Org } from '@timed/gql';
import { calculatePayrollCategory, PaySlip } from '@timed/report';
import { formatEventAsShifts } from '@timed/report/helpers/formatEventAsShifts';
import { differenceInMinutes, format } from 'date-fns';

enum PayrollItemDescription {
  WEEKDAY_DAY = 'Weekday Day',
  WEEKDAY_AFTERNOON = 'Weekday Afternoon',
  WEEKDAY_NIGHT = 'Weekday Night',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
  PUBLIC_HOLIDAY = 'Public Holiday',
  OVERNIGHT_ALLOWANCE = 'Overnight Allowance',
  KM_ALLOWANCE = 'KM Allowance',
}

type GenerateFn = {
  after: Date;
  before: Date;
  events: GetActivitySlipsQuery['events'];
  publicHolidays: GetPublicHolidaysQuery['publicHolidays'];
  basePayRate: number;
  payRates: Pick<
    Org,
    | 'afternoonPayMultiplier'
    | 'nightPayMultiplier'
    | 'satPayMultiplier'
    | 'sunPayMultiplier'
    | 'pubPayMultiplier'
    | 'passiveAllowance'
    | 'kmAllowance'
    | 'bonus2PayRate'
    | 'bonus3PayRate'
    | 'bonus4PayRate'
    | 'bonus5PayRate'
    | 'bonus6PayRate'
    | 'bonus7PayRate'
    | 'bonus8PayRate'
    | 'bonus9PayRate'
    | 'bonus10PayRate'
    | 'bonus11PayRate'
    | 'bonus12PayRate'
  >;
};

export const generatePaySlip = ({
  events,
  publicHolidays,
  after,
  before,
  basePayRate,
  payRates,
}: GenerateFn) => {
  const payrollEvents = formatEventAsShifts({ events, publicHolidays, include: { after, before } });

  const items: PaySlip[] = [];

  payrollEvents.forEach((event) => {
    if (!!event.original.travelDistance) {
      items.push({
        category: 'Km allowance',
        description: 'KM Allowance',
        hours: event.original.travelDistance / 1000,
        rate: (payRates.kmAllowance ?? 0) / 100,
        pay: (event.original.travelDistance / 1000) * ((payRates.kmAllowance ?? 0) / 100),
        type: 'Wages',
        event: event.original,
      });
    }

    event.shifts.forEach((shift) => {
      let description: string = PayrollItemDescription.WEEKDAY_DAY;
      let rate: number = 0;
      let hours: number = 0;
      let pay: number = 0;

      const category = calculatePayrollCategory({
        eventStart: event.original.startAt,
        eventEnd: event.original.endAt,
        shiftStart: shift.startAt,
        shiftEnd: shift.endAt,
        passive: !!shift.passive,
        publicHoliday: event.original.publicHoliday,
        memberBonusEligible: event.original.member!.bonusEligible,
      });

      switch (category) {
        case 'overnight allowance':
        case 'overnight allowance 2':
          description = PayrollItemDescription.OVERNIGHT_ALLOWANCE;
          rate = (payRates.passiveAllowance ?? 0) / 100;
          pay = (payRates.passiveAllowance ?? 0) / 100;
          hours = 1;
          break;

        case 'Base Hourly':
          description = PayrollItemDescription.WEEKDAY_DAY;
          rate = basePayRate / 100;
          break;

        case 'L1P1 Afternoon':
          description = PayrollItemDescription.WEEKDAY_AFTERNOON;
          rate = (basePayRate * ((payRates.afternoonPayMultiplier ?? 1) / 100)) / 100;
          break;

        case 'L1P1 Night':
          description = PayrollItemDescription.WEEKDAY_NIGHT;
          rate = (basePayRate * ((payRates.nightPayMultiplier ?? 1) / 100)) / 100;
          break;

        case 'L1P1 Sat':
          description = PayrollItemDescription.SATURDAY;
          rate = (basePayRate * ((payRates.satPayMultiplier ?? 1) / 100)) / 100;
          break;

        case 'L1P1 Sun':
          description = PayrollItemDescription.SUNDAY;
          rate = (basePayRate * ((payRates.sunPayMultiplier ?? 1) / 100)) / 100;
          break;

        case 'L1P1 Pub':
          description = PayrollItemDescription.PUBLIC_HOLIDAY;
          rate = (basePayRate * ((payRates.pubPayMultiplier ?? 1) / 100)) / 100;
          break;

        case 'L1P1 WKD BONUS 2':
          description = PayrollItemDescription.WEEKDAY_DAY;
          rate = basePayRate / 100 + (payRates.bonus2PayRate ?? 1) / 100;
          break;

        case 'L1P1 WKD BONUS 3':
          description = PayrollItemDescription.WEEKDAY_DAY;
          rate = basePayRate / 100 + (payRates.bonus3PayRate ?? 1) / 100;
          break;

        case 'L1P1 WKD BONUS 4':
          description = PayrollItemDescription.WEEKDAY_DAY;
          rate = basePayRate / 100 + (payRates.bonus4PayRate ?? 1) / 100;
          break;

        case 'L1P1 WKD BONUS 5':
          description = PayrollItemDescription.WEEKDAY_DAY;
          rate = basePayRate / 100 + (payRates.bonus5PayRate ?? 1) / 100;
          break;

        case 'L1P1 WKD BONUS 6':
          description = PayrollItemDescription.WEEKDAY_DAY;
          rate = basePayRate / 100 + (payRates.bonus6PayRate ?? 1) / 100;
          break;

        case 'L1P1 WKD BONUS 7':
          description = PayrollItemDescription.WEEKDAY_DAY;
          rate = basePayRate / 100 + (payRates.bonus7PayRate ?? 1) / 100;
          break;

        case 'L1P1 WKD BONUS 8':
          description = PayrollItemDescription.WEEKDAY_DAY;
          rate = basePayRate / 100 + (payRates.bonus8PayRate ?? 1) / 100;
          break;

        case 'L1P1 WKD BONUS 9':
          description = PayrollItemDescription.WEEKDAY_DAY;
          rate = basePayRate / 100 + (payRates.bonus9PayRate ?? 1) / 100;
          break;

        case 'L1P1 WKD BONUS 10':
          description = PayrollItemDescription.WEEKDAY_DAY;
          rate = basePayRate / 100 + (payRates.bonus10PayRate ?? 1) / 100;
          break;

        case 'L1P1 WKD BONUS 11':
          description = PayrollItemDescription.WEEKDAY_DAY;
          rate = basePayRate / 100 + (payRates.bonus11PayRate ?? 1) / 100;
          break;

        case 'L1P1 WKD BONUS 12':
          description = PayrollItemDescription.WEEKDAY_DAY;
          rate = basePayRate / 100 + (payRates.bonus12PayRate ?? 1) / 100;
          break;

        case 'L1P1 AFT BONUS 2':
          description = PayrollItemDescription.WEEKDAY_AFTERNOON;
          rate =
            (basePayRate / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100) +
            ((payRates.bonus2PayRate ?? 1) / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 AFT BONUS 3':
          description = PayrollItemDescription.WEEKDAY_AFTERNOON;
          rate =
            (basePayRate / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100) +
            ((payRates.bonus3PayRate ?? 1) / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 AFT BONUS 4':
          description = PayrollItemDescription.WEEKDAY_AFTERNOON;
          rate =
            (basePayRate / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100) +
            ((payRates.bonus4PayRate ?? 1) / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 AFT BONUS 5':
          description = PayrollItemDescription.WEEKDAY_AFTERNOON;
          rate =
            (basePayRate / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100) +
            ((payRates.bonus5PayRate ?? 1) / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 AFT BONUS 6':
          description = PayrollItemDescription.WEEKDAY_AFTERNOON;
          rate =
            (basePayRate / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100) +
            ((payRates.bonus6PayRate ?? 1) / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 AFT BONUS 7':
          description = PayrollItemDescription.WEEKDAY_AFTERNOON;
          rate =
            (basePayRate / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100) +
            ((payRates.bonus7PayRate ?? 1) / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 AFT BONUS 8':
          description = PayrollItemDescription.WEEKDAY_AFTERNOON;
          rate =
            (basePayRate / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100) +
            ((payRates.bonus8PayRate ?? 1) / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 AFT BONUS 9':
          description = PayrollItemDescription.WEEKDAY_AFTERNOON;
          rate =
            (basePayRate / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100) +
            ((payRates.bonus9PayRate ?? 1) / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 AFT BONUS 10':
          description = PayrollItemDescription.WEEKDAY_AFTERNOON;
          rate =
            (basePayRate / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100) +
            ((payRates.bonus10PayRate ?? 1) / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 AFT BONUS 11':
          description = PayrollItemDescription.WEEKDAY_AFTERNOON;
          rate =
            (basePayRate / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100) +
            ((payRates.bonus11PayRate ?? 1) / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 AFT BONUS 12':
          description = PayrollItemDescription.WEEKDAY_AFTERNOON;
          rate =
            (basePayRate / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100) +
            ((payRates.bonus12PayRate ?? 1) / 100) * ((payRates.afternoonPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 NHT BONUS 2':
          description = PayrollItemDescription.WEEKDAY_NIGHT;
          rate =
            (basePayRate / 100) * ((payRates.nightPayMultiplier ?? 1) / 100) +
            ((payRates.bonus2PayRate ?? 1) / 100) * ((payRates.nightPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 NHT BONUS 3':
          description = PayrollItemDescription.WEEKDAY_NIGHT;
          rate =
            (basePayRate / 100) * ((payRates.nightPayMultiplier ?? 1) / 100) +
            ((payRates.bonus3PayRate ?? 1) / 100) * ((payRates.nightPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 NHT BONUS 4':
          description = PayrollItemDescription.WEEKDAY_NIGHT;
          rate =
            (basePayRate / 100) * ((payRates.nightPayMultiplier ?? 1) / 100) +
            ((payRates.bonus4PayRate ?? 1) / 100) * ((payRates.nightPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 NHT BONUS 5':
          description = PayrollItemDescription.WEEKDAY_NIGHT;
          rate =
            (basePayRate / 100) * ((payRates.nightPayMultiplier ?? 1) / 100) +
            ((payRates.bonus5PayRate ?? 1) / 100) * ((payRates.nightPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 NHT BONUS 6':
          description = PayrollItemDescription.WEEKDAY_NIGHT;
          rate =
            (basePayRate / 100) * ((payRates.nightPayMultiplier ?? 1) / 100) +
            ((payRates.bonus6PayRate ?? 1) / 100) * ((payRates.nightPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 NHT BONUS 7':
          description = PayrollItemDescription.WEEKDAY_NIGHT;
          rate =
            (basePayRate / 100) * ((payRates.nightPayMultiplier ?? 1) / 100) +
            ((payRates.bonus7PayRate ?? 1) / 100) * ((payRates.nightPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 NHT BONUS 8':
          description = PayrollItemDescription.WEEKDAY_NIGHT;
          rate =
            (basePayRate / 100) * ((payRates.nightPayMultiplier ?? 1) / 100) +
            ((payRates.bonus8PayRate ?? 1) / 100) * ((payRates.nightPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 NHT BONUS 9':
          description = PayrollItemDescription.WEEKDAY_NIGHT;
          rate =
            (basePayRate / 100) * ((payRates.nightPayMultiplier ?? 1) / 100) +
            ((payRates.bonus9PayRate ?? 1) / 100) * ((payRates.nightPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 NHT BONUS 10':
          description = PayrollItemDescription.WEEKDAY_NIGHT;
          rate =
            (basePayRate / 100) * ((payRates.nightPayMultiplier ?? 1) / 100) +
            ((payRates.bonus10PayRate ?? 1) / 100) * ((payRates.nightPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 NHT BONUS 11':
          description = PayrollItemDescription.WEEKDAY_NIGHT;
          rate =
            (basePayRate / 100) * ((payRates.nightPayMultiplier ?? 1) / 100) +
            ((payRates.bonus11PayRate ?? 1) / 100) * ((payRates.nightPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 NHT BONUS 12':
          description = PayrollItemDescription.WEEKDAY_NIGHT;
          rate =
            (basePayRate / 100) * ((payRates.nightPayMultiplier ?? 1) / 100) +
            ((payRates.bonus12PayRate ?? 1) / 100) * ((payRates.nightPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SAT BONUS 2':
          description = PayrollItemDescription.SATURDAY;
          rate =
            (basePayRate / 100) * ((payRates.satPayMultiplier ?? 1) / 100) +
            ((payRates.bonus2PayRate ?? 1) / 100) * ((payRates.satPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SAT BONUS 3':
          description = PayrollItemDescription.SATURDAY;
          rate =
            (basePayRate / 100) * ((payRates.satPayMultiplier ?? 1) / 100) +
            ((payRates.bonus3PayRate ?? 1) / 100) * ((payRates.satPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SAT BONUS 4':
          description = PayrollItemDescription.SATURDAY;
          rate =
            (basePayRate / 100) * ((payRates.satPayMultiplier ?? 1) / 100) +
            ((payRates.bonus4PayRate ?? 1) / 100) * ((payRates.satPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SAT BONUS 5':
          description = PayrollItemDescription.SATURDAY;
          rate =
            (basePayRate / 100) * ((payRates.satPayMultiplier ?? 1) / 100) +
            ((payRates.bonus5PayRate ?? 1) / 100) * ((payRates.satPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SAT BONUS 6':
          description = PayrollItemDescription.SATURDAY;
          rate =
            (basePayRate / 100) * ((payRates.satPayMultiplier ?? 1) / 100) +
            ((payRates.bonus6PayRate ?? 1) / 100) * ((payRates.satPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SAT BONUS 7':
          description = PayrollItemDescription.SATURDAY;
          rate =
            (basePayRate / 100) * ((payRates.satPayMultiplier ?? 1) / 100) +
            ((payRates.bonus7PayRate ?? 1) / 100) * ((payRates.satPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SAT BONUS 8':
          description = PayrollItemDescription.SATURDAY;
          rate =
            (basePayRate / 100) * ((payRates.satPayMultiplier ?? 1) / 100) +
            ((payRates.bonus8PayRate ?? 1) / 100) * ((payRates.satPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SAT BONUS 9':
          description = PayrollItemDescription.SATURDAY;
          rate =
            (basePayRate / 100) * ((payRates.satPayMultiplier ?? 1) / 100) +
            ((payRates.bonus9PayRate ?? 1) / 100) * ((payRates.satPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SAT BONUS 10':
          description = PayrollItemDescription.SATURDAY;
          rate =
            (basePayRate / 100) * ((payRates.satPayMultiplier ?? 1) / 100) +
            ((payRates.bonus10PayRate ?? 1) / 100) * ((payRates.satPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SAT BONUS 11':
          description = PayrollItemDescription.SATURDAY;
          rate =
            (basePayRate / 100) * ((payRates.satPayMultiplier ?? 1) / 100) +
            ((payRates.bonus11PayRate ?? 1) / 100) * ((payRates.satPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SAT BONUS 12':
          description = PayrollItemDescription.SATURDAY;
          rate =
            (basePayRate / 100) * ((payRates.satPayMultiplier ?? 1) / 100) +
            ((payRates.bonus12PayRate ?? 1) / 100) * ((payRates.satPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SUN BONUS 2':
          description = PayrollItemDescription.SUNDAY;
          rate =
            (basePayRate / 100) * ((payRates.sunPayMultiplier ?? 1) / 100) +
            ((payRates.bonus2PayRate ?? 1) / 100) * ((payRates.sunPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SUN BONUS 3':
          description = PayrollItemDescription.SUNDAY;
          rate =
            (basePayRate / 100) * ((payRates.sunPayMultiplier ?? 1) / 100) +
            ((payRates.bonus3PayRate ?? 1) / 100) * ((payRates.sunPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SUN BONUS 4':
          description = PayrollItemDescription.SUNDAY;
          rate =
            (basePayRate / 100) * ((payRates.sunPayMultiplier ?? 1) / 100) +
            ((payRates.bonus4PayRate ?? 1) / 100) * ((payRates.sunPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SUN BONUS 5':
          description = PayrollItemDescription.SUNDAY;
          rate =
            (basePayRate / 100) * ((payRates.sunPayMultiplier ?? 1) / 100) +
            ((payRates.bonus5PayRate ?? 1) / 100) * ((payRates.sunPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SUN BONUS 6':
          description = PayrollItemDescription.SUNDAY;
          rate =
            (basePayRate / 100) * ((payRates.sunPayMultiplier ?? 1) / 100) +
            ((payRates.bonus6PayRate ?? 1) / 100) * ((payRates.sunPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SUN BONUS 7':
          description = PayrollItemDescription.SUNDAY;
          rate =
            (basePayRate / 100) * ((payRates.sunPayMultiplier ?? 1) / 100) +
            ((payRates.bonus7PayRate ?? 1) / 100) * ((payRates.sunPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SUN BONUS 8':
          description = PayrollItemDescription.SUNDAY;
          rate =
            (basePayRate / 100) * ((payRates.sunPayMultiplier ?? 1) / 100) +
            ((payRates.bonus8PayRate ?? 1) / 100) * ((payRates.sunPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SUN BONUS 9':
          description = PayrollItemDescription.SUNDAY;
          rate =
            (basePayRate / 100) * ((payRates.sunPayMultiplier ?? 1) / 100) +
            ((payRates.bonus9PayRate ?? 1) / 100) * ((payRates.sunPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SUN BONUS 10':
          description = PayrollItemDescription.SUNDAY;
          rate =
            (basePayRate / 100) * ((payRates.sunPayMultiplier ?? 1) / 100) +
            ((payRates.bonus10PayRate ?? 1) / 100) * ((payRates.sunPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SUN BONUS 11':
          description = PayrollItemDescription.SUNDAY;
          rate =
            (basePayRate / 100) * ((payRates.sunPayMultiplier ?? 1) / 100) +
            ((payRates.bonus11PayRate ?? 1) / 100) * ((payRates.sunPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 SUN BONUS 12':
          description = PayrollItemDescription.SUNDAY;
          rate =
            (basePayRate / 100) * ((payRates.sunPayMultiplier ?? 1) / 100) +
            ((payRates.bonus12PayRate ?? 1) / 100) * ((payRates.sunPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 PUB BONUS 2':
          description = PayrollItemDescription.PUBLIC_HOLIDAY;
          rate =
            (basePayRate / 100) * ((payRates.pubPayMultiplier ?? 1) / 100) +
            ((payRates.bonus2PayRate ?? 1) / 100) * ((payRates.pubPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 PUB BONUS 3':
          description = PayrollItemDescription.PUBLIC_HOLIDAY;
          rate =
            (basePayRate / 100) * ((payRates.pubPayMultiplier ?? 1) / 100) +
            ((payRates.bonus3PayRate ?? 1) / 100) * ((payRates.pubPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 PUB BONUS 4':
          description = PayrollItemDescription.PUBLIC_HOLIDAY;
          rate =
            (basePayRate / 100) * ((payRates.pubPayMultiplier ?? 1) / 100) +
            ((payRates.bonus4PayRate ?? 1) / 100) * ((payRates.pubPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 PUB BONUS 5':
          description = PayrollItemDescription.PUBLIC_HOLIDAY;
          rate =
            (basePayRate / 100) * ((payRates.pubPayMultiplier ?? 1) / 100) +
            ((payRates.bonus5PayRate ?? 1) / 100) * ((payRates.pubPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 PUB BONUS 6':
          description = PayrollItemDescription.PUBLIC_HOLIDAY;
          rate =
            (basePayRate / 100) * ((payRates.pubPayMultiplier ?? 1) / 100) +
            ((payRates.bonus6PayRate ?? 1) / 100) * ((payRates.pubPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 PUB BONUS 7':
          description = PayrollItemDescription.PUBLIC_HOLIDAY;
          rate =
            (basePayRate / 100) * ((payRates.pubPayMultiplier ?? 1) / 100) +
            ((payRates.bonus7PayRate ?? 1) / 100) * ((payRates.pubPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 PUB BONUS 8':
          description = PayrollItemDescription.PUBLIC_HOLIDAY;
          rate =
            (basePayRate / 100) * ((payRates.pubPayMultiplier ?? 1) / 100) +
            ((payRates.bonus8PayRate ?? 1) / 100) * ((payRates.pubPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 PUB BONUS 9':
          description = PayrollItemDescription.PUBLIC_HOLIDAY;
          rate =
            (basePayRate / 100) * ((payRates.pubPayMultiplier ?? 1) / 100) +
            ((payRates.bonus9PayRate ?? 1) / 100) * ((payRates.pubPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 PUB BONUS 10':
          description = PayrollItemDescription.PUBLIC_HOLIDAY;
          rate =
            (basePayRate / 100) * ((payRates.pubPayMultiplier ?? 1) / 100) +
            ((payRates.bonus10PayRate ?? 1) / 100) * ((payRates.pubPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 PUB BONUS 11':
          description = PayrollItemDescription.PUBLIC_HOLIDAY;
          rate =
            (basePayRate / 100) * ((payRates.pubPayMultiplier ?? 1) / 100) +
            ((payRates.bonus11PayRate ?? 1) / 100) * ((payRates.pubPayMultiplier ?? 1) / 100);
          break;

        case 'L1P1 PUB BONUS 12':
          description = PayrollItemDescription.PUBLIC_HOLIDAY;
          rate =
            (basePayRate / 100) * ((payRates.pubPayMultiplier ?? 1) / 100) +
            ((payRates.bonus12PayRate ?? 1) / 100) * ((payRates.pubPayMultiplier ?? 1) / 100);
          break;
      }

      description = format(shift.startAt, 'dd/MM') + ' ' + description;

      if (!!event.original.travelTime && !(shift.passive && shift.activeAssist)) {
        items.push({
          category,
          description: description + ' Travel',
          hours: roundNumber(event.original.travelTime / 60, 2),
          rate,
          pay: rate * roundNumber(event.original.travelTime / 60, 2),
          type: 'Wages',
          event: { endAt: shift.endAt, startAt: shift.startAt },
        });
      }

      if (!shift.passive) {
        hours = differenceInMinutes(shift.endAt, shift.startAt) / 60;
        pay = rate * hours;
      }

      // Push shift
      items.push({
        category,
        description,
        hours,
        rate,
        pay,
        type: 'Wages',
        event: { endAt: shift.endAt, startAt: shift.startAt },
      });
    });
  });

  const superRate = 10.5 / 100;

  const grossPay = roundNumber(
    items.map(({ pay }) => pay).reduce((a, b) => a + b, 0),
    2,
  );

  items.push({
    category: 'Super Expenses',
    description: 'Superannuation Guarantee',
    pay: grossPay * superRate,
    type: 'Super Expenses',
  });

  return items;
};
