import {
  Box,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Copyright, Logo } from '@timed/common';
import { UserLoginForm } from '@timed/user';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    copyright: {
      position: 'absolute',
      alignSelf: 'flex-end',
      marginBottom: theme.spacing(4),
    },
  }),
);

const SignIn = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <Grid container item xs={12} alignItems="center" justify="center">
        <Box className={classes.paper}>
          <Logo height="60px" />
          <Typography>{process.env.REACT_APP_VERSION}</Typography>
          <Typography component="h1" variant="h1">
            Sign In
          </Typography>
          <UserLoginForm />
        </Box>
        <Copyright className={classes.copyright} />
      </Grid>
    </Container>
  );
};

export default SignIn;
