import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Protected } from '@timed/auth';
import { ClientAllowMedicationCheckbox, ClientContext } from '@timed/client';
import {
  addServerErrors,
  Block,
  IconButtonMulti,
  intersperse,
  Select,
  SelectMenuItem,
} from '@timed/common';
import {
  OrderBy,
  Permission,
  QueryByIdsInput,
  useGetClientMedicationsQuery,
  useGetMedicationsQuery,
  useSetClientMedicationsMutation,
  useUpdateClientsMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { isEqual } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      gap: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    form: {
      display: 'flex',
      gap: theme.spacing(4),
      alignItems: 'start',
      flexDirection: 'column',
    },
    button: {
      width: 'max-content',
    },

    grid: {
      width: 'max-content',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      display: 'inline-grid',
      gridTemplateColumns:
        'max-content max-content max-content max-content max-content max-content max-content max-content max-content auto',
      columnGap: theme.spacing(4),
      rowGap: theme.spacing(0.25),
      '& .MuiTypography-root': {
        fontSize: 14,
        fontFamily: 'monospace',
        cursor: 'pointer',
      },
    },

    heading: {
      alignSelf: 'start',
      paddingBottom: theme.spacing(4),
      fontWeight: theme.typography.fontWeightMedium,
    },

    headingRotated: {
      transform: 'rotate(-45deg)',
    },
  }),
);

type FormData = {
  patch: {
    allowMedication?: boolean;
    medication?: QueryByIdsInput | null;
  };
};

const ClientViewMedications = () => {
  const classes = useStyles();

  const client = useContext(ClientContext);

  const medications = useGetMedicationsQuery({
    variables: {
      input: {
        orderBy: [{ name: OrderBy.ASC }],
      },
    },
  });

  const clientMedications = useGetClientMedicationsQuery({
    variables: {
      id: client.id,
      input: {
        orderBy: [{ medication: { name: OrderBy.ASC } }],
      },
    },
  });

  const [setMediations, setResponse] = useSetClientMedicationsMutation();

  const menuItems = useMemo<SelectMenuItem[]>(() => {
    return medications.data
      ? medications.data.medications.map((med) => ({ value: med.id, label: med.name }))
      : [];
  }, [medications.data]);

  const [editing, setEditing] = useState<boolean>(false);

  const defaultValues: FormData = {
    patch: {
      allowMedication: client.allowMedication,
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  const [updateClients, response] = useUpdateClientsMutation();

  const onSubmit = ({ patch: { medication, allowMedication } }: FormData) => {
    updateClients({
      variables: {
        input: {
          ids: [client.id],
          patch: { allowMedication },
        },
      },
    }).catch((e) => {});

    if (!!medication)
      setMediations({
        variables: {
          input: {
            medication: { ids: [...new Set(medication.ids)] },
            client: { id: client.id },
          },
        },
      }).catch((e) => {});

    reset({ patch: { allowMedication, medication } });
  };

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  useLoadingEffect(response.loading);

  return (
    <Box className={classes.wrapper}>
      <Protected permissions={Permission.CLIENT_WRITE}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Block
            inline
            title="Medication Settings"
            topRight={
              <IconButtonMulti
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            }
          >
            <Box className={classes.form}>
              <Box>
                <Typography>Allow recording of medication</Typography>
                <ClientAllowMedicationCheckbox
                  name="patch.allowMedication"
                  label={undefined}
                  defaultChecked={client.allowMedication}
                  control={control}
                  disabled={!editing}
                  error={!!errors.patch?.allowMedication}
                  helperText={errors.patch?.allowMedication?.message}
                  formControlProps={{ size: 'small', variant: 'outlined' }}
                />
              </Box>
              <Box>
                <Typography className={classes.bold}>Medication</Typography>
                {!!clientMedications.data ? (
                  <Select
                    multiple
                    name="patch.medication.ids"
                    items={menuItems}
                    label={undefined}
                    defaultValue={clientMedications.data.clientById.medications?.map(
                      ({ medication: { id } }) => id,
                    )}
                    control={control}
                    disabled={!editing}
                    formControlProps={{ size: 'small', variant: 'outlined' }}
                    renderValue={(value) =>
                      !clientMedications.data?.clientById.medications
                        ? 'None selected.'
                        : intersperse({
                            array: clientMedications.data?.clientById.medications
                              .filter(({ medication: { id } }) => (value as string[]).includes(id))
                              ?.map(({ medication: { name } }) => <Typography>{name}</Typography>),
                            sep: null,
                            and: false,
                          })
                    }
                  />
                ) : (
                  'Loading...'
                )}
              </Box>
            </Box>
          </Block>
        </form>
      </Protected>
    </Box>
  );
};

export default ClientViewMedications;
