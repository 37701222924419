import { AuthContext } from '@timed/auth';
import { isPermissible } from '@timed/auth/helpers';
import { useRouter } from '@timed/common';
import { Member, Permission, useGetMeQuery } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import _ from 'lodash';
import { useCallback, useState } from 'react';

const AuthProvider: React.FC = ({ children }) => {
  const { navigate, pathname } = useRouter();

  const redirectToLogin = 'sign-in' + (pathname === '/' ? '' : '?ref=' + pathname);

  const { data, loading, refetch } = useGetMeQuery({
    onError: () => navigate(redirectToLogin), // Navigate to user login-in route
    onCompleted: (data) => !data.me.member && navigate('/setup'), // Navigate to org-setup route
  });

  const [overriddenPermission, overridePermissions] =
    useState<Partial<Pick<Member, 'admin' | 'permissions'>>>();

  useLoadingEffect(loading);

  const permissible = useCallback(
    ({
      admin,
      permissions,
    }: {
      admin?: boolean;
      permissions?: Permission | Permission[];
    }): boolean =>
      overriddenPermission?.admin ||
      !!overriddenPermission?.permissions?.length ||
      admin ||
      permissions
        ? isPermissible({
            member: {
              admin:
                overriddenPermission?.admin ??
                data?.me.members.find((m) => m.id === data.me.member?.id)?.admin ??
                false,
              permissions: overriddenPermission?.permissions?.length
                ? overriddenPermission.permissions
                : data?.me.member?.permissions,
            },
            admin,
            permissions,
          })
        : true,
    [data?.me.member?.id, data?.me.member?.permissions, data?.me.members, overriddenPermission],
  );

  return !data ? null : (
    <AuthContext.Provider
      value={{
        refetch,
        permissible,
        overridePermissions,
        overriddenPermission,
        user: _.pick(data.me, ['id', 'firstName', 'middleName', 'lastName', 'preferredName']),
        org:
          !!data.me.member && data.me.members.find((m) => m.id === data.me.member?.id)
            ? {
                ...data.me.member.org,
                ...data.me.members.find((m) => m.id === data.me.member?.id)!.org,
              }
            : undefined,
        member: {
          ..._.omit(data.me.member!, 'permissions'),
          admin: data.me.members.find((m) => m.id === data.me.member!.id)?.admin ?? false,
          permissions: data.me.member?.permissions ?? [],
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
