import {
  createStyles,
  FormControl,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { _schedule } from '@timed/app';
import { formatPersonName, Select, useRouter, validateUuid } from '@timed/common';
import { OrderBy, useGetMembersSelectQuery } from '@timed/gql';
import { getProfile, setProfile } from '@timed/schedule';
import { ChangeEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.background.default,
      },
    },
    input: {
      backgroundColor: theme.palette.background.default,
      // minWidth: theme.spacing(50),
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      height: 20,
    },
    name: {
      fontSize: '90%',
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    control: {
      flex: '0 0 auto',
    },
  }),
);

const ScheduleControlsMemberInput = () => {
  const classes = useStyles();

  const { data, loading } = useGetMembersSelectQuery({
    variables: {
      input: {
        where: { schedulable: { _eq: true } },
        orderBy: [{ lastName: OrderBy.ASC }, { firstName: OrderBy.ASC }],
      },
    },
  });

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const { control, reset, setValue } = useForm<{ m: string }>({
    defaultValues: { m: getProfile('member', searchParams) || '' },
  });

  const members = data?.members;

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    // Unselect select field
    setTimeout(() => (document.activeElement as HTMLElement).blur(), 0);

    setProfile('member', searchParams, event.target.value as string);

    navigate(_schedule.path + '?' + searchParams);
  };

  /**
   * Delete 'm' (member id) from search params
   */
  const handleClear = () => {
    reset({ m: '' });
    setProfile('member', searchParams);
    navigate(_schedule.path + '?' + searchParams);
  };

  useEffect(() => {
    // Apply update to all visible MemberInput fields.
    setValue('m', searchParams.get('m') || '');
  }, [setValue, searchParams]);

  return loading ? (
    <FormControl className={classes.loading}>
      <TextField disabled label="Employee" variant="outlined" size="small" value="Loading..." />
    </FormControl>
  ) : (
    <Select
      name="m"
      label="Employee"
      control={control}
      validation={validateUuid}
      onChange={handleChange}
      className={classes.input}
      formControlProps={{
        variant: 'outlined',
        size: 'small',
        disabled: loading || !members,
      }}
      items={members?.map((member) => ({
        value: member.id,
        label: formatPersonName(member, { capitaliseLastName: true, lastNameFirst: true })!,
      }))}
      renderValue={(value) =>
        loading ? (
          <Typography variant="body2">Loading...</Typography>
        ) : !members?.length ? (
          'None found'
        ) : (
          <div className={classes.content}>
            <Typography variant="body1" className={classes.name}>
              {formatPersonName(members?.find((employee) => employee.id === value)!, {
                lastNameFirst: true,
                capitaliseLastName: true,
              })}
            </Typography>
            <IconButton
              size="small"
              className={classes.control}
              onClick={handleClear}
              onMouseDown={(event) => event.stopPropagation()}
            >
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </div>
        )
      }
    />
  );
};

export default ScheduleControlsMemberInput;
