import {
  Box,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { blue, lightBlue, red, yellow } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { _peopleClients, _peopleMembers } from '@timed/app';
import { AuthContextType, useAuth } from '@timed/auth';
import {
  ClientCreateBowelMovementFormModal,
  ClientCreateFoodButton,
  ClientCreateMedicationFormModal,
  ClientCreateObservationFormModal,
  ClientFormattedObservation,
  ClientUpdateBowelMovementFormModal,
  ClientUpdateObservationFormModal,
} from '@timed/client';
import {
  Avatar,
  Block,
  Button,
  Clock,
  DataSize,
  formatAddress,
  formatPersonName,
  formatTimeDuration,
  intersperse,
  Link,
  Modal,
  ModalProps,
  roundNumber,
} from '@timed/common';
import {
  EventAssignAttendeeAutomaticallyButton,
  EventCantAttendBox,
  EventClockButton,
  EventCreateFileFormModal,
  eventFilesMetadata,
  EventSummaryModalMenuButton,
  EventUpdateCaseNotesFormModal,
  EventUpdateFormModal,
  EventUpdateTravelFormModal,
} from '@timed/event';
import {
  Client,
  ClientObservation,
  Event,
  GetEventByIdQuery,
  GetEventOwnAndRelatedByIdQuery,
  Member,
  OrderBy,
  Permission,
  useDownloadEventFileByIdLazyQuery,
  useGetClientEnergyIntakeLazyQuery,
  useGetClientObservationsLazyQuery,
  useGetEventByIdLazyQuery,
  useGetEventFilesLazyQuery,
  useGetEventOwnAndRelatedByIdLazyQuery,
  useGetMemberScheduleByIdLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import clsx from 'clsx';
import {
  addDays,
  addHours,
  addWeeks,
  differenceInMinutes,
  format,
  formatDuration,
  isEqual,
  startOfDay,
} from 'date-fns';
import { eachDayOfInterval, isBefore } from 'date-fns/esm';
import { useModal } from 'mui-modal-provider';
import { useEffect, useMemo, useState } from 'react';

type EventSummaryModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  auth: AuthContextType;
  eventId: Event['id'];
  selectedPayrollPeriod: Date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        width: 640,
        gap: theme.spacing(4),
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      height: 18,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    error: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.error.main,
      border: '1px solid ' + theme.palette.error.main,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      '& .MuiTypography-root': {
        fontSize: 13,
      },
    },
    cancelled: {
      backgroundColor: 'lightyellow',
      border: '1px solid gold',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      // margin: theme.spacing(2, 2, 0, 2),
      '& .MuiTypography-root': {
        fontSize: 13,
      },
    },
    people: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        gap: theme.spacing(4),
      },
    },
    attendee: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      gap: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
      '& .MuiTypography-root': {
        fontSize: 13,
      },
    },
    avatar: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      fontSize: 24,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
    },
    scrollableText: {
      whiteSpace: 'pre-wrap',
      fontSize: 13,
      [theme.breakpoints.up('md')]: {
        maxHeight: 700,
        overflowY: 'auto',
      },
    },
    actions: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      '& div': {
        display: 'flex',
        gap: theme.spacing(2),
        '& .MuiButton-root': {
          flexBasis: '100%',
        },
      },
    },
    smallButton: {
      fontSize: 11,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      flexBasis: '100%',
      color: 'black',
    },
    items: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        height: 0,
      },
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.text.secondary,
      [theme.breakpoints.up('md')]: {
        maxHeight: 200,
        overflowY: 'auto',
      },
    },
    itemTitle: {
      fontSize: 12,
      fontWeight: theme.typography.fontWeightMedium,
    },
    attachments: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    attachment: {
      color: theme.palette.text.primary,
      padding: theme.spacing(0, 2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.main,
      display: 'flex',
      gap: theme.spacing(0.5),
      alignItems: 'center',
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        cursor: 'pointer',
      },
      '& .MuiTypography-root:first-child': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    attachmentTexts: {
      display: 'flex',
      flexDirection: 'column',
    },
    attachmentText: {
      fontSize: 10,
      lineHeight: 1,
    },
    observations: {
      fontFamily: 'monospace',
      cursor: 'pointer',
      fontSize: 12,
    },
    observationControls: {
      cursor: 'pointer',
      fontSize: 12,
    },
    recorder: {
      fontSize: 9,
    },
  }),
);

const EventSummaryModal = ({
  onClose,
  auth,
  eventId,
  selectedPayrollPeriod,
  ...modalProps
}: EventSummaryModalProps) => {
  const classes = useStyles();

  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { showModal } = useModal();

  const { member, org, permissible } = useAuth();

  const [errorText, setErrorText] = useState<string | null>(null);

  const [getEvent, eventResponse] = useGetEventByIdLazyQuery({ variables: { id: eventId } });
  const [getEventOwnAndRelated, eventOwnAndRelatedResponse] = useGetEventOwnAndRelatedByIdLazyQuery(
    { variables: { id: eventId } },
  );

  const [getObservations, observationsResponse] = useGetClientObservationsLazyQuery({
    variables: {
      input: {
        where: { event: { id: { _eq: eventId } } },
        orderBy: [{ date: OrderBy.ASC }],
      },
    },
  });

  const [getFiles, filesResponse] = useGetEventFilesLazyQuery({
    variables: {
      input: {
        where: { owner: { id: { _eq: eventId } } },
        orderBy: [{ dateOfFile: OrderBy.ASC }],
      },
    },
  });

  const [downloadFile] = useDownloadEventFileByIdLazyQuery({
    onCompleted: (data) => window.open(data.downloadEventFileById.path, '_blank'),
  });

  const [getMember, memberResponse] = useGetMemberScheduleByIdLazyQuery();

  const [getEnergyIntake, energyInTakeResponse] = useGetClientEnergyIntakeLazyQuery();

  const isEvent = (
    e?: GetEventByIdQuery | GetEventOwnAndRelatedByIdQuery,
  ): e is GetEventByIdQuery => !!e && e.hasOwnProperty('eventById');

  const hasPermission = useMemo(() => {
    return auth.permissible({
      permissions: [Permission.EVENT_READ, Permission.CLIENT_READ, Permission.MEMBER_READ],
    });
  }, [auth]);

  const [data, loading] = useMemo(() => {
    return [
      hasPermission ? eventResponse.data : eventOwnAndRelatedResponse.data,
      eventResponse.loading || eventOwnAndRelatedResponse.loading,
    ];
  }, [hasPermission, eventResponse, eventOwnAndRelatedResponse]);

  const event:
    | GetEventByIdQuery['eventById']
    | GetEventOwnAndRelatedByIdQuery['eventOwnAndRelatedById']
    | undefined = useMemo(() => {
    return (!!data && (isEvent(data) ? data.eventById : data.eventOwnAndRelatedById)) || undefined;
  }, [data]);

  /**
   * Event has an altered state and is non-edittable.
   */
  const nonEdittable = useMemo(
    () =>
      !!event?.cancel || (isEvent(data) && (!!data?.eventById.delete || !!data.eventById.archive)),
    [data, event?.cancel],
  );

  useLoadingEffect(loading);

  // Fetch event
  useEffect(() => {
    hasPermission ? getEvent() : getEventOwnAndRelated();
  }, [hasPermission, getEvent, getEventOwnAndRelated]);

  // Fetch schedule of attending member
  useEffect(() => {
    if (data && isEvent(data) && data.eventById.member) {
      const endOfPayrollPeriod = addWeeks(selectedPayrollPeriod, 1);

      getMember({
        variables: {
          id: data.eventById.member.id,
          scheduleInput: { startAt: selectedPayrollPeriod, endAt: endOfPayrollPeriod },
        },
      });
    }
  }, [data, getMember, selectedPayrollPeriod]);

  // Fetch client observations for this event
  useEffect(() => {
    if (!observationsResponse.data && !observationsResponse.loading) getObservations();
  }, [observationsResponse.data, observationsResponse.loading, getObservations]);

  // Fetch files attached to this event
  useEffect(() => {
    if (!filesResponse.data && !filesResponse.loading) getFiles();
  }, [filesResponse.data, filesResponse.loading, getFiles]);

  // Fetch client energy intake.
  useEffect(() => {
    if (!energyInTakeResponse.data && !energyInTakeResponse.loading && !!event)
      getEnergyIntake({
        variables: {
          input: {
            where: {
              client: { id: { _eq: event.client.id } },
              date: {
                _gte: startOfDay(new Date(event.startAt)),
                _lt: addDays(
                  startOfDay(
                    new Date(
                      isEqual(addDays(startOfDay(new Date(event.endAt)), 1), new Date(event.endAt))
                        ? event.startAt
                        : event.endAt,
                    ),
                  ),
                  1,
                ),
              },
            },
          },
        },
      });
  }, [energyInTakeResponse.data, energyInTakeResponse.loading, getEnergyIntake, event]);

  const totalEnergyIntake = useMemo<{ date: Date; kj: number }[] | null>(() => {
    if (!energyInTakeResponse.data?.clientObservations.length) return null;

    const foodObservations = energyInTakeResponse.data.clientObservations.filter(
      ({ givenFood }) => !!givenFood?.length,
    );

    // const uniqueDates = foodObservations
    //   .filter(
    //     ({ date }, i, self) =>
    //       self.findIndex(({ date: d }) =>
    //         isEqual(startOfDay(new Date(d)), startOfDay(new Date(date))),
    //       ) === i,
    //   )
    //   .map(({ date }) => date);

    const uniqueDates = eachDayOfInterval({
      start: new Date(event?.startAt),
      end: new Date(event?.endAt),
    });

    const totals: { date: Date; kj: number }[] = [];

    uniqueDates.forEach((date) =>
      totals.push({
        date,
        kj: foodObservations
          .filter(({ date: d }) => isEqual(startOfDay(new Date(d)), startOfDay(date)))
          .map(({ givenFood }) => givenFood![0].kilojoules || 0)
          .reduce((a, b) => a + b, 0),
      }),
    );

    return !!totals.length ? totals : null;
  }, [energyInTakeResponse.data, event?.startAt, event?.endAt]);

  const membersScheduledHoursThisWeek = useMemo<number | null>(() => {
    return memberResponse.data?.memberById.schedule?.timeScheduled
      ? memberResponse.data.memberById.schedule.timeScheduled / 60
      : null;
  }, [memberResponse.data?.memberById.schedule?.timeScheduled]);

  const handleOpenEditModal = () => {
    if (isEvent(data)) {
      const modal: { hide: () => void } = showModal(EventUpdateFormModal, {
        onClose: () => {
          modal.hide();
        },
        closeSummaryPage: onClose,
        event: data.eventById,
      });
    }
  };

  // const handleOpenAssignAttendeeModal = () => {
  //   if (isEvent(data)) {
  //     const modal: { hide: () => void } = showModal(EventAssignAttendeeFormModal, {
  //       onClose: () => {
  //         modal.hide();
  //       },
  //       event: data.eventById,
  //     });
  //   }
  // };

  const handleOpenEditCaseNotesModal = () => {
    if (event) {
      const modal: { hide: () => void } = showModal(EventUpdateCaseNotesFormModal, {
        onClose: () => {
          modal.hide();
        },
        event,
      });
    }
  };

  const handleOpenEditTravelModal = () => {
    if (event) {
      const modal: { hide: () => void } = showModal(EventUpdateTravelFormModal, {
        onClose: () => {
          modal.hide();
        },
        event,
      });
    }
  };

  const handleOpenRecordObsModal = () => {
    if (event) {
      const modal: { hide: () => void } = showModal(ClientCreateObservationFormModal, {
        onClose: () => {
          modal.hide();
        },
        event,
      });
    }
  };

  const handleOpenUpdateObsModal = (
    observation: Pick<
      ClientObservation,
      'id' | 'date' | 'bloodSystolic' | 'bloodDiastolic' | 'heart' | 'temp' | 'spo2' | 'respiration'
    > & { client: Pick<Client, 'id'> } & { member?: Pick<Member, 'id'> | null },
  ) => {
    if (event) {
      const modal: { hide: () => void } = showModal(ClientUpdateObservationFormModal, {
        onClose: () => {
          modal.hide();
        },
        observation,
      });
    }
  };

  const handleOpenRecordBmModal = () => {
    if (event) {
      const modal: { hide: () => void } = showModal(ClientCreateBowelMovementFormModal, {
        onClose: () => {
          modal.hide();
        },
        event,
      });
    }
  };

  const handleOpenUpdateBmModal = (
    observation: Pick<ClientObservation, 'id' | 'date' | 'notes' | 'stoolType'> & {
      client: Pick<Client, 'id'>;
    },
  ) => {
    if (event) {
      const modal: { hide: () => void } = showModal(ClientUpdateBowelMovementFormModal, {
        onClose: () => {
          modal.hide();
        },
        observation,
      });
    }
  };

  const handleOpenRecordMedicationModal = () => {
    if (event) {
      const modal: { hide: () => void } = showModal(ClientCreateMedicationFormModal, {
        onClose: () => {
          modal.hide();
        },
        event,
        clientId: event.client.id,
      });
    }
  };

  const handleOpenCreateFileModal = () => {
    if (event) {
      const modal: { hide: () => void } = showModal(EventCreateFileFormModal, {
        onClose: () => {
          modal.hide();
        },
        event,
      });
    }
  };

  return !event ? null : (
    <Modal disablePortal onClose={onClose} {...modalProps}>
      <Block
        titleProps={{ variant: 'h3' }}
        title={
          !!event.member ? (
            permissible({ permissions: Permission.MEMBER_READ }) ? (
              <Link to={_peopleMembers.path + '/' + event.member.id}>
                {formatPersonName(event.member, {
                  capitaliseLastName: true,
                  lastNameFirst: true,
                })}
              </Link>
            ) : (
              formatPersonName(event.member, {
                capitaliseLastName: true,
                lastNameFirst: true,
              })
            )
          ) : (
            'TBA'
          )
        }
        topRight={
          <Box className={classes.controls}>
            {hasPermission && isEvent(data) && (
              <EventSummaryModalMenuButton event={data.eventById} closeSummaryPage={onClose} />
            )}
            <IconButton onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        }
      >
        <Box className={classes.wrapper}>
          {!!errorText && (
            <Box className={classes.error}>
              <Typography>{errorText}</Typography>
            </Box>
          )}
          {event.cancel && (
            <Box className={classes.cancelled}>
              <Typography className={classes.bold}>This shift is cancelled.</Typography>
              <Typography>
                Cancelled
                {isEvent(data) &&
                  ' by ' +
                    formatPersonName(data.eventById.cancel!.actor!, {
                      capitaliseLastName: true,
                    })}{' '}
                at {format(new Date(event.cancel?.date), "h:mma 'on' dd/MM/yyyy")}.
              </Typography>
              {hasPermission && (
                <Typography>
                  {event.cancel?.reason
                    ? 'Reason: ' + event.cancel.reason
                    : 'No reason was provided.'}
                </Typography>
              )}
            </Box>
          )}
          {isEvent(data) && !!data.eventById.delete && (
            <Box className={classes.cancelled}>
              <Typography className={classes.bold}>This shift was deleted.</Typography>
              <Typography>
                Deleted by{' '}
                {formatPersonName(data.eventById.delete.actor!, {
                  capitaliseLastName: true,
                })}{' '}
                at {format(new Date(data.eventById.delete.date), "h:mma 'on' dd/MM/yyyy")}.
              </Typography>
              <Typography>
                {!!data.eventById.delete.reason
                  ? 'Reason: ' + data.eventById.delete.reason
                  : 'No reason was provided.'}
              </Typography>
            </Box>
          )}
          <Box className={classes.people}>
            <Box className={classes.attendee}>
              <Avatar
                className={classes.avatar}
                color={event.member?.color}
                content={!!event.member ? [event.member?.firstName, event.member?.lastName] : ['?']}
              />
              <Box>
                <Typography className={classes.bold}>
                  {format(new Date(event.startAt), 'EEE d MMM')}
                  {', '}
                  {formatTimeDuration({
                    start: new Date(event.startAt),
                    end: new Date(event.endAt),
                    options: {
                      militaryTime: true,
                      seperator: ' - ',
                    },
                  })}
                  {' ('}
                  {formatDuration(
                    {
                      hours: Math.floor(
                        differenceInMinutes(new Date(event.endAt), new Date(event.startAt)) / 60,
                      ),
                      minutes:
                        differenceInMinutes(new Date(event.endAt), new Date(event.startAt)) % 60,
                    },
                    { format: ['hours', 'minutes'], delimiter: ', ' },
                  )
                    .replace('hours', 'hrs')
                    .replace('minutes', 'mins')}
                  {')'}
                </Typography>
                {!!event.passive && (
                  <Typography variant="body2" style={{ color: blue[500] }} className={classes.bold}>
                    Passive overnight{' '}
                    {formatTimeDuration({
                      start: new Date(event.passiveStartAt),
                      end: addHours(new Date(event.passiveStartAt), 8),
                      options: { militaryTime: true, seperator: ' - ' },
                    })}
                    {event.activeAssist && ' (active assist)'}
                  </Typography>
                )}
                <Typography color="textPrimary">
                  Local time: <Clock militaryTime dateFirst dateFormat="EEE d MMM," />
                </Typography>
                {hasPermission && !!event.member && (
                  <Typography variant="body2" color="textSecondary">
                    {!memberResponse.loading
                      ? 'Working ' + roundNumber(membersScheduledHoursThisWeek || 0, 2) + ' hours'
                      : 'Loading schedule...'}
                  </Typography>
                )}
              </Box>
            </Box>
            {!smDown && (
              <Box className={classes.attendee}>
                <Avatar
                  className={classes.avatar}
                  color={event.client?.color}
                  content={
                    !!event.member ? [event.client?.firstName, event.client?.lastName] : ['?']
                  }
                />
                <Box>
                  <Typography className={classes.bold}>Participant</Typography>
                  <Typography color="textPrimary">
                    <Link to={_peopleClients.path + '/' + event.client.id} className={classes.link}>
                      {formatPersonName(event.client, {
                        capitaliseLastName: true,
                        lastNameFirst: true,
                      })}
                      <CallMadeRoundedIcon style={{ width: 10 }} />
                    </Link>
                  </Typography>

                  <Typography variant="body2" color="textSecondary">
                    <Link
                      to={'https://www.google.com/maps/search/' + formatAddress(event)}
                      className={classes.link}
                    >
                      {formatAddress(event) || 'None.'}
                      <CallMadeRoundedIcon style={{ width: 10 }} />
                    </Link>
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          {auth.member?.id === event.member?.id && (
            <EventCantAttendBox schedulingContactPerson={auth.org?.schedulingContactPerson} />
          )}

          {(hasPermission || auth?.member?.id === event.member?.id) && (
            <Box className={classes.actions}>
              {event.member &&
                !nonEdittable &&
                (permissible({ permissions: Permission.EVENT_WRITE }) ||
                  event.member.id === member?.id) && (
                  <EventClockButton setErrorText={setErrorText} event={event} />
                )}
              {hasPermission && isBefore(new Date(), new Date(event.endAt)) && (
                <Box>
                  <Button
                    variant="contained"
                    disabled={nonEdittable}
                    color="primary"
                    onClick={() => {
                      handleOpenEditModal();
                      // handleOpenAssignAttendeeModal();
                    }}
                  >
                    {!!event.member ? 'Find replacement' : 'Assign Support Worker'}
                  </Button>
                  <EventAssignAttendeeAutomaticallyButton
                    eventId={event.id}
                    disabled={nonEdittable}
                    setErrorText={setErrorText}
                  />
                </Box>
              )}

              {(!!event.clockedOnAt || permissible({ admin: true })) && (
                <Box>
                  <Button
                    variant="contained"
                    disabled={nonEdittable}
                    onClick={() => {
                      handleOpenEditCaseNotesModal();
                    }}
                  >
                    Edit Notes
                  </Button>
                  <Button
                    variant="contained"
                    disabled={nonEdittable}
                    onClick={() => {
                      handleOpenEditTravelModal();
                    }}
                  >
                    Edit Travel
                  </Button>
                  {/* {!!event.member && <Button variant="contained">Not available</Button>} */}
                </Box>
              )}
              {(event.client.allowObservations || event.client.allowMedication) &&
                (!!event.clockedOnAt || permissible({ admin: true })) && (
                  <Box>
                    {event.client.allowObservations && (
                      <>
                        <ClientCreateFoodButton
                          clientId={event.client.id}
                          event={event}
                          className={classes.smallButton}
                          variant="contained"
                          disabled={nonEdittable}
                          style={{ flexBasis: '20%', backgroundColor: lightBlue[500] }}
                        />
                        <Button
                          variant="contained"
                          disabled={nonEdittable}
                          color="secondary"
                          className={classes.smallButton}
                          onClick={() => {
                            handleOpenRecordObsModal();
                          }}
                          style={{ minWidth: 0, flexBasis: '5%' }}
                        >
                          Obs
                        </Button>

                        <Button
                          variant="contained"
                          disabled={nonEdittable}
                          color="secondary"
                          className={classes.smallButton}
                          onClick={() => {
                            handleOpenRecordBmModal();
                          }}
                        >
                          Toilet
                        </Button>
                      </>
                    )}
                    {event.client.allowMedication && (
                      <Button
                        variant="contained"
                        disabled={nonEdittable}
                        color="secondary"
                        className={classes.smallButton}
                        onClick={() => {
                          handleOpenRecordMedicationModal();
                        }}
                      >
                        Medication
                      </Button>
                    )}
                  </Box>
                )}
            </Box>
          )}

          <Box className={classes.items}>
            {!!totalEnergyIntake ? (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography className={classes.itemTitle} variant="body1" color="textPrimary">
                    Energy intake
                  </Typography>
                  <Typography variant="body2">
                    {intersperse({
                      sep: ', ',
                      and: false,
                      array: totalEnergyIntake.map(({ date, kj }) => {
                        return (
                          <>
                            <span
                              style={{
                                backgroundColor:
                                  kj < 4000 ? red[600] : kj < 10000 ? yellow[600] : undefined,
                                color: kj < 4000 ? 'white' : kj < 10000 ? 'black' : undefined,
                              }}
                            >
                              {kj}kJ
                            </span>{' '}
                            / 10,000kJ ({format(date, 'd/M/yy')})
                          </>
                        );
                      }),
                    })}
                  </Typography>
                </Box>
              </>
            ) : (
              event.client.allowObservations && (
                <>
                  <Divider />
                  <Box className={classes.item}>
                    <Typography className={classes.itemTitle} variant="body1" color="textPrimary">
                      Energy intake ({format(startOfDay(new Date(event.startAt)), 'dd/MM/yyyy')})
                    </Typography>
                    <Typography variant="body2">
                      <span style={{ backgroundColor: red[600], color: 'white' }}>0kJ</span> /
                      10,000kJ
                    </Typography>
                  </Box>
                </>
              )
            )}

            {smDown && (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography className={classes.itemTitle} variant="body1" color="textPrimary">
                    Participant
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <Link to={_peopleClients.path + '/' + event.client.id}>
                      {formatPersonName(event.client, {
                        capitaliseLastName: true,
                        lastNameFirst: true,
                      })}
                    </Link>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <Link to={'https://www.google.com/maps/search/' + formatAddress(event)}>
                      {formatAddress(event) || 'None.'}
                    </Link>
                  </Typography>
                </Box>
              </>
            )}

            {isEvent(data) && (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography className={classes.itemTitle} variant="body1" color="textPrimary">
                    Accounting
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Participant {data.eventById.billable ? 'billable' : 'not billable'}. Employee{' '}
                    {data.eventById.payable ? 'payable' : 'not payable'}.
                  </Typography>
                </Box>
              </>
            )}

            {event.summary && (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography className={classes.itemTitle} variant="body1" color="textPrimary">
                    Message from scheduler
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.scrollableText}
                  >
                    {event.summary ?? 'None.'}
                  </Typography>
                </Box>
              </>
            )}

            {!!event.clockedOnAt &&
              ((org?.membersCanSeeClockedTimes && event.member?.id === member?.id) ||
                permissible({ permissions: Permission.EVENT_WRITE })) && (
                <>
                  <Divider />
                  <Box className={classes.item}>
                    <Typography className={classes.itemTitle} variant="body1" color="textPrimary">
                      Clocked times
                    </Typography>
                    <Box style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                      <Typography variant="body2" color="textSecondary">
                        {isEvent(data)
                          ? formatPersonName(data.eventById.clockedOnBy!, { initials: true }) + ' c'
                          : ' C'}
                        locked on {format(new Date(event.clockedOnAt), 'd MMM, HHmm')}.
                      </Typography>
                      {isEvent(data) && permissible({ admin: true }) && (
                        <Link
                          to={
                            'https://www.google.com/maps/place/' +
                            data.eventById.clockedOnLatitude +
                            ',' +
                            data.eventById.clockedOnLongitude
                          }
                        >
                          <CallMadeRoundedIcon style={{ width: 10 }} />
                        </Link>
                      )}
                    </Box>

                    {!!event.clockedOffAt && (
                      <Box style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                        <Typography variant="body2" color="textSecondary">
                          {isEvent(data)
                            ? formatPersonName(data.eventById.clockedOffBy!, { initials: true }) +
                              ' c'
                            : ' C'}
                          locked off {format(new Date(event.clockedOffAt), 'd MMM, HHmm')}.
                        </Typography>
                        {isEvent(data) && permissible({ admin: true }) && (
                          <Link
                            to={
                              'https://www.google.com/maps/place/' +
                              data.eventById.clockedOffLatitude +
                              ',' +
                              data.eventById.clockedOffLongitude
                            }
                          >
                            <CallMadeRoundedIcon style={{ width: 10 }} />
                          </Link>
                        )}
                      </Box>
                    )}
                  </Box>
                </>
              )}

            {!nonEdittable && !!event.travelDistance && (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography className={classes.itemTitle} variant="body1" color="textPrimary">
                    Travel distance
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {event.travelDistance / 1000 + ' kilometer'}
                    {event.travelDistance > 1000 && 's'}
                  </Typography>
                </Box>
              </>
            )}
            {!nonEdittable && !!event.travelTime && (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography className={classes.itemTitle} variant="body1" color="textPrimary">
                    Travel time
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {event.travelTime + ' minute'}
                    {event.travelTime > 1 && 's'}
                  </Typography>
                </Box>
              </>
            )}
            {!!observationsResponse.data && !!observationsResponse.data.clientObservations.length && (
              <>
                <Divider />
                <Box
                  className={classes.item}
                  style={{ paddingBottom: smDown ? theme.spacing(8) : undefined }}
                >
                  <Typography
                    className={classes.itemTitle}
                    variant="body1"
                    color="textPrimary"
                    style={{ display: 'inline-flex', alignItems: 'center', gap: 4 }}
                  >
                    Observations
                    {permissible({ permissions: Permission.CLIENT_READ }) && (
                      <Link to={_peopleClients.path + '/' + event.client.id + '/obs'}>
                        <CallMadeRoundedIcon style={{ width: 10 }} />
                      </Link>
                    )}
                  </Typography>
                  {!observationsResponse.data.clientObservations.length
                    ? 'None.'
                    : observationsResponse.data.clientObservations.map((o) => (
                        <>
                          <Typography
                            color="textSecondary"
                            className={classes.observations}
                            onClick={() => {
                              if (
                                (permissible({ permissions: Permission.CLIENT_WRITE }) ||
                                  member?.id === event.member?.id) &&
                                !o.givenMedications?.length &&
                                !o.givenFood?.length
                              )
                                !!o.stoolType || o.urineColor
                                  ? handleOpenUpdateBmModal(o)
                                  : handleOpenUpdateObsModal(o);
                            }}
                          >
                            <ClientFormattedObservation limits={event.client} obs={o} />
                          </Typography>
                          {!!o.createdBy && (
                            <Typography
                              color="textSecondary"
                              className={clsx(classes.recorder, classes.bold)}
                            >
                              Recored by{' '}
                              {formatPersonName(o.createdBy, {
                                capitaliseLastName: true,
                                lastNameFirst: true,
                              })}
                            </Typography>
                          )}
                        </>
                      ))}
                </Box>
              </>
            )}

            {!!event.notes && (
              <>
                <Divider />
                <Box
                  className={classes.item}
                  style={{ paddingBottom: smDown ? theme.spacing(8) : undefined }}
                >
                  <Typography className={classes.itemTitle} variant="body1" color="textPrimary">
                    Case notes
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.scrollableText}
                  >
                    {event.notes?.trim() ?? 'None.'}
                  </Typography>
                </Box>
              </>
            )}

            {isEvent(data) && permissible({ admin: true }) && (
              <>
                <Divider />
                <Box
                  className={classes.item}
                  style={{ paddingBottom: smDown ? theme.spacing(8) : undefined }}
                >
                  <Typography className={classes.itemTitle} variant="body1" color="textPrimary">
                    Creation
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Shift created on{' '}
                    {format(new Date(data.eventById.createdAt), "dd/MM/yy 'at' HH:mm:ss")} by{' '}
                    <Link to={_peopleMembers.path + '/' + data.eventById.createdBy.id}>
                      {formatPersonName(data.eventById.createdBy, {
                        capitaliseLastName: true,
                        lastNameFirst: true,
                      })}
                    </Link>
                  </Typography>
                </Box>
              </>
            )}

            {hasPermission && isEvent(data) && data.eventById.scheduleNotes && (
              <>
                <Divider />
                <Box
                  className={classes.item}
                  style={{ paddingBottom: smDown ? theme.spacing(8) : undefined }}
                >
                  <Typography className={classes.itemTitle} color="error">
                    Scheduling notes
                  </Typography>
                  <Typography variant="body2" color="error" className={classes.scrollableText}>
                    {data.eventById.scheduleNotes ?? 'None.'}
                  </Typography>
                </Box>
              </>
            )}

            <Divider />

            {(permissible({ permissions: Permission.EVENT_READ }) ||
              event.member?.id === member!.id) && (
              <Box
                className={classes.item}
                style={{ paddingBottom: smDown ? theme.spacing(8) : undefined }}
              >
                <Typography className={classes.itemTitle} variant="body1" color="textPrimary">
                  Attachments
                </Typography>
                <Box className={classes.attachments}>
                  {filesResponse.data?.eventFiles.map(({ id, type, file: { size } }) => (
                    <Box
                      className={classes.attachment}
                      onClick={() => {
                        downloadFile({ variables: { id } });
                      }}
                    >
                      <DescriptionOutlinedIcon fontSize="small" />
                      <Box className={classes.attachmentTexts}>
                        <Typography className={classes.attachmentText}>
                          {eventFilesMetadata.find((meta) => meta.id === type)?.name}
                        </Typography>
                        <Typography className={classes.attachmentText}>
                          {roundNumber(
                            size > DataSize.MB ? size / DataSize.MB : size / DataSize.KB,
                            2,
                          ) + (size > DataSize.MB ? 'MB' : 'KB')}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                  <IconButton
                    onClick={() => {
                      handleOpenCreateFileModal();
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Block>
    </Modal>
  );
};

export default EventSummaryModal;
