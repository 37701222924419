import { Select, SelectMenuItem, SelectProps } from '@timed/common';
import { RegisterOptions } from 'react-hook-form';

export const validateNullableBooleanInput: RegisterOptions = {
  validate: {
    validGender: (value) => value instanceof Boolean || value === null || 'Invalid value',
  },
};

export type NullableBoolean = 'null' | 'true' | 'false';

export type NullableBooleanInputProps = Omit<SelectProps, 'items'> & {
  nullText?: string;
  trueText?: string;
  falseText?: string;
};

export const nullableBooleanValueToString = (value?: boolean | null): NullableBoolean => {
  switch (value) {
    case true:
      return 'true';
    case false:
      return 'false';
    default:
      return 'null';
  }
};

export const nullableBooleanStringToValue = (value?: NullableBoolean): boolean | null => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
};

const NullableBooleanInput = ({
  defaultValue,
  nullText = 'Unknown',
  trueText = 'Yes',
  falseText = 'No',
  ...props
}: NullableBooleanInputProps) => {
  const items: SelectMenuItem[] = [
    { label: nullText, value: 'null' },
    { label: trueText, value: 'true' },
    { label: falseText, value: 'false' },
  ];

  return (
    <Select
      formControlProps={{ size: 'small', variant: 'outlined' }}
      defaultValue={defaultValue || 'null'}
      // validation={validateNullableBooleanInput}
      items={items}
      {...props}
    />
  );
};

export default NullableBooleanInput;
