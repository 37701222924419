import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';
import { useAlert } from '@timed/alert';
import {
  Button,
  Checkbox,
  DateInput,
  formatPersonName,
  intersperse,
  jsDateToLocalISO8601DateString,
  Select,
  SelectMenuItem,
} from '@timed/common';
import {
  EntityState,
  EventsWhereInput,
  Member,
  OrderBy,
  PersonNamesFragment,
  useGetActivitySlipsLazyQuery,
  useGetEventsMembersReadyToExportLazyQuery,
  useGetPublicHolidaysLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { Activity, generateActivitySlips, PayrollCategory } from '@timed/report';
import clsx from 'clsx';
import { format, isBefore, startOfToday, startOfWeek, subWeeks } from 'date-fns';
import addDays from 'date-fns/addDays';
import { subDays } from 'date-fns/esm';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useForm } from 'react-hook-form';

export type ActivitySlip = {
  memberExternalId?: string | null;
  memberLastName: string;
  memberFirstName: string;
  date: string;
  activityId: Activity;
  planManagerName: string;
  units: string;
  ndisId: string;
  notes: string;
  payrollCategory: PayrollCategory;
};

type FormData = {
  members: string[];
  period: 'previous' | 'current' | 'custom' | undefined;
  from?: Date;
  to?: Date;
  capitaliseLastNames?: boolean;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      gridTemplateColumns: 'auto auto',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    info: {
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    warning: {
      backgroundColor: yellow[50],
      border: '1px solid ' + yellow[100],
    },
    error: {
      backgroundColor: red[50],
      border: '1px solid ' + red[100],
    },
    success: {
      backgroundColor: green[50],
      border: '1px solid ' + green[100],
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
);

// type PayrollPeriods = "previous" | "current" | "custom" | undefined;

const ReportActivitySlips = () => {
  const classes = useStyles();

  const WEEK_STARTS_ON = 1; // MONDAY

  const { control, watch, setValue } = useForm<FormData>({
    defaultValues: { period: 'previous' },
  });

  const alert = useAlert();

  const period = watch('period');
  const from = watch('from');
  const to = watch('to');
  const members = watch('members');
  const capitaliseLastNames = watch('capitaliseLastNames');

  const headers = [
    { label: 'Emp. Co./Last Name', key: 'memberLastName' },
    { label: 'First Name', key: 'memberFirstName' },
    { label: 'Emp. Record ID', key: 'memberExternalId' },
    { label: 'Date', key: 'date' },
    { label: 'Activity ID', key: 'activityId' },
    { label: 'Cust. Co./Last Name', key: 'planManagerName' },
    { label: 'Units', key: 'units' },
    { label: 'Job', key: 'ndisId' },
    { label: 'Notes', key: 'notes' },
    { label: 'Payroll Category', key: 'payrollCategory' },
  ];

  const periods = useMemo<SelectMenuItem[]>(
    () => [
      { label: 'Most recently ended payroll period', value: 'previous' },
      { label: 'Current payroll period', value: 'current' },
      { label: 'Custom date range', value: 'custom' },
    ],
    [],
  );

  const [reportData, setReportData] = useState<Array<ActivitySlip> | undefined>(undefined);

  const [getMembers, membersResposne] = useGetEventsMembersReadyToExportLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getEvents, { data, loading, error }] = useGetActivitySlipsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getPublicHolidays, publicHolidaysResponse] = useGetPublicHolidaysLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    error && alert.push({ message: error.graphQLErrors[0].message, severity: 'error' });
  }, [error, alert]);

  useLoadingEffect(loading || membersResposne.loading || publicHolidaysResponse.loading);

  useEffect(() => {
    if (period) {
      switch (period) {
        case 'current':
          setValue('from', startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }));
          setValue('to', addDays(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 6));
          break;
        case 'previous':
          setValue('from', subWeeks(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 1));
          setValue('to', subDays(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 1));
          break;
        case 'custom':
          setValue('from', startOfToday());
          setValue('to', startOfToday());
          break;
      }
    }
  }, [period, setValue]);

  /**
   * Fetch members
   */
  useEffect(() => {
    if (!membersResposne.loading && !!from && !!to)
      getMembers({
        variables: {
          input: {
            where: {
              startAt: { _lte: addDays(to, 1) },
              endAt: { _gte: from },
              member: { schedulable: { _eq: true } },
              payable: { _eq: true },
              client: { firstName: { _ne: 'Reminders' } },
            },
            orderBy: [
              { member: { lastName: OrderBy.ASC_NULLS_FIRST } },
              { member: { firstName: OrderBy.ASC } },
            ],
          },
        },
      });
  }, [getMembers, membersResposne.data, membersResposne.loading, from, to]);

  /**
   * Unique selectable members.
   */
  const selectableMembers = useMemo<[string, Pick<Member, 'id'> & PersonNamesFragment][]>(() => {
    return !!membersResposne.data?.events.length
      ? [
          ...new Map<string, any>(
            membersResposne.data.events
              .filter(({ member }) => !!member)
              .map(({ member }) => member)
              .map((arr) => [arr!['id'], arr]),
          ),
        ]
      : [];
  }, [membersResposne.data]);

  /**
   * Selectable members with errors.
   */
  const [membersMissingNotes, membersNotClocked] = useMemo(() => {
    if (!!selectableMembers.length) {
      const list: (Pick<Member, 'id'> & PersonNamesFragment)[][] = [[], []];

      selectableMembers.forEach(([id, m]) => {
        if (!!m) {
          if (
            !!membersResposne
              // Get this member's shifts
              .data!.events.filter(({ member }) => member!.id === id)
              .some(({ hasNotes }) => !hasNotes)
          )
            list[0].push(m);
        }
      });

      selectableMembers.forEach(([id, m]) => {
        if (!!m) {
          if (
            !!membersResposne
              // Get this member's shifts
              .data!.events.filter(({ member }) => member!.id === id)
              .some(({ clockedOffAt, clockedOnAt }) => !clockedOnAt || !clockedOffAt)
          )
            list[1].push(m);
        }
      });

      return [list[0], list[1]];
    }

    return [[], []];
  }, [selectableMembers, membersResposne.data]);

  /**
   * Selectable members ineligible for bonuses.
   */
  const membersIneligibleForBonus = useMemo(() => {
    if (!!membersResposne.data) {
      const membersIneligible: (Pick<Member, 'id'> & PersonNamesFragment)[] = [];

      [
        ...new Map(
          membersResposne.data.events
            .filter(({ member }) => !!member)
            .map(({ member }) => member!)
            .map((arr) => [arr['id'], arr]),
        ),
      ].forEach(([id, m]) => {
        if (
          !!membersResposne
            // Get this member's shifts
            .data!.events.filter(({ member }) => member!.id === id)
            .some(({ member }) => !member?.bonusEligible)
        )
          membersIneligible.push(m);
      });

      return membersIneligible;
    }
  }, [membersResposne.data]);

  /**
   * Set select field default value
   */
  useEffect(() => {
    if (!!selectableMembers?.length)
      setValue(
        'members',
        selectableMembers.map(([id]) => id),
      );
  }, [selectableMembers, setValue]);

  /**
   * Fetch events and public holidays
   */
  const fetchData = () => {
    if (
      !loading &&
      !publicHolidaysResponse.loading &&
      from &&
      to &&
      !!members.length &&
      (isBefore(from, to) || period === 'custom')
    ) {
      const where: EventsWhereInput = {
        endAt: { _gte: from },
        startAt: { _lte: addDays(to, 1) },
        payable: { _eq: true },
        member: { id: { _in: members } },
        client: { firstName: { _ne: 'Reminders' } },
        // notes: hasNotes ? { _ne: null } : undefined,
        // clockedOnAt: isClocked ? { _ne: null } : undefined,
        // clockedOffAt: isClocked ? { _ne: null } : undefined,
      };

      getEvents({
        variables: {
          input: {
            where,
            orderBy: [{ startAt: OrderBy.ASC }],
            entityStates: [EntityState.NORMAL, EntityState.CANCELLED],
          },
        },
      });

      getPublicHolidays({
        variables: {
          input: {
            orderBy: [{ date: OrderBy.ASC }],
            where: {
              date: {
                _gte: jsDateToLocalISO8601DateString(from),
                _lte: jsDateToLocalISO8601DateString(to),
              },
            },
          },
        },
      });
    }
  };

  /**
   * Generate report from fetched data
   */
  useEffect(() => {
    if (!!data && !!publicHolidaysResponse.data && from && to) {
      // Valiate existance of externIds for each discovered member
      if (data.events.some((e) => !e.member?.externalId)) {
        alert.push({
          message:
            'Set MYOB id(s) for: ' +
            intersperse({
              array: _.union(
                data.events
                  .filter((e) => !e.member?.externalId)
                  .map((e) => formatPersonName(e.member!, { capitaliseLastName: true })),
              ),
            }).join(''),
          severity: 'error',
        });
      }

      // Valiate existance of ndisIds for each discovered client
      if (data.events.some((e) => !e.client?.ndisId)) {
        alert.push({
          message:
            'Set NDIS id(s) for: ' +
            intersperse({
              array: _.union(
                data.events
                  .filter((e) => !e.client?.ndisId)
                  .map((e) => formatPersonName(e.client!, { capitaliseLastName: true })),
              ),
            }).join(''),
          severity: 'error',
        });
      }

      // Valiate existance of plan managers for each discovered client
      if (data.events.some((e) => !e.client?.planManager)) {
        alert.push({
          message:
            'Set plan manager(s) for: ' +
            intersperse({
              array: _.union(
                data.events
                  .filter((e) => !e.client?.planManager)
                  .map((e) => formatPersonName(e.client!, { capitaliseLastName: true })),
              ),
            }).join(''),
          severity: 'error',
        });
      }

      setReportData(
        generateActivitySlips({
          capitaliseLastNames,
          after: from,
          before: addDays(to, 1),
          events: data.events,
          publicHolidays: publicHolidaysResponse.data?.publicHolidays,
        }),
      );
    }
  }, [alert, data, publicHolidaysResponse.data, from, to, capitaliseLastNames]);

  return (
    <Box>
      <div className={classes.grid}>
        <Box className={classes.row}>
          <Select
            control={control}
            name="period"
            label="Reporting period"
            items={periods}
            formControlProps={{ variant: 'outlined', size: 'small' }}
          />
          {period && (
            <Box className={classes.row}>
              <DateInput
                disableToolbar
                autoOk
                control={control}
                name="from"
                label="From start of day"
                inputVariant="outlined"
                size="small"
                disabled={period === 'current' || period === 'previous'}
              />
              <DateInput
                disableToolbar
                autoOk
                control={control}
                name="to"
                label="To end of day"
                inputVariant="outlined"
                size="small"
                disabled={period === 'current' || period === 'previous'}
              />
            </Box>
          )}
        </Box>
        {!!period && !!selectableMembers && (
          <>
            <Box className={classes.row}>
              <Select
                multiple
                control={control}
                defaultValue={[]}
                name="members"
                label="Employees"
                items={selectableMembers.map(([_, member]) => ({
                  label: formatPersonName(member, { lastNameFirst: true }),
                  value: member.id,
                }))}
                formControlProps={{
                  variant: 'outlined',
                  size: 'small',
                  style: { width: 200 },
                }}
                renderValue={() => members.length + ' selected'}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setValue('members', selectableMembers.map(([id]) => id) || []);
                }}
              >
                Select All
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setValue('members', []);
                }}
              >
                Clear Selected
              </Button>
            </Box>
            <Box className={classes.row}>
              <Checkbox
                defaultChecked
                control={control}
                name="capitaliseLastNames"
                label="Capitalise employee last names in report"
              />
            </Box>
          </>
        )}

        {!!membersResposne.data &&
          !membersResposne.loading &&
          !membersMissingNotes?.length &&
          !membersNotClocked?.length && (
            <Box className={clsx(classes.info, classes.success)}>
              All Support workers have completed their case notes and successfully clocked on and
              off for each of their shifts.
            </Box>
          )}

        {!!membersResposne.data && !membersResposne.loading && !!membersMissingNotes?.length && (
          <Box className={clsx(classes.info, classes.error)}>
            <Typography className={classes.bold}>Missing case notes:</Typography>
            {membersMissingNotes.map((member) => (
              <Typography variant="body2" color="textSecondary">
                - {formatPersonName(member, { capitaliseLastName: true, lastNameFirst: true })}
              </Typography>
            ))}
          </Box>
        )}

        {!!membersResposne.data && !membersResposne.loading && !!membersNotClocked?.length && (
          <Box className={clsx(classes.info, classes.error)}>
            <Typography className={classes.bold}>Not clocked on/off:</Typography>
            {membersNotClocked.map((member) => (
              <Typography variant="body2" color="textSecondary">
                - {formatPersonName(member, { capitaliseLastName: true, lastNameFirst: true })}
              </Typography>
            ))}
          </Box>
        )}

        {!!membersResposne.data && !membersResposne.loading && !!membersIneligibleForBonus?.length && (
          <Box className={clsx(classes.info, classes.warning)}>
            <Typography className={classes.bold}>
              Ineligible for bonuses (will be exported with old payroll categories):
            </Typography>
            {membersIneligibleForBonus?.map((member) => (
              <Typography variant="body2" color="textSecondary">
                - {formatPersonName(member, { capitaliseLastName: true, lastNameFirst: true })}
              </Typography>
            ))}
          </Box>
        )}

        <Box className={classes.row}>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading || !from || !to || !members || !members.length}
            onClick={() => {
              fetchData();
            }}
          >
            Generate Report
          </Button>
          {members && !!members.length && reportData && !loading && (
            <CSVLink
              style={{ justifySelf: 'center' }}
              data={reportData}
              headers={headers}
              // separator={"	"}
              filename={
                format(from ? from : startOfToday(), 'yyyyMMdd') +
                '-' +
                format(to ? to : startOfToday(), 'yyyyMMdd') +
                '_activity-slips-myob.txt'
              }
              target="_blank"
            >
              <Button type="submit" variant="contained" color="secondary" disabled={loading}>
                Download CSV
              </Button>
            </CSVLink>
          )}
        </Box>
        <Box></Box>
      </div>
    </Box>
  );
};

export default ReportActivitySlips;
