import { Chip } from '@timed/common';
import { Member, useUninviteMembersMutation } from '@timed/gql';
import { useAlert } from '@timed/alert';
import { useLoadingEffect } from '@timed/loading';
import { useEffect } from 'react';

type MemberListCancelInviteChipProps = {
  memberId: Member['id'];
};

const MemberListCancelInviteChip = ({ memberId }: MemberListCancelInviteChipProps) => {
  const alert = useAlert();

  const [uninviteMembers, response] = useUninviteMembersMutation();

  useLoadingEffect(response.loading);

  useEffect(() => {
    if (!!response.data && !response.error) {
      if (response.data.uninviteMembers[0].invitationMostRecentlyReceived) {
        alert.push({ message: 'Invitation cancelled', severity: 'success' });

        const cache = response.client.cache;

        cache.evict({
          id: cache.identify({
            __typename: 'MemberInvitation',
            id: response.data.uninviteMembers[0].invitationMostRecentlyReceived.id,
          }),
        });

        cache.gc();
      }
    }
  }, [response.data, response.error, alert, response.client.cache]);

  const handleClick = () => uninviteMembers({ variables: { input: { ids: [memberId] } } });

  return <Chip label="Cancel invite" size="small" color="primary" onDelete={handleClick} />;
};

export default MemberListCancelInviteChip;
