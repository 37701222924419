import { LinearProgress, makeStyles } from '@material-ui/core';
import { LoadingContext } from '@timed/loading';
import { useContext } from 'react';

interface LoadingIndicatorProps {}

const useStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    width: '100%',
    pointerEvents: 'none',
  },
  loadingIndicator: {
    height: 8,
  },
});

const LoadingIndicator: React.FC<LoadingIndicatorProps> = () => {
  const classes = useStyles();
  const { loading } = useContext(LoadingContext);

  return !loading ? null : (
    <div className={classes.wrapper}>
      <LinearProgress color="secondary" className={classes.loadingIndicator} />
    </div>
  );
};

export default LoadingIndicator;
