import { Grid, Typography } from '@material-ui/core';
import {
  addServerErrors,
  Block,
  EmailInput,
  IconButtonMulti,
  PhoneInput,
  TextField,
  validateAustralianBusinessName,
} from '@timed/common';
import { Permission, SupportCoordinator, useUpdateSupportCoordinatorsMutation } from '@timed/gql';
import { Protected } from '@timed/auth';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type SupportCoordinatorUpdateSupportCoordinationDetailsFormProps = {
  supportCoordinator: Pick<
    SupportCoordinator,
    'id' | 'businessName' | 'businessEmail' | 'businessPhone'
  >;
};

type FormData = {
  patch: {
    businessName: SupportCoordinator['businessName'];
    businessEmail?: SupportCoordinator['businessEmail'];
    businessPhone?: SupportCoordinator['businessPhone'];
  };
};

const SupportCoordinatorUpdateSupportCoordinationDetailsForm = ({
  supportCoordinator,
}: SupportCoordinatorUpdateSupportCoordinationDetailsFormProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const [updateSupportCoordinators, response] = useUpdateSupportCoordinatorsMutation();

  const defaultValues: FormData = {
    patch: {
      businessName: supportCoordinator.businessName,
      businessEmail: supportCoordinator.businessEmail,
      businessPhone: supportCoordinator.businessPhone,
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) => {
    updateSupportCoordinators({
      variables: { input: { ids: [supportCoordinator.id], ...values } },
    }).catch(() => {});
    reset(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Support Coordinator Details"
        topRight={
          <Protected permissions={Permission.SUPPORTCOORDINATOR_WRITE}>
            <IconButtonMulti
              enabled={editing}
              changed={!isEqual(currentValues, defaultValues)}
              setEditing={setEditing}
              loading={response.loading}
              success={!!response.data}
            />
          </Protected>
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Business Name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                supportCoordinator.businessName
              ) : (
                <TextField
                  required
                  fullWidth
                  control={control}
                  name="patch.businessName"
                  label="Business Name"
                  type="text"
                  validation={validateAustralianBusinessName}
                  variant="outlined"
                  size="small"
                  error={!!errors.patch?.businessName}
                  helperText={errors.patch?.businessName?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Business Email</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                supportCoordinator.businessEmail
              ) : (
                <EmailInput
                  name="patch.businessEmail"
                  label="Business Email"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.businessEmail}
                  helperText={errors.patch?.businessEmail?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Business Phone</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                supportCoordinator.businessPhone
              ) : (
                <PhoneInput
                  name="patch.businessPhone"
                  label="Business Phone"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.businessPhone}
                  helperText={errors.patch?.businessPhone?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default SupportCoordinatorUpdateSupportCoordinationDetailsForm;
