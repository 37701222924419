import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAlert } from '@timed/alert';
import { useAuth } from '@timed/auth';
import { addServerErrors, formatPersonName, FormModal, ModalProps, Textarea } from '@timed/common';
import {
  Event,
  Permission,
  PersonNamesFragment,
  useUpdateEventAttendedMutation,
  useUpdateEventMutation,
} from '@timed/gql';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

type EventUpdateCaseNotesFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  event: Pick<Event, 'id' | 'notes' | 'startAt'> & { client: PersonNamesFragment };
};

type FormData = { notes: Event['notes'] };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textarea: {
      margin: 0,
      padding: theme.spacing(2),
      overflowY: 'auto',
      outline: 'none',
      resize: 'none',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      fontFamily: 'inherit',
      WebkitTextFillColor: theme.palette.text.primary, // required for iOS
      opacity: 1, // required for iOS
      [theme.breakpoints.up('md')]: {
        width: 600,
        height: 240,
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 1px)',
        height: 'calc(100% - 1px)',
        fontSize: 16,
      },
    },
  }),
);

const EventUpdateCaseNotesFormModal = ({
  onClose,
  event,
  ...modalProps
}: EventUpdateCaseNotesFormModalProps) => {
  const classes = useStyles();

  const alert = useAlert();

  const { permissible } = useAuth();

  const { handleSubmit, control, setError } = useForm<FormData>({
    defaultValues: { notes: event.notes },
  });

  const [updateAttendedEventNotes, responseUpdateAttended] = useUpdateEventAttendedMutation();

  const [updateEventNotes, responseUpdate] = useUpdateEventMutation();

  const [data, loading, error] = useMemo(() => {
    return [
      permissible({ permissions: [Permission.EVENT_WRITE] })
        ? responseUpdate.data
        : responseUpdateAttended.data,
      responseUpdate.loading || responseUpdateAttended.loading,
      responseUpdate.error || responseUpdateAttended.error,
    ];
  }, [permissible, responseUpdate, responseUpdateAttended]);

  useEffect(() => error && addServerErrors(error, setError), [error, setError]);

  const onSuccess = () => {
    alert.push({ message: 'Successfully updated event notes', severity: 'success' });
  };

  const onSubmit = ({ notes }: FormData) => {
    const variables = { input: { id: event.id, patch: { notes } } };

    permissible({ permissions: [Permission.EVENT_WRITE] })
      ? updateEventNotes({ variables })
      : updateAttendedEventNotes({ variables });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Edit case notes"
      description={`Shift starting ${format(
        new Date(event.startAt),
        'ha',
      ).toLocaleLowerCase()} on the ${format(
        new Date(event.startAt),
        "do 'of' MMM",
      )} with participant ${formatPersonName(event.client)}`}
      loading={loading}
      success={!!data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Textarea
        minRows={20}
        name="notes"
        control={control}
        placeholder="Notes"
        className={classes.textarea}
        style={{ height: '', overflow: '' }}
        validation={{ maxLength: { value: 10000, message: 'Exceeding 10,000 characters' } }}
      />
    </FormModal>
  );
};

export default EventUpdateCaseNotesFormModal;
