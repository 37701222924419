import { MemberFileType } from '@timed/gql';
import { FileMetadata } from '@timed/file';
import { MemberFileCategory } from '@timed/member/constants/categories';

export const memberFilesMetadata: FileMetadata<MemberFileType, MemberFileCategory>[] = [
  /**
   * Miscellaneous
   */
  {
    id: MemberFileType.MISC,
    name: '000. Miscellaneous',
    hasDate: true,
    hasNote: true,
    hasExpiryDate: true,
  },

  /**
   * Certifications
   */
  {
    id: MemberFileType.VISA,
    category: MemberFileCategory.CERTIFICATION,
    name: '100. VISA / VEVO / Workrights',
    hasDate: true,
    hasExpiryDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.POLICE_CHECK,
    category: MemberFileCategory.CERTIFICATION,
    name: '101. Police Check',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.WORKING_WITH_CHILDREN_CHECK,
    category: MemberFileCategory.CERTIFICATION,
    name: '102. Working With Children Check',
    hasDate: true,
    hasExpiryDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.NDIS_MODULE,
    category: MemberFileCategory.CERTIFICATION,
    name: '103. NDIS Module',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.COVID_19,
    category: MemberFileCategory.CERTIFICATION,
    name: '104. COVID 19 Module',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.NEW_NDIS_CLEARANCE,
    category: MemberFileCategory.CERTIFICATION,
    name: '105. New NDIS Clearence',
    hasDate: true,
    hasNote: true,
    hasExpiryDate: true,
  },
  {
    id: MemberFileType.ASSISTING_WITH_SELF_MEDICATION,
    category: MemberFileCategory.CERTIFICATION,
    name: '106. Assisting With Self Medication',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.FIRST_AID,
    category: MemberFileCategory.CERTIFICATION,
    name: '107. First Aid Certificate',
    hasDate: true,
    hasNote: true,
    hasExpiryDate: true,
  },
  {
    id: MemberFileType.CPR,
    category: MemberFileCategory.CERTIFICATION,
    name: '108. CPR Certificate',
    hasDate: true,
    hasNote: true,
    hasExpiryDate: true,
  },
  {
    id: MemberFileType.MANUAL_HANDLING,
    category: MemberFileCategory.CERTIFICATION,
    name: '109. Manual Handling Certificate',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.INDIVIDUAL_SUPPORT,
    category: MemberFileCategory.CERTIFICATION,
    name: '110. Individual Support Certificate',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.COVID_19_TRAVEL_EXEMPTION,
    category: MemberFileCategory.CERTIFICATION,
    name: '111. COVID-19 Travel Exemption',
    hasDate: true,
    hasNote: true,
  },

  /**
   * Employment
   */
  {
    id: MemberFileType.STAFF_APPLICATION,
    category: MemberFileCategory.EMPLOYMENT,
    name: '200. Staff Application',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.EMPLOYMENT_CONTRACT,
    category: MemberFileCategory.EMPLOYMENT,
    name: '201. Employment Contract',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.CONFIRMATION_OF_ENROLLMENT,
    category: MemberFileCategory.EMPLOYMENT,
    name: '202. Confirmation of Enrollment',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.PRIVACY_AND_CONFIDENTIAILITY_DECLARATION,
    category: MemberFileCategory.EMPLOYMENT,
    name: '203. Privacy And Confidentiality Declaration',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.NON_DISCLOSURE_AGREEMENT,
    category: MemberFileCategory.EMPLOYMENT,
    name: '204. Non-disclosure Agreement',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.TAX_FILE_DECLARATION,
    category: MemberFileCategory.EMPLOYMENT,
    name: '205. TAX File Declaration',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.STAFF_CARD_PHOTO_ID,
    category: MemberFileCategory.EMPLOYMENT,
    name: '206. Staff Card Photo ID',
    hasDate: true,
    hasNote: true,
  },

  /**
   * Financial
   */
  {
    id: MemberFileType.BANK_DETAILS,
    category: MemberFileCategory.FINANCIAL,
    name: '300. Bank Details',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.SUPERANNUATION,
    category: MemberFileCategory.FINANCIAL,
    name: '301. Superannutation',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.TAX_FILE_NUMBER,
    category: MemberFileCategory.FINANCIAL,
    name: '302. TAX File Number',
    hasDate: true,
    hasNote: true,
  },

  /**
   * Identification
   */
  {
    id: MemberFileType.PASSPORT,
    category: MemberFileCategory.IDENTIFICATION,
    name: '400. Passport / Citizenship certificate',
    hasDate: true,
    hasExpiryDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.DRIVERS_LICENCE,
    category: MemberFileCategory.IDENTIFICATION,
    name: '401. Drivers Licence',
    hasDate: true,
    hasExpiryDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.BIRTH_CERTIFICATE,
    category: MemberFileCategory.IDENTIFICATION,
    name: '402. Birth Certificate',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.MEDICARE_CARD,
    category: MemberFileCategory.IDENTIFICATION,
    name: '403. Medicare Card',
    hasDate: true,
    hasExpiryDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.PHOTO_ID_CARD,
    category: MemberFileCategory.IDENTIFICATION,
    name: '404. Photo ID Card',
    hasDate: true,
    hasExpiryDate: true,
    hasNote: true,
  },

  /**
   * Personal
   */
  {
    id: MemberFileType.RESUME,
    category: MemberFileCategory.PERSONAL,
    name: '500. Resumé',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.CAR_REGISTRATION,
    category: MemberFileCategory.PERSONAL,
    name: '501. Car Registration',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.CTP_INSURANCE,
    category: MemberFileCategory.PERSONAL,
    name: '502. CTP Insurance',
    hasDate: true,
    hasExpiryDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.CAR_INSURANCE,
    category: MemberFileCategory.PERSONAL,
    name: '503. Car Insurance',
    hasDate: true,
    hasNote: true,
  },

  /**
   * Medical
   */
  {
    id: MemberFileType.VACCINATION_RECORD,
    category: MemberFileCategory.MEDICAL,
    name: '600. Vaccination Record',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.COVID_TEST_RESULTS,
    category: MemberFileCategory.MEDICAL,
    name: '601. COVID-19 Test Results ',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.PROOF_OF_COVID_VACCINATION,
    category: MemberFileCategory.MEDICAL,
    name: '602. Proof of COVID-19 Vaccination',
    hasDate: true,
    hasNote: true,
  },
  {
    id: MemberFileType.PROOF_OF_OVERSEAS_HEALTH_COVER,
    category: MemberFileCategory.MEDICAL,
    name: '603. Proof of Overseas Health Cover',
    hasDate: true,
    hasNote: true,
  },

  /**
   * To be deleted
   */
  {
    id: MemberFileType.WORK_RIGHTS,
    category: MemberFileCategory.EMPLOYMENT,
    name: '999. Work Rights (OLD)',
    hasDate: true,
    hasNote: true,
    hasExpiryDate: true,
  },
  {
    id: MemberFileType.VEVO,
    category: MemberFileCategory.IDENTIFICATION,
    name: '999. VEVO (OLD)',
    hasDate: true,
    hasNote: true,
    hasExpiryDate: true,
  },
];
