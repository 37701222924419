import {
  Box,
  Container,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Avatar } from '@timed/common';
import { OrderBy, PlanManager, useGetPlanManagersQuery } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { PlanManagerListSearch, PlanManagerUpdateFormModal } from '@timed/plan-manager';
import clsx from 'clsx';
import { useModal } from 'mui-modal-provider';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      cursor: 'pointer',
      color: theme.palette.grey[800],
      boxShadow: 'none',
      borderLeft: '1px solid ' + theme.palette.grey[400],
      borderBottom: '1px solid ' + theme.palette.grey[400],
      borderRight: '1px solid ' + theme.palette.grey[400],
    },
    profile: {
      padding: 8,
      display: 'grid',
      gridTemplateColumns: 'max-content auto max-content',
      alignItems: 'center',
      gap: theme.spacing(1),
      '&:hover': {
        backgroundColor: grey[200],
      },
    },
    avatar: {
      backgroundColor: grey[500],
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: 12,
    },
    seperator: {
      height: 1,
      backgroundColor: grey[300],
    },
    characterFirst: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    character: {
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      backgroundColor: grey[300],
      color: grey[600],
      fontWeight: 'bold',
      fontSize: 14,
      boxSizing: 'border-box',
      borderTop: '1px solid ' + grey[400],
      borderBottom: '1px solid ' + grey[400],
    },
  }),
);

const PlanManagerList = () => {
  const classes = useStyles();
  const { data, loading } = useGetPlanManagersQuery({
    variables: { input: { orderBy: [{ name: OrderBy.ASC }] } },
  });
  const [searchTerm, setSearchTerm] = useState<string>();
  const { showModal } = useModal();

  useLoadingEffect(loading);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleOpenModal = (planManagerId: PlanManager['id']) => {
    const modal: { hide: () => void } = showModal(PlanManagerUpdateFormModal, {
      planManagerId,
      onClose: () => modal.hide(),
    });
  };

  const filteredPlanManagers = () => {
    return searchTerm
      ? data?.planManagers.filter((p) => p.name.toLowerCase().includes(searchTerm))
      : data?.planManagers;
  };

  const printPlanManagerList = () => {
    const formattedList: JSX.Element[] = [];
    let currentChar: string = '';
    let skipSeperator: boolean = true;

    filteredPlanManagers()?.forEach((p) => {
      if (p.name.charAt(0) !== currentChar) {
        skipSeperator = true;
        formattedList.push(
          <Box
            className={
              currentChar === ''
                ? clsx(classes.character, classes.characterFirst)
                : classes.character
            }
          >
            {(currentChar = p.name.charAt(0))}
          </Box>,
        );
      } else {
        skipSeperator = false;
      }
      formattedList.push(
        <>
          {skipSeperator ? '' : <Box className={classes.seperator}></Box>}
          <Box className={classes.profile} onClick={() => handleOpenModal(p.id)}>
            <Avatar aria-label="avatar" className={classes.avatar} content={[p.name]} />
            <Typography>{p.name}</Typography>
          </Box>
        </>,
      );
    });

    return formattedList;
  };

  return (
    <>
      <PlanManagerListSearch handleSearch={handleSearch} />
      {data?.planManagers && filteredPlanManagers()!.length > 0 ? (
        <Paper className={classes.paper}>{printPlanManagerList()}</Paper>
      ) : (
        <Container>No results</Container>
      )}
    </>
  );
};

export default PlanManagerList;
