import { Select, SelectProps } from '@timed/common';
import { MemberFileType } from '@timed/gql';
import { memberFilesMetadata } from '@timed/member';
import { validateFileType } from '@timed/file';

const MemberFileTypeInput = ({ defaultValue, ...props }: Omit<SelectProps, 'items'>) => (
  <Select
    label="Category"
    validation={validateFileType('member')}
    items={Object.values(MemberFileType)
      .map((name) => {
        return {
          value: name,
          label: memberFilesMetadata.find((meta) => meta.id === name)!.name,
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1))}
    renderValue={(value) => memberFilesMetadata.find((meta) => meta.id === value)?.name}
    {...props}
  />
);

export default MemberFileTypeInput;
