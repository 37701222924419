import { formatPronouns, Select, SelectProps } from '@timed/common';
import { Pronouns } from '@timed/gql';
import { RegisterOptions } from 'react-hook-form';

export const validatePronouns: RegisterOptions = {
  validate: {
    validPronouns: (value) => value in Pronouns || 'Invalid value',
  },
};

const PronounsInput = ({ defaultValue, ...props }: Omit<SelectProps, 'items'>) => (
  <Select
    label="Pronouns"
    defaultValue={defaultValue || Pronouns.NOTSPECIFIED}
    validation={validatePronouns}
    items={Object.values(Pronouns).map((pronouns) => {
      return {
        value: pronouns,
        label: formatPronouns(pronouns)!,
      };
    })}
    renderValue={(value) => formatPronouns(value as Pronouns)}
    {...props}
  />
);

export default PronounsInput;
