import {
  Box,
  ClickAwayListener,
  createStyles,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Typography,
} from '@material-ui/core';
import { Button, formatBusinessName, formatPersonName } from '@timed/common';
import { AuthLogoutLink, useAuth } from '@timed/auth';
import { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      flexGrow: 1,
      padding: theme.spacing(0, 8),
      maxWidth: theme.spacing(75),
      backgroundColor: theme.palette.topBar.dark,
      textAlign: 'start',
      textTransform: 'none',
      color: theme.palette.topBar.text,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: theme.palette.topBar.light,
      },
      '& p': {
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& p:first-child': {
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
      },
      '& p:last-child': {
        [theme.breakpoints.up('sm')]: {
          fontSize: '12px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '10px',
        },
      },
      '& svg': {
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
    },
    text: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
    },
  }),
);

const DrawerAccount = () => {
  const classes = useStyles();
  const { org, user } = useAuth();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  /**
   * Toggle menu display
   */
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  /**
   * Close menu
   */
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  /**
   * Detect 'Tab' keyboard event
   */
  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open, prevOpen]);

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleToggle}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
      >
        <Box className={classes.text}>
          {org && (
            <Typography noWrap variant="body2">
              {formatBusinessName(org, { preferred: true })}
            </Typography>
          )}
          {user && (
            <>
              <Typography noWrap variant="body2">
                {formatPersonName(user)}
              </Typography>
            </>
          )}
        </Box>
      </Button>
      <Popper
        transition
        disablePortal
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        style={{ zIndex: 100 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <AuthLogoutLink>
                    <MenuItem key="Logout">Logout</MenuItem>
                  </AuthLogoutLink>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DrawerAccount;
