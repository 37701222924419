import { TextField } from '@material-ui/core';

type ClientSearchBoxProps = React.PropsWithChildren<{
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}>;

const ClientListSearch = (props: ClientSearchBoxProps) => {
  return (
    <TextField
      autoFocus
      fullWidth
      size="small"
      name="search"
      variant="outlined"
      label="Search"
      onChange={props.handleSearch}
    />
  );
};

export default ClientListSearch;
