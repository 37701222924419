import { Permission } from '@timed/gql';
import { Crumb } from '@timed/app-bar';
import { DrawerControl } from '@timed/drawer/context/DrawerContext';
import { createContext } from 'react';

export type PageMetadata = {
  /**
   * Mark component as a new page
   *
   * React processes components in order of child -> parent, therefore the
   * deepest nested page component in the current context is processed first.
   * This component must include this flag in order to clear stale information.
   *
   * Example: /people/employees/:profileId/:category
   * :category is the first rendered page component - it must include this flag.
   */
  newPage?: boolean;

  /**
   * Position the the page on the document hierarchy.
   */
  level: number;

  /**
   * Title of the page
   */
  label: string;

  /**
   * Pathname of the page
   */
  path: string;

  /**
   * Is the page a module? Used by breadcrumbs menu on mobile
   */
  isModule?: boolean;

  /**
   * Path to redirect to if Page.path === Router.pathname
   */
  redirect?: string;

  /**
   * State object to pass when redirecting
   */
  state?: object;

  /**
   * Array of controls for the drawer control panel
   */
  controls?: DrawerControl[];

  /**
   * Restrict the loading of the metadata to the specified permission(s).
   * To also prevent the page from rendering, wrap child components with
   * \<Protected permission={PERMISSION} />{children}\</Protected>, exported from the 'Auth' module.
   */
  permissions?: Permission | Permission[];

  /**
   * Restrict the loading of the metadata to admins.
   * To also prevent the page from rendering, wrap child components with
   * \<Protected admin={true} />{children}\</Protected>, exported from the 'Auth' module.
   */
  admin?: boolean;

  /**
   * Addition items to render for the breadcrumbs menu. Not required for
   * rendering the default breadcrumb for the page.
   */

  crumbs?: {
    /**
     * Breadcrumbs to render before the current page's default breadcrumb
     */
    before?: Crumb[];

    /**
     * Breadcrumbs to render after the current page's default breadcrumb
     */
    after?: Crumb[];
  };
};

type PageContextType = {
  /**
   * Process supplied page metadata
   */
  addPage: (page: PageMetadata) => void;
  getDirectPagePath: (page: Pick<PageMetadata, 'path' | 'redirect'>) => string;
};

const PageContext = createContext<PageContextType>({
  addPage: () => {},
  getDirectPagePath: () => '',
});

export default PageContext;
