import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAlert } from '@timed/alert';
import {
  addServerErrors,
  EmailInput,
  FormModal,
  ModalProps,
  PersonNameFirstInput,
  PersonNameLastInput,
  PersonNameMiddleInput,
  PhoneInput,
  TextField,
  validateAustralianBusinessName,
} from '@timed/common';
import {
  CreateSupportCoordinatorsDocument,
  useCreateSupportCoordinatorsMutation,
} from '@timed/gql';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type SupportCoordinatorCreateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(6, 1fr)',
      gap: theme.spacing(8),
    },
    fullWidth: {
      gridColumn: 'span 6',
    },
    halfWidth: {
      gridColumn: 'span 3',
    },
    thirdWidth: {
      gridColumn: 'span 2',
    },
  }),
);

type FormData = {
  businessName: string;
  businessEmail: string;
  businessPhone: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
};

const SupportCoordinatorCreateFormModal = ({
  onClose,
  ...modalProps
}: SupportCoordinatorCreateFormModalProps) => {
  const classes = useStyles();

  const alert = useAlert();

  const [createSupportCoordinator, response] = useCreateSupportCoordinatorsMutation();

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      businessName: '',
      businessEmail: '',
      businessPhone: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  });

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    alert.push({ message: 'Successfully created support coordinator', severity: 'success' });

    const cache = response.client.cache;

    cache.modify({
      fields: {
        supportCoordinators(existing = []) {
          return [
            ...existing,
            cache.writeQuery({ data: response.data, query: CreateSupportCoordinatorsDocument }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async (values: FormData) => {
    createSupportCoordinator({ variables: { input: { objects: [values] } } });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Add support coordinator"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.grid}>
          <TextField
            required
            fullWidth
            control={control}
            name="businessName"
            label="Business Name"
            type="text"
            validation={validateAustralianBusinessName}
            variant="outlined"
            size="small"
            error={!!errors.businessName}
            helperText={errors.businessName?.message}
            className={classes.fullWidth}
          />
          <EmailInput
            name="businessEmail"
            label="Business Email"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.businessEmail}
            helperText={errors.businessEmail?.message}
            className={classes.fullWidth}
          />
          <PhoneInput
            name="businessPhone"
            label="Business Phone"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.businessPhone}
            helperText={errors.businessPhone?.message}
            className={classes.fullWidth}
          />
          <PersonNameFirstInput
            required
            name="firstName"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            className={classes.thirdWidth}
          />
          <PersonNameMiddleInput
            name="middleName"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.middleName}
            helperText={errors.middleName?.message}
            className={classes.thirdWidth}
          />
          <PersonNameLastInput
            name="lastName"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            className={classes.thirdWidth}
          />
          <EmailInput
            name="businessEmail"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.businessEmail}
            helperText={errors.businessEmail?.message}
            className={classes.fullWidth}
          />
          <PhoneInput
            name="businessPhone"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.businessPhone}
            helperText={errors.businessPhone?.message}
            className={classes.fullWidth}
          />
        </Box>
      </Box>
    </FormModal>
  );
};

export default SupportCoordinatorCreateFormModal;
