import { Box, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { _peopleMembers } from '@timed/app';
import { Protected } from '@timed/auth';
import {
  ClientContext,
  ClientUpdateColorForm,
  ClientUpdateNdisDetailsForm,
  ClientUpdatePersonalDetailsForm,
  ClientUpdateSchedulingForm,
} from '@timed/client';
import { isClient } from '@timed/client/components/Provider';
import { Block, formatPersonName, Link } from '@timed/common';
import { Permission } from '@timed/gql';
import { format } from 'date-fns';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8),
      },
    },
    wrapper: {
      display: 'grid',
      gridAutoRows: 'max-content',

      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
        marginRight: theme.spacing(8),
      },
    },
    archived: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },
  }),
);

const ClientViewOverview = () => {
  const classes = useStyles();
  const client = useContext(ClientContext);

  return (
    <Box className={classes.wrapper}>
      {client.archive && (
        <Block>
          <Box className={classes.archived}>
            <LockIcon fontSize="small" />
            <Typography>
              Archived on {format(new Date(client.archive.date), 'd MMM yyyy')} by{' '}
              <Link to={'../../' + client.archive.actor!.id}>
                {formatPersonName(client.archive.actor!)}
              </Link>
            </Typography>
          </Box>
        </Block>
      )}

      <ClientUpdatePersonalDetailsForm client={client} />

      {isClient(client) && (
        <>
          <Protected permissions={Permission.CLIENT_READ}>
            <ClientUpdateColorForm client={client} />
          </Protected>
          <Protected admin>
            <ClientUpdateSchedulingForm />
          </Protected>
          <Protected permissions={Permission.CLIENT_READ}>
            <ClientUpdateNdisDetailsForm />
          </Protected>
          <Protected admin>
            <Block>
              <Grid container spacing={4}>
                <Grid item xs={4} md={3} lg={2}>
                  <Typography>Created</Typography>
                </Grid>
                <Grid item xs={8} md={9} lg={10}>
                  <Typography>
                    {format(new Date(client.createdAt), 'h:mm a, dd MMMM yyyy')}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={3} lg={2}>
                  <Typography>Creator</Typography>
                </Grid>
                <Grid item xs={8} md={9} lg={10}>
                  <Link to={_peopleMembers.path + '/' + client.createdBy?.id}>
                    <Typography>
                      {client.createdBy && formatPersonName(client.createdBy)}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Block>
          </Protected>
        </>
      )}
    </Box>
  );
};

export default ClientViewOverview;
