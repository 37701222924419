import {
  Badge,
  Box,
  Container,
  createStyles,
  Divider,
  Hidden,
  makeStyles,
  Paper,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import LockIcon from '@material-ui/icons/Lock';
import { Protected, useAuth } from '@timed/auth';
import { Avatar, Button, formatPermissions, formatPersonName, useRouter } from '@timed/common';
import {
  EntityState,
  Member,
  OrderBy,
  Permission,
  RedactedMember,
  useGetMembersLazyQuery,
  useGetRedactedMembersLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import {
  isMember,
  MemberCreateFormModal,
  MemberListCancelInviteChip,
  MemberListSearch,
} from '@timed/member';
import { differenceInMinutes } from 'date-fns';
import { useModal } from 'mui-modal-provider';
import { useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controls: {
      display: 'flex',
      gap: theme.spacing(4),
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
      },
    },
    root: {
      overflowY: 'scroll',
    },
    wrapper: {
      display: 'grid',
      // marginBottom: theme.spacing(4),
    },
    paper: {
      borderRadius: theme.shape.borderRadius,
      flex: '1 1 auto',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(8),
      },
    },
    profile: {
      // borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
      padding: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: 'max-content auto max-content',
      alignItems: 'center',
      gap: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    name: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'grid',
      gap: theme.spacing(0),
    },
    avatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      fontSize: 12,
    },
    avatarArchivedIcon: {
      fontSize: '14px',
      border: `1px solid ${theme.palette.background.paper}`,
      borderRadius: 6,
      backgroundColor: theme.palette.background.paper,
    },
    character: {
      fontWeight: theme.typography.fontWeightMedium,
      margin: theme.spacing(4, 0, 2, 0),
    },
    chips: {
      display: 'inline-grid',
      gridAutoColumns: 'max-content',
      justifyItems: 'flex-end',
      gap: theme.spacing(2),
    },
    checklist: {
      padding: theme.spacing(1, 2),
      display: 'grid',
      gridAutoColumns: 'max-content',
      justifyItems: 'flex-end',
      gap: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.main,
    },
    check: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  }),
);

const ActiveBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
)(Badge);

const MemberList = () => {
  const classes = useStyles();
  const auth = useAuth();
  const { navigate } = useRouter();
  const [searchTerm, setSearchTerm] = useState<string>();
  const { showModal } = useModal();
  // const { data, loading } = useGetMembersQuery({
  //   variables: {
  //     input: {
  //       orderBy: { lastName: OrderBy.ASC, firstName: OrderBy.ASC, createdAt: OrderBy.ASC },
  //       entityStates: [EntityState.NORMAL, EntityState.ARCHIVED],
  //     },
  //   },
  // });
  const hasPermission = useMemo(() => {
    return auth.permissible({ permissions: [Permission.MEMBER_READ] });
  }, [auth]);

  const [getMembers, membersResponse] = useGetMembersLazyQuery({
    variables: {
      input: {
        orderBy: [
          { lastName: OrderBy.ASC },
          { firstName: OrderBy.ASC },
          { createdAt: OrderBy.ASC },
        ],
        entityStates: hasPermission
          ? [EntityState.NORMAL, EntityState.ARCHIVED]
          : [EntityState.NORMAL],
      },
    },
  });

  const [getRedactedMembers, redactedMembersResponse] = useGetRedactedMembersLazyQuery({
    variables: { input: { orderBy: [{ lastName: OrderBy.ASC }, { firstName: OrderBy.ASC }] } },
  });

  const data = hasPermission
    ? membersResponse.data?.members
    : redactedMembersResponse.data?.redactedMembers;

  const loading = hasPermission ? membersResponse.loading : redactedMembersResponse.loading;

  useLoadingEffect(loading);

  useEffect(() => {
    if (!data) hasPermission ? getMembers() : getRedactedMembers();
  }, [getMembers, getRedactedMembers, data, hasPermission]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleOpenModal = () => {
    const modal: { hide: () => void } = showModal(MemberCreateFormModal, {
      onClose: () => modal.hide(),
    });
  };

  const handleNavigate = (memberId: Member['id']) => (_: React.KeyboardEvent | React.MouseEvent) =>
    navigate(memberId);

  const members = useMemo<{
    archived: Pick<Member, 'id' | 'firstName' | 'lastName'>[];
    normal: Pick<Member, 'id' | 'firstName' | 'lastName'>[];
  }>(() => {
    if (!data) return { archived: [], normal: [] };

    const filteredMembers = searchTerm
      ? (data as Member[]).filter(
          (member) =>
            formatPersonName(member, { middle: true }).toLowerCase().includes(searchTerm) ||
            formatPersonName(member, { preferred: true }).toLowerCase().includes(searchTerm),
        )
      : data;

    const archived = (filteredMembers as Member[]).filter((m) => isMember(m) && !!m.archive);

    const normal = (filteredMembers as Member[]).filter(
      (m) => !archived.map((a) => a.id).includes(m.id),
    );

    return { archived, normal };
  }, [data, searchTerm]);

  const characters: [string[], string[]] = useMemo(() => {
    const characters: string[] = [];
    const charactersArchived: string[] = [];

    if (!data) return [[], []];

    if (isMember(data[0])) {
      data.forEach((m) => {
        if (
          !(m as Member).archive &&
          !characters.includes(
            m.lastName?.charAt(0).toUpperCase() || m.firstName?.charAt(0).toUpperCase(),
          )
        )
          characters.push(
            m.lastName?.charAt(0).toUpperCase() || m.firstName?.charAt(0).toUpperCase(),
          );

        if (
          !!(m as Member) &&
          !charactersArchived.includes(
            m.lastName?.charAt(0).toUpperCase() || m.firstName?.charAt(0).toUpperCase(),
          )
        )
          charactersArchived.push(
            m.lastName?.charAt(0).toUpperCase() || m.firstName?.charAt(0).toUpperCase(),
          );
      });
    } else {
      members.normal.forEach(
        (m) =>
          !characters.includes(
            m.lastName?.charAt(0).toUpperCase() || m.firstName?.charAt(0).toUpperCase(),
          ) &&
          characters.push(
            m.lastName?.charAt(0).toUpperCase() || m.firstName?.charAt(0).toUpperCase(),
          ),
      );
    }

    return [
      characters.sort((a, b) => a.localeCompare(b)),
      charactersArchived.sort((a, b) => a.localeCompare(b)),
    ];
  }, [members, data]);

  if (loading || !data) return <>Loading...</>;

  return (
    <>
      <Box className={classes.controls}>
        <MemberListSearch handleSearch={handleSearch} />
        <Protected permissions={Permission.MEMBER_WRITE}>
          <Box>
            <Button onClick={() => handleOpenModal()} variant="contained" color="primary">
              Create
            </Button>
          </Box>
        </Protected>
      </Box>

      {members && (!!members.archived.length || !!members.normal.length) ? (
        <Box className={classes.root}>
          {characters[0].map((character) => (
            <Box className={classes.wrapper}>
              <Box key={character} className={classes.character}>
                {character}
              </Box>
              <Paper className={classes.paper}>
                {(members.normal as RedactedMember[])
                  .filter(
                    ({ lastName, firstName }) =>
                      (lastName?.charAt(0).toUpperCase() || firstName?.charAt(0).toUpperCase()) ===
                      character,
                  )
                  .map((m, i) => (
                    <>
                      {i !== 0 && <Divider />}
                      <Box className={classes.profile} onClick={handleNavigate(m.id)}>
                        {isMember(m) && m.archive ? (
                          <Badge
                            overlap="circle"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                              <LockIcon fontSize="small" className={classes.avatarArchivedIcon} />
                            }
                          >
                            <Avatar
                              aria-label="avatar"
                              className={classes.avatar}
                              color={m.color}
                              content={[m.firstName, m.lastName]}
                            />
                          </Badge>
                        ) : isMember(m) &&
                          m.lastActiveAt &&
                          differenceInMinutes(new Date(), new Date(m.lastActiveAt)) <= 15 ? (
                          <ActiveBadge
                            overlap="circle"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                          >
                            <Avatar
                              aria-label="avatar"
                              className={classes.avatar}
                              color={m.color}
                              content={[m.firstName, m.lastName]}
                            />
                          </ActiveBadge>
                        ) : (
                          <Avatar
                            aria-label="avatar"
                            className={classes.avatar}
                            color={m.color}
                            content={[m.firstName, m.lastName]}
                          />
                        )}
                        <Box className={classes.name}>
                          <Typography color="textPrimary">
                            {formatPersonName(m, {
                              lastNameFirst: true,
                              capitaliseLastName: true,
                              middle: true,
                            })}
                          </Typography>
                          {isMember(m) && auth.permissible({ admin: true }) && (
                            <Typography color="textSecondary" variant="body2">
                              {formatPermissions(m)}
                            </Typography>
                          )}
                        </Box>

                        {isMember(m) && (
                          <Hidden smDown>
                            <Box className={classes.chips}>
                              {m.archive && (
                                <Box className={classes.check}>
                                  <Typography noWrap variant="body2">
                                    Archived
                                  </Typography>
                                  <LockIcon fontSize="small" />
                                </Box>
                              )}

                              {m.hasAccess && (
                                <Box className={classes.checklist}>
                                  {m.hasAccess && (
                                    <Box className={classes.check}>
                                      <Typography noWrap variant="body2">
                                        Has access
                                      </Typography>
                                      <DoneRoundedIcon fontSize="small" />
                                    </Box>
                                  )}
                                  {m.externalId && (
                                    <Box className={classes.check}>
                                      <Typography noWrap variant="body2">
                                        MYOB ID: {m.externalId}
                                      </Typography>
                                      <DoneRoundedIcon fontSize="small" />
                                    </Box>
                                  )}
                                </Box>
                              )}
                              {m.invitationMostRecentlyReceived &&
                                !m.invitationMostRecentlyReceived?.deletedAt &&
                                !m.invitationMostRecentlyReceived?.usedAt && (
                                  <MemberListCancelInviteChip memberId={m.id} />
                                )}
                            </Box>
                          </Hidden>
                        )}
                      </Box>
                    </>
                  ))}
              </Paper>
            </Box>
          ))}
          {characters[1].map((character) => (
            <Box className={classes.wrapper}>
              <Box key={character} className={classes.character}>
                {character}
              </Box>
              <Paper className={classes.paper}>
                {(members.archived as RedactedMember[])
                  .filter(
                    ({ lastName, firstName }) =>
                      (lastName?.charAt(0).toUpperCase() || firstName?.charAt(0).toUpperCase()) ===
                      character,
                  )
                  .map((m, i) => (
                    <>
                      {i !== 0 && <Divider />}
                      <Box className={classes.profile} onClick={handleNavigate(m.id)}>
                        {isMember(m) && m.archive ? (
                          <Badge
                            overlap="circle"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                              <LockIcon fontSize="small" className={classes.avatarArchivedIcon} />
                            }
                          >
                            <Avatar
                              aria-label="avatar"
                              className={classes.avatar}
                              color={m.color}
                              content={[m.firstName, m.lastName]}
                            />
                          </Badge>
                        ) : isMember(m) &&
                          m.lastActiveAt &&
                          differenceInMinutes(new Date(), new Date(m.lastActiveAt)) <= 15 ? (
                          <ActiveBadge
                            overlap="circle"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                          >
                            <Avatar
                              aria-label="avatar"
                              className={classes.avatar}
                              color={m.color}
                              content={[m.firstName, m.lastName]}
                            />
                          </ActiveBadge>
                        ) : (
                          <Avatar
                            aria-label="avatar"
                            className={classes.avatar}
                            color={m.color}
                            content={[m.firstName, m.lastName]}
                          />
                        )}
                        <Box className={classes.name}>
                          <Typography color="textPrimary">
                            {formatPersonName(m, {
                              lastNameFirst: true,
                              capitaliseLastName: true,
                              middle: true,
                            })}
                          </Typography>
                          {isMember(m) && auth.permissible({ admin: true }) && (
                            <Typography color="textSecondary" variant="body2">
                              {formatPermissions(m)}
                            </Typography>
                          )}
                        </Box>

                        {isMember(m) && (
                          <Hidden smDown>
                            <Box className={classes.chips}>
                              {m.archive && (
                                <Box className={classes.check}>
                                  <Typography noWrap variant="body2">
                                    Archived
                                  </Typography>
                                  <LockIcon fontSize="small" />
                                </Box>
                              )}

                              {m.hasAccess && (
                                <Box className={classes.checklist}>
                                  {m.hasAccess && (
                                    <Box className={classes.check}>
                                      <Typography noWrap variant="body2">
                                        Has access
                                      </Typography>
                                      <DoneRoundedIcon fontSize="small" />
                                    </Box>
                                  )}
                                  {m.externalId && (
                                    <Box className={classes.check}>
                                      <Typography noWrap variant="body2">
                                        MYOB ID: {m.externalId}
                                      </Typography>
                                      <DoneRoundedIcon fontSize="small" />
                                    </Box>
                                  )}
                                </Box>
                              )}
                              {m.invitationMostRecentlyReceived &&
                                !m.invitationMostRecentlyReceived?.deletedAt &&
                                !m.invitationMostRecentlyReceived?.usedAt && (
                                  <MemberListCancelInviteChip memberId={m.id} />
                                )}
                            </Box>
                          </Hidden>
                        )}
                      </Box>
                    </>
                  ))}
              </Paper>
            </Box>
          ))}
        </Box>
      ) : (
        <Container>No results</Container>
      )}
    </>
  );
};

export default MemberList;
