export enum MIME {
  // PDF
  PDF = 'application/pdf', // PDF

  // Microsoft Word (OpenXML)
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

  // Microsoft Excel (OpenXML)
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  // JPEG images
  JPG = 'image/jpeg',

  // GIF images
  GIF = 'image/gif',

  // PNG images
  PNG = 'image/png',
}
