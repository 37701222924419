import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hours: {
      zIndex: 20,
      top: -10,
      right: theme.spacing(2),
      position: 'relative',
      flex: '0 1 ' + theme.spacing(12) + 'px',
      display: 'flex',
      flexFlow: 'column',
      fontSize: 12,
      textAlign: 'right',
      '& div': {
        flex: '1 1 0',
        boxSizing: 'border-box',
      },
    },
  }),
);

const ScheduleCalendarTimes = () => {
  const classes = useStyles();

  return (
    <div className={classes.hours}>
      <div></div>
      <div>1 am</div>
      <div>2 am</div>
      <div>3 am</div>
      <div>4 am</div>
      <div>5 am</div>
      <div>6 am</div>
      <div>7 am</div>
      <div>8 am</div>
      <div>9 am</div>
      <div>10 am</div>
      <div>11 am</div>
      <div>12 pm</div>
      <div>1 pm</div>
      <div>2 pm</div>
      <div>3 pm</div>
      <div>4 pm</div>
      <div>5 pm</div>
      <div>6 pm</div>
      <div>7 pm</div>
      <div>8 pm</div>
      <div>9 pm</div>
      <div>10 pm</div>
      <div>11 pm</div>
    </div>
  );
};

export default ScheduleCalendarTimes;
