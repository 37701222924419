import { Typography } from '@material-ui/core';
import { useAuth } from '@timed/auth';
import { useRouter } from '@timed/common';
import {
  Member,
  Permission,
  RedactedMember,
  useGetMemberByIdLazyQuery,
  useGetRedactedMemberByIdLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { MemberContext } from '@timed/member';
import { ErrorNotFound } from '@timed/routes';
import React, { useEffect, useMemo } from 'react';

type MemberProviderProps = React.PropsWithChildren<{}>;

export const isMember = (
  entity: Pick<Member, 'id' | 'externalId'> | Pick<RedactedMember, 'id'>,
): entity is Member => entity.hasOwnProperty('externalId');

const MemberProvider = ({ children }: MemberProviderProps) => {
  const { query } = useRouter();

  const auth = useAuth();

  const [memberById, memberResponse] = useGetMemberByIdLazyQuery({
    variables: { id: query.member as string },
  });

  const [redactedMemberById, redactedMemberResponse] = useGetRedactedMemberByIdLazyQuery({
    variables: { id: query.member as string },
  });

  const canFetchNonRedactedMembers = useMemo(() => {
    return auth.permissible({
      permissions: [Permission.CLIENT_READ],
    });
  }, [auth]);

  const data = canFetchNonRedactedMembers
    ? memberResponse.data?.memberById
    : redactedMemberResponse.data?.redactedMemberById;

  const loading = canFetchNonRedactedMembers
    ? memberResponse.loading
    : redactedMemberResponse.loading;

  const error = canFetchNonRedactedMembers ? memberResponse.error : redactedMemberResponse.error;

  useEffect(() => {
    if (!data) canFetchNonRedactedMembers ? memberById() : redactedMemberById();
  }, [data, canFetchNonRedactedMembers, memberById, redactedMemberById, query.member]);

  useLoadingEffect(loading);

  if (loading) return <Typography variant="body2">Loading...</Typography>;

  if (!query || !data || error) return <ErrorNotFound />;

  return <MemberContext.Provider value={{ ...data }}>{children}</MemberContext.Provider>;
};

export default MemberProvider;
