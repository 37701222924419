import { ClientContext } from '@timed/client';
import ClientUpdateTeamForm from '@timed/client/components/UpdateTeamForm/UpdateTeamForm';
import React, { useContext } from 'react';

const ClientViewTeam = () => {
  const client = useContext(ClientContext);

  return <ClientUpdateTeamForm client={client} />;
};

export default ClientViewTeam;
