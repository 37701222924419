import { _shiftsAdminMissingNotes } from '@timed/app/constants/metadata';
import { Page } from '@timed/page';
import { ShiftsMissingNotes } from '@timed/shifts';

const ShiftsAdminMissingNotes = () => (
  <Page {..._shiftsAdminMissingNotes}>
    <ShiftsMissingNotes />
  </Page>
);

export default ShiftsAdminMissingNotes;
