import { useAuth } from '@timed/auth';
import { Permission } from '@timed/gql';
import { isArray } from 'lodash';

type ProtectedProps = React.PropsWithChildren<{
  admin?: boolean;
  permissions?: Permission | Permission[];
  fallback?: React.ReactNode | string | number;
  requireAny?: boolean;
}>;

const Protected = ({
  children,
  fallback,
  admin,
  permissions,
  requireAny = false,
}: ProtectedProps) => {
  const { permissible } = useAuth();

  if (requireAny && !!permissions && isArray(permissions))
    return (
      <>
        {permissions.some((p) => permissible({ admin: admin, permissions: p }))
          ? children
          : fallback}
      </>
    );

  return <>{permissible({ admin: admin, permissions }) ? children : fallback}</>;
};

export default Protected;
