import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { blue, green, red } from '@material-ui/core/colors';
import { _schedule } from '@timed/app';
import { useAuth } from '@timed/auth';
import { formatPersonName, roundNumber, useRouter } from '@timed/common';
import { OrderBy, useScheduleMemberHoursInputQuery } from '@timed/gql';
import { setProfile } from '@timed/schedule/helpers';
import clsx from 'clsx';
import { addMinutes, isBefore, startOfWeek } from 'date-fns';
import { useMemo } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    wrapper: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      lineHeight: 'initial',
      '& .MuiTypography-root': {
        fontSize: 10,
      },
    },
    title: {
      fontSize: 11 + 'px !IMPORTANT',
    },
    container: {
      display: 'grid',
      gridTemplateColumns: 'min-content auto max-content max-content',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    hours: {
      textAlign: 'center',
    },
    icon: {
      fontSize: 8,
    },
    warning: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.warning.light,
    },
    error: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
    legend: {
      borderTop: '1px solid ' + theme.palette.divider,
      marginTop: theme.spacing(1),
      paddingTop: theme.spacing(1),
      display: 'grid',
      gridTemplateColumns: 'min-content auto',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    person: {
      maxWidth: '20ch',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: 'initial',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
    triangle: {
      width: 0,
      height: 0,
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderBottom: '8px solid ' + blue[500],
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: green[500],
    },
    blackCircle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: 'black',
    },
    totals: {
      fontWeight: theme.typography.fontWeightMedium,
      fontStyle: 'italic',
    },
  }),
);

const ScheduleMemberHours = () => {
  const classes = useStyles();

  const { permissible } = useAuth();

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const payrollPeriodStartAt = startOfWeek(new Date(), { weekStartsOn: 1 });

  const schedule = useScheduleMemberHoursInputQuery({
    variables: {
      membersInput: {
        date: payrollPeriodStartAt,
        members: {
          where: { schedulable: { _eq: true } },
          orderBy: [{ lastName: OrderBy.ASC }, { firstName: OrderBy.ASC }],
        },
      },
    },
    pollInterval: 60000,
    fetchPolicy: 'network-only',
  });

  const [totalWeekMinutes, totalFortnightMinutes] = useMemo<[number, number]>(
    () =>
      !schedule.data
        ? [0, 0]
        : [
            schedule.data?.schedule.members?.reduce((sum, current) => sum + current.weekTime, 0) ??
              0,
            schedule.data?.schedule.members?.reduce(
              (sum, current) => sum + current.fortnightTime,
              0,
            ) ?? 0,
          ],
    [schedule.data],
  );

  if (schedule.loading) return <>Loading...</>;

  const handleSetMember = (memberId: string) => {
    setProfile('member', searchParams, memberId);

    navigate(_schedule.path + '?' + searchParams);
  };

  return (
    <Box className={classes.wrapper}>
      <Typography variant="body1" className={clsx(classes.bold, classes.title)}>
        Payroll period scheduled hours
      </Typography>

      <Typography className={classes.container}>
        <Box></Box>
        <Typography variant="body1" className={classes.bold}>
          Support Worker
        </Typography>
        <Typography variant="body1" className={classes.bold}>
          Week
        </Typography>
        <Typography variant="body1" className={classes.bold}>
          Fort
        </Typography>
        {schedule.data?.schedule.members?.map(
          ({ member, weekTime, fortnightTime, currentEvent, nextEvent }) => {
            const nextShiftStartingSoon =
              !!nextEvent && isBefore(new Date(nextEvent.startAt), addMinutes(new Date(), 90));

            const iconClass = !!currentEvent
              ? !currentEvent.clockedOnAt
                ? classes.blackCircle
                : classes.circle
              : nextShiftStartingSoon
              ? classes.triangle
              : undefined;

            const weekHours = weekTime > 0 ? weekTime / 60 : 0;
            const fortnightHours = fortnightTime > 0 ? fortnightTime / 60 : 0;

            const weekHoursClass =
              weekHours > 38 ? classes.error : weekHours > 30 ? classes.warning : undefined;

            const fortnightHoursClass =
              fortnightHours > 76
                ? classes.error
                : fortnightHours > 60
                ? classes.warning
                : undefined;

            return (
              <>
                <Box>
                  {!!currentEvent || nextShiftStartingSoon ? <Box className={iconClass}></Box> : ''}
                </Box>
                <Box
                  onClick={() => {
                    setProfile('client', searchParams, undefined);
                    handleSetMember(member.id);
                  }}
                >
                  <Typography variant="body2" color="textSecondary" className={classes.person}>
                    {formatPersonName(member, { lastNameFirst: true, preferredAndLast: true })}
                  </Typography>
                </Box>
                <Box className={classes.hours}>
                  <Typography className={weekHoursClass}>{roundNumber(weekHours, 2)}</Typography>
                </Box>
                <Box className={classes.hours}>
                  <Typography className={fortnightHoursClass}>
                    {roundNumber(fortnightHours, 2)}
                  </Typography>
                </Box>
              </>
            );
          },
        )}

        {permissible({ admin: true }) && (
          <>
            <Box></Box>
            <Typography className={classes.totals}>Totals</Typography>
            <Box className={classes.hours}>
              <Typography className={classes.bold}>
                {roundNumber(totalWeekMinutes / 60, 2)}
              </Typography>
            </Box>
            <Box className={classes.hours}>
              <Typography className={classes.bold}>
                {roundNumber(totalFortnightMinutes / 60, 2)}
              </Typography>
            </Box>
          </>
        )}
      </Typography>
      <Box className={classes.legend}>
        <Box className={classes.blackCircle}></Box>
        <Typography variant="body2" color="textSecondary">
          Not clocked on
        </Typography>
        <Box className={classes.circle}></Box>
        <Typography variant="body2" color="textSecondary">
          On shift
        </Typography>
        <Box className={classes.triangle}></Box>
        <Typography variant="body2" color="textSecondary">
          Upcoming shift
        </Typography>
      </Box>
    </Box>
  );
};

export default ScheduleMemberHours;
