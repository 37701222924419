import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Block, formatPersonName } from '@timed/common';
import { Permission } from '@timed/gql';
import { Protected } from '@timed/auth';
import { MemberContext, MemberInviteCancelButton, MemberInviteSendButton } from '@timed/member';
import { format } from 'date-fns';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottom: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(2),
      minBlockSize: 'max-content',
    },
    input: {
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: theme.spacing(1),
    },
  }),
);

const MemberInviteBox = () => {
  const member = useContext(MemberContext);

  const classes = useStyles();

  const invitation = member.invitationMostRecentlyReceived;

  return (
    <Protected permissions={Permission.MEMBER_WRITE}>
      <Block
        title="Invite to organisation"
        topRight={
          <Box className={classes.input}>
            {invitation && !invitation.deletedAt && <MemberInviteCancelButton />}
            <MemberInviteSendButton />
          </Box>
        }
      >
        {!!member.email ? (
          <Typography>
            Grant {formatPersonName(member, { preferred: true })} access to online features by
            inviting them to your team.
          </Typography>
        ) : (
          <Typography>
            To grant {formatPersonName(member, { preferred: true })} access to online features
            please set either their email address or phone number.
          </Typography>
        )}

        {invitation && !invitation.deletedAt && (
          <Typography>
            Invitation last sent on the
            {format(new Date(invitation.lastSender.sentAt), " do 'of' MMMM 'at' h:mm aaaa ")}
            by {formatPersonName(invitation.lastSender.sentBy)}.
          </Typography>
        )}
      </Block>
    </Protected>
  );
};

export default MemberInviteBox;
