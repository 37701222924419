import { createContext, Dispatch, isValidElement } from 'react';

/**
 * Typography-based breadcrumb menu item
 */
export type CrumbText = {
  /**
   * Text to render
   */
  label: string;
  /**
   * Set the breadcrumb item as a module. If true:
   *  1. Text will be emphasised
   *  2. The deepest nested breadcrumb module will be displayed on the app bar
   *     for the mobile version.
   */
  isModule?: boolean;
  /**
   * Optional path to navigate to when clicked. If omitted, the breadcrumb will
   * not be rendered as a link.
   */
  path?: string;
  /**
   * Optional path to redirect to when clicked. Takes precedence over 'path'.
   * This item is automatically determined from metadata cache for default
   * breadcrumbs and is only needed when creating additional breadcrumbs for
   * the current contxt, i.e: Breadcrumbs[index (current context)][crumbs].
   */
  redirect?: string;
};

/**
 * Crumb can either be a custom JSX element or a typography based object.
 */
export type Crumb = CrumbText | JSX.Element;

/**
 * Breadcrumbs are structured in blocks. Each index of the top-level array
 * represents a block. Blocks are allocated to Page objects based on the
 * Page's level property, i.e: Breadcrumbs[Page.level][crumbs]. Each block
 * will be rendered in sequential order based on their index.
 */
export type Breadcrumbs = Crumb[][];

/**
 * Function to overwrite the specified breadcrumbs index with the provided items
 */
export type SetCrumbs = {
  /**
   * Optionally reset the entire breadcrumb menu. Automatically applied when
   * adding an item to the Page context with the 'newPage' flag set to true.
   */
  reset?: boolean;
  /**
   * Top-level index of the breadcrumb array to overwrite. Automatically set as
   * the page's level when adding to the Page context.
   */
  index: number;
  /**
   * Array of breadcrumb menu items. Can be any length.
   */
  crumbs: Crumb[];
};

type AppBarContextType = {
  /**
   * Multidimensional array containing the entire breadcrumbs menu. Cannot be
   * written to directly.
   */
  breadcrumbs: Readonly<Breadcrumbs>;
  /**
   * Function to overwrite the specified breadcrumbs index with the provided items
   */
  setCrumbs: Dispatch<SetCrumbs>;
  /**
   * Function to retreive the deepest-nested breadcrumb 'isModule' menu item.
   */
  getLastCrumbModule: () => CrumbText | undefined;
};

/**
 * Type guard for breadcrumb menu items
 */
export const isCrumbAnElement = (item: Crumb): item is JSX.Element => isValidElement(item);

const AppBarContext = createContext<AppBarContextType>({
  breadcrumbs: [],
  setCrumbs: () => {},
  getLastCrumbModule: () => ({ label: '' }),
});

export default AppBarContext;
