import {} from '@timed/gql';

/**
 * Save profile id of the specified profile type.
 * @param id If blank, unsets existing value
 * @param searchParams Search params object to manipulate
 */
export const setProfile = (
  type: 'client' | 'member',
  searchParams: URLSearchParams,
  id?: string,
) => {
  if (id) {
    localStorage.setItem(`schedule.${type}`, id);
    searchParams.set(type.charAt(0), id);
  } else {
    searchParams.delete(type.charAt(0));
    localStorage.removeItem(`schedule.${type}`);
  }
};
