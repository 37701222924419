import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Block, Button, ButtonAsync, IconButton, Modal, ModalProps } from '@timed/common';
import { BlockProps } from '@timed/common/components/Block';
import { FormEventHandler } from 'react';

type FormModalProps = {
  modalProps: Omit<ModalProps, 'children'>;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  saveText?: string;
  cancelText?: string;
  extraControls?: JSX.Element;
  loading: boolean;
  success: boolean;
  disabled?: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  hideSave?: boolean;
  blockProps?: BlockProps;
  hasErrors?: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  onComplete?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      flexGrow: 1,
      display: 'flex',
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
  }),
);

const FormModal: React.FC<FormModalProps> = ({
  children,
  modalProps,
  title,
  description,
  saveText,
  cancelText,
  extraControls,
  loading,
  success,
  disabled,
  blockProps,
  onClose,
  onSubmit,
  onSuccess,
  onComplete,
  hasErrors: errors = false,
  hideSave = false,
}) => {
  const classes = useStyles();

  return (
    <Modal disablePortal {...modalProps} onClose={onClose}>
      <form onSubmit={onSubmit} className={classes.form}>
        <Block
          {...blockProps}
          modal
          title={title}
          description={description}
          topRight={
            <Box className={classes.controls}>
              {extraControls}
              <IconButton onClick={onClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Box>
          }
          bottomLeft={<Button onClick={onClose}>{cancelText || 'Cancel'}</Button>}
          bottomRight={
            hideSave ? undefined : (
              <ButtonAsync
                type="submit"
                color="primary"
                disabled={disabled || errors}
                loading={loading}
                success={success}
                onSuccess={onSuccess}
                onComplete={() => {
                  !!onComplete && onComplete();
                  onClose();
                }}
              >
                {saveText || 'Save'}
              </ButtonAsync>
            )
          }
        >
          {children}
        </Block>
      </form>
    </Modal>
  );
};

export default FormModal;
