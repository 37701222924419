import queryString from 'query-string';
import { useMemo } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const search = useSearchParams();

  return useMemo(() => {
    return {
      pathname: location.pathname,

      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      createSearchParams,
      search,
      location,
      navigate,
      prompt,
    };
  }, [params, location, navigate, search]);
};

export default useRouter;
