import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@material-ui/lab';
import { TextField } from '@timed/common';
import { Dispatch, SetStateAction } from 'react';

export type AutocompleteOption<T> = {
  label: string;
  value: T;
};

export type AutocompleteProps<T> = Omit<
  MuiAutocompleteProps<AutocompleteOption<T>, false, false, false>,
  'value'
> & {
  options: AutocompleteOption<T>[] | undefined;
  name: string;
  label?: string;
  value?: AutocompleteOption<T> | null;
  setValue: Dispatch<SetStateAction<AutocompleteOption<T> | null>>;
};

const Autocomplete = <T extends unknown>({
  name,
  label,
  defaultValue,
  onChange,
  options,
  value,
  setValue,
  renderInput,
  ...props
}: AutocompleteProps<T>) => (
  <MuiAutocomplete
    {...props}
    defaultValue={defaultValue}
    id={name}
    key={name}
    options={options}
    getOptionLabel={(option) => option.label}
    renderInput={
      renderInput || ((params) => <TextField {...params} label={label} variant="outlined" />)
    }
    onChange={(_, newValue) => setValue(newValue)}
  />
);

export default Autocomplete;
