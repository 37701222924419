import { Button } from '@timed/common';
import { ClientContext, ClientFileCreateFormModal } from '@timed/client';
import { useModal } from 'mui-modal-provider';
import React, { useContext } from 'react';

const ClientFileUploadButton = () => {
  const { showModal } = useModal();
  const client = useContext(ClientContext);

  const handleOpenModal = () => {
    const modal: { hide: () => void } = showModal(ClientFileCreateFormModal, {
      onClose: () => modal.hide(),
      client,
    });
  };

  return (
    <Button variant="contained" color="primary" onClick={handleOpenModal}>
      Upload
    </Button>
  );
};

export default ClientFileUploadButton;
