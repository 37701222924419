import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useAuth } from '@timed/auth';
import {
  ButtonAsync,
  Checkbox,
  ProfileInput,
  TextField,
  transformNumberToFixedFloat,
} from '@timed/common';
import {
  OrderBy,
  QueryByIdInput,
  useGetOrgSettingsByIdQuery,
  useUpdateOrgSchedulingMutation,
} from '@timed/gql';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  patch: {
    schedulingContactPerson: QueryByIdInput | null;
    schedulingContactPerson2: QueryByIdInput | null;
    membersCanSeeClockedTimes: boolean;
    runAutoAssignMemberTask: boolean;
    assignMemberToEventWeeksInAdvance: number | null;
    quantityOfFutureShiftsMembersCanSee: number | null;
    quantityOfPastShiftsMembersCanSee: number | null;
    unfilledEventsDayRange: number | null;
    clockOnWarnMinutes: number | null;
    clockOffWarnMinutes: number | null;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
);

const SettingsOrganisationSchedulingForm = () => {
  const classes = useStyles();

  const [updateOrg, updateResponse] = useUpdateOrgSchedulingMutation();

  const { handleSubmit, control, setValue, watch } = useForm<FormData>(); //({

  const { data, loading } = useGetOrgSettingsByIdQuery({ variables: { id: useAuth().org!.id } });

  const onSubmit = ({ patch }: FormData) => {
    if (!patch.schedulingContactPerson?.id) patch.schedulingContactPerson = null;
    if (!patch.schedulingContactPerson2?.id) patch.schedulingContactPerson2 = null;

    updateOrg({ variables: { input: { patch } } }).catch((e) => {});
  };

  useEffect(() => {
    if (data) {
      const {
        runAutoAssignMemberTask,
        assignMemberToEventWeeksInAdvance,
        membersCanSeeClockedTimes,
        quantityOfFutureShiftsMembersCanSee,
        quantityOfPastShiftsMembersCanSee,
        schedulingContactPerson,
        schedulingContactPerson2,
        unfilledEventsDayRange,
        clockOnWarnMinutes,
        clockOffWarnMinutes,
      } = data.orgById;

      setValue('patch.runAutoAssignMemberTask', runAutoAssignMemberTask || false);
      setValue(
        'patch.assignMemberToEventWeeksInAdvance',
        assignMemberToEventWeeksInAdvance || null,
      );
      setValue('patch.membersCanSeeClockedTimes', membersCanSeeClockedTimes || false);
      setValue(
        'patch.quantityOfFutureShiftsMembersCanSee',
        quantityOfFutureShiftsMembersCanSee || 0,
      );
      setValue('patch.quantityOfPastShiftsMembersCanSee', quantityOfPastShiftsMembersCanSee || 0);
      setValue(
        'patch.schedulingContactPerson',
        !!schedulingContactPerson ? { id: schedulingContactPerson.id } : null,
      );
      setValue(
        'patch.schedulingContactPerson2',
        !!schedulingContactPerson2 ? { id: schedulingContactPerson2.id } : null,
      );
      setValue('patch.unfilledEventsDayRange', unfilledEventsDayRange || 0);
      setValue('patch.clockOnWarnMinutes', clockOnWarnMinutes || 0);
      setValue('patch.clockOffWarnMinutes', clockOffWarnMinutes || 0);
    }
  }, [data, setValue]);

  if (loading) return <Typography>Loading</Typography>;

  if (!data) return <Typography>An error occured while fetching existing configuration</Typography>;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.wrapper}>
      <Typography className={classes.bold}>Scheduling contact person</Typography>
      <ProfileInput
        control={control}
        name="patch.schedulingContactPerson.id"
        chipProps={{ onDelete: () => setValue('patch.schedulingContactPerson.id', '') }}
        formControlProps={{ variant: 'outlined', size: 'small' }}
        type={'member'}
        orderBy={[{ lastName: OrderBy.ASC }]}
        where={{ phone: { _ne: null } }}
        watch={watch}
      />
      <Typography className={classes.bold}>Secondary scheduling contact person</Typography>
      <ProfileInput
        control={control}
        name="patch.schedulingContactPerson2.id"
        chipProps={{ onDelete: () => setValue('patch.schedulingContactPerson2.id', '') }}
        formControlProps={{ variant: 'outlined', size: 'small' }}
        type={'member'}
        orderBy={[{ lastName: OrderBy.ASC }]}
        where={{ phone: { _ne: null } }}
        watch={watch}
      />
      <Checkbox
        control={control}
        name="patch.membersCanSeeClockedTimes"
        label="Support workers can see their own clocked times."
      />
      <Checkbox
        control={control}
        name="patch.runAutoAssignMemberTask"
        label="Enable the auto-assign-support-worker cron job."
      />
      <Box>
        <Typography className={classes.bold}>
          Weeks in advanced to start automatically assigning support worker to shifts
        </Typography>
        <TextField
          control={control}
          name="patch.assignMemberToEventWeeksInAdvance"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 26, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Number of other-worker shifts a support worker can see in the future after their own
        </Typography>
        <TextField
          control={control}
          name="patch.quantityOfFutureShiftsMembersCanSee"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 10, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Number of other-worker shifts a support worker can see in the past before their own
        </Typography>
        <TextField
          control={control}
          name="patch.quantityOfPastShiftsMembersCanSee"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 10, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>Day range to check for unfilled shifts</Typography>
        <TextField
          control={control}
          name="patch.unfilledEventsDayRange"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 1, max: 100, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Number of minutes early/late for clock on highlights {'(Shifts -> Admin -> Approve)'}
        </Typography>
        <TextField
          control={control}
          name="patch.clockOnWarnMinutes"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 1, max: 100, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Number of minutes early/late for clock off highlights {'(Shifts -> Admin -> Approve)'}
        </Typography>
        <TextField
          control={control}
          name="patch.clockOffWarnMinutes"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 1, max: 100, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <ButtonAsync
        type="submit"
        variant="contained"
        color="primary"
        loading={updateResponse.loading}
        success={!!updateResponse.data}
      >
        Save
      </ButtonAsync>
    </form>
  );
};

export default SettingsOrganisationSchedulingForm;
