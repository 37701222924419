import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAlert } from '@timed/alert';
import {
  AddressInput,
  addServerErrors,
  EmailInput,
  FormModal,
  ModalProps,
  PersonNameFirstInput,
  PersonNameLastInput,
  PersonNameMiddleInput,
  PhoneInput,
} from '@timed/common';
import {
  Address,
  CreateMembersDocument,
  CreateMembersInput,
  Permission,
  useCreateMembersMutation,
} from '@timed/gql';
import {
  MemberBonusEligibleCheckbox,
  MemberHasCarInput,
  MemberHasDriversLicenseInput,
  MemberInviteCheckbox,
  MemberSchedulableCheckbox,
} from '@timed/member';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type MemberCreateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto',
      gap: theme.spacing(8),
    },
    fullWidth: {
      gridColumn: 'span 3',
    },
  }),
);

type FormData = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  permissions: Permission[];
  admin: boolean;
  schedulable: boolean;
  bonusEligible: boolean;
  hasDriversLicense: boolean | null;
  hasCar: boolean | null;
  invite: boolean;
  address?: Pick<
    Address,
    'unit' | 'street' | 'locality' | 'region' | 'postcode' | 'country' | 'latitude' | 'longitude'
  > | null;
};

const MemberCreateFormModal = ({ onClose, ...modalProps }: MemberCreateFormModalProps) => {
  const classes = useStyles();

  const alert = useAlert();

  const [createMembers, response] = useCreateMembersMutation();

  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: undefined,
      phone: undefined,
      firstName: '',
      middleName: undefined,
      lastName: undefined,
      invite: false,
      address: null,
      schedulable: true,
      bonusEligible: true,
      hasDriversLicense: null,
      hasCar: null,
    },
  });

  const watchEmail = watch('email');
  const watchPhone = watch('phone');

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    alert.push({
      message: 'Successfully created employee',
      severity: 'success',
    });

    const cache = response.client.cache;

    cache.modify({
      fields: {
        members(existing = []) {
          return [
            ...existing,
            cache.writeQuery({ data: response.data, query: CreateMembersDocument }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async (values: FormData) => {
    // To avoid graphql mutation errors, delete location object if it is empty
    (!values.address || Object.keys(values.address).length === 0) && delete values.address;

    // Format input for creating a single member entity
    let input: CreateMembersInput = {
      objects: [
        {
          unavailableMoMorn: false,
          unavailableMoDay: false,
          unavailableMoNight: false,
          unavailableTuMorn: false,
          unavailableTuDay: false,
          unavailableTuNight: false,
          unavailableWeMorn: false,
          unavailableWeDay: false,
          unavailableWeNight: false,
          unavailableThMorn: false,
          unavailableThDay: false,
          unavailableThNight: false,
          unavailableFrMorn: false,
          unavailableFrDay: false,
          unavailableFrNight: false,
          unavailableSaMorn: false,
          unavailableSaDay: false,
          unavailableSaNight: false,
          unavailableSuMorn: false,
          unavailableSuDay: false,
          unavailableSuNight: false,
          ...values.address,
          ..._.omit(values, 'address', 'invite'),
        },
      ],
      invite: values.invite,
    };

    createMembers({ variables: { input } });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Add employee"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.grid}>
          <PersonNameFirstInput
            required
            name="firstName"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
          />
          <PersonNameMiddleInput
            name="middleName"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.middleName}
            helperText={errors.middleName?.message}
          />
          <PersonNameLastInput
            name="lastName"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
          />
          <EmailInput
            name="email"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.email}
            helperText={errors.email?.message}
            className={classes.fullWidth}
          />
          <PhoneInput
            name="phone"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.phone}
            helperText={errors.phone?.message}
            className={classes.fullWidth}
          />
          <AddressInput
            name="address"
            control={control}
            clearErrors={clearErrors}
            setValue={setValue}
            setError={setError}
            error={!!errors.address}
            // helperText={errors.address?.message}
            placeholder="Residential address"
            className={classes.fullWidth}
          />
          <MemberHasDriversLicenseInput
            name="hasDriversLicense"
            label="Drivers license?"
            control={control}
          />
          <MemberHasCarInput name="hasCar" label="Has car?" control={control} />
          <MemberSchedulableCheckbox name="schedulable" control={control} />
          <MemberBonusEligibleCheckbox name="bonusEligible" control={control} />
          <MemberInviteCheckbox
            name="invite"
            control={control}
            formControlProps={{ className: classes.fullWidth }}
            disabled={!watchEmail && !watchPhone}
            error={!!errors.invite}
            helperText={errors.invite?.message}
          />
        </Box>
      </Box>
    </FormModal>
  );
};

export default MemberCreateFormModal;
