import { useAuth } from '@timed/auth';
import { PageMetadata, usePage } from '@timed/page';
import { ErrorForbidden } from '@timed/routes';
import { useEffect } from 'react';

type PageProps = React.PropsWithChildren<PageMetadata>;

const Page = ({
  /**
   * Note: Cannot use a spred operator on props in useEffect() dependency arrays as it would
   * cause infinite loops.
   */
  children,
  admin,
  permissions,
  level,
  label,
  newPage,
  path,
  isModule,
  redirect,
  state,
  controls,
  crumbs,
}: PageProps) => {
  const { permissible } = useAuth();
  const { addPage } = usePage();

  useEffect(
    () =>
      addPage({
        admin,
        permissions,
        level,
        label,
        newPage,
        path,
        isModule,
        redirect,
        state,
        controls,
        crumbs,
      }),
    [
      addPage,
      admin,
      permissions,
      level,
      label,
      newPage,
      path,
      isModule,
      redirect,
      state,
      controls,
      crumbs,
    ],
  );

  /**
   * This check will only prevent child nodes from rendering. To prevent
   * page metadata from being processed, PageProvider will also check permissions.
   */
  return (admin || permissions) && !permissible({ admin, permissions }) ? (
    <ErrorForbidden />
  ) : (
    <>{children}</>
  );
};

export default Page;
