import { red, yellow } from '@material-ui/core/colors';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ClientObservationDefaultThreshold } from '@timed/client/constants';
import { formatPersonName } from '@timed/common';
import { Client, ClientObservation, Member, PersonNamesFragment, StoolType } from '@timed/gql';
import { format } from 'date-fns';

/**
 * Options object for formatClientObservation() function
 */
type FormatClientObservation = {
  obs: Pick<
    ClientObservation,
    | 'bloodDiastolic'
    | 'bloodSystolic'
    | 'respiration'
    | 'date'
    | 'notes'
    | 'nursesName'
    | 'heart'
    | 'spo2'
    | 'temp'
    | 'stoolType'
  > & { member?: (Pick<Member, 'id'> & PersonNamesFragment) | null };
  limits?: Pick<
    Client,
    | 'bloodSystolicUpperThreshold1'
    | 'bloodSystolicLowerThreshold1'
    | 'bloodDiastolicUpperThreshold1'
    | 'bloodDiastolicLowerThreshold1'
    | 'heartUpperThreshold1'
    | 'heartLowerThreshold1'
    | 'spo2UpperThreshold1'
    | 'spo2LowerThreshold1'
    | 'respirationUpperThreshold1'
    | 'respirationLowerThreshold1'
    | 'bloodSystolicUpperThreshold2'
    | 'bloodSystolicLowerThreshold2'
    | 'bloodDiastolicUpperThreshold2'
    | 'bloodDiastolicLowerThreshold2'
    | 'heartUpperThreshold2'
    | 'heartLowerThreshold2'
    | 'spo2UpperThreshold2'
    | 'spo2LowerThreshold2'
    | 'respirationUpperThreshold2'
    | 'respirationLowerThreshold2'
  > & {
    tempLowerThreshold1?: number;
    tempLowerThreshold2?: number;
    tempUpperThreshold1?: number;
    tempUpperThreshold2?: number;
  };
};

/**
 * Format a person's name
 */
export const formatObservation = ({ obs, limits }: FormatClientObservation): any => {
  const level1Style: CSSProperties = {
    backgroundColor: yellow[600],
    color: 'white',
  };

  const level2Style: CSSProperties = {
    backgroundColor: red[600],
    color: 'white',
  };

  limits = {
    bloodSystolicLowerThreshold1:
      limits?.bloodSystolicLowerThreshold1 ??
      ClientObservationDefaultThreshold.BLOOD_SYSTOLIC_LOWER_1,
    bloodSystolicLowerThreshold2:
      limits?.bloodSystolicLowerThreshold2 ??
      ClientObservationDefaultThreshold.BLOOD_SYSTOLIC_LOWER_2,

    bloodSystolicUpperThreshold1:
      limits?.bloodSystolicUpperThreshold1 ??
      ClientObservationDefaultThreshold.BLOOD_SYSTOLIC_UPPER_1,
    bloodSystolicUpperThreshold2:
      limits?.bloodSystolicUpperThreshold2 ??
      ClientObservationDefaultThreshold.BLOOD_SYSTOLIC_UPPER_2,

    bloodDiastolicLowerThreshold1:
      limits?.bloodDiastolicLowerThreshold1 ??
      ClientObservationDefaultThreshold.BLOOD_DIASTOLIC_LOWER_1,
    bloodDiastolicLowerThreshold2:
      limits?.bloodDiastolicLowerThreshold2 ??
      ClientObservationDefaultThreshold.BLOOD_DIASTOLIC_LOWER_2,

    bloodDiastolicUpperThreshold1:
      limits?.bloodDiastolicUpperThreshold1 ??
      ClientObservationDefaultThreshold.BLOOD_DIASTOLIC_UPPER_1,
    bloodDiastolicUpperThreshold2:
      limits?.bloodDiastolicUpperThreshold2 ??
      ClientObservationDefaultThreshold.BLOOD_DIASTOLIC_UPPER_2,

    heartLowerThreshold1:
      limits?.heartLowerThreshold1 ?? ClientObservationDefaultThreshold.HEART_LOWER_1,
    heartLowerThreshold2:
      limits?.heartLowerThreshold2 ?? ClientObservationDefaultThreshold.HEART_LOWER_2,

    heartUpperThreshold1:
      limits?.heartUpperThreshold1 ?? ClientObservationDefaultThreshold.HEART_UPPER_1,
    heartUpperThreshold2:
      limits?.heartUpperThreshold2 ?? ClientObservationDefaultThreshold.HEART_UPPER_2,

    tempLowerThreshold1:
      limits?.tempLowerThreshold1 ?? ClientObservationDefaultThreshold.TEMP_LOWER_1,
    tempLowerThreshold2:
      limits?.tempLowerThreshold2 ?? ClientObservationDefaultThreshold.TEMP_LOWER_2,

    tempUpperThreshold1:
      limits?.tempUpperThreshold1 ?? ClientObservationDefaultThreshold.TEMP_UPPER_1,
    tempUpperThreshold2:
      limits?.tempUpperThreshold2 ?? ClientObservationDefaultThreshold.TEMP_UPPER_2,

    spo2LowerThreshold1:
      limits?.spo2LowerThreshold1 ?? ClientObservationDefaultThreshold.SPO2_LOWER_1,
    spo2LowerThreshold2:
      limits?.spo2LowerThreshold2 ?? ClientObservationDefaultThreshold.SPO2_LOWER_2,

    respirationLowerThreshold1:
      limits?.respirationLowerThreshold1 ?? ClientObservationDefaultThreshold.RESPIRATION_LOWER_1,
    respirationLowerThreshold2:
      limits?.respirationLowerThreshold2 ?? ClientObservationDefaultThreshold.RESPIRATION_LOWER_2,

    respirationUpperThreshold1:
      limits?.respirationUpperThreshold1 ?? ClientObservationDefaultThreshold.RESPIRATION_UPPER_1,
    respirationUpperThreshold2:
      limits?.respirationUpperThreshold2 ?? ClientObservationDefaultThreshold.RESPIRATION_UPPER_2,
  };

  const date = (
    <span style={{ fontWeight: 'bold' }}>{format(new Date(obs.date), 'd/M/yy HH:mm')}</span>
  );

  const bloodPressure =
    obs.bloodSystolic !== undefined &&
    obs.bloodSystolic !== null &&
    obs.bloodDiastolic !== undefined &&
    obs.bloodDiastolic !== null ? (
      <>
        <span
          style={
            (!!limits.bloodSystolicUpperThreshold2 &&
              obs.bloodSystolic >= limits.bloodSystolicUpperThreshold2) ||
            (!!limits.bloodSystolicLowerThreshold2 &&
              obs.bloodSystolic <= limits.bloodSystolicLowerThreshold2)
              ? level2Style
              : (!!limits.bloodSystolicUpperThreshold1 &&
                  obs.bloodSystolic >= limits.bloodSystolicUpperThreshold1) ||
                (!!limits.bloodSystolicLowerThreshold1 &&
                  obs.bloodSystolic <= limits.bloodSystolicLowerThreshold1)
              ? level1Style
              : undefined
          }
        >
          {obs.bloodSystolic}
        </span>
        /<span>{obs.bloodDiastolic}</span>
        {/* <span
          style={
            (!!limits.bloodDiastolicUpperThreshold2 &&
              obs.bloodDiastolic >= limits.bloodDiastolicUpperThreshold2) ||
            (!!limits.bloodDiastolicLowerThreshold2 &&
              obs.bloodDiastolic <= limits.bloodDiastolicLowerThreshold2)
              ? level2Style
              : (!!limits.bloodDiastolicUpperThreshold1 &&
                  obs.bloodDiastolic >= limits.bloodDiastolicUpperThreshold1) ||
                (!!limits.bloodDiastolicLowerThreshold1 &&
                  obs.bloodDiastolic <= limits.bloodDiastolicLowerThreshold1)
              ? level1Style
              : undefined
          }
        >
          {obs.bloodDiastolic}
        </span> */}
      </>
    ) : undefined;

  const heart =
    obs.heart !== null && obs.heart !== undefined ? (
      <span
        style={
          (!!limits.heartUpperThreshold2 && obs.heart >= limits.heartUpperThreshold2) ||
          (!!limits.heartLowerThreshold2 && obs.heart <= limits.heartLowerThreshold2)
            ? level2Style
            : (!!limits.heartUpperThreshold1 && obs.heart >= limits.heartUpperThreshold1) ||
              (!!limits.heartLowerThreshold1 && obs.heart <= limits.heartLowerThreshold1)
            ? level1Style
            : undefined
        }
      >
        {obs.heart}
      </span>
    ) : undefined;

  const temp =
    obs.temp !== null && obs.temp !== undefined ? (
      <span
        style={
          (!!limits.tempUpperThreshold2 && obs.temp >= limits.tempUpperThreshold2) ||
          (!!limits.tempLowerThreshold2 && obs.temp <= limits.tempLowerThreshold2)
            ? level2Style
            : (!!limits.tempUpperThreshold1 && obs.temp >= limits.tempUpperThreshold1) ||
              (!!limits.tempLowerThreshold1 && obs.temp <= limits.tempLowerThreshold1)
            ? level1Style
            : undefined
        }
      >
        {obs.temp.toFixed(1)}
      </span>
    ) : undefined;

  const spo2 =
    obs.spo2 !== null && obs.spo2 !== undefined ? (
      <span
        style={
          (!!limits.spo2UpperThreshold2 && obs.spo2 >= limits.spo2UpperThreshold2) ||
          (!!limits.spo2LowerThreshold2 && obs.spo2 <= limits.spo2LowerThreshold2)
            ? level2Style
            : (!!limits.spo2UpperThreshold1 && obs.spo2 >= limits.spo2UpperThreshold1) ||
              (!!limits.spo2LowerThreshold1 && obs.spo2 <= limits.spo2LowerThreshold1)
            ? level1Style
            : undefined
        }
      >
        {obs.spo2}%
      </span>
    ) : undefined;

  const respiration =
    obs.respiration !== null && obs.respiration !== undefined ? (
      <span
        style={
          (!!limits.respirationUpperThreshold2 &&
            obs.respiration >= limits.respirationUpperThreshold2) ||
          (!!limits.respirationLowerThreshold2 &&
            obs.respiration <= limits.respirationLowerThreshold2)
            ? level2Style
            : (!!limits.respirationUpperThreshold1 &&
                obs.respiration >= limits.respirationUpperThreshold1) ||
              (!!limits.respirationLowerThreshold1 &&
                obs.respiration <= limits.respirationLowerThreshold1)
            ? level1Style
            : undefined
        }
      >
        {obs.respiration}
      </span>
    ) : undefined;

  const stoolType =
    obs.stoolType !== null && obs.stoolType !== undefined ? (
      <span
        style={
          obs.stoolType === StoolType.NIL
            ? undefined
            : obs.stoolType >= StoolType.TYPE6 || obs.stoolType <= StoolType.TYPE2
            ? level2Style
            : obs.stoolType >= StoolType.TYPE5 || obs.stoolType <= StoolType.TYPE3
            ? level1Style
            : undefined
        }
      >
        BM {obs.stoolType.replace('TYPE', 'Type ')}
      </span>
    ) : undefined;

  const notes = !!obs.notes ? <span>{obs.notes}</span> : undefined;

  const nursesName = !!obs.nursesName ? <span>{obs.nursesName}</span> : undefined;

  const member = !!obs.member ? (
    <span>{formatPersonName(obs.member, { initials: true })}</span>
  ) : undefined;

  return [
    date,
    bloodPressure,
    heart,
    temp,
    spo2,
    respiration,
    stoolType,
    nursesName,
    member,
    notes,
  ];
};
