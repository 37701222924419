import {
  Box,
  createStyles,
  Divider,
  Grid,
  Link as MuiLink,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';
import { Protected } from '@timed/auth';
import { isClient } from '@timed/client/components/Provider';
import {
  AddressInput,
  addServerErrors,
  Block,
  DateInput,
  EmailInput,
  formatAddress,
  formatAge,
  formatPronouns,
  GenderInput,
  IconButtonMulti,
  Link,
  PersonNameFirstInput,
  PersonNameInput,
  PersonNameLastInput,
  PersonNameMiddleInput,
  PersonNamePreferredInput,
  PhoneInput,
  PronounsInput,
  SalutationInput,
} from '@timed/common';
import {
  Address,
  Client,
  Gender,
  Permission,
  Pronouns,
  Salutation,
  useUpdateClientsMutation,
} from '@timed/gql';
import { format } from 'date-fns';
import _, { camelCase, isEqual, pick, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type ClientUpdatePersonalDetailsFormProps = React.PropsWithChildren<{
  client: Pick<
    Client,
    | 'id'
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'preferredName'
    | 'email'
    | 'phone'
    | 'dob'
    | 'age'
    | 'gender'
    | 'salutation'
    | 'pronouns'
    | 'unit'
    | 'street'
    | 'locality'
    | 'region'
    | 'postcode'
    | 'country'
    | 'latitude'
    | 'longitude'
    | 'contactsName'
    | 'contactsEmail'
    | 'contactsPhone'
  >;
}>;

type FormData = {
  patch: {
    firstName: string;
    lastName: string;
    middleName: string;
    preferredName: string;
    email: string;
    phone: string;
    dob?: Client['dob'];
    gender: Gender;
    salutation: Salutation;
    pronouns: Pronouns;
    contactsName: string;
    contactsEmail: string;
    contactsPhone: string;
    address: Pick<
      Address,
      'unit' | 'street' | 'locality' | 'region' | 'postcode' | 'country' | 'latitude' | 'longitude'
    > | null;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      height: 18,
    },
  }),
);

const ClientUpdatePersonalDetailsForm = ({ client }: ClientUpdatePersonalDetailsFormProps) => {
  const classes = useStyles();

  const [editing, setEditing] = useState<boolean>(false);

  const [updateClients, response] = useUpdateClientsMutation();

  const defaultValues: FormData = {
    patch: {
      firstName: client.firstName,
      middleName: client.middleName || '',
      lastName: client.lastName || '',
      preferredName: client.preferredName || '',
      email: client.email || '',
      phone: client.phone || '',
      dob: client.dob,
      gender: client.gender || Gender.NOTSPECIFIED,
      salutation: client.salutation || Salutation.NOTSPECIFIED,
      pronouns: client.pronouns || Pronouns.NOTSPECIFIED,
      contactsName: client.contactsName || '',
      contactsEmail: client.contactsEmail || '',
      contactsPhone: client.contactsPhone || '',
      address:
        ((client.unit ||
          client.street ||
          client.locality ||
          client.region ||
          client.postcode ||
          client.country ||
          client.latitude ||
          client.longitude) &&
          pick(client, [
            'unit',
            'street',
            'locality',
            'region',
            'postcode',
            'country',
            'latitude',
            'longitude',
          ])) ||
        null,
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) => {
    // To avoid graphql mutation errors, delete location object if it is empty
    if (!values.patch.address || Object.keys(values.patch.address).length === 0) {
      values.patch.address = {
        unit: null,
        street: null,
        locality: null,
        region: null,
        postcode: null,
        country: null,
        latitude: null,
        longitude: null,
      };
    }

    updateClients({
      variables: {
        input: {
          ids: [client.id],
          patch: {
            ...values.patch.address,
            ..._.omit(values.patch, ['address']),
          },
        },
      },
    }).catch((e) => {});
    reset(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Personal Details"
        topRight={
          <Protected permissions={Permission.CLIENT_WRITE}>
            <IconButtonMulti
              enabled={editing}
              changed={!isEqual(currentValues, defaultValues)}
              setEditing={setEditing}
              loading={response.loading}
              success={!!response.data}
            />
          </Protected>
        }
      >
        <Box className={classes.wrapper}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={4} md={3} lg={2}>
              <Typography>Salutation</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  client.salutation !== Salutation.NOTSPECIFIED &&
                  startCase(camelCase(client.salutation || undefined))
                ) : (
                  <SalutationInput
                    fullWidth
                    name="patch.salutation"
                    control={control}
                    error={!!errors.patch?.salutation}
                    helperText={errors.patch?.salutation?.message}
                    disabled={!editing}
                    formControlProps={{
                      size: 'small',
                      variant: 'outlined',
                    }}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography>First name</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  client.firstName
                ) : (
                  <PersonNameFirstInput
                    fullWidth
                    required
                    name="patch.firstName"
                    variant="outlined"
                    size="small"
                    control={control}
                    error={!!errors.patch?.firstName}
                    helperText={errors.patch?.firstName?.message}
                    disabled={!editing}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography>Middle name</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  client.middleName
                ) : (
                  <PersonNameMiddleInput
                    fullWidth
                    name="patch.middleName"
                    variant="outlined"
                    size="small"
                    control={control}
                    error={!!errors.patch?.middleName}
                    helperText={errors.patch?.middleName?.message}
                    disabled={!editing}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography>Last name</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  client.lastName
                ) : (
                  <PersonNameLastInput
                    fullWidth
                    name="patch.lastName"
                    variant="outlined"
                    size="small"
                    control={control}
                    error={!!errors.patch?.lastName}
                    helperText={errors.patch?.lastName?.message}
                    disabled={!editing}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography>Preferred name</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  client.preferredName
                ) : (
                  <PersonNamePreferredInput
                    fullWidth
                    name="patch.preferredName"
                    variant="outlined"
                    size="small"
                    control={control}
                    error={!!errors.patch?.preferredName}
                    helperText={errors.patch?.preferredName?.message}
                    disabled={!editing}
                  />
                )}
              </Typography>
            </Grid>
            <Protected permissions={Permission.CLIENT_READ}>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Email</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!editing ? (
                    !!client.email && <Link to={'mailto:' + client.email}>{client.email}</Link>
                  ) : (
                    <EmailInput
                      fullWidth
                      name="patch.email"
                      variant="outlined"
                      size="small"
                      control={control}
                      error={!!errors.patch?.email}
                      helperText={errors.patch?.email?.message}
                      disabled={!editing}
                    />
                  )}
                </Typography>
              </Grid>

              <Grid item xs={4} md={3} lg={2}>
                <Typography>Phone</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!editing ? (
                    !!client.phone && (
                      <MuiLink href={'tel:' + client.phone.replace(/\s+/g, '')}>
                        {client.phone}
                      </MuiLink>
                    )
                  ) : (
                    <PhoneInput
                      fullWidth
                      name="patch.phone"
                      variant="outlined"
                      size="small"
                      control={control}
                      error={!!errors.patch?.phone}
                      helperText={errors.patch?.phone?.message}
                      disabled={!editing}
                    />
                  )}
                </Typography>
              </Grid>
            </Protected>
            <Grid item xs={4} md={3} lg={2}>
              <Typography>{isClient(client) ? 'Date of birth' : 'Age'}</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  isClient(client) ? (
                    client.dob &&
                    format(new Date(client.dob), 'd MMM yyyy') +
                      ` (${formatAge(client.dob)} years old)`
                  ) : (
                    client.age + ' years old'
                  )
                ) : (
                  <DateInput
                    keyboard
                    clearable
                    disableFuture
                    disableTime
                    openTo="year"
                    name="patch.dob"
                    initialFocusedDate={new Date().getFullYear() - 18 + '-1-1'}
                    control={control}
                    inputVariant="outlined"
                    size="small"
                    error={!!errors.patch?.dob}
                    helperText={errors.patch?.dob?.message}
                    disabled={!editing}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography>Gender</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  client.gender !== Gender.NOTSPECIFIED &&
                  startCase(camelCase(client.gender || undefined))
                ) : (
                  <GenderInput
                    fullWidth
                    name="patch.gender"
                    control={control}
                    error={!!errors.patch?.gender}
                    helperText={errors.patch?.gender?.message}
                    disabled={!editing}
                    formControlProps={{
                      size: 'small',
                      variant: 'outlined',
                    }}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography>Pronouns</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  formatPronouns(client.pronouns || undefined, true)
                ) : (
                  <PronounsInput
                    fullWidth
                    name="patch.pronouns"
                    control={control}
                    error={!!errors.patch?.pronouns}
                    helperText={errors.patch?.pronouns?.message}
                    disabled={!editing}
                    formControlProps={{
                      size: 'small',
                      variant: 'outlined',
                    }}
                  />
                )}
              </Typography>
            </Grid>

            <Grid item xs={4} md={3} lg={2}>
              <Typography>Residential address</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  <Link
                    to={'https://www.google.com/maps/search/' + formatAddress(client)}
                    className={classes.link}
                  >
                    {formatAddress(client, true) || 'None.'}
                    <CallMadeRoundedIcon style={{ width: 10 }} />
                  </Link>
                ) : (
                  <AddressInput
                    name="patch.address"
                    control={control}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    setError={setError}
                    error={!!errors.patch?.address}
                    // helperText={errors.patch?.address?.message}
                    placeholder={'Residential address'}
                    isDisabled={!editing}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={4} md={3} lg={2}>
              <Typography>Contact name</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  client.contactsName
                ) : (
                  <PersonNameInput
                    fullWidth
                    name="patch.contactsName"
                    variant="outlined"
                    size="small"
                    control={control}
                    error={!!errors.patch?.contactsName}
                    helperText={errors.patch?.contactsName?.message}
                    disabled={!editing}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography>Contact email</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  !!client.contactsEmail && (
                    <Link to={'mailto:' + client.contactsEmail}>{client.contactsEmail}</Link>
                  )
                ) : (
                  <EmailInput
                    fullWidth
                    name="patch.contactsEmail"
                    variant="outlined"
                    size="small"
                    control={control}
                    error={!!errors.patch?.contactsEmail}
                    helperText={errors.patch?.contactsEmail?.message}
                    disabled={!editing}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2}>
              <Typography>Contact phone</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  !!client.contactsPhone && (
                    <MuiLink href={'tel:' + client.contactsPhone.replace(/\s+/g, '')}>
                      {client.contactsPhone}
                    </MuiLink>
                  )
                ) : (
                  <PhoneInput
                    fullWidth
                    name="patch.contactsPhone"
                    variant="outlined"
                    size="small"
                    control={control}
                    error={!!errors.patch?.contactsPhone}
                    helperText={errors.patch?.contactsPhone?.message}
                    disabled={!editing}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Block>
    </form>
  );
};

export default ClientUpdatePersonalDetailsForm;
