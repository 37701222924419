import { Grid, Link as MuiLink, Typography } from '@material-ui/core';
import { Protected } from '@timed/auth';
import {
  addServerErrors,
  Block,
  EmailInput,
  IconButtonMulti,
  Link,
  PersonNameFirstInput,
  PersonNameLastInput,
  PersonNameMiddleInput,
  PersonNamePreferredInput,
  PhoneInput,
  SalutationInput,
} from '@timed/common';
import {
  Permission,
  Salutation,
  SupportCoordinator,
  useUpdateSupportCoordinatorsMutation,
} from '@timed/gql';
import { camelCase, isEqual, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type SupportCoordinatorUpdatePersonalDetailsFormProps = {
  supportCoordinator: Pick<
    SupportCoordinator,
    | 'id'
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'preferredName'
    | 'email'
    | 'phone'
    | 'salutation'
  >;
};

type FormData = {
  patch: {
    firstName: string;
    lastName: string;
    middleName: string;
    preferredName: string;
    email: string;
    phone: string;
    salutation: Salutation;
  };
};

const SupportCoordinatorUpdatePersonalDetailsForm = ({
  supportCoordinator,
}: SupportCoordinatorUpdatePersonalDetailsFormProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const [updateSupportCoordinators, response] = useUpdateSupportCoordinatorsMutation();

  const defaultValues: FormData = {
    patch: {
      firstName: supportCoordinator.firstName,
      middleName: supportCoordinator.middleName || '',
      lastName: supportCoordinator.lastName || '',
      preferredName: supportCoordinator.preferredName || '',
      email: supportCoordinator.email || '',
      phone: supportCoordinator.phone || '',
      salutation: supportCoordinator.salutation || Salutation.NOTSPECIFIED,
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) => {
    updateSupportCoordinators({
      variables: { input: { ids: [supportCoordinator.id], ...values } },
    }).catch(() => {});

    reset(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Personal Details"
        topRight={
          <Protected permissions={Permission.SUPPORTCOORDINATOR_WRITE}>
            <IconButtonMulti
              enabled={editing}
              changed={!isEqual(currentValues, defaultValues)}
              setEditing={setEditing}
              loading={response.loading}
              success={!!response.data}
            />
          </Protected>
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Salutation</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                supportCoordinator.salutation !== Salutation.NOTSPECIFIED &&
                startCase(camelCase(supportCoordinator.salutation || undefined))
              ) : (
                <SalutationInput
                  fullWidth
                  name="patch.salutation"
                  control={control}
                  error={!!errors.patch?.salutation}
                  helperText={errors.patch?.salutation?.message}
                  disabled={!editing}
                  formControlProps={{
                    size: 'small',
                    variant: 'outlined',
                  }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>First name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                supportCoordinator.firstName
              ) : (
                <PersonNameFirstInput
                  fullWidth
                  required
                  name="patch.firstName"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.firstName}
                  helperText={errors.patch?.firstName?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Middle name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                supportCoordinator.middleName
              ) : (
                <PersonNameMiddleInput
                  fullWidth
                  name="patch.middleName"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.middleName}
                  helperText={errors.patch?.middleName?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Last name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                supportCoordinator.lastName
              ) : (
                <PersonNameLastInput
                  fullWidth
                  name="patch.lastName"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.lastName}
                  helperText={errors.patch?.lastName?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Preferred name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                supportCoordinator.preferredName
              ) : (
                <PersonNamePreferredInput
                  fullWidth
                  name="patch.preferredName"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.preferredName}
                  helperText={errors.patch?.preferredName?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Email</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                <Link to={'mailto:' + supportCoordinator.email}>{supportCoordinator.email}</Link>
              ) : (
                <EmailInput
                  fullWidth
                  name="patch.email"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.email}
                  helperText={errors.patch?.email?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Phone</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                supportCoordinator.phone && (
                  <MuiLink href={'tel:' + supportCoordinator.phone.replace(/\s+/g, '')}>
                    {supportCoordinator.phone}
                  </MuiLink>
                )
              ) : (
                <PhoneInput
                  fullWidth
                  name="patch.phone"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.phone}
                  helperText={errors.patch?.phone?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default SupportCoordinatorUpdatePersonalDetailsForm;
