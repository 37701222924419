import {
  Box,
  Container,
  createStyles,
  Divider,
  Hidden,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { Protected, useAuth } from '@timed/auth';
import { ClientCreateFormModal, ClientListSearch, isClient } from '@timed/client';
import { Avatar, Button, formatPersonName, useRouter } from '@timed/common';
import {
  Client,
  EntityState,
  OrderBy,
  Permission,
  RedactedClient,
  useGetClientsLazyQuery,
  useGetRedactedClientsLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { useModal } from 'mui-modal-provider';
import { useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controls: {
      display: 'flex',
      gap: theme.spacing(4),
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
      },
    },
    root: {
      overflowY: 'scroll',
    },
    wrapper: {
      display: 'grid',
      // marginBottom: theme.spacing(4),
    },
    paper: {
      borderRadius: theme.shape.borderRadius,
      flex: '1 1 auto',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(8),
      },
    },
    profile: {
      // borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
      padding: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: 'max-content auto max-content',
      alignItems: 'center',
      gap: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    name: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'grid',
      gap: theme.spacing(0),
    },
    avatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      fontSize: 12,
    },
    character: {
      fontWeight: theme.typography.fontWeightMedium,
      margin: theme.spacing(4, 0, 2, 0),
    },
    chips: {
      display: 'inline-grid',
      gridAutoColumns: 'max-content',
      justifyItems: 'flex-end',
      gap: theme.spacing(2),
    },
    checklist: {
      padding: theme.spacing(1, 2),
      display: 'grid',
      gridAutoColumns: 'max-content',
      justifyItems: 'flex-end',
      gap: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.main,
    },
    check: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  }),
);

const ClientList = () => {
  const classes = useStyles();
  const auth = useAuth();
  const { navigate } = useRouter();
  const [searchTerm, setSearchTerm] = useState<string>();
  const { showModal } = useModal();

  const [getClients, clientsResponse] = useGetClientsLazyQuery({
    variables: {
      input: {
        orderBy: [
          { lastName: OrderBy.ASC },
          { firstName: OrderBy.ASC },
          { createdAt: OrderBy.ASC },
        ],
        entityStates: [EntityState.NORMAL, EntityState.ARCHIVED],
      },
    },
  });

  const [getRedactedClients, redactedClientsResponse] = useGetRedactedClientsLazyQuery({
    variables: {
      input: {
        orderBy: [{ lastName: OrderBy.ASC }, { firstName: OrderBy.ASC }],
      },
    },
  });

  const hasPermission = useMemo(() => {
    return auth.permissible({ permissions: [Permission.CLIENT_READ] });
  }, [auth]);

  const data = hasPermission
    ? clientsResponse.data?.clients
    : redactedClientsResponse.data?.redactedClients;

  const loading = hasPermission ? clientsResponse.loading : redactedClientsResponse.loading;

  useLoadingEffect(loading);

  useEffect(() => {
    if (!data) hasPermission ? getClients() : getRedactedClients();
  }, [getClients, getRedactedClients, data, hasPermission]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleOpenModal = () => {
    const modal: { hide: () => void } = showModal(ClientCreateFormModal, {
      onClose: () => modal.hide(),
    });
  };

  const handleNavigate = (clientId: Client['id']) => (_: React.KeyboardEvent | React.MouseEvent) =>
    navigate(clientId);

  const clients = useMemo(() => {
    if (data)
      return searchTerm
        ? isClient(data[0])
          ? (data as Client[]).filter(
              (client) =>
                formatPersonName(client, { middle: true }).toLowerCase().includes(searchTerm) ||
                formatPersonName(client, { preferred: true }).toLowerCase().includes(searchTerm),
            )
          : (data as RedactedClient[]).filter(
              (client) =>
                formatPersonName(client, { middle: true }).toLowerCase().includes(searchTerm) ||
                formatPersonName(client, { preferred: true }).toLowerCase().includes(searchTerm),
            )
        : data;
  }, [data, searchTerm]);

  const characters: string[] = useMemo(() => {
    const characters: string[] = [];

    // Build characters array
    clients?.forEach((c) => {
      if (
        !characters.includes(
          c.lastName?.charAt(0).toUpperCase() || c.firstName?.charAt(0).toUpperCase(),
        )
      ) {
        characters.push(
          c.lastName?.charAt(0).toUpperCase() || c.firstName?.charAt(0).toUpperCase(),
        );
      }
    });

    return characters.sort((a, b) => a.localeCompare(b));
  }, [clients]);

  if (loading || !data) return <>Loading...</>;

  return (
    <>
      <Box className={classes.controls}>
        <ClientListSearch handleSearch={handleSearch} />
        <Protected permissions={Permission.CLIENT_WRITE}>
          <Box>
            <Button onClick={() => handleOpenModal()} variant="contained" color="primary">
              Create
            </Button>
          </Box>
        </Protected>
      </Box>

      {clients && clients.length > 0 ? (
        <Box className={classes.root}>
          {characters.map((character) => (
            <Box className={classes.wrapper}>
              <Box key={character} className={classes.character}>
                {character}
              </Box>
              <Paper className={classes.paper}>
                {(clients as RedactedClient[])
                  .filter(
                    ({ lastName, firstName }) =>
                      (lastName?.charAt(0).toUpperCase() || firstName?.charAt(0).toUpperCase()) ===
                      character,
                  )
                  .map((c, i) => (
                    <>
                      {i !== 0 && <Divider />}
                      <Box className={classes.profile} onClick={handleNavigate(c.id)}>
                        <Avatar
                          aria-label="avatar"
                          className={classes.avatar}
                          color={c.color}
                          content={[c.firstName, c.lastName]}
                        />
                        <Box className={classes.name}>
                          <Typography color="textPrimary">
                            {formatPersonName(c, { lastNameFirst: true, capitaliseLastName: true })}
                          </Typography>
                        </Box>
                        {isClient(data[0]) && (
                          <Hidden smDown>
                            <Box className={classes.chips}>
                              {(c as Client).archive && (
                                <Box className={classes.check}>
                                  <Typography noWrap variant="body2">
                                    Archived
                                  </Typography>
                                  <LockIcon fontSize="small" />
                                </Box>
                              )}
                            </Box>
                          </Hidden>
                        )}
                      </Box>
                    </>
                  ))}
              </Paper>
            </Box>
          ))}
        </Box>
      ) : (
        <Container>No results</Container>
      )}
    </>
  );
};

export default ClientList;
