import {
  Box,
  Breadcrumbs,
  createStyles,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import { IconButton, Link, useRouter } from '@timed/common';
import { _schedule } from '@timed/app';
import { isCrumbAnElement } from '@timed/app-bar';
import { usePage } from '@timed/page';
import clsx from 'clsx';
import { useMemo } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      userSelect: 'none' /* Standard */,
      WebkitUserSelect: 'none' /* Safari */,
      msUserSelect: 'none' /* IE10+/Edge */,
      MozUserSelect: 'none' /* Firefox */,
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.topBar.main,
      alignItems: 'center',
      [theme.breakpoints.between('xs', 'md')]: {
        flex: '0 1 ' + theme.spacing(12) + 'px',
        minHeight: theme.spacing(12),
        padding: theme.spacing(0, 2),
        lineHeight: theme.spacing(12) + 'px',
      },
      [theme.breakpoints.up('md')]: {
        flex: '0 1 ' + theme.spacing(16) + 'px',
        minHeight: theme.spacing(16),
        padding: theme.spacing(0, 4),
        lineHeight: theme.spacing(16) + 'px',
      },
    },
    left: {
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    menuIcon: {
      color: theme.palette.topBar.text,
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    breadcrumbs: {
      overflow: 'hidden',
      color: theme.palette.topBar.text,
      '& .MuiBreadcrumbs-separator': {
        '& .MuiSvgIcon-root': {
          // marginTop: theme.spacing(1),
        },
      },
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    item: { padding: theme.spacing(0, 4) },
    typography: {
      padding: theme.spacing(2, 0),
      color: theme.palette.topBar.text,
      '& > span': {
        // padding: theme.spacing(0, 4),
      },
      '& > a': {
        padding: theme.spacing(2, 4),
        color: theme.palette.topBar.text,
        [theme.breakpoints.up('md')]: {
          borderRadius: theme.shape.borderRadius,
          '&:hover': {
            backgroundColor: theme.palette.topBar.hoverBackground,
            textDecoration: 'none',
          },
          '&:active': {
            backgroundColor: theme.palette.topBar.activeBackground,
            textDecoration: 'none',
          },
        },
      },
    },
    downIcon: {
      color: theme.palette.topBar.text,
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  }),
);

const AppBar = () => {
  const classes = useStyles();
  const { pathname } = useRouter();
  const { toggleDrawer, breadcrumbs, getLastCrumbModule, getDirectPagePath } = usePage();

  const smDown = useMemo(() => {
    const item = getLastCrumbModule();

    return (
      item && (
        <Hidden mdUp>
          <IconButton className={classes.menuIcon} onClick={toggleDrawer(true)}>
            <MenuRoundedIcon />
          </IconButton>
          <Breadcrumbs
            aria-label="breadcrumbs"
            className={classes.breadcrumbs}
            separator={<NavigateNextRoundedIcon />}
          >
            {pathname === _schedule.path ? (
              breadcrumbs
                .flat()
                .filter((crumb) => isCrumbAnElement(crumb) || !crumb.isModule)
                .map((crumb, index) => {
                  if (isCrumbAnElement(crumb))
                    return (
                      <span className={classes.item} key={index}>
                        {crumb}
                      </span>
                    );

                  const style = crumb.isModule
                    ? clsx(classes.typography, classes.title)
                    : classes.typography;

                  return (
                    <Typography className={style} key={index}>
                      {crumb.path && crumb.path !== pathname && crumb.redirect !== pathname ? (
                        <Link
                          to={getDirectPagePath({
                            path: crumb.path,
                            redirect: crumb.redirect,
                          })}
                        >
                          {crumb.label}
                        </Link>
                      ) : (
                        <span className={classes.item}>{crumb.label}</span>
                      )}
                    </Typography>
                  );
                })
            ) : (
              <Typography className={clsx(classes.typography, classes.title)}>
                {item.label}
              </Typography>
            )}
          </Breadcrumbs>
        </Hidden>
      )
    );
  }, [getLastCrumbModule, toggleDrawer, classes, breadcrumbs, getDirectPagePath, pathname]);

  const mdUp = useMemo(
    () => (
      <Hidden smDown>
        <Breadcrumbs
          aria-label="breadcrumbs"
          className={classes.breadcrumbs}
          separator={<NavigateNextRoundedIcon />}
        >
          {breadcrumbs.flat().map((crumb, index) => {
            if (isCrumbAnElement(crumb))
              return (
                <span className={classes.item} key={index}>
                  {crumb}
                </span>
              );

            const style = crumb.isModule
              ? clsx(classes.typography, classes.title)
              : classes.typography;

            return (
              <Typography className={style} key={index}>
                {crumb.path && crumb.path !== pathname && crumb.redirect !== pathname ? (
                  <Link
                    to={getDirectPagePath({
                      path: crumb.path,
                      redirect: crumb.redirect,
                    })}
                  >
                    {crumb.label}
                  </Link>
                ) : (
                  <span className={classes.item} key={index}>
                    {crumb.label}
                  </span>
                )}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Hidden>
    ),

    [breadcrumbs, pathname, classes, getDirectPagePath],
  );

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.left}>
        {smDown}
        {mdUp}
      </Box>
    </Box>
  );
};

export default AppBar;
