import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Protected } from '@timed/auth';
import { addServerErrors, Block, IconButtonMulti, numberRegex, TextField } from '@timed/common';
import { transformStringToNumber } from '@timed/common/transformations/stringToNumber';
import { Client, Permission, useUpdateClientsObservationThresholdsMutation } from '@timed/gql';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type ClientUpdateObsservationThresholdsFormProps = React.PropsWithChildren<{
  client: Pick<
    Client,
    | 'id'
    | 'bloodSystolicUpperThreshold1'
    | 'bloodSystolicLowerThreshold1'
    | 'bloodDiastolicUpperThreshold1'
    | 'bloodDiastolicLowerThreshold1'
    | 'heartUpperThreshold1'
    | 'heartLowerThreshold1'
    | 'spo2UpperThreshold1'
    | 'spo2LowerThreshold1'
    | 'respirationUpperThreshold1'
    | 'respirationLowerThreshold1'
  >;
}>;

type FormData = {
  patch: {
    bloodSystolicUpperThreshold1?: number;
    bloodSystolicLowerThreshold1?: number;
    bloodDiastolicUpperThreshold1?: number;
    bloodDiastolicLowerThreshold1?: number;
    heartUpperThreshold1?: number;
    heartLowerThreshold1?: number;
    spo2UpperThreshold1?: number;
    spo2LowerThreshold1?: number;
    respirationUpperThreshold1?: number;
    respirationLowerThreshold1?: number;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 60,
      },
    },
  }),
);

const ClientUpdateObservationThresholdsForm = ({
  client,
}: ClientUpdateObsservationThresholdsFormProps) => {
  const classes = useStyles();

  const [editing, setEditing] = useState<boolean>(false);

  const [updateClient, { data, loading, error }] = useUpdateClientsObservationThresholdsMutation();

  const defaultValues: FormData = {
    patch: {
      bloodSystolicUpperThreshold1: client.bloodSystolicUpperThreshold1 || undefined,
      bloodSystolicLowerThreshold1: client.bloodSystolicLowerThreshold1 || undefined,
      bloodDiastolicUpperThreshold1: client.bloodDiastolicUpperThreshold1 || undefined,
      bloodDiastolicLowerThreshold1: client.bloodDiastolicLowerThreshold1 || undefined,
      heartUpperThreshold1: client.heartUpperThreshold1 || undefined,
      heartLowerThreshold1: client.heartLowerThreshold1 || undefined,
      spo2UpperThreshold1: client.spo2UpperThreshold1 || undefined,
      spo2LowerThreshold1: client.spo2LowerThreshold1 || undefined,
      respirationUpperThreshold1: client.respirationUpperThreshold1 || undefined,
      respirationLowerThreshold1: client.respirationLowerThreshold1 || undefined,
    },
  };

  const {
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(() => {
    !!error && addServerErrors(error, setError);
  }, [error, setError]);

  const onSubmit = ({ patch }: FormData) => {
    updateClient({ variables: { input: { ids: [client.id], patch } } });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        inline
        title="Obs upper/lower warning thresholds"
        topRight={
          <Protected permissions={Permission.CLIENT_WRITE}>
            <IconButtonMulti
              enabled={editing}
              changed={!isEqual(currentValues, defaultValues)}
              setEditing={setEditing}
              loading={loading}
              success={!!data}
            />
          </Protected>
        }
      >
        <Box className={classes.grid}>
          <Box className={classes.input}>
            <Typography variant="body1">BP upper threshold</Typography>
            <TextField
              name="patch.bloodSystolicUpperThreshold1"
              variant="outlined"
              size="small"
              disabled={!editing}
              control={control}
              error={!!errors.patch?.bloodSystolicUpperThreshold1}
              helperText={errors.patch?.bloodSystolicUpperThreshold1?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            {'/'}
            <TextField
              name="patch.bloodDiastolicUpperThreshold1"
              variant="outlined"
              size="small"
              disabled={!editing}
              control={control}
              error={!!errors.patch?.bloodDiastolicUpperThreshold1}
              helperText={errors.patch?.bloodDiastolicUpperThreshold1?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            mmHg
          </Box>
          <Box className={classes.input}>
            <Typography variant="body1">BP lower threshold</Typography>
            <TextField
              name="patch.bloodSystolicLowerThreshold1"
              variant="outlined"
              size="small"
              disabled={!editing}
              control={control}
              error={!!errors.patch?.bloodSystolicLowerThreshold1}
              helperText={errors.patch?.bloodSystolicLowerThreshold1?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            {'/'}
            <TextField
              name="patch.bloodDiastolicLowerThreshold1"
              variant="outlined"
              size="small"
              disabled={!editing}
              control={control}
              error={!!errors.patch?.bloodDiastolicLowerThreshold1}
              helperText={errors.patch?.bloodDiastolicLowerThreshold1?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            mmHg
          </Box>
          <Box className={classes.input}>
            <Typography variant="body1">Heart rate upper threshold</Typography>
            <Box className={classes.input}>
              <TextField
                name="patch.heartUpperThreshold1"
                variant="outlined"
                size="small"
                disabled={!editing}
                control={control}
                error={!!errors.patch?.heartUpperThreshold1}
                helperText={errors.patch?.heartUpperThreshold1?.message}
                transform={transformStringToNumber()}
                validation={{ pattern: numberRegex }}
                onClick={(event) => {
                  (event.target as HTMLInputElement).select();
                }}
              ></TextField>
              bpm
            </Box>
          </Box>
          <Box className={classes.input}>
            <Typography variant="body1">Heart rate lower threshold</Typography>
            <Box className={classes.input}>
              <TextField
                name="patch.heartLowerThreshold1"
                variant="outlined"
                size="small"
                disabled={!editing}
                control={control}
                error={!!errors.patch?.heartLowerThreshold1}
                helperText={errors.patch?.heartLowerThreshold1?.message}
                transform={transformStringToNumber()}
                validation={{ pattern: numberRegex }}
                onClick={(event) => {
                  (event.target as HTMLInputElement).select();
                }}
              ></TextField>
              bpm
            </Box>
          </Box>
          <Box className={classes.input}>
            <Typography variant="body1">SpO2 upper threshold</Typography>
            <Box className={classes.input}>
              <TextField
                name="patch.spo2UpperThreshold1"
                variant="outlined"
                size="small"
                disabled={!editing}
                control={control}
                error={!!errors.patch?.spo2UpperThreshold1}
                helperText={errors.patch?.spo2UpperThreshold1?.message}
                transform={transformStringToNumber()}
                validation={{ pattern: numberRegex }}
                onClick={(event) => {
                  (event.target as HTMLInputElement).select();
                }}
              ></TextField>
              %
            </Box>
          </Box>
          <Box className={classes.input}>
            <Typography variant="body1">SpO2 lower threshold</Typography>
            <Box className={classes.input}>
              <TextField
                name="patch.spo2LowerThreshold1"
                variant="outlined"
                size="small"
                disabled={!editing}
                control={control}
                error={!!errors.patch?.spo2LowerThreshold1}
                helperText={errors.patch?.spo2LowerThreshold1?.message}
                transform={transformStringToNumber()}
                validation={{ pattern: numberRegex }}
                onClick={(event) => {
                  (event.target as HTMLInputElement).select();
                }}
              ></TextField>
              %
            </Box>
          </Box>
          <Box className={classes.input}>
            <Typography variant="body1">Respiration upper threshold</Typography>
            <Box className={classes.input}>
              <TextField
                name="patch.respirationUpperThreshold1"
                variant="outlined"
                size="small"
                disabled={!editing}
                control={control}
                error={!!errors.patch?.respirationUpperThreshold1}
                helperText={errors.patch?.respirationUpperThreshold1?.message}
                transform={transformStringToNumber()}
                validation={{ pattern: numberRegex }}
                onClick={(event) => {
                  (event.target as HTMLInputElement).select();
                }}
              ></TextField>
              brpm
            </Box>
          </Box>
          <Box className={classes.input}>
            <Typography variant="body1">Respiration lower threshold</Typography>
            <Box className={classes.input}>
              <TextField
                name="patch.respirationLowerThreshold1"
                variant="outlined"
                size="small"
                disabled={!editing}
                control={control}
                error={!!errors.patch?.respirationLowerThreshold1}
                helperText={errors.patch?.respirationLowerThreshold1?.message}
                transform={transformStringToNumber()}
                validation={{ pattern: numberRegex }}
                onClick={(event) => {
                  (event.target as HTMLInputElement).select();
                }}
              ></TextField>
              brpm
            </Box>
          </Box>
        </Box>
      </Block>
    </form>
  );
};

export default ClientUpdateObservationThresholdsForm;
