import { _settingsOrganisationMedication } from '@timed/app';
import { Page } from '@timed/page';
import { SettingsOrganisationMedicationForm } from '@timed/settings';

const SettingsOrganisationMedication = () => {
  return (
    <Page {..._settingsOrganisationMedication}>
      <SettingsOrganisationMedicationForm />
    </Page>
  );
};

export default SettingsOrganisationMedication;
