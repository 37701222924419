import { makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
// import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { createStyles } from '@material-ui/styles';
import { _people, _reports, _schedule, _settings, _shifts, _support } from '@timed/app';
import { useAuth } from '@timed/auth';
import { useRouter } from '@timed/common';
import { Permission } from '@timed/gql';
import { usePage } from '@timed/page';

type TabItem = {
  label: string;
  path: string;
  redirect: string | undefined;
  icon: JSX.Element;
  admin?: boolean;
  permissions?: Permission | Permission[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 0),
      width: theme.spacing(16),
    },
    tab: {
      lineHeight: 'initial',
      textTransform: 'none',
      fontSize: '80%',
      minWidth: 'inherit', // Needed to overwrite MuiTab's default minWidth
      '&.MuiTab-root': {
        padding: 0,
      },
      '& .MuiTab-wrapper': {
        '& :first-child': {
          marginBottom: theme.spacing(0.25),
        },
      },
      '&.MuiTab-labelIcon': {
        minHeight: 'initial',
        margin: theme.spacing(3, 0),
      },
      '& .MuiSvgIcon-root': {
        fontSize: theme.spacing(6),
      },
      '&:last-child': {
        position: 'absolute',
        bottom: 0,
        width: '100%',
      },
      '&:nth-last-child(2)': {
        position: 'absolute',
        bottom: theme.spacing(16),
        width: '100%',
      },
      '& span': {
        padding: theme.spacing(0, 2),
      },
    },
    // more: {
    //   marginTop: theme.spacing(4),
    //   color: theme.palette.text.primary,
    // },
    indicator: {
      left: 0,
      width: theme.spacing(1),
    },
  }),
);

const DrawerNavBar = () => {
  const classes = useStyles();
  const { pathname, navigate } = useRouter();
  const { permissible } = useAuth();
  const { getDirectPagePath } = usePage();

  const menuItems: TabItem[] = [
    // {
    //   label: _personal.label,
    //   icon: <HomeOutlinedIcon />,
    //   path: _personal.path,
    //   redirect: _personal.redirect,
    // },
    // {
    //   label: "Chat",
    //   icon: <ChatBubbleOutlineOutlinedIcon />,
    //   path: "chat",
    //   redirect: "chat",
    //   permissions: Role.NONE,
    // },
    {
      label: _schedule.label,
      icon: <EventOutlinedIcon />,
      path: _schedule.path,
      redirect: _schedule.redirect,
      permissions: _schedule.permissions,
    },
    {
      label: _people.label,
      icon: <PeopleOutlineIcon />,
      path: _people.path,
      permissions: _people.permissions,
      redirect: _people.redirect,
    },
    {
      label: _shifts.label,
      icon: <ScheduleOutlinedIcon />,
      path: _shifts.path,
      redirect: _shifts.redirect,
      permissions: _shifts.permissions,
    },
    {
      label: _reports.label,
      icon: <AssignmentOutlinedIcon />,
      path: _reports.path,
      redirect: _reports.redirect,
      permissions: _reports.permissions,
    },
    // {
    //   label: _recycling.label,
    //   icon: <DeleteOutlinedIcon />,
    //   path: _recycling.path,
    //   redirect: _recycling.redirect,
    //   permissions: _recycling.permissions,
    // },
    {
      label: _settings.label,
      icon: <SettingsOutlinedIcon />,
      path: _settings.path,
      redirect: _settings.redirect,
      permissions: _settings.permissions,
    },
    {
      label: _support.label,
      icon: <HelpOutlineOutlinedIcon />,
      path: _support.path,
      redirect: _support.redirect,
      permissions: _support.permissions,
    },
  ];

  const currentTab = pathname.split('/', 2)[1];

  const handleClick = (link: string) => (_: React.KeyboardEvent | React.MouseEvent) =>
    link !== currentTab && navigate(link);

  return (
    <Tabs
      orientation="vertical"
      value={'/' + currentTab}
      aria-label="Nav bar"
      className={classes.tabs}
      TabIndicatorProps={{ className: classes.indicator }}
    >
      {menuItems.map(
        (tab, index) =>
          permissible({ admin: tab.admin, permissions: tab.permissions }) && (
            <Tab
              key={index}
              label={tab?.label}
              icon={tab?.icon}
              className={classes.tab}
              value={tab.path}
              onClick={handleClick(getDirectPagePath(tab))}
            />
          ),
      )}
    </Tabs>
  );
};

export default DrawerNavBar;
