import { Permission } from '@timed/gql';
import { PageMetadata } from '@timed/page';

/**
 * Personal metadata
 */
// export const _personal: PageMetadata = {
//   label: 'Personal',
//   path: '/personal',
//   redirect: '/personal/shifts',
//   level: 0,
// };
// export const _personalShifts: PageMetadata = {
//   label: 'My Shifts',
//   path: _personal.path + '/shifts',
//   level: 1,
//   newPage: true,
// };
// export const _personalUnavailability: PageMetadata = {
//   label: 'My Unavailability',
//   path: _personal.path + '/unavailability',
//   level: 1,
//   newPage: true,
// };

/**
 * Notifications metadata
 */
export const _notifications: PageMetadata = {
  label: 'Notifications',
  path: '/notifications',
  level: 0,
  newPage: true,
};

/**
 * Schedule metadata
 */
export const _schedule: PageMetadata = {
  label: 'Schedule',
  path: '/schedule',
  level: 0,
  newPage: true,
};

/**
 * Shifts metadata
 */
export const _shifts: PageMetadata = {
  label: 'Shifts',
  path: '/shifts',
  redirect: '/shifts/personal/upcoming',
  level: 0,
};
export const _shiftsPersonal: PageMetadata = {
  label: 'Personal',
  path: _shifts.path + '/personal',
  redirect: _shifts.path + '/personal/upcoming',
  level: 1,
};
export const _shiftsPersonalUpcoming: PageMetadata = {
  label: 'My Upcoming Shifts',
  path: _shiftsPersonal.path + '/upcoming',
  level: 2,
  newPage: true,
  isModule: true,
};
export const _shiftsPersonalClaim: PageMetadata = {
  label: 'Grab Shifts',
  path: _shiftsPersonal.path + '/claim',
  level: 2,
  newPage: true,
  isModule: true,
};
export const _shiftsPersonalUnavailability: PageMetadata = {
  label: 'Unavailability',
  path: _shiftsPersonal.path + '/unavailability',
  level: 2,
  newPage: true,
  isModule: true,
};
export const _shiftsAdmin: PageMetadata = {
  label: 'Administration',
  path: _shifts.path + '/admin',
  redirect: _shifts.path + '/admin/missing-notes',
  level: 1,
  permissions: Permission.EVENT_READ,
};
export const _shiftsAdminMissingNotes: PageMetadata = {
  label: 'Missing Case Notes',
  path: _shiftsAdmin.path + '/missing-notes',
  level: 2,
  newPage: true,
  isModule: true,
  admin: true,
};
export const _shiftsAdminMissingSupportWorker: PageMetadata = {
  label: 'TBA Shifts',
  path: _shiftsAdmin.path + '/missing-support-worker',
  level: 2,
  newPage: true,
  isModule: true,
  permissions: Permission.EVENT_READ,
};
export const _shiftsAdminApprove: PageMetadata = {
  label: 'Approve Shifts (temp)',
  path: _shiftsAdmin.path + '/approve',
  level: 2,
  newPage: true,
  isModule: true,
  admin: true,
};

/**
 * People metadata
 */
export const _people: PageMetadata = {
  label: 'People',
  path: '/people',
  redirect: '/people/employees',
  level: 0,
};
export const _peopleMembers: PageMetadata = {
  label: 'Employees',
  path: _people.path + '/employees',
  level: 1,
  newPage: true,
  isModule: true,
};
export const _peopleMembersProfile: Pick<PageMetadata, 'level'> = {
  level: 2,
};
export const _peopleMembersProfileCategory: Pick<PageMetadata, 'level' | 'newPage'> = {
  level: 3,
  newPage: true,
};
export const _peopleClients: PageMetadata = {
  label: 'Participants',
  path: _people.path + '/participants',
  level: 1,
  newPage: true,
  isModule: true,
};
export const _peopleClientsProfile: Pick<PageMetadata, 'level'> = {
  level: 2,
};
export const _peopleClientsProfileCategory: Pick<PageMetadata, 'level' | 'newPage'> = {
  level: 3,
  newPage: true,
};
export const _peopleSupportCoordinators: PageMetadata = {
  label: 'Support Coordinators',
  path: _people.path + '/support-coordinators',
  level: 1,
  permissions: Permission.SUPPORTCOORDINATOR_READ,
  newPage: true,
  isModule: true,
};
export const _peopleSupportCoordinatorsProfile: Pick<PageMetadata, 'level'> = {
  level: 2,
};
export const _peopleSupportCoordinatorsProfileCategory: Pick<PageMetadata, 'level' | 'newPage'> = {
  level: 3,
  newPage: true,
};
export const _peoplePlanManagers: PageMetadata = {
  label: 'Plan Managers',
  path: _people.path + '/plan-managers',
  level: 1,
  permissions: [Permission.PLANMANAGER_READ],
  newPage: true,
  isModule: true,
};

/**
 * Reports metadata
 */
export const _reports: PageMetadata = {
  label: 'Reports',
  path: '/reports',
  level: 0,
  newPage: true,
  permissions: [Permission.MEMBER_READ, Permission.CLIENT_READ, Permission.PLANMANAGER_READ],
};

/**
 * Recycling metadata
 */
// export const _recycling: PageMetadata = {
//   label: 'Recycling',
//   path: '/recycling',
//   level: 0,
//   newPage: true,
//   admin: true,
// };

/**
 * Settings metadata
 */
export const _settings: PageMetadata = {
  label: 'Settings',
  path: '/settings',
  redirect: '/settings/personal/account',
  level: 0,
};
export const _settingsPersonal: PageMetadata = {
  label: 'Personal',
  path: _settings.path + '/personal',
  redirect: '/settings/personal/account',
  level: 1,
};
export const _settingsPersonalAccount: PageMetadata = {
  label: 'Account',
  path: _settingsPersonal.path + '/account',
  level: 2,
  newPage: true,
};
// export const _settingsPersonalAppearance: PageMetadata = {
//   label: 'Appearance',
//   path: _settingsPersonal.path + '/appearance',
//   level: 2,
//   newPage: true,
// };
// export const _settingsPersonalNotifications: PageMetadata = {
//   label: 'Notifications',
//   path: _settingsPersonal.path + '/notifications',
//   level: 2,
//   newPage: true,
// };
// export const _settingsPersonalSecurity: PageMetadata = {
//   label: 'Security',
//   path: _settingsPersonal.path + '/security',
//   level: 2,
//   newPage: true,
// };
export const _settingsOrganisation: PageMetadata = {
  label: 'Organisation',
  path: _settings.path + '/organisation',
  redirect: '/settings/organisation/scheduling',
  level: 1,
  admin: true,
};
export const _settingsOrganisationScheduling: PageMetadata = {
  label: 'Scheduling',
  path: _settingsOrganisation.path + '/scheduling',
  level: 2,
  newPage: true,
  admin: true,
};
export const _settingsOrganisationVisaTypes: PageMetadata = {
  label: 'VISA Types',
  path: _settingsOrganisation.path + '/visa-types',
  level: 2,
  newPage: true,
  admin: true,
};
export const _settingsOrganisationPayroll: PageMetadata = {
  label: 'Payroll',
  path: _settingsOrganisation.path + '/payroll',
  level: 2,
  newPage: true,
  admin: true,
};
export const _settingsOrganisationMedication: PageMetadata = {
  label: 'Medication',
  path: _settingsOrganisation.path + '/medication',
  level: 2,
  newPage: true,
  admin: true,
};
// export const _settingsOrganisationAccess: PageMetadata = {
//   label: 'Access',
//   path: _settingsOrganisation.path + '/access',
//   level: 2,
//   newPage: true,
//   admin: true,
// };
// export const _settingsOrganisationBilling: PageMetadata = {
//   label: 'Billing',
//   path: _settingsOrganisation.path + '/billing',
//   level: 2,
//   newPage: true,
// };
// export const _settingsOrganisationExports: PageMetadata = {
//   label: 'Exports',
//   path: _settingsOrganisation.path + '/exports',
//   level: 2,
//   newPage: true,
// };
// export const _settingsOrganisationOverview: PageMetadata = {
//   label: 'Overview',
//   path: _settingsOrganisation.path + '/overview',
//   level: 2,
//   newPage: true,
// };

/**
 * Support metadata
 */
export const _support: PageMetadata = {
  label: 'Support',
  path: '/support',
  redirect: '/support/contact',
  level: 0,
};
export const _supportContact: PageMetadata = {
  label: 'Contact',
  path: _support.path + '/contact',
  level: 1,
  newPage: true,
};
export const _supportDebug: PageMetadata = {
  label: 'Debug',
  path: _support.path + '/debug',
  level: 1,
  newPage: true,
};
