import { Box, Grid, Typography } from '@material-ui/core';
import { _peopleSupportCoordinators } from '@timed/app';
import { Protected } from '@timed/auth';
import {
  ClientContext,
  ClientNdisIdInput,
  ClientPlanManagerInput,
  ClientSupportCoordinatorInput,
} from '@timed/client';
import {
  addServerErrors,
  Block,
  DateInput,
  formatPersonName,
  IconButtonMulti,
  Link,
  TextField,
  transformNumberToFixedFloat,
} from '@timed/common';
import { Client, Permission, QueryByIdInput, useUpdateClientsMutation } from '@timed/gql';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  patch: {
    ndisId: Client['ndisId'];
    ndisPlanStartDate: Client['ndisPlanStartDate'];
    ndisPlanEndDate: Client['ndisPlanEndDate'];
    ndisPlanInitialFundsCore: Client['ndisPlanInitialFundsCore'];
    ndisPlanInitialFundsCs: Client['ndisPlanInitialFundsCs'];
    ndisPlanInitialFundsCb: Client['ndisPlanInitialFundsCb'];
    planManager?: QueryByIdInput | null;
    supportCoordinator?: QueryByIdInput | null;
  };
};

const ClientUpdateNdisDetailsForm = () => {
  const client = useContext(ClientContext);

  const [editing, setEditing] = useState<boolean>(false);

  const [updateClients, response] = useUpdateClientsMutation();

  const defaultValues: FormData = {
    patch: {
      ndisId: client.ndisId,
      ndisPlanStartDate: client.ndisPlanStartDate,
      ndisPlanEndDate: client.ndisPlanEndDate,
      ndisPlanInitialFundsCore: client.ndisPlanInitialFundsCore,
      ndisPlanInitialFundsCs: client.ndisPlanInitialFundsCs,
      ndisPlanInitialFundsCb: client.ndisPlanInitialFundsCb,
      planManager: client.planManager?.id ? { id: client.planManager?.id } : null,
      supportCoordinator: client.supportCoordinator?.id
        ? { id: client.supportCoordinator?.id }
        : null,
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) => {
    // Delete "planManager" object if it's "id" property is nullish
    !values.patch.planManager?.id && (values.patch.planManager = null);

    // Delete "supportCoordinator" object if it's "id" property is nullish
    !values.patch.supportCoordinator?.id && (values.patch.supportCoordinator = null);

    updateClients({ variables: { input: { ids: [client.id], ...values } } }).catch((e) => {});
    reset(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Participant Details"
        topRight={
          <Protected admin>
            <IconButtonMulti
              enabled={editing}
              changed={!isEqual(currentValues, defaultValues)}
              setEditing={setEditing}
              loading={response.loading}
              success={!!response.data}
            />
          </Protected>
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>NDIS ID</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                client.ndisId
              ) : (
                <ClientNdisIdInput
                  fullWidth
                  name="patch.ndisId"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.ndisId}
                  helperText={errors.patch?.ndisId?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>NDIS Plan Dates</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!client.ndisPlanStartDate || !!client.ndisPlanEndDate ? (
                  (!!client.ndisPlanStartDate
                    ? format(new Date(client.ndisPlanStartDate), 'dd/MM/yyyy')
                    : 'Unknown') +
                  ' to ' +
                  (!!client.ndisPlanEndDate
                    ? format(new Date(client.ndisPlanEndDate), 'dd/MM/yyyy')
                    : 'Unknown')
                ) : (
                  ''
                )
              ) : (
                <Box style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                  <DateInput
                    keyboard
                    clearable
                    disableTime
                    openTo="year"
                    name="patch.ndisPlanStartDate"
                    control={control}
                    inputVariant="outlined"
                    size="small"
                    error={!!errors.patch?.ndisPlanStartDate}
                    helperText={errors.patch?.ndisPlanStartDate?.message}
                    disabled={!editing}
                  />{' '}
                  To{' '}
                  <DateInput
                    keyboard
                    clearable
                    disableTime
                    openTo="year"
                    name="patch.ndisPlanEndDate"
                    control={control}
                    inputVariant="outlined"
                    size="small"
                    error={!!errors.patch?.ndisPlanEndDate}
                    helperText={errors.patch?.ndisPlanEndDate?.message}
                    disabled={!editing}
                  />
                </Box>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>NDIS Plan Initial Funds (Core)</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                client.ndisPlanInitialFundsCore ? (
                  '$' + client.ndisPlanInitialFundsCore
                ) : (
                  ''
                )
              ) : (
                <Box style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                  <TextField
                    fullWidth
                    label="Whole Dollars"
                    type="number"
                    name="patch.ndisPlanInitialFundsCore"
                    variant="outlined"
                    size="small"
                    control={control}
                    error={!!errors.patch?.ndisPlanInitialFundsCore}
                    helperText={errors.patch?.ndisPlanInitialFundsCore?.message}
                    disabled={!editing}
                    transform={transformNumberToFixedFloat(0)}
                  />
                </Box>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>NDIS Plan Initial Funds (CS)</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                client.ndisPlanInitialFundsCs ? (
                  '$' + client.ndisPlanInitialFundsCs
                ) : (
                  ''
                )
              ) : (
                <Box style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                  <TextField
                    fullWidth
                    label="Whole Dollars"
                    type="number"
                    name="patch.ndisPlanInitialFundsCs"
                    variant="outlined"
                    size="small"
                    control={control}
                    error={!!errors.patch?.ndisPlanInitialFundsCs}
                    helperText={errors.patch?.ndisPlanInitialFundsCs?.message}
                    disabled={!editing}
                    transform={transformNumberToFixedFloat(0)}
                  />
                </Box>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>NDIS Plan Initial Funds (CB)</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                client.ndisPlanInitialFundsCb ? (
                  '$' + client.ndisPlanInitialFundsCb
                ) : (
                  ''
                )
              ) : (
                <Box style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                  <TextField
                    fullWidth
                    label="Whole Dollars"
                    type="number"
                    name="patch.ndisPlanInitialFundsCb"
                    variant="outlined"
                    size="small"
                    control={control}
                    error={!!errors.patch?.ndisPlanInitialFundsCb}
                    helperText={errors.patch?.ndisPlanInitialFundsCb?.message}
                    disabled={!editing}
                    transform={transformNumberToFixedFloat(0)}
                  />
                </Box>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Plan Manager</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                client.planManager?.name
              ) : (
                <ClientPlanManagerInput
                  displayEmpty
                  fullWidth
                  name="patch.planManager.id"
                  control={control}
                  error={!!errors.patch?.planManager}
                  // helperText={errors.patch?.planManager?.message}
                  disabled={!editing}
                  formControlProps={{ size: 'small', variant: 'outlined' }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Support Coordinator</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                <Link to={_peopleSupportCoordinators.path + '/' + client.supportCoordinator?.id}>
                  {client.supportCoordinator && formatPersonName(client.supportCoordinator)}
                </Link>
              ) : (
                <ClientSupportCoordinatorInput
                  displayEmpty
                  fullWidth
                  label={undefined}
                  name="patch.supportCoordinator.id"
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  error={!!errors.patch?.supportCoordinator}
                  // helperText={errors.patch?.supportCoordinator?.message}
                  disabled={!editing}
                  formControlProps={{ size: 'small', variant: 'outlined' }}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default ClientUpdateNdisDetailsForm;
