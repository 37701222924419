import { createStyles, FormControl, makeStyles, TextField, Theme } from '@material-ui/core';
import { Chip, ChipProps, formatPersonName, Select, SelectProps } from '@timed/common';
import {
  GetClientsSelectQuery,
  GetMembersSelectQuery,
  MembersOrderByInput,
  MembersWhereInput,
  RedactedClientsOrderByInput,
  RedactedClientsWhereInput,
  useGetClientsSelectLazyQuery,
  useGetMembersSelectLazyQuery,
} from '@timed/gql';
import { useEffect, useMemo } from 'react';
import { UseFormWatch } from 'react-hook-form';

type ProfileInputProps = Omit<SelectProps, 'items'> & {
  chipProps: ChipProps;
  type: 'client' | 'member';
  watch: UseFormWatch<any>;
  where?: RedactedClientsWhereInput | MembersWhereInput;
  orderBy?: (RedactedClientsOrderByInput | MembersOrderByInput)[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 185,
      backgroundColor: 'white',
    },
    loading: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.background.default,
      },
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      height: 18,
    },
    chip: {
      // marginLeft: theme.spacing(0.5),
      height: 20,
    },
  }),
);

function ProfileInput({ chipProps, type, where, orderBy, watch, ...props }: ProfileInputProps) {
  const classes = useStyles();

  const watched = watch(props.name);

  const [getMembers, members] = useGetMembersSelectLazyQuery({
    variables: { input: { where, orderBy } },
  });

  const [getClients, clients] = useGetClientsSelectLazyQuery({
    variables: { input: { where, orderBy } },
  });

  const [data, loading] = useMemo(() => {
    switch (type) {
      case 'client':
        return [clients.data, clients.loading];
      case 'member':
        return [members.data, members.loading];
    }
  }, [type, clients, members]);

  useEffect(() => {
    if (!data && !loading) {
      switch (type) {
        case 'client':
          getClients();
          break;
        case 'member':
          getMembers();
          break;
      }
    }
  }, [data, getMembers, getClients, loading, type]);

  if (loading || !data) return <>Loading...</>;

  const profiles =
    type === 'member'
      ? (data as GetMembersSelectQuery).members
      : (data as GetClientsSelectQuery).redactedClients;

  const selected =
    type === 'member'
      ? (data as GetMembersSelectQuery).members?.find((m) => m.id === watched)
      : (data as GetClientsSelectQuery).redactedClients?.find((c) => c.id === watched);

  const profileList = profiles
    ? profiles.map((item: any) => {
        return {
          value: item.id!,
          label: formatPersonName(item, { capitaliseLastName: true, lastNameFirst: true })!,
        };
      })
    : undefined;

  return loading ? (
    <FormControl className={classes.loading}>
      <TextField disabled label={type} variant="outlined" size="small" value="Loading..." />
    </FormControl>
  ) : (
    <Select
      items={profileList}
      renderValue={() =>
        selected && (
          <div className={classes.chips}>
            <Chip
              size="small"
              color="primary"
              label={formatPersonName(selected!, { capitaliseLastName: true, lastNameFirst: true })}
              className={classes.chip}
              onMouseDown={(event: any) => event.stopPropagation()}
              {...chipProps}
            />
          </div>
        )
      }
      {...props}
    />
  );
}

export default ProfileInput;
