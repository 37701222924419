import { AppBarContext } from '@timed/app-bar';
import { DrawerContext } from '@timed/drawer';
import { PageContext } from '@timed/page';
import { useContext } from 'react';

/**
 * This hook exposes all page-related contexts
 */
const usePage = () => ({
  ...useContext(PageContext),
  ...useContext(DrawerContext),
  ...useContext(AppBarContext),
});

export default usePage;
