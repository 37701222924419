import { Typography } from '@material-ui/core';
import { useRouter } from '@timed/common';
import { useGetSupportCoordinatorByIdLazyQuery } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { SupportCoordinatorContext } from '@timed/support-coordinator';
import { ErrorNotFound } from '@timed/routes';
import React, { useEffect } from 'react';

type SupportCoordinatorProviderProps = React.PropsWithChildren<{}>;

const SupportCoordinatorProvider = ({ children }: SupportCoordinatorProviderProps) => {
  const { query } = useRouter();

  const [supportCoordinatorById, { data, loading, error }] =
    useGetSupportCoordinatorByIdLazyQuery();

  useEffect(() => {
    query.supportCoordinator &&
      supportCoordinatorById({ variables: { id: query.supportCoordinator as string } });
  }, [supportCoordinatorById, query.supportCoordinator]);

  useLoadingEffect(loading);

  if (loading) return <Typography variant="body2">Loading...</Typography>;

  if (!query || !data || error) return <ErrorNotFound />;

  return (
    <SupportCoordinatorContext.Provider value={{ ...data?.supportCoordinatorById }}>
      {children}
    </SupportCoordinatorContext.Provider>
  );
};

export default SupportCoordinatorProvider;
