import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Checkbox, CheckboxProps } from '@timed/common';

const ClientAllowMedicationCheckbox = (props: CheckboxProps) => (
  <Checkbox
    defaultChecked={false}
    label="Allow the recording of medication"
    icon={<RadioButtonUncheckedIcon />}
    checkedIcon={<CheckCircleOutlineRoundedIcon />}
    {...props}
  />
);

export default ClientAllowMedicationCheckbox;
