import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  addServerErrors,
  DateInput,
  FormModal,
  ModalProps,
  ProfileInput,
  Select,
  TextField,
  TimeInput,
} from '@timed/common';
import {
  Client,
  CreateClientObservationDocument,
  Event,
  Member,
  OrderBy,
  StoolType,
  UrineColor,
  useCreateClientObservationMutation,
  useCreateClientObservationWithEventMutation,
} from '@timed/gql';
import clsx from 'clsx';
import { isAfter, isBefore, isValid, max, subMinutes, subMonths } from 'date-fns';
import _, { camelCase, startCase } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

type ClientCreateBowelMovementFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  clientId?: Client['id'];
  event?: Pick<Event, 'id' | 'startAt' | 'endAt'> & { member?: Pick<Member, 'id'> | null };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    types: {
      alignItems: 'center',
      display: 'flex',
      gap: theme.spacing(4),
    },
    type: {
      cursor: 'pointer',
      padding: theme.spacing(2),
      backgroundColor: 'white',
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
    },
    selectedType: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      border: '1px solid ' + green[500],
      backgroundColor: green[50],
      color: green[800],
    },
    items: {
      display: 'grid',
      gridTemplateColumns: 'max-content max-content',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(4),
      alignItems: 'center',
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 100,
      },
    },
    dateInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 120,
      },
    },
    notesInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 248,
      },
    },
  }),
);

type FormData = {
  date?: Date;
  time?: Date | null;
  notes?: string | null;
  nursesName?: string | null;
  stoolType?: StoolType | null;
  urineColor?: UrineColor | null;
  member?: { id: string } | null;
};

const ClientCreateBowelMovementFormModal = ({
  onClose,
  clientId,
  event,
  ...modalProps
}: ClientCreateBowelMovementFormModalProps) => {
  const classes = useStyles();

  const [selectedType, selectType] = useState<'bm' | 'urine' | null>('bm');

  const [createClientObservation, responseWithoutEvent] = useCreateClientObservationMutation();
  const [createClientObservationWithEvent, responseWithEvent] =
    useCreateClientObservationWithEventMutation();

  const now: Date = useMemo(() => new Date(), []);
  let date: Date = !!event ? max([subMinutes(now, 10), new Date(event.startAt)]) : now;

  // Recording obs before event has started
  if (!!event && isBefore(now, new Date(event.startAt))) {
    date = new Date(event.startAt);
  }

  // Recording obs after event has finished
  if (!!event && isAfter(now, new Date(event.endAt))) {
    date = new Date(event.endAt);
  }

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    watch,
    formState: { errors, isValid: formIsValid },
  } = useForm<FormData>({
    defaultValues: {
      date,
      time: date,
      stoolType: StoolType.TYPE4,
      urineColor: UrineColor.YELLOW,
      notes: null,
      nursesName: null,
      member: event?.member ? { id: event?.member?.id } : null,
    },
  });

  const response = useMemo(
    () => (!!event ? responseWithEvent : responseWithoutEvent),
    [event, responseWithoutEvent, responseWithEvent],
  );

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientObservations(existing = []) {
          return [
            ...existing,
            cache.writeQuery({ data: response.data, query: CreateClientObservationDocument }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async ({ member, stoolType, urineColor, ...values }: FormData) => {
    if (!values.time || !isValid(values.time)) setError('time', { message: 'Invalid time' });
    else {
      values = _.omit(values, ['time']);

      switch (selectedType) {
        case 'bm':
          Object.assign(values, { stoolType });
          break;
        case 'urine':
          Object.assign(values, { urineColor });
          break;
      }

      if (event)
        createClientObservationWithEvent({
          variables: {
            input: {
              ...values,
              event: { id: event.id },
            },
          },
        });
      else
        createClientObservation({
          variables: {
            input: {
              ...values,
              client: { id: clientId! },
              member: member?.id ? { id: member.id } : null,
            },
          },
        });
    }
  };

  const handleChangeTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('date')!);
        currentDate.setHours(date.getHours(), date.getMinutes());

        setValue('date', currentDate);
      }
    },
    [getValues, setValue],
  );

  if (!clientId && !event)
    throw new Error('clientId and eventId properties cannot both be undefined');

  return (
    <FormModal
      modalProps={modalProps}
      title="Record Toilet"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
      saveText="Record"
    >
      <Box className={classes.wrapper}>
        <Box className={classes.types}>
          <Box
            className={
              selectedType === 'bm' ? clsx(classes.type, classes.selectedType) : classes.type
            }
            onClick={() => {
              selectType(selectedType === 'bm' ? null : 'bm');
            }}
          >
            {selectedType === 'bm' && <CheckRoundedIcon style={{ fill: green[800] }} />}
            <Typography>Bowel Movement</Typography>
          </Box>
          <Box
            className={
              selectedType === 'urine' ? clsx(classes.type, classes.selectedType) : classes.type
            }
            onClick={() => {
              selectType(selectedType === 'urine' ? null : 'urine');
            }}
          >
            {selectedType === 'urine' && <CheckRoundedIcon style={{ fill: green[800] }} />}
            <Typography>Urine</Typography>
          </Box>
        </Box>

        {selectedType && (
          <Box className={classes.items}>
            <Typography variant="body1">Time</Typography>
            <Box className={classes.dateInput}>
              <DateInput
                required
                name="date"
                control={control}
                inputVariant="outlined"
                size="small"
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.date}
                helperText={errors.date?.message}
              />
              <TimeInput
                required
                keyboard
                name="time"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={(date) => {
                  if (isValid(date)) handleChangeTime(date);
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.time}
                helperText={errors.time?.message}
              />
            </Box>

            {selectedType === 'bm' && (
              <>
                <Typography variant="body1">Stool Type</Typography>
                <Box className={classes.input}>
                  <Select
                    name="stoolType"
                    variant="outlined"
                    control={control}
                    formControlProps={{ size: 'small' }}
                    error={!!errors.stoolType}
                    helperText={errors.stoolType?.message}
                    items={Object.values(StoolType).map((type) => {
                      return {
                        value: type,
                        label: startCase(camelCase(type)),
                      };
                    })}
                  />
                </Box>
              </>
            )}

            {selectedType === 'urine' && (
              <>
                <Typography variant="body1">Urine Colour</Typography>
                <Box className={classes.input}>
                  <Select
                    name="urineColor"
                    variant="outlined"
                    control={control}
                    formControlProps={{ size: 'small' }}
                    error={!!errors.urineColor}
                    helperText={errors.urineColor?.message}
                    items={[
                      {
                        value: UrineColor.CLEAR,
                        label: 'Clear',
                      },
                      {
                        value: UrineColor.LIGHTYELLOW,
                        label: 'Light Yellow',
                      },
                      {
                        value: UrineColor.YELLOW,
                        label: 'Yellow',
                      },
                      {
                        value: UrineColor.DARKYELLOW,
                        label: 'Dark Yellow',
                      },
                      {
                        value: UrineColor.BROWN,
                        label: 'Brown',
                      },
                    ]}
                  />
                </Box>
              </>
            )}

            {!event && (
              <>
                <Typography variant="body1">Support worker</Typography>
                <Box className={classes.notesInput}>
                  <ProfileInput
                    control={control}
                    name="member.id"
                    chipProps={{ onDelete: () => setValue('member.id', '') }}
                    formControlProps={{ variant: 'outlined', size: 'small' }}
                    watch={watch}
                    error={!!errors.member}
                    type="member"
                    orderBy={[{ lastName: OrderBy.ASC }]}
                    where={{
                      events: {
                        client: { id: { _eq: clientId } },
                        startAt: { _gte: subMonths(new Date(), 3) },
                      },
                    }}
                  />
                </Box>
              </>
            )}

            <Typography variant="body1">Nurse Name</Typography>
            <Box className={classes.notesInput}>
              <TextField
                name="nursesName"
                variant="outlined"
                size="small"
                control={control}
                error={!!errors.nursesName}
                helperText={errors.nursesName?.message}
              ></TextField>
            </Box>

            <Typography variant="body1">Notes</Typography>
            <Box className={classes.notesInput}>
              <TextField
                multiline
                minRows={2}
                name="notes"
                variant="outlined"
                size="small"
                control={control}
                error={!!errors.notes}
                helperText={errors.notes?.message}
              ></TextField>
            </Box>
          </Box>
        )}
      </Box>
    </FormModal>
  );
};

export default ClientCreateBowelMovementFormModal;
