import { Address } from '@timed/gql';

export const formatAddress = (
  location?: Pick<
    Address,
    'unit' | 'street' | 'locality' | 'region' | 'postcode' | 'country'
  > | null,
  postcode: boolean = false,
) => {
  const parts: any[] = [];

  location?.street &&
    location.locality &&
    location.region &&
    location?.country &&
    parts.push(
      (!!location?.unit ? 'U' + location.unit.toLocaleUpperCase() + ' ' : '') + location.street,
    );

  if (location?.locality && location.region && location?.country) {
    if (postcode && location?.postcode)
      parts.push(location.locality + ' ' + location.region + ' ' + location.postcode);
    else parts.push(location.locality + ' ' + location.region);
  } else if (location?.region && location?.country) {
    parts.push(location.region);
  }

  // location?.country && parts.push(location.country);

  return parts.join(', ');
};
