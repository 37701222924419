import {
  Box,
  createStyles,
  Hidden,
  makeStyles,
  SwipeableDrawer,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { DrawerAccount, DrawerControlPanel, DrawerLogo, DrawerNavBar } from '@timed/drawer';
import { usePage } from '@timed/page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: theme.spacing(80),
      flexDirection: 'column',
      '&.MuiDrawer-paperAnchorDockedLeft': {
        border: 'none',
      },
      [theme.breakpoints.up('sm')]: {
        position: 'inherit',
      },
    },
    top: {
      display: 'flex',
      flexShrink: 0,
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(16),
      },
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(12),
      },
    },
    bottom: {
      display: 'flex',
      flexGrow: 1,
    },
  }),
);

const Drawer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { isVisible, toggleDrawer } = usePage();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const iOS = (process as any).browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <Hidden smDown implementation={smDown ? 'css' : 'js'}>
      <SwipeableDrawer
        open={isVisible || !smDown}
        anchor="left"
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        variant={smDown ? 'temporary' : 'persistent'}
        PaperProps={{ className: classes.root }}
        ModalProps={{ keepMounted: true }}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <Box className={classes.top}>
          <DrawerLogo />
          <DrawerAccount />
        </Box>
        <Box className={classes.bottom}>
          <DrawerNavBar />
          <DrawerControlPanel />
        </Box>
      </SwipeableDrawer>
    </Hidden>
  );
};

export default Drawer;
