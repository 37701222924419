import { _shiftsPersonalUnavailability } from '@timed/app';
import { Page } from '@timed/page';
import { ShiftsUnavailability } from '@timed/shifts/components/Unavailability';

const ShiftsPersonalUnavailability = () => {
  return (
    <Page {..._shiftsPersonalUnavailability}>
      <ShiftsUnavailability />
    </Page>
  );
};
export default ShiftsPersonalUnavailability;
