import { AppBarContext, Crumb, CrumbText, isCrumbAnElement, SetCrumbs } from '@timed/app-bar';
import { useReducer } from 'react';

const AppBarProvider: React.FC = ({ children }) => {
  const [breadcrumbs, setCrumbs] = useReducer((crumbs: Crumb[][], newCrumbs: SetCrumbs) => {
    // Reset crumbs to initial value
    newCrumbs.reset && (crumbs = []);

    // Overwrite (potentially) exiting crumbs at specified level
    crumbs[newCrumbs.index] = newCrumbs.crumbs;

    return crumbs;
  }, []);

  // Get the deepest-nested module-crumb from the current breadcrumbs array
  const getLastCrumbModule = (): CrumbText | undefined =>
    breadcrumbs
      .flat()
      .reverse()
      .find((crumb) => !isCrumbAnElement(crumb) && crumb.isModule) as CrumbText;

  return (
    <AppBarContext.Provider value={{ breadcrumbs, setCrumbs, getLastCrumbModule }}>
      {children}
    </AppBarContext.Provider>
  );
};

export default AppBarProvider;
