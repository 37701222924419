import { Box, createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { AlertProvider } from '@timed/alert';
import { _schedule } from '@timed/app';
import { AppBar, AppBarProvider } from '@timed/app-bar';
import { AuthProvider, OverridePermissionsOpenModalButton } from '@timed/auth';
import { useRouter } from '@timed/common';
import { Drawer, DrawerProvider } from '@timed/drawer';
import { LoadingIndicator, LoadingProvider } from '@timed/loading';
import { PageProvider } from '@timed/page';
import { ScheduleProvider } from '@timed/schedule';
import clsx from 'clsx';
import ModalProvider from 'mui-modal-provider';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: theme.palette.background.default,
    },
    wrapper: {
      display: 'flex',
      flexFlow: 'column',
      flex: '1 1 auto',
    },
    content: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8),
      },
    },
    contentMobileSchedule: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0),
      },
    },
  }),
);

const Authenticated = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { pathname } = useRouter();

  useEffect(() => {
    pathname === '/' && navigate(_schedule.path);
  }, [pathname, navigate]);

  // Do not apply padding for the scheduel route on small screens
  const contentClass =
    smDown && pathname === _schedule.path
      ? clsx(classes.content, classes.contentMobileSchedule)
      : classes.content;

  return (
    <LoadingProvider>
      <AuthProvider>
        {/* <NotificationProvider> */}
        <ModalProvider>
          <LoadingIndicator />
          <AlertProvider>
            <AppBarProvider>
              <DrawerProvider>
                <PageProvider>
                  <Box className={classes.root}>
                    <ScheduleProvider>
                      <Drawer />
                      <Box className={classes.wrapper}>
                        <AppBar />
                        <Box className={contentClass}>
                          <Outlet />
                          <OverridePermissionsOpenModalButton />
                        </Box>
                      </Box>
                    </ScheduleProvider>
                  </Box>
                </PageProvider>
              </DrawerProvider>
            </AppBarProvider>
          </AlertProvider>
        </ModalProvider>
        {/* </NotificationProvider> */}
      </AuthProvider>
    </LoadingProvider>
  );
};

export default Authenticated;
