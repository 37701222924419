import { useRouter } from '@timed/common';
import { _peopleSupportCoordinatorsProfileCategory } from '@timed/app';
import { Page } from '@timed/page';
import {
  SupportCoordinatorViewClients,
  SupportCoordinatorViewNotes,
  SupportCoordinatorViewOverview,
} from '@timed/support-coordinator';
import { ErrorNotFound } from '@timed/routes';
import React from 'react';

const PeopleSupportCoordinatorsProfileCategory = () => {
  const {
    query: { category, supportCoordinator },
  } = useRouter();

  let element!: JSX.Element;
  let label!: string;
  let path!: string;

  switch (category) {
    case 'overview':
      label = 'Overview';
      path = `/people/support-coordinators/${supportCoordinator}/overview`;
      element = <SupportCoordinatorViewOverview />;
      break;

    case 'notes':
      label = 'Notes';
      path = `/people/support-coordinators/${supportCoordinator}/notes`;
      element = <SupportCoordinatorViewNotes />;
      break;

    case 'participants':
      label = 'Participants';
      path = `/people/support-coordinators/${supportCoordinator}/participants`;
      element = <SupportCoordinatorViewClients />;
      break;
  }

  return !label || !path || !element ? (
    <ErrorNotFound />
  ) : (
    <Page {..._peopleSupportCoordinatorsProfileCategory} label={label} path={path}>
      {element}
    </Page>
  );
};

export default PeopleSupportCoordinatorsProfileCategory;
