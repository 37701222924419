import { DrawerContext } from '@timed/drawer';
import { DrawerControl } from '@timed/drawer/context/DrawerContext';
import { useCallback, useState } from 'react';

const DrawerProvider: React.FC = ({ children }) => {
  const [isVisible, toggleDrawer] = useState<boolean>(false);
  const [controls, setControls] = useState<DrawerControl[]>();

  /**
   * Toggle visibility state of drawer
   * Will not close drawer upon "Tab" and "Shift" key-down events
   */
  const handleToggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    toggleDrawer(open);
  };

  /**
   * Set control panel items
   */
  const handleSetControls = useCallback(
    (items: DrawerControl[] | undefined) => setControls(items),
    [setControls],
  );

  /**
   * Remove all rendered control panel items
   */
  const handleClearControls = () => setControls(undefined);

  return (
    <DrawerContext.Provider
      value={{
        isVisible,
        toggleDrawer: handleToggleDrawer,
        controls,
        setControls: handleSetControls,
        clearControls: handleClearControls,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
