import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { Select, useRouter } from '@timed/common';
import { _schedule } from '@timed/app';
import { ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'inherit',
      '& .MuiSvgIcon-root': {
        fill: 'rgba(255,255,255,0.4)',
      },
      '& :focus': {
        background: 'inherit',
      },
    },
    formControl: {
      [theme.breakpoints.between('xs', 'sm')]: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
      },
    },
  }),
);

const ScheduleControlsDateRangeInput = () => {
  const classes = useStyles();
  const {
    navigate,
    search: [searchParams],
  } = useRouter();
  const { control } = useForm();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    if (searchParams.get('r') !== event.target.value) {
      localStorage.setItem('schedule.range', event.target.value as string);
      searchParams.set('r', event.target.value as string);
      navigate(_schedule.path + '?' + searchParams);
    }
  };

  return (
    <Select
      disableUnderline
      aria-label="date-range"
      name="r"
      defaultValue="28"
      value={searchParams.get('r')}
      control={control}
      onChange={handleChange}
      className={classes.root}
      formControlProps={{ className: classes.formControl }}
      items={
        smDown
          ? [
              // { label: '3 days', value: '3' },
              { label: 'Week', value: '7' },
              { label: 'Fortnight', value: '14' },
              { label: 'Month', value: '28' },
              { label: '2 Months', value: '56' },
              { label: '3 Months', value: '84' },
            ]
          : [
              // { label: '3 days', value: '3' },
              { label: 'Week', value: '7' },
              { label: 'Fortnight', value: '14' },
              { label: 'Month', value: '28' },
              { label: '2 Months', value: '56' },
              { label: '3 Months', value: '84' },
            ]
      }
    />
  );
};

export default ScheduleControlsDateRangeInput;
