import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Permission } from '@timed/gql';
import { _peoplePlanManagers } from '@timed/app';
import { Protected } from '@timed/auth';
import { Page } from '@timed/page';
import { PlanManagerCreateForm, PlanManagerList } from '@timed/plan-manager';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    leftColumn: {
      flex: '1 1 0px',
      display: 'grid',
      gap: theme.spacing(2),
    },
  }),
);

const PeoplePlanManagers = () => {
  const classes = useStyles();

  return (
    <Page {..._peoplePlanManagers}>
      <Box className={classes.container}>
        <Box className={classes.leftColumn}>
          <PlanManagerList />
        </Box>
        <Protected permissions={Permission.PLANMANAGER_WRITE}>
          <PlanManagerCreateForm />
        </Protected>
      </Box>
    </Page>
  );
};

export default PeoplePlanManagers;
