import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';
import { TransformInput, validateRequired } from '@timed/common';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

export type TextFieldProps = MuiTextFieldProps & {
  defaultValue?: unknown;
  control?: Control<any>;
  validation?: Omit<RegisterOptions, 'required'>;
  transform?: TransformInput;
};

const TextField = ({
  validation,
  control,
  defaultValue = '',
  transform,
  onChange: onChangeControlled,
  ...props
}: TextFieldProps) => {
  return control ? (
    <Controller
      key={props.name}
      name={props.name!}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, ...field } }) => (
        <MuiTextField
          key={props.name}
          onChange={(e) => {
            !!onChangeControlled && onChangeControlled(e);
            transform ? onChange(transform.output(e)) : onChange(e);
          }}
          {...field}
          {...props}
        />
      )}
      rules={validation && props.required ? { ...validateRequired, ...validation } : validation}
    />
  ) : (
    <MuiTextField {...props} />
  );
};

export default TextField;
