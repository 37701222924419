import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { IconButtonAsync, IconButtonAsyncProps } from '@timed/common';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

type IconButtonMultiProps = IconButtonAsyncProps & {
  enabled: boolean;
  changed: boolean;
  setEditing: Dispatch<SetStateAction<boolean>>;
};

const IconButtonMulti = ({
  enabled,
  changed,
  setEditing,
  onSuccess,
  ...props
}: IconButtonMultiProps) => {
  const timer = useRef<number>();

  const DisplayIcon = props.loading
    ? SaveIcon
    : enabled
    ? changed
      ? SaveIcon
      : CloseIcon
    : EditIcon;

  // Toggle icons only when not submitting
  const handleToggle = () => {
    timer.current = window.setTimeout(() => {
      if (!enabled) setEditing(true);
      else if (enabled && !changed) setEditing(false);
    }, 10);
  };

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  return (
    <IconButtonAsync
      color={enabled && changed ? 'primary' : 'default'}
      type={enabled && changed ? 'submit' : undefined}
      onClick={handleToggle}
      onSuccess={() => {
        onSuccess && onSuccess();
        setEditing(false);
      }}
      {...props}
    >
      <DisplayIcon fontSize="small" />
    </IconButtonAsync>
  );
};

export default IconButtonMulti;
