import { Box, createStyles, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { grey, red, yellow } from '@material-ui/core/colors';
import { Button, Checkbox, DateInput, formatPersonName, Select, TimeInput } from '@timed/common';
import {
  OrderBy,
  useGetApproveShiftsOrgSettingsQuery,
  useGetShiftsToApproveLazyQuery,
} from '@timed/gql';
import {
  addDays,
  addMinutes,
  format,
  isBefore,
  isSameDay,
  startOfToday,
  startOfWeek,
  subDays,
  subMinutes,
  subWeeks,
} from 'date-fns';
import { isAfter } from 'date-fns/esm';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  period: 'previous' | 'current' | 'custom' | undefined;
  from?: Date;
  to?: Date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    controls: {
      width: 300,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    dateInput: {
      display: 'flex',
      gap: theme.spacing(4),
    },
    title: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    header: {
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: grey[200],
    },
    shifts: {
      border: '1px solid ' + grey[500],
      width: 'max-content',
      display: 'grid',
      gridTemplateColumns: 'repeat(10, auto)',
      flexDirection: 'column',
      // columnGap: theme.spacing(16),
      '& .MuiTypography-root': {
        fontSize: 12,
        padding: theme.spacing(0, 4, 0, 4),
        borderRight: '1px solid ' + grey[300],
        borderBottom: '1px solid ' + grey[500],
      },
    },
    error: {
      backgroundColor: red[200],
    },
    highlight: {
      backgroundColor: yellow[200],
    },
    // shift: {
    //   // width: 'max-content',
    //   padding: theme.spacing(4),
    //   borderRadius: theme.shape.borderRadius,
    //   cursor: 'pointer',
    //   borderBottom: '1px solid grey',
    // },
  }),
);

const ShiftsApprove = () => {
  const classes = useStyles();

  // const theme = useTheme();

  // const auth = useAuth();

  const WEEK_STARTS_ON = 1; // MONDAY

  const roundUp = 5;

  const [getEvents, { data, loading }] = useGetShiftsToApproveLazyQuery();

  const orgSettings = useGetApproveShiftsOrgSettingsQuery();

  const [clockOnHighlightMinutes, clockOffHighlightMinutes] = useMemo(() => {
    return [
      orgSettings.data?.me.member?.org.clockOnWarnMinutes ?? 10,
      orgSettings.data?.me.member?.org.clockOffWarnMinutes ?? 10,
    ];
  }, [orgSettings.data?.me.member?.org]);

  const { control, watch, setValue } = useForm<FormData>();

  const period = watch('period');
  const from = watch('from');
  const to = watch('to');

  const handleFetchEvents = () => {
    if (!!from && !!to && !data && !loading)
      getEvents({
        variables: {
          input: {
            where: {
              member: { id: { _ne: null } },
              endAt: { _gte: from },
              startAt: { _lte: addDays(to, 1) },
              payable: { _eq: true },
            },
            orderBy: [
              { client: { lastName: OrderBy.ASC } },
              { client: { firstName: OrderBy.ASC } },
              { startAt: OrderBy.ASC },
            ],
          },
        },
      });
  };

  useEffect(() => {
    if (period) {
      switch (period) {
        case 'current':
          setValue('from', startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }));
          setValue('to', addDays(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 6));
          break;
        case 'previous':
          setValue('from', subWeeks(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 1));
          setValue('to', subDays(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 1));
          break;
        case 'custom':
          setValue('from', startOfToday());
          setValue('to', startOfToday());
          break;
      }
    }
  }, [period, setValue]);

  const periods = [
    { label: 'Most recently ended payroll period', value: 'previous' },
    { label: 'Current payroll period', value: 'current' },
    { label: 'Custom date range', value: 'custom' },
  ];

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.controls}>
        <Select
          control={control}
          name="period"
          label="Reporting period"
          items={periods}
          formControlProps={{
            variant: 'outlined',
            size: 'small',
            style: { gridColumn: 'Span 2' },
          }}
        />
        {period && (
          <Box className={classes.dateInput}>
            <DateInput
              disableToolbar
              autoOk
              control={control}
              name="from"
              label="From start of day"
              inputVariant="outlined"
              size="small"
              disabled={period === 'current' || period === 'previous'}
            />
            <DateInput
              disableToolbar
              autoOk
              control={control}
              name="to"
              label="To end of day"
              inputVariant="outlined"
              size="small"
              disabled={period === 'current' || period === 'previous'}
            />
          </Box>
        )}
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ width: 'max-content' }}
          onClick={() => {
            handleFetchEvents();
          }}
          disabled={!period || !from || !to}
        >
          Load Shifts
        </Button>
      </Box>
      <Divider />
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        !!data?.events && (
          <Box className={classes.shifts}>
            {!data?.events.length ? (
              <Typography variant="body1">No completed shifts found.</Typography>
            ) : (
              <>
                <Typography className={classes.header} variant="body1">
                  Participant
                </Typography>
                <Typography className={classes.header} variant="body1">
                  Employee
                </Typography>
                <Typography className={classes.header} variant="body1">
                  Date(s)
                </Typography>
                <Typography className={classes.header} variant="body1">
                  Start Time
                </Typography>
                <Typography className={classes.header} variant="body1">
                  Finish Time
                </Typography>
                <Typography className={classes.header} variant="body1">
                  Clock On
                </Typography>
                <Typography className={classes.header} variant="body1">
                  Clock Off
                </Typography>
                <Typography className={classes.header} variant="body1">
                  Adj. Start
                </Typography>
                <Typography className={classes.header} variant="body1">
                  Adj. End
                </Typography>
                <Typography className={classes.header} variant="body1">
                  Approve
                </Typography>

                {data?.events.map(
                  (event) => {
                    const highlightClockOn =
                      isAfter(
                        new Date(event.clockedOnAt),
                        addMinutes(new Date(event.startAt), clockOnHighlightMinutes),
                      ) ||
                      isBefore(
                        new Date(event.clockedOnAt),
                        subMinutes(new Date(event.startAt), clockOnHighlightMinutes),
                      );

                    const highlightClockOff =
                      isAfter(
                        new Date(event.clockedOffAt),
                        addMinutes(new Date(event.endAt), clockOffHighlightMinutes),
                      ) ||
                      isBefore(
                        new Date(event.clockedOffAt),
                        subMinutes(new Date(event.endAt), clockOffHighlightMinutes),
                      );

                    return (
                      <>
                        <Typography>
                          {formatPersonName(event.client, {
                            capitaliseLastName: true,
                            lastNameFirst: true,
                          })}
                        </Typography>
                        <Typography>
                          {formatPersonName(event.member!, {
                            capitaliseLastName: true,
                            lastNameFirst: true,
                          })}
                        </Typography>
                        <Typography>
                          {isSameDay(new Date(event.startAt), new Date(event.endAt))
                            ? format(new Date(event.startAt), 'd MMM yyyy')
                            : format(new Date(event.startAt), 'd MMM') +
                              ' to ' +
                              format(new Date(event.endAt), 'd MMM yyyy')}
                        </Typography>
                        <Typography>{format(new Date(event.startAt), 'HH:mm')}</Typography>
                        <Typography>{format(new Date(event.endAt), 'HH:mm')}</Typography>
                        <Typography
                          className={
                            !event.clockedOnAt
                              ? classes.error
                              : highlightClockOn
                              ? classes.highlight
                              : undefined
                          }
                        >
                          {!!event.clockedOnAt
                            ? format(new Date(event.clockedOnAt), 'HH:mm d/M')
                            : '-'}
                        </Typography>
                        <Typography
                          className={
                            !event.clockedOffAt
                              ? classes.error
                              : highlightClockOff
                              ? classes.highlight
                              : undefined
                          }
                        >
                          {!!event.clockedOffAt
                            ? format(new Date(event.clockedOffAt), 'HH:mm d/M')
                            : '-'}
                        </Typography>
                        <Typography>
                          {/* <DateInput
                          control={control}
                          name={'adjStartDate_' + event.id}
                          size="small"
                          format="dd/MM"
                          style={{ width: 100 }}
                        />
                        <TimeInput
                          control={control}
                          name={'adjStartTime_' + event.id}
                          size="small"
                          format="HH:mm"
                          style={{ width: 100 }}
                        /> */}

                          {format(new Date(event.startAt), 'dd/MM HH:mm')}
                        </Typography>
                        <Typography>{format(new Date(event.endAt), 'dd/MM HH:mm')}</Typography>
                        <Typography>
                          {/* <Checkbox control={control} name={event.id} /> */}
                        </Typography>
                        {/* <Checkbox name={event.id}></Checkbox> */}
                      </>
                    );
                  },

                  // <Box className={classes.shift}>
                  //   <Box className={classes.title}>
                  //     <Typography variant="h3" className={classes.bold}>
                  //       {format(new Date(event.startAt), "EEEE, do 'of' MMMM")}
                  //     </Typography>
                  //     {activeNow && (
                  //       <Typography variant="h3" className={classes.bold}>
                  //         (Active Now)
                  //       </Typography>
                  //     )}
                  //     {startingSoon && (
                  //       <Typography variant="h3" className={classes.bold}>
                  //         (Starting Soon)
                  //       </Typography>
                  //     )}
                  //   </Box>
                  //   <Typography variant="body2">
                  //     {formatTimeDuration({
                  //       start: new Date(event.startAt),
                  //       end: new Date(event.endAt),
                  //       options: { militaryTime: true },
                  //     })}
                  //   </Typography>
                  //   <Typography variant="body2">
                  //     Participant: {formatPersonName(event.client)}
                  //   </Typography>
                  //   <Typography variant="body2">
                  //     Support worker: {formatPersonName(event.member!)}
                  //   </Typography>
                  // </Box>
                )}
              </>
            )}
          </Box>
        )
      )}
    </Box>
  );
};

export default ShiftsApprove;
