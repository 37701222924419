import { Box, createStyles, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';
import { useAuth } from '@timed/auth';
import { formatPersonName, formatTimeDuration } from '@timed/common';
import { EventSummaryModal } from '@timed/event';
import { Event, OrderBy, useGetEventsLazyQuery } from '@timed/gql';
import { format, isWithinInterval, startOfWeek, subMinutes, subWeeks } from 'date-fns';
import { useModal } from 'mui-modal-provider';
import { CSSProperties, useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    title: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    shifts: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
      },
    },
    shift: {
      // width: 'max-content',
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
    },
  }),
);

const ShiftsMissingNotes = () => {
  const classes = useStyles();

  const theme = useTheme();

  const auth = useAuth();

  const { showModal } = useModal();

  const [getEvents, { data, loading }] = useGetEventsLazyQuery();

  // Fetch events
  useEffect(() => {
    if (!data && !loading)
      getEvents({
        variables: {
          input: {
            where: {
              member: { id: { _ne: null } },
              startAt: { _gte: subWeeks(new Date(), 2) },
              endAt: { _lte: new Date() },
              notes: { _eq: null },
            },
            orderBy: [{ startAt: OrderBy.ASC }],
          },
        },
      });
  }, [getEvents, data, loading]);

  const handleOpenModal = (event: Pick<Event, 'id' | 'startAt'>) => {
    const modal: { hide: () => void } = showModal(EventSummaryModal, {
      onClose: () => {
        modal.hide();
      },
      auth,
      eventId: event.id,
      selectedPayrollPeriod: startOfWeek(new Date(event.startAt), { weekStartsOn: 1 }),
    });
  };

  return loading ? (
    <Typography>Loading...</Typography>
  ) : (
    <Box className={classes.wrapper}>
      <Box className={classes.shifts}>
        {!data?.events.length ? (
          <Typography variant="body1">There are currently no shifts lacking case notes.</Typography>
        ) : (
          <>
            <Typography variant="body1">
              The following shifts have occurred within the past two weeks.
            </Typography>
            {data?.events.map((event) => {
              const activeNow: boolean = isWithinInterval(new Date(), {
                start: new Date(event.startAt),
                end: new Date(event.endAt),
              });
              const startingSoon: boolean = isWithinInterval(new Date(), {
                start: subMinutes(new Date(event.startAt), 120),
                end: new Date(event.startAt),
              });

              let style: CSSProperties = { backgroundColor: theme.palette.background.paper };

              if (activeNow) style.backgroundColor = green[200];

              if (startingSoon) style.backgroundColor = yellow[200];

              return (
                <Box className={classes.shift} onClick={() => handleOpenModal(event)} style={style}>
                  <Box className={classes.title}>
                    <Typography variant="h3" className={classes.bold}>
                      {format(new Date(event.startAt), "EEEE, do 'of' MMMM")}
                    </Typography>
                    {activeNow && (
                      <Typography variant="h3" className={classes.bold}>
                        (Active Now)
                      </Typography>
                    )}
                    {startingSoon && (
                      <Typography variant="h3" className={classes.bold}>
                        (Starting Soon)
                      </Typography>
                    )}
                  </Box>
                  <Typography variant="body2">
                    {formatTimeDuration({
                      start: new Date(event.startAt),
                      end: new Date(event.endAt),
                      options: { militaryTime: true },
                    })}
                  </Typography>
                  <Typography variant="body2">
                    Participant: {formatPersonName(event.client)}
                  </Typography>
                  <Typography variant="body2">
                    Support worker: {formatPersonName(event.member!)}
                  </Typography>
                </Box>
              );
            })}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ShiftsMissingNotes;
