import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { IconButton, useRouter } from '@timed/common';
import { _peopleSupportCoordinators } from '@timed/app';

const SupportCoordinatorViewCloseButton = () => {
  const { navigate } = useRouter();

  const handleClick = () => {
    navigate(_peopleSupportCoordinators.path);
  };

  return (
    <IconButton onClick={handleClick}>
      <CloseRoundedIcon />
    </IconButton>
  );
};

export default SupportCoordinatorViewCloseButton;
