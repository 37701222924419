import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A field whose value represents an Australian Business Number. */
  ABN: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: any;
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: any;
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: any;
  /** A field whose value represents an NDIS participant ID. */
  NdisId: any;
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: any;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  /** A field whose value represents either an email address or phone number - the unique identifiers for the User type. */
  UserIdentifier: any;
  /** A field whose value represents a not-yet-hashed password. */
  UserPlainTextPassword: any;
};

/** Australian Business Number conditional expression input. */
export type AbnConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['ABN']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['ABN']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['ABN']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['ABN']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['ABN']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['ABN']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['ABN']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['ABN']>>;
};

/** An address */
export type Address = {
  /** Country. */
  country?: Maybe<AddressCountryCode>;
  /** Latitude coordinate. */
  latitude?: Maybe<Scalars['String']>;
  /** Suburb or town. */
  locality?: Maybe<Scalars['String']>;
  /** Longitude coordinate. */
  longitude?: Maybe<Scalars['String']>;
  /** Postcode. */
  postcode?: Maybe<Scalars['PostalCode']>;
  /** State or territory. */
  region?: Maybe<AddressRegionCode>;
  /** Street number, name and type. */
  street?: Maybe<Scalars['String']>;
  /** Unit number. */
  unit?: Maybe<Scalars['String']>;
};

/** Countries. */
export enum AddressCountryCode {
  Afghanistan = 'Afghanistan',
  AlandIslands = 'AlandIslands',
  Albania = 'Albania',
  Algeria = 'Algeria',
  AmericanSamoa = 'AmericanSamoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  Antarctica = 'Antarctica',
  AntiguaAndBarbuda = 'AntiguaAndBarbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  /** Australia */
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bhutan = 'Bhutan',
  Bolivia = 'Bolivia',
  BonaireSintEustatiusSaba = 'BonaireSintEustatiusSaba',
  BosniaAndHerzegovina = 'BosniaAndHerzegovina',
  Botswana = 'Botswana',
  BouvetIsland = 'BouvetIsland',
  Brazil = 'Brazil',
  BritishIndianOceanTerritory = 'BritishIndianOceanTerritory',
  BruneiDarussalam = 'BruneiDarussalam',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'BurkinaFaso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'CapeVerde',
  CaymanIslands = 'CaymanIslands',
  CentralAfricanRepublic = 'CentralAfricanRepublic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  ChristmasIsland = 'ChristmasIsland',
  CocosKeelingIslands = 'CocosKeelingIslands',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CongoDemocraticRepublic = 'CongoDemocraticRepublic',
  CookIslands = 'CookIslands',
  CostaRica = 'CostaRica',
  CoteDIvoire = 'CoteDIvoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Curacao = 'Curacao',
  Cyprus = 'Cyprus',
  CzechRepublic = 'CzechRepublic',
  Denmark = 'Denmark',
  Djibouti = 'Djibouti',
  Dominica = 'Dominica',
  DominicanRepublic = 'DominicanRepublic',
  Ecuador = 'Ecuador',
  Egypt = 'Egypt',
  ElSalvador = 'ElSalvador',
  EquatorialGuinea = 'EquatorialGuinea',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  FalklandIslands = 'FalklandIslands',
  FaroeIslands = 'FaroeIslands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'FrenchGuiana',
  FrenchPolynesia = 'FrenchPolynesia',
  FrenchSouthernTerritories = 'FrenchSouthernTerritories',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadeloupe = 'Guadeloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guernsey = 'Guernsey',
  Guinea = 'Guinea',
  GuineaBissau = 'GuineaBissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  HeardIslandMcdonaldIslands = 'HeardIslandMcdonaldIslands',
  HolySeeVaticanCityState = 'HolySeeVaticanCityState',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  IsleOfMan = 'IsleOfMan',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jersey = 'Jersey',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  Korea = 'Korea',
  KoreaDemocraticPeoplesRepublic = 'KoreaDemocraticPeoplesRepublic',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  LaoPeoplesDemocraticRepublic = 'LaoPeoplesDemocraticRepublic',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  LibyanArabJamahiriya = 'LibyanArabJamahiriya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macao = 'Macao',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'MarshallIslands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Micronesia = 'Micronesia',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montenegro = 'Montenegro',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NewCaledonia = 'NewCaledonia',
  NewZealand = 'NewZealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  Niue = 'Niue',
  NorfolkIsland = 'NorfolkIsland',
  NorthernMarianaIslands = 'NorthernMarianaIslands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  PalestinianTerritory = 'PalestinianTerritory',
  Panama = 'Panama',
  PapuaNewGuinea = 'PapuaNewGuinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Pitcairn = 'Pitcairn',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'PuertoRico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  RussianFederation = 'RussianFederation',
  Rwanda = 'Rwanda',
  SaintBarthelemy = 'SaintBarthelemy',
  SaintHelena = 'SaintHelena',
  SaintKittsAndNevis = 'SaintKittsAndNevis',
  SaintLucia = 'SaintLucia',
  SaintMartin = 'SaintMartin',
  SaintPierreAndMiquelon = 'SaintPierreAndMiquelon',
  SaintVincentAndGrenadines = 'SaintVincentAndGrenadines',
  Samoa = 'Samoa',
  SanMarino = 'SanMarino',
  SaoTomeAndPrincipe = 'SaoTomeAndPrincipe',
  SaudiArabia = 'SaudiArabia',
  Senegal = 'Senegal',
  Serbia = 'Serbia',
  Seychelles = 'Seychelles',
  SierraLeone = 'SierraLeone',
  Singapore = 'Singapore',
  SintMaarten = 'SintMaarten',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  SolomonIslands = 'SolomonIslands',
  Somalia = 'Somalia',
  SouthAfrica = 'SouthAfrica',
  SouthGeorgiaAndSandwichIsl = 'SouthGeorgiaAndSandwichIsl',
  SouthSudan = 'SouthSudan',
  Spain = 'Spain',
  SriLanka = 'SriLanka',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  SvalbardAndJanMayen = 'SvalbardAndJanMayen',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  SyrianArabRepublic = 'SyrianArabRepublic',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  TimorLeste = 'TimorLeste',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'TrinidadAndTobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'TurksAndCaicosIslands',
  Tuvalu = 'Tuvalu',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'UnitedArabEmirates',
  UnitedKingdom = 'UnitedKingdom',
  UnitedStates = 'UnitedStates',
  UnitedStatesOutlyingIslands = 'UnitedStatesOutlyingIslands',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  VirginIslandsBritish = 'VirginIslandsBritish',
  VirginIslandsUS = 'VirginIslandsUS',
  WallisAndFutuna = 'WallisAndFutuna',
  WesternSahara = 'WesternSahara',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe',
}

/** Australian states and territories. */
export enum AddressRegionCode {
  ACT = 'ACT',
  NSW = 'NSW',
  NT = 'NT',
  QLD = 'QLD',
  SA = 'SA',
  TAS = 'TAS',
  VIC = 'VIC',
  WA = 'WA',
}

/** Aggregation data */
export type AggregateData = {
  __typename?: 'AggregateData';
  /** Total nodes discovered. */
  totalCount: Scalars['Int'];
};

/** Input data for archiving entities. */
export type ArchiveInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Reason for archiving. */
  reason?: InputMaybe<Scalars['String']>;
};

/** BaseHistory interface. */
export type BaseHistory = {
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Boolean conditional expression input. */
export type BooleanConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['Boolean']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean response */
export type BooleanResponse = {
  __typename?: 'BooleanResponse';
  /** Value. */
  value: Scalars['Boolean'];
};

export type Branch = {
  __typename?: 'Branch';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: Member;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  name: Scalars['String'];
  org: Org;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Filtering conditions for "BranchMember" entities. */
export type BranchMembersWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<BranchMembersWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<BranchMembersWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<BranchMembersWhereInput>>;
  /** Linked Branch entity. */
  branch?: InputMaybe<BranchesWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Datetime of entitys deletion. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Eraser of the entity. */
  deletedBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked Member entity. */
  member?: InputMaybe<MembersWhereInput>;
};

/** Aggregated response object for "Branch" entities. */
export type BranchesAggregate = {
  __typename?: 'BranchesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Branch>;
};

/** Creatable fields for "Branch" entities */
export type BranchesCreatableFieldsInput = {
  name: Scalars['String'];
};

/** Ordering options for "Branch" entities. */
export type BranchesOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by name. */
  name?: InputMaybe<OrderBy>;
};

/** Updatable fields for "Branch" entities. */
export type BranchesUpdatableFieldsInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "Branch" entities. */
export type BranchesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<BranchesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<BranchesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<BranchesWhereInput>>;
  /** BranchMember associative entities. */
  branchMembers?: InputMaybe<BranchMembersWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Name. */
  name?: InputMaybe<StringConditionalInput>;
};

/** A business */
export type Business = {
  /** Australian Business Number of the organisation */
  abn?: Maybe<Scalars['ABN']>;
  /** Legal name */
  legalName: Scalars['String'];
  /** Preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** Website of the organisation */
  website?: Maybe<Scalars['String']>;
};

/** A client of an organisation. */
export type Client = Address &
  Person & {
    __typename?: 'Client';
    /** Active status. */
    active: Scalars['Boolean'];
    /** Age in years. */
    age?: Maybe<Scalars['Int']>;
    /** Allow automatic population of data for unattended future events. */
    allowAutomaticEventPopulation: Scalars['Boolean'];
    /** Allow recording of medication. */
    allowMedication: Scalars['Boolean'];
    /** Allow recording of observations. */
    allowObservations: Scalars['Boolean'];
    /** Archive data, if entity is archived. */
    archive?: Maybe<HistoryRestorable>;
    /** Diastolic blood pressure lower-limit warning threshold (level 1). */
    bloodDiastolicLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Diastolic blood pressure lower-limit warning threshold (level 2). */
    bloodDiastolicLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Diastolic blood pressure upper-limit warning threshold (level 1). */
    bloodDiastolicUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Diastolic blood pressure upper-limit warning threshold (level 2). */
    bloodDiastolicUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure lower-limit warning threshold (level 1). */
    bloodSystolicLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure lower-limit warning threshold (level 2). */
    bloodSystolicLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure upper-limit warning threshold (level 1). */
    bloodSystolicUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure upper-limit warning threshold (level 2). */
    bloodSystolicUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Branch to which this client belongs. */
    branch: Branch;
    /** Hexidecimal color. */
    color?: Maybe<Scalars['HexColorCode']>;
    /** Contacts Email address. */
    contactsEmail?: Maybe<Scalars['String']>;
    /** Contacts name. */
    contactsName?: Maybe<Scalars['String']>;
    /** Contacts Phone number. */
    contactsPhone?: Maybe<Scalars['String']>;
    /** Address country. */
    country?: Maybe<AddressCountryCode>;
    /** Datetime of the entity's creation. */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Creator of the entity. */
    createdBy?: Maybe<Member>;
    /** Default duration (in hours) to use when creating events. */
    defaultEventDuration?: Maybe<Scalars['Float']>;
    /** Deletion data, if entity is deleted. */
    delete?: Maybe<HistoryRestorable>;
    /** Date of birth. */
    dob?: Maybe<Scalars['Date']>;
    /** Email address. */
    email?: Maybe<Scalars['String']>;
    events?: Maybe<Array<Event>>;
    /** First name. */
    firstName: Scalars['String'];
    /** Gender. */
    gender?: Maybe<Gender>;
    /** Heart rate lower-limit warning threshold (level 1). */
    heartLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Heart rate lower-limit warning threshold. (level 2) */
    heartLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Heart rate upper-limit warning threshold (level 1). */
    heartUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Heart rate upper-limit warning threshold (level 2). */
    heartUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Unique identifier of the entity. */
    id: Scalars['ID'];
    /** Last name. */
    lastName?: Maybe<Scalars['String']>;
    /** Address latitude coordinate. */
    latitude?: Maybe<Scalars['String']>;
    /** Address suburb or town. */
    locality?: Maybe<Scalars['String']>;
    /** Address longitude coordinate. */
    longitude?: Maybe<Scalars['String']>;
    medications?: Maybe<Array<ClientMedication>>;
    /** Middle name. */
    middleName?: Maybe<Scalars['String']>;
    /** NDIS participant ID. */
    ndisId?: Maybe<Scalars['NdisId']>;
    /** End datetime of the NDIS plan. */
    ndisPlanEndDate?: Maybe<Scalars['Date']>;
    /** NDIS plan initial funds capacity building. */
    ndisPlanInitialFundsCb?: Maybe<Scalars['Int']>;
    /** NDIS plan initial funds core support. */
    ndisPlanInitialFundsCore?: Maybe<Scalars['Int']>;
    /** NDIS plan initial funds community service. */
    ndisPlanInitialFundsCs?: Maybe<Scalars['Int']>;
    /** Start date of the NDIS plan. */
    ndisPlanStartDate?: Maybe<Scalars['Date']>;
    /** Administrator-only notes. */
    notes?: Maybe<Scalars['String']>;
    /** Organisation to which this client belongs. */
    org: Org;
    /** Phone number. */
    phone?: Maybe<Scalars['String']>;
    /** Plan manager for the client. */
    planManager?: Maybe<PlanManager>;
    /** Address postcode. */
    postcode?: Maybe<Scalars['PostalCode']>;
    /** Preferred name. */
    preferredName?: Maybe<Scalars['String']>;
    /** Pronouns. */
    pronouns?: Maybe<Pronouns>;
    /** Address state or territory. */
    region?: Maybe<AddressRegionCode>;
    /** Respiration lower-limit warning threshold. (level 1) */
    respirationLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Respiration lower-limit warning threshold. (level 2) */
    respirationLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Respiration upper-limit warning threshold. (level 1) */
    respirationUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Respiration upper-limit warning threshold. (level 2) */
    respirationUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Salutation. */
    salutation?: Maybe<Salutation>;
    /** Shortened name. */
    shortName: Scalars['String'];
    /** Spo2 lower-limit warning threshold. (level 1) */
    spo2LowerThreshold1?: Maybe<Scalars['Int']>;
    /** Spo2 lower-limit warning threshold. (level 2) */
    spo2LowerThreshold2?: Maybe<Scalars['Int']>;
    /** Spo2 upper-limit warning threshold. (level 1) */
    spo2UpperThreshold1?: Maybe<Scalars['Int']>;
    /** Spo2 upper-limit warning threshold. (level 2) */
    spo2UpperThreshold2?: Maybe<Scalars['Int']>;
    /** Address street number, name and type. */
    street?: Maybe<Scalars['String']>;
    /** Support coordinator for the client. */
    supportCoordinator?: Maybe<SupportCoordinator>;
    teams?: Maybe<Array<ClientTeam>>;
    /** Address unit number. */
    unit?: Maybe<Scalars['String']>;
    /** Datetime of the entity record's most recent update. */
    updatedAt?: Maybe<Scalars['DateTime']>;
  };

/** A client of an organisation. */
export type ClientEventsArgs = {
  input?: InputMaybe<QueryEventsInput>;
};

/** A client of an organisation. */
export type ClientMedicationsArgs = {
  input?: InputMaybe<QueryClientMedicationsInput>;
};

/** A client of an organisation. */
export type ClientTeamsArgs = {
  input?: InputMaybe<QueryClientTeamsInput>;
};

export type ClientFile = {
  __typename?: 'ClientFile';
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: Maybe<Scalars['Date']>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Expiry date of file. */
  expiresAt?: Maybe<Scalars['DateTime']>;
  file: File;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Owner of the file. */
  owner: Client;
  type: ClientFileType;
  value?: Maybe<Scalars['String']>;
};

/** Type of file belonging to a "Client" entity. */
export enum ClientFileType {
  AC_HANDBOOK = 'AC_HANDBOOK',
  CAREPLAN = 'CAREPLAN',
  CONFLICT_OF_INTEREST = 'CONFLICT_OF_INTEREST',
  CONSENT_TO_ACT = 'CONSENT_TO_ACT',
  CONSENT_TO_ACT_NDIS = 'CONSENT_TO_ACT_NDIS',
  CONSENT_TO_SHARE = 'CONSENT_TO_SHARE',
  CONSENT_TO_SHARE_NDIS = 'CONSENT_TO_SHARE_NDIS',
  CURRENT_MEDICATION = 'CURRENT_MEDICATION',
  FUNCTIONAL_ASSESMENT = 'FUNCTIONAL_ASSESMENT',
  GP = 'GP',
  INITIAL_CONSULT = 'INITIAL_CONSULT',
  LETTER_TO_PARTICIPANT_NDIS = 'LETTER_TO_PARTICIPANT_NDIS',
  MISC = 'MISC',
  NDIS_PLAN = 'NDIS_PLAN',
  NDIS_SERVICE_BOOKING = 'NDIS_SERVICE_BOOKING',
  OCCUPATIONAL_THERAPIST = 'OCCUPATIONAL_THERAPIST',
  OTHER_SPECIALISTS = 'OTHER_SPECIALISTS',
  PARTICIPANT_CHARTER = 'PARTICIPANT_CHARTER',
  PHYSIOTHERAPIST = 'PHYSIOTHERAPIST',
  PLAN_MANAGER = 'PLAN_MANAGER',
  PRIVACY_CONSENT = 'PRIVACY_CONSENT',
  PRIVACY_STATEMENT = 'PRIVACY_STATEMENT',
  PROGRESS_REPORT = 'PROGRESS_REPORT',
  PSYCHOLOGIST = 'PSYCHOLOGIST',
  REFERRALS = 'REFERRALS',
  RISK_ASSESSMENT = 'RISK_ASSESSMENT',
  SERVICE_AGREEMENT = 'SERVICE_AGREEMENT',
  TEST_RESULTS = 'TEST_RESULTS',
}

/** Aggregated response object for "ClientFile" entities. */
export type ClientFilesAggregate = {
  __typename?: 'ClientFilesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientFile>;
};

/** Ordering options for "ClientFile" entities. */
export type ClientFilesOrderByInput = {
  /** Order by date-of-file. */
  dateOfFile?: InputMaybe<OrderBy>;
  /** Order by expiry date. */
  expiresAt?: InputMaybe<OrderBy>;
  /** Order by file. */
  file?: InputMaybe<FilesOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by value. */
  value?: InputMaybe<OrderBy>;
};

/** Updatable fields for "ClientFile" entities. */
export type ClientFilesUpdatableFieldsInput = {
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Owner of the file. */
  owner?: InputMaybe<QueryByIdInput>;
  type?: InputMaybe<ClientFileType>;
  value?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "ClientFile" entities. */
export type ClientFilesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientFilesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientFilesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientFilesWhereInput>>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<DateTimeConditionalInput>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<DateTimeConditionalInput>;
  /** File. */
  file?: InputMaybe<FilesWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Owner. */
  owner?: InputMaybe<ClientsWhereInput>;
  /** File type. */
  type?: InputMaybe<IntConditionalInput>;
  /** Value. */
  value?: InputMaybe<StringConditionalInput>;
};

/** Associative entity joining Client and Medication entities. */
export type ClientMedication = {
  __typename?: 'ClientMedication';
  /** Linked Client entity. */
  client: Client;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Datetime of entity's deletion. */
  deletedAt: Scalars['DateTime'];
  /** Eraser of the entity. */
  deletedBy?: Maybe<Member>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked Medication entity. */
  medication: Medication;
};

/** Aggregated response object for "ClientMedication" entities. */
export type ClientMedicationsAggregate = {
  __typename?: 'ClientMedicationsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientMedication>;
};

/** Ordering options for "ClientMedication" entities. */
export type ClientMedicationsOrderByInput = {
  /** Order by linked client. */
  client?: InputMaybe<ClientsOrderByInput>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by linked medication. */
  medication?: InputMaybe<MedicationsOrderByInput>;
};

/** Filtering conditions for "ClientMedication" entities. */
export type ClientMedicationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientMedicationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientMedicationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientMedicationsWhereInput>>;
  /** Linked client entity. */
  client?: InputMaybe<ClientsWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Datetime of entitys deletion. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Eraser of the entity. */
  deletedBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked medication entity. */
  medication?: InputMaybe<MedicationsWhereInput>;
};

/** Recorded observation of a client. */
export type ClientObservation = {
  __typename?: 'ClientObservation';
  /** Diastolic blood pressure. */
  bloodDiastolic?: Maybe<Scalars['Int']>;
  /** Systolic blood pressure. */
  bloodSystolic?: Maybe<Scalars['Int']>;
  /** Owner of the data. */
  client: Client;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Date. */
  date?: Maybe<Scalars['DateTime']>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Event during which this observation was recorded. */
  event?: Maybe<Event>;
  givenFood?: Maybe<Array<ClientObservationFood>>;
  givenMedications?: Maybe<Array<ClientObservationMedication>>;
  /** Heart rate. */
  heart?: Maybe<Scalars['Int']>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Member linked with the observation. */
  member?: Maybe<Member>;
  /** Observational notes. */
  notes?: Maybe<Scalars['String']>;
  /** Nurse's name. */
  nursesName?: Maybe<Scalars['String']>;
  /** Respiration. */
  respiration?: Maybe<Scalars['Int']>;
  /** SpO2. */
  spo2?: Maybe<Scalars['Int']>;
  /** Bristol stool chart type. */
  stoolType?: Maybe<StoolType>;
  /** Temperature. */
  temp?: Maybe<Scalars['Float']>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Urine color. */
  urineColor?: Maybe<UrineColor>;
};

/** Recorded observation of a client. */
export type ClientObservationGivenFoodArgs = {
  input?: InputMaybe<QueryClientGivenFoodsInput>;
};

/** Recorded observation of a client. */
export type ClientObservationGivenMedicationsArgs = {
  input?: InputMaybe<QueryClientGivenMedicationsInput>;
};

/** Food given to a client. */
export type ClientObservationFood = {
  __typename?: 'ClientObservationFood';
  /** Description. */
  description?: Maybe<Scalars['String']>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Kilojoules. */
  kilojoules?: Maybe<Scalars['Int']>;
  /** Linked ClientObservation entity. */
  observation: ClientObservation;
};

/** Creatable fields for "ClientObservationFood" entities */
export type ClientObservationFoodCreatableFieldsInput = {
  /** Description. */
  description?: InputMaybe<Scalars['String']>;
  /** Kilojoules. */
  kilojoules?: InputMaybe<Scalars['Int']>;
};

/** Ordering options for "ClientObservationFood" entities. */
export type ClientObservationFoodsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by description. */
  description?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "ClientObservationFood" entities. */
export type ClientObservationFoodsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientObservationFoodsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientObservationFoodsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientObservationFoodsWhereInput>>;
  /** Description. */
  description?: InputMaybe<StringConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
};

/** Medications given to a client. */
export type ClientObservationMedication = {
  __typename?: 'ClientObservationMedication';
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked Medication entity. */
  medication: Medication;
  /** Linked ClientObservation entity. */
  observation: ClientObservation;
  /** Quantity. */
  quantity?: Maybe<Scalars['String']>;
};

/** Creatable fields for "ClientObservationMedication" entities */
export type ClientObservationMedicationCreatableFieldsInput = {
  /** The medication which was given. */
  medication: QueryByIdInput;
  /** Quantity. */
  quantity?: InputMaybe<Scalars['String']>;
};

/** Ordering options for "ClientObservationMedication" entities. */
export type ClientObservationMedicationsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by medication given. */
  medication?: InputMaybe<MedicationsOrderByInput>;
};

/** Filtering conditions for "ClientObservationMedication" entities. */
export type ClientObservationMedicationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientObservationMedicationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientObservationMedicationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientObservationMedicationsWhereInput>>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** The medication given. */
  medication?: InputMaybe<MedicationsWhereInput>;
};

/** Aggregated response object for "ClientObservation" entities. */
export type ClientObservationsAggregate = {
  __typename?: 'ClientObservationsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientObservation>;
};

/** Ordering options for "ClientObservation" entities. */
export type ClientObservationsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by date. */
  date?: InputMaybe<OrderBy>;
  /** Order by event. */
  event?: InputMaybe<EventsOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
};

/** Updatable fields for "ClientObservation" entities. */
export type ClientObservationsUpdatableFieldsInput = {
  /** Diastolic blood pressure. */
  bloodDiastolic?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure. */
  bloodSystolic?: InputMaybe<Scalars['Int']>;
  /** Date. */
  date?: InputMaybe<Scalars['DateTime']>;
  /** Food input data. */
  givenFood?: InputMaybe<CreateClientObservationFoodsInput>;
  /** Medication input data. */
  givenMedications?: InputMaybe<CreateClientObservationMedicationsInput>;
  /** Heart rate. */
  heart?: InputMaybe<Scalars['Int']>;
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
  /** Observational notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Nurse's name. */
  nursesName?: InputMaybe<Scalars['String']>;
  /** Respiration. */
  respiration?: InputMaybe<Scalars['Int']>;
  /** SpO2. */
  spo2?: InputMaybe<Scalars['Int']>;
  /** Bristol stool chart type. */
  stoolType?: InputMaybe<StoolType>;
  /** Temperature. */
  temp?: InputMaybe<Scalars['Float']>;
  /** Urine color. */
  urineColor?: InputMaybe<UrineColor>;
};

/** Filtering conditions for "ClientObservation" entities. */
export type ClientObservationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientObservationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientObservationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientObservationsWhereInput>>;
  /** Diastolic blood pressure. */
  bloodDiastolic?: InputMaybe<IntConditionalInput>;
  /** Systolic blood pressure. */
  bloodSystolic?: InputMaybe<IntConditionalInput>;
  /** Owner of the data. */
  client?: InputMaybe<ClientsWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Date. */
  date?: InputMaybe<DateTimeConditionalInput>;
  /** Event during which this observation was recorded. */
  event?: InputMaybe<EventsWhereInput>;
  /** Given medications. */
  givenMedications?: InputMaybe<ClientObservationMedicationsWhereInput>;
  /** Given food. */
  givenfood?: InputMaybe<ClientObservationFoodsWhereInput>;
  /** Heart rate. */
  heart?: InputMaybe<IntConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Observational notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Nurse's name. */
  nursesName?: InputMaybe<StringConditionalInput>;
  /** Respiration. */
  respiration?: InputMaybe<IntConditionalInput>;
  /** SpO2. */
  spo2?: InputMaybe<IntConditionalInput>;
  /** Stool type. */
  stoolType?: InputMaybe<IntConditionalInput>;
  /** Temperature. */
  temp?: InputMaybe<FloatConditionalInput>;
};

/** Associative entity joining Client and Member entities as a team. */
export type ClientTeam = {
  __typename?: 'ClientTeam';
  /** Linked "Client" entity. */
  client: Client;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Datetime of entity's deletion. */
  deletedAt: Scalars['DateTime'];
  /** Eraser of the entity. */
  deletedBy?: Maybe<Member>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked "Member" entity. */
  member: Member;
  /** Preferential-status of the member */
  preferred: Scalars['Boolean'];
};

/** Aggregated response object for "ClientTeam" entities. */
export type ClientTeamsAggregate = {
  __typename?: 'ClientTeamsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientTeam>;
};

/** Ordering options for "ClientTeam" entities. */
export type ClientTeamsOrderByInput = {
  /** Order by clients. */
  client?: InputMaybe<ClientsOrderByInput>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by datetime of deletion. */
  deletedAt?: InputMaybe<OrderBy>;
  /** Order by eraser. */
  deletedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by members. */
  member?: InputMaybe<MembersOrderByInput>;
  /** Order by preferential-status of the member. */
  preferred?: InputMaybe<OrderBy>;
};

/** Settable fields for "ClientTeam" entities. */
export type ClientTeamsSettableFieldsInput = {
  /** Member input data. */
  member: QueryByIdInput;
  /** Preferential-status of the member */
  preferred: Scalars['Boolean'];
};

/** Filtering conditions for "ClientTeam" entities. */
export type ClientTeamsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientTeamsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientTeamsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientTeamsWhereInput>>;
  /** Linked Client entity. */
  client?: InputMaybe<ClientsWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Datetime of entitys deletion. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Eraser of the entity. */
  deletedBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked Member entity. */
  member?: InputMaybe<MembersWhereInput>;
  /** Preferential-status of the member. */
  preferred?: InputMaybe<BooleanConditionalInput>;
};

/** Aggregated response object for "Client" entities. */
export type ClientsAggregate = {
  __typename?: 'ClientsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Client>;
};

/** Creatable fields for "Client" entities. */
export type ClientsCreatableFieldsInput = {
  /** Active status. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Allow automatic population of data for unattended future events. */
  allowAutomaticEventPopulation: Scalars['Boolean'];
  /** Allow recording of medication. */
  allowMedication: Scalars['Boolean'];
  /** Allow recording of observations. */
  allowObservations: Scalars['Boolean'];
  avatarData?: InputMaybe<Scalars['Upload']>;
  /** Diastolic blood pressure lower-limit warning threshold (level 1). */
  bloodDiastolicLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Diastolic blood pressure lower-limit warning threshold (level 2). */
  bloodDiastolicLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Diastolic blood pressure upper-limit warning threshold (level 1). */
  bloodDiastolicUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Diastolic blood pressure upper-limit warning threshold (level 2). */
  bloodDiastolicUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure lower-limit warning threshold (level 1). */
  bloodSystolicLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure lower-limit warning threshold (level 2). */
  bloodSystolicLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure upper-limit warning threshold (level 1). */
  bloodSystolicUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure upper-limit warning threshold (level 2). */
  bloodSystolicUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Branch input data. */
  branch: QueryByIdInput;
  /** Hexidecimal color. */
  color?: InputMaybe<Scalars['HexColorCode']>;
  /** Contacts Email address. */
  contactsEmail?: InputMaybe<Scalars['String']>;
  /** Contacts name. */
  contactsName?: InputMaybe<Scalars['String']>;
  /** Contacts Phone number. */
  contactsPhone?: InputMaybe<Scalars['String']>;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Default duration (in hours) to use when creating events. */
  defaultEventDuration?: InputMaybe<Scalars['Float']>;
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName: Scalars['String'];
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Heart rate lower-limit warning threshold (level 1). */
  heartLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Heart rate lower-limit warning threshold. (level 2) */
  heartLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Heart rate upper-limit warning threshold (level 1). */
  heartUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Heart rate upper-limit warning threshold (level 2). */
  heartUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** NDIS participant ID. */
  ndisId?: InputMaybe<Scalars['NdisId']>;
  /** End datetime of the NDIS plan. */
  ndisPlanEndDate?: InputMaybe<Scalars['Date']>;
  /** NDIS plan initial funds capacity building. */
  ndisPlanInitialFundsCb?: InputMaybe<Scalars['Int']>;
  /** NDIS plan initial funds core support. */
  ndisPlanInitialFundsCore?: InputMaybe<Scalars['Int']>;
  /** NDIS plan initial funds community service. */
  ndisPlanInitialFundsCs?: InputMaybe<Scalars['Int']>;
  /** Start date of the NDIS plan. */
  ndisPlanStartDate?: InputMaybe<Scalars['Date']>;
  /** Administrator-only notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** PlanManager input data. */
  planManager?: InputMaybe<QueryByIdInput>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Respiration lower-limit warning threshold. (level 1) */
  respirationLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Respiration lower-limit warning threshold. (level 2) */
  respirationLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Respiration upper-limit warning threshold. (level 1) */
  respirationUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Respiration upper-limit warning threshold. (level 2) */
  respirationUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** Spo2 lower-limit warning threshold. (level 1) */
  spo2LowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Spo2 lower-limit warning threshold. (level 2) */
  spo2LowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Spo2 upper-limit warning threshold. (level 1) */
  spo2UpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Spo2 upper-limit warning threshold. (level 2) */
  spo2UpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** SupportCoordinator input data. */
  supportCoordinator?: InputMaybe<QueryByIdInput>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
};

/** Ordering options for "Client" entities. */
export type ClientsOrderByInput = {
  /** Order by active status. */
  active?: InputMaybe<OrderBy>;
  /** Order by branch. */
  branch?: InputMaybe<BranchesOrderByInput>;
  /** Order by address country. */
  country?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by preferred name. */
  dob?: InputMaybe<OrderBy>;
  /** Order by events. */
  events?: InputMaybe<EventsOrderByInput>;
  /** Order by first name. */
  firstName?: InputMaybe<OrderBy>;
  /** Order by gender. */
  gender?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by last name. */
  lastName?: InputMaybe<OrderBy>;
  /** Order by address latitude. */
  latitude?: InputMaybe<OrderBy>;
  /** Order by address locality. */
  locality?: InputMaybe<OrderBy>;
  /** Order by address longitude. */
  longitude?: InputMaybe<OrderBy>;
  /** Order by middle name. */
  middleName?: InputMaybe<OrderBy>;
  /** Order by plan manager. */
  planManager?: InputMaybe<PlanManagersOrderByInput>;
  /** Order by address postcode. */
  postcode?: InputMaybe<OrderBy>;
  /** Order by preferred name. */
  preferredName?: InputMaybe<OrderBy>;
  /** Order by address region. */
  region?: InputMaybe<OrderBy>;
  /** Order by address street. */
  street?: InputMaybe<OrderBy>;
  /** Order by support coordinator. */
  supportCoordinator?: InputMaybe<SupportCoordinatorsOrderByInput>;
  /** Order by teams. */
  teams?: InputMaybe<ClientTeamsOrderByInput>;
  /** Order by address unit. */
  unit?: InputMaybe<OrderBy>;
};

/** Updatable fields for "Client" entities. */
export type ClientsUpdatableFieldsInput = {
  /** Active status. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Allow automatic population of data for unattended future events. */
  allowAutomaticEventPopulation?: InputMaybe<Scalars['Boolean']>;
  /** Allow recording of medication. */
  allowMedication?: InputMaybe<Scalars['Boolean']>;
  /** Allow recording of observations. */
  allowObservations?: InputMaybe<Scalars['Boolean']>;
  avatarData?: InputMaybe<Scalars['Upload']>;
  /** Diastolic blood pressure lower-limit warning threshold (level 1). */
  bloodDiastolicLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Diastolic blood pressure lower-limit warning threshold (level 2). */
  bloodDiastolicLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Diastolic blood pressure upper-limit warning threshold (level 1). */
  bloodDiastolicUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Diastolic blood pressure upper-limit warning threshold (level 2). */
  bloodDiastolicUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure lower-limit warning threshold (level 1). */
  bloodSystolicLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure lower-limit warning threshold (level 2). */
  bloodSystolicLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure upper-limit warning threshold (level 1). */
  bloodSystolicUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure upper-limit warning threshold (level 2). */
  bloodSystolicUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Branch input data. */
  branch?: InputMaybe<QueryByIdInput>;
  /** Hexidecimal color. */
  color?: InputMaybe<Scalars['HexColorCode']>;
  /** Contacts Email address. */
  contactsEmail?: InputMaybe<Scalars['String']>;
  /** Contacts name. */
  contactsName?: InputMaybe<Scalars['String']>;
  /** Contacts Phone number. */
  contactsPhone?: InputMaybe<Scalars['String']>;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Default duration (in hours) to use when creating events. */
  defaultEventDuration?: InputMaybe<Scalars['Float']>;
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Heart rate lower-limit warning threshold (level 1). */
  heartLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Heart rate lower-limit warning threshold. (level 2) */
  heartLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Heart rate upper-limit warning threshold (level 1). */
  heartUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Heart rate upper-limit warning threshold (level 2). */
  heartUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** NDIS participant ID. */
  ndisId?: InputMaybe<Scalars['NdisId']>;
  /** End datetime of the NDIS plan. */
  ndisPlanEndDate?: InputMaybe<Scalars['Date']>;
  /** NDIS plan initial funds capacity building. */
  ndisPlanInitialFundsCb?: InputMaybe<Scalars['Int']>;
  /** NDIS plan initial funds core support. */
  ndisPlanInitialFundsCore?: InputMaybe<Scalars['Int']>;
  /** NDIS plan initial funds community service. */
  ndisPlanInitialFundsCs?: InputMaybe<Scalars['Int']>;
  /** Start date of the NDIS plan. */
  ndisPlanStartDate?: InputMaybe<Scalars['Date']>;
  /** Administrator-only notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** PlanManager input data. */
  planManager?: InputMaybe<QueryByIdInput>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Respiration lower-limit warning threshold. (level 1) */
  respirationLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Respiration lower-limit warning threshold. (level 2) */
  respirationLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Respiration upper-limit warning threshold. (level 1) */
  respirationUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Respiration upper-limit warning threshold. (level 2) */
  respirationUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** Spo2 lower-limit warning threshold. (level 1) */
  spo2LowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Spo2 lower-limit warning threshold. (level 2) */
  spo2LowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Spo2 upper-limit warning threshold. (level 1) */
  spo2UpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Spo2 upper-limit warning threshold. (level 2) */
  spo2UpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** SupportCoordinator input data. */
  supportCoordinator?: InputMaybe<QueryByIdInput>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "Client" entities. */
export type ClientsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientsWhereInput>>;
  /** Allow recording of observations. */
  allowObservations?: InputMaybe<BooleanConditionalInput>;
  /** Branch. */
  branch?: InputMaybe<BranchesWhereInput>;
  /** Color. */
  color?: InputMaybe<StringConditionalInput>;
  /** Address country. */
  country?: InputMaybe<StringConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Default event duration. */
  defaultEventDuration?: InputMaybe<IntConditionalInput>;
  /** Date of birth. */
  dob?: InputMaybe<DateConditionalInput>;
  /** Email address. */
  email?: InputMaybe<StringConditionalInput>;
  /** Events for this client. */
  events?: InputMaybe<EventsWhereInput>;
  /** First name. */
  firstName?: InputMaybe<StringConditionalInput>;
  /** Gender. */
  gender?: InputMaybe<IntConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Last name. */
  lastName?: InputMaybe<StringConditionalInput>;
  /** Address latitude. */
  latitude?: InputMaybe<StringConditionalInput>;
  /** Address locality. */
  locality?: InputMaybe<StringConditionalInput>;
  /** Address longitude. */
  longitude?: InputMaybe<StringConditionalInput>;
  /** Middle name. */
  middleName?: InputMaybe<StringConditionalInput>;
  /** NDIS participant ID. */
  ndisId?: InputMaybe<NdisIdConditionalInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Phone number. */
  phone?: InputMaybe<PhoneConditionalInput>;
  /** Plan manager. */
  planManager?: InputMaybe<PlanManagersWhereInput>;
  /** Address postcode. */
  postcode?: InputMaybe<StringConditionalInput>;
  /** Preferred name. */
  preferredName?: InputMaybe<StringConditionalInput>;
  /** Pronouns. */
  pronouns?: InputMaybe<IntConditionalInput>;
  /** Address region. */
  region?: InputMaybe<StringConditionalInput>;
  /** Salutation. */
  salutation?: InputMaybe<IntConditionalInput>;
  /** Address street. */
  street?: InputMaybe<StringConditionalInput>;
  /** Support Coordinator. */
  supportCoordinator?: InputMaybe<SupportCoordinatorsWhereInput>;
  /** Teams. */
  teams?: InputMaybe<ClientTeamsWhereInput>;
  /** Address unit. */
  unit?: InputMaybe<StringConditionalInput>;
};

/** Input data for clocking on/off "Event" entities. */
export type ClockEventInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
};

/** Creation input data for "Branch" entities. */
export type CreateBranchesInput = {
  objects: Array<BranchesCreatableFieldsInput>;
};

/** Creation input data for singular "ClientFile" entities. */
export type CreateClientFileInput = {
  /** File upload buffer. */
  attachment: Scalars['Upload'];
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Owner of the file. */
  owner: QueryByIdInput;
  type: ClientFileType;
  value?: InputMaybe<Scalars['String']>;
};

/** Creation input data for "ClientObservationFood" entities. */
export type CreateClientObservationFoodsInput = {
  objects: Array<ClientObservationFoodCreatableFieldsInput>;
};

/** Creation input data for "ClientObservation" entities. */
export type CreateClientObservationInput = {
  /** Diastolic blood pressure. */
  bloodDiastolic?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure. */
  bloodSystolic?: InputMaybe<Scalars['Int']>;
  /** Client input data. */
  client: QueryByIdInput;
  /** Date. */
  date?: InputMaybe<Scalars['DateTime']>;
  /** Food input data. */
  givenFood?: InputMaybe<CreateClientObservationFoodsInput>;
  /** Medication input data. */
  givenMedications?: InputMaybe<CreateClientObservationMedicationsInput>;
  /** Heart rate. */
  heart?: InputMaybe<Scalars['Int']>;
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
  /** Observational notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Nurse's name. */
  nursesName?: InputMaybe<Scalars['String']>;
  /** Respiration. */
  respiration?: InputMaybe<Scalars['Int']>;
  /** SpO2. */
  spo2?: InputMaybe<Scalars['Int']>;
  /** Bristol stool chart type. */
  stoolType?: InputMaybe<StoolType>;
  /** Temperature. */
  temp?: InputMaybe<Scalars['Float']>;
  /** Urine color. */
  urineColor?: InputMaybe<UrineColor>;
};

/** Creation input data for "ClientObservationMedication" entities. */
export type CreateClientObservationMedicationsInput = {
  objects: Array<ClientObservationMedicationCreatableFieldsInput>;
};

/** Creation input data for "ClientObservation" entities with an Event relation. */
export type CreateClientObservationWithEventInput = {
  /** Diastolic blood pressure. */
  bloodDiastolic?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure. */
  bloodSystolic?: InputMaybe<Scalars['Int']>;
  /** Date. */
  date?: InputMaybe<Scalars['DateTime']>;
  /** Event input data. */
  event: QueryByIdInput;
  /** Food input data. */
  givenFood?: InputMaybe<CreateClientObservationFoodsInput>;
  /** Medication input data. */
  givenMedications?: InputMaybe<CreateClientObservationMedicationsInput>;
  /** Heart rate. */
  heart?: InputMaybe<Scalars['Int']>;
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
  /** Observational notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Nurse's name. */
  nursesName?: InputMaybe<Scalars['String']>;
  /** Respiration. */
  respiration?: InputMaybe<Scalars['Int']>;
  /** SpO2. */
  spo2?: InputMaybe<Scalars['Int']>;
  /** Bristol stool chart type. */
  stoolType?: InputMaybe<StoolType>;
  /** Temperature. */
  temp?: InputMaybe<Scalars['Float']>;
  /** Urine color. */
  urineColor?: InputMaybe<UrineColor>;
};

/** Creation input data for "Client" entities. */
export type CreateClientsInput = {
  objects: Array<ClientsCreatableFieldsInput>;
};

/** Creation input data for singular "EventFile" entities. */
export type CreateEventFileInput = {
  /** File upload buffer. */
  attachment: Scalars['Upload'];
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Owner of the file. */
  owner: QueryByIdInput;
  type: EventFileType;
  value?: InputMaybe<Scalars['String']>;
};

/** Creation input data for an "Event" entity. */
export type CreateEventInput = {
  /** Passive active-assist state. */
  activeAssist: Scalars['Boolean'];
  /** Client-billable status. */
  billable: Scalars['Boolean'];
  /** Client input data. */
  client: QueryByIdInput;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Duration of the event in minutes. */
  duration: Scalars['Int'];
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
  /** Detailed information concering the event. */
  notes?: InputMaybe<Scalars['String']>;
  /** Event is passive. */
  passive: Scalars['Boolean'];
  /** Start datetime of the passive period. Can not exceed 4 hours past event startAt. */
  passiveStartAt?: InputMaybe<Scalars['DateTime']>;
  /** Member-payable status. */
  payable: Scalars['Boolean'];
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Does event occurs on a public holiday? */
  publicHoliday?: InputMaybe<Scalars['Boolean']>;
  /** Publish status. */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Scheduling information concering the event. */
  scheduleNotes?: InputMaybe<Scalars['String']>;
  /** Start datetime of the event. */
  startAt: Scalars['DateTime'];
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** A short summary of the event. */
  summary?: InputMaybe<Scalars['String']>;
  /** Travel distance in meters. */
  travelDistance?: InputMaybe<Scalars['Int']>;
  travelDistanceNotes?: InputMaybe<Scalars['String']>;
  /** Travel time in minutes. */
  travelTime?: InputMaybe<Scalars['Int']>;
  travelTimeNotes?: InputMaybe<Scalars['String']>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
};

/** Creation input data for "Medication" entities. */
export type CreateMedicationInput = {
  /** Name of medication. */
  name: Scalars['String'];
};

/** Creation input data for singular "MemberFile" entities. */
export type CreateMemberFileInput = {
  /** File upload buffer. */
  attachment: Scalars['Upload'];
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Owner of the file. */
  owner: QueryByIdInput;
  type: MemberFileType;
  value?: InputMaybe<Scalars['String']>;
};

/** Creatable fields for "MemberUnavailable" entities */
export type CreateMemberUnavailableForMeInput = {
  /** End datetime of the unavailable period. */
  endAt: Scalars['DateTime'];
  /** Notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Start datetime of the unavailable period. */
  startAt: Scalars['DateTime'];
};

/** Creation input data for "MemberUnavailable" entities. */
export type CreateMemberUnavailablesInput = {
  objects: Array<MemberUnavailablesCreatableFieldsInput>;
};

/** Creation input data for "Member" entities. */
export type CreateMembersInput = {
  /** Immediately send invitations to all newly created members. */
  invite?: InputMaybe<Scalars['Boolean']>;
  objects: Array<MembersCreatableFieldsInput>;
};

/** Creation input data for singular "Org" entity. */
export type CreateOrgInput = {
  /** Australian Business Number of the organisation */
  abn?: InputMaybe<Scalars['ABN']>;
  /** Afternoon pay multiplier. */
  afternoonPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Number of weeks in the future where to start automatically assigning members. */
  assignMemberToEventWeeksInAdvance?: InputMaybe<Scalars['Int']>;
  /** Base pay rate. */
  basePayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 2. */
  bonus2PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 3. */
  bonus3PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 4. */
  bonus4PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 5. */
  bonus5PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 6. */
  bonus6PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 7. */
  bonus7PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 8. */
  bonus8PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 9. */
  bonus9PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 10. */
  bonus10PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 11. */
  bonus11PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 12. */
  bonus12PayRate?: InputMaybe<Scalars['Int']>;
  /** "Branch" entity input data. */
  branches: CreateBranchesInput;
  /** Warning threshold for early/late clock-off times. */
  clockOffWarnMinutes?: InputMaybe<Scalars['Int']>;
  /** Warning threshold for early/late clock-on times. */
  clockOnWarnMinutes?: InputMaybe<Scalars['Int']>;
  /** Email address */
  email?: InputMaybe<Scalars['EmailAddress']>;
  /** Kilometre allowance. */
  kmAllowance?: InputMaybe<Scalars['Int']>;
  /** Legal name */
  legalName: Scalars['String'];
  /** Members can see clocked times. */
  membersCanSeeClockedTimes?: InputMaybe<Scalars['Boolean']>;
  /** Night pay multiplier. */
  nightPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Passive-overnight allowance. */
  passiveAllowance?: InputMaybe<Scalars['Int']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred name */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Public holiday pay multiplier. */
  pubPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Quantity of shifts a member can see occuring immedaitely after thier own shift. */
  quantityOfFutureShiftsMembersCanSee?: InputMaybe<Scalars['Int']>;
  /** Quantity of shifts a member can see occuring immedaitely before thier own shift. */
  quantityOfPastShiftsMembersCanSee?: InputMaybe<Scalars['Int']>;
  /** Allow running the auto-assign-member task. */
  runAutoAssignMemberTask?: InputMaybe<Scalars['Boolean']>;
  /** Saturday pay multiplier. */
  satPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Sunday pay multiplier. */
  sunPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Days in advance to check for unfilled shifts. */
  unfilledEventsDayRange?: InputMaybe<Scalars['Int']>;
  /** Website of the organisation */
  website?: InputMaybe<Scalars['String']>;
};

/** Creation input data for "PlanManager" entities. */
export type CreatePlanManagersInput = {
  objects: Array<PlanManagersCreatableFieldsInput>;
};

/** Creation input data for "SupportCoordinator" entities. */
export type CreateSupportCoordinatorsInput = {
  objects: Array<SupportCoordinatorsCreatableFieldsInput>;
};

/** Creation input data for singular "User" entities. */
export type CreateUserInput = {
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Email address */
  email?: InputMaybe<Scalars['String']>;
  /** First name */
  firstName: Scalars['String'];
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** A unique identifier. Either an email address or phone number. */
  identifier: Scalars['UserIdentifier'];
  /** Last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** User's mailing list preferrence. */
  mailingList?: InputMaybe<Scalars['Boolean']>;
  /** Middle name */
  middleName?: InputMaybe<Scalars['String']>;
  /** Passwords must be at least 8 characters in length and contain a combination of lowercase, uppercase and special characters */
  password: Scalars['UserPlainTextPassword'];
  /** Phone number */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred name */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** User's chosen application theme. */
  theme?: InputMaybe<UserTheme>;
};

/** Creation input data for singular "VisaType" entities. */
export type CreateVisaTypeInput = {
  /** Allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: InputMaybe<Scalars['Int']>;
  /** Allowed worked hours is waived. */
  allowedWorkHoursWaived: Scalars['Boolean'];
  /** Description. */
  description?: InputMaybe<Scalars['String']>;
  /** Name of VISA. */
  name: Scalars['String'];
};

/** Date conditional expression input. */
export type DateConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['Date']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['Date']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['Date']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['Date']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['Date']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['Date']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['Date']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['Date']>>;
};

/** DateTime conditional expression input. */
export type DateTimeConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['DateTime']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['DateTime']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['DateTime']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['DateTime']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['DateTime']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['DateTime']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['DateTime']>>;
};

/** Input data for deleting entities. */
export type DeleteInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Reason for deletion. */
  reason?: InputMaybe<Scalars['String']>;
};

/** Drivers license. */
export enum DriversLicense {
  C = 'C',
  HC = 'HC',
  HR = 'HR',
  L = 'L',
  MC = 'MC',
  MR = 'MR',
  NO = 'NO',
  P1 = 'P1',
  P2 = 'P2',
  UNKNOWN = 'UNKNOWN',
  YES = 'YES',
}

export enum EntityState {
  ARCHIVED = 'ARCHIVED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
  NORMAL = 'NORMAL',
}

export type Event = Address & {
  __typename?: 'Event';
  /** Passive active-assist state. */
  activeAssist: Scalars['Boolean'];
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Client-billable status. */
  billable: Scalars['Boolean'];
  /** Cancellation data, if entity is cancelled. */
  cancel?: Maybe<HistoryRestorable>;
  /** Client linked with the event. */
  client: Client;
  /** Clock-off datetime. */
  clockedOffAt?: Maybe<Scalars['DateTime']>;
  /** Member who clocked-off. */
  clockedOffBy?: Maybe<Member>;
  /** Clocked-off latitude coordinate. */
  clockedOffLatitude?: Maybe<Scalars['String']>;
  /** Clocked-off longitude coordinate. */
  clockedOffLongitude?: Maybe<Scalars['String']>;
  /** Clock-on datetime. */
  clockedOnAt?: Maybe<Scalars['DateTime']>;
  /** Member who clocked-on. */
  clockedOnBy?: Maybe<Member>;
  /** Clocked-on latitude coordinate. */
  clockedOnLatitude?: Maybe<Scalars['String']>;
  /** Clocked-on longitude coordinate. */
  clockedOnLongitude?: Maybe<Scalars['String']>;
  conflictExists?: Maybe<Scalars['Boolean']>;
  /** Address country. */
  country?: Maybe<AddressCountryCode>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Duration of the event in minutes. */
  duration: Scalars['Int'];
  /** End datetime of the final instance of the event. */
  endAt: Scalars['DateTime'];
  hasFiles: Scalars['Boolean'];
  hasNotes: Scalars['Boolean'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Address latitude coordinate. */
  latitude?: Maybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: Maybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: Maybe<Scalars['String']>;
  /** Member linked with the event. */
  member?: Maybe<Member>;
  /** Member was assigned automatically by the server. */
  memberAssignedAutomatically: Scalars['Boolean'];
  /** Detailed information concering the event. */
  notes?: Maybe<Scalars['String']>;
  /** Event is passive. */
  passive: Scalars['Boolean'];
  /** Start datetime of the passive period. Can not exceed 4 hours past event startAt. */
  passiveStartAt?: Maybe<Scalars['DateTime']>;
  /** Member-payable status. */
  payable: Scalars['Boolean'];
  /** Address postcode. */
  postcode?: Maybe<Scalars['PostalCode']>;
  /** Does event occurs on a public holiday? */
  publicHoliday?: Maybe<Scalars['Boolean']>;
  /** Publish status. */
  published?: Maybe<Scalars['Boolean']>;
  /** Address state or territory. */
  region?: Maybe<AddressRegionCode>;
  /** Scheduling information concering the event. */
  scheduleNotes?: Maybe<Scalars['String']>;
  /** Start datetime of the event. */
  startAt: Scalars['DateTime'];
  /** Address street number, name and type. */
  street?: Maybe<Scalars['String']>;
  /** A short summary of the event. */
  summary?: Maybe<Scalars['String']>;
  /** Datetime of when travel data was approved. */
  travelApprovedAt?: Maybe<Scalars['DateTime']>;
  /** Member who approved the travel data. */
  travelApprovedBy?: Maybe<Member>;
  /** Travel distance in meters. */
  travelDistance?: Maybe<Scalars['Int']>;
  travelDistanceNotes?: Maybe<Scalars['String']>;
  /** Travel time in minutes. */
  travelTime?: Maybe<Scalars['Int']>;
  travelTimeNotes?: Maybe<Scalars['String']>;
  /** Address unit number. */
  unit?: Maybe<Scalars['String']>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EventFile = {
  __typename?: 'EventFile';
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: Maybe<Scalars['Date']>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  file: File;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Owner of the file. */
  owner: Event;
  type: EventFileType;
  value?: Maybe<Scalars['String']>;
};

/** Type of file belonging to an "Event" entity. */
export enum EventFileType {
  MISC = 'MISC',
  TRAVEL_RECEIPT = 'TRAVEL_RECEIPT',
}

/** Aggregated response object for "EventFile" entities. */
export type EventFilesAggregate = {
  __typename?: 'EventFilesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<EventFile>;
};

/** Ordering options for "EventFile" entities. */
export type EventFilesOrderByInput = {
  /** Order by date-of-file. */
  dateOfFile?: InputMaybe<OrderBy>;
  /** Order by file. */
  file?: InputMaybe<FilesOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by value. */
  value?: InputMaybe<OrderBy>;
};

/** Updatable fields for "EventFile" entities. */
export type EventFilesUpdatableFieldsInput = {
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Owner of the file. */
  owner?: InputMaybe<QueryByIdInput>;
  type?: InputMaybe<EventFileType>;
  value?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "EventFile" entities. */
export type EventFilesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<EventFilesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<EventFilesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<EventFilesWhereInput>>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<DateTimeConditionalInput>;
  /** File. */
  file?: InputMaybe<FilesWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Owner. */
  owner?: InputMaybe<EventsWhereInput>;
  /** File type. */
  type?: InputMaybe<StringConditionalInput>;
  /** Value. */
  value?: InputMaybe<StringConditionalInput>;
};

export type EventOwnAndRelated = Address & {
  __typename?: 'EventOwnAndRelated';
  /** Passive active-assist state. */
  activeAssist: Scalars['Boolean'];
  /** Cancellation data, if entity is cancelled. */
  cancel?: Maybe<HistoryRestorable>;
  /** Redacted client linked with the event. */
  client: RedactedClient;
  /** Clock-off datetime. */
  clockedOffAt?: Maybe<Scalars['DateTime']>;
  /** Clock-on datetime. */
  clockedOnAt?: Maybe<Scalars['DateTime']>;
  /** Address country. */
  country?: Maybe<AddressCountryCode>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Duration of the event in minutes. */
  duration: Scalars['Int'];
  /** End datetime of the final instance of the event. */
  endAt: Scalars['DateTime'];
  hasFiles: Scalars['Boolean'];
  hasNotes: Scalars['Boolean'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Address latitude coordinate. */
  latitude?: Maybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: Maybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: Maybe<Scalars['String']>;
  /** Redacted member linked with the event. */
  member?: Maybe<RedactedMember>;
  /** Member was assigned automatically by the server. */
  memberAssignedAutomatically: Scalars['Boolean'];
  /** Detailed information concering the event. */
  notes?: Maybe<Scalars['String']>;
  /** Event is passive. */
  passive: Scalars['Boolean'];
  /** Start datetime of the passive period. */
  passiveStartAt?: Maybe<Scalars['DateTime']>;
  /** Address postcode. */
  postcode?: Maybe<Scalars['PostalCode']>;
  /** Does event occurs on a public holiday? */
  publicHoliday: Scalars['Boolean'];
  /** Address state or territory. */
  region?: Maybe<AddressRegionCode>;
  /** Start datetime of the event. */
  startAt: Scalars['DateTime'];
  /** Address street number, name and type. */
  street?: Maybe<Scalars['String']>;
  /** A short summary of the event. */
  summary?: Maybe<Scalars['String']>;
  /** Travel distance in meters. */
  travelDistance: Scalars['Int'];
  travelDistanceNotes?: Maybe<Scalars['String']>;
  /** Travel time in minutes. */
  travelTime: Scalars['Int'];
  travelTimeNotes?: Maybe<Scalars['String']>;
  /** Address unit number. */
  unit?: Maybe<Scalars['String']>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Aggregated response object for "Event" entities. */
export type EventsAggregate = {
  __typename?: 'EventsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Event>;
};

/** Cancellation input data for "Event" entities. */
export type EventsCancelInput = {
  objects: Array<EventsCancellableFieldsInput>;
};

/** Input data for cancelling "Event" entities. */
export type EventsCancellableFieldsInput = {
  /** Client-billable status. */
  billable: Scalars['Boolean'];
  /** Entity ID. */
  id: Scalars['ID'];
  /** Member-payable status. */
  payable: Scalars['Boolean'];
  /** Reason for cancellation. */
  reason?: InputMaybe<Scalars['String']>;
};

/** Ordering options for "Event" entities. */
export type EventsOrderByInput = {
  /** Order by active-assist state. */
  activeAssist?: InputMaybe<OrderBy>;
  /** Order by billable status. */
  billable?: InputMaybe<OrderBy>;
  /** Order by client. */
  client?: InputMaybe<ClientsOrderByInput>;
  /** Order by clock-off datetime. */
  clockedOffAt?: InputMaybe<OrderBy>;
  /** Order by clock-on datetime. */
  clockedOnAt?: InputMaybe<OrderBy>;
  /** Order by address country. */
  country?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by duration. */
  duration?: InputMaybe<OrderBy>;
  /** Order by end datetime. */
  endAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by address latitude. */
  latitude?: InputMaybe<OrderBy>;
  /** Order by address locality. */
  locality?: InputMaybe<OrderBy>;
  /** Order by address longitude. */
  longitude?: InputMaybe<OrderBy>;
  /** Order by member. */
  member?: InputMaybe<MembersOrderByInput>;
  /** Order by notes. */
  notes?: InputMaybe<OrderBy>;
  /** Order by passive state. */
  passive?: InputMaybe<OrderBy>;
  /** Order by passive start datetime. */
  passiveStartAt?: InputMaybe<OrderBy>;
  /** Order by member payable status. */
  payable?: InputMaybe<OrderBy>;
  /** Order by address postcode. */
  postcode?: InputMaybe<OrderBy>;
  /** Order by public holiday status. */
  publicHoliday?: InputMaybe<OrderBy>;
  /** Order by published status. */
  published?: InputMaybe<OrderBy>;
  /** Order by address region. */
  region?: InputMaybe<OrderBy>;
  /** Order by start datetime. */
  startAt?: InputMaybe<OrderBy>;
  /** Order by address street. */
  street?: InputMaybe<OrderBy>;
  /** Order by summary. */
  summary?: InputMaybe<OrderBy>;
  /** Order by travel approved at datetime. */
  travelApproveAt?: InputMaybe<OrderBy>;
  /** Order by the member who approved the travel data. */
  travelApprovedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by travel distance. */
  travelDistance?: InputMaybe<OrderBy>;
  /** Order by travel distance notes. */
  travelDistanceNotes?: InputMaybe<OrderBy>;
  /** Order by travel set at datetime. */
  travelSetAt?: InputMaybe<OrderBy>;
  /** Order by the member who set the travel data. */
  travelSetBy?: InputMaybe<MembersOrderByInput>;
  /** Order by travel time. */
  travelTime?: InputMaybe<OrderBy>;
  /** Order by travel time notes. */
  travelTimeNotes?: InputMaybe<OrderBy>;
  /** Order by address unit. */
  unit?: InputMaybe<OrderBy>;
};

/** Ordering options for "EventOwnAndRelated" entities. */
export type EventsOwnAndRelatedOrderByInput = {
  /** Order by client. */
  client?: InputMaybe<ClientsOrderByInput>;
  /** Order by address country. */
  country?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by duration. */
  duration?: InputMaybe<OrderBy>;
  /** Order by end datetime. */
  endAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by address latitude. */
  latitude?: InputMaybe<OrderBy>;
  /** Order by address locality. */
  locality?: InputMaybe<OrderBy>;
  /** Order by address longitude. */
  longitude?: InputMaybe<OrderBy>;
  /** Order by member. */
  member?: InputMaybe<MembersOrderByInput>;
  /** Order by notes. */
  notes?: InputMaybe<OrderBy>;
  /** Order by passive state. */
  passive?: InputMaybe<OrderBy>;
  /** Order by address postcode. */
  postcode?: InputMaybe<OrderBy>;
  /** Order by public holiday status. */
  publicHoliday?: InputMaybe<OrderBy>;
  /** Order by address region. */
  region?: InputMaybe<OrderBy>;
  /** Order by start datetime. */
  startAt?: InputMaybe<OrderBy>;
  /** Order by address street. */
  street?: InputMaybe<OrderBy>;
  /** Order by summary. */
  summary?: InputMaybe<OrderBy>;
  /** Order by travel distance. */
  travelDistance?: InputMaybe<OrderBy>;
  /** Order by travel distance notes. */
  travelDistanceNotes?: InputMaybe<OrderBy>;
  /** Order by travel time. */
  travelTime?: InputMaybe<OrderBy>;
  /** Order by travel time notes. */
  travelTimeNotes?: InputMaybe<OrderBy>;
  /** Order by address unit. */
  unit?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "EventOwnAndRelated" entities. */
export type EventsOwnAndRelatedWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<EventsOwnAndRelatedWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<EventsOwnAndRelatedWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<EventsOwnAndRelatedWhereInput>>;
  /** Address country. */
  country?: InputMaybe<StringConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Duration in minutes. */
  duration?: InputMaybe<IntConditionalInput>;
  /** End datetime. */
  endAt?: InputMaybe<DateTimeConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Address latitude. */
  latitude?: InputMaybe<StringConditionalInput>;
  /** Address locality. */
  locality?: InputMaybe<StringConditionalInput>;
  /** Address longitude. */
  longitude?: InputMaybe<StringConditionalInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Passive-state. */
  passive?: InputMaybe<BooleanConditionalInput>;
  /** Address postcode. */
  postcode?: InputMaybe<StringConditionalInput>;
  /** Public-holiday-status. */
  publicHoliday?: InputMaybe<BooleanConditionalInput>;
  /** Address region. */
  region?: InputMaybe<StringConditionalInput>;
  /** Start datetime. */
  startAt?: InputMaybe<DateTimeConditionalInput>;
  /** Address street. */
  street?: InputMaybe<StringConditionalInput>;
  /** Summary. */
  summary?: InputMaybe<StringConditionalInput>;
  /** Travel distance. */
  travelDistance?: InputMaybe<IntConditionalInput>;
  /** Travel distance notes. */
  travelDistanceNotes?: InputMaybe<StringConditionalInput>;
  /** Travel time in minutes. */
  travelTime?: InputMaybe<IntConditionalInput>;
  /** Travel time notes. */
  travelTimeNotes?: InputMaybe<StringConditionalInput>;
  /** Address unit. */
  unit?: InputMaybe<StringConditionalInput>;
};

/** Updatable basic fields for "Event" entities. */
export type EventsUpdatableBasicFieldsInput = {
  /** Detailed information concering the event. */
  notes?: InputMaybe<Scalars['String']>;
  /** Travel distance in meters. */
  travelDistance?: InputMaybe<Scalars['Int']>;
  travelDistanceNotes?: InputMaybe<Scalars['String']>;
  /** Travel time in minutes. */
  travelTime?: InputMaybe<Scalars['Int']>;
  travelTimeNotes?: InputMaybe<Scalars['String']>;
};

/** Updatable fields for "Event" entities. */
export type EventsUpdatableFieldsInput = {
  /** Passive active-assist state. */
  activeAssist?: InputMaybe<Scalars['Boolean']>;
  /** Client-billable status. */
  billable?: InputMaybe<Scalars['Boolean']>;
  /** Client input data. */
  client?: InputMaybe<QueryByIdInput>;
  /** Clock-off datetime. */
  clockedOffAt?: InputMaybe<Scalars['DateTime']>;
  /** Clock-on datetime. */
  clockedOnAt?: InputMaybe<Scalars['DateTime']>;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Duration of the event in minutes. */
  duration?: InputMaybe<Scalars['Int']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
  /** Detailed information concering the event. */
  notes?: InputMaybe<Scalars['String']>;
  /** Event is passive. */
  passive?: InputMaybe<Scalars['Boolean']>;
  /** Start datetime of the passive period. Can not exceed 4 hours past event startAt. */
  passiveStartAt?: InputMaybe<Scalars['DateTime']>;
  /** Member-payable status. */
  payable?: InputMaybe<Scalars['Boolean']>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Does event occurs on a public holiday? */
  publicHoliday?: InputMaybe<Scalars['Boolean']>;
  /** Publish status. */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Scheduling information concering the event. */
  scheduleNotes?: InputMaybe<Scalars['String']>;
  /** Start datetime of the event. */
  startAt?: InputMaybe<Scalars['DateTime']>;
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** A short summary of the event. */
  summary?: InputMaybe<Scalars['String']>;
  /** Travel distance in meters. */
  travelDistance?: InputMaybe<Scalars['Int']>;
  travelDistanceNotes?: InputMaybe<Scalars['String']>;
  /** Travel time in minutes. */
  travelTime?: InputMaybe<Scalars['Int']>;
  travelTimeNotes?: InputMaybe<Scalars['String']>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "Event" entities. */
export type EventsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<EventsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<EventsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<EventsWhereInput>>;
  /** Active-state state. */
  activeAssist?: InputMaybe<BooleanConditionalInput>;
  /** Billable. */
  billable?: InputMaybe<BooleanConditionalInput>;
  /** Client. */
  client?: InputMaybe<ClientsWhereInput>;
  /** End datetime. */
  clockedOffAt?: InputMaybe<DateTimeConditionalInput>;
  /** Start datetime. */
  clockedOnAt?: InputMaybe<DateTimeConditionalInput>;
  /** Address country. */
  country?: InputMaybe<StringConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Duration in minutes. */
  duration?: InputMaybe<IntConditionalInput>;
  /** End datetime. */
  endAt?: InputMaybe<DateTimeConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Address latitude. */
  latitude?: InputMaybe<StringConditionalInput>;
  /** Address locality. */
  locality?: InputMaybe<StringConditionalInput>;
  /** Address longitude. */
  longitude?: InputMaybe<StringConditionalInput>;
  /** Attending member. */
  member?: InputMaybe<MembersWhereInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Passive state. */
  passive?: InputMaybe<BooleanConditionalInput>;
  /** Passive start datetime. */
  passiveStartAt?: InputMaybe<DateTimeConditionalInput>;
  /** Member payable status. */
  payable?: InputMaybe<BooleanConditionalInput>;
  /** Address postcode. */
  postcode?: InputMaybe<StringConditionalInput>;
  /** Public-holiday-status. */
  publicHoliday?: InputMaybe<BooleanConditionalInput>;
  /** Published. */
  published?: InputMaybe<BooleanConditionalInput>;
  /** Address region. */
  region?: InputMaybe<StringConditionalInput>;
  /** Start datetime. */
  startAt?: InputMaybe<DateTimeConditionalInput>;
  /** Address street. */
  street?: InputMaybe<StringConditionalInput>;
  /** Summary. */
  summary?: InputMaybe<StringConditionalInput>;
  /** Travel approved at datetime. */
  travelApprovedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Member who last approved the travel data. */
  travelApprovedBy?: InputMaybe<MembersWhereInput>;
  /** Travel distance. */
  travelDistance?: InputMaybe<IntConditionalInput>;
  /** Travel distance notes. */
  travelDistanceNotes?: InputMaybe<StringConditionalInput>;
  /** Travel set at datetime. */
  travelSetAt?: InputMaybe<DateTimeConditionalInput>;
  /** Member who last set the travel data. */
  travelSetBy?: InputMaybe<MembersWhereInput>;
  /** Travel time in minutes. */
  travelTime?: InputMaybe<IntConditionalInput>;
  /** Travel time notes. */
  travelTimeNotes?: InputMaybe<StringConditionalInput>;
  /** Address unit. */
  unit?: InputMaybe<StringConditionalInput>;
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime'];
  createdBy: Member;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Path to file. */
  path: Scalars['String'];
  /** Size in bytes. */
  size: Scalars['Int'];
};

/** Ordering options for "File" entities. */
export type FilesOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by file size. */
  size?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "File" entities. */
export type FilesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<FilesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<FilesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<FilesWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** File size. */
  size?: InputMaybe<IntConditionalInput>;
};

/** Float conditional expression input. */
export type FloatConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['Float']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['Float']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['Float']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['Float']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['Float']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['Float']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['Float']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

/** Gender */
export enum Gender {
  /** Female */
  FEMALE = 'FEMALE',
  /** Intersex */
  INTERSEX = 'INTERSEX',
  /** Male */
  MALE = 'MALE',
  /** Not specified */
  NOTSPECIFIED = 'NOTSPECIFIED',
}

/** Group entity. */
export type Group = {
  __typename?: 'Group';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Name. */
  name: Scalars['String'];
  /** Organisation to which group belongs. */
  org: Org;
  /** Permissions within the organisation. */
  permissions?: Maybe<Array<Permission>>;
};

/** Ordering options when selecting data from "History" sub-entities. */
export type HistoriesOrderByInput = {
  /** Order by actor. */
  actor?: InputMaybe<MembersOrderByInput>;
  /** Order by creation date. */
  date?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
};

/** Union of historical entity records. */
export type HistoriesUnion =
  | HistoryBranchName
  | HistoryClientAllowAutomaticEventPopulation
  | HistoryClientColor
  | HistoryClientCountry
  | HistoryClientDefaultEventDuration
  | HistoryClientDob
  | HistoryClientEmail
  | HistoryClientFileDateOfFile
  | HistoryClientFileExpiresAt
  | HistoryClientFileOwner
  | HistoryClientFileType
  | HistoryClientFileValue
  | HistoryClientFirstName
  | HistoryClientGender
  | HistoryClientLastName
  | HistoryClientLocality
  | HistoryClientMiddleName
  | HistoryClientNdisId
  | HistoryClientNdisPlanEndDate
  | HistoryClientNdisPlanInitialFundsCb
  | HistoryClientNdisPlanInitialFundsCore
  | HistoryClientNdisPlanInitialFundsCs
  | HistoryClientNdisPlanStartDate
  | HistoryClientNotes
  | HistoryClientObservationBloodDiastolic
  | HistoryClientObservationBloodSystolic
  | HistoryClientObservationDate
  | HistoryClientObservationHeart
  | HistoryClientObservationMember
  | HistoryClientObservationNotes
  | HistoryClientObservationNursesName
  | HistoryClientObservationRespiration
  | HistoryClientObservationSpo2
  | HistoryClientObservationStoolType
  | HistoryClientObservationTemp
  | HistoryClientPhone
  | HistoryClientPlanManager
  | HistoryClientPostcode
  | HistoryClientPreferredName
  | HistoryClientPronouns
  | HistoryClientRegion
  | HistoryClientSalutation
  | HistoryClientStreet
  | HistoryClientSupportCoordinator
  | HistoryClientUnit
  | HistoryEventActiveAssist
  | HistoryEventBillable
  | HistoryEventClient
  | HistoryEventClockedOffAt
  | HistoryEventClockedOnAt
  | HistoryEventCountry
  | HistoryEventDuration
  | HistoryEventLocality
  | HistoryEventMember
  | HistoryEventNotes
  | HistoryEventPassive
  | HistoryEventPassiveStartAt
  | HistoryEventPayable
  | HistoryEventPostcode
  | HistoryEventPublicHoliday
  | HistoryEventRegion
  | HistoryEventScheduleNotes
  | HistoryEventStartAt
  | HistoryEventStreet
  | HistoryEventSummary
  | HistoryEventTravelDistance
  | HistoryEventTravelDistanceNotes
  | HistoryEventTravelTime
  | HistoryEventTravelTimeNotes
  | HistoryEventUnit
  | HistoryMemberAdmin
  | HistoryMemberColor
  | HistoryMemberCountry
  | HistoryMemberDob
  | HistoryMemberEmail
  | HistoryMemberExternalId
  | HistoryMemberFileDateOfFile
  | HistoryMemberFileExpiresAt
  | HistoryMemberFileOwner
  | HistoryMemberFileType
  | HistoryMemberFileValue
  | HistoryMemberFirstName
  | HistoryMemberGender
  | HistoryMemberLastName
  | HistoryMemberLocality
  | HistoryMemberMiddleName
  | HistoryMemberNotes
  | HistoryMemberPhone
  | HistoryMemberPostcode
  | HistoryMemberPreferredName
  | HistoryMemberPronouns
  | HistoryMemberRegion
  | HistoryMemberSalutation
  | HistoryMemberSchedulable
  | HistoryMemberStreet
  | HistoryMemberUnit
  | HistoryOrgAbn
  | HistoryOrgEmail
  | HistoryOrgLegalName
  | HistoryOrgPhone
  | HistoryOrgPreferredName
  | HistoryOrgWebsite
  | HistoryPlanManagerAbn
  | HistoryPlanManagerBillingEmail
  | HistoryPlanManagerBusinessName
  | HistoryPlanManagerContactEmail
  | HistoryPlanManagerName
  | HistoryPlanManagerNotes
  | HistoryPlanManagerPhone
  | HistoryPlanManagerSalutation
  | HistoryRestorable
  | HistorySupportCoordinatorAbn
  | HistorySupportCoordinatorBusinessEmail
  | HistorySupportCoordinatorBusinessName
  | HistorySupportCoordinatorBusinessPhone
  | HistorySupportCoordinatorEmail
  | HistorySupportCoordinatorFirstName
  | HistorySupportCoordinatorLastName
  | HistorySupportCoordinatorMiddleName
  | HistorySupportCoordinatorNotes
  | HistorySupportCoordinatorPhone
  | HistorySupportCoordinatorPreferredName
  | HistorySupportCoordinatorSalutation
  | HistoryVisaTypeDescription
  | HistoryVisaTypeName;

/** OrgHistory query conditions. */
export type HistoriesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<HistoriesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<HistoriesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<HistoriesWhereInput>>;
  /** Creator of the entity. */
  actor?: InputMaybe<MembersWhereInput>;
  /** "Branch" entity to which this historical record belongs. */
  branch?: InputMaybe<BranchesWhereInput>;
  /** "Client" entity to which this historical record belongs. */
  client?: InputMaybe<ClientsWhereInput>;
  /** "ClientFile" entity to which this historical record belongs. */
  clientFile?: InputMaybe<ClientFilesWhereInput>;
  /** "ClientObservation" entity to which this historical record belongs. */
  clientObservation?: InputMaybe<ClientObservationsWhereInput>;
  /** Date of record creation. */
  date?: InputMaybe<DateTimeConditionalInput>;
  /** "Event" entity to which this historical record belongs. */
  event?: InputMaybe<EventsWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** "Member" entity to which this historical record belongs. */
  member?: InputMaybe<MembersWhereInput>;
  /** "MemberFile" entity to which this historical record belongs. */
  memberFile?: InputMaybe<MemberFilesWhereInput>;
  /** "Org" entity to which this historical record belongs. */
  org?: InputMaybe<OrgsWhereInput>;
  /** "PlanManager" entity to which this historical record belongs. */
  planManager?: InputMaybe<PlanManagersWhereInput>;
  /** "SupportCoordinator" entity to which this historical record belongs. */
  supportCoordinator?: InputMaybe<SupportCoordinatorsWhereInput>;
  /** Type of record. */
  type?: InputMaybe<IntConditionalInput>;
  /** "VisaType" entity to which this historical record belongs. */
  visaType?: InputMaybe<VisaTypesWhereInput>;
};

/** Historical "name" update record for "Branch" entities. */
export type HistoryBranchName = BaseHistory & {
  __typename?: 'HistoryBranchName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "allowAutomaticEventPopulation" update record for "Client" entities. */
export type HistoryClientAllowAutomaticEventPopulation = BaseHistory & {
  __typename?: 'HistoryClientAllowAutomaticEventPopulation';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  old?: Maybe<Scalars['Boolean']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "color" update record for "Client" entities. */
export type HistoryClientColor = BaseHistory & {
  __typename?: 'HistoryClientColor';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['HexColorCode']>;
  /** Old value. */
  old?: Maybe<Scalars['HexColorCode']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "country" update record for "Client" entities. */
export type HistoryClientCountry = BaseHistory & {
  __typename?: 'HistoryClientCountry';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<AddressCountryCode>;
  /** Old value. */
  old?: Maybe<AddressCountryCode>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "defaultEventDuration" update record for "Client" entities. */
export type HistoryClientDefaultEventDuration = BaseHistory & {
  __typename?: 'HistoryClientDefaultEventDuration';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "dob" update record for "Client" entities. */
export type HistoryClientDob = BaseHistory & {
  __typename?: 'HistoryClientDob';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "email" update record for "Client" entities. */
export type HistoryClientEmail = BaseHistory & {
  __typename?: 'HistoryClientEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['EmailAddress']>;
  /** Old value. */
  old?: Maybe<Scalars['EmailAddress']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "dateOfFile" update record for "ClientFile" entities. */
export type HistoryClientFileDateOfFile = BaseHistory & {
  __typename?: 'HistoryClientFileDateOfFile';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "expiresAt" update record for "ClientFile" entities. */
export type HistoryClientFileExpiresAt = BaseHistory & {
  __typename?: 'HistoryClientFileExpiresAt';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  old?: Maybe<Scalars['DateTime']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "owner" update record for "ClientFile" entities. */
export type HistoryClientFileOwner = BaseHistory & {
  __typename?: 'HistoryClientFileOwner';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Client>;
  /** Old value. */
  old?: Maybe<Client>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "type" update record for "ClientFile" entities. */
export type HistoryClientFileType = BaseHistory & {
  __typename?: 'HistoryClientFileType';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<ClientFileType>;
  /** Old value. */
  old?: Maybe<ClientFileType>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "value" update record for "ClientFile" entities. */
export type HistoryClientFileValue = BaseHistory & {
  __typename?: 'HistoryClientFileValue';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "firstName" update record for "Client" entities. */
export type HistoryClientFirstName = BaseHistory & {
  __typename?: 'HistoryClientFirstName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "gender" update record for "Client" entities. */
export type HistoryClientGender = BaseHistory & {
  __typename?: 'HistoryClientGender';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Gender>;
  /** Old value. */
  old?: Maybe<Gender>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "lastName" update record for "Client" entities. */
export type HistoryClientLastName = BaseHistory & {
  __typename?: 'HistoryClientLastName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "locality" update record for "Client" entities. */
export type HistoryClientLocality = BaseHistory & {
  __typename?: 'HistoryClientLocality';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "middleName" update record for "Client" entities. */
export type HistoryClientMiddleName = BaseHistory & {
  __typename?: 'HistoryClientMiddleName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "color" update record for "Client" entities. */
export type HistoryClientNdisId = BaseHistory & {
  __typename?: 'HistoryClientNdisId';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['NdisId']>;
  /** Old value. */
  old?: Maybe<Scalars['NdisId']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "ndisPlanEndDate" update record for "Client" entities. */
export type HistoryClientNdisPlanEndDate = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanEndDate';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "ndisPlanInitialFundsCb" update record for "Client" entities. */
export type HistoryClientNdisPlanInitialFundsCb = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanInitialFundsCb';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "ndisPlanInitialFundsCore" update record for "Client" entities. */
export type HistoryClientNdisPlanInitialFundsCore = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanInitialFundsCore';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "ndisPlanInitialFundsCs" update record for "Client" entities. */
export type HistoryClientNdisPlanInitialFundsCs = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanInitialFundsCs';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "ndisPlanStartDate" update record for "Client" entities. */
export type HistoryClientNdisPlanStartDate = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanStartDate';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "notes" update record for "Client" entities. */
export type HistoryClientNotes = BaseHistory & {
  __typename?: 'HistoryClientNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "bloodDiastolic" update record for "ClientObservation" entities. */
export type HistoryClientObservationBloodDiastolic = BaseHistory & {
  __typename?: 'HistoryClientObservationBloodDiastolic';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "bloodSystolic" update record for "ClientObservation" entities. */
export type HistoryClientObservationBloodSystolic = BaseHistory & {
  __typename?: 'HistoryClientObservationBloodSystolic';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "date" update record for "ClientObservation" entities. */
export type HistoryClientObservationDate = BaseHistory & {
  __typename?: 'HistoryClientObservationDate';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  old?: Maybe<Scalars['DateTime']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "heart" update record for "ClientObservation" entities. */
export type HistoryClientObservationHeart = BaseHistory & {
  __typename?: 'HistoryClientObservationHeart';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "member" update record for "ClientObservation" entities. */
export type HistoryClientObservationMember = BaseHistory & {
  __typename?: 'HistoryClientObservationMember';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Member>;
  /** Old value. */
  old?: Maybe<Member>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "notes" update record for "ClientObservation" entities. */
export type HistoryClientObservationNotes = BaseHistory & {
  __typename?: 'HistoryClientObservationNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "nursesName" update record for "ClientObservation" entities. */
export type HistoryClientObservationNursesName = BaseHistory & {
  __typename?: 'HistoryClientObservationNursesName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "respiration" update record for "ClientObservation" entities. */
export type HistoryClientObservationRespiration = BaseHistory & {
  __typename?: 'HistoryClientObservationRespiration';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "spo2" update record for "ClientObservation" entities. */
export type HistoryClientObservationSpo2 = BaseHistory & {
  __typename?: 'HistoryClientObservationSpo2';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "stoolType" update record for "ClientObservation" entities. */
export type HistoryClientObservationStoolType = BaseHistory & {
  __typename?: 'HistoryClientObservationStoolType';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<StoolType>;
  /** Old value. */
  old?: Maybe<StoolType>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "temp" update record for "ClientObservation" entities. */
export type HistoryClientObservationTemp = BaseHistory & {
  __typename?: 'HistoryClientObservationTemp';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Float']>;
  /** Old value. */
  old?: Maybe<Scalars['Float']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "phone" update record for "Client" entities. */
export type HistoryClientPhone = BaseHistory & {
  __typename?: 'HistoryClientPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "planManager" update record for "Client" entities. */
export type HistoryClientPlanManager = BaseHistory & {
  __typename?: 'HistoryClientPlanManager';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<PlanManager>;
  /** Old value. */
  old?: Maybe<PlanManager>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "postcode" update record for "Client" entities. */
export type HistoryClientPostcode = BaseHistory & {
  __typename?: 'HistoryClientPostcode';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "preferredName" update record for "Client" entities. */
export type HistoryClientPreferredName = BaseHistory & {
  __typename?: 'HistoryClientPreferredName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "pronouns" update record for "Client" entities. */
export type HistoryClientPronouns = BaseHistory & {
  __typename?: 'HistoryClientPronouns';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Pronouns>;
  /** Old value. */
  old?: Maybe<Pronouns>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "region" update record for "Client" entities. */
export type HistoryClientRegion = BaseHistory & {
  __typename?: 'HistoryClientRegion';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<AddressRegionCode>;
  /** Old value. */
  old?: Maybe<AddressRegionCode>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "salutation" update record for "Client" entities. */
export type HistoryClientSalutation = BaseHistory & {
  __typename?: 'HistoryClientSalutation';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Salutation>;
  /** Old value. */
  old?: Maybe<Salutation>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "street" update record for "Client" entities. */
export type HistoryClientStreet = BaseHistory & {
  __typename?: 'HistoryClientStreet';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "supportCoordinator" update record for "Client" entities. */
export type HistoryClientSupportCoordinator = BaseHistory & {
  __typename?: 'HistoryClientSupportCoordinator';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<SupportCoordinator>;
  /** Old value. */
  old?: Maybe<SupportCoordinator>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "unit" update record for "Client" entities. */
export type HistoryClientUnit = BaseHistory & {
  __typename?: 'HistoryClientUnit';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "activeAssist" update record for "Event" entities. */
export type HistoryEventActiveAssist = BaseHistory & {
  __typename?: 'HistoryEventActiveAssist';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventActiveAssistNew?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  eventActiveAssistOld?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "billable" update record for "Event" entities. */
export type HistoryEventBillable = BaseHistory & {
  __typename?: 'HistoryEventBillable';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** Old value. */
  eventBillableOld?: Maybe<Scalars['Boolean']>;
  /** New value. */
  eventBillablenew?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "client" update record for "Event" entities. */
export type HistoryEventClient = BaseHistory & {
  __typename?: 'HistoryEventClient';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventClientNew?: Maybe<Client>;
  /** Old value. */
  eventClientOld?: Maybe<Client>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "clockedOffAt" update record for "Event" entities. */
export type HistoryEventClockedOffAt = BaseHistory & {
  __typename?: 'HistoryEventClockedOffAt';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventClockedOffAtNew?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  eventClockedOffAtOld?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "clockedOnAt" update record for "Event" entities. */
export type HistoryEventClockedOnAt = BaseHistory & {
  __typename?: 'HistoryEventClockedOnAt';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventClockedOnAtNew?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  eventClockedOnAtOld?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "country" update record for "Event" entities. */
export type HistoryEventCountry = BaseHistory & {
  __typename?: 'HistoryEventCountry';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventCountryNew?: Maybe<AddressCountryCode>;
  /** Old value. */
  eventCountryOld?: Maybe<AddressCountryCode>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "duration" update record for "Event" entities. */
export type HistoryEventDuration = BaseHistory & {
  __typename?: 'HistoryEventDuration';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventDurationNew?: Maybe<Scalars['Int']>;
  /** Old value. */
  eventDurationOld?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "locality" update record for "Event" entities. */
export type HistoryEventLocality = BaseHistory & {
  __typename?: 'HistoryEventLocality';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventLocalityNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventLocalityOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "member" update record for "Event" entities. */
export type HistoryEventMember = BaseHistory & {
  __typename?: 'HistoryEventMember';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventMemberNew?: Maybe<Member>;
  /** Old value. */
  eventMemberOld?: Maybe<Member>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "notes" update record for "Event" entities. */
export type HistoryEventNotes = BaseHistory & {
  __typename?: 'HistoryEventNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventNotesNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventNotesOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "passive" update record for "Event" entities. */
export type HistoryEventPassive = BaseHistory & {
  __typename?: 'HistoryEventPassive';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventPassiveNew?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  eventPassiveOld?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "passiveStartAt" update record for "Event" entities. */
export type HistoryEventPassiveStartAt = BaseHistory & {
  __typename?: 'HistoryEventPassiveStartAt';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventPassiveStartAtNew?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  eventPassiveStartAtOld?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "payable" update record for "Event" entities. */
export type HistoryEventPayable = BaseHistory & {
  __typename?: 'HistoryEventPayable';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventPayableNew?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  eventPayableOld?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "postcode" update record for "Event" entities. */
export type HistoryEventPostcode = BaseHistory & {
  __typename?: 'HistoryEventPostcode';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventPostcodeNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventPostcodeOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "publicHoliday" update record for "Event" entities. */
export type HistoryEventPublicHoliday = BaseHistory & {
  __typename?: 'HistoryEventPublicHoliday';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventPublicHolidayNew?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  eventPublicHolidayOld?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "region" update record for "Event" entities. */
export type HistoryEventRegion = BaseHistory & {
  __typename?: 'HistoryEventRegion';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventRegionNew?: Maybe<AddressRegionCode>;
  /** Old value. */
  eventRegionOld?: Maybe<AddressRegionCode>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "scheduleNotes" update record for "Event" entities. */
export type HistoryEventScheduleNotes = BaseHistory & {
  __typename?: 'HistoryEventScheduleNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventScheduleNotesNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventSheduleNotesOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "startAt" update record for "Event" entities. */
export type HistoryEventStartAt = BaseHistory & {
  __typename?: 'HistoryEventStartAt';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventStartAtNew?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  eventStartAtOld?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "street" update record for "Event" entities. */
export type HistoryEventStreet = BaseHistory & {
  __typename?: 'HistoryEventStreet';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventStreetNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventStreetOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "summary" update record for "Event" entities. */
export type HistoryEventSummary = BaseHistory & {
  __typename?: 'HistoryEventSummary';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventSummaryNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventSummaryOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "travelDistance" update record for "Event" entities. */
export type HistoryEventTravelDistance = BaseHistory & {
  __typename?: 'HistoryEventTravelDistance';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventTravelDistanceNew?: Maybe<Scalars['Int']>;
  /** Old value. */
  eventTravelDistanceOld?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "travelDistanceNotes" update record for "Event" entities. */
export type HistoryEventTravelDistanceNotes = BaseHistory & {
  __typename?: 'HistoryEventTravelDistanceNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventTravelDistanceNotesNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventTravelDistanceNotesOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "travelTime" update record for "Event" entities. */
export type HistoryEventTravelTime = BaseHistory & {
  __typename?: 'HistoryEventTravelTime';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventTravelTimeNew?: Maybe<Scalars['Int']>;
  /** Old value. */
  eventTravelTimeOld?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "travelTimeNotes" update record for "Event" entities. */
export type HistoryEventTravelTimeNotes = BaseHistory & {
  __typename?: 'HistoryEventTravelTimeNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventTravelTimeNotesNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventTravelTimeNotesOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "unit" update record for "Event" entities. */
export type HistoryEventUnit = BaseHistory & {
  __typename?: 'HistoryEventUnit';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventUnitNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventUnitOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "admin" update record for "Member" entities. */
export type HistoryMemberAdmin = BaseHistory & {
  __typename?: 'HistoryMemberAdmin';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Entity to which the historical record belongs. */
  member: Member;
  /** New value. */
  memberAdminNew?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  memberAdminOld?: Maybe<Scalars['Boolean']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "color" update record for "Member" entities. */
export type HistoryMemberColor = BaseHistory & {
  __typename?: 'HistoryMemberColor';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['HexColorCode']>;
  /** Old value. */
  old?: Maybe<Scalars['HexColorCode']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "country" update record for "Member" entities. */
export type HistoryMemberCountry = BaseHistory & {
  __typename?: 'HistoryMemberCountry';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<AddressCountryCode>;
  /** Old value. */
  old?: Maybe<AddressCountryCode>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "dob" update record for "Member" entities. */
export type HistoryMemberDob = BaseHistory & {
  __typename?: 'HistoryMemberDob';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "email" update record for "Member" entities. */
export type HistoryMemberEmail = BaseHistory & {
  __typename?: 'HistoryMemberEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['EmailAddress']>;
  /** Old value. */
  old?: Maybe<Scalars['EmailAddress']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "externalId" update record for "Member" entities. */
export type HistoryMemberExternalId = BaseHistory & {
  __typename?: 'HistoryMemberExternalId';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "dateOfFile" update record for "MemberFile" entities. */
export type HistoryMemberFileDateOfFile = BaseHistory & {
  __typename?: 'HistoryMemberFileDateOfFile';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "expiresAt" update record for "MemberFile" entities. */
export type HistoryMemberFileExpiresAt = BaseHistory & {
  __typename?: 'HistoryMemberFileExpiresAt';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  old?: Maybe<Scalars['DateTime']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "owner" update record for "MemberFile" entities. */
export type HistoryMemberFileOwner = BaseHistory & {
  __typename?: 'HistoryMemberFileOwner';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Member>;
  /** Old value. */
  old?: Maybe<Member>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "type" update record for "MemberFile" entities. */
export type HistoryMemberFileType = BaseHistory & {
  __typename?: 'HistoryMemberFileType';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<MemberFileType>;
  /** Old value. */
  old?: Maybe<MemberFileType>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "value" update record for "MemberFile" entities. */
export type HistoryMemberFileValue = BaseHistory & {
  __typename?: 'HistoryMemberFileValue';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "firstName" update record for "Member" entities. */
export type HistoryMemberFirstName = BaseHistory & {
  __typename?: 'HistoryMemberFirstName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "gender" update record for "Member" entities. */
export type HistoryMemberGender = BaseHistory & {
  __typename?: 'HistoryMemberGender';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Gender>;
  /** Old value. */
  old?: Maybe<Gender>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "lastName" update record for "Member" entities. */
export type HistoryMemberLastName = BaseHistory & {
  __typename?: 'HistoryMemberLastName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "locality" update record for "Member" entities. */
export type HistoryMemberLocality = BaseHistory & {
  __typename?: 'HistoryMemberLocality';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "middleName" update record for "Member" entities. */
export type HistoryMemberMiddleName = BaseHistory & {
  __typename?: 'HistoryMemberMiddleName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "notes" update record for "Member" entities. */
export type HistoryMemberNotes = BaseHistory & {
  __typename?: 'HistoryMemberNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "phone" update record for "Member" entities. */
export type HistoryMemberPhone = BaseHistory & {
  __typename?: 'HistoryMemberPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "postcode" update record for "Member" entities. */
export type HistoryMemberPostcode = BaseHistory & {
  __typename?: 'HistoryMemberPostcode';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "preferredName" update record for "Member" entities. */
export type HistoryMemberPreferredName = BaseHistory & {
  __typename?: 'HistoryMemberPreferredName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "pronouns" update record for "Member" entities. */
export type HistoryMemberPronouns = BaseHistory & {
  __typename?: 'HistoryMemberPronouns';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Pronouns>;
  /** Old value. */
  old?: Maybe<Pronouns>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "region" update record for "Member" entities. */
export type HistoryMemberRegion = BaseHistory & {
  __typename?: 'HistoryMemberRegion';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<AddressRegionCode>;
  /** Old value. */
  old?: Maybe<AddressRegionCode>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "salutation" update record for "Member" entities. */
export type HistoryMemberSalutation = BaseHistory & {
  __typename?: 'HistoryMemberSalutation';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Salutation>;
  /** Old value. */
  old?: Maybe<Salutation>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "schedulable" update record for "Member" entities. */
export type HistoryMemberSchedulable = BaseHistory & {
  __typename?: 'HistoryMemberSchedulable';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  old?: Maybe<Scalars['Boolean']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "street" update record for "Member" entities. */
export type HistoryMemberStreet = BaseHistory & {
  __typename?: 'HistoryMemberStreet';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "unit" update record for "Member" entities. */
export type HistoryMemberUnit = BaseHistory & {
  __typename?: 'HistoryMemberUnit';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "abn" update record for "Org" entities. */
export type HistoryOrgAbn = BaseHistory & {
  __typename?: 'HistoryOrgAbn';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "email" update record for "Org" entities. */
export type HistoryOrgEmail = BaseHistory & {
  __typename?: 'HistoryOrgEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "legalName" update record for "Org" entities. */
export type HistoryOrgLegalName = BaseHistory & {
  __typename?: 'HistoryOrgLegalName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "phone" update record for "Org" entities. */
export type HistoryOrgPhone = BaseHistory & {
  __typename?: 'HistoryOrgPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "preferredName" update record for "Org" entities. */
export type HistoryOrgPreferredName = BaseHistory & {
  __typename?: 'HistoryOrgPreferredName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "website" update record for "Org" entities. */
export type HistoryOrgWebsite = BaseHistory & {
  __typename?: 'HistoryOrgWebsite';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "abn" update record for "PlanManager" entities. */
export type HistoryPlanManagerAbn = BaseHistory & {
  __typename?: 'HistoryPlanManagerAbn';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "billingEmail" update record for "PlanManager" entities. */
export type HistoryPlanManagerBillingEmail = BaseHistory & {
  __typename?: 'HistoryPlanManagerBillingEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "businessName" update record for "PlanManager" entities. */
export type HistoryPlanManagerBusinessName = BaseHistory & {
  __typename?: 'HistoryPlanManagerBusinessName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "contactEmail" update record for "PlanManager" entities. */
export type HistoryPlanManagerContactEmail = BaseHistory & {
  __typename?: 'HistoryPlanManagerContactEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "name" update record for "PlanManager" entities. */
export type HistoryPlanManagerName = BaseHistory & {
  __typename?: 'HistoryPlanManagerName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "notes" update record for "PlanManager" entities. */
export type HistoryPlanManagerNotes = BaseHistory & {
  __typename?: 'HistoryPlanManagerNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "phone" update record for "PlanManager" entities. */
export type HistoryPlanManagerPhone = BaseHistory & {
  __typename?: 'HistoryPlanManagerPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "salutation" update record for "PlanManager" entities. */
export type HistoryPlanManagerSalutation = BaseHistory & {
  __typename?: 'HistoryPlanManagerSalutation';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

export type HistoryRestorable = BaseHistory & {
  __typename?: 'HistoryRestorable';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  reason?: Maybe<Scalars['String']>;
  restoredAt?: Maybe<Scalars['DateTime']>;
  restoredBy?: Maybe<Member>;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "abn" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorAbn = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorAbn';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "businessEmail" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorBusinessEmail = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorBusinessEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "businessName" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorBusinessName = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorBusinessName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "businessPhone" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorBusinessPhone = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorBusinessPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "email" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorEmail = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "firstName" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorFirstName = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorFirstName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "lastName" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorLastName = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorLastName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "middleName" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorMiddleName = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorMiddleName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "notes" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorNotes = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "phone" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorPhone = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "preferredName" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorPreferredName = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorPreferredName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "salutation" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorSalutation = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorSalutation';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

export enum HistoryType {
  ARCHIVE = 'ARCHIVE',
  CANCELLATION = 'CANCELLATION',
  DELETION = 'DELETION',
  UPDATE = 'UPDATE',
}

/** Historical "description" update record for "VisaType" entities. */
export type HistoryVisaTypeDescription = BaseHistory & {
  __typename?: 'HistoryVisaTypeDescription';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "name" update record for "VisaType" entities. */
export type HistoryVisaTypeName = BaseHistory & {
  __typename?: 'HistoryVisaTypeName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** ID conditional expression input. */
export type IdConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['ID']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['ID']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['ID']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['ID']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['ID']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['ID']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['ID']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['ID']>>;
};

/** Int conditional expression input. */
export type IntConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['Int']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['Int']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['Int']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['Int']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['Int']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['Int']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LinkMemberInput = {
  /** Invitation token. */
  token: Scalars['UUID'];
};

/** User account login input data */
export type LoginUserInput = {
  /** A unique identifier. Either an email address or phone number. */
  identifier: Scalars['UserIdentifier'];
  /** Passwords must be at least 8 characters in length and contain a combination of lowercase, uppercase and special characters */
  password: Scalars['UserPlainTextPassword'];
  /** Keep session active for an extended period of time */
  rememberMe?: InputMaybe<Scalars['Boolean']>;
};

/** An medication linked to an organisation. */
export type Medication = {
  __typename?: 'Medication';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Name of medication. */
  name: Scalars['String'];
  /** Linked organisation. */
  org: Org;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Aggregated response object for "Medication" entities. */
export type MedicationsAggregate = {
  __typename?: 'MedicationsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Medication>;
};

/** Ordering options for "Medication" entities. */
export type MedicationsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by name of medication. */
  name?: InputMaybe<OrderBy>;
};

/** Updatable fields for "Medication" entities. */
export type MedicationsUpdatableFieldsInput = {
  /** Name of medication. */
  name?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "Medication" entities. */
export type MedicationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MedicationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MedicationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MedicationsWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked organisation. */
  org?: InputMaybe<OrgsWhereInput>;
};

/** A member profile linking a user with an organisation. */
export type Member = Address &
  Person & {
    __typename?: 'Member';
    /** Admin status. */
    admin: Scalars['Boolean'];
    /** Age in years. */
    age?: Maybe<Scalars['Int']>;
    /** Allowed worked hours per fortnight. */
    allowedFortnightlyWorkHours?: Maybe<Scalars['Int']>;
    /** Archive data, if entity is archived. */
    archive?: Maybe<HistoryRestorable>;
    avatar?: Maybe<File>;
    /** Base pay rate. */
    basePayRate?: Maybe<Scalars['Int']>;
    /** Eligible for pay bonuses. */
    bonusEligible: Scalars['Boolean'];
    branches?: Maybe<Array<Branch>>;
    /** Description of car. */
    carDescription?: Maybe<Scalars['String']>;
    /** Hexidecimal color. */
    color?: Maybe<Scalars['HexColorCode']>;
    /** Address country. */
    country?: Maybe<AddressCountryCode>;
    /** Datetime of the entity's creation. */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Creator of the entity. */
    createdBy?: Maybe<Member>;
    /** Deletion data, if entity is deleted. */
    delete?: Maybe<HistoryRestorable>;
    /** Date of birth. */
    dob?: Maybe<Scalars['Date']>;
    /** Drivers license. */
    driversLicense?: Maybe<DriversLicense>;
    /** Drivers license country of issue. */
    driversLicenseCountry?: Maybe<AddressCountryCode>;
    /** Drivers license expiry date. */
    driversLicenseExpiryDate?: Maybe<Scalars['Date']>;
    /** Email address. */
    email?: Maybe<Scalars['String']>;
    /** Employment end date */
    employmentEndDate?: Maybe<Scalars['Date']>;
    /** Employment start date */
    employmentStartDate?: Maybe<Scalars['Date']>;
    events?: Maybe<Array<Event>>;
    /** External reference ID. */
    externalId?: Maybe<Scalars['String']>;
    /** First name. */
    firstName: Scalars['String'];
    /** Gender. */
    gender?: Maybe<Gender>;
    /** Members ability to access their organisation. */
    hasAccess?: Maybe<Scalars['Boolean']>;
    /** Member has a car. */
    hasCar?: Maybe<Scalars['Boolean']>;
    /** Member has a drivers license. */
    hasDriversLicense?: Maybe<Scalars['Boolean']>;
    /** Unique identifier of the entity. */
    id: Scalars['ID'];
    /** Fetches the most recently created invitation. */
    invitationMostRecentlyReceived?: Maybe<MemberInvitation>;
    invitationsReceived?: Maybe<Array<MemberInvitation>>;
    invitationsSent?: Maybe<Array<MemberInvitationSender>>;
    lastActiveAt?: Maybe<Scalars['DateTime']>;
    /** Last name. */
    lastName?: Maybe<Scalars['String']>;
    /** Address latitude coordinate. */
    latitude?: Maybe<Scalars['String']>;
    /** Address suburb or town. */
    locality?: Maybe<Scalars['String']>;
    /** Address longitude coordinate. */
    longitude?: Maybe<Scalars['String']>;
    /** Middle name. */
    middleName?: Maybe<Scalars['String']>;
    /** Administrator-only notes. */
    notes?: Maybe<Scalars['String']>;
    /** Organisation to which member belongs. */
    org: Org;
    /** Permissions within the organisation. */
    permissions?: Maybe<Array<Permission>>;
    /** Phone number. */
    phone?: Maybe<Scalars['String']>;
    /** Address postcode. */
    postcode?: Maybe<Scalars['PostalCode']>;
    /** Preferred name. */
    preferredName?: Maybe<Scalars['String']>;
    /** Pronouns. */
    pronouns?: Maybe<Pronouns>;
    /** Address state or territory. */
    region?: Maybe<AddressRegionCode>;
    /** Salutation. */
    salutation?: Maybe<Salutation>;
    /** Member-schedulable status. */
    schedulable: Scalars['Boolean'];
    /** Resolver for "schedule" field. */
    schedule?: Maybe<MemberSchedule>;
    /** Shortened name. */
    shortName: Scalars['String'];
    /** Address street number, name and type. */
    street?: Maybe<Scalars['String']>;
    /** Field of study. */
    study?: Maybe<Scalars['String']>;
    teams?: Maybe<Array<ClientTeam>>;
    /** Member is typically unavailable on Friday daytime. */
    unavailableFrDay: Scalars['Boolean'];
    /** Member is typically unavailable on Friday morning. */
    unavailableFrMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Friday night. */
    unavailableFrNight: Scalars['Boolean'];
    /** Member is typically unavailable on Monday daytime. */
    unavailableMoDay: Scalars['Boolean'];
    /** Member is typically unavailable on Monday morning. */
    unavailableMoMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Monday night. */
    unavailableMoNight: Scalars['Boolean'];
    /** General unavailability notes. */
    unavailableNotes?: Maybe<Scalars['String']>;
    /** Member is typically unavailable on Saturday daytime. */
    unavailableSaDay: Scalars['Boolean'];
    /** Member is typically unavailable on Saturday morning. */
    unavailableSaMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Saturday night. */
    unavailableSaNight: Scalars['Boolean'];
    /** Member is typically unavailable on Saturday daytime. */
    unavailableSuDay: Scalars['Boolean'];
    /** Member is typically unavailable on Saturday morning. */
    unavailableSuMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Sunday night. */
    unavailableSuNight: Scalars['Boolean'];
    /** Member is typically unavailable on Thurday daytime. */
    unavailableThDay: Scalars['Boolean'];
    /** Member is typically unavailable on Thurday morning. */
    unavailableThMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Thurday night. */
    unavailableThNight: Scalars['Boolean'];
    /** Member is typically unavailable on Tuesday daytime. */
    unavailableTuDay: Scalars['Boolean'];
    /** Member is typically unavailable on Tuesday morning. */
    unavailableTuMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Tuesday night. */
    unavailableTuNight: Scalars['Boolean'];
    /** Member is typically unavailable on Wednesday daytime. */
    unavailableWeDay: Scalars['Boolean'];
    /** Member is typically unavailable on Wednesday morning. */
    unavailableWeMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Wednesday night. */
    unavailableWeNight: Scalars['Boolean'];
    unavailables?: Maybe<Array<MemberUnavailable>>;
    /** Address unit number. */
    unit?: Maybe<Scalars['String']>;
    /** Datetime of the entity record's most recent update. */
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** Type of VISA. */
    visaType?: Maybe<VisaType>;
    /** Work rights. */
    workRights?: Maybe<MemberWorkRights>;
    /** Work rights last check date. */
    workRightsLastCheckedAt?: Maybe<Scalars['Date']>;
  };

/** A member profile linking a user with an organisation. */
export type MemberBranchesArgs = {
  input?: InputMaybe<QueryBranchesInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberEventsArgs = {
  input?: InputMaybe<QueryEventsInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberInvitationsReceivedArgs = {
  input?: InputMaybe<QueryMemberInvitationsInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberInvitationsSentArgs = {
  input?: InputMaybe<QueryMemberInvitationSendersInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberScheduleArgs = {
  input: QueryMemberScheduleInput;
};

/** A member profile linking a user with an organisation. */
export type MemberTeamsArgs = {
  input?: InputMaybe<QueryClientTeamsInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberUnavailablesArgs = {
  input?: InputMaybe<QueryMemberUnavailablesInput>;
};

export type MemberFile = {
  __typename?: 'MemberFile';
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: Maybe<Scalars['Date']>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Expiry date of file. */
  expiresAt?: Maybe<Scalars['DateTime']>;
  file: File;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Owner of the file. */
  owner: Member;
  type: MemberFileType;
  value?: Maybe<Scalars['String']>;
};

/** Type of file belonging to a "Member" entity. */
export enum MemberFileType {
  ASSISTING_WITH_SELF_MEDICATION = 'ASSISTING_WITH_SELF_MEDICATION',
  BANK_DETAILS = 'BANK_DETAILS',
  BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
  CAR_INSURANCE = 'CAR_INSURANCE',
  CAR_REGISTRATION = 'CAR_REGISTRATION',
  CONFIRMATION_OF_ENROLLMENT = 'CONFIRMATION_OF_ENROLLMENT',
  COVID_19 = 'COVID_19',
  COVID_19_TRAVEL_EXEMPTION = 'COVID_19_TRAVEL_EXEMPTION',
  COVID_TEST_RESULTS = 'COVID_TEST_RESULTS',
  CPR = 'CPR',
  CTP_INSURANCE = 'CTP_INSURANCE',
  DRIVERS_LICENCE = 'DRIVERS_LICENCE',
  EMPLOYMENT_CONTRACT = 'EMPLOYMENT_CONTRACT',
  FIRST_AID = 'FIRST_AID',
  INDIVIDUAL_SUPPORT = 'INDIVIDUAL_SUPPORT',
  MANUAL_HANDLING = 'MANUAL_HANDLING',
  MEDICARE_CARD = 'MEDICARE_CARD',
  MISC = 'MISC',
  NDIS_MODULE = 'NDIS_MODULE',
  NEW_NDIS_CLEARANCE = 'NEW_NDIS_CLEARANCE',
  NON_DISCLOSURE_AGREEMENT = 'NON_DISCLOSURE_AGREEMENT',
  PASSPORT = 'PASSPORT',
  PHOTO_ID_CARD = 'PHOTO_ID_CARD',
  POLICE_CHECK = 'POLICE_CHECK',
  PRIVACY_AND_CONFIDENTIAILITY_DECLARATION = 'PRIVACY_AND_CONFIDENTIAILITY_DECLARATION',
  PROOF_OF_COVID_VACCINATION = 'PROOF_OF_COVID_VACCINATION',
  PROOF_OF_OVERSEAS_HEALTH_COVER = 'PROOF_OF_OVERSEAS_HEALTH_COVER',
  RESUME = 'RESUME',
  STAFF_APPLICATION = 'STAFF_APPLICATION',
  STAFF_CARD_PHOTO_ID = 'STAFF_CARD_PHOTO_ID',
  SUPERANNUATION = 'SUPERANNUATION',
  TAX_FILE_DECLARATION = 'TAX_FILE_DECLARATION',
  TAX_FILE_NUMBER = 'TAX_FILE_NUMBER',
  VACCINATION_RECORD = 'VACCINATION_RECORD',
  VEVO = 'VEVO',
  VISA = 'VISA',
  WORKING_WITH_CHILDREN_CHECK = 'WORKING_WITH_CHILDREN_CHECK',
  WORK_RIGHTS = 'WORK_RIGHTS',
}

/** Aggregated response object for "MemberFile" entities. */
export type MemberFilesAggregate = {
  __typename?: 'MemberFilesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<MemberFile>;
};

/** Ordering options for "MemberFile" entities. */
export type MemberFilesOrderByInput = {
  /** Order by date-of-file. */
  dateOfFile?: InputMaybe<OrderBy>;
  /** Order by expiry date. */
  expiresAt?: InputMaybe<OrderBy>;
  /** Order by file. */
  file?: InputMaybe<FilesOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by value. */
  value?: InputMaybe<OrderBy>;
};

/** Updatable fields for "MemberFile" entities. */
export type MemberFilesUpdatableFieldsInput = {
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Owner of the file. */
  owner?: InputMaybe<QueryByIdInput>;
  type?: InputMaybe<MemberFileType>;
  value?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "MemberFile" entities. */
export type MemberFilesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberFilesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberFilesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberFilesWhereInput>>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<DateTimeConditionalInput>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<DateTimeConditionalInput>;
  /** File. */
  file?: InputMaybe<FilesWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Owner. */
  owner?: InputMaybe<MembersWhereInput>;
  /** File type. */
  type?: InputMaybe<StringConditionalInput>;
  /** Value. */
  value?: InputMaybe<StringConditionalInput>;
};

/** An invitation to be sent to a user to grant them access to an organisation. */
export type MemberInvitation = {
  __typename?: 'MemberInvitation';
  /** Datetime of the entity's creation. */
  createdAt: Scalars['DateTime'];
  /** Creator of the entity. */
  createdBy: Member;
  /** Datetime of the entity's deletion. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Actor who performed the deletion. */
  deletedBy?: Maybe<Member>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Member whom the invitation was created for. */
  invitee: Member;
  lastSender: MemberInvitationSender;
  /** The number of times the invitation has been sent. */
  sentCount: Scalars['Int'];
  /** Date token was successfully used. */
  usedAt?: Maybe<Scalars['DateTime']>;
};

/** A record of a sent member-invitation. */
export type MemberInvitationSender = {
  __typename?: 'MemberInvitationSender';
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** The invitation that was sent. */
  invitation: MemberInvitation;
  /** Date invitation was sent. */
  sentAt: Scalars['DateTime'];
  /** The sender of the invitation. */
  sentBy: Member;
};

/** Ordering options for "MemberInvitationSender" entities. */
export type MemberInvitationSendersOrderByInput = {
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by invitation. */
  invitation?: InputMaybe<MemberInvitationsOrderByInput>;
  /** Order by the datetime of when the invitation was sent. */
  sentAt?: InputMaybe<OrderBy>;
  /** Order by sender. */
  sentBy?: InputMaybe<MembersOrderByInput>;
};

/** Filtering conditions for "MemberInvitationSender" entities. */
export type MemberInvitationSendersWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberInvitationSendersWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberInvitationSendersWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberInvitationSendersWhereInput>>;
  /** Invitation that was sent. */
  invitation?: InputMaybe<MemberInvitationsWhereInput>;
  /** Datetime when invitation was sent. */
  sentAt?: InputMaybe<DateTimeConditionalInput>;
  /** Sender of the invitation. */
  sentBy?: InputMaybe<MembersWhereInput>;
};

/** Ordering options for "MemberInvitation" entities. */
export type MemberInvitationsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by datetime of deletion. */
  deletedAt?: InputMaybe<OrderBy>;
  /** Order by eraser. */
  deletedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by invitee. */
  invitee?: InputMaybe<MembersOrderByInput>;
  /** Order by senders. */
  senders?: InputMaybe<MemberInvitationSendersOrderByInput>;
  /** Order by datetime of successful use. */
  usedAt?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "MemberInvitation" entities. */
export type MemberInvitationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberInvitationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberInvitationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberInvitationsWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Datetime of entitys deletion. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Eraser of the entity. */
  deletedBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Member whom the invitation was created for. */
  invitee?: InputMaybe<MembersWhereInput>;
  /** Senders of the invitation. */
  senders?: InputMaybe<MemberInvitationSendersWhereInput>;
  /** Datetime of successful use. */
  usedAt?: InputMaybe<DateTimeConditionalInput>;
};

/** A schedule for a member. */
export type MemberSchedule = {
  __typename?: 'MemberSchedule';
  /** Current shift. */
  currentShift?: Maybe<Event>;
  /** Next shift. */
  nextShift?: Maybe<Event>;
  /** Time scheduled within specified period. */
  timeScheduled: Scalars['Int'];
  /** Time worked within specified period. */
  timeWorked: Scalars['Int'];
};

/** A period of time during which an employee is unavailable. */
export type MemberUnavailable = {
  __typename?: 'MemberUnavailable';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Date of deletion. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Actor who performed the deletion. */
  deletedBy?: Maybe<Member>;
  /** End datetime of the unavailable period. */
  endAt: Scalars['DateTime'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Notes. */
  notes?: Maybe<Scalars['String']>;
  /** Member to which this entity is linked. */
  owner: Member;
  /** Start datetime of the unavailable period. */
  startAt: Scalars['DateTime'];
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Aggregated response object for "MemberUnavailable" entities. */
export type MemberUnavailablesAggregate = {
  __typename?: 'MemberUnavailablesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<MemberUnavailable>;
};

/** Creatable fields for "MemberUnavailable" entities */
export type MemberUnavailablesCreatableFieldsInput = {
  /** End datetime of the unavailable period. */
  endAt: Scalars['DateTime'];
  /** Notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Owner. */
  owner?: InputMaybe<QueryByIdInput>;
  /** Start datetime of the unavailable period. */
  startAt: Scalars['DateTime'];
};

/** Ordering options for "MemberUnavailable" entities. */
export type MemberUnavailablesOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by deletion date. */
  deletedAt?: InputMaybe<OrderBy>;
  /** Order by eraser. */
  deletedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by end date. */
  endAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by notes. */
  notes?: InputMaybe<OrderBy>;
  /** Order by owner. */
  owner?: InputMaybe<MembersOrderByInput>;
  /** Order by start date. */
  startAt?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "MemberUnavailable" entities. */
export type MemberUnavailablesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberUnavailablesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberUnavailablesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberUnavailablesWhereInput>>;
  /** Deletion date. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** End date. */
  endAt?: InputMaybe<DateTimeConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Owner. */
  owner?: InputMaybe<MembersWhereInput>;
  /** Start date. */
  startAt?: InputMaybe<DateTimeConditionalInput>;
};

/** Work rights of a "Member" entity. */
export enum MemberWorkRights {
  NIL = 'NIL',
  RESTRICTED = 'RESTRICTED',
  UNRESTRICTED = 'UNRESTRICTED',
}

/** Aggregated response object for "Member" entities. */
export type MembersAggregate = {
  __typename?: 'MembersAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Member>;
};

/** Creatable fields for "Member" entities. */
export type MembersCreatableFieldsInput = {
  /** Allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: InputMaybe<Scalars['Int']>;
  /** Avatar image upload. */
  avatarData?: InputMaybe<Scalars['Upload']>;
  /** Base pay rate. */
  basePayRate?: InputMaybe<Scalars['Int']>;
  /** Eligible for pay bonuses. */
  bonusEligible: Scalars['Boolean'];
  /** Branches input data. */
  branches?: InputMaybe<QueryByIdsNullableInput>;
  /** Description of car. */
  carDescription?: InputMaybe<Scalars['String']>;
  /** Hexidecimal color. */
  color?: InputMaybe<Scalars['HexColorCode']>;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Drivers license. */
  driversLicense?: InputMaybe<DriversLicense>;
  /** Drivers license country of issue. */
  driversLicenseCountry?: InputMaybe<AddressCountryCode>;
  /** Drivers license expiry date. */
  driversLicenseExpiryDate?: InputMaybe<Scalars['Date']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** Employment end date */
  employmentEndDate?: InputMaybe<Scalars['Date']>;
  /** Employment start date */
  employmentStartDate?: InputMaybe<Scalars['Date']>;
  /** External reference ID. */
  externalId?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName: Scalars['String'];
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Member has a car. */
  hasCar?: InputMaybe<Scalars['Boolean']>;
  /** Member has a drivers license. */
  hasDriversLicense?: InputMaybe<Scalars['Boolean']>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Administrator-only notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** Member-schedulable status. */
  schedulable: Scalars['Boolean'];
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** Field of study. */
  study?: InputMaybe<Scalars['String']>;
  /** Member is typically unavailable on Friday daytime. */
  unavailableFrDay: Scalars['Boolean'];
  /** Member is typically unavailable on Friday morning. */
  unavailableFrMorn: Scalars['Boolean'];
  /** Member is typically unavailable on Friday night. */
  unavailableFrNight: Scalars['Boolean'];
  /** Member is typically unavailable on Monday daytime. */
  unavailableMoDay: Scalars['Boolean'];
  /** Member is typically unavailable on Monday morning. */
  unavailableMoMorn: Scalars['Boolean'];
  /** Member is typically unavailable on Monday night. */
  unavailableMoNight: Scalars['Boolean'];
  /** General unavailability notes. */
  unavailableNotes?: InputMaybe<Scalars['String']>;
  /** Member is typically unavailable on Saturday daytime. */
  unavailableSaDay: Scalars['Boolean'];
  /** Member is typically unavailable on Saturday morning. */
  unavailableSaMorn: Scalars['Boolean'];
  /** Member is typically unavailable on Saturday night. */
  unavailableSaNight: Scalars['Boolean'];
  /** Member is typically unavailable on Saturday daytime. */
  unavailableSuDay: Scalars['Boolean'];
  /** Member is typically unavailable on Saturday morning. */
  unavailableSuMorn: Scalars['Boolean'];
  /** Member is typically unavailable on Sunday night. */
  unavailableSuNight: Scalars['Boolean'];
  /** Member is typically unavailable on Thurday daytime. */
  unavailableThDay: Scalars['Boolean'];
  /** Member is typically unavailable on Thurday morning. */
  unavailableThMorn: Scalars['Boolean'];
  /** Member is typically unavailable on Thurday night. */
  unavailableThNight: Scalars['Boolean'];
  /** Member is typically unavailable on Tuesday daytime. */
  unavailableTuDay: Scalars['Boolean'];
  /** Member is typically unavailable on Tuesday morning. */
  unavailableTuMorn: Scalars['Boolean'];
  /** Member is typically unavailable on Tuesday night. */
  unavailableTuNight: Scalars['Boolean'];
  /** Member is typically unavailable on Wednesday daytime. */
  unavailableWeDay: Scalars['Boolean'];
  /** Member is typically unavailable on Wednesday morning. */
  unavailableWeMorn: Scalars['Boolean'];
  /** Member is typically unavailable on Wednesday night. */
  unavailableWeNight: Scalars['Boolean'];
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
  /** Visa Type input data. */
  visaType?: InputMaybe<QueryByIdInput>;
  /** Work rights. */
  workRights?: InputMaybe<MemberWorkRights>;
  /** Work rights last check date. */
  workRightsLastCheckedAt?: InputMaybe<Scalars['Date']>;
};

/** Ordering options for "Member" entities. */
export type MembersOrderByInput = {
  /** Order by allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: InputMaybe<OrderBy>;
  /** Order by address country. */
  country?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by date of birth. */
  dob?: InputMaybe<OrderBy>;
  /** Order by drivers license country of issue. */
  driversLicenseCountry?: InputMaybe<OrderBy>;
  /** Order by drivers license expiry date. */
  driversLicenseExpiryDate?: InputMaybe<OrderBy>;
  /** Order by attending events. */
  events?: InputMaybe<EventsOrderByInput>;
  /** Order by external reference ID. */
  externalId?: InputMaybe<OrderBy>;
  /** Order by first name. */
  firstName?: InputMaybe<OrderBy>;
  /** Order by having a car. */
  hasCar?: InputMaybe<OrderBy>;
  /** Order by having drivers license. */
  hasDriversLicense?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by last-active-at datatime. */
  lastActiveAt?: InputMaybe<OrderBy>;
  /** Order by last name. */
  lastName?: InputMaybe<OrderBy>;
  /** Order by address latitude. */
  latitude?: InputMaybe<OrderBy>;
  /** Order by address locality. */
  locality?: InputMaybe<OrderBy>;
  /** Order by address longitude. */
  longitude?: InputMaybe<OrderBy>;
  /** Order by middle name. */
  middleName?: InputMaybe<OrderBy>;
  /** Order by address postcode. */
  postcode?: InputMaybe<OrderBy>;
  /** Order by preferred name. */
  preferredName?: InputMaybe<OrderBy>;
  /** Order by address region. */
  region?: InputMaybe<OrderBy>;
  /** Order by schedulable status. */
  schedulable?: InputMaybe<OrderBy>;
  /** Order by address street. */
  street?: InputMaybe<OrderBy>;
  /** Order by teams. */
  teams?: InputMaybe<ClientTeamsOrderByInput>;
  /** Order by unavailable periods. */
  unavailables?: InputMaybe<MemberUnavailablesOrderByInput>;
  /** Order by address unit. */
  unit?: InputMaybe<OrderBy>;
  /** Order by work rights. */
  workRights?: InputMaybe<OrderBy>;
  /** Order by work rights last check date. */
  workRightsLastCheckedAt?: InputMaybe<OrderBy>;
};

/** Updatable fields for "Member" entities. */
export type MembersUpdatableFieldsInput = {
  /** Admin status. */
  admin?: InputMaybe<Scalars['Boolean']>;
  /** Allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: InputMaybe<Scalars['Int']>;
  /** Avatar image upload. */
  avatarData?: InputMaybe<Scalars['Upload']>;
  /** Base pay rate. */
  basePayRate?: InputMaybe<Scalars['Int']>;
  /** Eligible for pay bonuses. */
  bonusEligible?: InputMaybe<Scalars['Boolean']>;
  /** Branches input data. */
  branches?: InputMaybe<QueryByIdsNullableInput>;
  /** Description of car. */
  carDescription?: InputMaybe<Scalars['String']>;
  /** Hexidecimal color. */
  color?: InputMaybe<Scalars['HexColorCode']>;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Drivers license. */
  driversLicense?: InputMaybe<DriversLicense>;
  /** Drivers license country of issue. */
  driversLicenseCountry?: InputMaybe<AddressCountryCode>;
  /** Drivers license expiry date. */
  driversLicenseExpiryDate?: InputMaybe<Scalars['Date']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** Employment end date */
  employmentEndDate?: InputMaybe<Scalars['Date']>;
  /** Employment start date */
  employmentStartDate?: InputMaybe<Scalars['Date']>;
  /** External reference ID. */
  externalId?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Member has a car. */
  hasCar?: InputMaybe<Scalars['Boolean']>;
  /** Member has a drivers license. */
  hasDriversLicense?: InputMaybe<Scalars['Boolean']>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Administrator-only notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Permissions within the organisation. */
  permissions?: InputMaybe<Array<Permission>>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** Member-schedulable status. */
  schedulable?: InputMaybe<Scalars['Boolean']>;
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** Field of study. */
  study?: InputMaybe<Scalars['String']>;
  /** Member is typically unavailable on Friday daytime. */
  unavailableFrDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Friday morning. */
  unavailableFrMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Friday night. */
  unavailableFrNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Monday daytime. */
  unavailableMoDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Monday morning. */
  unavailableMoMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Monday night. */
  unavailableMoNight?: InputMaybe<Scalars['Boolean']>;
  /** General unavailability notes. */
  unavailableNotes?: InputMaybe<Scalars['String']>;
  /** Member is typically unavailable on Saturday daytime. */
  unavailableSaDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday morning. */
  unavailableSaMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday night. */
  unavailableSaNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday daytime. */
  unavailableSuDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday morning. */
  unavailableSuMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Sunday night. */
  unavailableSuNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Thurday daytime. */
  unavailableThDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Thurday morning. */
  unavailableThMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Thurday night. */
  unavailableThNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Tuesday daytime. */
  unavailableTuDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Tuesday morning. */
  unavailableTuMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Tuesday night. */
  unavailableTuNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Wednesday daytime. */
  unavailableWeDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Wednesday morning. */
  unavailableWeMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Wednesday night. */
  unavailableWeNight?: InputMaybe<Scalars['Boolean']>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
  /** Visa Type input data. */
  visaType?: InputMaybe<QueryByIdInput>;
  /** Work rights. */
  workRights?: InputMaybe<MemberWorkRights>;
  /** Work rights last check date. */
  workRightsLastCheckedAt?: InputMaybe<Scalars['Date']>;
};

/** Filtering conditions for "Member" entities. */
export type MembersWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MembersWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MembersWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MembersWhereInput>>;
  /** Allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: InputMaybe<IntConditionalInput>;
  /** BranchMember associative entities. */
  branchMembers?: InputMaybe<BranchMembersWhereInput>;
  /** Car description. */
  carDescription?: InputMaybe<StringConditionalInput>;
  /** Color. */
  color?: InputMaybe<StringConditionalInput>;
  /** Address country. */
  country?: InputMaybe<StringConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Date of birth. */
  dob?: InputMaybe<DateConditionalInput>;
  /** Drivers license country of issue. */
  driversLicenseCountry?: InputMaybe<StringConditionalInput>;
  /** Drivers license expiry date. */
  driversLicenseExpiryDate?: InputMaybe<DateConditionalInput>;
  /** Email address. */
  email?: InputMaybe<StringConditionalInput>;
  /** Events this member is an attendee of. */
  events?: InputMaybe<EventsWhereInput>;
  /** External reference ID. */
  externalId?: InputMaybe<StringConditionalInput>;
  /** First name. */
  firstName?: InputMaybe<StringConditionalInput>;
  /** Gender. */
  gender?: InputMaybe<IntConditionalInput>;
  /** Has car. */
  hasCar?: InputMaybe<BooleanConditionalInput>;
  /** Has drivers license. */
  hasDriversLicense?: InputMaybe<BooleanConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Datetime of when member was last active in the organisation. */
  lastActiveAt?: InputMaybe<DateTimeConditionalInput>;
  /** Last name. */
  lastName?: InputMaybe<StringConditionalInput>;
  /** Address latitude. */
  latitude?: InputMaybe<StringConditionalInput>;
  /** Address locality. */
  locality?: InputMaybe<StringConditionalInput>;
  /** Address longitude. */
  longitude?: InputMaybe<StringConditionalInput>;
  /** Middle name. */
  middleName?: InputMaybe<StringConditionalInput>;
  /** Administrator-only notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Phone number. */
  phone?: InputMaybe<PhoneConditionalInput>;
  /** Address postcode. */
  postcode?: InputMaybe<StringConditionalInput>;
  /** Preferred name. */
  preferredName?: InputMaybe<StringConditionalInput>;
  /** Pronouns. */
  pronouns?: InputMaybe<IntConditionalInput>;
  /** Address region. */
  region?: InputMaybe<StringConditionalInput>;
  /** Salutation. */
  salutation?: InputMaybe<IntConditionalInput>;
  /** Member schedulable status. */
  schedulable?: InputMaybe<BooleanConditionalInput>;
  /** Address street. */
  street?: InputMaybe<StringConditionalInput>;
  /** Teams. */
  teams?: InputMaybe<ClientTeamsWhereInput>;
  /** General unavailability notes. */
  unavailabileNotes?: InputMaybe<StringConditionalInput>;
  /** Member unavailable periods. */
  unavailables?: InputMaybe<MemberUnavailablesWhereInput>;
  /** Address unit. */
  unit?: InputMaybe<StringConditionalInput>;
  /** Work rights. */
  workRights?: InputMaybe<IntConditionalInput>;
  /** Work rights last check date. */
  workRightsLastCheckedAt?: InputMaybe<DateConditionalInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Approve travel data for "Event" entities. */
  approveEventsTravel: Array<Event>;
  /** Archive "Branch" entities. */
  archiveBranches: Array<Branch>;
  /** Archive "Client" entities. */
  archiveClients: Array<Client>;
  /** Archive "Event" entities. */
  archiveEvents: Array<Event>;
  /** Archive "Member" entities. */
  archiveMembers: Array<Member>;
  /** Archive "Org" entities of which the current user is a member. */
  archiveOrgs: Array<Org>;
  /** Archive "PlanManager" entities. */
  archivePlanManagers: Array<PlanManager>;
  /** Archive "SupportCoordinator" entities. */
  archiveSupportCoordinators: Array<SupportCoordinator>;
  /** Cancel "Event" entities. */
  cancelEvents: Array<Event>;
  /** Claim an event for the current user. */
  claimEvent: BooleanResponse;
  /** Clock on/off. */
  clockEvent: Event;
  /** Clock on/off. */
  clockEventAttended: EventOwnAndRelated;
  /** Create "Branch" entities. */
  createBranches: Array<Branch>;
  /** Upload a single File for a "Client" entity. */
  createClientFile: ClientFile;
  /** Create "ClientObservation" entities. */
  createClientObservation: ClientObservation;
  /** Create a "ClientObservation" entity with an Event relation. */
  createClientObservationWithEvent: ClientObservation;
  /** Create "Client" entities. */
  createClients: Array<Client>;
  /** Create "Event" entity. */
  createEvent: Event;
  /** Upload a single File for an "Event" entity. */
  createEventFile: EventFile;
  /** Create "Medication" entities. */
  createMedication: Medication;
  /** Upload a single File for a "Member" entity. */
  createMemberFile: MemberFile;
  /** Create "MemberUnavailable" entities for the current user */
  createMemberUnavailableForMe: MemberUnavailable;
  /** Create "MemberUnavailable" entities. */
  createMemberUnavailables: Array<MemberUnavailable>;
  /** Create "Member" entities. */
  createMembers: Array<Member>;
  /** Create an "Org" entity, "Branch" entities and a "Member" profile entity for the current user. */
  createOrg: Org;
  /** Create "PlanManager" entities. */
  createPlanManagers: Array<PlanManager>;
  /** Create "SupportCoordinator" entities. */
  createSupportCoordinators: Array<SupportCoordinator>;
  /** Create a user account. */
  createUser: UserLoginResponse;
  /** Create a "VisaType" entity. */
  createVisaType: VisaType;
  /** Delete "Branch" entities. */
  deleteBranches: Array<Branch>;
  /** Delete "ClientFile" entities. */
  deleteClientFiles: Array<ClientFile>;
  /** Delete "ClientObservation" entities. */
  deleteClientObservations: Array<ClientObservation>;
  /** Delete "Client" entities. */
  deleteClients: Array<Client>;
  /** Delete "EventFile" entities. */
  deleteEventFiles: Array<EventFile>;
  /** Delete "Event" entities. */
  deleteEvents: Array<Event>;
  /** Delete "Medication" entities. */
  deleteMedications: Array<Medication>;
  /** Delete "MemberFile" entities. */
  deleteMemberFiles: Array<MemberFile>;
  /** Delete "MemberUnavailable" entities. */
  deleteMemberUnavailables: Array<MemberUnavailable>;
  /** Delete "Member" entities. */
  deleteMembers: Array<Member>;
  /** Delete "Org" entities of which the current user is a member. */
  deleteOrgs: Array<Org>;
  /** Delete "PlanManager" entities. */
  deletePlanManagers: Array<PlanManager>;
  /** Delete "SupportCoordinator" entities. */
  deleteSupportCoordinators: Array<SupportCoordinator>;
  /** Delete "VisaType" entities. */
  deleteVisaTypes: Array<VisaType>;
  /** Email worked periods. */
  emailMemberClockedPeriods: BooleanResponse;
  /** Send invitations to join an organisation. */
  inviteMembers: Array<Member>;
  /** Link a member with the current user. */
  linkMember: Member;
  /** Log into an existing user account. */
  loginUser: UserLoginResponse;
  /** Resend user identifier verification token. */
  resendUserIdentifierVerification: Scalars['Boolean'];
  /** Initiate password-reset process for a user account. */
  resetUserPassword: Scalars['Boolean'];
  /** Restore deleted or archived "Branch" entities. */
  restoreBranches: Array<Branch>;
  /** Restore deleted or archived "ClientFile" entities. */
  restoreClientFiles: Array<ClientFile>;
  /** Restore deleted or archived "ClientObservation" entities. */
  restoreClientObservations: Array<ClientObservation>;
  /** Restore deleted or archived "Client" entities. */
  restoreClients: Array<Client>;
  /** Restore deleted or archived "EventFile" entities. */
  restoreEventFiles: Array<EventFile>;
  /** Restore "Event" entities. */
  restoreEvents: Array<Event>;
  /** Restore deleted or archived "Medication" entities. */
  restoreMedications: Array<Medication>;
  /** Restore deleted or archived "MemberFile" entities. */
  restoreMemberFiles: Array<MemberFile>;
  /** Restore deleted or archived "Member" entities. */
  restoreMembers: Array<Member>;
  /** Restore deleted or archived "Org" entities of which the current user is a member. */
  restoreOrgs: Array<Org>;
  /** Restore deleted or archived "PlanManager" entities. */
  restorePlanManagers: Array<PlanManager>;
  /** Restore deleted or archived "SupportCoordinator" entities. */
  restoreSupportCoordinators: Array<SupportCoordinator>;
  /** Restore deleted or archived "VisaType" entities. */
  restoreVisaTypes: Array<VisaType>;
  /** Revert updates to a "Branch" entity, providing the IDs of correlating "History" entities. */
  revertBranchUpdates: Array<Branch>;
  /** Revert updates to a "Client" entity, providing the IDs of correlating "History" entities. */
  revertClientUpdates: Array<Client>;
  /** Revert updates to a "Member" entity, providing the IDs of correlating "History" entities. */
  revertMemberUpdates: Array<Member>;
  /** Revert updates to an "Org" entity of which the current user is a member, providing the IDs of correlating "History" entities. */
  revertOrgUpdates: Array<Org>;
  /** Revert updates to a "PlanManager" entity, providing the IDs of correlating "History" entities. */
  revertPlanManagerUpdates: Array<PlanManager>;
  /** Revert updates to a "SupportCoordinator" entity, providing the IDs of correlating "History" entities. */
  revertSupportCoordinatorUpdates: Array<SupportCoordinator>;
  /** Set a client's medication. */
  setClientMedications: Array<ClientMedication>;
  /** Set which "Member" entities belong to a given "Client" entity's team. */
  setClientTeamMembers: Array<ClientTeam>;
  /** Using a password-reset token, set a new password for a user account. */
  setUserPassword: UserLoginResponse;
  /** Delete existing and unused invitations. */
  uninviteMembers: Array<Member>;
  /** Unlink members from their user entities. */
  unlinkMembers: Array<Member>;
  /** Update "Branch" entities. */
  updateBranches: Array<Branch>;
  /** Update "ClientFile" entities. */
  updateClientFiles: Array<ClientFile>;
  /** Update "ClientObservation" entities. */
  updateClientObservation: ClientObservation;
  /** Update "Client" entities. */
  updateClients: Array<Client>;
  /** Update "Event" entity. */
  updateEvent: Event;
  /** Update basic fields of an "Event" entity of which the current user is attending. */
  updateEventAttended: EventOwnAndRelated;
  /** Automatically assign an attendee to an "Event" entity. */
  updateEventAutomaticAttendee: Event;
  /** Update "EventFile" entities. */
  updateEventFiles: Array<EventFile>;
  /** Update "Medication" entities. */
  updateMedication: Medication;
  /** Update the current users own "Member" entity. */
  updateMemberCurrent: Member;
  /** Update "MemberFile" entities. */
  updateMemberFiles: Array<MemberFile>;
  /** Update "Member" entities. */
  updateMembers: Array<Member>;
  /** Update the users current "Org" entity. */
  updateOrg: Org;
  /** Update "PlanManager" entities. */
  updatePlanManagers: Array<PlanManager>;
  /** Update "SupportCoordinator" entities. */
  updateSupportCoordinators: Array<SupportCoordinator>;
  /** Update the currently logged-in user account. */
  updateUser: User;
  /** Update the currently logged-in user's active member profile. */
  updateUserActiveMember: User;
  /** Update "VisaType" entities. */
  updateVisaType: VisaType;
  /** Verify user identififer. */
  verifyUserIdentifier: User;
};

export type MutationApproveEventsTravelArgs = {
  input: QueryByIdsInput;
};

export type MutationArchiveBranchesArgs = {
  input: ArchiveInput;
};

export type MutationArchiveClientsArgs = {
  input: ArchiveInput;
};

export type MutationArchiveEventsArgs = {
  input: ArchiveInput;
};

export type MutationArchiveMembersArgs = {
  input: ArchiveInput;
};

export type MutationArchiveOrgsArgs = {
  input: ArchiveInput;
};

export type MutationArchivePlanManagersArgs = {
  input: ArchiveInput;
};

export type MutationArchiveSupportCoordinatorsArgs = {
  input: ArchiveInput;
};

export type MutationCancelEventsArgs = {
  input: EventsCancelInput;
};

export type MutationClaimEventArgs = {
  input: QueryByIdInput;
};

export type MutationClockEventArgs = {
  input: ClockEventInput;
};

export type MutationClockEventAttendedArgs = {
  input: ClockEventInput;
};

export type MutationCreateBranchesArgs = {
  input: CreateBranchesInput;
};

export type MutationCreateClientFileArgs = {
  input: CreateClientFileInput;
};

export type MutationCreateClientObservationArgs = {
  input: CreateClientObservationInput;
};

export type MutationCreateClientObservationWithEventArgs = {
  input: CreateClientObservationWithEventInput;
};

export type MutationCreateClientsArgs = {
  input: CreateClientsInput;
};

export type MutationCreateEventArgs = {
  input: CreateEventInput;
};

export type MutationCreateEventFileArgs = {
  input: CreateEventFileInput;
};

export type MutationCreateMedicationArgs = {
  input: CreateMedicationInput;
};

export type MutationCreateMemberFileArgs = {
  input: CreateMemberFileInput;
};

export type MutationCreateMemberUnavailableForMeArgs = {
  input: CreateMemberUnavailableForMeInput;
};

export type MutationCreateMemberUnavailablesArgs = {
  input: CreateMemberUnavailablesInput;
};

export type MutationCreateMembersArgs = {
  input: CreateMembersInput;
};

export type MutationCreateOrgArgs = {
  input: CreateOrgInput;
};

export type MutationCreatePlanManagersArgs = {
  input: CreatePlanManagersInput;
};

export type MutationCreateSupportCoordinatorsArgs = {
  input: CreateSupportCoordinatorsInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateVisaTypeArgs = {
  input: CreateVisaTypeInput;
};

export type MutationDeleteBranchesArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientFilesArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientObservationsArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientsArgs = {
  input: DeleteInput;
};

export type MutationDeleteEventFilesArgs = {
  input: DeleteInput;
};

export type MutationDeleteEventsArgs = {
  input: DeleteInput;
};

export type MutationDeleteMedicationsArgs = {
  input: DeleteInput;
};

export type MutationDeleteMemberFilesArgs = {
  input: DeleteInput;
};

export type MutationDeleteMemberUnavailablesArgs = {
  input: QueryByIdsInput;
};

export type MutationDeleteMembersArgs = {
  input: DeleteInput;
};

export type MutationDeleteOrgsArgs = {
  input: DeleteInput;
};

export type MutationDeletePlanManagersArgs = {
  input: DeleteInput;
};

export type MutationDeleteSupportCoordinatorsArgs = {
  input: DeleteInput;
};

export type MutationDeleteVisaTypesArgs = {
  input: DeleteInput;
};

export type MutationEmailMemberClockedPeriodsArgs = {
  input: QueryMemberClockedPeriodsInput;
};

export type MutationInviteMembersArgs = {
  input: QueryByIdsInput;
};

export type MutationLinkMemberArgs = {
  input: LinkMemberInput;
};

export type MutationLoginUserArgs = {
  input: LoginUserInput;
};

export type MutationResendUserIdentifierVerificationArgs = {
  input: ResendUserIdentifierVerificationInput;
};

export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

export type MutationRestoreBranchesArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientFilesArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientObservationsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreEventFilesArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreEventsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreMedicationsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreMemberFilesArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreMembersArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreOrgsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestorePlanManagersArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreSupportCoordinatorsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreVisaTypesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertBranchUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertClientUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertMemberUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertOrgUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertPlanManagerUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertSupportCoordinatorUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationSetClientMedicationsArgs = {
  input: SetClientMedicationsInput;
};

export type MutationSetClientTeamMembersArgs = {
  input: SetClientTeamMembersInput;
};

export type MutationSetUserPasswordArgs = {
  input: SetUserPasswordInput;
};

export type MutationUninviteMembersArgs = {
  input: QueryByIdsInput;
};

export type MutationUnlinkMembersArgs = {
  input: QueryByIdsInput;
};

export type MutationUpdateBranchesArgs = {
  input: UpdateBranchesInput;
};

export type MutationUpdateClientFilesArgs = {
  input: UpdateClientFilesInput;
};

export type MutationUpdateClientObservationArgs = {
  input: UpdateClientObservationInput;
};

export type MutationUpdateClientsArgs = {
  input: UpdateClientsInput;
};

export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};

export type MutationUpdateEventAttendedArgs = {
  input: UpdateEventBasicFieldsInput;
};

export type MutationUpdateEventAutomaticAttendeeArgs = {
  input: QueryByIdInput;
};

export type MutationUpdateEventFilesArgs = {
  input: UpdateEventFilesInput;
};

export type MutationUpdateMedicationArgs = {
  input: UpdateMedicationInput;
};

export type MutationUpdateMemberCurrentArgs = {
  input: UpdateMemberCurrentInput;
};

export type MutationUpdateMemberFilesArgs = {
  input: UpdateMemberFilesInput;
};

export type MutationUpdateMembersArgs = {
  input: UpdateMembersInput;
};

export type MutationUpdateOrgArgs = {
  input: UpdateOrgInput;
};

export type MutationUpdatePlanManagersArgs = {
  input: UpdatePlanManagersInput;
};

export type MutationUpdateSupportCoordinatorsArgs = {
  input: UpdateSupportCoordinatorsInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserActiveMemberArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateVisaTypeArgs = {
  input: UpdateVisaTypeInput;
};

export type MutationVerifyUserIdentifierArgs = {
  input: VerifyUserIdentifierInput;
};

/** NDIS participant ID conditional expression input. */
export type NdisIdConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['NdisId']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['NdisId']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['NdisId']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['NdisId']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['NdisId']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['NdisId']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['NdisId']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['NdisId']>>;
};

export type Notification = {
  __typename?: 'Notification';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Datetime when notification was dismissed. */
  dismissedAt?: Maybe<Scalars['DateTime']>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  recipient: Member;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Aggregated response object for "Notification" entities. */
export type NotificationsAggregate = {
  __typename?: 'NotificationsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Notification>;
};

/** Ordering options for "Notification" entities. */
export type NotificationsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "Notification" entities. */
export type NotificationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<NotificationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<NotificationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<NotificationsWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
};

export enum OrderBy {
  ASC = 'ASC',
  ASC_NULLS_FIRST = 'ASC_NULLS_FIRST',
  ASC_NULLS_LAST = 'ASC_NULLS_LAST',
  DESC = 'DESC',
  DESC_NULLS_FIRST = 'DESC_NULLS_FIRST',
  DESC_NULLS_LAST = 'DESC_NULLS_LAST',
}

/** An organisation. */
export type Org = Business & {
  __typename?: 'Org';
  /** Australian Business Number of the organisation */
  abn?: Maybe<Scalars['ABN']>;
  /** Administrators of the organisation. */
  admins?: Maybe<Array<Member>>;
  /** Afternoon pay multiplier. */
  afternoonPayMultiplier?: Maybe<Scalars['Int']>;
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Number of weeks in the future where to start automatically assigning members. */
  assignMemberToEventWeeksInAdvance?: Maybe<Scalars['Int']>;
  /** Base pay rate. */
  basePayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 2. */
  bonus2PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 3. */
  bonus3PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 4. */
  bonus4PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 5. */
  bonus5PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 6. */
  bonus6PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 7. */
  bonus7PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 8. */
  bonus8PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 9. */
  bonus9PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 10. */
  bonus10PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 11. */
  bonus11PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 12. */
  bonus12PayRate?: Maybe<Scalars['Int']>;
  /** Branches belonging to the organisation. */
  branches: Array<Branch>;
  /** Warning threshold for early/late clock-off times. */
  clockOffWarnMinutes?: Maybe<Scalars['Int']>;
  /** Warning threshold for early/late clock-on times. */
  clockOnWarnMinutes?: Maybe<Scalars['Int']>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Email address */
  email?: Maybe<Scalars['EmailAddress']>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Kilometre allowance. */
  kmAllowance?: Maybe<Scalars['Int']>;
  /** Legal name */
  legalName: Scalars['String'];
  /** Members can see clocked times. */
  membersCanSeeClockedTimes?: Maybe<Scalars['Boolean']>;
  /** Night pay multiplier. */
  nightPayMultiplier?: Maybe<Scalars['Int']>;
  /** Passive-overnight allowance. */
  passiveAllowance?: Maybe<Scalars['Int']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']>;
  /** Preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** Public holiday pay multiplier. */
  pubPayMultiplier?: Maybe<Scalars['Int']>;
  /** Quantity of shifts a member can see occuring immedaitely after thier own shift. */
  quantityOfFutureShiftsMembersCanSee?: Maybe<Scalars['Int']>;
  /** Quantity of shifts a member can see occuring immedaitely before thier own shift. */
  quantityOfPastShiftsMembersCanSee?: Maybe<Scalars['Int']>;
  /** Allow running the auto-assign-member task. */
  runAutoAssignMemberTask?: Maybe<Scalars['Boolean']>;
  /** Saturday pay multiplier. */
  satPayMultiplier?: Maybe<Scalars['Int']>;
  /** Scheduling contact person. */
  schedulingContactPerson?: Maybe<Member>;
  /** Second scheduling contact person. */
  schedulingContactPerson2?: Maybe<Member>;
  /** Sunday pay multiplier. */
  sunPayMultiplier?: Maybe<Scalars['Int']>;
  /** Days in advance to check for unfilled shifts. */
  unfilledEventsDayRange?: Maybe<Scalars['Int']>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Website of the organisation */
  website?: Maybe<Scalars['String']>;
};

/** An organisation. */
export type OrgAdminsArgs = {
  input?: InputMaybe<QueryMembersInput>;
};

/** An organisation. */
export type OrgBranchesArgs = {
  input?: InputMaybe<QueryBranchesInput>;
};

/** Aggregated response object for "Org" entities. */
export type OrgsAggregate = {
  __typename?: 'OrgsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Org>;
};

/** Ordering options for "Org" entities. */
export type OrgsOrderByInput = {
  /** Order by Australian Business Number. */
  abn?: InputMaybe<OrderBy>;
  /** Order by datetime of entity creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by email address. */
  email?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by legal name. */
  legalName?: InputMaybe<OrderBy>;
  /** Order by phone number. */
  phone?: InputMaybe<OrderBy>;
  /** Order by preferred name. */
  preferredName?: InputMaybe<OrderBy>;
  /** Order by website. */
  website?: InputMaybe<OrderBy>;
};

/** Updatable fields for "Org" entities. */
export type OrgsUpdatableFieldsInput = {
  /** Australian Business Number of the organisation */
  abn?: InputMaybe<Scalars['ABN']>;
  /** Afternoon pay multiplier. */
  afternoonPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Number of weeks in the future where to start automatically assigning members. */
  assignMemberToEventWeeksInAdvance?: InputMaybe<Scalars['Int']>;
  /** Base pay rate. */
  basePayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 2. */
  bonus2PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 3. */
  bonus3PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 4. */
  bonus4PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 5. */
  bonus5PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 6. */
  bonus6PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 7. */
  bonus7PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 8. */
  bonus8PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 9. */
  bonus9PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 10. */
  bonus10PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 11. */
  bonus11PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 12. */
  bonus12PayRate?: InputMaybe<Scalars['Int']>;
  /** Warning threshold for early/late clock-off times. */
  clockOffWarnMinutes?: InputMaybe<Scalars['Int']>;
  /** Warning threshold for early/late clock-on times. */
  clockOnWarnMinutes?: InputMaybe<Scalars['Int']>;
  /** Email address */
  email?: InputMaybe<Scalars['EmailAddress']>;
  /** Kilometre allowance. */
  kmAllowance?: InputMaybe<Scalars['Int']>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']>;
  /** Members can see clocked times. */
  membersCanSeeClockedTimes?: InputMaybe<Scalars['Boolean']>;
  /** Night pay multiplier. */
  nightPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Passive-overnight allowance. */
  passiveAllowance?: InputMaybe<Scalars['Int']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred name */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Public holiday pay multiplier. */
  pubPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Quantity of shifts a member can see occuring immedaitely after thier own shift. */
  quantityOfFutureShiftsMembersCanSee?: InputMaybe<Scalars['Int']>;
  /** Quantity of shifts a member can see occuring immedaitely before thier own shift. */
  quantityOfPastShiftsMembersCanSee?: InputMaybe<Scalars['Int']>;
  /** Allow running the auto-assign-member task. */
  runAutoAssignMemberTask?: InputMaybe<Scalars['Boolean']>;
  /** Saturday pay multiplier. */
  satPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Scheduling contact person input data. */
  schedulingContactPerson?: InputMaybe<QueryByIdInput>;
  /** Second scheduling contact person input data. */
  schedulingContactPerson2?: InputMaybe<QueryByIdInput>;
  /** Sunday pay multiplier. */
  sunPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Days in advance to check for unfilled shifts. */
  unfilledEventsDayRange?: InputMaybe<Scalars['Int']>;
  /** Website of the organisation */
  website?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "Org" entities. */
export type OrgsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<OrgsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<OrgsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<OrgsWhereInput>>;
  /** Australian Business Number. */
  abn?: InputMaybe<AbnConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Email address. */
  email?: InputMaybe<StringConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Legal Name. */
  legalName?: InputMaybe<StringConditionalInput>;
  /** Phone number. */
  phone?: InputMaybe<PhoneConditionalInput>;
  /** Preferred Name. */
  preferredName?: InputMaybe<StringConditionalInput>;
  /** Website. */
  website?: InputMaybe<StringConditionalInput>;
};

export enum Permission {
  CLIENT_FILE_READ = 'CLIENT_FILE_READ',
  CLIENT_FILE_WRITE = 'CLIENT_FILE_WRITE',
  CLIENT_READ = 'CLIENT_READ',
  CLIENT_WRITE = 'CLIENT_WRITE',
  EVENT_READ = 'EVENT_READ',
  EVENT_WRITE = 'EVENT_WRITE',
  MEMBER_FILE_READ = 'MEMBER_FILE_READ',
  MEMBER_FILE_WRITE = 'MEMBER_FILE_WRITE',
  MEMBER_READ = 'MEMBER_READ',
  MEMBER_WRITE = 'MEMBER_WRITE',
  PLANMANAGER_READ = 'PLANMANAGER_READ',
  PLANMANAGER_WRITE = 'PLANMANAGER_WRITE',
  SUPPORTCOORDINATOR_READ = 'SUPPORTCOORDINATOR_READ',
  SUPPORTCOORDINATOR_WRITE = 'SUPPORTCOORDINATOR_WRITE',
}

/** A person */
export type Person = {
  /** First name */
  firstName: Scalars['String'];
  /** Gender */
  gender?: Maybe<Gender>;
  /** Last name */
  lastName?: Maybe<Scalars['String']>;
  /** Middle name */
  middleName?: Maybe<Scalars['String']>;
  /** Preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** Pronouns */
  pronouns?: Maybe<Pronouns>;
  /** Salutation */
  salutation?: Maybe<Salutation>;
};

/** Phone number conditional expression input. */
export type PhoneConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['String']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['String']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['String']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['String']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['String']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['String']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['String']>>;
};

/** A plan manager for NDIS participants. */
export type PlanManager = {
  __typename?: 'PlanManager';
  /** Australian Business Number of the organisation */
  abn?: Maybe<Scalars['ABN']>;
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Billing email address. */
  billingEmail?: Maybe<Scalars['String']>;
  /** Business name. */
  businessName?: Maybe<Scalars['String']>;
  clients: Array<Client>;
  /** Contact email address. */
  contactEmail?: Maybe<Scalars['String']>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Name. */
  name: Scalars['String'];
  /** Notes. */
  notes?: Maybe<Scalars['String']>;
  /** Organisation to which this plan manager belongs. */
  org: Org;
  /** Phone number. */
  phone?: Maybe<Scalars['String']>;
  /** Salutation. */
  salutation?: Maybe<Salutation>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** A plan manager for NDIS participants. */
export type PlanManagerClientsArgs = {
  input?: InputMaybe<QueryClientsInput>;
};

/** Aggregated response object for "PlanManager" entities. */
export type PlanManagersAggregate = {
  __typename?: 'PlanManagersAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<PlanManager>;
};

/** Creatable fields for "PlanManager" entities. */
export type PlanManagersCreatableFieldsInput = {
  /** Australian Business Number of the organisation */
  abn?: InputMaybe<Scalars['ABN']>;
  /** Billing email address. */
  billingEmail?: InputMaybe<Scalars['String']>;
  /** Business name. */
  businessName?: InputMaybe<Scalars['String']>;
  /** Contact email address. */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Name. */
  name: Scalars['String'];
  /** Notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
};

/** Ordering options for "PlanManager" entities. */
export type PlanManagersOrderByInput = {
  /** Order by Australian Business Number. */
  abn?: InputMaybe<OrderBy>;
  /** Order by business name. */
  businessName?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by name. */
  name?: InputMaybe<OrderBy>;
};

/** Updatable fields for "PlanManager" entities. */
export type PlanManagersUpdatableFieldsInput = {
  /** Australian Business Number of the organisation */
  abn?: InputMaybe<Scalars['ABN']>;
  /** Billing email address. */
  billingEmail?: InputMaybe<Scalars['String']>;
  /** Business name. */
  businessName?: InputMaybe<Scalars['String']>;
  /** Contact email address. */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Name. */
  name?: InputMaybe<Scalars['String']>;
  /** Notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
};

/** Filtering conditions for "PlanManager" entities. */
export type PlanManagersWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<PlanManagersWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<PlanManagersWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<PlanManagersWhereInput>>;
  /** Australian Business Number. */
  abn?: InputMaybe<AbnConditionalInput>;
  /** Billing email address. */
  billingEmail?: InputMaybe<StringConditionalInput>;
  /** Business name. */
  businessName?: InputMaybe<StringConditionalInput>;
  /** Contact email address. */
  contactEmail?: InputMaybe<StringConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Name. */
  name?: InputMaybe<StringConditionalInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Phone number. */
  phone?: InputMaybe<PhoneConditionalInput>;
  /** Salutation. */
  salutation?: InputMaybe<IntConditionalInput>;
};

/** Pronouns */
export enum Pronouns {
  /** He */
  HE = 'HE',
  /** Not specified */
  NOTSPECIFIED = 'NOTSPECIFIED',
  /** She */
  SHE = 'SHE',
  /** They */
  THEY = 'THEY',
}

/** A public holiday. */
export type PublicHoliday = {
  __typename?: 'PublicHoliday';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Date of the public holiday. */
  date: Scalars['Date'];
  /** Description. */
  description: Scalars['String'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Aggregated response object for "PublicHoliday" entities. */
export type PublicHolidaysAggregate = {
  __typename?: 'PublicHolidaysAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<PublicHoliday>;
};

/** Ordering options for "PublicHoliday" entities. */
export type PublicHolidaysOrderByInput = {
  /** Order by date. */
  date?: InputMaybe<OrderBy>;
  /** Order by description. */
  description?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "PublicHoliday" entities. */
export type PublicHolidaysWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<PublicHolidaysWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<PublicHolidaysWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<PublicHolidaysWhereInput>>;
  /** Date. */
  date?: InputMaybe<DateConditionalInput>;
  /** Description. */
  description?: InputMaybe<StringConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
};

export type Query = {
  __typename?: 'Query';
  /** Fetch a single "Branch" entity by its unique ID. */
  branchById: Branch;
  /** Fetch paginated "Branch" entities. */
  branches: Array<Branch>;
  /** Fetch aggregated "Branch" entities. */
  branchesAggregate: BranchesAggregate;
  /** Fetch a single "Client" entity by its unique ID. */
  clientById: Client;
  /** Fetch paginated "ClientFile" entities. */
  clientFiles: Array<ClientFile>;
  /** Fetch aggregated "ClientFile" entities. */
  clientFilesAggregate: ClientFilesAggregate;
  /** Fetch a single "ClientMedication" entity by its unique ID. */
  clientMedicationById: ClientMedication;
  /** Fetch paginated "ClientMedication" entities. */
  clientMedications: Array<ClientMedication>;
  /** Fetch aggregated "ClientMedication" entities. */
  clientMedicationsAggregate: ClientMedicationsAggregate;
  /** Fetch a single "ClientObservation" entity by its unique ID. */
  clientObservationById: ClientObservation;
  /** Fetch paginated "ClientObservation" entities. */
  clientObservations: Array<ClientObservation>;
  /** Fetch aggregated "ClientObservation" entities. */
  clientObservationsAggregate: ClientObservationsAggregate;
  /** Fetch a single "ClientTeam" entity by its unique ID. */
  clientTeamById: ClientTeam;
  /** Fetch paginated "ClientTeam" entities. */
  clientTeams: Array<ClientTeam>;
  /** Fetch aggregated "ClientTeam" entities. */
  clientTeamsAggregate: ClientTeamsAggregate;
  /** Fetch paginated "Client" entities. */
  clients: Array<Client>;
  /** Fetch aggregated "Client" entities. */
  clientsAggregate: ClientsAggregate;
  /** Download a file by its unique ID, belonging to a "Client" entity. */
  downloadClientFileById: File;
  /** Download a file by its unique ID, belonging to an "Event" entity. */
  downloadEventFileById: File;
  /** Download a file by its unique ID, belonging to a "Member" entity. */
  downloadMemberFileById: File;
  /** Fetch a single "Event" entity by its unique ID. */
  eventById: Event;
  /** Fetch paginated "EventFile" entities. */
  eventFiles: Array<EventFile>;
  /** Fetch aggregated "EventFile" entities. */
  eventFilesAggregate: EventFilesAggregate;
  /** Fetch a single "EventOwnAndRelated" entity by its unique ID. */
  eventOwnAndRelatedById: EventOwnAndRelated;
  /** Fetch paginated "Event" entities. */
  events: Array<Event>;
  /** Fetch aggregated "Event" entities. */
  eventsAggregate: EventsAggregate;
  /** Fetch unattended "EventOwnAndRelated" entities which the current user can claim. */
  eventsClaimable: Array<EventOwnAndRelated>;
  /** Fetch "EventOwnAndRelated" entities. */
  eventsOwnAndRelated: Array<EventOwnAndRelated>;
  /** Historical records. */
  histories?: Maybe<Array<HistoriesUnion>>;
  /** Get data on the currently logged-in user. */
  me: User;
  /** Fetch a single "Medication" entity by its unique ID. */
  medicationById: Medication;
  /** Fetch paginated "Medication" entities. */
  medications: Array<Medication>;
  /** Fetch aggregated "Medication" entities. */
  medicationsAggregate: MedicationsAggregate;
  /** Fetch a single "Member" entity by its unique ID. */
  memberById: Member;
  /** Fetch paginated "MemberFile" entities. */
  memberFiles: Array<MemberFile>;
  /** Fetch aggregated "MemberFile" entities. */
  memberFilesAggregate: MemberFilesAggregate;
  /** Fetch a single "MemberUnavailable" entity by its unique ID. */
  memberUnavailableById: MemberUnavailable;
  /** Fetch paginated "MemberUnavailable" entities. */
  memberUnavailables: Array<MemberUnavailable>;
  /** Fetch aggregated "MemberUnavailable" entities. */
  memberUnavailablesAggregate: MemberUnavailablesAggregate;
  /** Fetch paginated "Member" entities. */
  members: Array<Member>;
  /** Fetch aggregated "Member" entities. */
  membersAggregate: MembersAggregate;
  /** Fetch paginated "MemberUnavailable" entities for the current user. */
  myUnavailables: Array<MemberUnavailable>;
  /** Fetch a single "Notification" entity by its unique ID belonging to the current user. */
  notificationById: Notification;
  /** Fetch paginated "Notification" entities. */
  notifications: Array<Notification>;
  /** Fetch aggregated "Notification" entities. */
  notificationsAggregate: NotificationsAggregate;
  /** Fetch a single "Org" entity by its unique ID. */
  orgById: Org;
  /** Fetch paginated "Org" entities of which the current user is a member. */
  orgs: Array<Org>;
  /** Fetch aggregated "Org" entities of which the current user is a member. */
  orgsAggregate: OrgsAggregate;
  /** Fetch a single "PlanManager" entity by its unique ID. */
  planManagerById: PlanManager;
  /** Fetch paginated "PlanManager" entities. */
  planManagers: Array<PlanManager>;
  /** Fetch aggregated "PlanManager" entities. */
  planManagersAggregate: PlanManagersAggregate;
  /** Fetch a single "PublicHoliday" entity by its unique ID. */
  publicHolidayById: PublicHoliday;
  /** Fetch paginated "PublicHoliday" entities. */
  publicHolidays: Array<PublicHoliday>;
  /** Fetch aggregated "PublicHoliday" entities. */
  publicHolidaysAggregate: PublicHolidaysAggregate;
  /** Fetch a single "RedactedClient" entity by its unique ID. */
  redactedClientById: RedactedClient;
  /** Fetch paginated "RedactedClient" entities. */
  redactedClients: Array<RedactedClient>;
  /** Fetch aggregated "RedactedClient" entities. */
  redactedClientsAggregate: RedactedClientsAggregate;
  /** Fetch a single "RedactedMember" entity by its unique ID. */
  redactedMemberById: RedactedMember;
  /** Fetch paginated "RedactedMember" entities. */
  redactedMembers: Array<RedactedMember>;
  /** Fetch aggregated "RedactedMember" entities. */
  redactedMembersAggregate: RedactedMembersAggregate;
  /** Fetch the "Schedule" entity. */
  schedule: Schedule;
  /** Fetch a single "SupportCoordinator" entity by its unique ID. */
  supportCoordinatorById: SupportCoordinator;
  /** Fetch paginated "SupportCoordinator" entities. */
  supportCoordinators: Array<SupportCoordinator>;
  /** Fetch aggregated "SupportCoordinator" entities. */
  supportCoordinatorsAggregate: SupportCoordinatorsAggregate;
  /** Fetch a single "VisaType" entity by its unique ID. */
  visaTypeById: VisaType;
  /** Fetch paginated "VisaType" entities. */
  visaTypes: Array<VisaType>;
  /** Fetch aggregated "VisaType" entities. */
  visaTypesAggregate: VisaTypesAggregate;
};

export type QueryBranchByIdArgs = {
  id: Scalars['ID'];
};

export type QueryBranchesArgs = {
  input: QueryBranchesInput;
};

export type QueryBranchesAggregateArgs = {
  input: QueryBranchesInput;
};

export type QueryClientByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientFilesArgs = {
  input: QueryClientFilesInput;
};

export type QueryClientFilesAggregateArgs = {
  input: QueryClientFilesInput;
};

export type QueryClientMedicationByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientMedicationsArgs = {
  input: QueryClientMedicationsInput;
};

export type QueryClientMedicationsAggregateArgs = {
  input: QueryClientMedicationsInput;
};

export type QueryClientObservationByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientObservationsArgs = {
  input: QueryClientObservationsInput;
};

export type QueryClientObservationsAggregateArgs = {
  input: QueryClientObservationsInput;
};

export type QueryClientTeamByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientTeamsArgs = {
  input: QueryClientTeamsInput;
};

export type QueryClientTeamsAggregateArgs = {
  input: QueryClientTeamsInput;
};

export type QueryClientsArgs = {
  input: QueryClientsInput;
};

export type QueryClientsAggregateArgs = {
  input: QueryClientsInput;
};

export type QueryDownloadClientFileByIdArgs = {
  id: Scalars['ID'];
};

export type QueryDownloadEventFileByIdArgs = {
  id: Scalars['ID'];
};

export type QueryDownloadMemberFileByIdArgs = {
  id: Scalars['ID'];
};

export type QueryEventByIdArgs = {
  id: Scalars['ID'];
};

export type QueryEventFilesArgs = {
  input: QueryEventFilesInput;
};

export type QueryEventFilesAggregateArgs = {
  input: QueryEventFilesInput;
};

export type QueryEventOwnAndRelatedByIdArgs = {
  id: Scalars['ID'];
};

export type QueryEventsArgs = {
  input: QueryEventsInput;
};

export type QueryEventsAggregateArgs = {
  input: QueryEventsInput;
};

export type QueryEventsOwnAndRelatedArgs = {
  input: QueryEventsOwnAndRelatedInput;
};

export type QueryHistoriesArgs = {
  input: QueryHistoriesInput;
};

export type QueryMedicationByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMedicationsArgs = {
  input: QueryMedicationsInput;
};

export type QueryMedicationsAggregateArgs = {
  input: QueryMedicationsInput;
};

export type QueryMemberByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMemberFilesArgs = {
  input: QueryMemberFilesInput;
};

export type QueryMemberFilesAggregateArgs = {
  input: QueryMemberFilesInput;
};

export type QueryMemberUnavailableByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMemberUnavailablesArgs = {
  input: QueryMemberUnavailablesInput;
};

export type QueryMemberUnavailablesAggregateArgs = {
  input: QueryMemberUnavailablesInput;
};

export type QueryMembersArgs = {
  input: QueryMembersInput;
};

export type QueryMembersAggregateArgs = {
  input: QueryMembersInput;
};

export type QueryMyUnavailablesArgs = {
  input: QueryMyMemberUnavailablesInput;
};

export type QueryNotificationByIdArgs = {
  id: Scalars['ID'];
};

export type QueryNotificationsArgs = {
  input: QueryNotificationsInput;
};

export type QueryNotificationsAggregateArgs = {
  input: QueryNotificationsInput;
};

export type QueryOrgByIdArgs = {
  id: Scalars['ID'];
};

export type QueryOrgsArgs = {
  input: QueryOrgsInput;
};

export type QueryOrgsAggregateArgs = {
  input: QueryOrgsInput;
};

export type QueryPlanManagerByIdArgs = {
  id: Scalars['ID'];
};

export type QueryPlanManagersArgs = {
  input: QueryPlanManagersInput;
};

export type QueryPlanManagersAggregateArgs = {
  input: QueryPlanManagersInput;
};

export type QueryPublicHolidayByIdArgs = {
  id: Scalars['ID'];
};

export type QueryPublicHolidaysArgs = {
  input: QueryPublicHolidaysInput;
};

export type QueryPublicHolidaysAggregateArgs = {
  input: QueryPublicHolidaysInput;
};

export type QueryRedactedClientByIdArgs = {
  id: Scalars['ID'];
};

export type QueryRedactedClientsArgs = {
  input: QueryRedactedClientsInput;
};

export type QueryRedactedClientsAggregateArgs = {
  input: QueryRedactedClientsInput;
};

export type QueryRedactedMemberByIdArgs = {
  id: Scalars['ID'];
};

export type QueryRedactedMembersArgs = {
  input: QueryRedactedMembersInput;
};

export type QueryRedactedMembersAggregateArgs = {
  input: QueryRedactedMembersInput;
};

export type QuerySupportCoordinatorByIdArgs = {
  id: Scalars['ID'];
};

export type QuerySupportCoordinatorsArgs = {
  input: QuerySupportCoordinatorsInput;
};

export type QuerySupportCoordinatorsAggregateArgs = {
  input: QuerySupportCoordinatorsInput;
};

export type QueryVisaTypeByIdArgs = {
  id: Scalars['ID'];
};

export type QueryVisaTypesArgs = {
  input: QueryVisaTypesInput;
};

export type QueryVisaTypesAggregateArgs = {
  input: QueryVisaTypesInput;
};

/** Quering input data for "Branch" entities. */
export type QueryBranchesInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<BranchesOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<BranchesWhereInput>;
};

/** Query by ID input data for any entity. */
export type QueryByIdInput = {
  /** Entity ID. */
  id: Scalars['ID'];
};

/** Query by ID input data for any entity. */
export type QueryByIdsInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
};

/** Query by ID input data for any entity. Nullable. */
export type QueryByIdsNullableInput = {
  /** Entity IDs. */
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

/** Quering input data for "ClientFile" entities. */
export type QueryClientFilesInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientFilesOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<ClientFilesWhereInput>;
};

/** Quering input data for "ClientGivenMedication" entities. */
export type QueryClientGivenFoodsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientObservationFoodsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<ClientObservationFoodsWhereInput>;
};

/** Quering input data for "ClientGivenMedication" entities. */
export type QueryClientGivenMedicationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientObservationMedicationsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<ClientObservationMedicationsWhereInput>;
};

/** Quering input data for "ClientMedication" entities. */
export type QueryClientMedicationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientMedicationsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<ClientMedicationsWhereInput>;
};

/** Quering input data for "ClientObservation" entities. */
export type QueryClientObservationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientObservationsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<ClientObservationsWhereInput>;
};

/** Quering input data for "ClientTeam" entities. */
export type QueryClientTeamsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientTeamsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<ClientTeamsWhereInput>;
};

/** Quering input data for "Client" entities. */
export type QueryClientsInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<ClientsWhereInput>;
};

/** Quering input data for "EventFile" entities. */
export type QueryEventFilesInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<EventFilesOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<EventFilesWhereInput>;
};

/** Quering input data for "Event" entities. */
export type QueryEventsInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<EventsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<EventsWhereInput>;
};

/** Quering input data for "EventOwnAndRelated" entities. */
export type QueryEventsOwnAndRelatedInput = {
  /** Filter by client. */
  client?: InputMaybe<QueryByIdInput>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<EventsOwnAndRelatedOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<EventsOwnAndRelatedWhereInput>;
};

/** Quering input data for "History" sub-entities. */
export type QueryHistoriesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<HistoriesOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<HistoriesWhereInput>;
};

/** Quering input data for "Medication" entities. */
export type QueryMedicationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MedicationsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<MedicationsWhereInput>;
};

/**  Quering input data for emailing worked periods to "Member" entities. */
export type QueryMemberClockedPeriodsInput = {
  /** Period starting datetime. */
  from: Scalars['DateTime'];
  /** Entity ID. */
  id: Scalars['ID'];
  /** Period ending datetime. */
  to: Scalars['DateTime'];
};

/** Quering input data for "MemberFile" entities. */
export type QueryMemberFilesInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberFilesOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<MemberFilesWhereInput>;
};

/** Quering input data for "MemberInvitationSender" entities. */
export type QueryMemberInvitationSendersInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberInvitationSendersOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<MemberInvitationSendersWhereInput>;
};

/** Quering input data for "MemberInvitation" entities. */
export type QueryMemberInvitationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberInvitationsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<MemberInvitationsWhereInput>;
};

/** Quering input data for "MemberSchedule" objects. */
export type QueryMemberScheduleInput = {
  /** Period ending datetime. */
  endAt: Scalars['DateTime'];
  /** Period starting datetime. */
  startAt: Scalars['DateTime'];
};

/** Quering input data for "MemberUnavailable" entities. */
export type QueryMemberUnavailablesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberUnavailablesOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<MemberUnavailablesWhereInput>;
};

/** Quering input data for "Member" entities. */
export type QueryMembersInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MembersOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<MembersWhereInput>;
};

/** Quering input data for "MemberUnavailable" entities. */
export type QueryMyMemberUnavailablesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberUnavailablesOrderByInput>>;
};

/** Quering input data for "Notification" entities. */
export type QueryNotificationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<NotificationsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<NotificationsWhereInput>;
};

/** Quering input data for "Org" entities. */
export type QueryOrgsInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<OrgsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<OrgsWhereInput>;
};

/** Quering input data for "PlanManager" entities. */
export type QueryPlanManagersInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<PlanManagersOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<PlanManagersWhereInput>;
};

/** Quering input data for "PublicHoliday" entities. */
export type QueryPublicHolidaysInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<PublicHolidaysOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<PublicHolidaysWhereInput>;
};

/** Quering input data for "RedactedClient" entities. */
export type QueryRedactedClientsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<RedactedClientsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<RedactedClientsWhereInput>;
};

/** Quering input data for "RedactedMember" entities. */
export type QueryRedactedMembersInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<RedactedMembersOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<RedactedMembersWhereInput>;
};

/** Quering input data for the "Schedule" entity. */
export type QueryScheuleMembersInput = {
  /** Period starting datetime. */
  date: Scalars['DateTime'];
  /** Members. */
  members: QueryMembersInput;
};

/** Quering input data for "SupportCoordinator" entities. */
export type QuerySupportCoordinatorsInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<SupportCoordinatorsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<SupportCoordinatorsWhereInput>;
};

/** Quering input data for "UserLogin" entities. */
export type QueryUserLoginsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<UserLoginsOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<UserLoginsWhereInput>;
};

/** Quering input data for "VisaType" entities. */
export type QueryVisaTypesInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<VisaTypesOrderByInput>>;
  /** Query conditions. */
  where?: InputMaybe<VisaTypesWhereInput>;
};

/** A redacted client of an organisation. */
export type RedactedClient = Address &
  Person & {
    __typename?: 'RedactedClient';
    /** Age in years. */
    age?: Maybe<Scalars['Int']>;
    /** Allow automatic population of data for unattended future events. */
    allowAutomaticEventPopulation: Scalars['Boolean'];
    /** Allow recording of medication. */
    allowMedication: Scalars['Boolean'];
    /** Allow recording of observations. */
    allowObservations: Scalars['Boolean'];
    /** Diastolic blood pressure lower-limit warning threshold (level 1). */
    bloodDiastolicLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Diastolic blood pressure lower-limit warning threshold (level 2). */
    bloodDiastolicLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Diastolic blood pressure upper-limit warning threshold (level 1). */
    bloodDiastolicUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Diastolic blood pressure upper-limit warning threshold (level 2). */
    bloodDiastolicUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure lower-limit warning threshold (level 1). */
    bloodSystolicLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure lower-limit warning threshold (level 2). */
    bloodSystolicLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure upper-limit warning threshold (level 1). */
    bloodSystolicUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure upper-limit warning threshold (level 2). */
    bloodSystolicUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Hexidecimal color. */
    color?: Maybe<Scalars['HexColorCode']>;
    /** Address country. */
    country?: Maybe<AddressCountryCode>;
    /** First name. */
    firstName: Scalars['String'];
    /** Gender. */
    gender?: Maybe<Gender>;
    /** Heart rate lower-limit warning threshold (level 1). */
    heartLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Heart rate lower-limit warning threshold. (level 2) */
    heartLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Heart rate upper-limit warning threshold (level 1). */
    heartUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Heart rate upper-limit warning threshold (level 2). */
    heartUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Unique identifier of the entity. */
    id: Scalars['ID'];
    /** Last name. */
    lastName?: Maybe<Scalars['String']>;
    /** Address latitude coordinate. */
    latitude?: Maybe<Scalars['String']>;
    /** Address suburb or town. */
    locality?: Maybe<Scalars['String']>;
    /** Address longitude coordinate. */
    longitude?: Maybe<Scalars['String']>;
    medications?: Maybe<Array<ClientMedication>>;
    /** Middle name. */
    middleName?: Maybe<Scalars['String']>;
    /** Address postcode. */
    postcode?: Maybe<Scalars['PostalCode']>;
    /** Preferred name. */
    preferredName?: Maybe<Scalars['String']>;
    /** Pronouns. */
    pronouns?: Maybe<Pronouns>;
    /** Address state or territory. */
    region?: Maybe<AddressRegionCode>;
    /** Respiration lower-limit warning threshold. (level 1) */
    respirationLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Respiration lower-limit warning threshold. (level 2) */
    respirationLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Respiration upper-limit warning threshold. (level 1) */
    respirationUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Respiration upper-limit warning threshold. (level 2) */
    respirationUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Salutation. */
    salutation?: Maybe<Salutation>;
    /** Shortened name. */
    shortName: Scalars['String'];
    /** Spo2 lower-limit warning threshold. (level 1) */
    spo2LowerThreshold1?: Maybe<Scalars['Int']>;
    /** Spo2 lower-limit warning threshold. (level 2) */
    spo2LowerThreshold2?: Maybe<Scalars['Int']>;
    /** Spo2 upper-limit warning threshold. (level 1) */
    spo2UpperThreshold1?: Maybe<Scalars['Int']>;
    /** Spo2 upper-limit warning threshold. (level 2) */
    spo2UpperThreshold2?: Maybe<Scalars['Int']>;
    /** Address street number, name and type. */
    street?: Maybe<Scalars['String']>;
    /** Address unit number. */
    unit?: Maybe<Scalars['String']>;
  };

/** A redacted client of an organisation. */
export type RedactedClientMedicationsArgs = {
  input?: InputMaybe<QueryClientMedicationsInput>;
};

/** Aggregated response object for "RedactedClient" entities. */
export type RedactedClientsAggregate = {
  __typename?: 'RedactedClientsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<RedactedClient>;
};

/** Ordering options for "RedactedClient" entities. */
export type RedactedClientsOrderByInput = {
  /** Order by address country. */
  country?: InputMaybe<OrderBy>;
  /** Order by first name. */
  firstName?: InputMaybe<OrderBy>;
  /** Order by gender. */
  gender?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by last name. */
  lastName?: InputMaybe<OrderBy>;
  /** Order by address latitude. */
  latitude?: InputMaybe<OrderBy>;
  /** Order by address locality. */
  locality?: InputMaybe<OrderBy>;
  /** Order by address longitude. */
  longitude?: InputMaybe<OrderBy>;
  /** Order by middle name. */
  middleName?: InputMaybe<OrderBy>;
  /** Order by address postcode. */
  postcode?: InputMaybe<OrderBy>;
  /** Order by preferred name. */
  preferredName?: InputMaybe<OrderBy>;
  /** Order by address region. */
  region?: InputMaybe<OrderBy>;
  /** Order by address street. */
  street?: InputMaybe<OrderBy>;
  /** Order by address unit. */
  unit?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "RedactedClient" entities. */
export type RedactedClientsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<RedactedClientsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<RedactedClientsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<RedactedClientsWhereInput>>;
  /** Color. */
  color?: InputMaybe<StringConditionalInput>;
  /** Address country. */
  country?: InputMaybe<StringConditionalInput>;
  /** First name. */
  firstName?: InputMaybe<StringConditionalInput>;
  /** Gender. */
  gender?: InputMaybe<IntConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Last name. */
  lastName?: InputMaybe<StringConditionalInput>;
  /** Address latitude. */
  latitude?: InputMaybe<StringConditionalInput>;
  /** Address locality. */
  locality?: InputMaybe<StringConditionalInput>;
  /** Address longitude. */
  longitude?: InputMaybe<StringConditionalInput>;
  /** Middle name. */
  middleName?: InputMaybe<StringConditionalInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Address postcode. */
  postcode?: InputMaybe<StringConditionalInput>;
  /** Preferred name. */
  preferredName?: InputMaybe<StringConditionalInput>;
  /** Pronouns. */
  pronouns?: InputMaybe<IntConditionalInput>;
  /** Address region. */
  region?: InputMaybe<StringConditionalInput>;
  /** Salutation. */
  salutation?: InputMaybe<IntConditionalInput>;
  /** Address street. */
  street?: InputMaybe<StringConditionalInput>;
  /** Address unit. */
  unit?: InputMaybe<StringConditionalInput>;
};

/** A redacted member of an organisation. */
export type RedactedMember = Person & {
  __typename?: 'RedactedMember';
  /** Hexidecimal color. */
  color?: Maybe<Scalars['HexColorCode']>;
  /** Email address. */
  email?: Maybe<Scalars['String']>;
  /** First name. */
  firstName: Scalars['String'];
  /** Gender. */
  gender?: Maybe<Gender>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Last name. */
  lastName?: Maybe<Scalars['String']>;
  /** Middle name. */
  middleName?: Maybe<Scalars['String']>;
  /** Phone number. */
  phone?: Maybe<Scalars['String']>;
  /** Preferred name. */
  preferredName?: Maybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: Maybe<Pronouns>;
  /** Salutation. */
  salutation?: Maybe<Salutation>;
  /** Shortened name. */
  shortName: Scalars['String'];
};

/** Aggregated response object for "RedactedMember" entities. */
export type RedactedMembersAggregate = {
  __typename?: 'RedactedMembersAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<RedactedMember>;
};

/** Ordering options for "RedactedMember" entities. */
export type RedactedMembersOrderByInput = {
  /** Order by first name. */
  firstName?: InputMaybe<OrderBy>;
  /** Order by gender. */
  gender?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by last name. */
  lastName?: InputMaybe<OrderBy>;
  /** Order by middle name. */
  middleName?: InputMaybe<OrderBy>;
  /** Order by preferred name. */
  preferredName?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "RedactedMember" entities. */
export type RedactedMembersWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<RedactedMembersWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<RedactedMembersWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<RedactedMembersWhereInput>>;
  /** Color. */
  color?: InputMaybe<StringConditionalInput>;
  /** Email address. */
  email?: InputMaybe<StringConditionalInput>;
  /** First name. */
  firstName?: InputMaybe<StringConditionalInput>;
  /** Gender. */
  gender?: InputMaybe<IntConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Last name. */
  lastName?: InputMaybe<StringConditionalInput>;
  /** Middle name. */
  middleName?: InputMaybe<StringConditionalInput>;
  /** Phone number. */
  phone?: InputMaybe<PhoneConditionalInput>;
  /** Preferred name. */
  preferredName?: InputMaybe<StringConditionalInput>;
  /** Pronouns. */
  pronouns?: InputMaybe<IntConditionalInput>;
  /** Salutation. */
  salutation?: InputMaybe<IntConditionalInput>;
};

/** User identifier verification resend input */
export type ResendUserIdentifierVerificationInput = {
  /** Type of identifier whose verification token is to be resent */
  type: UserIdentifierType;
};

/** User password-reset input data. */
export type ResetUserPasswordInput = {
  /** Email address or phone number. */
  identifier: Scalars['UserIdentifier'];
};

/** Salutation */
export enum Salutation {
  /** Dr */
  DR = 'DR',
  /** Master */
  MASTER = 'MASTER',
  /** Miss */
  MISS = 'MISS',
  /** Mr */
  MR = 'MR',
  /** Mrs */
  MRS = 'MRS',
  /** Ms */
  MS = 'MS',
  /** Mx */
  MX = 'MX',
  /** Not specified */
  NOTSPECIFIED = 'NOTSPECIFIED',
}

export type Schedule = {
  __typename?: 'Schedule';
  /** Events. */
  events?: Maybe<Array<ScheduleEvent>>;
  /** Members. */
  members?: Maybe<Array<ScheduleMember>>;
};

export type ScheduleEventsArgs = {
  input?: InputMaybe<QueryEventsInput>;
};

export type ScheduleMembersArgs = {
  input?: InputMaybe<QueryScheuleMembersInput>;
};

export type ScheduleEvent = {
  __typename?: 'ScheduleEvent';
  /** Conflict reason. */
  conflictReason?: Maybe<Scalars['String']>;
  /** Event. */
  event: Event;
};

export type ScheduleMember = {
  __typename?: 'ScheduleMember';
  /** Current shift. */
  currentEvent?: Maybe<Event>;
  /** Fortnight scheduled minutes. */
  fortnightTime: Scalars['Int'];
  /** Member. */
  member: Member;
  /** Next shift. */
  nextEvent?: Maybe<Event>;
  /** Week scheduled minutes. */
  weekTime: Scalars['Int'];
};

/** Input data for setting a clients medication. */
export type SetClientMedicationsInput = {
  /** Client input data. */
  client: QueryByIdInput;
  /** Medication ids. */
  medication: QueryByIdsInput;
};

/** Input data for setting which "Member" entities belong to a given "Client" entity's team. */
export type SetClientTeamMembersInput = {
  /** Client input data. */
  client: QueryByIdInput;
  objects: Array<ClientTeamsSettableFieldsInput>;
};

export type SetUserPasswordInput = {
  /** New password. */
  password: Scalars['UserPlainTextPassword'];
  /** Verification token. */
  token: Scalars['UUID'];
};

/** Bristol Stool Chart Type. */
export enum StoolType {
  NIL = 'NIL',
  /** Type 1 */
  TYPE1 = 'TYPE1',
  /** Type 2 */
  TYPE2 = 'TYPE2',
  /** Type 3 */
  TYPE3 = 'TYPE3',
  /** Type 4 */
  TYPE4 = 'TYPE4',
  /** Type 5 */
  TYPE5 = 'TYPE5',
  /** Type 6 */
  TYPE6 = 'TYPE6',
  /** Type 7 */
  TYPE7 = 'TYPE7',
}

/** String conditional expression input. */
export type StringConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['String']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['String']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['String']>;
  /** Case-insensitive pattern matching. */
  _ilike?: InputMaybe<Scalars['String']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** Pattern matching. */
  _like?: InputMaybe<Scalars['String']>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['String']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['String']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['String']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['String']>>;
};

/** A support coordinator for NDIS participants. */
export type SupportCoordinator = {
  __typename?: 'SupportCoordinator';
  /** Australian Business Number of the organisation */
  abn?: Maybe<Scalars['ABN']>;
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Billing email address. */
  businessEmail?: Maybe<Scalars['String']>;
  /** Business name. */
  businessName?: Maybe<Scalars['String']>;
  /** Phone number. */
  businessPhone?: Maybe<Scalars['String']>;
  clients?: Maybe<Array<Client>>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Contact email address. */
  email?: Maybe<Scalars['String']>;
  /** First name. */
  firstName: Scalars['String'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Last name. */
  lastName?: Maybe<Scalars['String']>;
  /** Middle name. */
  middleName?: Maybe<Scalars['String']>;
  /** Notes. */
  notes?: Maybe<Scalars['String']>;
  /** Organisation to which this support coordinator belongs. */
  org: Org;
  /** Phone number. */
  phone?: Maybe<Scalars['String']>;
  /** Preferred name. */
  preferredName?: Maybe<Scalars['String']>;
  /** Salutation. */
  salutation?: Maybe<Salutation>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** A support coordinator for NDIS participants. */
export type SupportCoordinatorClientsArgs = {
  input?: InputMaybe<QueryClientsInput>;
};

/** Aggregated response object for "SupportCoordinator" entities. */
export type SupportCoordinatorsAggregate = {
  __typename?: 'SupportCoordinatorsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<SupportCoordinator>;
};

/** Creatable fields for "SupportCoordinator" entities. */
export type SupportCoordinatorsCreatableFieldsInput = {
  /** Australian Business Number of the organisation */
  abn?: InputMaybe<Scalars['ABN']>;
  /** Billing email address. */
  businessEmail?: InputMaybe<Scalars['String']>;
  /** Business name. */
  businessName?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  businessPhone?: InputMaybe<Scalars['String']>;
  /** Clients input data. */
  clients?: InputMaybe<QueryByIdsInput>;
  /** Contact email address. */
  email?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName: Scalars['String'];
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
};

/** Ordering options for "SupportCoordinator" entities. */
export type SupportCoordinatorsOrderByInput = {
  /** Order by Australian Business Number. */
  abn?: InputMaybe<OrderBy>;
  /** Order by business name. */
  businessName?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by first name. */
  firstName?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by last name. */
  lastName?: InputMaybe<OrderBy>;
  /** Order by middle name. */
  middleName?: InputMaybe<OrderBy>;
  /** Order by preferred name. */
  preferredName?: InputMaybe<OrderBy>;
};

/** Updatable fields for "SupportCoordinator" entities. */
export type SupportCoordinatorsUpdatableFieldsInput = {
  /** Australian Business Number of the organisation */
  abn?: InputMaybe<Scalars['ABN']>;
  /** Billing email address. */
  businessEmail?: InputMaybe<Scalars['String']>;
  /** Business name. */
  businessName?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  businessPhone?: InputMaybe<Scalars['String']>;
  /** Clients input data. */
  clients?: InputMaybe<QueryByIdsInput>;
  /** Contact email address. */
  email?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
};

/** Filtering conditions for "SupportCoordinator" entities. */
export type SupportCoordinatorsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<SupportCoordinatorsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<SupportCoordinatorsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<SupportCoordinatorsWhereInput>>;
  /** Australian Business Number. */
  abn?: InputMaybe<AbnConditionalInput>;
  /** Business email address. */
  businessEmail?: InputMaybe<StringConditionalInput>;
  /** Business name. */
  businessName?: InputMaybe<StringConditionalInput>;
  /** Business phone number. */
  businessPhone?: InputMaybe<PhoneConditionalInput>;
  /** Clients. */
  clients?: InputMaybe<ClientsWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Email address. */
  email?: InputMaybe<StringConditionalInput>;
  /** First name. */
  firstName?: InputMaybe<StringConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Last name. */
  lastName?: InputMaybe<StringConditionalInput>;
  /** Middle name. */
  middleName?: InputMaybe<StringConditionalInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Phone number. */
  phone?: InputMaybe<PhoneConditionalInput>;
  /** Preferred name. */
  preferredName?: InputMaybe<StringConditionalInput>;
  /** Salutation. */
  salutation?: InputMaybe<IntConditionalInput>;
};

/** Input data for updating "Branch" entities. */
export type UpdateBranchesInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: BranchesUpdatableFieldsInput;
};

/** Input data for updating "ClientFile" entities. */
export type UpdateClientFilesInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: ClientFilesUpdatableFieldsInput;
};

/** Input data for updating a "ClientObservation" entity. */
export type UpdateClientObservationInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to the discovered entity. */
  patch: ClientObservationsUpdatableFieldsInput;
};

/** Input data for updating "Client" entities. */
export type UpdateClientsInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: ClientsUpdatableFieldsInput;
};

/** Input data for updating the basic fields of an "Event" entity. */
export type UpdateEventBasicFieldsInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: EventsUpdatableBasicFieldsInput;
};

/** Input data for updating "EventFile" entities. */
export type UpdateEventFilesInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: EventFilesUpdatableFieldsInput;
};

/** Input data for updating an "Event" entity. */
export type UpdateEventInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: EventsUpdatableFieldsInput;
  /** Unset clocked off data. */
  unsetClockedOff?: InputMaybe<Scalars['Boolean']>;
  /** Unset clocked on data. */
  unsetClockedOn?: InputMaybe<Scalars['Boolean']>;
};

/** Input data for updating a "Medication" entity. */
export type UpdateMedicationInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to the discovered entity. */
  patch: MedicationsUpdatableFieldsInput;
};

/** Input data for updating the current users current "Member" entity. */
export type UpdateMemberCurrentInput = {
  /** Update to apply to the discovered entity. */
  patch: MembersUpdatableFieldsInput;
};

/** Input data for updating "MemberFile" entities. */
export type UpdateMemberFilesInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: MemberFilesUpdatableFieldsInput;
};

/** Input data for updating "Member" entities. */
export type UpdateMembersInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: MembersUpdatableFieldsInput;
};

/** Input data for updating an "Org" entity. */
export type UpdateOrgInput = {
  /** Update to apply to discovered entity. */
  patch: OrgsUpdatableFieldsInput;
};

/** Input data for updating "PlanManager" entities. */
export type UpdatePlanManagersInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: PlanManagersUpdatableFieldsInput;
};

/** Input data for updating "SupportCoordinator" entities. */
export type UpdateSupportCoordinatorsInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: SupportCoordinatorsUpdatableFieldsInput;
};

/** Input data for updating the current user. */
export type UpdateUserInput = {
  /** Updated data to apply to the current user. */
  patch: UserUpdatableFieldsInput;
};

/** Input data for updating singular "VisaType" entities. */
export type UpdateVisaTypeInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: VisaTypesUpdatableFieldsInput;
};

/** Urine color. */
export enum UrineColor {
  /** Brown */
  BROWN = 'BROWN',
  /** Clear */
  CLEAR = 'CLEAR',
  /** Dark yellow */
  DARKYELLOW = 'DARKYELLOW',
  /** Light yellow */
  LIGHTYELLOW = 'LIGHTYELLOW',
  /** Yellow */
  YELLOW = 'YELLOW',
}

/** A user account */
export type User = Person & {
  __typename?: 'User';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Current password data. */
  currentPassword: UserPassword;
  /** Date of birth. */
  dob?: Maybe<Scalars['Date']>;
  /** Email address */
  email?: Maybe<Scalars['String']>;
  /** Datetime of when email address was last set. */
  emailSetAt?: Maybe<Scalars['DateTime']>;
  /** Datetime of successful email address verification. */
  emailVerifiedAt?: Maybe<Scalars['DateTime']>;
  /** First name */
  firstName: Scalars['String'];
  /** Gender. */
  gender?: Maybe<Gender>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Last name */
  lastName?: Maybe<Scalars['String']>;
  /** Recent successful user account logins. */
  logins: Array<UserLogin>;
  /** User's mailing list preferrence. */
  mailingList: Scalars['Boolean'];
  /** User's currently-active member profile. */
  member?: Maybe<Member>;
  /** Member profiles belonging to this user. */
  members: Array<Member>;
  /** Middle name */
  middleName?: Maybe<Scalars['String']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']>;
  /** Datetime of when phone number was last set. */
  phoneSetAt?: Maybe<Scalars['DateTime']>;
  /** Datetime of successful phone number verification. */
  phoneVerifiedAt?: Maybe<Scalars['DateTime']>;
  /** Preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** Most recent successful log in. */
  previousLogin: UserLogin;
  /** Pronouns. */
  pronouns?: Maybe<Pronouns>;
  /** Salutation. */
  salutation?: Maybe<Salutation>;
  /** User's chosen application theme. */
  theme?: Maybe<UserTheme>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** A user account */
export type UserLoginsArgs = {
  input?: InputMaybe<QueryUserLoginsInput>;
};

/** A user account */
export type UserMembersArgs = {
  input?: InputMaybe<QueryMembersInput>;
};

/** Type of unique user identifier */
export enum UserIdentifierType {
  /** Email address */
  EMAIL = 'EMAIL',
  /** Phone number */
  PHONE = 'PHONE',
}

/** Record of successful user login */
export type UserLogin = {
  __typename?: 'UserLogin';
  /** Datetime of successful login */
  date: Scalars['DateTime'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** IP address of client during successful login */
  ip: Scalars['String'];
};

/** "User" entity login response object. */
export type UserLoginResponse = {
  __typename?: 'UserLoginResponse';
  /** User authentication JWT. */
  token: Scalars['JWT'];
};

/** Ordering options for "UserLogin" entities. */
export type UserLoginsOrderByInput = {
  /** Order by datetime of entity creation. */
  date?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by identifier used. */
  identifierUsed?: InputMaybe<OrderBy>;
  /** Order by ip address. */
  ip?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "UserLogin" entities. */
export type UserLoginsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<UserLoginsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<UserLoginsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<UserLoginsWhereInput>>;
  /** Datetime of entitys creation. */
  date?: InputMaybe<DateTimeConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Identifier used. */
  identifierUsed?: InputMaybe<StringConditionalInput>;
  /** IP address used. */
  ip?: InputMaybe<StringConditionalInput>;
};

/** Current password data for "User" entities. */
export type UserPassword = {
  __typename?: 'UserPassword';
  /** Date password was set. */
  date: Scalars['DateTime'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** IP address of the user who set the password. */
  ip: Scalars['String'];
};

/** User's chosen application theme. */
export enum UserTheme {
  /** Dark theme. */
  DARK = 'DARK',
  /** Light theme. */
  LIGHT = 'LIGHT',
}

/** Update user patch data. */
export type UserUpdatableFieldsInput = {
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Email address */
  email?: InputMaybe<Scalars['String']>;
  /** First name */
  firstName?: InputMaybe<Scalars['String']>;
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** User's mailing list preferrence. */
  mailingList?: InputMaybe<Scalars['Boolean']>;
  /** Middle name */
  middleName?: InputMaybe<Scalars['String']>;
  /** Passwords must be at least 8 characters in length and contain a combination of lowercase, uppercase and special characters */
  password?: InputMaybe<Scalars['UserPlainTextPassword']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred name */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** User's chosen application theme. */
  theme?: InputMaybe<UserTheme>;
};

/** User email address or phone number verification input */
export type VerifyUserIdentifierInput = {
  /** Verification token */
  token: Scalars['UUID'];
};

export type VisaType = {
  __typename?: 'VisaType';
  /** Allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: Maybe<Scalars['Int']>;
  /** Allowed worked hours is waived. */
  allowedWorkHoursWaived: Scalars['Boolean'];
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Description. */
  description?: Maybe<Scalars['String']>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Name of VISA. */
  name: Scalars['String'];
  /** Organisation. */
  org: Org;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Aggregated response object for "VisaType" entities. */
export type VisaTypesAggregate = {
  __typename?: 'VisaTypesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<VisaType>;
};

/** Ordering options for "VisaType" entities. */
export type VisaTypesOrderByInput = {
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by name. */
  name?: InputMaybe<OrderBy>;
};

/** Updatable fields for "VisaType" entities. */
export type VisaTypesUpdatableFieldsInput = {
  /** Allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: InputMaybe<Scalars['Int']>;
  /** Allowed worked hours is waived. */
  allowedWorkHoursWaived?: InputMaybe<Scalars['Boolean']>;
  /** Description. */
  description?: InputMaybe<Scalars['String']>;
  /** Name of VISA. */
  name?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "VisaType" entities. */
export type VisaTypesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<VisaTypesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<VisaTypesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<VisaTypesWhereInput>>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Name. */
  name?: InputMaybe<StringConditionalInput>;
};

type AddressFull_Client_Fragment = {
  __typename?: 'Client';
  unit?: string | null;
  street?: string | null;
  locality?: string | null;
  region?: AddressRegionCode | null;
  postcode?: any | null;
  country?: AddressCountryCode | null;
};

type AddressFull_Event_Fragment = {
  __typename?: 'Event';
  unit?: string | null;
  street?: string | null;
  locality?: string | null;
  region?: AddressRegionCode | null;
  postcode?: any | null;
  country?: AddressCountryCode | null;
};

type AddressFull_EventOwnAndRelated_Fragment = {
  __typename?: 'EventOwnAndRelated';
  unit?: string | null;
  street?: string | null;
  locality?: string | null;
  region?: AddressRegionCode | null;
  postcode?: any | null;
  country?: AddressCountryCode | null;
};

type AddressFull_Member_Fragment = {
  __typename?: 'Member';
  unit?: string | null;
  street?: string | null;
  locality?: string | null;
  region?: AddressRegionCode | null;
  postcode?: any | null;
  country?: AddressCountryCode | null;
};

type AddressFull_RedactedClient_Fragment = {
  __typename?: 'RedactedClient';
  unit?: string | null;
  street?: string | null;
  locality?: string | null;
  region?: AddressRegionCode | null;
  postcode?: any | null;
  country?: AddressCountryCode | null;
};

export type AddressFullFragment =
  | AddressFull_Client_Fragment
  | AddressFull_Event_Fragment
  | AddressFull_EventOwnAndRelated_Fragment
  | AddressFull_Member_Fragment
  | AddressFull_RedactedClient_Fragment;

type AddressGeolocation_Client_Fragment = {
  __typename?: 'Client';
  latitude?: string | null;
  longitude?: string | null;
};

type AddressGeolocation_Event_Fragment = {
  __typename?: 'Event';
  latitude?: string | null;
  longitude?: string | null;
};

type AddressGeolocation_EventOwnAndRelated_Fragment = {
  __typename?: 'EventOwnAndRelated';
  latitude?: string | null;
  longitude?: string | null;
};

type AddressGeolocation_Member_Fragment = {
  __typename?: 'Member';
  latitude?: string | null;
  longitude?: string | null;
};

type AddressGeolocation_RedactedClient_Fragment = {
  __typename?: 'RedactedClient';
  latitude?: string | null;
  longitude?: string | null;
};

export type AddressGeolocationFragment =
  | AddressGeolocation_Client_Fragment
  | AddressGeolocation_Event_Fragment
  | AddressGeolocation_EventOwnAndRelated_Fragment
  | AddressGeolocation_Member_Fragment
  | AddressGeolocation_RedactedClient_Fragment;

export type BusinessNamesFragment = {
  __typename?: 'Org';
  legalName: string;
  preferredName?: string | null;
};

type PersonGenderIdentifiers_Client_Fragment = {
  __typename?: 'Client';
  gender?: Gender | null;
  salutation?: Salutation | null;
  pronouns?: Pronouns | null;
};

type PersonGenderIdentifiers_Member_Fragment = {
  __typename?: 'Member';
  gender?: Gender | null;
  salutation?: Salutation | null;
  pronouns?: Pronouns | null;
};

type PersonGenderIdentifiers_RedactedClient_Fragment = {
  __typename?: 'RedactedClient';
  gender?: Gender | null;
  salutation?: Salutation | null;
  pronouns?: Pronouns | null;
};

type PersonGenderIdentifiers_RedactedMember_Fragment = {
  __typename?: 'RedactedMember';
  gender?: Gender | null;
  salutation?: Salutation | null;
  pronouns?: Pronouns | null;
};

type PersonGenderIdentifiers_User_Fragment = {
  __typename?: 'User';
  gender?: Gender | null;
  salutation?: Salutation | null;
  pronouns?: Pronouns | null;
};

export type PersonGenderIdentifiersFragment =
  | PersonGenderIdentifiers_Client_Fragment
  | PersonGenderIdentifiers_Member_Fragment
  | PersonGenderIdentifiers_RedactedClient_Fragment
  | PersonGenderIdentifiers_RedactedMember_Fragment
  | PersonGenderIdentifiers_User_Fragment;

type PersonNames_Client_Fragment = {
  __typename?: 'Client';
  middleName?: string | null;
  preferredName?: string | null;
  firstName: string;
  lastName?: string | null;
};

type PersonNames_Member_Fragment = {
  __typename?: 'Member';
  middleName?: string | null;
  preferredName?: string | null;
  firstName: string;
  lastName?: string | null;
};

type PersonNames_RedactedClient_Fragment = {
  __typename?: 'RedactedClient';
  middleName?: string | null;
  preferredName?: string | null;
  firstName: string;
  lastName?: string | null;
};

type PersonNames_RedactedMember_Fragment = {
  __typename?: 'RedactedMember';
  middleName?: string | null;
  preferredName?: string | null;
  firstName: string;
  lastName?: string | null;
};

type PersonNames_User_Fragment = {
  __typename?: 'User';
  middleName?: string | null;
  preferredName?: string | null;
  firstName: string;
  lastName?: string | null;
};

export type PersonNamesFragment =
  | PersonNames_Client_Fragment
  | PersonNames_Member_Fragment
  | PersonNames_RedactedClient_Fragment
  | PersonNames_RedactedMember_Fragment
  | PersonNames_User_Fragment;

type PersonNamesPartial_Client_Fragment = {
  __typename?: 'Client';
  firstName: string;
  lastName?: string | null;
};

type PersonNamesPartial_Member_Fragment = {
  __typename?: 'Member';
  firstName: string;
  lastName?: string | null;
};

type PersonNamesPartial_RedactedClient_Fragment = {
  __typename?: 'RedactedClient';
  firstName: string;
  lastName?: string | null;
};

type PersonNamesPartial_RedactedMember_Fragment = {
  __typename?: 'RedactedMember';
  firstName: string;
  lastName?: string | null;
};

type PersonNamesPartial_User_Fragment = {
  __typename?: 'User';
  firstName: string;
  lastName?: string | null;
};

export type PersonNamesPartialFragment =
  | PersonNamesPartial_Client_Fragment
  | PersonNamesPartial_Member_Fragment
  | PersonNamesPartial_RedactedClient_Fragment
  | PersonNamesPartial_RedactedMember_Fragment
  | PersonNamesPartial_User_Fragment;

export type CreateBranchesMutationVariables = Exact<{
  input: CreateBranchesInput;
}>;

export type CreateBranchesMutation = {
  __typename?: 'Mutation';
  createBranches: Array<{ __typename?: 'Branch'; id: string; name: string }>;
};

export type UpdateBranchesMutationVariables = Exact<{
  input: UpdateBranchesInput;
}>;

export type UpdateBranchesMutation = {
  __typename?: 'Mutation';
  updateBranches: Array<{ __typename?: 'Branch'; id: string; name: string }>;
};

export type CreateClientFileMutationVariables = Exact<{
  input: CreateClientFileInput;
}>;

export type CreateClientFileMutation = {
  __typename?: 'Mutation';
  createClientFile: {
    __typename?: 'ClientFile';
    id: string;
    type: ClientFileType;
    dateOfFile?: any | null;
    expiresAt?: any | null;
    value?: string | null;
    owner: { __typename?: 'Client'; id: string };
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      path: string;
      createdBy: { __typename?: 'Member'; id: string };
    };
  };
};

export type DeleteClientFilesMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientFilesMutation = {
  __typename?: 'Mutation';
  deleteClientFiles: Array<{
    __typename?: 'ClientFile';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreClientFilesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreClientFilesMutation = {
  __typename?: 'Mutation';
  restoreClientFiles: Array<{
    __typename?: 'ClientFile';
    id: string;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type UpdateClientFilesMutationVariables = Exact<{
  input: UpdateClientFilesInput;
}>;

export type UpdateClientFilesMutation = {
  __typename?: 'Mutation';
  updateClientFiles: Array<{
    __typename?: 'ClientFile';
    id: string;
    type: ClientFileType;
    dateOfFile?: any | null;
    expiresAt?: any | null;
    value?: string | null;
    owner: { __typename?: 'Client'; id: string };
  }>;
};

export type SetClientMedicationsMutationVariables = Exact<{
  input: SetClientMedicationsInput;
}>;

export type SetClientMedicationsMutation = {
  __typename?: 'Mutation';
  setClientMedications: Array<{
    __typename?: 'ClientMedication';
    id: string;
    client: { __typename?: 'Client'; id: string };
    medication: { __typename?: 'Medication'; id: string };
  }>;
};

export type CreateClientObservationMutationVariables = Exact<{
  input: CreateClientObservationInput;
}>;

export type CreateClientObservationMutation = {
  __typename?: 'Mutation';
  createClientObservation: {
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    notes?: string | null;
    nursesName?: string | null;
    bloodSystolic?: number | null;
    bloodDiastolic?: number | null;
    heart?: number | null;
    temp?: number | null;
    spo2?: number | null;
    respiration?: number | null;
    stoolType?: StoolType | null;
    urineColor?: UrineColor | null;
    givenFood?: Array<{
      __typename?: 'ClientObservationFood';
      id: string;
      description?: string | null;
      kilojoules?: number | null;
    }> | null;
    givenMedications?: Array<{
      __typename?: 'ClientObservationMedication';
      id: string;
      quantity?: string | null;
      medication: { __typename?: 'Medication'; id: string; name: string };
    }> | null;
    client: { __typename?: 'Client'; id: string };
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type CreateClientObservationWithEventMutationVariables = Exact<{
  input: CreateClientObservationWithEventInput;
}>;

export type CreateClientObservationWithEventMutation = {
  __typename?: 'Mutation';
  createClientObservationWithEvent: {
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    notes?: string | null;
    nursesName?: string | null;
    bloodSystolic?: number | null;
    bloodDiastolic?: number | null;
    heart?: number | null;
    temp?: number | null;
    spo2?: number | null;
    respiration?: number | null;
    stoolType?: StoolType | null;
    urineColor?: UrineColor | null;
    givenFood?: Array<{
      __typename?: 'ClientObservationFood';
      id: string;
      description?: string | null;
      kilojoules?: number | null;
    }> | null;
    givenMedications?: Array<{
      __typename?: 'ClientObservationMedication';
      id: string;
      quantity?: string | null;
      medication: { __typename?: 'Medication'; id: string; name: string };
    }> | null;
    event?: { __typename?: 'Event'; id: string } | null;
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type DeleteClientObservationsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientObservationsMutation = {
  __typename?: 'Mutation';
  deleteClientObservations: Array<{
    __typename?: 'ClientObservation';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateClientObservationMutationVariables = Exact<{
  input: UpdateClientObservationInput;
}>;

export type UpdateClientObservationMutation = {
  __typename?: 'Mutation';
  updateClientObservation: {
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    notes?: string | null;
    nursesName?: string | null;
    bloodSystolic?: number | null;
    bloodDiastolic?: number | null;
    heart?: number | null;
    temp?: number | null;
    spo2?: number | null;
    respiration?: number | null;
    stoolType?: StoolType | null;
    urineColor?: UrineColor | null;
    givenFood?: Array<{
      __typename?: 'ClientObservationFood';
      id: string;
      description?: string | null;
      kilojoules?: number | null;
    }> | null;
    givenMedications?: Array<{
      __typename?: 'ClientObservationMedication';
      id: string;
      quantity?: string | null;
      medication: { __typename?: 'Medication'; id: string; name: string };
    }> | null;
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type SetClientTeamMembersMutationVariables = Exact<{
  input: SetClientTeamMembersInput;
}>;

export type SetClientTeamMembersMutation = {
  __typename?: 'Mutation';
  setClientTeamMembers: Array<{
    __typename?: 'ClientTeam';
    id: string;
    preferred: boolean;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member: {
      __typename?: 'Member';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type ArchiveClientsMutationVariables = Exact<{
  input: ArchiveInput;
}>;

export type ArchiveClientsMutation = {
  __typename?: 'Mutation';
  archiveClients: Array<{
    __typename?: 'Client';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type CreateClientsMutationVariables = Exact<{
  input: CreateClientsInput;
}>;

export type CreateClientsMutation = {
  __typename?: 'Mutation';
  createClients: Array<{
    __typename?: 'Client';
    id: string;
    email?: string | null;
    phone?: string | null;
    dob?: any | null;
    color?: any | null;
    ndisId?: any | null;
    ndisPlanStartDate?: any | null;
    ndisPlanEndDate?: any | null;
    ndisPlanInitialFundsCore?: number | null;
    ndisPlanInitialFundsCs?: number | null;
    ndisPlanInitialFundsCb?: number | null;
    defaultEventDuration?: number | null;
    notes?: string | null;
    active: boolean;
    allowMedication: boolean;
    allowObservations: boolean;
    allowAutomaticEventPopulation: boolean;
    bloodSystolicUpperThreshold1?: number | null;
    bloodSystolicLowerThreshold1?: number | null;
    bloodDiastolicUpperThreshold1?: number | null;
    bloodDiastolicLowerThreshold1?: number | null;
    heartUpperThreshold1?: number | null;
    heartLowerThreshold1?: number | null;
    spo2UpperThreshold1?: number | null;
    spo2LowerThreshold1?: number | null;
    respirationUpperThreshold1?: number | null;
    respirationLowerThreshold1?: number | null;
    bloodSystolicUpperThreshold2?: number | null;
    bloodSystolicLowerThreshold2?: number | null;
    bloodDiastolicUpperThreshold2?: number | null;
    bloodDiastolicLowerThreshold2?: number | null;
    heartUpperThreshold2?: number | null;
    heartLowerThreshold2?: number | null;
    spo2UpperThreshold2?: number | null;
    spo2LowerThreshold2?: number | null;
    respirationUpperThreshold2?: number | null;
    respirationLowerThreshold2?: number | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    branch: { __typename?: 'Branch'; id: string; name: string };
    planManager?: { __typename?: 'PlanManager'; id: string; name: string } | null;
    supportCoordinator?: { __typename?: 'SupportCoordinator'; id: string } | null;
  }>;
};

export type DeleteClientsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientsMutation = {
  __typename?: 'Mutation';
  deleteClients: Array<{
    __typename?: 'Client';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreClientsMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreClientsMutation = {
  __typename?: 'Mutation';
  restoreClients: Array<{
    __typename?: 'Client';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateClientsMutationVariables = Exact<{
  input: UpdateClientsInput;
}>;

export type UpdateClientsMutation = {
  __typename?: 'Mutation';
  updateClients: Array<{
    __typename?: 'Client';
    id: string;
    email?: string | null;
    phone?: string | null;
    dob?: any | null;
    color?: any | null;
    ndisId?: any | null;
    ndisPlanStartDate?: any | null;
    ndisPlanEndDate?: any | null;
    ndisPlanInitialFundsCore?: number | null;
    ndisPlanInitialFundsCs?: number | null;
    ndisPlanInitialFundsCb?: number | null;
    defaultEventDuration?: number | null;
    notes?: string | null;
    active: boolean;
    allowMedication: boolean;
    allowObservations: boolean;
    allowAutomaticEventPopulation: boolean;
    contactsName?: string | null;
    contactsEmail?: string | null;
    contactsPhone?: string | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    branch: { __typename?: 'Branch'; id: string; name: string };
    planManager?: { __typename?: 'PlanManager'; id: string; name: string } | null;
    supportCoordinator?: { __typename?: 'SupportCoordinator'; id: string } | null;
  }>;
};

export type UpdateClientsColorsMutationVariables = Exact<{
  input: UpdateClientsInput;
}>;

export type UpdateClientsColorsMutation = {
  __typename?: 'Mutation';
  updateClients: Array<{ __typename?: 'Client'; id: string; color?: any | null }>;
};

export type UpdateClientsNotesMutationVariables = Exact<{
  input: UpdateClientsInput;
}>;

export type UpdateClientsNotesMutation = {
  __typename?: 'Mutation';
  updateClients: Array<{ __typename?: 'Client'; id: string; notes?: string | null }>;
};

export type UpdateClientsObservationThresholdsMutationVariables = Exact<{
  input: UpdateClientsInput;
}>;

export type UpdateClientsObservationThresholdsMutation = {
  __typename?: 'Mutation';
  updateClients: Array<{
    __typename?: 'Client';
    id: string;
    bloodSystolicUpperThreshold1?: number | null;
    bloodSystolicLowerThreshold1?: number | null;
    bloodDiastolicUpperThreshold1?: number | null;
    bloodDiastolicLowerThreshold1?: number | null;
    heartUpperThreshold1?: number | null;
    heartLowerThreshold1?: number | null;
    spo2UpperThreshold1?: number | null;
    spo2LowerThreshold1?: number | null;
    respirationUpperThreshold1?: number | null;
    respirationLowerThreshold1?: number | null;
    bloodSystolicUpperThreshold2?: number | null;
    bloodSystolicLowerThreshold2?: number | null;
    bloodDiastolicUpperThreshold2?: number | null;
    bloodDiastolicLowerThreshold2?: number | null;
    heartUpperThreshold2?: number | null;
    heartLowerThreshold2?: number | null;
    spo2UpperThreshold2?: number | null;
    spo2LowerThreshold2?: number | null;
    respirationUpperThreshold2?: number | null;
    respirationLowerThreshold2?: number | null;
  }>;
};

export type CreateEventFileMutationVariables = Exact<{
  input: CreateEventFileInput;
}>;

export type CreateEventFileMutation = {
  __typename?: 'Mutation';
  createEventFile: {
    __typename?: 'EventFile';
    id: string;
    type: EventFileType;
    dateOfFile?: any | null;
    value?: string | null;
    owner: { __typename?: 'Event'; id: string };
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      path: string;
      createdBy: { __typename?: 'Member'; id: string };
    };
  };
};

export type DeleteEventFilesMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteEventFilesMutation = {
  __typename?: 'Mutation';
  deleteEventFiles: Array<{
    __typename?: 'EventFile';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreEventFilesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreEventFilesMutation = {
  __typename?: 'Mutation';
  restoreEventFiles: Array<{
    __typename?: 'EventFile';
    id: string;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type UpdateEventFilesMutationVariables = Exact<{
  input: UpdateEventFilesInput;
}>;

export type UpdateEventFilesMutation = {
  __typename?: 'Mutation';
  updateEventFiles: Array<{
    __typename?: 'EventFile';
    id: string;
    type: EventFileType;
    dateOfFile?: any | null;
    value?: string | null;
    owner: { __typename?: 'Event'; id: string };
  }>;
};

export type ApproveEventsTravelMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type ApproveEventsTravelMutation = {
  __typename?: 'Mutation';
  approveEventsTravel: Array<{
    __typename?: 'Event';
    id: string;
    travelApprovedAt?: any | null;
    travelApprovedBy?: { __typename?: 'Member'; id: string } | null;
  }>;
};

export type ArchiveEventsMutationVariables = Exact<{
  input: ArchiveInput;
}>;

export type ArchiveEventsMutation = {
  __typename?: 'Mutation';
  archiveEvents: Array<{
    __typename?: 'Event';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type AutomaticallyAssignAttendeeMutationVariables = Exact<{
  input: QueryByIdInput;
}>;

export type AutomaticallyAssignAttendeeMutation = {
  __typename?: 'Mutation';
  updateEventAutomaticAttendee: {
    __typename?: 'Event';
    id: string;
    conflictExists?: boolean | null;
    memberAssignedAutomatically: boolean;
    scheduleNotes?: string | null;
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  };
};

export type CancelEventsMutationVariables = Exact<{
  input: EventsCancelInput;
}>;

export type CancelEventsMutation = {
  __typename?: 'Mutation';
  cancelEvents: Array<{
    __typename?: 'Event';
    id: string;
    billable: boolean;
    payable: boolean;
    cancel?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type ClaimEventMutationVariables = Exact<{
  input: QueryByIdInput;
}>;

export type ClaimEventMutation = {
  __typename?: 'Mutation';
  claimEvent: { __typename?: 'BooleanResponse'; value: boolean };
};

export type ClockOffEventMutationVariables = Exact<{
  input: ClockEventInput;
}>;

export type ClockOffEventMutation = {
  __typename?: 'Mutation';
  clockEvent: {
    __typename?: 'Event';
    id: string;
    clockedOffAt?: any | null;
    clockedOffLatitude?: string | null;
    clockedOffLongitude?: string | null;
    clockedOffBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  };
};

export type ClockOffEventAttendedMutationVariables = Exact<{
  input: ClockEventInput;
}>;

export type ClockOffEventAttendedMutation = {
  __typename?: 'Mutation';
  clockEventAttended: { __typename?: 'EventOwnAndRelated'; id: string; clockedOffAt?: any | null };
};

export type ClockOnEventMutationVariables = Exact<{
  input: ClockEventInput;
}>;

export type ClockOnEventMutation = {
  __typename?: 'Mutation';
  clockEvent: {
    __typename?: 'Event';
    id: string;
    clockedOnAt?: any | null;
    clockedOnLatitude?: string | null;
    clockedOnLongitude?: string | null;
    clockedOnBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  };
};

export type ClockOnEventAttendedMutationVariables = Exact<{
  input: ClockEventInput;
}>;

export type ClockOnEventAttendedMutation = {
  __typename?: 'Mutation';
  clockEventAttended: { __typename?: 'EventOwnAndRelated'; id: string; clockedOnAt?: any | null };
};

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;

export type CreateEventMutation = {
  __typename?: 'Mutation';
  createEvent: {
    __typename?: 'Event';
    id: string;
    published?: boolean | null;
    billable: boolean;
    payable: boolean;
    startAt: any;
    duration: number;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    summary?: string | null;
    publicHoliday?: boolean | null;
    conflictExists?: boolean | null;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    client: { __typename?: 'Client'; id: string };
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type DeleteEventsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteEventsMutation = {
  __typename?: 'Mutation';
  deleteEvents: Array<{
    __typename?: 'Event';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreEventsMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreEventsMutation = {
  __typename?: 'Mutation';
  restoreEvents: Array<{
    __typename?: 'Event';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateEventMutationVariables = Exact<{
  input: UpdateEventInput;
}>;

export type UpdateEventMutation = {
  __typename?: 'Mutation';
  updateEvent: {
    __typename?: 'Event';
    id: string;
    hasNotes: boolean;
    published?: boolean | null;
    billable: boolean;
    payable: boolean;
    startAt: any;
    endAt: any;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    duration: number;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    summary?: string | null;
    notes?: string | null;
    scheduleNotes?: string | null;
    travelDistance?: number | null;
    travelTime?: number | null;
    publicHoliday?: boolean | null;
    memberAssignedAutomatically: boolean;
    conflictExists?: boolean | null;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    clockedOnBy?: { __typename?: 'Member'; id: string } | null;
    clockedOffBy?: { __typename?: 'Member'; id: string } | null;
    client: { __typename?: 'Client'; id: string };
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type UpdateEventAttendedMutationVariables = Exact<{
  input: UpdateEventBasicFieldsInput;
}>;

export type UpdateEventAttendedMutation = {
  __typename?: 'Mutation';
  updateEventAttended: {
    __typename?: 'EventOwnAndRelated';
    id: string;
    hasNotes: boolean;
    notes?: string | null;
    travelDistance: number;
    travelTime: number;
  };
};

export type CreateMedicationMutationVariables = Exact<{
  input: CreateMedicationInput;
}>;

export type CreateMedicationMutation = {
  __typename?: 'Mutation';
  createMedication: { __typename?: 'Medication'; id: string; name: string };
};

export type DeleteMedicationsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteMedicationsMutation = {
  __typename?: 'Mutation';
  deleteMedications: Array<{
    __typename?: 'Medication';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateMedicationMutationVariables = Exact<{
  input: UpdateMedicationInput;
}>;

export type UpdateMedicationMutation = {
  __typename?: 'Mutation';
  updateMedication: { __typename?: 'Medication'; id: string; name: string };
};

export type CreateMemberFileMutationVariables = Exact<{
  input: CreateMemberFileInput;
}>;

export type CreateMemberFileMutation = {
  __typename?: 'Mutation';
  createMemberFile: {
    __typename?: 'MemberFile';
    id: string;
    type: MemberFileType;
    dateOfFile?: any | null;
    expiresAt?: any | null;
    value?: string | null;
    owner: { __typename?: 'Member'; id: string };
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      path: string;
      createdBy: { __typename?: 'Member'; id: string };
    };
  };
};

export type DeleteMemberFilesMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteMemberFilesMutation = {
  __typename?: 'Mutation';
  deleteMemberFiles: Array<{
    __typename?: 'MemberFile';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreMemberFilesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreMemberFilesMutation = {
  __typename?: 'Mutation';
  restoreMemberFiles: Array<{
    __typename?: 'MemberFile';
    id: string;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type UpdateMemberFilesMutationVariables = Exact<{
  input: UpdateMemberFilesInput;
}>;

export type UpdateMemberFilesMutation = {
  __typename?: 'Mutation';
  updateMemberFiles: Array<{
    __typename?: 'MemberFile';
    id: string;
    type: MemberFileType;
    dateOfFile?: any | null;
    expiresAt?: any | null;
    value?: string | null;
    owner: { __typename?: 'Member'; id: string };
  }>;
};

export type InviteMembersMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type InviteMembersMutation = {
  __typename?: 'Mutation';
  inviteMembers: Array<{
    __typename?: 'Member';
    id: string;
    invitationMostRecentlyReceived?: {
      __typename?: 'MemberInvitation';
      id: string;
      lastSender: {
        __typename?: 'MemberInvitationSender';
        id: string;
        sentAt: any;
        sentBy: { __typename?: 'Member'; id: string; firstName: string; lastName?: string | null };
      };
    } | null;
  }>;
};

export type ProcessMemberInvitationMutationVariables = Exact<{
  input: LinkMemberInput;
}>;

export type ProcessMemberInvitationMutation = {
  __typename?: 'Mutation';
  linkMember: { __typename?: 'Member'; id: string; org: { __typename?: 'Org'; id: string } };
};

export type UninviteMembersMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type UninviteMembersMutation = {
  __typename?: 'Mutation';
  uninviteMembers: Array<{
    __typename?: 'Member';
    id: string;
    invitationMostRecentlyReceived?: {
      __typename?: 'MemberInvitation';
      id: string;
      deletedAt?: any | null;
    } | null;
  }>;
};

export type CreateMemberUnavailableForMeMutationVariables = Exact<{
  input: CreateMemberUnavailableForMeInput;
}>;

export type CreateMemberUnavailableForMeMutation = {
  __typename?: 'Mutation';
  createMemberUnavailableForMe: {
    __typename?: 'MemberUnavailable';
    id: string;
    startAt: any;
    endAt: any;
    notes?: string | null;
    owner: { __typename?: 'Member'; id: string };
  };
};

export type CreateMemberUnavailablesMutationVariables = Exact<{
  input: CreateMemberUnavailablesInput;
}>;

export type CreateMemberUnavailablesMutation = {
  __typename?: 'Mutation';
  createMemberUnavailables: Array<{
    __typename?: 'MemberUnavailable';
    id: string;
    startAt: any;
    endAt: any;
    notes?: string | null;
    owner: { __typename?: 'Member'; id: string };
  }>;
};

export type DeleteMemberUnavailablesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type DeleteMemberUnavailablesMutation = {
  __typename?: 'Mutation';
  deleteMemberUnavailables: Array<{
    __typename?: 'MemberUnavailable';
    id: string;
    deletedAt?: any | null;
  }>;
};

export type ArchiveMembersMutationVariables = Exact<{
  input: ArchiveInput;
}>;

export type ArchiveMembersMutation = {
  __typename?: 'Mutation';
  archiveMembers: Array<{
    __typename?: 'Member';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type CreateMembersMutationVariables = Exact<{
  input: CreateMembersInput;
}>;

export type CreateMembersMutation = {
  __typename?: 'Mutation';
  createMembers: Array<{
    __typename?: 'Member';
    id: string;
    externalId?: string | null;
    color?: any | null;
    email?: string | null;
    phone?: string | null;
    dob?: any | null;
    schedulable: boolean;
    bonusEligible: boolean;
    workRights?: MemberWorkRights | null;
    workRightsLastCheckedAt?: any | null;
    allowedFortnightlyWorkHours?: number | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    visaType?: { __typename?: 'VisaType'; id: string } | null;
  }>;
};

export type DeleteMembersMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteMembersMutation = {
  __typename?: 'Mutation';
  deleteMembers: Array<{
    __typename?: 'Member';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type EmailMemberClockedPeriodsMutationVariables = Exact<{
  input: QueryMemberClockedPeriodsInput;
}>;

export type EmailMemberClockedPeriodsMutation = {
  __typename?: 'Mutation';
  emailMemberClockedPeriods: { __typename?: 'BooleanResponse'; value: boolean };
};

export type RestoreMembersMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreMembersMutation = {
  __typename?: 'Mutation';
  restoreMembers: Array<{
    __typename?: 'Member';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UnlinkMembersFromUsersMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type UnlinkMembersFromUsersMutation = {
  __typename?: 'Mutation';
  unlinkMembers: Array<{ __typename?: 'Member'; id: string; hasAccess?: boolean | null }>;
};

export type UpdateCurrentMemberTypicalUnavailabilityMutationVariables = Exact<{
  input: UpdateMemberCurrentInput;
}>;

export type UpdateCurrentMemberTypicalUnavailabilityMutation = {
  __typename?: 'Mutation';
  updateMemberCurrent: {
    __typename?: 'Member';
    id: string;
    unavailableNotes?: string | null;
    unavailableMoMorn: boolean;
    unavailableMoDay: boolean;
    unavailableMoNight: boolean;
    unavailableTuMorn: boolean;
    unavailableTuDay: boolean;
    unavailableTuNight: boolean;
    unavailableWeMorn: boolean;
    unavailableWeDay: boolean;
    unavailableWeNight: boolean;
    unavailableThMorn: boolean;
    unavailableThDay: boolean;
    unavailableThNight: boolean;
    unavailableFrMorn: boolean;
    unavailableFrDay: boolean;
    unavailableFrNight: boolean;
    unavailableSaMorn: boolean;
    unavailableSaDay: boolean;
    unavailableSaNight: boolean;
    unavailableSuMorn: boolean;
    unavailableSuDay: boolean;
    unavailableSuNight: boolean;
  };
};

export type UpdateMembersMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    dob?: any | null;
    email?: string | null;
    phone?: string | null;
    study?: string | null;
    externalId?: string | null;
    schedulable: boolean;
    workRights?: MemberWorkRights | null;
    workRightsLastCheckedAt?: any | null;
    allowedFortnightlyWorkHours?: number | null;
    driversLicense?: DriversLicense | null;
    driversLicenseCountry?: AddressCountryCode | null;
    driversLicenseExpiryDate?: any | null;
    hasCar?: boolean | null;
    carDescription?: string | null;
    employmentStartDate?: any | null;
    employmentEndDate?: any | null;
    basePayRate?: number | null;
    bonusEligible: boolean;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    visaType?: { __typename?: 'VisaType'; id: string } | null;
    branches?: Array<{ __typename?: 'Branch'; id: string; name: string }> | null;
  }>;
};

export type UpdateMembersAvatarMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersAvatarMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    avatar?: { __typename?: 'File'; id: string; path: string; size: number } | null;
  }>;
};

export type UpdateMembersColorsMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersColorsMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{ __typename?: 'Member'; id: string; color?: any | null }>;
};

export type UpdateMembersExternalIdsMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersExternalIdsMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{ __typename?: 'Member'; id: string; externalId?: string | null }>;
};

export type UpdateMembersNotesMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersNotesMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{ __typename?: 'Member'; id: string; notes?: string | null }>;
};

export type UpdateMembersPermissionsMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersPermissionsMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    admin: boolean;
    permissions?: Array<Permission> | null;
  }>;
};

export type UpdateMembersTypicalUnavailabilityMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersTypicalUnavailabilityMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    unavailableNotes?: string | null;
    unavailableMoMorn: boolean;
    unavailableMoDay: boolean;
    unavailableMoNight: boolean;
    unavailableTuMorn: boolean;
    unavailableTuDay: boolean;
    unavailableTuNight: boolean;
    unavailableWeMorn: boolean;
    unavailableWeDay: boolean;
    unavailableWeNight: boolean;
    unavailableThMorn: boolean;
    unavailableThDay: boolean;
    unavailableThNight: boolean;
    unavailableFrMorn: boolean;
    unavailableFrDay: boolean;
    unavailableFrNight: boolean;
    unavailableSaMorn: boolean;
    unavailableSaDay: boolean;
    unavailableSaNight: boolean;
    unavailableSuMorn: boolean;
    unavailableSuDay: boolean;
    unavailableSuNight: boolean;
  }>;
};

export type CreateOrgMutationVariables = Exact<{
  input: CreateOrgInput;
}>;

export type CreateOrgMutation = {
  __typename?: 'Mutation';
  createOrg: {
    __typename?: 'Org';
    id: string;
    abn?: any | null;
    email?: any | null;
    phone?: string | null;
    website?: string | null;
    legalName: string;
    preferredName?: string | null;
    branches: Array<{ __typename?: 'Branch'; id: string; name: string }>;
  };
};

export type UpdateOrgPayrollMutationVariables = Exact<{
  input: UpdateOrgInput;
}>;

export type UpdateOrgPayrollMutation = {
  __typename?: 'Mutation';
  updateOrg: {
    __typename?: 'Org';
    id: string;
    basePayRate?: number | null;
    afternoonPayMultiplier?: number | null;
    nightPayMultiplier?: number | null;
    satPayMultiplier?: number | null;
    sunPayMultiplier?: number | null;
    pubPayMultiplier?: number | null;
    passiveAllowance?: number | null;
    kmAllowance?: number | null;
    bonus2PayRate?: number | null;
    bonus3PayRate?: number | null;
    bonus4PayRate?: number | null;
    bonus5PayRate?: number | null;
    bonus6PayRate?: number | null;
    bonus7PayRate?: number | null;
    bonus8PayRate?: number | null;
    bonus9PayRate?: number | null;
    bonus10PayRate?: number | null;
    bonus11PayRate?: number | null;
    bonus12PayRate?: number | null;
  };
};

export type UpdateOrgSchedulingMutationVariables = Exact<{
  input: UpdateOrgInput;
}>;

export type UpdateOrgSchedulingMutation = {
  __typename?: 'Mutation';
  updateOrg: {
    __typename?: 'Org';
    id: string;
    runAutoAssignMemberTask?: boolean | null;
    assignMemberToEventWeeksInAdvance?: number | null;
    membersCanSeeClockedTimes?: boolean | null;
    quantityOfFutureShiftsMembersCanSee?: number | null;
    quantityOfPastShiftsMembersCanSee?: number | null;
    unfilledEventsDayRange?: number | null;
    clockOnWarnMinutes?: number | null;
    clockOffWarnMinutes?: number | null;
    schedulingContactPerson?: { __typename?: 'Member'; id: string } | null;
    schedulingContactPerson2?: { __typename?: 'Member'; id: string } | null;
  };
};

export type ArchivePlanManagersMutationVariables = Exact<{
  input: ArchiveInput;
}>;

export type ArchivePlanManagersMutation = {
  __typename?: 'Mutation';
  archivePlanManagers: Array<{
    __typename?: 'PlanManager';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type CreatePlanManagersMutationVariables = Exact<{
  input: CreatePlanManagersInput;
}>;

export type CreatePlanManagersMutation = {
  __typename?: 'Mutation';
  createPlanManagers: Array<{
    __typename?: 'PlanManager';
    id: string;
    abn?: any | null;
    name: string;
    salutation?: Salutation | null;
    phone?: string | null;
    businessName?: string | null;
    contactEmail?: string | null;
    billingEmail?: string | null;
    notes?: string | null;
  }>;
};

export type DeletePlanManagersMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeletePlanManagersMutation = {
  __typename?: 'Mutation';
  deletePlanManagers: Array<{
    __typename?: 'PlanManager';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestorePlanManagersMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestorePlanManagersMutation = {
  __typename?: 'Mutation';
  restorePlanManagers: Array<{
    __typename?: 'PlanManager';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdatePlanManagersMutationVariables = Exact<{
  input: UpdatePlanManagersInput;
}>;

export type UpdatePlanManagersMutation = {
  __typename?: 'Mutation';
  updatePlanManagers: Array<{
    __typename?: 'PlanManager';
    id: string;
    abn?: any | null;
    name: string;
    salutation?: Salutation | null;
    phone?: string | null;
    businessName?: string | null;
    contactEmail?: string | null;
    billingEmail?: string | null;
    notes?: string | null;
  }>;
};

export type ArchiveSupportCoordinatorsMutationVariables = Exact<{
  input: ArchiveInput;
}>;

export type ArchiveSupportCoordinatorsMutation = {
  __typename?: 'Mutation';
  archiveSupportCoordinators: Array<{
    __typename?: 'SupportCoordinator';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type CreateSupportCoordinatorsMutationVariables = Exact<{
  input: CreateSupportCoordinatorsInput;
}>;

export type CreateSupportCoordinatorsMutation = {
  __typename?: 'Mutation';
  createSupportCoordinators: Array<{
    __typename?: 'SupportCoordinator';
    id: string;
    firstName: string;
    middleName?: string | null;
    lastName?: string | null;
    preferredName?: string | null;
    email?: string | null;
    phone?: string | null;
    businessName?: string | null;
    businessEmail?: string | null;
    businessPhone?: string | null;
    abn?: any | null;
    salutation?: Salutation | null;
    notes?: string | null;
    clients?: Array<{ __typename?: 'Client'; id: string }> | null;
  }>;
};

export type DeleteSupportCoordinatorsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteSupportCoordinatorsMutation = {
  __typename?: 'Mutation';
  deleteSupportCoordinators: Array<{
    __typename?: 'SupportCoordinator';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreSupportCoordinatorsMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreSupportCoordinatorsMutation = {
  __typename?: 'Mutation';
  restoreSupportCoordinators: Array<{
    __typename?: 'SupportCoordinator';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateSupportCoordinatorsMutationVariables = Exact<{
  input: UpdateSupportCoordinatorsInput;
}>;

export type UpdateSupportCoordinatorsMutation = {
  __typename?: 'Mutation';
  updateSupportCoordinators: Array<{
    __typename?: 'SupportCoordinator';
    id: string;
    firstName: string;
    middleName?: string | null;
    lastName?: string | null;
    preferredName?: string | null;
    email?: string | null;
    phone?: string | null;
    businessName?: string | null;
    businessEmail?: string | null;
    businessPhone?: string | null;
    abn?: any | null;
    salutation?: Salutation | null;
    notes?: string | null;
    clients?: Array<{ __typename?: 'Client'; id: string }> | null;
  }>;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: { __typename?: 'UserLoginResponse'; token: any };
};

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type LoginUserMutation = {
  __typename?: 'Mutation';
  loginUser: { __typename?: 'UserLoginResponse'; token: any };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    email?: string | null;
    phone?: string | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    firstName: string;
    lastName?: string | null;
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type CreateVisaTypeMutationVariables = Exact<{
  input: CreateVisaTypeInput;
}>;

export type CreateVisaTypeMutation = {
  __typename?: 'Mutation';
  createVisaType: {
    __typename?: 'VisaType';
    id: string;
    name: string;
    description?: string | null;
    allowedFortnightlyWorkHours?: number | null;
    allowedWorkHoursWaived: boolean;
  };
};

export type DeleteVisaTypesMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteVisaTypesMutation = {
  __typename?: 'Mutation';
  deleteVisaTypes: Array<{
    __typename?: 'VisaType';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreVisaTypesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreVisaTypesMutation = {
  __typename?: 'Mutation';
  restoreVisaTypes: Array<{
    __typename?: 'VisaType';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateVisaTypeMutationVariables = Exact<{
  input: UpdateVisaTypeInput;
}>;

export type UpdateVisaTypeMutation = {
  __typename?: 'Mutation';
  updateVisaType: {
    __typename?: 'VisaType';
    id: string;
    name: string;
    description?: string | null;
    allowedFortnightlyWorkHours?: number | null;
    allowedWorkHoursWaived: boolean;
  };
};

export type GetBranchByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBranchByIdQuery = {
  __typename?: 'Query';
  branchById: { __typename?: 'Branch'; id: string; name: string };
};

export type GetBranchesQueryVariables = Exact<{
  input: QueryBranchesInput;
}>;

export type GetBranchesQuery = {
  __typename?: 'Query';
  branches: Array<{ __typename?: 'Branch'; id: string; name: string }>;
};

export type DownloadClientFileByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DownloadClientFileByIdQuery = {
  __typename?: 'Query';
  downloadClientFileById: { __typename?: 'File'; id: string; path: string };
};

export type GetClientFilesQueryVariables = Exact<{
  input: QueryClientFilesInput;
}>;

export type GetClientFilesQuery = {
  __typename?: 'Query';
  clientFiles: Array<{
    __typename?: 'ClientFile';
    id: string;
    type: ClientFileType;
    dateOfFile?: any | null;
    expiresAt?: any | null;
    value?: string | null;
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      createdAt: any;
      createdBy: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    };
  }>;
};

export type GetClientEnergyIntakeQueryVariables = Exact<{
  input: QueryClientObservationsInput;
}>;

export type GetClientEnergyIntakeQuery = {
  __typename?: 'Query';
  clientObservations: Array<{
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    client: { __typename?: 'Client'; id: string };
    givenFood?: Array<{
      __typename?: 'ClientObservationFood';
      id: string;
      kilojoules?: number | null;
    }> | null;
  }>;
};

export type GetClientObservationByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetClientObservationByIdQuery = {
  __typename?: 'Query';
  clientObservationById: {
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    notes?: string | null;
    nursesName?: string | null;
    bloodSystolic?: number | null;
    bloodDiastolic?: number | null;
    heart?: number | null;
    temp?: number | null;
    spo2?: number | null;
    respiration?: number | null;
    stoolType?: StoolType | null;
    urineColor?: UrineColor | null;
    givenFood?: Array<{
      __typename?: 'ClientObservationFood';
      id: string;
      description?: string | null;
      kilojoules?: number | null;
    }> | null;
    givenMedications?: Array<{
      __typename?: 'ClientObservationMedication';
      id: string;
      quantity?: string | null;
      medication: { __typename?: 'Medication'; id: string; name: string };
    }> | null;
    client: { __typename?: 'Client'; id: string };
    event?: { __typename?: 'Event'; id: string } | null;
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  };
};

export type GetClientObservationsQueryVariables = Exact<{
  input: QueryClientObservationsInput;
}>;

export type GetClientObservationsQuery = {
  __typename?: 'Query';
  clientObservations: Array<{
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    notes?: string | null;
    nursesName?: string | null;
    bloodSystolic?: number | null;
    bloodDiastolic?: number | null;
    heart?: number | null;
    temp?: number | null;
    spo2?: number | null;
    respiration?: number | null;
    stoolType?: StoolType | null;
    urineColor?: UrineColor | null;
    givenFood?: Array<{
      __typename?: 'ClientObservationFood';
      id: string;
      description?: string | null;
      kilojoules?: number | null;
    }> | null;
    givenMedications?: Array<{
      __typename?: 'ClientObservationMedication';
      id: string;
      quantity?: string | null;
      medication: { __typename?: 'Medication'; id: string; name: string };
    }> | null;
    client: { __typename?: 'Client'; id: string };
    event?: {
      __typename?: 'Event';
      id: string;
      member?: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    createdBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetClientTeamsQueryVariables = Exact<{
  input: QueryClientTeamsInput;
}>;

export type GetClientTeamsQuery = {
  __typename?: 'Query';
  clientTeams: Array<{
    __typename?: 'ClientTeam';
    id: string;
    preferred: boolean;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member: {
      __typename?: 'Member';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type GetClientByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetClientByIdQuery = {
  __typename?: 'Query';
  clientById: {
    __typename?: 'Client';
    id: string;
    createdAt?: any | null;
    color?: any | null;
    dob?: any | null;
    email?: string | null;
    phone?: string | null;
    ndisId?: any | null;
    ndisPlanStartDate?: any | null;
    ndisPlanEndDate?: any | null;
    ndisPlanInitialFundsCore?: number | null;
    ndisPlanInitialFundsCs?: number | null;
    ndisPlanInitialFundsCb?: number | null;
    defaultEventDuration?: number | null;
    active: boolean;
    allowMedication: boolean;
    allowObservations: boolean;
    allowAutomaticEventPopulation: boolean;
    bloodSystolicUpperThreshold1?: number | null;
    bloodSystolicLowerThreshold1?: number | null;
    bloodDiastolicUpperThreshold1?: number | null;
    bloodDiastolicLowerThreshold1?: number | null;
    heartUpperThreshold1?: number | null;
    heartLowerThreshold1?: number | null;
    spo2UpperThreshold1?: number | null;
    spo2LowerThreshold1?: number | null;
    respirationUpperThreshold1?: number | null;
    respirationLowerThreshold1?: number | null;
    bloodSystolicUpperThreshold2?: number | null;
    bloodSystolicLowerThreshold2?: number | null;
    bloodDiastolicUpperThreshold2?: number | null;
    bloodDiastolicLowerThreshold2?: number | null;
    heartUpperThreshold2?: number | null;
    heartLowerThreshold2?: number | null;
    spo2UpperThreshold2?: number | null;
    spo2LowerThreshold2?: number | null;
    respirationUpperThreshold2?: number | null;
    respirationLowerThreshold2?: number | null;
    notes?: string | null;
    contactsName?: string | null;
    contactsEmail?: string | null;
    contactsPhone?: string | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    branch: { __typename?: 'Branch'; id: string; name: string };
    createdBy?: {
      __typename?: 'Member';
      id: string;
      firstName: string;
      lastName?: string | null;
    } | null;
    planManager?: { __typename?: 'PlanManager'; id: string; name: string } | null;
    supportCoordinator?: {
      __typename?: 'SupportCoordinator';
      id: string;
      firstName: string;
      lastName?: string | null;
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
  };
};

export type GetClientMedicationsQueryVariables = Exact<{
  id: Scalars['ID'];
  input: QueryClientMedicationsInput;
}>;

export type GetClientMedicationsQuery = {
  __typename?: 'Query';
  clientById: {
    __typename?: 'Client';
    id: string;
    medications?: Array<{
      __typename?: 'ClientMedication';
      id: string;
      medication: { __typename?: 'Medication'; id: string; name: string };
      client: { __typename?: 'Client'; id: string };
    }> | null;
  };
};

export type GetClientsQueryVariables = Exact<{
  input: QueryClientsInput;
}>;

export type GetClientsQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'Client';
    id: string;
    createdAt?: any | null;
    color?: any | null;
    dob?: any | null;
    email?: string | null;
    phone?: string | null;
    ndisId?: any | null;
    defaultEventDuration?: number | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    branch: { __typename?: 'Branch'; id: string; name: string };
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type GetClientsSelectQueryVariables = Exact<{
  input: QueryRedactedClientsInput;
}>;

export type GetClientsSelectQuery = {
  __typename?: 'Query';
  redactedClients: Array<{
    __typename?: 'RedactedClient';
    id: string;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type GetRedactedClientByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetRedactedClientByIdQuery = {
  __typename?: 'Query';
  redactedClientById: {
    __typename?: 'RedactedClient';
    id: string;
    color?: any | null;
    age?: number | null;
    allowMedication: boolean;
    allowObservations: boolean;
    allowAutomaticEventPopulation: boolean;
    bloodSystolicUpperThreshold1?: number | null;
    bloodSystolicLowerThreshold1?: number | null;
    bloodDiastolicUpperThreshold1?: number | null;
    bloodDiastolicLowerThreshold1?: number | null;
    heartUpperThreshold1?: number | null;
    heartLowerThreshold1?: number | null;
    spo2UpperThreshold1?: number | null;
    spo2LowerThreshold1?: number | null;
    respirationUpperThreshold1?: number | null;
    respirationLowerThreshold1?: number | null;
    bloodSystolicUpperThreshold2?: number | null;
    bloodSystolicLowerThreshold2?: number | null;
    bloodDiastolicUpperThreshold2?: number | null;
    bloodDiastolicLowerThreshold2?: number | null;
    heartUpperThreshold2?: number | null;
    heartLowerThreshold2?: number | null;
    spo2UpperThreshold2?: number | null;
    spo2LowerThreshold2?: number | null;
    respirationUpperThreshold2?: number | null;
    respirationLowerThreshold2?: number | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
  };
};

export type GetRedactedClientMedicationsQueryVariables = Exact<{
  id: Scalars['ID'];
  input: QueryClientMedicationsInput;
}>;

export type GetRedactedClientMedicationsQuery = {
  __typename?: 'Query';
  redactedClientById: {
    __typename?: 'RedactedClient';
    id: string;
    medications?: Array<{
      __typename?: 'ClientMedication';
      id: string;
      medication: { __typename?: 'Medication'; id: string; name: string };
    }> | null;
  };
};

export type GetRedactedClientsQueryVariables = Exact<{
  input: QueryRedactedClientsInput;
}>;

export type GetRedactedClientsQuery = {
  __typename?: 'Query';
  redactedClients: Array<{
    __typename?: 'RedactedClient';
    id: string;
    color?: any | null;
    age?: number | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type DownloadEventFileByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DownloadEventFileByIdQuery = {
  __typename?: 'Query';
  downloadEventFileById: { __typename?: 'File'; id: string; path: string };
};

export type GetEventFilesQueryVariables = Exact<{
  input: QueryEventFilesInput;
}>;

export type GetEventFilesQuery = {
  __typename?: 'Query';
  eventFiles: Array<{
    __typename?: 'EventFile';
    id: string;
    type: EventFileType;
    dateOfFile?: any | null;
    value?: string | null;
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      createdAt: any;
      createdBy: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    };
  }>;
};

export type GetEventByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetEventByIdQuery = {
  __typename?: 'Query';
  eventById: {
    __typename?: 'Event';
    id: string;
    createdAt?: any | null;
    startAt: any;
    endAt: any;
    duration: number;
    notes?: string | null;
    summary?: string | null;
    scheduleNotes?: string | null;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    billable: boolean;
    payable: boolean;
    publicHoliday?: boolean | null;
    memberAssignedAutomatically: boolean;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    clockedOnLatitude?: string | null;
    clockedOnLongitude?: string | null;
    clockedOffLatitude?: string | null;
    clockedOffLongitude?: string | null;
    travelDistance?: number | null;
    travelDistanceNotes?: string | null;
    travelTime?: number | null;
    travelTimeNotes?: string | null;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    createdBy: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    clockedOnBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    clockedOffBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    travelApprovedBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    client: {
      __typename?: 'Client';
      id: string;
      color?: any | null;
      defaultEventDuration?: number | null;
      allowMedication: boolean;
      allowObservations: boolean;
      bloodSystolicUpperThreshold1?: number | null;
      bloodSystolicLowerThreshold1?: number | null;
      bloodDiastolicUpperThreshold1?: number | null;
      bloodDiastolicLowerThreshold1?: number | null;
      heartUpperThreshold1?: number | null;
      heartLowerThreshold1?: number | null;
      spo2UpperThreshold1?: number | null;
      spo2LowerThreshold1?: number | null;
      respirationUpperThreshold1?: number | null;
      respirationLowerThreshold1?: number | null;
      bloodSystolicUpperThreshold2?: number | null;
      bloodSystolicLowerThreshold2?: number | null;
      bloodDiastolicUpperThreshold2?: number | null;
      bloodDiastolicLowerThreshold2?: number | null;
      heartUpperThreshold2?: number | null;
      heartLowerThreshold2?: number | null;
      spo2UpperThreshold2?: number | null;
      spo2LowerThreshold2?: number | null;
      respirationUpperThreshold2?: number | null;
      respirationLowerThreshold2?: number | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'Member';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
    cancel?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
  };
};

export type GetEventCreatorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetEventCreatorQuery = {
  __typename?: 'Query';
  eventById: {
    __typename?: 'Event';
    id: string;
    createdAt?: any | null;
    createdBy: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  };
};

export type GetEventHistoriesQueryVariables = Exact<{
  input: QueryHistoriesInput;
}>;

export type GetEventHistoriesQuery = {
  __typename?: 'Query';
  histories?: Array<
    | {
        __typename?: 'HistoryBranchName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientAllowAutomaticEventPopulation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientColor';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientDefaultEventDuration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientDob';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileDateOfFile';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileExpiresAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileOwner';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientGender';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanEndDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCb';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCore';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCs';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanStartDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationBloodDiastolic';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationBloodSystolic';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationHeart';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationMember';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationNursesName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationRespiration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationSpo2';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationStoolType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationTemp';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPlanManager';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPronouns';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientSupportCoordinator';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventActiveAssist';
        id: string;
        date: any;
        type: HistoryType;
        eventActiveAssistNew?: boolean | null;
        eventActiveAssistOld?: boolean | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventBillable';
        id: string;
        date: any;
        type: HistoryType;
        eventBillablenew?: boolean | null;
        eventBillableOld?: boolean | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventClient';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
        eventClientNew?: {
          __typename?: 'Client';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        eventClientOld?: {
          __typename?: 'Client';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClockedOffAt';
        id: string;
        date: any;
        type: HistoryType;
        eventClockedOffAtNew?: any | null;
        eventClockedOffAtOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventClockedOnAt';
        id: string;
        date: any;
        type: HistoryType;
        eventClockedOnAtNew?: any | null;
        eventClockedOnAtOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventCountry';
        id: string;
        date: any;
        type: HistoryType;
        eventCountryNew?: AddressCountryCode | null;
        eventCountryOld?: AddressCountryCode | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventDuration';
        id: string;
        date: any;
        type: HistoryType;
        eventDurationNew?: number | null;
        eventDurationOld?: number | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventLocality';
        id: string;
        date: any;
        type: HistoryType;
        eventLocalityNew?: string | null;
        eventLocalityOld?: string | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventMember';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
        eventMemberNew?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        eventMemberOld?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventPassive';
        id: string;
        date: any;
        type: HistoryType;
        eventPassiveNew?: boolean | null;
        eventPassiveOld?: boolean | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventPassiveStartAt';
        id: string;
        date: any;
        type: HistoryType;
        eventPassiveStartAtNew?: any | null;
        eventPassiveStartAtOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventPayable';
        id: string;
        date: any;
        type: HistoryType;
        eventPayableNew?: boolean | null;
        eventPayableOld?: boolean | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventPostcode';
        id: string;
        date: any;
        type: HistoryType;
        eventPostcodeNew?: string | null;
        eventPostcodeOld?: string | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventPublicHoliday';
        id: string;
        date: any;
        type: HistoryType;
        eventPublicHolidayNew?: boolean | null;
        eventPublicHolidayOld?: boolean | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventRegion';
        id: string;
        date: any;
        type: HistoryType;
        eventRegionNew?: AddressRegionCode | null;
        eventRegionOld?: AddressRegionCode | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventScheduleNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventStartAt';
        id: string;
        date: any;
        type: HistoryType;
        eventStartAtNew?: any | null;
        eventStartAtOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventStreet';
        id: string;
        date: any;
        type: HistoryType;
        eventStreetNew?: string | null;
        eventStreetOld?: string | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventSummary';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventTravelDistance';
        id: string;
        date: any;
        type: HistoryType;
        eventTravelDistanceNew?: number | null;
        eventTravelDistanceOld?: number | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventTravelDistanceNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventTravelTime';
        id: string;
        date: any;
        type: HistoryType;
        eventTravelTimeNew?: number | null;
        eventTravelTimeOld?: number | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventTravelTimeNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventUnit';
        id: string;
        date: any;
        type: HistoryType;
        eventUnitNew?: string | null;
        eventUnitOld?: string | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryMemberAdmin';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberColor';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberDob';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberExternalId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileDateOfFile';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileExpiresAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileOwner';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberGender';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPronouns';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberSchedulable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgLegalName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgWebsite';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerBillingEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerBusinessName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerContactEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryRestorable';
        id: string;
        date: any;
        type: HistoryType;
        reason?: string | null;
        restoredAt?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        restoredBy?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          middleName?: string | null;
          lastName?: string | null;
          preferredName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryVisaTypeDescription';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryVisaTypeName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
  > | null;
};

export type GetEventOwnAndRelatedByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetEventOwnAndRelatedByIdQuery = {
  __typename?: 'Query';
  eventOwnAndRelatedById: {
    __typename?: 'EventOwnAndRelated';
    id: string;
    startAt: any;
    endAt: any;
    duration: number;
    notes?: string | null;
    summary?: string | null;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    publicHoliday: boolean;
    memberAssignedAutomatically: boolean;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    travelDistance: number;
    travelDistanceNotes?: string | null;
    travelTime: number;
    travelTimeNotes?: string | null;
    client: {
      __typename?: 'RedactedClient';
      id: string;
      color?: any | null;
      allowMedication: boolean;
      allowObservations: boolean;
      bloodSystolicUpperThreshold1?: number | null;
      bloodSystolicLowerThreshold1?: number | null;
      bloodDiastolicUpperThreshold1?: number | null;
      bloodDiastolicLowerThreshold1?: number | null;
      heartUpperThreshold1?: number | null;
      heartLowerThreshold1?: number | null;
      spo2UpperThreshold1?: number | null;
      spo2LowerThreshold1?: number | null;
      respirationUpperThreshold1?: number | null;
      respirationLowerThreshold1?: number | null;
      bloodSystolicUpperThreshold2?: number | null;
      bloodSystolicLowerThreshold2?: number | null;
      bloodDiastolicUpperThreshold2?: number | null;
      bloodDiastolicLowerThreshold2?: number | null;
      heartUpperThreshold2?: number | null;
      heartLowerThreshold2?: number | null;
      spo2UpperThreshold2?: number | null;
      spo2LowerThreshold2?: number | null;
      respirationUpperThreshold2?: number | null;
      respirationLowerThreshold2?: number | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'RedactedMember';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    cancel?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
    } | null;
  };
};

export type GetEventTooltipQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetEventTooltipQuery = {
  __typename?: 'Query';
  eventById: { __typename?: 'Event'; id: string; scheduleNotes?: string | null };
};

export type GetEventsQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetEventsQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    createdAt?: any | null;
    startAt: any;
    endAt: any;
    duration: number;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    hasNotes: boolean;
    hasFiles: boolean;
    conflictExists?: boolean | null;
    publicHoliday?: boolean | null;
    travelDistance?: number | null;
    travelTime?: number | null;
    memberAssignedAutomatically: boolean;
    client: {
      __typename?: 'Client';
      id: string;
      ndisId?: any | null;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'Member';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
    cancel?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type GetEventsMembersReadyToExportQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetEventsMembersReadyToExportQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    hasNotes: boolean;
    member?: {
      __typename?: 'Member';
      id: string;
      bonusEligible: boolean;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetEventsMembersPaySlipsDataQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetEventsMembersPaySlipsDataQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    hasNotes: boolean;
    member?: {
      __typename?: 'Member';
      id: string;
      basePayRate?: number | null;
      bonusEligible: boolean;
      externalId?: string | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetEventsMembersWorkedHoursQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetEventsMembersWorkedHoursQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type GetEventsOwnAndRelatedQueryVariables = Exact<{
  input: QueryEventsOwnAndRelatedInput;
}>;

export type GetEventsOwnAndRelatedQuery = {
  __typename?: 'Query';
  eventsOwnAndRelated: Array<{
    __typename?: 'EventOwnAndRelated';
    id: string;
    createdAt?: any | null;
    startAt: any;
    endAt: any;
    duration: number;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    hasNotes: boolean;
    hasFiles: boolean;
    publicHoliday: boolean;
    travelDistance: number;
    travelTime: number;
    memberAssignedAutomatically: boolean;
    client: {
      __typename?: 'RedactedClient';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'RedactedMember';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    cancel?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type GetNonClockedEventsQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetNonClockedEventsQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type GetUnfilledEventsQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetUnfilledEventsQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type GetMedicationByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMedicationByIdQuery = {
  __typename?: 'Query';
  medicationById: { __typename?: 'Medication'; id: string; name: string };
};

export type GetMedicationsQueryVariables = Exact<{
  input: QueryMedicationsInput;
}>;

export type GetMedicationsQuery = {
  __typename?: 'Query';
  medications: Array<{ __typename?: 'Medication'; id: string; name: string }>;
};

export type DownloadMemberFileByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DownloadMemberFileByIdQuery = {
  __typename?: 'Query';
  downloadMemberFileById: { __typename?: 'File'; id: string; path: string };
};

export type GetMemberFilesQueryVariables = Exact<{
  input: QueryMemberFilesInput;
}>;

export type GetMemberFilesQuery = {
  __typename?: 'Query';
  memberFiles: Array<{
    __typename?: 'MemberFile';
    id: string;
    type: MemberFileType;
    dateOfFile?: any | null;
    expiresAt?: any | null;
    value?: string | null;
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      createdAt: any;
      createdBy: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    };
  }>;
};

export type GetMemberByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberByIdQuery = {
  __typename?: 'Query';
  memberById: {
    __typename?: 'Member';
    id: string;
    createdAt?: any | null;
    lastActiveAt?: any | null;
    hasAccess?: boolean | null;
    admin: boolean;
    permissions?: Array<Permission> | null;
    externalId?: string | null;
    schedulable: boolean;
    workRights?: MemberWorkRights | null;
    workRightsLastCheckedAt?: any | null;
    allowedFortnightlyWorkHours?: number | null;
    driversLicense?: DriversLicense | null;
    driversLicenseCountry?: AddressCountryCode | null;
    driversLicenseExpiryDate?: any | null;
    hasCar?: boolean | null;
    carDescription?: string | null;
    color?: any | null;
    dob?: any | null;
    email?: string | null;
    phone?: string | null;
    notes?: string | null;
    employmentStartDate?: any | null;
    employmentEndDate?: any | null;
    study?: string | null;
    basePayRate?: number | null;
    bonusEligible: boolean;
    unavailableNotes?: string | null;
    unavailableMoMorn: boolean;
    unavailableMoDay: boolean;
    unavailableMoNight: boolean;
    unavailableTuMorn: boolean;
    unavailableTuDay: boolean;
    unavailableTuNight: boolean;
    unavailableWeMorn: boolean;
    unavailableWeDay: boolean;
    unavailableWeNight: boolean;
    unavailableThMorn: boolean;
    unavailableThDay: boolean;
    unavailableThNight: boolean;
    unavailableFrMorn: boolean;
    unavailableFrDay: boolean;
    unavailableFrNight: boolean;
    unavailableSaMorn: boolean;
    unavailableSaDay: boolean;
    unavailableSaNight: boolean;
    unavailableSuMorn: boolean;
    unavailableSuDay: boolean;
    unavailableSuNight: boolean;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    visaType?: { __typename?: 'VisaType'; id: string; name: string } | null;
    createdBy?: {
      __typename?: 'Member';
      id: string;
      firstName: string;
      lastName?: string | null;
    } | null;
    invitationMostRecentlyReceived?: {
      __typename?: 'MemberInvitation';
      id: string;
      deletedAt?: any | null;
      usedAt?: any | null;
      sentCount: number;
      lastSender: {
        __typename?: 'MemberInvitationSender';
        id: string;
        sentAt: any;
        sentBy: { __typename?: 'Member'; id: string; firstName: string; lastName?: string | null };
      };
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
  };
};

export type GetMemberScheduleByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  scheduleInput: QueryMemberScheduleInput;
}>;

export type GetMemberScheduleByIdQuery = {
  __typename?: 'Query';
  memberById: {
    __typename?: 'Member';
    id: string;
    schedule?: { __typename?: 'MemberSchedule'; timeScheduled: number } | null;
  };
};

export type GetMemberTypicalUnavailablilityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberTypicalUnavailablilityQuery = {
  __typename?: 'Query';
  memberById: {
    __typename?: 'Member';
    id: string;
    unavailableNotes?: string | null;
    unavailableMoMorn: boolean;
    unavailableMoDay: boolean;
    unavailableMoNight: boolean;
    unavailableTuMorn: boolean;
    unavailableTuDay: boolean;
    unavailableTuNight: boolean;
    unavailableWeMorn: boolean;
    unavailableWeDay: boolean;
    unavailableWeNight: boolean;
    unavailableThMorn: boolean;
    unavailableThDay: boolean;
    unavailableThNight: boolean;
    unavailableFrMorn: boolean;
    unavailableFrDay: boolean;
    unavailableFrNight: boolean;
    unavailableSaMorn: boolean;
    unavailableSaDay: boolean;
    unavailableSaNight: boolean;
    unavailableSuMorn: boolean;
    unavailableSuDay: boolean;
    unavailableSuNight: boolean;
  };
};

export type GetMemberUnavailablesQueryVariables = Exact<{
  unavailablesInput: QueryMemberUnavailablesInput;
}>;

export type GetMemberUnavailablesQuery = {
  __typename?: 'Query';
  memberUnavailables: Array<{
    __typename?: 'MemberUnavailable';
    id: string;
    startAt: any;
    endAt: any;
    notes?: string | null;
    owner: { __typename?: 'Member'; id: string };
  }>;
};

export type GetMembersQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type GetMembersQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    admin: boolean;
    permissions?: Array<Permission> | null;
    color?: any | null;
    hasAccess?: boolean | null;
    externalId?: string | null;
    lastActiveAt?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
    invitationMostRecentlyReceived?: {
      __typename?: 'MemberInvitation';
      id: string;
      deletedAt?: any | null;
      usedAt?: any | null;
    } | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type GetMemberBasePayRateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberBasePayRateQuery = {
  __typename?: 'Query';
  memberById: { __typename?: 'Member'; id: string; basePayRate?: number | null };
};

export type GetMemberPermissionsQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type GetMemberPermissionsQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    admin: boolean;
    permissions?: Array<Permission> | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type GetMembersSelectQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type GetMembersSelectQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type GetMyUnavailablesQueryVariables = Exact<{
  input: QueryMyMemberUnavailablesInput;
}>;

export type GetMyUnavailablesQuery = {
  __typename?: 'Query';
  myUnavailables: Array<{
    __typename?: 'MemberUnavailable';
    id: string;
    startAt: any;
    endAt: any;
    notes?: string | null;
    owner: { __typename?: 'Member'; id: string };
  }>;
};

export type GetRedactedMemberByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetRedactedMemberByIdQuery = {
  __typename?: 'Query';
  redactedMemberById: {
    __typename?: 'RedactedMember';
    id: string;
    color?: any | null;
    email?: string | null;
    phone?: string | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  };
};

export type GetRedactedMembersQueryVariables = Exact<{
  input: QueryRedactedMembersInput;
}>;

export type GetRedactedMembersQuery = {
  __typename?: 'Query';
  redactedMembers: Array<{
    __typename?: 'RedactedMember';
    id: string;
    color?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type GetNotificationByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetNotificationByIdQuery = {
  __typename?: 'Query';
  notificationById: { __typename?: 'Notification'; id: string; message?: string | null };
};

export type GetNotificationsQueryVariables = Exact<{
  input: QueryNotificationsInput;
}>;

export type GetNotificationsQuery = {
  __typename?: 'Query';
  notificationsAggregate: {
    __typename?: 'NotificationsAggregate';
    aggregate: { __typename?: 'AggregateData'; totalCount: number };
    nodes: Array<{ __typename?: 'Notification'; id: string; message?: string | null }>;
  };
};

export type GetCurrentOrgPayrollSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentOrgPayrollSettingsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        basePayRate?: number | null;
        afternoonPayMultiplier?: number | null;
        nightPayMultiplier?: number | null;
        satPayMultiplier?: number | null;
        sunPayMultiplier?: number | null;
        pubPayMultiplier?: number | null;
        passiveAllowance?: number | null;
        kmAllowance?: number | null;
        bonus2PayRate?: number | null;
        bonus3PayRate?: number | null;
        bonus4PayRate?: number | null;
        bonus5PayRate?: number | null;
        bonus6PayRate?: number | null;
        bonus7PayRate?: number | null;
        bonus8PayRate?: number | null;
        bonus9PayRate?: number | null;
        bonus10PayRate?: number | null;
        bonus11PayRate?: number | null;
        bonus12PayRate?: number | null;
      };
    } | null;
  };
};

export type GetOrgPayrollSettingsByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOrgPayrollSettingsByIdQuery = {
  __typename?: 'Query';
  orgById: {
    __typename?: 'Org';
    id: string;
    basePayRate?: number | null;
    afternoonPayMultiplier?: number | null;
    nightPayMultiplier?: number | null;
    satPayMultiplier?: number | null;
    sunPayMultiplier?: number | null;
    pubPayMultiplier?: number | null;
    passiveAllowance?: number | null;
    kmAllowance?: number | null;
    bonus2PayRate?: number | null;
    bonus3PayRate?: number | null;
    bonus4PayRate?: number | null;
    bonus5PayRate?: number | null;
    bonus6PayRate?: number | null;
    bonus7PayRate?: number | null;
    bonus8PayRate?: number | null;
    bonus9PayRate?: number | null;
    bonus10PayRate?: number | null;
    bonus11PayRate?: number | null;
    bonus12PayRate?: number | null;
  };
};

export type GetOrgSettingsByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOrgSettingsByIdQuery = {
  __typename?: 'Query';
  orgById: {
    __typename?: 'Org';
    id: string;
    membersCanSeeClockedTimes?: boolean | null;
    quantityOfFutureShiftsMembersCanSee?: number | null;
    quantityOfPastShiftsMembersCanSee?: number | null;
    runAutoAssignMemberTask?: boolean | null;
    assignMemberToEventWeeksInAdvance?: number | null;
    unfilledEventsDayRange?: number | null;
    clockOnWarnMinutes?: number | null;
    clockOffWarnMinutes?: number | null;
    schedulingContactPerson?: {
      __typename?: 'Member';
      id: string;
      phone?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    schedulingContactPerson2?: {
      __typename?: 'Member';
      id: string;
      phone?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  };
};

export type GetOrgUnfilledEventsDayRangeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOrgUnfilledEventsDayRangeQuery = {
  __typename?: 'Query';
  orgById: { __typename?: 'Org'; id: string; unfilledEventsDayRange?: number | null };
};

export type GetPlanManagerByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPlanManagerByIdQuery = {
  __typename?: 'Query';
  planManagerById: {
    __typename?: 'PlanManager';
    id: string;
    abn?: any | null;
    name: string;
    salutation?: Salutation | null;
    phone?: string | null;
    businessName?: string | null;
    contactEmail?: string | null;
    billingEmail?: string | null;
    notes?: string | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  };
};

export type GetPlanManagersQueryVariables = Exact<{
  input: QueryPlanManagersInput;
}>;

export type GetPlanManagersQuery = {
  __typename?: 'Query';
  planManagers: Array<{
    __typename?: 'PlanManager';
    id: string;
    name: string;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type GetPublicHolidayByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPublicHolidayByIdQuery = {
  __typename?: 'Query';
  publicHolidayById: { __typename?: 'PublicHoliday'; id: string; date: any; description: string };
};

export type GetPublicHolidaysQueryVariables = Exact<{
  input: QueryPublicHolidaysInput;
}>;

export type GetPublicHolidaysQuery = {
  __typename?: 'Query';
  publicHolidays: Array<{
    __typename?: 'PublicHoliday';
    id: string;
    date: any;
    description: string;
  }>;
};

export type GetActivitySlipMembersQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type GetActivitySlipMembersQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type GetActivitySlipsQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetActivitySlipsQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    publicHoliday?: boolean | null;
    travelDistance?: number | null;
    travelTime?: number | null;
    billable: boolean;
    payable: boolean;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    hasNotes: boolean;
    cancel?: { __typename?: 'HistoryRestorable'; id: string } | null;
    client: {
      __typename?: 'Client';
      id: string;
      ndisId?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
      planManager?: { __typename?: 'PlanManager'; id: string; name: string } | null;
    };
    member?: {
      __typename?: 'Member';
      id: string;
      externalId?: string | null;
      bonusEligible: boolean;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetMemberWorkedHoursQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetMemberWorkedHoursQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    duration: number;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    travelTime?: number | null;
    passive: boolean;
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetShiftsToApproveQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetShiftsToApproveQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    clockedOnAt?: any | null;
    clockedOnLatitude?: string | null;
    clockedOnLongitude?: string | null;
    clockedOffAt?: any | null;
    clockedOffLatitude?: string | null;
    clockedOffLongitude?: string | null;
    payable: boolean;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type ScheduleControlMembersInputQueryVariables = Exact<{
  input: QueryMembersInput;
  scheduleInput: QueryMemberScheduleInput;
  eventsInput: QueryEventsInput;
  teamsInput: QueryClientTeamsInput;
  unavailablesInput: QueryMemberUnavailablesInput;
}>;

export type ScheduleControlMembersInputQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    hasAccess?: boolean | null;
    color?: any | null;
    phone?: string | null;
    gender?: Gender | null;
    dob?: any | null;
    unavailableNotes?: string | null;
    unavailableMoMorn: boolean;
    unavailableMoDay: boolean;
    unavailableMoNight: boolean;
    unavailableTuMorn: boolean;
    unavailableTuDay: boolean;
    unavailableTuNight: boolean;
    unavailableWeMorn: boolean;
    unavailableWeDay: boolean;
    unavailableWeNight: boolean;
    unavailableThMorn: boolean;
    unavailableThDay: boolean;
    unavailableThNight: boolean;
    unavailableFrMorn: boolean;
    unavailableFrDay: boolean;
    unavailableFrNight: boolean;
    unavailableSaMorn: boolean;
    unavailableSaDay: boolean;
    unavailableSaNight: boolean;
    unavailableSuMorn: boolean;
    unavailableSuDay: boolean;
    unavailableSuNight: boolean;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
    schedule?: { __typename?: 'MemberSchedule'; timeScheduled: number } | null;
    events?: Array<{
      __typename?: 'Event';
      id: string;
      startAt: any;
      duration: number;
      client: {
        __typename?: 'Client';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    }> | null;
    teams?: Array<{
      __typename?: 'ClientTeam';
      id: string;
      preferred: boolean;
      client: { __typename?: 'Client'; id: string };
    }> | null;
    unavailables?: Array<{
      __typename?: 'MemberUnavailable';
      id: string;
      startAt: any;
      endAt: any;
      notes?: string | null;
      owner: { __typename?: 'Member'; id: string };
    }> | null;
  }>;
};

export type ScheduleMemberHoursInputQueryVariables = Exact<{
  membersInput: QueryScheuleMembersInput;
}>;

export type ScheduleMemberHoursInputQuery = {
  __typename?: 'Query';
  schedule: {
    __typename?: 'Schedule';
    members?: Array<{
      __typename?: 'ScheduleMember';
      weekTime: number;
      fortnightTime: number;
      member: {
        __typename?: 'Member';
        id: string;
        color?: any | null;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
      currentEvent?: {
        __typename?: 'Event';
        id: string;
        startAt: any;
        endAt: any;
        clockedOnAt?: any | null;
      } | null;
      nextEvent?: { __typename?: 'Event'; id: string; startAt: any; endAt: any } | null;
    }> | null;
  };
};

export type GetScheduleOrgSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetScheduleOrgSettingsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: { __typename?: 'Org'; id: string; assignMemberToEventWeeksInAdvance?: number | null };
    } | null;
  };
};

export type GetApproveShiftsOrgSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetApproveShiftsOrgSettingsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        clockOnWarnMinutes?: number | null;
        clockOffWarnMinutes?: number | null;
      };
    } | null;
  };
};

export type GetClaimableShiftsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClaimableShiftsQuery = {
  __typename?: 'Query';
  eventsClaimable: Array<{
    __typename?: 'EventOwnAndRelated';
    id: string;
    startAt: any;
    endAt: any;
    duration: number;
    passive: boolean;
    locality?: string | null;
    region?: AddressRegionCode | null;
    client: {
      __typename?: 'RedactedClient';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'RedactedMember';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetMyShiftsQueryVariables = Exact<{
  input: QueryEventsOwnAndRelatedInput;
}>;

export type GetMyShiftsQuery = {
  __typename?: 'Query';
  eventsOwnAndRelated: Array<{
    __typename?: 'EventOwnAndRelated';
    id: string;
    startAt: any;
    endAt: any;
    duration: number;
    passive: boolean;
    locality?: string | null;
    region?: AddressRegionCode | null;
    client: {
      __typename?: 'RedactedClient';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: { __typename?: 'RedactedMember'; id: string } | null;
  }>;
};

export type GetShiftsMissingAnAttendeeQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetShiftsMissingAnAttendeeQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    duration: number;
    locality?: string | null;
    region?: AddressRegionCode | null;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type GetSupportCoordinatorByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetSupportCoordinatorByIdQuery = {
  __typename?: 'Query';
  supportCoordinatorById: {
    __typename?: 'SupportCoordinator';
    id: string;
    createdAt?: any | null;
    firstName: string;
    middleName?: string | null;
    lastName?: string | null;
    preferredName?: string | null;
    email?: string | null;
    phone?: string | null;
    businessName?: string | null;
    businessEmail?: string | null;
    businessPhone?: string | null;
    abn?: any | null;
    salutation?: Salutation | null;
    notes?: string | null;
    createdBy?: {
      __typename?: 'Member';
      id: string;
      firstName: string;
      lastName?: string | null;
    } | null;
    clients?: Array<{ __typename?: 'Client'; id: string }> | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
  };
};

export type GetSupportCoordinatorsQueryVariables = Exact<{
  input: QuerySupportCoordinatorsInput;
}>;

export type GetSupportCoordinatorsQuery = {
  __typename?: 'Query';
  supportCoordinators: Array<{
    __typename?: 'SupportCoordinator';
    id: string;
    abn?: any | null;
    salutation?: Salutation | null;
    firstName: string;
    middleName?: string | null;
    lastName?: string | null;
    preferredName?: string | null;
    email?: string | null;
    phone?: string | null;
    businessName?: string | null;
    businessEmail?: string | null;
    businessPhone?: string | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
    member?: {
      __typename?: 'Member';
      id: string;
      permissions?: Array<Permission> | null;
      color?: any | null;
      unavailableNotes?: string | null;
      unavailableMoMorn: boolean;
      unavailableMoDay: boolean;
      unavailableMoNight: boolean;
      unavailableTuMorn: boolean;
      unavailableTuDay: boolean;
      unavailableTuNight: boolean;
      unavailableWeMorn: boolean;
      unavailableWeDay: boolean;
      unavailableWeNight: boolean;
      unavailableThMorn: boolean;
      unavailableThDay: boolean;
      unavailableThNight: boolean;
      unavailableFrMorn: boolean;
      unavailableFrDay: boolean;
      unavailableFrNight: boolean;
      unavailableSaMorn: boolean;
      unavailableSaDay: boolean;
      unavailableSaNight: boolean;
      unavailableSuMorn: boolean;
      unavailableSuDay: boolean;
      unavailableSuNight: boolean;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
      org: {
        __typename?: 'Org';
        id: string;
        membersCanSeeClockedTimes?: boolean | null;
        schedulingContactPerson?: {
          __typename?: 'Member';
          id: string;
          phone?: string | null;
          middleName?: string | null;
          preferredName?: string | null;
          firstName: string;
          lastName?: string | null;
        } | null;
        schedulingContactPerson2?: {
          __typename?: 'Member';
          id: string;
          phone?: string | null;
          middleName?: string | null;
          preferredName?: string | null;
          firstName: string;
          lastName?: string | null;
        } | null;
      };
    } | null;
    members: Array<{
      __typename?: 'Member';
      id: string;
      admin: boolean;
      org: { __typename?: 'Org'; id: string; legalName: string; preferredName?: string | null };
    }>;
  };
};

export type GetVisaTypesQueryVariables = Exact<{
  input: QueryVisaTypesInput;
}>;

export type GetVisaTypesQuery = {
  __typename?: 'Query';
  visaTypes: Array<{
    __typename?: 'VisaType';
    id: string;
    name: string;
    description?: string | null;
    allowedFortnightlyWorkHours?: number | null;
    allowedWorkHoursWaived: boolean;
  }>;
};

export const AddressFullFragmentDoc = gql`
  fragment AddressFull on Address {
    unit
    street
    locality
    region
    postcode
    country
  }
`;
export const AddressGeolocationFragmentDoc = gql`
  fragment AddressGeolocation on Address {
    latitude
    longitude
  }
`;
export const BusinessNamesFragmentDoc = gql`
  fragment BusinessNames on Business {
    legalName
    preferredName
  }
`;
export const PersonGenderIdentifiersFragmentDoc = gql`
  fragment PersonGenderIdentifiers on Person {
    gender
    salutation
    pronouns
  }
`;
export const PersonNamesPartialFragmentDoc = gql`
  fragment PersonNamesPartial on Person {
    firstName
    lastName
  }
`;
export const PersonNamesFragmentDoc = gql`
  fragment PersonNames on Person {
    middleName
    preferredName
    ...PersonNamesPartial
  }
  ${PersonNamesPartialFragmentDoc}
`;
export const CreateBranchesDocument = gql`
  mutation CreateBranches($input: CreateBranchesInput!) {
    createBranches(input: $input) {
      id
      name
    }
  }
`;
export type CreateBranchesMutationFn = Apollo.MutationFunction<
  CreateBranchesMutation,
  CreateBranchesMutationVariables
>;

/**
 * __useCreateBranchesMutation__
 *
 * To run a mutation, you first call `useCreateBranchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBranchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchesMutation, { data, loading, error }] = useCreateBranchesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBranchesMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBranchesMutation, CreateBranchesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBranchesMutation, CreateBranchesMutationVariables>(
    CreateBranchesDocument,
    options,
  );
}
export type CreateBranchesMutationHookResult = ReturnType<typeof useCreateBranchesMutation>;
export type CreateBranchesMutationResult = Apollo.MutationResult<CreateBranchesMutation>;
export type CreateBranchesMutationOptions = Apollo.BaseMutationOptions<
  CreateBranchesMutation,
  CreateBranchesMutationVariables
>;
export const UpdateBranchesDocument = gql`
  mutation UpdateBranches($input: UpdateBranchesInput!) {
    updateBranches(input: $input) {
      id
      name
    }
  }
`;
export type UpdateBranchesMutationFn = Apollo.MutationFunction<
  UpdateBranchesMutation,
  UpdateBranchesMutationVariables
>;

/**
 * __useUpdateBranchesMutation__
 *
 * To run a mutation, you first call `useUpdateBranchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBranchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBranchesMutation, { data, loading, error }] = useUpdateBranchesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBranchesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBranchesMutation, UpdateBranchesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBranchesMutation, UpdateBranchesMutationVariables>(
    UpdateBranchesDocument,
    options,
  );
}
export type UpdateBranchesMutationHookResult = ReturnType<typeof useUpdateBranchesMutation>;
export type UpdateBranchesMutationResult = Apollo.MutationResult<UpdateBranchesMutation>;
export type UpdateBranchesMutationOptions = Apollo.BaseMutationOptions<
  UpdateBranchesMutation,
  UpdateBranchesMutationVariables
>;
export const CreateClientFileDocument = gql`
  mutation CreateClientFile($input: CreateClientFileInput!) {
    createClientFile(input: $input) {
      id
      type
      dateOfFile
      expiresAt
      value
      owner {
        id
      }
      file {
        id
        size
        path
        createdBy {
          id
        }
      }
    }
  }
`;
export type CreateClientFileMutationFn = Apollo.MutationFunction<
  CreateClientFileMutation,
  CreateClientFileMutationVariables
>;

/**
 * __useCreateClientFileMutation__
 *
 * To run a mutation, you first call `useCreateClientFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientFileMutation, { data, loading, error }] = useCreateClientFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientFileMutation,
    CreateClientFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateClientFileMutation, CreateClientFileMutationVariables>(
    CreateClientFileDocument,
    options,
  );
}
export type CreateClientFileMutationHookResult = ReturnType<typeof useCreateClientFileMutation>;
export type CreateClientFileMutationResult = Apollo.MutationResult<CreateClientFileMutation>;
export type CreateClientFileMutationOptions = Apollo.BaseMutationOptions<
  CreateClientFileMutation,
  CreateClientFileMutationVariables
>;
export const DeleteClientFilesDocument = gql`
  mutation DeleteClientFiles($input: DeleteInput!) {
    deleteClientFiles(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientFilesMutationFn = Apollo.MutationFunction<
  DeleteClientFilesMutation,
  DeleteClientFilesMutationVariables
>;

/**
 * __useDeleteClientFilesMutation__
 *
 * To run a mutation, you first call `useDeleteClientFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientFilesMutation, { data, loading, error }] = useDeleteClientFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientFilesMutation,
    DeleteClientFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteClientFilesMutation, DeleteClientFilesMutationVariables>(
    DeleteClientFilesDocument,
    options,
  );
}
export type DeleteClientFilesMutationHookResult = ReturnType<typeof useDeleteClientFilesMutation>;
export type DeleteClientFilesMutationResult = Apollo.MutationResult<DeleteClientFilesMutation>;
export type DeleteClientFilesMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientFilesMutation,
  DeleteClientFilesMutationVariables
>;
export const RestoreClientFilesDocument = gql`
  mutation RestoreClientFiles($input: QueryByIdsInput!) {
    restoreClientFiles(input: $input) {
      id
      delete {
        id
      }
      archive {
        id
      }
    }
  }
`;
export type RestoreClientFilesMutationFn = Apollo.MutationFunction<
  RestoreClientFilesMutation,
  RestoreClientFilesMutationVariables
>;

/**
 * __useRestoreClientFilesMutation__
 *
 * To run a mutation, you first call `useRestoreClientFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreClientFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreClientFilesMutation, { data, loading, error }] = useRestoreClientFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreClientFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreClientFilesMutation,
    RestoreClientFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreClientFilesMutation, RestoreClientFilesMutationVariables>(
    RestoreClientFilesDocument,
    options,
  );
}
export type RestoreClientFilesMutationHookResult = ReturnType<typeof useRestoreClientFilesMutation>;
export type RestoreClientFilesMutationResult = Apollo.MutationResult<RestoreClientFilesMutation>;
export type RestoreClientFilesMutationOptions = Apollo.BaseMutationOptions<
  RestoreClientFilesMutation,
  RestoreClientFilesMutationVariables
>;
export const UpdateClientFilesDocument = gql`
  mutation UpdateClientFiles($input: UpdateClientFilesInput!) {
    updateClientFiles(input: $input) {
      id
      type
      dateOfFile
      expiresAt
      value
      owner {
        id
      }
    }
  }
`;
export type UpdateClientFilesMutationFn = Apollo.MutationFunction<
  UpdateClientFilesMutation,
  UpdateClientFilesMutationVariables
>;

/**
 * __useUpdateClientFilesMutation__
 *
 * To run a mutation, you first call `useUpdateClientFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientFilesMutation, { data, loading, error }] = useUpdateClientFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientFilesMutation,
    UpdateClientFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientFilesMutation, UpdateClientFilesMutationVariables>(
    UpdateClientFilesDocument,
    options,
  );
}
export type UpdateClientFilesMutationHookResult = ReturnType<typeof useUpdateClientFilesMutation>;
export type UpdateClientFilesMutationResult = Apollo.MutationResult<UpdateClientFilesMutation>;
export type UpdateClientFilesMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientFilesMutation,
  UpdateClientFilesMutationVariables
>;
export const SetClientMedicationsDocument = gql`
  mutation SetClientMedications($input: SetClientMedicationsInput!) {
    setClientMedications(input: $input) {
      id
      client {
        id
      }
      medication {
        id
      }
    }
  }
`;
export type SetClientMedicationsMutationFn = Apollo.MutationFunction<
  SetClientMedicationsMutation,
  SetClientMedicationsMutationVariables
>;

/**
 * __useSetClientMedicationsMutation__
 *
 * To run a mutation, you first call `useSetClientMedicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClientMedicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClientMedicationsMutation, { data, loading, error }] = useSetClientMedicationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetClientMedicationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetClientMedicationsMutation,
    SetClientMedicationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetClientMedicationsMutation, SetClientMedicationsMutationVariables>(
    SetClientMedicationsDocument,
    options,
  );
}
export type SetClientMedicationsMutationHookResult = ReturnType<
  typeof useSetClientMedicationsMutation
>;
export type SetClientMedicationsMutationResult =
  Apollo.MutationResult<SetClientMedicationsMutation>;
export type SetClientMedicationsMutationOptions = Apollo.BaseMutationOptions<
  SetClientMedicationsMutation,
  SetClientMedicationsMutationVariables
>;
export const CreateClientObservationDocument = gql`
  mutation CreateClientObservation($input: CreateClientObservationInput!) {
    createClientObservation(input: $input) {
      id
      date
      notes
      nursesName
      bloodSystolic
      bloodDiastolic
      heart
      temp
      spo2
      respiration
      stoolType
      urineColor
      givenFood {
        id
        description
        kilojoules
      }
      givenMedications {
        id
        quantity
        medication {
          id
          name
        }
      }
      client {
        id
      }
      member {
        id
      }
    }
  }
`;
export type CreateClientObservationMutationFn = Apollo.MutationFunction<
  CreateClientObservationMutation,
  CreateClientObservationMutationVariables
>;

/**
 * __useCreateClientObservationMutation__
 *
 * To run a mutation, you first call `useCreateClientObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientObservationMutation, { data, loading, error }] = useCreateClientObservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientObservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientObservationMutation,
    CreateClientObservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientObservationMutation,
    CreateClientObservationMutationVariables
  >(CreateClientObservationDocument, options);
}
export type CreateClientObservationMutationHookResult = ReturnType<
  typeof useCreateClientObservationMutation
>;
export type CreateClientObservationMutationResult =
  Apollo.MutationResult<CreateClientObservationMutation>;
export type CreateClientObservationMutationOptions = Apollo.BaseMutationOptions<
  CreateClientObservationMutation,
  CreateClientObservationMutationVariables
>;
export const CreateClientObservationWithEventDocument = gql`
  mutation CreateClientObservationWithEvent($input: CreateClientObservationWithEventInput!) {
    createClientObservationWithEvent(input: $input) {
      id
      date
      notes
      nursesName
      bloodSystolic
      bloodDiastolic
      heart
      temp
      spo2
      respiration
      stoolType
      urineColor
      givenFood {
        id
        description
        kilojoules
      }
      givenMedications {
        id
        quantity
        medication {
          id
          name
        }
      }
      event {
        id
      }
      member {
        id
      }
    }
  }
`;
export type CreateClientObservationWithEventMutationFn = Apollo.MutationFunction<
  CreateClientObservationWithEventMutation,
  CreateClientObservationWithEventMutationVariables
>;

/**
 * __useCreateClientObservationWithEventMutation__
 *
 * To run a mutation, you first call `useCreateClientObservationWithEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientObservationWithEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientObservationWithEventMutation, { data, loading, error }] = useCreateClientObservationWithEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientObservationWithEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientObservationWithEventMutation,
    CreateClientObservationWithEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientObservationWithEventMutation,
    CreateClientObservationWithEventMutationVariables
  >(CreateClientObservationWithEventDocument, options);
}
export type CreateClientObservationWithEventMutationHookResult = ReturnType<
  typeof useCreateClientObservationWithEventMutation
>;
export type CreateClientObservationWithEventMutationResult =
  Apollo.MutationResult<CreateClientObservationWithEventMutation>;
export type CreateClientObservationWithEventMutationOptions = Apollo.BaseMutationOptions<
  CreateClientObservationWithEventMutation,
  CreateClientObservationWithEventMutationVariables
>;
export const DeleteClientObservationsDocument = gql`
  mutation DeleteClientObservations($input: DeleteInput!) {
    deleteClientObservations(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientObservationsMutationFn = Apollo.MutationFunction<
  DeleteClientObservationsMutation,
  DeleteClientObservationsMutationVariables
>;

/**
 * __useDeleteClientObservationsMutation__
 *
 * To run a mutation, you first call `useDeleteClientObservationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientObservationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientObservationsMutation, { data, loading, error }] = useDeleteClientObservationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientObservationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientObservationsMutation,
    DeleteClientObservationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientObservationsMutation,
    DeleteClientObservationsMutationVariables
  >(DeleteClientObservationsDocument, options);
}
export type DeleteClientObservationsMutationHookResult = ReturnType<
  typeof useDeleteClientObservationsMutation
>;
export type DeleteClientObservationsMutationResult =
  Apollo.MutationResult<DeleteClientObservationsMutation>;
export type DeleteClientObservationsMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientObservationsMutation,
  DeleteClientObservationsMutationVariables
>;
export const UpdateClientObservationDocument = gql`
  mutation UpdateClientObservation($input: UpdateClientObservationInput!) {
    updateClientObservation(input: $input) {
      id
      date
      notes
      nursesName
      bloodSystolic
      bloodDiastolic
      heart
      temp
      spo2
      respiration
      stoolType
      urineColor
      givenFood {
        id
        description
        kilojoules
      }
      givenMedications {
        id
        quantity
        medication {
          id
          name
        }
      }
      member {
        id
      }
    }
  }
`;
export type UpdateClientObservationMutationFn = Apollo.MutationFunction<
  UpdateClientObservationMutation,
  UpdateClientObservationMutationVariables
>;

/**
 * __useUpdateClientObservationMutation__
 *
 * To run a mutation, you first call `useUpdateClientObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientObservationMutation, { data, loading, error }] = useUpdateClientObservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientObservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientObservationMutation,
    UpdateClientObservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientObservationMutation,
    UpdateClientObservationMutationVariables
  >(UpdateClientObservationDocument, options);
}
export type UpdateClientObservationMutationHookResult = ReturnType<
  typeof useUpdateClientObservationMutation
>;
export type UpdateClientObservationMutationResult =
  Apollo.MutationResult<UpdateClientObservationMutation>;
export type UpdateClientObservationMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientObservationMutation,
  UpdateClientObservationMutationVariables
>;
export const SetClientTeamMembersDocument = gql`
  mutation SetClientTeamMembers($input: SetClientTeamMembersInput!) {
    setClientTeamMembers(input: $input) {
      id
      preferred
      client {
        id
        ...PersonNames
      }
      member {
        id
        color
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;
export type SetClientTeamMembersMutationFn = Apollo.MutationFunction<
  SetClientTeamMembersMutation,
  SetClientTeamMembersMutationVariables
>;

/**
 * __useSetClientTeamMembersMutation__
 *
 * To run a mutation, you first call `useSetClientTeamMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClientTeamMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClientTeamMembersMutation, { data, loading, error }] = useSetClientTeamMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetClientTeamMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetClientTeamMembersMutation,
    SetClientTeamMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetClientTeamMembersMutation, SetClientTeamMembersMutationVariables>(
    SetClientTeamMembersDocument,
    options,
  );
}
export type SetClientTeamMembersMutationHookResult = ReturnType<
  typeof useSetClientTeamMembersMutation
>;
export type SetClientTeamMembersMutationResult =
  Apollo.MutationResult<SetClientTeamMembersMutation>;
export type SetClientTeamMembersMutationOptions = Apollo.BaseMutationOptions<
  SetClientTeamMembersMutation,
  SetClientTeamMembersMutationVariables
>;
export const ArchiveClientsDocument = gql`
  mutation ArchiveClients($input: ArchiveInput!) {
    archiveClients(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchiveClientsMutationFn = Apollo.MutationFunction<
  ArchiveClientsMutation,
  ArchiveClientsMutationVariables
>;

/**
 * __useArchiveClientsMutation__
 *
 * To run a mutation, you first call `useArchiveClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveClientsMutation, { data, loading, error }] = useArchiveClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveClientsMutation, ArchiveClientsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveClientsMutation, ArchiveClientsMutationVariables>(
    ArchiveClientsDocument,
    options,
  );
}
export type ArchiveClientsMutationHookResult = ReturnType<typeof useArchiveClientsMutation>;
export type ArchiveClientsMutationResult = Apollo.MutationResult<ArchiveClientsMutation>;
export type ArchiveClientsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveClientsMutation,
  ArchiveClientsMutationVariables
>;
export const CreateClientsDocument = gql`
  mutation CreateClients($input: CreateClientsInput!) {
    createClients(input: $input) {
      id
      email
      phone
      dob
      color
      ndisId
      ndisPlanStartDate
      ndisPlanEndDate
      ndisPlanInitialFundsCore
      ndisPlanInitialFundsCs
      ndisPlanInitialFundsCb
      defaultEventDuration
      notes
      active
      allowMedication
      allowObservations
      allowAutomaticEventPopulation
      bloodSystolicUpperThreshold1
      bloodSystolicLowerThreshold1
      bloodDiastolicUpperThreshold1
      bloodDiastolicLowerThreshold1
      heartUpperThreshold1
      heartLowerThreshold1
      spo2UpperThreshold1
      spo2LowerThreshold1
      respirationUpperThreshold1
      respirationLowerThreshold1
      bloodSystolicUpperThreshold2
      bloodSystolicLowerThreshold2
      bloodDiastolicUpperThreshold2
      bloodDiastolicLowerThreshold2
      heartUpperThreshold2
      heartLowerThreshold2
      spo2UpperThreshold2
      spo2LowerThreshold2
      respirationUpperThreshold2
      respirationLowerThreshold2
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branch {
        id
        name
      }
      planManager {
        id
        name
      }
      supportCoordinator {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type CreateClientsMutationFn = Apollo.MutationFunction<
  CreateClientsMutation,
  CreateClientsMutationVariables
>;

/**
 * __useCreateClientsMutation__
 *
 * To run a mutation, you first call `useCreateClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientsMutation, { data, loading, error }] = useCreateClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateClientsMutation, CreateClientsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateClientsMutation, CreateClientsMutationVariables>(
    CreateClientsDocument,
    options,
  );
}
export type CreateClientsMutationHookResult = ReturnType<typeof useCreateClientsMutation>;
export type CreateClientsMutationResult = Apollo.MutationResult<CreateClientsMutation>;
export type CreateClientsMutationOptions = Apollo.BaseMutationOptions<
  CreateClientsMutation,
  CreateClientsMutationVariables
>;
export const DeleteClientsDocument = gql`
  mutation DeleteClients($input: DeleteInput!) {
    deleteClients(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientsMutationFn = Apollo.MutationFunction<
  DeleteClientsMutation,
  DeleteClientsMutationVariables
>;

/**
 * __useDeleteClientsMutation__
 *
 * To run a mutation, you first call `useDeleteClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientsMutation, { data, loading, error }] = useDeleteClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteClientsMutation, DeleteClientsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteClientsMutation, DeleteClientsMutationVariables>(
    DeleteClientsDocument,
    options,
  );
}
export type DeleteClientsMutationHookResult = ReturnType<typeof useDeleteClientsMutation>;
export type DeleteClientsMutationResult = Apollo.MutationResult<DeleteClientsMutation>;
export type DeleteClientsMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientsMutation,
  DeleteClientsMutationVariables
>;
export const RestoreClientsDocument = gql`
  mutation RestoreClients($input: QueryByIdsInput!) {
    restoreClients(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
      archive {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreClientsMutationFn = Apollo.MutationFunction<
  RestoreClientsMutation,
  RestoreClientsMutationVariables
>;

/**
 * __useRestoreClientsMutation__
 *
 * To run a mutation, you first call `useRestoreClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreClientsMutation, { data, loading, error }] = useRestoreClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<RestoreClientsMutation, RestoreClientsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreClientsMutation, RestoreClientsMutationVariables>(
    RestoreClientsDocument,
    options,
  );
}
export type RestoreClientsMutationHookResult = ReturnType<typeof useRestoreClientsMutation>;
export type RestoreClientsMutationResult = Apollo.MutationResult<RestoreClientsMutation>;
export type RestoreClientsMutationOptions = Apollo.BaseMutationOptions<
  RestoreClientsMutation,
  RestoreClientsMutationVariables
>;
export const UpdateClientsDocument = gql`
  mutation UpdateClients($input: UpdateClientsInput!) {
    updateClients(input: $input) {
      id
      email
      phone
      dob
      color
      ndisId
      ndisPlanStartDate
      ndisPlanEndDate
      ndisPlanInitialFundsCore
      ndisPlanInitialFundsCs
      ndisPlanInitialFundsCb
      defaultEventDuration
      notes
      active
      allowMedication
      allowObservations
      allowAutomaticEventPopulation
      contactsName
      contactsEmail
      contactsPhone
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branch {
        id
        name
      }
      planManager {
        id
        name
      }
      supportCoordinator {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type UpdateClientsMutationFn = Apollo.MutationFunction<
  UpdateClientsMutation,
  UpdateClientsMutationVariables
>;

/**
 * __useUpdateClientsMutation__
 *
 * To run a mutation, you first call `useUpdateClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientsMutation, { data, loading, error }] = useUpdateClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientsMutation, UpdateClientsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientsMutation, UpdateClientsMutationVariables>(
    UpdateClientsDocument,
    options,
  );
}
export type UpdateClientsMutationHookResult = ReturnType<typeof useUpdateClientsMutation>;
export type UpdateClientsMutationResult = Apollo.MutationResult<UpdateClientsMutation>;
export type UpdateClientsMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientsMutation,
  UpdateClientsMutationVariables
>;
export const UpdateClientsColorsDocument = gql`
  mutation UpdateClientsColors($input: UpdateClientsInput!) {
    updateClients(input: $input) {
      id
      color
    }
  }
`;
export type UpdateClientsColorsMutationFn = Apollo.MutationFunction<
  UpdateClientsColorsMutation,
  UpdateClientsColorsMutationVariables
>;

/**
 * __useUpdateClientsColorsMutation__
 *
 * To run a mutation, you first call `useUpdateClientsColorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientsColorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientsColorsMutation, { data, loading, error }] = useUpdateClientsColorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientsColorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientsColorsMutation,
    UpdateClientsColorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientsColorsMutation, UpdateClientsColorsMutationVariables>(
    UpdateClientsColorsDocument,
    options,
  );
}
export type UpdateClientsColorsMutationHookResult = ReturnType<
  typeof useUpdateClientsColorsMutation
>;
export type UpdateClientsColorsMutationResult = Apollo.MutationResult<UpdateClientsColorsMutation>;
export type UpdateClientsColorsMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientsColorsMutation,
  UpdateClientsColorsMutationVariables
>;
export const UpdateClientsNotesDocument = gql`
  mutation UpdateClientsNotes($input: UpdateClientsInput!) {
    updateClients(input: $input) {
      id
      notes
    }
  }
`;
export type UpdateClientsNotesMutationFn = Apollo.MutationFunction<
  UpdateClientsNotesMutation,
  UpdateClientsNotesMutationVariables
>;

/**
 * __useUpdateClientsNotesMutation__
 *
 * To run a mutation, you first call `useUpdateClientsNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientsNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientsNotesMutation, { data, loading, error }] = useUpdateClientsNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientsNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientsNotesMutation,
    UpdateClientsNotesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientsNotesMutation, UpdateClientsNotesMutationVariables>(
    UpdateClientsNotesDocument,
    options,
  );
}
export type UpdateClientsNotesMutationHookResult = ReturnType<typeof useUpdateClientsNotesMutation>;
export type UpdateClientsNotesMutationResult = Apollo.MutationResult<UpdateClientsNotesMutation>;
export type UpdateClientsNotesMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientsNotesMutation,
  UpdateClientsNotesMutationVariables
>;
export const UpdateClientsObservationThresholdsDocument = gql`
  mutation UpdateClientsObservationThresholds($input: UpdateClientsInput!) {
    updateClients(input: $input) {
      id
      bloodSystolicUpperThreshold1
      bloodSystolicLowerThreshold1
      bloodDiastolicUpperThreshold1
      bloodDiastolicLowerThreshold1
      heartUpperThreshold1
      heartLowerThreshold1
      spo2UpperThreshold1
      spo2LowerThreshold1
      respirationUpperThreshold1
      respirationLowerThreshold1
      bloodSystolicUpperThreshold2
      bloodSystolicLowerThreshold2
      bloodDiastolicUpperThreshold2
      bloodDiastolicLowerThreshold2
      heartUpperThreshold2
      heartLowerThreshold2
      spo2UpperThreshold2
      spo2LowerThreshold2
      respirationUpperThreshold2
      respirationLowerThreshold2
    }
  }
`;
export type UpdateClientsObservationThresholdsMutationFn = Apollo.MutationFunction<
  UpdateClientsObservationThresholdsMutation,
  UpdateClientsObservationThresholdsMutationVariables
>;

/**
 * __useUpdateClientsObservationThresholdsMutation__
 *
 * To run a mutation, you first call `useUpdateClientsObservationThresholdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientsObservationThresholdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientsObservationThresholdsMutation, { data, loading, error }] = useUpdateClientsObservationThresholdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientsObservationThresholdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientsObservationThresholdsMutation,
    UpdateClientsObservationThresholdsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientsObservationThresholdsMutation,
    UpdateClientsObservationThresholdsMutationVariables
  >(UpdateClientsObservationThresholdsDocument, options);
}
export type UpdateClientsObservationThresholdsMutationHookResult = ReturnType<
  typeof useUpdateClientsObservationThresholdsMutation
>;
export type UpdateClientsObservationThresholdsMutationResult =
  Apollo.MutationResult<UpdateClientsObservationThresholdsMutation>;
export type UpdateClientsObservationThresholdsMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientsObservationThresholdsMutation,
  UpdateClientsObservationThresholdsMutationVariables
>;
export const CreateEventFileDocument = gql`
  mutation CreateEventFile($input: CreateEventFileInput!) {
    createEventFile(input: $input) {
      id
      type
      dateOfFile
      value
      owner {
        id
      }
      file {
        id
        size
        path
        createdBy {
          id
        }
      }
    }
  }
`;
export type CreateEventFileMutationFn = Apollo.MutationFunction<
  CreateEventFileMutation,
  CreateEventFileMutationVariables
>;

/**
 * __useCreateEventFileMutation__
 *
 * To run a mutation, you first call `useCreateEventFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventFileMutation, { data, loading, error }] = useCreateEventFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventFileMutation,
    CreateEventFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEventFileMutation, CreateEventFileMutationVariables>(
    CreateEventFileDocument,
    options,
  );
}
export type CreateEventFileMutationHookResult = ReturnType<typeof useCreateEventFileMutation>;
export type CreateEventFileMutationResult = Apollo.MutationResult<CreateEventFileMutation>;
export type CreateEventFileMutationOptions = Apollo.BaseMutationOptions<
  CreateEventFileMutation,
  CreateEventFileMutationVariables
>;
export const DeleteEventFilesDocument = gql`
  mutation DeleteEventFiles($input: DeleteInput!) {
    deleteEventFiles(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteEventFilesMutationFn = Apollo.MutationFunction<
  DeleteEventFilesMutation,
  DeleteEventFilesMutationVariables
>;

/**
 * __useDeleteEventFilesMutation__
 *
 * To run a mutation, you first call `useDeleteEventFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventFilesMutation, { data, loading, error }] = useDeleteEventFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEventFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventFilesMutation,
    DeleteEventFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEventFilesMutation, DeleteEventFilesMutationVariables>(
    DeleteEventFilesDocument,
    options,
  );
}
export type DeleteEventFilesMutationHookResult = ReturnType<typeof useDeleteEventFilesMutation>;
export type DeleteEventFilesMutationResult = Apollo.MutationResult<DeleteEventFilesMutation>;
export type DeleteEventFilesMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventFilesMutation,
  DeleteEventFilesMutationVariables
>;
export const RestoreEventFilesDocument = gql`
  mutation RestoreEventFiles($input: QueryByIdsInput!) {
    restoreEventFiles(input: $input) {
      id
      delete {
        id
      }
      archive {
        id
      }
    }
  }
`;
export type RestoreEventFilesMutationFn = Apollo.MutationFunction<
  RestoreEventFilesMutation,
  RestoreEventFilesMutationVariables
>;

/**
 * __useRestoreEventFilesMutation__
 *
 * To run a mutation, you first call `useRestoreEventFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreEventFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreEventFilesMutation, { data, loading, error }] = useRestoreEventFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreEventFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreEventFilesMutation,
    RestoreEventFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreEventFilesMutation, RestoreEventFilesMutationVariables>(
    RestoreEventFilesDocument,
    options,
  );
}
export type RestoreEventFilesMutationHookResult = ReturnType<typeof useRestoreEventFilesMutation>;
export type RestoreEventFilesMutationResult = Apollo.MutationResult<RestoreEventFilesMutation>;
export type RestoreEventFilesMutationOptions = Apollo.BaseMutationOptions<
  RestoreEventFilesMutation,
  RestoreEventFilesMutationVariables
>;
export const UpdateEventFilesDocument = gql`
  mutation UpdateEventFiles($input: UpdateEventFilesInput!) {
    updateEventFiles(input: $input) {
      id
      type
      dateOfFile
      value
      owner {
        id
      }
    }
  }
`;
export type UpdateEventFilesMutationFn = Apollo.MutationFunction<
  UpdateEventFilesMutation,
  UpdateEventFilesMutationVariables
>;

/**
 * __useUpdateEventFilesMutation__
 *
 * To run a mutation, you first call `useUpdateEventFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventFilesMutation, { data, loading, error }] = useUpdateEventFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventFilesMutation,
    UpdateEventFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEventFilesMutation, UpdateEventFilesMutationVariables>(
    UpdateEventFilesDocument,
    options,
  );
}
export type UpdateEventFilesMutationHookResult = ReturnType<typeof useUpdateEventFilesMutation>;
export type UpdateEventFilesMutationResult = Apollo.MutationResult<UpdateEventFilesMutation>;
export type UpdateEventFilesMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventFilesMutation,
  UpdateEventFilesMutationVariables
>;
export const ApproveEventsTravelDocument = gql`
  mutation ApproveEventsTravel($input: QueryByIdsInput!) {
    approveEventsTravel(input: $input) {
      id
      travelApprovedAt
      travelApprovedBy {
        id
      }
    }
  }
`;
export type ApproveEventsTravelMutationFn = Apollo.MutationFunction<
  ApproveEventsTravelMutation,
  ApproveEventsTravelMutationVariables
>;

/**
 * __useApproveEventsTravelMutation__
 *
 * To run a mutation, you first call `useApproveEventsTravelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveEventsTravelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveEventsTravelMutation, { data, loading, error }] = useApproveEventsTravelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveEventsTravelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveEventsTravelMutation,
    ApproveEventsTravelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApproveEventsTravelMutation, ApproveEventsTravelMutationVariables>(
    ApproveEventsTravelDocument,
    options,
  );
}
export type ApproveEventsTravelMutationHookResult = ReturnType<
  typeof useApproveEventsTravelMutation
>;
export type ApproveEventsTravelMutationResult = Apollo.MutationResult<ApproveEventsTravelMutation>;
export type ApproveEventsTravelMutationOptions = Apollo.BaseMutationOptions<
  ApproveEventsTravelMutation,
  ApproveEventsTravelMutationVariables
>;
export const ArchiveEventsDocument = gql`
  mutation ArchiveEvents($input: ArchiveInput!) {
    archiveEvents(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchiveEventsMutationFn = Apollo.MutationFunction<
  ArchiveEventsMutation,
  ArchiveEventsMutationVariables
>;

/**
 * __useArchiveEventsMutation__
 *
 * To run a mutation, you first call `useArchiveEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveEventsMutation, { data, loading, error }] = useArchiveEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveEventsMutation, ArchiveEventsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveEventsMutation, ArchiveEventsMutationVariables>(
    ArchiveEventsDocument,
    options,
  );
}
export type ArchiveEventsMutationHookResult = ReturnType<typeof useArchiveEventsMutation>;
export type ArchiveEventsMutationResult = Apollo.MutationResult<ArchiveEventsMutation>;
export type ArchiveEventsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveEventsMutation,
  ArchiveEventsMutationVariables
>;
export const AutomaticallyAssignAttendeeDocument = gql`
  mutation AutomaticallyAssignAttendee($input: QueryByIdInput!) {
    updateEventAutomaticAttendee(input: $input) {
      id
      conflictExists
      memberAssignedAutomatically
      scheduleNotes
      member {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;
export type AutomaticallyAssignAttendeeMutationFn = Apollo.MutationFunction<
  AutomaticallyAssignAttendeeMutation,
  AutomaticallyAssignAttendeeMutationVariables
>;

/**
 * __useAutomaticallyAssignAttendeeMutation__
 *
 * To run a mutation, you first call `useAutomaticallyAssignAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutomaticallyAssignAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [automaticallyAssignAttendeeMutation, { data, loading, error }] = useAutomaticallyAssignAttendeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAutomaticallyAssignAttendeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AutomaticallyAssignAttendeeMutation,
    AutomaticallyAssignAttendeeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AutomaticallyAssignAttendeeMutation,
    AutomaticallyAssignAttendeeMutationVariables
  >(AutomaticallyAssignAttendeeDocument, options);
}
export type AutomaticallyAssignAttendeeMutationHookResult = ReturnType<
  typeof useAutomaticallyAssignAttendeeMutation
>;
export type AutomaticallyAssignAttendeeMutationResult =
  Apollo.MutationResult<AutomaticallyAssignAttendeeMutation>;
export type AutomaticallyAssignAttendeeMutationOptions = Apollo.BaseMutationOptions<
  AutomaticallyAssignAttendeeMutation,
  AutomaticallyAssignAttendeeMutationVariables
>;
export const CancelEventsDocument = gql`
  mutation CancelEvents($input: EventsCancelInput!) {
    cancelEvents(input: $input) {
      id
      billable
      payable
      cancel {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type CancelEventsMutationFn = Apollo.MutationFunction<
  CancelEventsMutation,
  CancelEventsMutationVariables
>;

/**
 * __useCancelEventsMutation__
 *
 * To run a mutation, you first call `useCancelEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEventsMutation, { data, loading, error }] = useCancelEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelEventsMutation, CancelEventsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelEventsMutation, CancelEventsMutationVariables>(
    CancelEventsDocument,
    options,
  );
}
export type CancelEventsMutationHookResult = ReturnType<typeof useCancelEventsMutation>;
export type CancelEventsMutationResult = Apollo.MutationResult<CancelEventsMutation>;
export type CancelEventsMutationOptions = Apollo.BaseMutationOptions<
  CancelEventsMutation,
  CancelEventsMutationVariables
>;
export const ClaimEventDocument = gql`
  mutation ClaimEvent($input: QueryByIdInput!) {
    claimEvent(input: $input) {
      value
    }
  }
`;
export type ClaimEventMutationFn = Apollo.MutationFunction<
  ClaimEventMutation,
  ClaimEventMutationVariables
>;

/**
 * __useClaimEventMutation__
 *
 * To run a mutation, you first call `useClaimEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimEventMutation, { data, loading, error }] = useClaimEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimEventMutation(
  baseOptions?: Apollo.MutationHookOptions<ClaimEventMutation, ClaimEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimEventMutation, ClaimEventMutationVariables>(
    ClaimEventDocument,
    options,
  );
}
export type ClaimEventMutationHookResult = ReturnType<typeof useClaimEventMutation>;
export type ClaimEventMutationResult = Apollo.MutationResult<ClaimEventMutation>;
export type ClaimEventMutationOptions = Apollo.BaseMutationOptions<
  ClaimEventMutation,
  ClaimEventMutationVariables
>;
export const ClockOffEventDocument = gql`
  mutation ClockOffEvent($input: ClockEventInput!) {
    clockEvent(input: $input) {
      id
      clockedOffAt
      clockedOffLatitude
      clockedOffLongitude
      clockedOffBy {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;
export type ClockOffEventMutationFn = Apollo.MutationFunction<
  ClockOffEventMutation,
  ClockOffEventMutationVariables
>;

/**
 * __useClockOffEventMutation__
 *
 * To run a mutation, you first call `useClockOffEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClockOffEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clockOffEventMutation, { data, loading, error }] = useClockOffEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClockOffEventMutation(
  baseOptions?: Apollo.MutationHookOptions<ClockOffEventMutation, ClockOffEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClockOffEventMutation, ClockOffEventMutationVariables>(
    ClockOffEventDocument,
    options,
  );
}
export type ClockOffEventMutationHookResult = ReturnType<typeof useClockOffEventMutation>;
export type ClockOffEventMutationResult = Apollo.MutationResult<ClockOffEventMutation>;
export type ClockOffEventMutationOptions = Apollo.BaseMutationOptions<
  ClockOffEventMutation,
  ClockOffEventMutationVariables
>;
export const ClockOffEventAttendedDocument = gql`
  mutation ClockOffEventAttended($input: ClockEventInput!) {
    clockEventAttended(input: $input) {
      id
      clockedOffAt
    }
  }
`;
export type ClockOffEventAttendedMutationFn = Apollo.MutationFunction<
  ClockOffEventAttendedMutation,
  ClockOffEventAttendedMutationVariables
>;

/**
 * __useClockOffEventAttendedMutation__
 *
 * To run a mutation, you first call `useClockOffEventAttendedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClockOffEventAttendedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clockOffEventAttendedMutation, { data, loading, error }] = useClockOffEventAttendedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClockOffEventAttendedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClockOffEventAttendedMutation,
    ClockOffEventAttendedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClockOffEventAttendedMutation, ClockOffEventAttendedMutationVariables>(
    ClockOffEventAttendedDocument,
    options,
  );
}
export type ClockOffEventAttendedMutationHookResult = ReturnType<
  typeof useClockOffEventAttendedMutation
>;
export type ClockOffEventAttendedMutationResult =
  Apollo.MutationResult<ClockOffEventAttendedMutation>;
export type ClockOffEventAttendedMutationOptions = Apollo.BaseMutationOptions<
  ClockOffEventAttendedMutation,
  ClockOffEventAttendedMutationVariables
>;
export const ClockOnEventDocument = gql`
  mutation ClockOnEvent($input: ClockEventInput!) {
    clockEvent(input: $input) {
      id
      clockedOnAt
      clockedOnLatitude
      clockedOnLongitude
      clockedOnBy {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;
export type ClockOnEventMutationFn = Apollo.MutationFunction<
  ClockOnEventMutation,
  ClockOnEventMutationVariables
>;

/**
 * __useClockOnEventMutation__
 *
 * To run a mutation, you first call `useClockOnEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClockOnEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clockOnEventMutation, { data, loading, error }] = useClockOnEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClockOnEventMutation(
  baseOptions?: Apollo.MutationHookOptions<ClockOnEventMutation, ClockOnEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClockOnEventMutation, ClockOnEventMutationVariables>(
    ClockOnEventDocument,
    options,
  );
}
export type ClockOnEventMutationHookResult = ReturnType<typeof useClockOnEventMutation>;
export type ClockOnEventMutationResult = Apollo.MutationResult<ClockOnEventMutation>;
export type ClockOnEventMutationOptions = Apollo.BaseMutationOptions<
  ClockOnEventMutation,
  ClockOnEventMutationVariables
>;
export const ClockOnEventAttendedDocument = gql`
  mutation ClockOnEventAttended($input: ClockEventInput!) {
    clockEventAttended(input: $input) {
      id
      clockedOnAt
    }
  }
`;
export type ClockOnEventAttendedMutationFn = Apollo.MutationFunction<
  ClockOnEventAttendedMutation,
  ClockOnEventAttendedMutationVariables
>;

/**
 * __useClockOnEventAttendedMutation__
 *
 * To run a mutation, you first call `useClockOnEventAttendedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClockOnEventAttendedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clockOnEventAttendedMutation, { data, loading, error }] = useClockOnEventAttendedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClockOnEventAttendedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClockOnEventAttendedMutation,
    ClockOnEventAttendedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClockOnEventAttendedMutation, ClockOnEventAttendedMutationVariables>(
    ClockOnEventAttendedDocument,
    options,
  );
}
export type ClockOnEventAttendedMutationHookResult = ReturnType<
  typeof useClockOnEventAttendedMutation
>;
export type ClockOnEventAttendedMutationResult =
  Apollo.MutationResult<ClockOnEventAttendedMutation>;
export type ClockOnEventAttendedMutationOptions = Apollo.BaseMutationOptions<
  ClockOnEventAttendedMutation,
  ClockOnEventAttendedMutationVariables
>;
export const CreateEventDocument = gql`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      published
      billable
      payable
      startAt
      duration
      passive
      passiveStartAt
      activeAssist
      summary
      publicHoliday
      conflictExists
      ...AddressFull
      ...AddressGeolocation
      client {
        id
      }
      member {
        id
      }
    }
  }
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type CreateEventMutationFn = Apollo.MutationFunction<
  CreateEventMutation,
  CreateEventMutationVariables
>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(
    CreateEventDocument,
    options,
  );
}
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<
  CreateEventMutation,
  CreateEventMutationVariables
>;
export const DeleteEventsDocument = gql`
  mutation DeleteEvents($input: DeleteInput!) {
    deleteEvents(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteEventsMutationFn = Apollo.MutationFunction<
  DeleteEventsMutation,
  DeleteEventsMutationVariables
>;

/**
 * __useDeleteEventsMutation__
 *
 * To run a mutation, you first call `useDeleteEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventsMutation, { data, loading, error }] = useDeleteEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEventsMutation, DeleteEventsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEventsMutation, DeleteEventsMutationVariables>(
    DeleteEventsDocument,
    options,
  );
}
export type DeleteEventsMutationHookResult = ReturnType<typeof useDeleteEventsMutation>;
export type DeleteEventsMutationResult = Apollo.MutationResult<DeleteEventsMutation>;
export type DeleteEventsMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventsMutation,
  DeleteEventsMutationVariables
>;
export const RestoreEventsDocument = gql`
  mutation RestoreEvents($input: QueryByIdsInput!) {
    restoreEvents(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
      archive {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreEventsMutationFn = Apollo.MutationFunction<
  RestoreEventsMutation,
  RestoreEventsMutationVariables
>;

/**
 * __useRestoreEventsMutation__
 *
 * To run a mutation, you first call `useRestoreEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreEventsMutation, { data, loading, error }] = useRestoreEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<RestoreEventsMutation, RestoreEventsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreEventsMutation, RestoreEventsMutationVariables>(
    RestoreEventsDocument,
    options,
  );
}
export type RestoreEventsMutationHookResult = ReturnType<typeof useRestoreEventsMutation>;
export type RestoreEventsMutationResult = Apollo.MutationResult<RestoreEventsMutation>;
export type RestoreEventsMutationOptions = Apollo.BaseMutationOptions<
  RestoreEventsMutation,
  RestoreEventsMutationVariables
>;
export const UpdateEventDocument = gql`
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
      hasNotes
      published
      billable
      payable
      startAt
      endAt
      clockedOnAt
      clockedOnBy {
        id
      }
      clockedOffAt
      clockedOffBy {
        id
      }
      duration
      passive
      passiveStartAt
      activeAssist
      summary
      notes
      scheduleNotes
      travelDistance
      travelTime
      publicHoliday
      memberAssignedAutomatically
      conflictExists
      ...AddressFull
      ...AddressGeolocation
      client {
        id
      }
      member {
        id
      }
    }
  }
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type UpdateEventMutationFn = Apollo.MutationFunction<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(
    UpdateEventDocument,
    options,
  );
}
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;
export const UpdateEventAttendedDocument = gql`
  mutation UpdateEventAttended($input: UpdateEventBasicFieldsInput!) {
    updateEventAttended(input: $input) {
      id
      hasNotes
      notes
      travelDistance
      travelTime
    }
  }
`;
export type UpdateEventAttendedMutationFn = Apollo.MutationFunction<
  UpdateEventAttendedMutation,
  UpdateEventAttendedMutationVariables
>;

/**
 * __useUpdateEventAttendedMutation__
 *
 * To run a mutation, you first call `useUpdateEventAttendedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventAttendedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventAttendedMutation, { data, loading, error }] = useUpdateEventAttendedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventAttendedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventAttendedMutation,
    UpdateEventAttendedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEventAttendedMutation, UpdateEventAttendedMutationVariables>(
    UpdateEventAttendedDocument,
    options,
  );
}
export type UpdateEventAttendedMutationHookResult = ReturnType<
  typeof useUpdateEventAttendedMutation
>;
export type UpdateEventAttendedMutationResult = Apollo.MutationResult<UpdateEventAttendedMutation>;
export type UpdateEventAttendedMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventAttendedMutation,
  UpdateEventAttendedMutationVariables
>;
export const CreateMedicationDocument = gql`
  mutation CreateMedication($input: CreateMedicationInput!) {
    createMedication(input: $input) {
      id
      name
    }
  }
`;
export type CreateMedicationMutationFn = Apollo.MutationFunction<
  CreateMedicationMutation,
  CreateMedicationMutationVariables
>;

/**
 * __useCreateMedicationMutation__
 *
 * To run a mutation, you first call `useCreateMedicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMedicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMedicationMutation, { data, loading, error }] = useCreateMedicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMedicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMedicationMutation,
    CreateMedicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMedicationMutation, CreateMedicationMutationVariables>(
    CreateMedicationDocument,
    options,
  );
}
export type CreateMedicationMutationHookResult = ReturnType<typeof useCreateMedicationMutation>;
export type CreateMedicationMutationResult = Apollo.MutationResult<CreateMedicationMutation>;
export type CreateMedicationMutationOptions = Apollo.BaseMutationOptions<
  CreateMedicationMutation,
  CreateMedicationMutationVariables
>;
export const DeleteMedicationsDocument = gql`
  mutation DeleteMedications($input: DeleteInput!) {
    deleteMedications(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteMedicationsMutationFn = Apollo.MutationFunction<
  DeleteMedicationsMutation,
  DeleteMedicationsMutationVariables
>;

/**
 * __useDeleteMedicationsMutation__
 *
 * To run a mutation, you first call `useDeleteMedicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMedicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMedicationsMutation, { data, loading, error }] = useDeleteMedicationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMedicationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMedicationsMutation,
    DeleteMedicationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMedicationsMutation, DeleteMedicationsMutationVariables>(
    DeleteMedicationsDocument,
    options,
  );
}
export type DeleteMedicationsMutationHookResult = ReturnType<typeof useDeleteMedicationsMutation>;
export type DeleteMedicationsMutationResult = Apollo.MutationResult<DeleteMedicationsMutation>;
export type DeleteMedicationsMutationOptions = Apollo.BaseMutationOptions<
  DeleteMedicationsMutation,
  DeleteMedicationsMutationVariables
>;
export const UpdateMedicationDocument = gql`
  mutation UpdateMedication($input: UpdateMedicationInput!) {
    updateMedication(input: $input) {
      id
      name
    }
  }
`;
export type UpdateMedicationMutationFn = Apollo.MutationFunction<
  UpdateMedicationMutation,
  UpdateMedicationMutationVariables
>;

/**
 * __useUpdateMedicationMutation__
 *
 * To run a mutation, you first call `useUpdateMedicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMedicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMedicationMutation, { data, loading, error }] = useUpdateMedicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMedicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMedicationMutation,
    UpdateMedicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMedicationMutation, UpdateMedicationMutationVariables>(
    UpdateMedicationDocument,
    options,
  );
}
export type UpdateMedicationMutationHookResult = ReturnType<typeof useUpdateMedicationMutation>;
export type UpdateMedicationMutationResult = Apollo.MutationResult<UpdateMedicationMutation>;
export type UpdateMedicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateMedicationMutation,
  UpdateMedicationMutationVariables
>;
export const CreateMemberFileDocument = gql`
  mutation CreateMemberFile($input: CreateMemberFileInput!) {
    createMemberFile(input: $input) {
      id
      type
      dateOfFile
      expiresAt
      value
      owner {
        id
      }
      file {
        id
        size
        path
        createdBy {
          id
        }
      }
    }
  }
`;
export type CreateMemberFileMutationFn = Apollo.MutationFunction<
  CreateMemberFileMutation,
  CreateMemberFileMutationVariables
>;

/**
 * __useCreateMemberFileMutation__
 *
 * To run a mutation, you first call `useCreateMemberFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberFileMutation, { data, loading, error }] = useCreateMemberFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberFileMutation,
    CreateMemberFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMemberFileMutation, CreateMemberFileMutationVariables>(
    CreateMemberFileDocument,
    options,
  );
}
export type CreateMemberFileMutationHookResult = ReturnType<typeof useCreateMemberFileMutation>;
export type CreateMemberFileMutationResult = Apollo.MutationResult<CreateMemberFileMutation>;
export type CreateMemberFileMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberFileMutation,
  CreateMemberFileMutationVariables
>;
export const DeleteMemberFilesDocument = gql`
  mutation DeleteMemberFiles($input: DeleteInput!) {
    deleteMemberFiles(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteMemberFilesMutationFn = Apollo.MutationFunction<
  DeleteMemberFilesMutation,
  DeleteMemberFilesMutationVariables
>;

/**
 * __useDeleteMemberFilesMutation__
 *
 * To run a mutation, you first call `useDeleteMemberFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberFilesMutation, { data, loading, error }] = useDeleteMemberFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMemberFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMemberFilesMutation,
    DeleteMemberFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMemberFilesMutation, DeleteMemberFilesMutationVariables>(
    DeleteMemberFilesDocument,
    options,
  );
}
export type DeleteMemberFilesMutationHookResult = ReturnType<typeof useDeleteMemberFilesMutation>;
export type DeleteMemberFilesMutationResult = Apollo.MutationResult<DeleteMemberFilesMutation>;
export type DeleteMemberFilesMutationOptions = Apollo.BaseMutationOptions<
  DeleteMemberFilesMutation,
  DeleteMemberFilesMutationVariables
>;
export const RestoreMemberFilesDocument = gql`
  mutation RestoreMemberFiles($input: QueryByIdsInput!) {
    restoreMemberFiles(input: $input) {
      id
      delete {
        id
      }
      archive {
        id
      }
    }
  }
`;
export type RestoreMemberFilesMutationFn = Apollo.MutationFunction<
  RestoreMemberFilesMutation,
  RestoreMemberFilesMutationVariables
>;

/**
 * __useRestoreMemberFilesMutation__
 *
 * To run a mutation, you first call `useRestoreMemberFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreMemberFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreMemberFilesMutation, { data, loading, error }] = useRestoreMemberFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreMemberFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreMemberFilesMutation,
    RestoreMemberFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreMemberFilesMutation, RestoreMemberFilesMutationVariables>(
    RestoreMemberFilesDocument,
    options,
  );
}
export type RestoreMemberFilesMutationHookResult = ReturnType<typeof useRestoreMemberFilesMutation>;
export type RestoreMemberFilesMutationResult = Apollo.MutationResult<RestoreMemberFilesMutation>;
export type RestoreMemberFilesMutationOptions = Apollo.BaseMutationOptions<
  RestoreMemberFilesMutation,
  RestoreMemberFilesMutationVariables
>;
export const UpdateMemberFilesDocument = gql`
  mutation UpdateMemberFiles($input: UpdateMemberFilesInput!) {
    updateMemberFiles(input: $input) {
      id
      type
      dateOfFile
      expiresAt
      value
      owner {
        id
      }
    }
  }
`;
export type UpdateMemberFilesMutationFn = Apollo.MutationFunction<
  UpdateMemberFilesMutation,
  UpdateMemberFilesMutationVariables
>;

/**
 * __useUpdateMemberFilesMutation__
 *
 * To run a mutation, you first call `useUpdateMemberFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberFilesMutation, { data, loading, error }] = useUpdateMemberFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberFilesMutation,
    UpdateMemberFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberFilesMutation, UpdateMemberFilesMutationVariables>(
    UpdateMemberFilesDocument,
    options,
  );
}
export type UpdateMemberFilesMutationHookResult = ReturnType<typeof useUpdateMemberFilesMutation>;
export type UpdateMemberFilesMutationResult = Apollo.MutationResult<UpdateMemberFilesMutation>;
export type UpdateMemberFilesMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberFilesMutation,
  UpdateMemberFilesMutationVariables
>;
export const InviteMembersDocument = gql`
  mutation InviteMembers($input: QueryByIdsInput!) {
    inviteMembers(input: $input) {
      id
      invitationMostRecentlyReceived {
        id
        lastSender {
          id
          sentAt
          sentBy {
            id
            ...PersonNamesPartial
          }
        }
      }
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;
export type InviteMembersMutationFn = Apollo.MutationFunction<
  InviteMembersMutation,
  InviteMembersMutationVariables
>;

/**
 * __useInviteMembersMutation__
 *
 * To run a mutation, you first call `useInviteMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMembersMutation, { data, loading, error }] = useInviteMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteMembersMutation, InviteMembersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteMembersMutation, InviteMembersMutationVariables>(
    InviteMembersDocument,
    options,
  );
}
export type InviteMembersMutationHookResult = ReturnType<typeof useInviteMembersMutation>;
export type InviteMembersMutationResult = Apollo.MutationResult<InviteMembersMutation>;
export type InviteMembersMutationOptions = Apollo.BaseMutationOptions<
  InviteMembersMutation,
  InviteMembersMutationVariables
>;
export const ProcessMemberInvitationDocument = gql`
  mutation ProcessMemberInvitation($input: LinkMemberInput!) {
    linkMember(input: $input) {
      id
      org {
        id
      }
    }
  }
`;
export type ProcessMemberInvitationMutationFn = Apollo.MutationFunction<
  ProcessMemberInvitationMutation,
  ProcessMemberInvitationMutationVariables
>;

/**
 * __useProcessMemberInvitationMutation__
 *
 * To run a mutation, you first call `useProcessMemberInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessMemberInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processMemberInvitationMutation, { data, loading, error }] = useProcessMemberInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessMemberInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProcessMemberInvitationMutation,
    ProcessMemberInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProcessMemberInvitationMutation,
    ProcessMemberInvitationMutationVariables
  >(ProcessMemberInvitationDocument, options);
}
export type ProcessMemberInvitationMutationHookResult = ReturnType<
  typeof useProcessMemberInvitationMutation
>;
export type ProcessMemberInvitationMutationResult =
  Apollo.MutationResult<ProcessMemberInvitationMutation>;
export type ProcessMemberInvitationMutationOptions = Apollo.BaseMutationOptions<
  ProcessMemberInvitationMutation,
  ProcessMemberInvitationMutationVariables
>;
export const UninviteMembersDocument = gql`
  mutation UninviteMembers($input: QueryByIdsInput!) {
    uninviteMembers(input: $input) {
      id
      invitationMostRecentlyReceived {
        id
        deletedAt
      }
    }
  }
`;
export type UninviteMembersMutationFn = Apollo.MutationFunction<
  UninviteMembersMutation,
  UninviteMembersMutationVariables
>;

/**
 * __useUninviteMembersMutation__
 *
 * To run a mutation, you first call `useUninviteMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninviteMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninviteMembersMutation, { data, loading, error }] = useUninviteMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUninviteMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UninviteMembersMutation,
    UninviteMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UninviteMembersMutation, UninviteMembersMutationVariables>(
    UninviteMembersDocument,
    options,
  );
}
export type UninviteMembersMutationHookResult = ReturnType<typeof useUninviteMembersMutation>;
export type UninviteMembersMutationResult = Apollo.MutationResult<UninviteMembersMutation>;
export type UninviteMembersMutationOptions = Apollo.BaseMutationOptions<
  UninviteMembersMutation,
  UninviteMembersMutationVariables
>;
export const CreateMemberUnavailableForMeDocument = gql`
  mutation CreateMemberUnavailableForMe($input: CreateMemberUnavailableForMeInput!) {
    createMemberUnavailableForMe(input: $input) {
      id
      startAt
      endAt
      notes
      owner {
        id
      }
    }
  }
`;
export type CreateMemberUnavailableForMeMutationFn = Apollo.MutationFunction<
  CreateMemberUnavailableForMeMutation,
  CreateMemberUnavailableForMeMutationVariables
>;

/**
 * __useCreateMemberUnavailableForMeMutation__
 *
 * To run a mutation, you first call `useCreateMemberUnavailableForMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberUnavailableForMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberUnavailableForMeMutation, { data, loading, error }] = useCreateMemberUnavailableForMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberUnavailableForMeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberUnavailableForMeMutation,
    CreateMemberUnavailableForMeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMemberUnavailableForMeMutation,
    CreateMemberUnavailableForMeMutationVariables
  >(CreateMemberUnavailableForMeDocument, options);
}
export type CreateMemberUnavailableForMeMutationHookResult = ReturnType<
  typeof useCreateMemberUnavailableForMeMutation
>;
export type CreateMemberUnavailableForMeMutationResult =
  Apollo.MutationResult<CreateMemberUnavailableForMeMutation>;
export type CreateMemberUnavailableForMeMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberUnavailableForMeMutation,
  CreateMemberUnavailableForMeMutationVariables
>;
export const CreateMemberUnavailablesDocument = gql`
  mutation CreateMemberUnavailables($input: CreateMemberUnavailablesInput!) {
    createMemberUnavailables(input: $input) {
      id
      startAt
      endAt
      notes
      owner {
        id
      }
    }
  }
`;
export type CreateMemberUnavailablesMutationFn = Apollo.MutationFunction<
  CreateMemberUnavailablesMutation,
  CreateMemberUnavailablesMutationVariables
>;

/**
 * __useCreateMemberUnavailablesMutation__
 *
 * To run a mutation, you first call `useCreateMemberUnavailablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberUnavailablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberUnavailablesMutation, { data, loading, error }] = useCreateMemberUnavailablesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberUnavailablesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberUnavailablesMutation,
    CreateMemberUnavailablesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMemberUnavailablesMutation,
    CreateMemberUnavailablesMutationVariables
  >(CreateMemberUnavailablesDocument, options);
}
export type CreateMemberUnavailablesMutationHookResult = ReturnType<
  typeof useCreateMemberUnavailablesMutation
>;
export type CreateMemberUnavailablesMutationResult =
  Apollo.MutationResult<CreateMemberUnavailablesMutation>;
export type CreateMemberUnavailablesMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberUnavailablesMutation,
  CreateMemberUnavailablesMutationVariables
>;
export const DeleteMemberUnavailablesDocument = gql`
  mutation DeleteMemberUnavailables($input: QueryByIdsInput!) {
    deleteMemberUnavailables(input: $input) {
      id
      deletedAt
    }
  }
`;
export type DeleteMemberUnavailablesMutationFn = Apollo.MutationFunction<
  DeleteMemberUnavailablesMutation,
  DeleteMemberUnavailablesMutationVariables
>;

/**
 * __useDeleteMemberUnavailablesMutation__
 *
 * To run a mutation, you first call `useDeleteMemberUnavailablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberUnavailablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberUnavailablesMutation, { data, loading, error }] = useDeleteMemberUnavailablesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMemberUnavailablesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMemberUnavailablesMutation,
    DeleteMemberUnavailablesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMemberUnavailablesMutation,
    DeleteMemberUnavailablesMutationVariables
  >(DeleteMemberUnavailablesDocument, options);
}
export type DeleteMemberUnavailablesMutationHookResult = ReturnType<
  typeof useDeleteMemberUnavailablesMutation
>;
export type DeleteMemberUnavailablesMutationResult =
  Apollo.MutationResult<DeleteMemberUnavailablesMutation>;
export type DeleteMemberUnavailablesMutationOptions = Apollo.BaseMutationOptions<
  DeleteMemberUnavailablesMutation,
  DeleteMemberUnavailablesMutationVariables
>;
export const ArchiveMembersDocument = gql`
  mutation ArchiveMembers($input: ArchiveInput!) {
    archiveMembers(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchiveMembersMutationFn = Apollo.MutationFunction<
  ArchiveMembersMutation,
  ArchiveMembersMutationVariables
>;

/**
 * __useArchiveMembersMutation__
 *
 * To run a mutation, you first call `useArchiveMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMembersMutation, { data, loading, error }] = useArchiveMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveMembersMutation, ArchiveMembersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveMembersMutation, ArchiveMembersMutationVariables>(
    ArchiveMembersDocument,
    options,
  );
}
export type ArchiveMembersMutationHookResult = ReturnType<typeof useArchiveMembersMutation>;
export type ArchiveMembersMutationResult = Apollo.MutationResult<ArchiveMembersMutation>;
export type ArchiveMembersMutationOptions = Apollo.BaseMutationOptions<
  ArchiveMembersMutation,
  ArchiveMembersMutationVariables
>;
export const CreateMembersDocument = gql`
  mutation CreateMembers($input: CreateMembersInput!) {
    createMembers(input: $input) {
      id
      externalId
      color
      email
      phone
      dob
      schedulable
      bonusEligible
      workRights
      workRightsLastCheckedAt
      allowedFortnightlyWorkHours
      visaType {
        id
      }
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type CreateMembersMutationFn = Apollo.MutationFunction<
  CreateMembersMutation,
  CreateMembersMutationVariables
>;

/**
 * __useCreateMembersMutation__
 *
 * To run a mutation, you first call `useCreateMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMembersMutation, { data, loading, error }] = useCreateMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMembersMutation, CreateMembersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMembersMutation, CreateMembersMutationVariables>(
    CreateMembersDocument,
    options,
  );
}
export type CreateMembersMutationHookResult = ReturnType<typeof useCreateMembersMutation>;
export type CreateMembersMutationResult = Apollo.MutationResult<CreateMembersMutation>;
export type CreateMembersMutationOptions = Apollo.BaseMutationOptions<
  CreateMembersMutation,
  CreateMembersMutationVariables
>;
export const DeleteMembersDocument = gql`
  mutation DeleteMembers($input: DeleteInput!) {
    deleteMembers(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteMembersMutationFn = Apollo.MutationFunction<
  DeleteMembersMutation,
  DeleteMembersMutationVariables
>;

/**
 * __useDeleteMembersMutation__
 *
 * To run a mutation, you first call `useDeleteMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMembersMutation, { data, loading, error }] = useDeleteMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMembersMutation, DeleteMembersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMembersMutation, DeleteMembersMutationVariables>(
    DeleteMembersDocument,
    options,
  );
}
export type DeleteMembersMutationHookResult = ReturnType<typeof useDeleteMembersMutation>;
export type DeleteMembersMutationResult = Apollo.MutationResult<DeleteMembersMutation>;
export type DeleteMembersMutationOptions = Apollo.BaseMutationOptions<
  DeleteMembersMutation,
  DeleteMembersMutationVariables
>;
export const EmailMemberClockedPeriodsDocument = gql`
  mutation EmailMemberClockedPeriods($input: QueryMemberClockedPeriodsInput!) {
    emailMemberClockedPeriods(input: $input) {
      value
    }
  }
`;
export type EmailMemberClockedPeriodsMutationFn = Apollo.MutationFunction<
  EmailMemberClockedPeriodsMutation,
  EmailMemberClockedPeriodsMutationVariables
>;

/**
 * __useEmailMemberClockedPeriodsMutation__
 *
 * To run a mutation, you first call `useEmailMemberClockedPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailMemberClockedPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailMemberClockedPeriodsMutation, { data, loading, error }] = useEmailMemberClockedPeriodsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailMemberClockedPeriodsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EmailMemberClockedPeriodsMutation,
    EmailMemberClockedPeriodsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EmailMemberClockedPeriodsMutation,
    EmailMemberClockedPeriodsMutationVariables
  >(EmailMemberClockedPeriodsDocument, options);
}
export type EmailMemberClockedPeriodsMutationHookResult = ReturnType<
  typeof useEmailMemberClockedPeriodsMutation
>;
export type EmailMemberClockedPeriodsMutationResult =
  Apollo.MutationResult<EmailMemberClockedPeriodsMutation>;
export type EmailMemberClockedPeriodsMutationOptions = Apollo.BaseMutationOptions<
  EmailMemberClockedPeriodsMutation,
  EmailMemberClockedPeriodsMutationVariables
>;
export const RestoreMembersDocument = gql`
  mutation RestoreMembers($input: QueryByIdsInput!) {
    restoreMembers(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
      archive {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreMembersMutationFn = Apollo.MutationFunction<
  RestoreMembersMutation,
  RestoreMembersMutationVariables
>;

/**
 * __useRestoreMembersMutation__
 *
 * To run a mutation, you first call `useRestoreMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreMembersMutation, { data, loading, error }] = useRestoreMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<RestoreMembersMutation, RestoreMembersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreMembersMutation, RestoreMembersMutationVariables>(
    RestoreMembersDocument,
    options,
  );
}
export type RestoreMembersMutationHookResult = ReturnType<typeof useRestoreMembersMutation>;
export type RestoreMembersMutationResult = Apollo.MutationResult<RestoreMembersMutation>;
export type RestoreMembersMutationOptions = Apollo.BaseMutationOptions<
  RestoreMembersMutation,
  RestoreMembersMutationVariables
>;
export const UnlinkMembersFromUsersDocument = gql`
  mutation UnlinkMembersFromUsers($input: QueryByIdsInput!) {
    unlinkMembers(input: $input) {
      id
      hasAccess
    }
  }
`;
export type UnlinkMembersFromUsersMutationFn = Apollo.MutationFunction<
  UnlinkMembersFromUsersMutation,
  UnlinkMembersFromUsersMutationVariables
>;

/**
 * __useUnlinkMembersFromUsersMutation__
 *
 * To run a mutation, you first call `useUnlinkMembersFromUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkMembersFromUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkMembersFromUsersMutation, { data, loading, error }] = useUnlinkMembersFromUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnlinkMembersFromUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkMembersFromUsersMutation,
    UnlinkMembersFromUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlinkMembersFromUsersMutation,
    UnlinkMembersFromUsersMutationVariables
  >(UnlinkMembersFromUsersDocument, options);
}
export type UnlinkMembersFromUsersMutationHookResult = ReturnType<
  typeof useUnlinkMembersFromUsersMutation
>;
export type UnlinkMembersFromUsersMutationResult =
  Apollo.MutationResult<UnlinkMembersFromUsersMutation>;
export type UnlinkMembersFromUsersMutationOptions = Apollo.BaseMutationOptions<
  UnlinkMembersFromUsersMutation,
  UnlinkMembersFromUsersMutationVariables
>;
export const UpdateCurrentMemberTypicalUnavailabilityDocument = gql`
  mutation UpdateCurrentMemberTypicalUnavailability($input: UpdateMemberCurrentInput!) {
    updateMemberCurrent(input: $input) {
      id
      unavailableNotes
      unavailableMoMorn
      unavailableMoDay
      unavailableMoNight
      unavailableTuMorn
      unavailableTuDay
      unavailableTuNight
      unavailableWeMorn
      unavailableWeDay
      unavailableWeNight
      unavailableThMorn
      unavailableThDay
      unavailableThNight
      unavailableFrMorn
      unavailableFrDay
      unavailableFrNight
      unavailableSaMorn
      unavailableSaDay
      unavailableSaNight
      unavailableSuMorn
      unavailableSuDay
      unavailableSuNight
    }
  }
`;
export type UpdateCurrentMemberTypicalUnavailabilityMutationFn = Apollo.MutationFunction<
  UpdateCurrentMemberTypicalUnavailabilityMutation,
  UpdateCurrentMemberTypicalUnavailabilityMutationVariables
>;

/**
 * __useUpdateCurrentMemberTypicalUnavailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentMemberTypicalUnavailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentMemberTypicalUnavailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentMemberTypicalUnavailabilityMutation, { data, loading, error }] = useUpdateCurrentMemberTypicalUnavailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentMemberTypicalUnavailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentMemberTypicalUnavailabilityMutation,
    UpdateCurrentMemberTypicalUnavailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentMemberTypicalUnavailabilityMutation,
    UpdateCurrentMemberTypicalUnavailabilityMutationVariables
  >(UpdateCurrentMemberTypicalUnavailabilityDocument, options);
}
export type UpdateCurrentMemberTypicalUnavailabilityMutationHookResult = ReturnType<
  typeof useUpdateCurrentMemberTypicalUnavailabilityMutation
>;
export type UpdateCurrentMemberTypicalUnavailabilityMutationResult =
  Apollo.MutationResult<UpdateCurrentMemberTypicalUnavailabilityMutation>;
export type UpdateCurrentMemberTypicalUnavailabilityMutationOptions = Apollo.BaseMutationOptions<
  UpdateCurrentMemberTypicalUnavailabilityMutation,
  UpdateCurrentMemberTypicalUnavailabilityMutationVariables
>;
export const UpdateMembersDocument = gql`
  mutation UpdateMembers($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      dob
      email
      phone
      study
      externalId
      schedulable
      workRights
      workRightsLastCheckedAt
      allowedFortnightlyWorkHours
      driversLicense
      driversLicenseCountry
      driversLicenseExpiryDate
      hasCar
      carDescription
      employmentStartDate
      employmentEndDate
      basePayRate
      bonusEligible
      visaType {
        id
      }
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branches {
        id
        name
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type UpdateMembersMutationFn = Apollo.MutationFunction<
  UpdateMembersMutation,
  UpdateMembersMutationVariables
>;

/**
 * __useUpdateMembersMutation__
 *
 * To run a mutation, you first call `useUpdateMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersMutation, { data, loading, error }] = useUpdateMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMembersMutation, UpdateMembersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMembersMutation, UpdateMembersMutationVariables>(
    UpdateMembersDocument,
    options,
  );
}
export type UpdateMembersMutationHookResult = ReturnType<typeof useUpdateMembersMutation>;
export type UpdateMembersMutationResult = Apollo.MutationResult<UpdateMembersMutation>;
export type UpdateMembersMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersMutation,
  UpdateMembersMutationVariables
>;
export const UpdateMembersAvatarDocument = gql`
  mutation UpdateMembersAvatar($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      avatar {
        id
        path
        size
      }
    }
  }
`;
export type UpdateMembersAvatarMutationFn = Apollo.MutationFunction<
  UpdateMembersAvatarMutation,
  UpdateMembersAvatarMutationVariables
>;

/**
 * __useUpdateMembersAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateMembersAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersAvatarMutation, { data, loading, error }] = useUpdateMembersAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersAvatarMutation,
    UpdateMembersAvatarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMembersAvatarMutation, UpdateMembersAvatarMutationVariables>(
    UpdateMembersAvatarDocument,
    options,
  );
}
export type UpdateMembersAvatarMutationHookResult = ReturnType<
  typeof useUpdateMembersAvatarMutation
>;
export type UpdateMembersAvatarMutationResult = Apollo.MutationResult<UpdateMembersAvatarMutation>;
export type UpdateMembersAvatarMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersAvatarMutation,
  UpdateMembersAvatarMutationVariables
>;
export const UpdateMembersColorsDocument = gql`
  mutation UpdateMembersColors($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      color
    }
  }
`;
export type UpdateMembersColorsMutationFn = Apollo.MutationFunction<
  UpdateMembersColorsMutation,
  UpdateMembersColorsMutationVariables
>;

/**
 * __useUpdateMembersColorsMutation__
 *
 * To run a mutation, you first call `useUpdateMembersColorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersColorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersColorsMutation, { data, loading, error }] = useUpdateMembersColorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersColorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersColorsMutation,
    UpdateMembersColorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMembersColorsMutation, UpdateMembersColorsMutationVariables>(
    UpdateMembersColorsDocument,
    options,
  );
}
export type UpdateMembersColorsMutationHookResult = ReturnType<
  typeof useUpdateMembersColorsMutation
>;
export type UpdateMembersColorsMutationResult = Apollo.MutationResult<UpdateMembersColorsMutation>;
export type UpdateMembersColorsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersColorsMutation,
  UpdateMembersColorsMutationVariables
>;
export const UpdateMembersExternalIdsDocument = gql`
  mutation UpdateMembersExternalIds($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      externalId
    }
  }
`;
export type UpdateMembersExternalIdsMutationFn = Apollo.MutationFunction<
  UpdateMembersExternalIdsMutation,
  UpdateMembersExternalIdsMutationVariables
>;

/**
 * __useUpdateMembersExternalIdsMutation__
 *
 * To run a mutation, you first call `useUpdateMembersExternalIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersExternalIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersExternalIdsMutation, { data, loading, error }] = useUpdateMembersExternalIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersExternalIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersExternalIdsMutation,
    UpdateMembersExternalIdsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersExternalIdsMutation,
    UpdateMembersExternalIdsMutationVariables
  >(UpdateMembersExternalIdsDocument, options);
}
export type UpdateMembersExternalIdsMutationHookResult = ReturnType<
  typeof useUpdateMembersExternalIdsMutation
>;
export type UpdateMembersExternalIdsMutationResult =
  Apollo.MutationResult<UpdateMembersExternalIdsMutation>;
export type UpdateMembersExternalIdsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersExternalIdsMutation,
  UpdateMembersExternalIdsMutationVariables
>;
export const UpdateMembersNotesDocument = gql`
  mutation UpdateMembersNotes($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      notes
    }
  }
`;
export type UpdateMembersNotesMutationFn = Apollo.MutationFunction<
  UpdateMembersNotesMutation,
  UpdateMembersNotesMutationVariables
>;

/**
 * __useUpdateMembersNotesMutation__
 *
 * To run a mutation, you first call `useUpdateMembersNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersNotesMutation, { data, loading, error }] = useUpdateMembersNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersNotesMutation,
    UpdateMembersNotesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMembersNotesMutation, UpdateMembersNotesMutationVariables>(
    UpdateMembersNotesDocument,
    options,
  );
}
export type UpdateMembersNotesMutationHookResult = ReturnType<typeof useUpdateMembersNotesMutation>;
export type UpdateMembersNotesMutationResult = Apollo.MutationResult<UpdateMembersNotesMutation>;
export type UpdateMembersNotesMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersNotesMutation,
  UpdateMembersNotesMutationVariables
>;
export const UpdateMembersPermissionsDocument = gql`
  mutation UpdateMembersPermissions($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      admin
      permissions
    }
  }
`;
export type UpdateMembersPermissionsMutationFn = Apollo.MutationFunction<
  UpdateMembersPermissionsMutation,
  UpdateMembersPermissionsMutationVariables
>;

/**
 * __useUpdateMembersPermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateMembersPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersPermissionsMutation, { data, loading, error }] = useUpdateMembersPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersPermissionsMutation,
    UpdateMembersPermissionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersPermissionsMutation,
    UpdateMembersPermissionsMutationVariables
  >(UpdateMembersPermissionsDocument, options);
}
export type UpdateMembersPermissionsMutationHookResult = ReturnType<
  typeof useUpdateMembersPermissionsMutation
>;
export type UpdateMembersPermissionsMutationResult =
  Apollo.MutationResult<UpdateMembersPermissionsMutation>;
export type UpdateMembersPermissionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersPermissionsMutation,
  UpdateMembersPermissionsMutationVariables
>;
export const UpdateMembersTypicalUnavailabilityDocument = gql`
  mutation UpdateMembersTypicalUnavailability($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      unavailableNotes
      unavailableMoMorn
      unavailableMoDay
      unavailableMoNight
      unavailableTuMorn
      unavailableTuDay
      unavailableTuNight
      unavailableWeMorn
      unavailableWeDay
      unavailableWeNight
      unavailableThMorn
      unavailableThDay
      unavailableThNight
      unavailableFrMorn
      unavailableFrDay
      unavailableFrNight
      unavailableSaMorn
      unavailableSaDay
      unavailableSaNight
      unavailableSuMorn
      unavailableSuDay
      unavailableSuNight
    }
  }
`;
export type UpdateMembersTypicalUnavailabilityMutationFn = Apollo.MutationFunction<
  UpdateMembersTypicalUnavailabilityMutation,
  UpdateMembersTypicalUnavailabilityMutationVariables
>;

/**
 * __useUpdateMembersTypicalUnavailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateMembersTypicalUnavailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersTypicalUnavailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersTypicalUnavailabilityMutation, { data, loading, error }] = useUpdateMembersTypicalUnavailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersTypicalUnavailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersTypicalUnavailabilityMutation,
    UpdateMembersTypicalUnavailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersTypicalUnavailabilityMutation,
    UpdateMembersTypicalUnavailabilityMutationVariables
  >(UpdateMembersTypicalUnavailabilityDocument, options);
}
export type UpdateMembersTypicalUnavailabilityMutationHookResult = ReturnType<
  typeof useUpdateMembersTypicalUnavailabilityMutation
>;
export type UpdateMembersTypicalUnavailabilityMutationResult =
  Apollo.MutationResult<UpdateMembersTypicalUnavailabilityMutation>;
export type UpdateMembersTypicalUnavailabilityMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersTypicalUnavailabilityMutation,
  UpdateMembersTypicalUnavailabilityMutationVariables
>;
export const CreateOrgDocument = gql`
  mutation CreateOrg($input: CreateOrgInput!) {
    createOrg(input: $input) {
      id
      abn
      email
      phone
      website
      ...BusinessNames
      branches {
        id
        name
      }
    }
  }
  ${BusinessNamesFragmentDoc}
`;
export type CreateOrgMutationFn = Apollo.MutationFunction<
  CreateOrgMutation,
  CreateOrgMutationVariables
>;

/**
 * __useCreateOrgMutation__
 *
 * To run a mutation, you first call `useCreateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgMutation, { data, loading, error }] = useCreateOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrgMutation, CreateOrgMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrgMutation, CreateOrgMutationVariables>(
    CreateOrgDocument,
    options,
  );
}
export type CreateOrgMutationHookResult = ReturnType<typeof useCreateOrgMutation>;
export type CreateOrgMutationResult = Apollo.MutationResult<CreateOrgMutation>;
export type CreateOrgMutationOptions = Apollo.BaseMutationOptions<
  CreateOrgMutation,
  CreateOrgMutationVariables
>;
export const UpdateOrgPayrollDocument = gql`
  mutation UpdateOrgPayroll($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      id
      basePayRate
      afternoonPayMultiplier
      nightPayMultiplier
      satPayMultiplier
      sunPayMultiplier
      pubPayMultiplier
      passiveAllowance
      kmAllowance
      bonus2PayRate
      bonus3PayRate
      bonus4PayRate
      bonus5PayRate
      bonus6PayRate
      bonus7PayRate
      bonus8PayRate
      bonus9PayRate
      bonus10PayRate
      bonus11PayRate
      bonus12PayRate
    }
  }
`;
export type UpdateOrgPayrollMutationFn = Apollo.MutationFunction<
  UpdateOrgPayrollMutation,
  UpdateOrgPayrollMutationVariables
>;

/**
 * __useUpdateOrgPayrollMutation__
 *
 * To run a mutation, you first call `useUpdateOrgPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgPayrollMutation, { data, loading, error }] = useUpdateOrgPayrollMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgPayrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgPayrollMutation,
    UpdateOrgPayrollMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrgPayrollMutation, UpdateOrgPayrollMutationVariables>(
    UpdateOrgPayrollDocument,
    options,
  );
}
export type UpdateOrgPayrollMutationHookResult = ReturnType<typeof useUpdateOrgPayrollMutation>;
export type UpdateOrgPayrollMutationResult = Apollo.MutationResult<UpdateOrgPayrollMutation>;
export type UpdateOrgPayrollMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgPayrollMutation,
  UpdateOrgPayrollMutationVariables
>;
export const UpdateOrgSchedulingDocument = gql`
  mutation UpdateOrgScheduling($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      id
      runAutoAssignMemberTask
      assignMemberToEventWeeksInAdvance
      membersCanSeeClockedTimes
      quantityOfFutureShiftsMembersCanSee
      quantityOfPastShiftsMembersCanSee
      unfilledEventsDayRange
      clockOnWarnMinutes
      clockOffWarnMinutes
      schedulingContactPerson {
        id
      }
      schedulingContactPerson2 {
        id
      }
    }
  }
`;
export type UpdateOrgSchedulingMutationFn = Apollo.MutationFunction<
  UpdateOrgSchedulingMutation,
  UpdateOrgSchedulingMutationVariables
>;

/**
 * __useUpdateOrgSchedulingMutation__
 *
 * To run a mutation, you first call `useUpdateOrgSchedulingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgSchedulingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgSchedulingMutation, { data, loading, error }] = useUpdateOrgSchedulingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgSchedulingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgSchedulingMutation,
    UpdateOrgSchedulingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrgSchedulingMutation, UpdateOrgSchedulingMutationVariables>(
    UpdateOrgSchedulingDocument,
    options,
  );
}
export type UpdateOrgSchedulingMutationHookResult = ReturnType<
  typeof useUpdateOrgSchedulingMutation
>;
export type UpdateOrgSchedulingMutationResult = Apollo.MutationResult<UpdateOrgSchedulingMutation>;
export type UpdateOrgSchedulingMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgSchedulingMutation,
  UpdateOrgSchedulingMutationVariables
>;
export const ArchivePlanManagersDocument = gql`
  mutation ArchivePlanManagers($input: ArchiveInput!) {
    archivePlanManagers(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchivePlanManagersMutationFn = Apollo.MutationFunction<
  ArchivePlanManagersMutation,
  ArchivePlanManagersMutationVariables
>;

/**
 * __useArchivePlanManagersMutation__
 *
 * To run a mutation, you first call `useArchivePlanManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePlanManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePlanManagersMutation, { data, loading, error }] = useArchivePlanManagersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchivePlanManagersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchivePlanManagersMutation,
    ArchivePlanManagersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchivePlanManagersMutation, ArchivePlanManagersMutationVariables>(
    ArchivePlanManagersDocument,
    options,
  );
}
export type ArchivePlanManagersMutationHookResult = ReturnType<
  typeof useArchivePlanManagersMutation
>;
export type ArchivePlanManagersMutationResult = Apollo.MutationResult<ArchivePlanManagersMutation>;
export type ArchivePlanManagersMutationOptions = Apollo.BaseMutationOptions<
  ArchivePlanManagersMutation,
  ArchivePlanManagersMutationVariables
>;
export const CreatePlanManagersDocument = gql`
  mutation CreatePlanManagers($input: CreatePlanManagersInput!) {
    createPlanManagers(input: $input) {
      id
      abn
      name
      salutation
      phone
      businessName
      contactEmail
      billingEmail
      notes
    }
  }
`;
export type CreatePlanManagersMutationFn = Apollo.MutationFunction<
  CreatePlanManagersMutation,
  CreatePlanManagersMutationVariables
>;

/**
 * __useCreatePlanManagersMutation__
 *
 * To run a mutation, you first call `useCreatePlanManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanManagersMutation, { data, loading, error }] = useCreatePlanManagersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanManagersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlanManagersMutation,
    CreatePlanManagersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePlanManagersMutation, CreatePlanManagersMutationVariables>(
    CreatePlanManagersDocument,
    options,
  );
}
export type CreatePlanManagersMutationHookResult = ReturnType<typeof useCreatePlanManagersMutation>;
export type CreatePlanManagersMutationResult = Apollo.MutationResult<CreatePlanManagersMutation>;
export type CreatePlanManagersMutationOptions = Apollo.BaseMutationOptions<
  CreatePlanManagersMutation,
  CreatePlanManagersMutationVariables
>;
export const DeletePlanManagersDocument = gql`
  mutation DeletePlanManagers($input: DeleteInput!) {
    deletePlanManagers(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeletePlanManagersMutationFn = Apollo.MutationFunction<
  DeletePlanManagersMutation,
  DeletePlanManagersMutationVariables
>;

/**
 * __useDeletePlanManagersMutation__
 *
 * To run a mutation, you first call `useDeletePlanManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanManagersMutation, { data, loading, error }] = useDeletePlanManagersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlanManagersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePlanManagersMutation,
    DeletePlanManagersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePlanManagersMutation, DeletePlanManagersMutationVariables>(
    DeletePlanManagersDocument,
    options,
  );
}
export type DeletePlanManagersMutationHookResult = ReturnType<typeof useDeletePlanManagersMutation>;
export type DeletePlanManagersMutationResult = Apollo.MutationResult<DeletePlanManagersMutation>;
export type DeletePlanManagersMutationOptions = Apollo.BaseMutationOptions<
  DeletePlanManagersMutation,
  DeletePlanManagersMutationVariables
>;
export const RestorePlanManagersDocument = gql`
  mutation RestorePlanManagers($input: QueryByIdsInput!) {
    restorePlanManagers(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
      archive {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestorePlanManagersMutationFn = Apollo.MutationFunction<
  RestorePlanManagersMutation,
  RestorePlanManagersMutationVariables
>;

/**
 * __useRestorePlanManagersMutation__
 *
 * To run a mutation, you first call `useRestorePlanManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestorePlanManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restorePlanManagersMutation, { data, loading, error }] = useRestorePlanManagersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestorePlanManagersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestorePlanManagersMutation,
    RestorePlanManagersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestorePlanManagersMutation, RestorePlanManagersMutationVariables>(
    RestorePlanManagersDocument,
    options,
  );
}
export type RestorePlanManagersMutationHookResult = ReturnType<
  typeof useRestorePlanManagersMutation
>;
export type RestorePlanManagersMutationResult = Apollo.MutationResult<RestorePlanManagersMutation>;
export type RestorePlanManagersMutationOptions = Apollo.BaseMutationOptions<
  RestorePlanManagersMutation,
  RestorePlanManagersMutationVariables
>;
export const UpdatePlanManagersDocument = gql`
  mutation UpdatePlanManagers($input: UpdatePlanManagersInput!) {
    updatePlanManagers(input: $input) {
      id
      abn
      name
      salutation
      phone
      businessName
      contactEmail
      billingEmail
      notes
    }
  }
`;
export type UpdatePlanManagersMutationFn = Apollo.MutationFunction<
  UpdatePlanManagersMutation,
  UpdatePlanManagersMutationVariables
>;

/**
 * __useUpdatePlanManagersMutation__
 *
 * To run a mutation, you first call `useUpdatePlanManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanManagersMutation, { data, loading, error }] = useUpdatePlanManagersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanManagersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlanManagersMutation,
    UpdatePlanManagersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePlanManagersMutation, UpdatePlanManagersMutationVariables>(
    UpdatePlanManagersDocument,
    options,
  );
}
export type UpdatePlanManagersMutationHookResult = ReturnType<typeof useUpdatePlanManagersMutation>;
export type UpdatePlanManagersMutationResult = Apollo.MutationResult<UpdatePlanManagersMutation>;
export type UpdatePlanManagersMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlanManagersMutation,
  UpdatePlanManagersMutationVariables
>;
export const ArchiveSupportCoordinatorsDocument = gql`
  mutation ArchiveSupportCoordinators($input: ArchiveInput!) {
    archiveSupportCoordinators(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchiveSupportCoordinatorsMutationFn = Apollo.MutationFunction<
  ArchiveSupportCoordinatorsMutation,
  ArchiveSupportCoordinatorsMutationVariables
>;

/**
 * __useArchiveSupportCoordinatorsMutation__
 *
 * To run a mutation, you first call `useArchiveSupportCoordinatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSupportCoordinatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSupportCoordinatorsMutation, { data, loading, error }] = useArchiveSupportCoordinatorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveSupportCoordinatorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveSupportCoordinatorsMutation,
    ArchiveSupportCoordinatorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveSupportCoordinatorsMutation,
    ArchiveSupportCoordinatorsMutationVariables
  >(ArchiveSupportCoordinatorsDocument, options);
}
export type ArchiveSupportCoordinatorsMutationHookResult = ReturnType<
  typeof useArchiveSupportCoordinatorsMutation
>;
export type ArchiveSupportCoordinatorsMutationResult =
  Apollo.MutationResult<ArchiveSupportCoordinatorsMutation>;
export type ArchiveSupportCoordinatorsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveSupportCoordinatorsMutation,
  ArchiveSupportCoordinatorsMutationVariables
>;
export const CreateSupportCoordinatorsDocument = gql`
  mutation CreateSupportCoordinators($input: CreateSupportCoordinatorsInput!) {
    createSupportCoordinators(input: $input) {
      id
      firstName
      middleName
      lastName
      preferredName
      email
      phone
      businessName
      businessEmail
      businessPhone
      abn
      salutation
      notes
      clients {
        id
      }
    }
  }
`;
export type CreateSupportCoordinatorsMutationFn = Apollo.MutationFunction<
  CreateSupportCoordinatorsMutation,
  CreateSupportCoordinatorsMutationVariables
>;

/**
 * __useCreateSupportCoordinatorsMutation__
 *
 * To run a mutation, you first call `useCreateSupportCoordinatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupportCoordinatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupportCoordinatorsMutation, { data, loading, error }] = useCreateSupportCoordinatorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupportCoordinatorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSupportCoordinatorsMutation,
    CreateSupportCoordinatorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSupportCoordinatorsMutation,
    CreateSupportCoordinatorsMutationVariables
  >(CreateSupportCoordinatorsDocument, options);
}
export type CreateSupportCoordinatorsMutationHookResult = ReturnType<
  typeof useCreateSupportCoordinatorsMutation
>;
export type CreateSupportCoordinatorsMutationResult =
  Apollo.MutationResult<CreateSupportCoordinatorsMutation>;
export type CreateSupportCoordinatorsMutationOptions = Apollo.BaseMutationOptions<
  CreateSupportCoordinatorsMutation,
  CreateSupportCoordinatorsMutationVariables
>;
export const DeleteSupportCoordinatorsDocument = gql`
  mutation DeleteSupportCoordinators($input: DeleteInput!) {
    deleteSupportCoordinators(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteSupportCoordinatorsMutationFn = Apollo.MutationFunction<
  DeleteSupportCoordinatorsMutation,
  DeleteSupportCoordinatorsMutationVariables
>;

/**
 * __useDeleteSupportCoordinatorsMutation__
 *
 * To run a mutation, you first call `useDeleteSupportCoordinatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupportCoordinatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupportCoordinatorsMutation, { data, loading, error }] = useDeleteSupportCoordinatorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSupportCoordinatorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSupportCoordinatorsMutation,
    DeleteSupportCoordinatorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSupportCoordinatorsMutation,
    DeleteSupportCoordinatorsMutationVariables
  >(DeleteSupportCoordinatorsDocument, options);
}
export type DeleteSupportCoordinatorsMutationHookResult = ReturnType<
  typeof useDeleteSupportCoordinatorsMutation
>;
export type DeleteSupportCoordinatorsMutationResult =
  Apollo.MutationResult<DeleteSupportCoordinatorsMutation>;
export type DeleteSupportCoordinatorsMutationOptions = Apollo.BaseMutationOptions<
  DeleteSupportCoordinatorsMutation,
  DeleteSupportCoordinatorsMutationVariables
>;
export const RestoreSupportCoordinatorsDocument = gql`
  mutation RestoreSupportCoordinators($input: QueryByIdsInput!) {
    restoreSupportCoordinators(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
      archive {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreSupportCoordinatorsMutationFn = Apollo.MutationFunction<
  RestoreSupportCoordinatorsMutation,
  RestoreSupportCoordinatorsMutationVariables
>;

/**
 * __useRestoreSupportCoordinatorsMutation__
 *
 * To run a mutation, you first call `useRestoreSupportCoordinatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreSupportCoordinatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreSupportCoordinatorsMutation, { data, loading, error }] = useRestoreSupportCoordinatorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreSupportCoordinatorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreSupportCoordinatorsMutation,
    RestoreSupportCoordinatorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreSupportCoordinatorsMutation,
    RestoreSupportCoordinatorsMutationVariables
  >(RestoreSupportCoordinatorsDocument, options);
}
export type RestoreSupportCoordinatorsMutationHookResult = ReturnType<
  typeof useRestoreSupportCoordinatorsMutation
>;
export type RestoreSupportCoordinatorsMutationResult =
  Apollo.MutationResult<RestoreSupportCoordinatorsMutation>;
export type RestoreSupportCoordinatorsMutationOptions = Apollo.BaseMutationOptions<
  RestoreSupportCoordinatorsMutation,
  RestoreSupportCoordinatorsMutationVariables
>;
export const UpdateSupportCoordinatorsDocument = gql`
  mutation UpdateSupportCoordinators($input: UpdateSupportCoordinatorsInput!) {
    updateSupportCoordinators(input: $input) {
      id
      firstName
      middleName
      lastName
      preferredName
      email
      phone
      businessName
      businessEmail
      businessPhone
      abn
      salutation
      notes
      clients {
        id
      }
    }
  }
`;
export type UpdateSupportCoordinatorsMutationFn = Apollo.MutationFunction<
  UpdateSupportCoordinatorsMutation,
  UpdateSupportCoordinatorsMutationVariables
>;

/**
 * __useUpdateSupportCoordinatorsMutation__
 *
 * To run a mutation, you first call `useUpdateSupportCoordinatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupportCoordinatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupportCoordinatorsMutation, { data, loading, error }] = useUpdateSupportCoordinatorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupportCoordinatorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSupportCoordinatorsMutation,
    UpdateSupportCoordinatorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSupportCoordinatorsMutation,
    UpdateSupportCoordinatorsMutationVariables
  >(UpdateSupportCoordinatorsDocument, options);
}
export type UpdateSupportCoordinatorsMutationHookResult = ReturnType<
  typeof useUpdateSupportCoordinatorsMutation
>;
export type UpdateSupportCoordinatorsMutationResult =
  Apollo.MutationResult<UpdateSupportCoordinatorsMutation>;
export type UpdateSupportCoordinatorsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSupportCoordinatorsMutation,
  UpdateSupportCoordinatorsMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      token
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const LoginUserDocument = gql`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      token
    }
  }
`;
export type LoginUserMutationFn = Apollo.MutationFunction<
  LoginUserMutation,
  LoginUserMutationVariables
>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(
    LoginUserDocument,
    options,
  );
}
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      email
      phone
      ...PersonNames
      ...PersonGenderIdentifiers
      member {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const CreateVisaTypeDocument = gql`
  mutation CreateVisaType($input: CreateVisaTypeInput!) {
    createVisaType(input: $input) {
      id
      name
      description
      allowedFortnightlyWorkHours
      allowedWorkHoursWaived
    }
  }
`;
export type CreateVisaTypeMutationFn = Apollo.MutationFunction<
  CreateVisaTypeMutation,
  CreateVisaTypeMutationVariables
>;

/**
 * __useCreateVisaTypeMutation__
 *
 * To run a mutation, you first call `useCreateVisaTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisaTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisaTypeMutation, { data, loading, error }] = useCreateVisaTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVisaTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVisaTypeMutation, CreateVisaTypeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVisaTypeMutation, CreateVisaTypeMutationVariables>(
    CreateVisaTypeDocument,
    options,
  );
}
export type CreateVisaTypeMutationHookResult = ReturnType<typeof useCreateVisaTypeMutation>;
export type CreateVisaTypeMutationResult = Apollo.MutationResult<CreateVisaTypeMutation>;
export type CreateVisaTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateVisaTypeMutation,
  CreateVisaTypeMutationVariables
>;
export const DeleteVisaTypesDocument = gql`
  mutation DeleteVisaTypes($input: DeleteInput!) {
    deleteVisaTypes(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteVisaTypesMutationFn = Apollo.MutationFunction<
  DeleteVisaTypesMutation,
  DeleteVisaTypesMutationVariables
>;

/**
 * __useDeleteVisaTypesMutation__
 *
 * To run a mutation, you first call `useDeleteVisaTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVisaTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVisaTypesMutation, { data, loading, error }] = useDeleteVisaTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVisaTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVisaTypesMutation,
    DeleteVisaTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVisaTypesMutation, DeleteVisaTypesMutationVariables>(
    DeleteVisaTypesDocument,
    options,
  );
}
export type DeleteVisaTypesMutationHookResult = ReturnType<typeof useDeleteVisaTypesMutation>;
export type DeleteVisaTypesMutationResult = Apollo.MutationResult<DeleteVisaTypesMutation>;
export type DeleteVisaTypesMutationOptions = Apollo.BaseMutationOptions<
  DeleteVisaTypesMutation,
  DeleteVisaTypesMutationVariables
>;
export const RestoreVisaTypesDocument = gql`
  mutation RestoreVisaTypes($input: QueryByIdsInput!) {
    restoreVisaTypes(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreVisaTypesMutationFn = Apollo.MutationFunction<
  RestoreVisaTypesMutation,
  RestoreVisaTypesMutationVariables
>;

/**
 * __useRestoreVisaTypesMutation__
 *
 * To run a mutation, you first call `useRestoreVisaTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreVisaTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreVisaTypesMutation, { data, loading, error }] = useRestoreVisaTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreVisaTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreVisaTypesMutation,
    RestoreVisaTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreVisaTypesMutation, RestoreVisaTypesMutationVariables>(
    RestoreVisaTypesDocument,
    options,
  );
}
export type RestoreVisaTypesMutationHookResult = ReturnType<typeof useRestoreVisaTypesMutation>;
export type RestoreVisaTypesMutationResult = Apollo.MutationResult<RestoreVisaTypesMutation>;
export type RestoreVisaTypesMutationOptions = Apollo.BaseMutationOptions<
  RestoreVisaTypesMutation,
  RestoreVisaTypesMutationVariables
>;
export const UpdateVisaTypeDocument = gql`
  mutation UpdateVisaType($input: UpdateVisaTypeInput!) {
    updateVisaType(input: $input) {
      id
      name
      description
      allowedFortnightlyWorkHours
      allowedWorkHoursWaived
    }
  }
`;
export type UpdateVisaTypeMutationFn = Apollo.MutationFunction<
  UpdateVisaTypeMutation,
  UpdateVisaTypeMutationVariables
>;

/**
 * __useUpdateVisaTypeMutation__
 *
 * To run a mutation, you first call `useUpdateVisaTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisaTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisaTypeMutation, { data, loading, error }] = useUpdateVisaTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVisaTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVisaTypeMutation, UpdateVisaTypeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVisaTypeMutation, UpdateVisaTypeMutationVariables>(
    UpdateVisaTypeDocument,
    options,
  );
}
export type UpdateVisaTypeMutationHookResult = ReturnType<typeof useUpdateVisaTypeMutation>;
export type UpdateVisaTypeMutationResult = Apollo.MutationResult<UpdateVisaTypeMutation>;
export type UpdateVisaTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateVisaTypeMutation,
  UpdateVisaTypeMutationVariables
>;
export const GetBranchByIdDocument = gql`
  query GetBranchById($id: ID!) {
    branchById(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useGetBranchByIdQuery__
 *
 * To run a query within a React component, call `useGetBranchByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBranchByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetBranchByIdQuery, GetBranchByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBranchByIdQuery, GetBranchByIdQueryVariables>(
    GetBranchByIdDocument,
    options,
  );
}
export function useGetBranchByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBranchByIdQuery, GetBranchByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBranchByIdQuery, GetBranchByIdQueryVariables>(
    GetBranchByIdDocument,
    options,
  );
}
export type GetBranchByIdQueryHookResult = ReturnType<typeof useGetBranchByIdQuery>;
export type GetBranchByIdLazyQueryHookResult = ReturnType<typeof useGetBranchByIdLazyQuery>;
export type GetBranchByIdQueryResult = Apollo.QueryResult<
  GetBranchByIdQuery,
  GetBranchByIdQueryVariables
>;
export const GetBranchesDocument = gql`
  query GetBranches($input: QueryBranchesInput!) {
    branches(input: $input) {
      id
      name
    }
  }
`;

/**
 * __useGetBranchesQuery__
 *
 * To run a query within a React component, call `useGetBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBranchesQuery(
  baseOptions: Apollo.QueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
}
export function useGetBranchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBranchesQuery, GetBranchesQueryVariables>(
    GetBranchesDocument,
    options,
  );
}
export type GetBranchesQueryHookResult = ReturnType<typeof useGetBranchesQuery>;
export type GetBranchesLazyQueryHookResult = ReturnType<typeof useGetBranchesLazyQuery>;
export type GetBranchesQueryResult = Apollo.QueryResult<
  GetBranchesQuery,
  GetBranchesQueryVariables
>;
export const DownloadClientFileByIdDocument = gql`
  query DownloadClientFileById($id: ID!) {
    downloadClientFileById(id: $id) {
      id
      path
    }
  }
`;

/**
 * __useDownloadClientFileByIdQuery__
 *
 * To run a query within a React component, call `useDownloadClientFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadClientFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadClientFileByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadClientFileByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadClientFileByIdQuery,
    DownloadClientFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadClientFileByIdQuery, DownloadClientFileByIdQueryVariables>(
    DownloadClientFileByIdDocument,
    options,
  );
}
export function useDownloadClientFileByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadClientFileByIdQuery,
    DownloadClientFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadClientFileByIdQuery, DownloadClientFileByIdQueryVariables>(
    DownloadClientFileByIdDocument,
    options,
  );
}
export type DownloadClientFileByIdQueryHookResult = ReturnType<
  typeof useDownloadClientFileByIdQuery
>;
export type DownloadClientFileByIdLazyQueryHookResult = ReturnType<
  typeof useDownloadClientFileByIdLazyQuery
>;
export type DownloadClientFileByIdQueryResult = Apollo.QueryResult<
  DownloadClientFileByIdQuery,
  DownloadClientFileByIdQueryVariables
>;
export const GetClientFilesDocument = gql`
  query GetClientFiles($input: QueryClientFilesInput!) {
    clientFiles(input: $input) {
      id
      type
      dateOfFile
      expiresAt
      value
      file {
        id
        size
        createdAt
        createdBy {
          id
          ...PersonNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientFilesQuery__
 *
 * To run a query within a React component, call `useGetClientFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientFilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientFilesQuery(
  baseOptions: Apollo.QueryHookOptions<GetClientFilesQuery, GetClientFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientFilesQuery, GetClientFilesQueryVariables>(
    GetClientFilesDocument,
    options,
  );
}
export function useGetClientFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClientFilesQuery, GetClientFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientFilesQuery, GetClientFilesQueryVariables>(
    GetClientFilesDocument,
    options,
  );
}
export type GetClientFilesQueryHookResult = ReturnType<typeof useGetClientFilesQuery>;
export type GetClientFilesLazyQueryHookResult = ReturnType<typeof useGetClientFilesLazyQuery>;
export type GetClientFilesQueryResult = Apollo.QueryResult<
  GetClientFilesQuery,
  GetClientFilesQueryVariables
>;
export const GetClientEnergyIntakeDocument = gql`
  query GetClientEnergyIntake($input: QueryClientObservationsInput!) {
    clientObservations(input: $input) {
      id
      date
      client {
        id
      }
      givenFood {
        id
        kilojoules
      }
    }
  }
`;

/**
 * __useGetClientEnergyIntakeQuery__
 *
 * To run a query within a React component, call `useGetClientEnergyIntakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientEnergyIntakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientEnergyIntakeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientEnergyIntakeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientEnergyIntakeQuery,
    GetClientEnergyIntakeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientEnergyIntakeQuery, GetClientEnergyIntakeQueryVariables>(
    GetClientEnergyIntakeDocument,
    options,
  );
}
export function useGetClientEnergyIntakeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientEnergyIntakeQuery,
    GetClientEnergyIntakeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientEnergyIntakeQuery, GetClientEnergyIntakeQueryVariables>(
    GetClientEnergyIntakeDocument,
    options,
  );
}
export type GetClientEnergyIntakeQueryHookResult = ReturnType<typeof useGetClientEnergyIntakeQuery>;
export type GetClientEnergyIntakeLazyQueryHookResult = ReturnType<
  typeof useGetClientEnergyIntakeLazyQuery
>;
export type GetClientEnergyIntakeQueryResult = Apollo.QueryResult<
  GetClientEnergyIntakeQuery,
  GetClientEnergyIntakeQueryVariables
>;
export const GetClientObservationByIdDocument = gql`
  query GetClientObservationById($id: ID!) {
    clientObservationById(id: $id) {
      id
      date
      notes
      nursesName
      bloodSystolic
      bloodDiastolic
      heart
      temp
      spo2
      respiration
      stoolType
      urineColor
      givenFood {
        id
        description
        kilojoules
      }
      givenMedications {
        id
        quantity
        medication {
          id
          name
        }
      }
      client {
        id
      }
      event {
        id
      }
      member {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientObservationByIdQuery__
 *
 * To run a query within a React component, call `useGetClientObservationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientObservationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientObservationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientObservationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientObservationByIdQuery,
    GetClientObservationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientObservationByIdQuery, GetClientObservationByIdQueryVariables>(
    GetClientObservationByIdDocument,
    options,
  );
}
export function useGetClientObservationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientObservationByIdQuery,
    GetClientObservationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientObservationByIdQuery, GetClientObservationByIdQueryVariables>(
    GetClientObservationByIdDocument,
    options,
  );
}
export type GetClientObservationByIdQueryHookResult = ReturnType<
  typeof useGetClientObservationByIdQuery
>;
export type GetClientObservationByIdLazyQueryHookResult = ReturnType<
  typeof useGetClientObservationByIdLazyQuery
>;
export type GetClientObservationByIdQueryResult = Apollo.QueryResult<
  GetClientObservationByIdQuery,
  GetClientObservationByIdQueryVariables
>;
export const GetClientObservationsDocument = gql`
  query GetClientObservations($input: QueryClientObservationsInput!) {
    clientObservations(input: $input) {
      id
      date
      notes
      nursesName
      bloodSystolic
      bloodDiastolic
      heart
      temp
      spo2
      respiration
      stoolType
      urineColor
      givenFood {
        id
        description
        kilojoules
      }
      givenMedications {
        id
        quantity
        medication {
          id
          name
        }
      }
      client {
        id
      }
      event {
        id
        member {
          id
          ...PersonNames
        }
      }
      member {
        id
        ...PersonNames
      }
      createdBy {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientObservationsQuery__
 *
 * To run a query within a React component, call `useGetClientObservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientObservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientObservationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientObservationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientObservationsQuery,
    GetClientObservationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientObservationsQuery, GetClientObservationsQueryVariables>(
    GetClientObservationsDocument,
    options,
  );
}
export function useGetClientObservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientObservationsQuery,
    GetClientObservationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientObservationsQuery, GetClientObservationsQueryVariables>(
    GetClientObservationsDocument,
    options,
  );
}
export type GetClientObservationsQueryHookResult = ReturnType<typeof useGetClientObservationsQuery>;
export type GetClientObservationsLazyQueryHookResult = ReturnType<
  typeof useGetClientObservationsLazyQuery
>;
export type GetClientObservationsQueryResult = Apollo.QueryResult<
  GetClientObservationsQuery,
  GetClientObservationsQueryVariables
>;
export const GetClientTeamsDocument = gql`
  query GetClientTeams($input: QueryClientTeamsInput!) {
    clientTeams(input: $input) {
      id
      preferred
      client {
        id
        ...PersonNames
      }
      member {
        id
        color
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientTeamsQuery__
 *
 * To run a query within a React component, call `useGetClientTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientTeamsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<GetClientTeamsQuery, GetClientTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientTeamsQuery, GetClientTeamsQueryVariables>(
    GetClientTeamsDocument,
    options,
  );
}
export function useGetClientTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClientTeamsQuery, GetClientTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientTeamsQuery, GetClientTeamsQueryVariables>(
    GetClientTeamsDocument,
    options,
  );
}
export type GetClientTeamsQueryHookResult = ReturnType<typeof useGetClientTeamsQuery>;
export type GetClientTeamsLazyQueryHookResult = ReturnType<typeof useGetClientTeamsLazyQuery>;
export type GetClientTeamsQueryResult = Apollo.QueryResult<
  GetClientTeamsQuery,
  GetClientTeamsQueryVariables
>;
export const GetClientByIdDocument = gql`
  query GetClientById($id: ID!) {
    clientById(id: $id) {
      id
      createdAt
      color
      dob
      email
      phone
      ndisId
      ndisPlanStartDate
      ndisPlanEndDate
      ndisPlanInitialFundsCore
      ndisPlanInitialFundsCs
      ndisPlanInitialFundsCb
      defaultEventDuration
      active
      allowMedication
      allowObservations
      allowAutomaticEventPopulation
      bloodSystolicUpperThreshold1
      bloodSystolicLowerThreshold1
      bloodDiastolicUpperThreshold1
      bloodDiastolicLowerThreshold1
      heartUpperThreshold1
      heartLowerThreshold1
      spo2UpperThreshold1
      spo2LowerThreshold1
      respirationUpperThreshold1
      respirationLowerThreshold1
      bloodSystolicUpperThreshold2
      bloodSystolicLowerThreshold2
      bloodDiastolicUpperThreshold2
      bloodDiastolicLowerThreshold2
      heartUpperThreshold2
      heartLowerThreshold2
      spo2UpperThreshold2
      spo2LowerThreshold2
      respirationUpperThreshold2
      respirationLowerThreshold2
      notes
      contactsName
      contactsEmail
      contactsPhone
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branch {
        id
        name
      }
      createdBy {
        id
        ...PersonNamesPartial
      }
      planManager {
        id
        name
      }
      supportCoordinator {
        id
        firstName
        lastName
      }
      archive {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
      delete {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetClientByIdQuery__
 *
 * To run a query within a React component, call `useGetClientByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetClientByIdQuery, GetClientByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientByIdQuery, GetClientByIdQueryVariables>(
    GetClientByIdDocument,
    options,
  );
}
export function useGetClientByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClientByIdQuery, GetClientByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientByIdQuery, GetClientByIdQueryVariables>(
    GetClientByIdDocument,
    options,
  );
}
export type GetClientByIdQueryHookResult = ReturnType<typeof useGetClientByIdQuery>;
export type GetClientByIdLazyQueryHookResult = ReturnType<typeof useGetClientByIdLazyQuery>;
export type GetClientByIdQueryResult = Apollo.QueryResult<
  GetClientByIdQuery,
  GetClientByIdQueryVariables
>;
export const GetClientMedicationsDocument = gql`
  query GetClientMedications($id: ID!, $input: QueryClientMedicationsInput!) {
    clientById(id: $id) {
      id
      medications(input: $input) {
        id
        medication {
          id
          name
        }
        client {
          id
        }
      }
    }
  }
`;

/**
 * __useGetClientMedicationsQuery__
 *
 * To run a query within a React component, call `useGetClientMedicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientMedicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientMedicationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientMedicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientMedicationsQuery,
    GetClientMedicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientMedicationsQuery, GetClientMedicationsQueryVariables>(
    GetClientMedicationsDocument,
    options,
  );
}
export function useGetClientMedicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientMedicationsQuery,
    GetClientMedicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientMedicationsQuery, GetClientMedicationsQueryVariables>(
    GetClientMedicationsDocument,
    options,
  );
}
export type GetClientMedicationsQueryHookResult = ReturnType<typeof useGetClientMedicationsQuery>;
export type GetClientMedicationsLazyQueryHookResult = ReturnType<
  typeof useGetClientMedicationsLazyQuery
>;
export type GetClientMedicationsQueryResult = Apollo.QueryResult<
  GetClientMedicationsQuery,
  GetClientMedicationsQueryVariables
>;
export const GetClientsDocument = gql`
  query GetClients($input: QueryClientsInput!) {
    clients(input: $input) {
      id
      createdAt
      color
      dob
      email
      phone
      ndisId
      defaultEventDuration
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branch {
        id
        name
      }
      archive {
        id
      }
      delete {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientsQuery(
  baseOptions: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
}
export function useGetClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const GetClientsSelectDocument = gql`
  query GetClientsSelect($input: QueryRedactedClientsInput!) {
    redactedClients(input: $input) {
      id
      ...PersonNamesPartial
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetClientsSelectQuery__
 *
 * To run a query within a React component, call `useGetClientsSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientsSelectQuery(
  baseOptions: Apollo.QueryHookOptions<GetClientsSelectQuery, GetClientsSelectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientsSelectQuery, GetClientsSelectQueryVariables>(
    GetClientsSelectDocument,
    options,
  );
}
export function useGetClientsSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClientsSelectQuery, GetClientsSelectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientsSelectQuery, GetClientsSelectQueryVariables>(
    GetClientsSelectDocument,
    options,
  );
}
export type GetClientsSelectQueryHookResult = ReturnType<typeof useGetClientsSelectQuery>;
export type GetClientsSelectLazyQueryHookResult = ReturnType<typeof useGetClientsSelectLazyQuery>;
export type GetClientsSelectQueryResult = Apollo.QueryResult<
  GetClientsSelectQuery,
  GetClientsSelectQueryVariables
>;
export const GetRedactedClientByIdDocument = gql`
  query GetRedactedClientById($id: ID!) {
    redactedClientById(id: $id) {
      id
      color
      age
      allowMedication
      allowObservations
      allowAutomaticEventPopulation
      bloodSystolicUpperThreshold1
      bloodSystolicLowerThreshold1
      bloodDiastolicUpperThreshold1
      bloodDiastolicLowerThreshold1
      heartUpperThreshold1
      heartLowerThreshold1
      spo2UpperThreshold1
      spo2LowerThreshold1
      respirationUpperThreshold1
      respirationLowerThreshold1
      bloodSystolicUpperThreshold2
      bloodSystolicLowerThreshold2
      bloodDiastolicUpperThreshold2
      bloodDiastolicLowerThreshold2
      heartUpperThreshold2
      heartLowerThreshold2
      spo2UpperThreshold2
      spo2LowerThreshold2
      respirationUpperThreshold2
      respirationLowerThreshold2
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;

/**
 * __useGetRedactedClientByIdQuery__
 *
 * To run a query within a React component, call `useGetRedactedClientByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedactedClientByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedactedClientByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRedactedClientByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRedactedClientByIdQuery,
    GetRedactedClientByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRedactedClientByIdQuery, GetRedactedClientByIdQueryVariables>(
    GetRedactedClientByIdDocument,
    options,
  );
}
export function useGetRedactedClientByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRedactedClientByIdQuery,
    GetRedactedClientByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRedactedClientByIdQuery, GetRedactedClientByIdQueryVariables>(
    GetRedactedClientByIdDocument,
    options,
  );
}
export type GetRedactedClientByIdQueryHookResult = ReturnType<typeof useGetRedactedClientByIdQuery>;
export type GetRedactedClientByIdLazyQueryHookResult = ReturnType<
  typeof useGetRedactedClientByIdLazyQuery
>;
export type GetRedactedClientByIdQueryResult = Apollo.QueryResult<
  GetRedactedClientByIdQuery,
  GetRedactedClientByIdQueryVariables
>;
export const GetRedactedClientMedicationsDocument = gql`
  query GetRedactedClientMedications($id: ID!, $input: QueryClientMedicationsInput!) {
    redactedClientById(id: $id) {
      id
      medications(input: $input) {
        id
        medication {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetRedactedClientMedicationsQuery__
 *
 * To run a query within a React component, call `useGetRedactedClientMedicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedactedClientMedicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedactedClientMedicationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRedactedClientMedicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRedactedClientMedicationsQuery,
    GetRedactedClientMedicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRedactedClientMedicationsQuery,
    GetRedactedClientMedicationsQueryVariables
  >(GetRedactedClientMedicationsDocument, options);
}
export function useGetRedactedClientMedicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRedactedClientMedicationsQuery,
    GetRedactedClientMedicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRedactedClientMedicationsQuery,
    GetRedactedClientMedicationsQueryVariables
  >(GetRedactedClientMedicationsDocument, options);
}
export type GetRedactedClientMedicationsQueryHookResult = ReturnType<
  typeof useGetRedactedClientMedicationsQuery
>;
export type GetRedactedClientMedicationsLazyQueryHookResult = ReturnType<
  typeof useGetRedactedClientMedicationsLazyQuery
>;
export type GetRedactedClientMedicationsQueryResult = Apollo.QueryResult<
  GetRedactedClientMedicationsQuery,
  GetRedactedClientMedicationsQueryVariables
>;
export const GetRedactedClientsDocument = gql`
  query GetRedactedClients($input: QueryRedactedClientsInput!) {
    redactedClients(input: $input) {
      id
      color
      age
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;

/**
 * __useGetRedactedClientsQuery__
 *
 * To run a query within a React component, call `useGetRedactedClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedactedClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedactedClientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRedactedClientsQuery(
  baseOptions: Apollo.QueryHookOptions<GetRedactedClientsQuery, GetRedactedClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRedactedClientsQuery, GetRedactedClientsQueryVariables>(
    GetRedactedClientsDocument,
    options,
  );
}
export function useGetRedactedClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRedactedClientsQuery,
    GetRedactedClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRedactedClientsQuery, GetRedactedClientsQueryVariables>(
    GetRedactedClientsDocument,
    options,
  );
}
export type GetRedactedClientsQueryHookResult = ReturnType<typeof useGetRedactedClientsQuery>;
export type GetRedactedClientsLazyQueryHookResult = ReturnType<
  typeof useGetRedactedClientsLazyQuery
>;
export type GetRedactedClientsQueryResult = Apollo.QueryResult<
  GetRedactedClientsQuery,
  GetRedactedClientsQueryVariables
>;
export const DownloadEventFileByIdDocument = gql`
  query DownloadEventFileById($id: ID!) {
    downloadEventFileById(id: $id) {
      id
      path
    }
  }
`;

/**
 * __useDownloadEventFileByIdQuery__
 *
 * To run a query within a React component, call `useDownloadEventFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadEventFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadEventFileByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadEventFileByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadEventFileByIdQuery,
    DownloadEventFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadEventFileByIdQuery, DownloadEventFileByIdQueryVariables>(
    DownloadEventFileByIdDocument,
    options,
  );
}
export function useDownloadEventFileByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadEventFileByIdQuery,
    DownloadEventFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadEventFileByIdQuery, DownloadEventFileByIdQueryVariables>(
    DownloadEventFileByIdDocument,
    options,
  );
}
export type DownloadEventFileByIdQueryHookResult = ReturnType<typeof useDownloadEventFileByIdQuery>;
export type DownloadEventFileByIdLazyQueryHookResult = ReturnType<
  typeof useDownloadEventFileByIdLazyQuery
>;
export type DownloadEventFileByIdQueryResult = Apollo.QueryResult<
  DownloadEventFileByIdQuery,
  DownloadEventFileByIdQueryVariables
>;
export const GetEventFilesDocument = gql`
  query GetEventFiles($input: QueryEventFilesInput!) {
    eventFiles(input: $input) {
      id
      type
      dateOfFile
      value
      file {
        id
        size
        createdAt
        createdBy {
          id
          ...PersonNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventFilesQuery__
 *
 * To run a query within a React component, call `useGetEventFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventFilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventFilesQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventFilesQuery, GetEventFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventFilesQuery, GetEventFilesQueryVariables>(
    GetEventFilesDocument,
    options,
  );
}
export function useGetEventFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEventFilesQuery, GetEventFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventFilesQuery, GetEventFilesQueryVariables>(
    GetEventFilesDocument,
    options,
  );
}
export type GetEventFilesQueryHookResult = ReturnType<typeof useGetEventFilesQuery>;
export type GetEventFilesLazyQueryHookResult = ReturnType<typeof useGetEventFilesLazyQuery>;
export type GetEventFilesQueryResult = Apollo.QueryResult<
  GetEventFilesQuery,
  GetEventFilesQueryVariables
>;
export const GetEventByIdDocument = gql`
  query GetEventById($id: ID!) {
    eventById(id: $id) {
      id
      createdAt
      createdBy {
        id
        ...PersonNames
      }
      startAt
      endAt
      duration
      notes
      summary
      scheduleNotes
      passive
      passiveStartAt
      activeAssist
      billable
      payable
      publicHoliday
      memberAssignedAutomatically
      ...AddressFull
      ...AddressGeolocation
      clockedOnAt
      clockedOffAt
      clockedOnLatitude
      clockedOnLongitude
      clockedOffLatitude
      clockedOffLongitude
      clockedOnBy {
        id
        ...PersonNames
      }
      clockedOffBy {
        id
        ...PersonNames
      }
      travelDistance
      travelDistanceNotes
      travelTime
      travelTimeNotes
      travelApprovedBy {
        id
        ...PersonNames
      }
      client {
        id
        color
        defaultEventDuration
        allowMedication
        allowObservations
        bloodSystolicUpperThreshold1
        bloodSystolicLowerThreshold1
        bloodDiastolicUpperThreshold1
        bloodDiastolicLowerThreshold1
        heartUpperThreshold1
        heartLowerThreshold1
        spo2UpperThreshold1
        spo2LowerThreshold1
        respirationUpperThreshold1
        respirationLowerThreshold1
        bloodSystolicUpperThreshold2
        bloodSystolicLowerThreshold2
        bloodDiastolicUpperThreshold2
        bloodDiastolicLowerThreshold2
        heartUpperThreshold2
        heartLowerThreshold2
        spo2UpperThreshold2
        spo2LowerThreshold2
        respirationUpperThreshold2
        respirationLowerThreshold2
        ...PersonNames
      }
      member {
        id
        color
        ...PersonNames
      }
      archive {
        id
      }
      delete {
        id
        date
        reason
        actor {
          id
          ...PersonNames
        }
      }
      cancel {
        id
        date
        reason
        actor {
          id
          ...PersonNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;

/**
 * __useGetEventByIdQuery__
 *
 * To run a query within a React component, call `useGetEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventByIdQuery, GetEventByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventByIdQuery, GetEventByIdQueryVariables>(
    GetEventByIdDocument,
    options,
  );
}
export function useGetEventByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEventByIdQuery, GetEventByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventByIdQuery, GetEventByIdQueryVariables>(
    GetEventByIdDocument,
    options,
  );
}
export type GetEventByIdQueryHookResult = ReturnType<typeof useGetEventByIdQuery>;
export type GetEventByIdLazyQueryHookResult = ReturnType<typeof useGetEventByIdLazyQuery>;
export type GetEventByIdQueryResult = Apollo.QueryResult<
  GetEventByIdQuery,
  GetEventByIdQueryVariables
>;
export const GetEventCreatorDocument = gql`
  query GetEventCreator($id: ID!) {
    eventById(id: $id) {
      id
      createdAt
      createdBy {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventCreatorQuery__
 *
 * To run a query within a React component, call `useGetEventCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventCreatorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventCreatorQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventCreatorQuery, GetEventCreatorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventCreatorQuery, GetEventCreatorQueryVariables>(
    GetEventCreatorDocument,
    options,
  );
}
export function useGetEventCreatorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEventCreatorQuery, GetEventCreatorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventCreatorQuery, GetEventCreatorQueryVariables>(
    GetEventCreatorDocument,
    options,
  );
}
export type GetEventCreatorQueryHookResult = ReturnType<typeof useGetEventCreatorQuery>;
export type GetEventCreatorLazyQueryHookResult = ReturnType<typeof useGetEventCreatorLazyQuery>;
export type GetEventCreatorQueryResult = Apollo.QueryResult<
  GetEventCreatorQuery,
  GetEventCreatorQueryVariables
>;
export const GetEventHistoriesDocument = gql`
  query GetEventHistories($input: QueryHistoriesInput!) {
    histories(input: $input) {
      ... on BaseHistory {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
      }
      ... on HistoryRestorable {
        reason
        restoredAt
        restoredBy {
          id
          firstName
          middleName
          lastName
          preferredName
        }
      }
      ... on HistoryEventActiveAssist {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventActiveAssistNew
        eventActiveAssistOld
      }
      ... on HistoryEventBillable {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventBillablenew
        eventBillableOld
      }
      ... on HistoryEventClient {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventClientNew {
          id
          firstName
          lastName
        }
        eventClientOld {
          id
          firstName
          lastName
        }
      }
      ... on HistoryEventCountry {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventCountryNew
        eventCountryOld
      }
      ... on HistoryEventDuration {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventDurationNew
        eventDurationOld
      }
      ... on HistoryEventLocality {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventLocalityNew
        eventLocalityOld
      }
      ... on HistoryEventMember {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventMemberNew {
          id
          firstName
          lastName
        }
        eventMemberOld {
          id
          firstName
          lastName
        }
      }
      ... on HistoryEventNotes {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
      }
      ... on HistoryEventPassiveStartAt {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventPassiveStartAtNew
        eventPassiveStartAtOld
      }
      ... on HistoryEventPassive {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventPassiveNew
        eventPassiveOld
      }
      ... on HistoryEventPayable {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventPayableNew
        eventPayableOld
      }
      ... on HistoryEventPostcode {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventPostcodeNew
        eventPostcodeOld
      }
      ... on HistoryEventPublicHoliday {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventPublicHolidayNew
        eventPublicHolidayOld
      }
      ... on HistoryEventRegion {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventRegionNew
        eventRegionOld
      }
      ... on HistoryEventScheduleNotes {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
      }
      ... on HistoryEventStartAt {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventStartAtNew
        eventStartAtOld
      }
      ... on HistoryEventClockedOnAt {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventClockedOnAtNew
        eventClockedOnAtOld
      }
      ... on HistoryEventClockedOffAt {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventClockedOffAtNew
        eventClockedOffAtOld
      }
      ... on HistoryEventStreet {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventStreetNew
        eventStreetOld
      }
      ... on HistoryEventSummary {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
      }
      ... on HistoryEventTravelDistanceNotes {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
      }
      ... on HistoryEventTravelDistance {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventTravelDistanceNew
        eventTravelDistanceOld
      }
      ... on HistoryEventTravelTimeNotes {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
      }
      ... on HistoryEventTravelTime {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventTravelTimeNew
        eventTravelTimeOld
      }
      ... on HistoryEventUnit {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventUnitNew
        eventUnitOld
      }
    }
  }
`;

/**
 * __useGetEventHistoriesQuery__
 *
 * To run a query within a React component, call `useGetEventHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventHistoriesQuery, GetEventHistoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventHistoriesQuery, GetEventHistoriesQueryVariables>(
    GetEventHistoriesDocument,
    options,
  );
}
export function useGetEventHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventHistoriesQuery,
    GetEventHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventHistoriesQuery, GetEventHistoriesQueryVariables>(
    GetEventHistoriesDocument,
    options,
  );
}
export type GetEventHistoriesQueryHookResult = ReturnType<typeof useGetEventHistoriesQuery>;
export type GetEventHistoriesLazyQueryHookResult = ReturnType<typeof useGetEventHistoriesLazyQuery>;
export type GetEventHistoriesQueryResult = Apollo.QueryResult<
  GetEventHistoriesQuery,
  GetEventHistoriesQueryVariables
>;
export const GetEventOwnAndRelatedByIdDocument = gql`
  query GetEventOwnAndRelatedById($id: ID!) {
    eventOwnAndRelatedById(id: $id) {
      id
      startAt
      endAt
      duration
      notes
      summary
      passive
      passiveStartAt
      activeAssist
      publicHoliday
      memberAssignedAutomatically
      unit
      street
      locality
      region
      postcode
      country
      latitude
      longitude
      clockedOnAt
      clockedOffAt
      travelDistance
      travelDistanceNotes
      travelTime
      travelTimeNotes
      client {
        id
        color
        allowMedication
        allowObservations
        bloodSystolicUpperThreshold1
        bloodSystolicLowerThreshold1
        bloodDiastolicUpperThreshold1
        bloodDiastolicLowerThreshold1
        heartUpperThreshold1
        heartLowerThreshold1
        spo2UpperThreshold1
        spo2LowerThreshold1
        respirationUpperThreshold1
        respirationLowerThreshold1
        bloodSystolicUpperThreshold2
        bloodSystolicLowerThreshold2
        bloodDiastolicUpperThreshold2
        bloodDiastolicLowerThreshold2
        heartUpperThreshold2
        heartLowerThreshold2
        spo2UpperThreshold2
        spo2LowerThreshold2
        respirationUpperThreshold2
        respirationLowerThreshold2
        ...PersonNames
      }
      member {
        id
        color
        ...PersonNames
      }
      cancel {
        id
        date
        reason
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventOwnAndRelatedByIdQuery__
 *
 * To run a query within a React component, call `useGetEventOwnAndRelatedByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventOwnAndRelatedByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventOwnAndRelatedByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventOwnAndRelatedByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventOwnAndRelatedByIdQuery,
    GetEventOwnAndRelatedByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventOwnAndRelatedByIdQuery, GetEventOwnAndRelatedByIdQueryVariables>(
    GetEventOwnAndRelatedByIdDocument,
    options,
  );
}
export function useGetEventOwnAndRelatedByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventOwnAndRelatedByIdQuery,
    GetEventOwnAndRelatedByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventOwnAndRelatedByIdQuery,
    GetEventOwnAndRelatedByIdQueryVariables
  >(GetEventOwnAndRelatedByIdDocument, options);
}
export type GetEventOwnAndRelatedByIdQueryHookResult = ReturnType<
  typeof useGetEventOwnAndRelatedByIdQuery
>;
export type GetEventOwnAndRelatedByIdLazyQueryHookResult = ReturnType<
  typeof useGetEventOwnAndRelatedByIdLazyQuery
>;
export type GetEventOwnAndRelatedByIdQueryResult = Apollo.QueryResult<
  GetEventOwnAndRelatedByIdQuery,
  GetEventOwnAndRelatedByIdQueryVariables
>;
export const GetEventTooltipDocument = gql`
  query GetEventTooltip($id: ID!) {
    eventById(id: $id) {
      id
      scheduleNotes
    }
  }
`;

/**
 * __useGetEventTooltipQuery__
 *
 * To run a query within a React component, call `useGetEventTooltipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventTooltipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventTooltipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventTooltipQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventTooltipQuery, GetEventTooltipQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventTooltipQuery, GetEventTooltipQueryVariables>(
    GetEventTooltipDocument,
    options,
  );
}
export function useGetEventTooltipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEventTooltipQuery, GetEventTooltipQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventTooltipQuery, GetEventTooltipQueryVariables>(
    GetEventTooltipDocument,
    options,
  );
}
export type GetEventTooltipQueryHookResult = ReturnType<typeof useGetEventTooltipQuery>;
export type GetEventTooltipLazyQueryHookResult = ReturnType<typeof useGetEventTooltipLazyQuery>;
export type GetEventTooltipQueryResult = Apollo.QueryResult<
  GetEventTooltipQuery,
  GetEventTooltipQueryVariables
>;
export const GetEventsDocument = gql`
  query GetEvents($input: QueryEventsInput!) {
    events(input: $input) {
      id
      createdAt
      startAt
      endAt
      duration
      clockedOnAt
      clockedOffAt
      passive
      passiveStartAt
      activeAssist
      hasNotes
      hasFiles
      conflictExists
      publicHoliday
      travelDistance
      travelTime
      memberAssignedAutomatically
      client {
        id
        ndisId
        color
        ...PersonNames
      }
      member {
        id
        color
        ...PersonNames
      }
      archive {
        id
      }
      delete {
        id
      }
      cancel {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventsQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
}
export function useGetEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
}
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const GetEventsMembersReadyToExportDocument = gql`
  query GetEventsMembersReadyToExport($input: QueryEventsInput!) {
    events(input: $input) {
      id
      clockedOnAt
      clockedOffAt
      hasNotes
      member {
        id
        bonusEligible
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventsMembersReadyToExportQuery__
 *
 * To run a query within a React component, call `useGetEventsMembersReadyToExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsMembersReadyToExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsMembersReadyToExportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventsMembersReadyToExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventsMembersReadyToExportQuery,
    GetEventsMembersReadyToExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventsMembersReadyToExportQuery,
    GetEventsMembersReadyToExportQueryVariables
  >(GetEventsMembersReadyToExportDocument, options);
}
export function useGetEventsMembersReadyToExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsMembersReadyToExportQuery,
    GetEventsMembersReadyToExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventsMembersReadyToExportQuery,
    GetEventsMembersReadyToExportQueryVariables
  >(GetEventsMembersReadyToExportDocument, options);
}
export type GetEventsMembersReadyToExportQueryHookResult = ReturnType<
  typeof useGetEventsMembersReadyToExportQuery
>;
export type GetEventsMembersReadyToExportLazyQueryHookResult = ReturnType<
  typeof useGetEventsMembersReadyToExportLazyQuery
>;
export type GetEventsMembersReadyToExportQueryResult = Apollo.QueryResult<
  GetEventsMembersReadyToExportQuery,
  GetEventsMembersReadyToExportQueryVariables
>;
export const GetEventsMembersPaySlipsDataDocument = gql`
  query GetEventsMembersPaySlipsData($input: QueryEventsInput!) {
    events(input: $input) {
      id
      clockedOnAt
      clockedOffAt
      hasNotes
      member {
        id
        basePayRate
        bonusEligible
        externalId
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventsMembersPaySlipsDataQuery__
 *
 * To run a query within a React component, call `useGetEventsMembersPaySlipsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsMembersPaySlipsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsMembersPaySlipsDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventsMembersPaySlipsDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventsMembersPaySlipsDataQuery,
    GetEventsMembersPaySlipsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventsMembersPaySlipsDataQuery,
    GetEventsMembersPaySlipsDataQueryVariables
  >(GetEventsMembersPaySlipsDataDocument, options);
}
export function useGetEventsMembersPaySlipsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsMembersPaySlipsDataQuery,
    GetEventsMembersPaySlipsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventsMembersPaySlipsDataQuery,
    GetEventsMembersPaySlipsDataQueryVariables
  >(GetEventsMembersPaySlipsDataDocument, options);
}
export type GetEventsMembersPaySlipsDataQueryHookResult = ReturnType<
  typeof useGetEventsMembersPaySlipsDataQuery
>;
export type GetEventsMembersPaySlipsDataLazyQueryHookResult = ReturnType<
  typeof useGetEventsMembersPaySlipsDataLazyQuery
>;
export type GetEventsMembersPaySlipsDataQueryResult = Apollo.QueryResult<
  GetEventsMembersPaySlipsDataQuery,
  GetEventsMembersPaySlipsDataQueryVariables
>;
export const GetEventsMembersWorkedHoursDocument = gql`
  query GetEventsMembersWorkedHours($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      clockedOnAt
      clockedOffAt
      member {
        id
        ...PersonNames
      }
      client {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventsMembersWorkedHoursQuery__
 *
 * To run a query within a React component, call `useGetEventsMembersWorkedHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsMembersWorkedHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsMembersWorkedHoursQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventsMembersWorkedHoursQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventsMembersWorkedHoursQuery,
    GetEventsMembersWorkedHoursQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventsMembersWorkedHoursQuery,
    GetEventsMembersWorkedHoursQueryVariables
  >(GetEventsMembersWorkedHoursDocument, options);
}
export function useGetEventsMembersWorkedHoursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsMembersWorkedHoursQuery,
    GetEventsMembersWorkedHoursQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventsMembersWorkedHoursQuery,
    GetEventsMembersWorkedHoursQueryVariables
  >(GetEventsMembersWorkedHoursDocument, options);
}
export type GetEventsMembersWorkedHoursQueryHookResult = ReturnType<
  typeof useGetEventsMembersWorkedHoursQuery
>;
export type GetEventsMembersWorkedHoursLazyQueryHookResult = ReturnType<
  typeof useGetEventsMembersWorkedHoursLazyQuery
>;
export type GetEventsMembersWorkedHoursQueryResult = Apollo.QueryResult<
  GetEventsMembersWorkedHoursQuery,
  GetEventsMembersWorkedHoursQueryVariables
>;
export const GetEventsOwnAndRelatedDocument = gql`
  query GetEventsOwnAndRelated($input: QueryEventsOwnAndRelatedInput!) {
    eventsOwnAndRelated(input: $input) {
      id
      createdAt
      startAt
      endAt
      duration
      clockedOnAt
      clockedOffAt
      passive
      passiveStartAt
      activeAssist
      hasNotes
      hasFiles
      publicHoliday
      travelDistance
      travelTime
      memberAssignedAutomatically
      client {
        id
        color
        ...PersonNames
      }
      member {
        id
        color
        ...PersonNames
      }
      cancel {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventsOwnAndRelatedQuery__
 *
 * To run a query within a React component, call `useGetEventsOwnAndRelatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsOwnAndRelatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsOwnAndRelatedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventsOwnAndRelatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventsOwnAndRelatedQuery,
    GetEventsOwnAndRelatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventsOwnAndRelatedQuery, GetEventsOwnAndRelatedQueryVariables>(
    GetEventsOwnAndRelatedDocument,
    options,
  );
}
export function useGetEventsOwnAndRelatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsOwnAndRelatedQuery,
    GetEventsOwnAndRelatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventsOwnAndRelatedQuery, GetEventsOwnAndRelatedQueryVariables>(
    GetEventsOwnAndRelatedDocument,
    options,
  );
}
export type GetEventsOwnAndRelatedQueryHookResult = ReturnType<
  typeof useGetEventsOwnAndRelatedQuery
>;
export type GetEventsOwnAndRelatedLazyQueryHookResult = ReturnType<
  typeof useGetEventsOwnAndRelatedLazyQuery
>;
export type GetEventsOwnAndRelatedQueryResult = Apollo.QueryResult<
  GetEventsOwnAndRelatedQuery,
  GetEventsOwnAndRelatedQueryVariables
>;
export const GetNonClockedEventsDocument = gql`
  query GetNonClockedEvents($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      clockedOnAt
      clockedOffAt
      client {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetNonClockedEventsQuery__
 *
 * To run a query within a React component, call `useGetNonClockedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonClockedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonClockedEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNonClockedEventsQuery(
  baseOptions: Apollo.QueryHookOptions<GetNonClockedEventsQuery, GetNonClockedEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNonClockedEventsQuery, GetNonClockedEventsQueryVariables>(
    GetNonClockedEventsDocument,
    options,
  );
}
export function useGetNonClockedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNonClockedEventsQuery,
    GetNonClockedEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNonClockedEventsQuery, GetNonClockedEventsQueryVariables>(
    GetNonClockedEventsDocument,
    options,
  );
}
export type GetNonClockedEventsQueryHookResult = ReturnType<typeof useGetNonClockedEventsQuery>;
export type GetNonClockedEventsLazyQueryHookResult = ReturnType<
  typeof useGetNonClockedEventsLazyQuery
>;
export type GetNonClockedEventsQueryResult = Apollo.QueryResult<
  GetNonClockedEventsQuery,
  GetNonClockedEventsQueryVariables
>;
export const GetUnfilledEventsDocument = gql`
  query GetUnfilledEvents($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      client {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetUnfilledEventsQuery__
 *
 * To run a query within a React component, call `useGetUnfilledEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnfilledEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnfilledEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUnfilledEventsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUnfilledEventsQuery, GetUnfilledEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnfilledEventsQuery, GetUnfilledEventsQueryVariables>(
    GetUnfilledEventsDocument,
    options,
  );
}
export function useGetUnfilledEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnfilledEventsQuery,
    GetUnfilledEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnfilledEventsQuery, GetUnfilledEventsQueryVariables>(
    GetUnfilledEventsDocument,
    options,
  );
}
export type GetUnfilledEventsQueryHookResult = ReturnType<typeof useGetUnfilledEventsQuery>;
export type GetUnfilledEventsLazyQueryHookResult = ReturnType<typeof useGetUnfilledEventsLazyQuery>;
export type GetUnfilledEventsQueryResult = Apollo.QueryResult<
  GetUnfilledEventsQuery,
  GetUnfilledEventsQueryVariables
>;
export const GetMedicationByIdDocument = gql`
  query GetMedicationById($id: ID!) {
    medicationById(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useGetMedicationByIdQuery__
 *
 * To run a query within a React component, call `useGetMedicationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMedicationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMedicationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMedicationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMedicationByIdQuery, GetMedicationByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMedicationByIdQuery, GetMedicationByIdQueryVariables>(
    GetMedicationByIdDocument,
    options,
  );
}
export function useGetMedicationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMedicationByIdQuery,
    GetMedicationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMedicationByIdQuery, GetMedicationByIdQueryVariables>(
    GetMedicationByIdDocument,
    options,
  );
}
export type GetMedicationByIdQueryHookResult = ReturnType<typeof useGetMedicationByIdQuery>;
export type GetMedicationByIdLazyQueryHookResult = ReturnType<typeof useGetMedicationByIdLazyQuery>;
export type GetMedicationByIdQueryResult = Apollo.QueryResult<
  GetMedicationByIdQuery,
  GetMedicationByIdQueryVariables
>;
export const GetMedicationsDocument = gql`
  query GetMedications($input: QueryMedicationsInput!) {
    medications(input: $input) {
      id
      name
    }
  }
`;

/**
 * __useGetMedicationsQuery__
 *
 * To run a query within a React component, call `useGetMedicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMedicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMedicationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMedicationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetMedicationsQuery, GetMedicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMedicationsQuery, GetMedicationsQueryVariables>(
    GetMedicationsDocument,
    options,
  );
}
export function useGetMedicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMedicationsQuery, GetMedicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMedicationsQuery, GetMedicationsQueryVariables>(
    GetMedicationsDocument,
    options,
  );
}
export type GetMedicationsQueryHookResult = ReturnType<typeof useGetMedicationsQuery>;
export type GetMedicationsLazyQueryHookResult = ReturnType<typeof useGetMedicationsLazyQuery>;
export type GetMedicationsQueryResult = Apollo.QueryResult<
  GetMedicationsQuery,
  GetMedicationsQueryVariables
>;
export const DownloadMemberFileByIdDocument = gql`
  query DownloadMemberFileById($id: ID!) {
    downloadMemberFileById(id: $id) {
      id
      path
    }
  }
`;

/**
 * __useDownloadMemberFileByIdQuery__
 *
 * To run a query within a React component, call `useDownloadMemberFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadMemberFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadMemberFileByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadMemberFileByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadMemberFileByIdQuery,
    DownloadMemberFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadMemberFileByIdQuery, DownloadMemberFileByIdQueryVariables>(
    DownloadMemberFileByIdDocument,
    options,
  );
}
export function useDownloadMemberFileByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadMemberFileByIdQuery,
    DownloadMemberFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadMemberFileByIdQuery, DownloadMemberFileByIdQueryVariables>(
    DownloadMemberFileByIdDocument,
    options,
  );
}
export type DownloadMemberFileByIdQueryHookResult = ReturnType<
  typeof useDownloadMemberFileByIdQuery
>;
export type DownloadMemberFileByIdLazyQueryHookResult = ReturnType<
  typeof useDownloadMemberFileByIdLazyQuery
>;
export type DownloadMemberFileByIdQueryResult = Apollo.QueryResult<
  DownloadMemberFileByIdQuery,
  DownloadMemberFileByIdQueryVariables
>;
export const GetMemberFilesDocument = gql`
  query GetMemberFiles($input: QueryMemberFilesInput!) {
    memberFiles(input: $input) {
      id
      type
      dateOfFile
      expiresAt
      value
      file {
        id
        size
        createdAt
        createdBy {
          id
          ...PersonNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberFilesQuery__
 *
 * To run a query within a React component, call `useGetMemberFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberFilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMemberFilesQuery(
  baseOptions: Apollo.QueryHookOptions<GetMemberFilesQuery, GetMemberFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberFilesQuery, GetMemberFilesQueryVariables>(
    GetMemberFilesDocument,
    options,
  );
}
export function useGetMemberFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMemberFilesQuery, GetMemberFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMemberFilesQuery, GetMemberFilesQueryVariables>(
    GetMemberFilesDocument,
    options,
  );
}
export type GetMemberFilesQueryHookResult = ReturnType<typeof useGetMemberFilesQuery>;
export type GetMemberFilesLazyQueryHookResult = ReturnType<typeof useGetMemberFilesLazyQuery>;
export type GetMemberFilesQueryResult = Apollo.QueryResult<
  GetMemberFilesQuery,
  GetMemberFilesQueryVariables
>;
export const GetMemberByIdDocument = gql`
  query GetMemberById($id: ID!) {
    memberById(id: $id) {
      id
      createdAt
      lastActiveAt
      hasAccess
      admin
      permissions
      externalId
      schedulable
      workRights
      workRightsLastCheckedAt
      allowedFortnightlyWorkHours
      driversLicense
      driversLicenseCountry
      driversLicenseExpiryDate
      hasCar
      carDescription
      color
      dob
      email
      phone
      notes
      employmentStartDate
      employmentEndDate
      study
      basePayRate
      bonusEligible
      unavailableNotes
      unavailableMoMorn
      unavailableMoDay
      unavailableMoNight
      unavailableTuMorn
      unavailableTuDay
      unavailableTuNight
      unavailableWeMorn
      unavailableWeDay
      unavailableWeNight
      unavailableThMorn
      unavailableThDay
      unavailableThNight
      unavailableFrMorn
      unavailableFrDay
      unavailableFrNight
      unavailableSaMorn
      unavailableSaDay
      unavailableSaNight
      unavailableSuMorn
      unavailableSuDay
      unavailableSuNight
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      visaType {
        id
        name
      }
      createdBy {
        id
        ...PersonNamesPartial
      }
      invitationMostRecentlyReceived {
        id
        deletedAt
        usedAt
        sentCount
        lastSender {
          id
          sentAt
          sentBy {
            id
            ...PersonNamesPartial
          }
        }
      }
      archive {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
      delete {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetMemberByIdQuery__
 *
 * To run a query within a React component, call `useGetMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMemberByIdQuery, GetMemberByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberByIdQuery, GetMemberByIdQueryVariables>(
    GetMemberByIdDocument,
    options,
  );
}
export function useGetMemberByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMemberByIdQuery, GetMemberByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMemberByIdQuery, GetMemberByIdQueryVariables>(
    GetMemberByIdDocument,
    options,
  );
}
export type GetMemberByIdQueryHookResult = ReturnType<typeof useGetMemberByIdQuery>;
export type GetMemberByIdLazyQueryHookResult = ReturnType<typeof useGetMemberByIdLazyQuery>;
export type GetMemberByIdQueryResult = Apollo.QueryResult<
  GetMemberByIdQuery,
  GetMemberByIdQueryVariables
>;
export const GetMemberScheduleByIdDocument = gql`
  query GetMemberScheduleById($id: ID!, $scheduleInput: QueryMemberScheduleInput!) {
    memberById(id: $id) {
      id
      schedule(input: $scheduleInput) {
        timeScheduled
      }
    }
  }
`;

/**
 * __useGetMemberScheduleByIdQuery__
 *
 * To run a query within a React component, call `useGetMemberScheduleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberScheduleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberScheduleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      scheduleInput: // value for 'scheduleInput'
 *   },
 * });
 */
export function useGetMemberScheduleByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberScheduleByIdQuery,
    GetMemberScheduleByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberScheduleByIdQuery, GetMemberScheduleByIdQueryVariables>(
    GetMemberScheduleByIdDocument,
    options,
  );
}
export function useGetMemberScheduleByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberScheduleByIdQuery,
    GetMemberScheduleByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMemberScheduleByIdQuery, GetMemberScheduleByIdQueryVariables>(
    GetMemberScheduleByIdDocument,
    options,
  );
}
export type GetMemberScheduleByIdQueryHookResult = ReturnType<typeof useGetMemberScheduleByIdQuery>;
export type GetMemberScheduleByIdLazyQueryHookResult = ReturnType<
  typeof useGetMemberScheduleByIdLazyQuery
>;
export type GetMemberScheduleByIdQueryResult = Apollo.QueryResult<
  GetMemberScheduleByIdQuery,
  GetMemberScheduleByIdQueryVariables
>;
export const GetMemberTypicalUnavailablilityDocument = gql`
  query GetMemberTypicalUnavailablility($id: ID!) {
    memberById(id: $id) {
      id
      unavailableNotes
      unavailableMoMorn
      unavailableMoDay
      unavailableMoNight
      unavailableTuMorn
      unavailableTuDay
      unavailableTuNight
      unavailableWeMorn
      unavailableWeDay
      unavailableWeNight
      unavailableThMorn
      unavailableThDay
      unavailableThNight
      unavailableFrMorn
      unavailableFrDay
      unavailableFrNight
      unavailableSaMorn
      unavailableSaDay
      unavailableSaNight
      unavailableSuMorn
      unavailableSuDay
      unavailableSuNight
    }
  }
`;

/**
 * __useGetMemberTypicalUnavailablilityQuery__
 *
 * To run a query within a React component, call `useGetMemberTypicalUnavailablilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberTypicalUnavailablilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberTypicalUnavailablilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberTypicalUnavailablilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberTypicalUnavailablilityQuery,
    GetMemberTypicalUnavailablilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberTypicalUnavailablilityQuery,
    GetMemberTypicalUnavailablilityQueryVariables
  >(GetMemberTypicalUnavailablilityDocument, options);
}
export function useGetMemberTypicalUnavailablilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberTypicalUnavailablilityQuery,
    GetMemberTypicalUnavailablilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberTypicalUnavailablilityQuery,
    GetMemberTypicalUnavailablilityQueryVariables
  >(GetMemberTypicalUnavailablilityDocument, options);
}
export type GetMemberTypicalUnavailablilityQueryHookResult = ReturnType<
  typeof useGetMemberTypicalUnavailablilityQuery
>;
export type GetMemberTypicalUnavailablilityLazyQueryHookResult = ReturnType<
  typeof useGetMemberTypicalUnavailablilityLazyQuery
>;
export type GetMemberTypicalUnavailablilityQueryResult = Apollo.QueryResult<
  GetMemberTypicalUnavailablilityQuery,
  GetMemberTypicalUnavailablilityQueryVariables
>;
export const GetMemberUnavailablesDocument = gql`
  query GetMemberUnavailables($unavailablesInput: QueryMemberUnavailablesInput!) {
    memberUnavailables(input: $unavailablesInput) {
      id
      startAt
      endAt
      notes
      owner {
        id
      }
    }
  }
`;

/**
 * __useGetMemberUnavailablesQuery__
 *
 * To run a query within a React component, call `useGetMemberUnavailablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberUnavailablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberUnavailablesQuery({
 *   variables: {
 *      unavailablesInput: // value for 'unavailablesInput'
 *   },
 * });
 */
export function useGetMemberUnavailablesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberUnavailablesQuery,
    GetMemberUnavailablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberUnavailablesQuery, GetMemberUnavailablesQueryVariables>(
    GetMemberUnavailablesDocument,
    options,
  );
}
export function useGetMemberUnavailablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberUnavailablesQuery,
    GetMemberUnavailablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMemberUnavailablesQuery, GetMemberUnavailablesQueryVariables>(
    GetMemberUnavailablesDocument,
    options,
  );
}
export type GetMemberUnavailablesQueryHookResult = ReturnType<typeof useGetMemberUnavailablesQuery>;
export type GetMemberUnavailablesLazyQueryHookResult = ReturnType<
  typeof useGetMemberUnavailablesLazyQuery
>;
export type GetMemberUnavailablesQueryResult = Apollo.QueryResult<
  GetMemberUnavailablesQuery,
  GetMemberUnavailablesQueryVariables
>;
export const GetMembersDocument = gql`
  query GetMembers($input: QueryMembersInput!) {
    members(input: $input) {
      id
      admin
      permissions
      color
      hasAccess
      externalId
      lastActiveAt
      ...PersonNames
      invitationMostRecentlyReceived {
        id
        deletedAt
        usedAt
      }
      archive {
        id
      }
      delete {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMembersQuery(
  baseOptions: Apollo.QueryHookOptions<GetMembersQuery, GetMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
}
export function useGetMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(
    GetMembersDocument,
    options,
  );
}
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>;
export type GetMembersQueryResult = Apollo.QueryResult<GetMembersQuery, GetMembersQueryVariables>;
export const GetMemberBasePayRateDocument = gql`
  query GetMemberBasePayRate($id: ID!) {
    memberById(id: $id) {
      id
      basePayRate
    }
  }
`;

/**
 * __useGetMemberBasePayRateQuery__
 *
 * To run a query within a React component, call `useGetMemberBasePayRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberBasePayRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberBasePayRateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberBasePayRateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberBasePayRateQuery,
    GetMemberBasePayRateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberBasePayRateQuery, GetMemberBasePayRateQueryVariables>(
    GetMemberBasePayRateDocument,
    options,
  );
}
export function useGetMemberBasePayRateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberBasePayRateQuery,
    GetMemberBasePayRateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMemberBasePayRateQuery, GetMemberBasePayRateQueryVariables>(
    GetMemberBasePayRateDocument,
    options,
  );
}
export type GetMemberBasePayRateQueryHookResult = ReturnType<typeof useGetMemberBasePayRateQuery>;
export type GetMemberBasePayRateLazyQueryHookResult = ReturnType<
  typeof useGetMemberBasePayRateLazyQuery
>;
export type GetMemberBasePayRateQueryResult = Apollo.QueryResult<
  GetMemberBasePayRateQuery,
  GetMemberBasePayRateQueryVariables
>;
export const GetMemberPermissionsDocument = gql`
  query GetMemberPermissions($input: QueryMembersInput!) {
    members(input: $input) {
      id
      admin
      permissions
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberPermissionsQuery__
 *
 * To run a query within a React component, call `useGetMemberPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPermissionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMemberPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberPermissionsQuery,
    GetMemberPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberPermissionsQuery, GetMemberPermissionsQueryVariables>(
    GetMemberPermissionsDocument,
    options,
  );
}
export function useGetMemberPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberPermissionsQuery,
    GetMemberPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMemberPermissionsQuery, GetMemberPermissionsQueryVariables>(
    GetMemberPermissionsDocument,
    options,
  );
}
export type GetMemberPermissionsQueryHookResult = ReturnType<typeof useGetMemberPermissionsQuery>;
export type GetMemberPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetMemberPermissionsLazyQuery
>;
export type GetMemberPermissionsQueryResult = Apollo.QueryResult<
  GetMemberPermissionsQuery,
  GetMemberPermissionsQueryVariables
>;
export const GetMembersSelectDocument = gql`
  query GetMembersSelect($input: QueryMembersInput!) {
    members(input: $input) {
      id
      ...PersonNamesPartial
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetMembersSelectQuery__
 *
 * To run a query within a React component, call `useGetMembersSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMembersSelectQuery(
  baseOptions: Apollo.QueryHookOptions<GetMembersSelectQuery, GetMembersSelectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMembersSelectQuery, GetMembersSelectQueryVariables>(
    GetMembersSelectDocument,
    options,
  );
}
export function useGetMembersSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMembersSelectQuery, GetMembersSelectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMembersSelectQuery, GetMembersSelectQueryVariables>(
    GetMembersSelectDocument,
    options,
  );
}
export type GetMembersSelectQueryHookResult = ReturnType<typeof useGetMembersSelectQuery>;
export type GetMembersSelectLazyQueryHookResult = ReturnType<typeof useGetMembersSelectLazyQuery>;
export type GetMembersSelectQueryResult = Apollo.QueryResult<
  GetMembersSelectQuery,
  GetMembersSelectQueryVariables
>;
export const GetMyUnavailablesDocument = gql`
  query GetMyUnavailables($input: QueryMyMemberUnavailablesInput!) {
    myUnavailables(input: $input) {
      id
      startAt
      endAt
      notes
      owner {
        id
      }
    }
  }
`;

/**
 * __useGetMyUnavailablesQuery__
 *
 * To run a query within a React component, call `useGetMyUnavailablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUnavailablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUnavailablesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyUnavailablesQuery(
  baseOptions: Apollo.QueryHookOptions<GetMyUnavailablesQuery, GetMyUnavailablesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyUnavailablesQuery, GetMyUnavailablesQueryVariables>(
    GetMyUnavailablesDocument,
    options,
  );
}
export function useGetMyUnavailablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyUnavailablesQuery,
    GetMyUnavailablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyUnavailablesQuery, GetMyUnavailablesQueryVariables>(
    GetMyUnavailablesDocument,
    options,
  );
}
export type GetMyUnavailablesQueryHookResult = ReturnType<typeof useGetMyUnavailablesQuery>;
export type GetMyUnavailablesLazyQueryHookResult = ReturnType<typeof useGetMyUnavailablesLazyQuery>;
export type GetMyUnavailablesQueryResult = Apollo.QueryResult<
  GetMyUnavailablesQuery,
  GetMyUnavailablesQueryVariables
>;
export const GetRedactedMemberByIdDocument = gql`
  query GetRedactedMemberById($id: ID!) {
    redactedMemberById(id: $id) {
      id
      color
      email
      phone
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetRedactedMemberByIdQuery__
 *
 * To run a query within a React component, call `useGetRedactedMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedactedMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedactedMemberByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRedactedMemberByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRedactedMemberByIdQuery,
    GetRedactedMemberByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRedactedMemberByIdQuery, GetRedactedMemberByIdQueryVariables>(
    GetRedactedMemberByIdDocument,
    options,
  );
}
export function useGetRedactedMemberByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRedactedMemberByIdQuery,
    GetRedactedMemberByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRedactedMemberByIdQuery, GetRedactedMemberByIdQueryVariables>(
    GetRedactedMemberByIdDocument,
    options,
  );
}
export type GetRedactedMemberByIdQueryHookResult = ReturnType<typeof useGetRedactedMemberByIdQuery>;
export type GetRedactedMemberByIdLazyQueryHookResult = ReturnType<
  typeof useGetRedactedMemberByIdLazyQuery
>;
export type GetRedactedMemberByIdQueryResult = Apollo.QueryResult<
  GetRedactedMemberByIdQuery,
  GetRedactedMemberByIdQueryVariables
>;
export const GetRedactedMembersDocument = gql`
  query GetRedactedMembers($input: QueryRedactedMembersInput!) {
    redactedMembers(input: $input) {
      id
      color
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetRedactedMembersQuery__
 *
 * To run a query within a React component, call `useGetRedactedMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedactedMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedactedMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRedactedMembersQuery(
  baseOptions: Apollo.QueryHookOptions<GetRedactedMembersQuery, GetRedactedMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRedactedMembersQuery, GetRedactedMembersQueryVariables>(
    GetRedactedMembersDocument,
    options,
  );
}
export function useGetRedactedMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRedactedMembersQuery,
    GetRedactedMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRedactedMembersQuery, GetRedactedMembersQueryVariables>(
    GetRedactedMembersDocument,
    options,
  );
}
export type GetRedactedMembersQueryHookResult = ReturnType<typeof useGetRedactedMembersQuery>;
export type GetRedactedMembersLazyQueryHookResult = ReturnType<
  typeof useGetRedactedMembersLazyQuery
>;
export type GetRedactedMembersQueryResult = Apollo.QueryResult<
  GetRedactedMembersQuery,
  GetRedactedMembersQueryVariables
>;
export const GetNotificationByIdDocument = gql`
  query GetNotificationById($id: ID!) {
    notificationById(id: $id) {
      id
      message
    }
  }
`;

/**
 * __useGetNotificationByIdQuery__
 *
 * To run a query within a React component, call `useGetNotificationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotificationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetNotificationByIdQuery, GetNotificationByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationByIdQuery, GetNotificationByIdQueryVariables>(
    GetNotificationByIdDocument,
    options,
  );
}
export function useGetNotificationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationByIdQuery,
    GetNotificationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotificationByIdQuery, GetNotificationByIdQueryVariables>(
    GetNotificationByIdDocument,
    options,
  );
}
export type GetNotificationByIdQueryHookResult = ReturnType<typeof useGetNotificationByIdQuery>;
export type GetNotificationByIdLazyQueryHookResult = ReturnType<
  typeof useGetNotificationByIdLazyQuery
>;
export type GetNotificationByIdQueryResult = Apollo.QueryResult<
  GetNotificationByIdQuery,
  GetNotificationByIdQueryVariables
>;
export const GetNotificationsDocument = gql`
  query GetNotifications($input: QueryNotificationsInput!) {
    notificationsAggregate(input: $input) {
      aggregate {
        totalCount
      }
      nodes {
        id
        message
      }
    }
  }
`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options,
  );
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options,
  );
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>;
export const GetCurrentOrgPayrollSettingsDocument = gql`
  query GetCurrentOrgPayrollSettings {
    me {
      id
      member {
        id
        org {
          id
          basePayRate
          afternoonPayMultiplier
          nightPayMultiplier
          satPayMultiplier
          sunPayMultiplier
          pubPayMultiplier
          passiveAllowance
          kmAllowance
          bonus2PayRate
          bonus3PayRate
          bonus4PayRate
          bonus5PayRate
          bonus6PayRate
          bonus7PayRate
          bonus8PayRate
          bonus9PayRate
          bonus10PayRate
          bonus11PayRate
          bonus12PayRate
        }
      }
    }
  }
`;

/**
 * __useGetCurrentOrgPayrollSettingsQuery__
 *
 * To run a query within a React component, call `useGetCurrentOrgPayrollSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentOrgPayrollSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentOrgPayrollSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentOrgPayrollSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentOrgPayrollSettingsQuery,
    GetCurrentOrgPayrollSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentOrgPayrollSettingsQuery,
    GetCurrentOrgPayrollSettingsQueryVariables
  >(GetCurrentOrgPayrollSettingsDocument, options);
}
export function useGetCurrentOrgPayrollSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentOrgPayrollSettingsQuery,
    GetCurrentOrgPayrollSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentOrgPayrollSettingsQuery,
    GetCurrentOrgPayrollSettingsQueryVariables
  >(GetCurrentOrgPayrollSettingsDocument, options);
}
export type GetCurrentOrgPayrollSettingsQueryHookResult = ReturnType<
  typeof useGetCurrentOrgPayrollSettingsQuery
>;
export type GetCurrentOrgPayrollSettingsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentOrgPayrollSettingsLazyQuery
>;
export type GetCurrentOrgPayrollSettingsQueryResult = Apollo.QueryResult<
  GetCurrentOrgPayrollSettingsQuery,
  GetCurrentOrgPayrollSettingsQueryVariables
>;
export const GetOrgPayrollSettingsByIdDocument = gql`
  query GetOrgPayrollSettingsById($id: ID!) {
    orgById(id: $id) {
      id
      basePayRate
      afternoonPayMultiplier
      nightPayMultiplier
      satPayMultiplier
      sunPayMultiplier
      pubPayMultiplier
      passiveAllowance
      kmAllowance
      bonus2PayRate
      bonus3PayRate
      bonus4PayRate
      bonus5PayRate
      bonus6PayRate
      bonus7PayRate
      bonus8PayRate
      bonus9PayRate
      bonus10PayRate
      bonus11PayRate
      bonus12PayRate
    }
  }
`;

/**
 * __useGetOrgPayrollSettingsByIdQuery__
 *
 * To run a query within a React component, call `useGetOrgPayrollSettingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgPayrollSettingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgPayrollSettingsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgPayrollSettingsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrgPayrollSettingsByIdQuery,
    GetOrgPayrollSettingsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrgPayrollSettingsByIdQuery, GetOrgPayrollSettingsByIdQueryVariables>(
    GetOrgPayrollSettingsByIdDocument,
    options,
  );
}
export function useGetOrgPayrollSettingsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgPayrollSettingsByIdQuery,
    GetOrgPayrollSettingsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgPayrollSettingsByIdQuery,
    GetOrgPayrollSettingsByIdQueryVariables
  >(GetOrgPayrollSettingsByIdDocument, options);
}
export type GetOrgPayrollSettingsByIdQueryHookResult = ReturnType<
  typeof useGetOrgPayrollSettingsByIdQuery
>;
export type GetOrgPayrollSettingsByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrgPayrollSettingsByIdLazyQuery
>;
export type GetOrgPayrollSettingsByIdQueryResult = Apollo.QueryResult<
  GetOrgPayrollSettingsByIdQuery,
  GetOrgPayrollSettingsByIdQueryVariables
>;
export const GetOrgSettingsByIdDocument = gql`
  query GetOrgSettingsById($id: ID!) {
    orgById(id: $id) {
      id
      membersCanSeeClockedTimes
      quantityOfFutureShiftsMembersCanSee
      quantityOfPastShiftsMembersCanSee
      runAutoAssignMemberTask
      assignMemberToEventWeeksInAdvance
      unfilledEventsDayRange
      clockOnWarnMinutes
      clockOffWarnMinutes
      schedulingContactPerson {
        id
        phone
        ...PersonNamesPartial
      }
      schedulingContactPerson2 {
        id
        phone
        ...PersonNamesPartial
      }
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetOrgSettingsByIdQuery__
 *
 * To run a query within a React component, call `useGetOrgSettingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgSettingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgSettingsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgSettingsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrgSettingsByIdQuery, GetOrgSettingsByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrgSettingsByIdQuery, GetOrgSettingsByIdQueryVariables>(
    GetOrgSettingsByIdDocument,
    options,
  );
}
export function useGetOrgSettingsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgSettingsByIdQuery,
    GetOrgSettingsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrgSettingsByIdQuery, GetOrgSettingsByIdQueryVariables>(
    GetOrgSettingsByIdDocument,
    options,
  );
}
export type GetOrgSettingsByIdQueryHookResult = ReturnType<typeof useGetOrgSettingsByIdQuery>;
export type GetOrgSettingsByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrgSettingsByIdLazyQuery
>;
export type GetOrgSettingsByIdQueryResult = Apollo.QueryResult<
  GetOrgSettingsByIdQuery,
  GetOrgSettingsByIdQueryVariables
>;
export const GetOrgUnfilledEventsDayRangeDocument = gql`
  query GetOrgUnfilledEventsDayRange($id: ID!) {
    orgById(id: $id) {
      id
      unfilledEventsDayRange
    }
  }
`;

/**
 * __useGetOrgUnfilledEventsDayRangeQuery__
 *
 * To run a query within a React component, call `useGetOrgUnfilledEventsDayRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgUnfilledEventsDayRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgUnfilledEventsDayRangeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgUnfilledEventsDayRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrgUnfilledEventsDayRangeQuery,
    GetOrgUnfilledEventsDayRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrgUnfilledEventsDayRangeQuery,
    GetOrgUnfilledEventsDayRangeQueryVariables
  >(GetOrgUnfilledEventsDayRangeDocument, options);
}
export function useGetOrgUnfilledEventsDayRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgUnfilledEventsDayRangeQuery,
    GetOrgUnfilledEventsDayRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgUnfilledEventsDayRangeQuery,
    GetOrgUnfilledEventsDayRangeQueryVariables
  >(GetOrgUnfilledEventsDayRangeDocument, options);
}
export type GetOrgUnfilledEventsDayRangeQueryHookResult = ReturnType<
  typeof useGetOrgUnfilledEventsDayRangeQuery
>;
export type GetOrgUnfilledEventsDayRangeLazyQueryHookResult = ReturnType<
  typeof useGetOrgUnfilledEventsDayRangeLazyQuery
>;
export type GetOrgUnfilledEventsDayRangeQueryResult = Apollo.QueryResult<
  GetOrgUnfilledEventsDayRangeQuery,
  GetOrgUnfilledEventsDayRangeQueryVariables
>;
export const GetPlanManagerByIdDocument = gql`
  query GetPlanManagerById($id: ID!) {
    planManagerById(id: $id) {
      id
      abn
      name
      salutation
      phone
      businessName
      contactEmail
      billingEmail
      notes
      archive {
        id
      }
      delete {
        id
      }
    }
  }
`;

/**
 * __useGetPlanManagerByIdQuery__
 *
 * To run a query within a React component, call `useGetPlanManagerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanManagerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanManagerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlanManagerByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlanManagerByIdQuery, GetPlanManagerByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlanManagerByIdQuery, GetPlanManagerByIdQueryVariables>(
    GetPlanManagerByIdDocument,
    options,
  );
}
export function useGetPlanManagerByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlanManagerByIdQuery,
    GetPlanManagerByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlanManagerByIdQuery, GetPlanManagerByIdQueryVariables>(
    GetPlanManagerByIdDocument,
    options,
  );
}
export type GetPlanManagerByIdQueryHookResult = ReturnType<typeof useGetPlanManagerByIdQuery>;
export type GetPlanManagerByIdLazyQueryHookResult = ReturnType<
  typeof useGetPlanManagerByIdLazyQuery
>;
export type GetPlanManagerByIdQueryResult = Apollo.QueryResult<
  GetPlanManagerByIdQuery,
  GetPlanManagerByIdQueryVariables
>;
export const GetPlanManagersDocument = gql`
  query GetPlanManagers($input: QueryPlanManagersInput!) {
    planManagers(input: $input) {
      id
      name
      archive {
        id
      }
      delete {
        id
      }
    }
  }
`;

/**
 * __useGetPlanManagersQuery__
 *
 * To run a query within a React component, call `useGetPlanManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanManagersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPlanManagersQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlanManagersQuery, GetPlanManagersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlanManagersQuery, GetPlanManagersQueryVariables>(
    GetPlanManagersDocument,
    options,
  );
}
export function useGetPlanManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlanManagersQuery, GetPlanManagersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlanManagersQuery, GetPlanManagersQueryVariables>(
    GetPlanManagersDocument,
    options,
  );
}
export type GetPlanManagersQueryHookResult = ReturnType<typeof useGetPlanManagersQuery>;
export type GetPlanManagersLazyQueryHookResult = ReturnType<typeof useGetPlanManagersLazyQuery>;
export type GetPlanManagersQueryResult = Apollo.QueryResult<
  GetPlanManagersQuery,
  GetPlanManagersQueryVariables
>;
export const GetPublicHolidayByIdDocument = gql`
  query GetPublicHolidayById($id: ID!) {
    publicHolidayById(id: $id) {
      id
      date
      description
    }
  }
`;

/**
 * __useGetPublicHolidayByIdQuery__
 *
 * To run a query within a React component, call `useGetPublicHolidayByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicHolidayByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicHolidayByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPublicHolidayByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicHolidayByIdQuery,
    GetPublicHolidayByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPublicHolidayByIdQuery, GetPublicHolidayByIdQueryVariables>(
    GetPublicHolidayByIdDocument,
    options,
  );
}
export function useGetPublicHolidayByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicHolidayByIdQuery,
    GetPublicHolidayByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPublicHolidayByIdQuery, GetPublicHolidayByIdQueryVariables>(
    GetPublicHolidayByIdDocument,
    options,
  );
}
export type GetPublicHolidayByIdQueryHookResult = ReturnType<typeof useGetPublicHolidayByIdQuery>;
export type GetPublicHolidayByIdLazyQueryHookResult = ReturnType<
  typeof useGetPublicHolidayByIdLazyQuery
>;
export type GetPublicHolidayByIdQueryResult = Apollo.QueryResult<
  GetPublicHolidayByIdQuery,
  GetPublicHolidayByIdQueryVariables
>;
export const GetPublicHolidaysDocument = gql`
  query GetPublicHolidays($input: QueryPublicHolidaysInput!) {
    publicHolidays(input: $input) {
      id
      date
      description
    }
  }
`;

/**
 * __useGetPublicHolidaysQuery__
 *
 * To run a query within a React component, call `useGetPublicHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicHolidaysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPublicHolidaysQuery(
  baseOptions: Apollo.QueryHookOptions<GetPublicHolidaysQuery, GetPublicHolidaysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPublicHolidaysQuery, GetPublicHolidaysQueryVariables>(
    GetPublicHolidaysDocument,
    options,
  );
}
export function useGetPublicHolidaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicHolidaysQuery,
    GetPublicHolidaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPublicHolidaysQuery, GetPublicHolidaysQueryVariables>(
    GetPublicHolidaysDocument,
    options,
  );
}
export type GetPublicHolidaysQueryHookResult = ReturnType<typeof useGetPublicHolidaysQuery>;
export type GetPublicHolidaysLazyQueryHookResult = ReturnType<typeof useGetPublicHolidaysLazyQuery>;
export type GetPublicHolidaysQueryResult = Apollo.QueryResult<
  GetPublicHolidaysQuery,
  GetPublicHolidaysQueryVariables
>;
export const GetActivitySlipMembersDocument = gql`
  query GetActivitySlipMembers($input: QueryMembersInput!) {
    members(input: $input) {
      id
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetActivitySlipMembersQuery__
 *
 * To run a query within a React component, call `useGetActivitySlipMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitySlipMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitySlipMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActivitySlipMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivitySlipMembersQuery,
    GetActivitySlipMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivitySlipMembersQuery, GetActivitySlipMembersQueryVariables>(
    GetActivitySlipMembersDocument,
    options,
  );
}
export function useGetActivitySlipMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivitySlipMembersQuery,
    GetActivitySlipMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActivitySlipMembersQuery, GetActivitySlipMembersQueryVariables>(
    GetActivitySlipMembersDocument,
    options,
  );
}
export type GetActivitySlipMembersQueryHookResult = ReturnType<
  typeof useGetActivitySlipMembersQuery
>;
export type GetActivitySlipMembersLazyQueryHookResult = ReturnType<
  typeof useGetActivitySlipMembersLazyQuery
>;
export type GetActivitySlipMembersQueryResult = Apollo.QueryResult<
  GetActivitySlipMembersQuery,
  GetActivitySlipMembersQueryVariables
>;
export const GetActivitySlipsDocument = gql`
  query GetActivitySlips($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      passive
      passiveStartAt
      activeAssist
      publicHoliday
      travelDistance
      travelTime
      billable
      payable
      clockedOnAt
      clockedOffAt
      hasNotes
      cancel {
        id
      }
      client {
        id
        ndisId
        ...PersonNames
        planManager {
          id
          name
        }
      }
      member {
        id
        externalId
        bonusEligible
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetActivitySlipsQuery__
 *
 * To run a query within a React component, call `useGetActivitySlipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitySlipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitySlipsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActivitySlipsQuery(
  baseOptions: Apollo.QueryHookOptions<GetActivitySlipsQuery, GetActivitySlipsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivitySlipsQuery, GetActivitySlipsQueryVariables>(
    GetActivitySlipsDocument,
    options,
  );
}
export function useGetActivitySlipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActivitySlipsQuery, GetActivitySlipsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActivitySlipsQuery, GetActivitySlipsQueryVariables>(
    GetActivitySlipsDocument,
    options,
  );
}
export type GetActivitySlipsQueryHookResult = ReturnType<typeof useGetActivitySlipsQuery>;
export type GetActivitySlipsLazyQueryHookResult = ReturnType<typeof useGetActivitySlipsLazyQuery>;
export type GetActivitySlipsQueryResult = Apollo.QueryResult<
  GetActivitySlipsQuery,
  GetActivitySlipsQueryVariables
>;
export const GetMemberWorkedHoursDocument = gql`
  query GetMemberWorkedHours($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      duration
      clockedOnAt
      clockedOffAt
      travelTime
      passive
      member {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberWorkedHoursQuery__
 *
 * To run a query within a React component, call `useGetMemberWorkedHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberWorkedHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberWorkedHoursQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMemberWorkedHoursQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberWorkedHoursQuery,
    GetMemberWorkedHoursQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberWorkedHoursQuery, GetMemberWorkedHoursQueryVariables>(
    GetMemberWorkedHoursDocument,
    options,
  );
}
export function useGetMemberWorkedHoursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberWorkedHoursQuery,
    GetMemberWorkedHoursQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMemberWorkedHoursQuery, GetMemberWorkedHoursQueryVariables>(
    GetMemberWorkedHoursDocument,
    options,
  );
}
export type GetMemberWorkedHoursQueryHookResult = ReturnType<typeof useGetMemberWorkedHoursQuery>;
export type GetMemberWorkedHoursLazyQueryHookResult = ReturnType<
  typeof useGetMemberWorkedHoursLazyQuery
>;
export type GetMemberWorkedHoursQueryResult = Apollo.QueryResult<
  GetMemberWorkedHoursQuery,
  GetMemberWorkedHoursQueryVariables
>;
export const GetShiftsToApproveDocument = gql`
  query GetShiftsToApprove($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      clockedOnAt
      clockedOnLatitude
      clockedOnLongitude
      clockedOffAt
      clockedOffLatitude
      clockedOffLongitude
      payable
      client {
        id
        ...PersonNames
      }
      member {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetShiftsToApproveQuery__
 *
 * To run a query within a React component, call `useGetShiftsToApproveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftsToApproveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftsToApproveQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShiftsToApproveQuery(
  baseOptions: Apollo.QueryHookOptions<GetShiftsToApproveQuery, GetShiftsToApproveQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetShiftsToApproveQuery, GetShiftsToApproveQueryVariables>(
    GetShiftsToApproveDocument,
    options,
  );
}
export function useGetShiftsToApproveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShiftsToApproveQuery,
    GetShiftsToApproveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetShiftsToApproveQuery, GetShiftsToApproveQueryVariables>(
    GetShiftsToApproveDocument,
    options,
  );
}
export type GetShiftsToApproveQueryHookResult = ReturnType<typeof useGetShiftsToApproveQuery>;
export type GetShiftsToApproveLazyQueryHookResult = ReturnType<
  typeof useGetShiftsToApproveLazyQuery
>;
export type GetShiftsToApproveQueryResult = Apollo.QueryResult<
  GetShiftsToApproveQuery,
  GetShiftsToApproveQueryVariables
>;
export const ScheduleControlMembersInputDocument = gql`
  query ScheduleControlMembersInput(
    $input: QueryMembersInput!
    $scheduleInput: QueryMemberScheduleInput!
    $eventsInput: QueryEventsInput!
    $teamsInput: QueryClientTeamsInput!
    $unavailablesInput: QueryMemberUnavailablesInput!
  ) {
    members(input: $input) {
      id
      hasAccess
      color
      phone
      gender
      dob
      unavailableNotes
      unavailableMoMorn
      unavailableMoDay
      unavailableMoNight
      unavailableTuMorn
      unavailableTuDay
      unavailableTuNight
      unavailableWeMorn
      unavailableWeDay
      unavailableWeNight
      unavailableThMorn
      unavailableThDay
      unavailableThNight
      unavailableFrMorn
      unavailableFrDay
      unavailableFrNight
      unavailableSaMorn
      unavailableSaDay
      unavailableSaNight
      unavailableSuMorn
      unavailableSuDay
      unavailableSuNight
      ...PersonNames
      schedule(input: $scheduleInput) {
        timeScheduled
      }
      events(input: $eventsInput) {
        id
        startAt
        duration
        client {
          id
          ...PersonNames
        }
      }
      teams(input: $teamsInput) {
        id
        preferred
        client {
          id
        }
      }
      unavailables(input: $unavailablesInput) {
        id
        startAt
        endAt
        notes
        owner {
          id
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useScheduleControlMembersInputQuery__
 *
 * To run a query within a React component, call `useScheduleControlMembersInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleControlMembersInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleControlMembersInputQuery({
 *   variables: {
 *      input: // value for 'input'
 *      scheduleInput: // value for 'scheduleInput'
 *      eventsInput: // value for 'eventsInput'
 *      teamsInput: // value for 'teamsInput'
 *      unavailablesInput: // value for 'unavailablesInput'
 *   },
 * });
 */
export function useScheduleControlMembersInputQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScheduleControlMembersInputQuery,
    ScheduleControlMembersInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScheduleControlMembersInputQuery,
    ScheduleControlMembersInputQueryVariables
  >(ScheduleControlMembersInputDocument, options);
}
export function useScheduleControlMembersInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleControlMembersInputQuery,
    ScheduleControlMembersInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScheduleControlMembersInputQuery,
    ScheduleControlMembersInputQueryVariables
  >(ScheduleControlMembersInputDocument, options);
}
export type ScheduleControlMembersInputQueryHookResult = ReturnType<
  typeof useScheduleControlMembersInputQuery
>;
export type ScheduleControlMembersInputLazyQueryHookResult = ReturnType<
  typeof useScheduleControlMembersInputLazyQuery
>;
export type ScheduleControlMembersInputQueryResult = Apollo.QueryResult<
  ScheduleControlMembersInputQuery,
  ScheduleControlMembersInputQueryVariables
>;
export const ScheduleMemberHoursInputDocument = gql`
  query ScheduleMemberHoursInput($membersInput: QueryScheuleMembersInput!) {
    schedule {
      members(input: $membersInput) {
        weekTime
        fortnightTime
        member {
          id
          color
          ...PersonNames
        }
        currentEvent {
          id
          startAt
          endAt
          clockedOnAt
        }
        nextEvent {
          id
          startAt
          endAt
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useScheduleMemberHoursInputQuery__
 *
 * To run a query within a React component, call `useScheduleMemberHoursInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleMemberHoursInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleMemberHoursInputQuery({
 *   variables: {
 *      membersInput: // value for 'membersInput'
 *   },
 * });
 */
export function useScheduleMemberHoursInputQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScheduleMemberHoursInputQuery,
    ScheduleMemberHoursInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScheduleMemberHoursInputQuery, ScheduleMemberHoursInputQueryVariables>(
    ScheduleMemberHoursInputDocument,
    options,
  );
}
export function useScheduleMemberHoursInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleMemberHoursInputQuery,
    ScheduleMemberHoursInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScheduleMemberHoursInputQuery, ScheduleMemberHoursInputQueryVariables>(
    ScheduleMemberHoursInputDocument,
    options,
  );
}
export type ScheduleMemberHoursInputQueryHookResult = ReturnType<
  typeof useScheduleMemberHoursInputQuery
>;
export type ScheduleMemberHoursInputLazyQueryHookResult = ReturnType<
  typeof useScheduleMemberHoursInputLazyQuery
>;
export type ScheduleMemberHoursInputQueryResult = Apollo.QueryResult<
  ScheduleMemberHoursInputQuery,
  ScheduleMemberHoursInputQueryVariables
>;
export const GetScheduleOrgSettingsDocument = gql`
  query GetScheduleOrgSettings {
    me {
      id
      member {
        id
        org {
          id
          assignMemberToEventWeeksInAdvance
        }
      }
    }
  }
`;

/**
 * __useGetScheduleOrgSettingsQuery__
 *
 * To run a query within a React component, call `useGetScheduleOrgSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleOrgSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleOrgSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScheduleOrgSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetScheduleOrgSettingsQuery,
    GetScheduleOrgSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScheduleOrgSettingsQuery, GetScheduleOrgSettingsQueryVariables>(
    GetScheduleOrgSettingsDocument,
    options,
  );
}
export function useGetScheduleOrgSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScheduleOrgSettingsQuery,
    GetScheduleOrgSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScheduleOrgSettingsQuery, GetScheduleOrgSettingsQueryVariables>(
    GetScheduleOrgSettingsDocument,
    options,
  );
}
export type GetScheduleOrgSettingsQueryHookResult = ReturnType<
  typeof useGetScheduleOrgSettingsQuery
>;
export type GetScheduleOrgSettingsLazyQueryHookResult = ReturnType<
  typeof useGetScheduleOrgSettingsLazyQuery
>;
export type GetScheduleOrgSettingsQueryResult = Apollo.QueryResult<
  GetScheduleOrgSettingsQuery,
  GetScheduleOrgSettingsQueryVariables
>;
export const GetApproveShiftsOrgSettingsDocument = gql`
  query GetApproveShiftsOrgSettings {
    me {
      id
      member {
        id
        org {
          id
          clockOnWarnMinutes
          clockOffWarnMinutes
        }
      }
    }
  }
`;

/**
 * __useGetApproveShiftsOrgSettingsQuery__
 *
 * To run a query within a React component, call `useGetApproveShiftsOrgSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApproveShiftsOrgSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApproveShiftsOrgSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApproveShiftsOrgSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApproveShiftsOrgSettingsQuery,
    GetApproveShiftsOrgSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApproveShiftsOrgSettingsQuery,
    GetApproveShiftsOrgSettingsQueryVariables
  >(GetApproveShiftsOrgSettingsDocument, options);
}
export function useGetApproveShiftsOrgSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApproveShiftsOrgSettingsQuery,
    GetApproveShiftsOrgSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApproveShiftsOrgSettingsQuery,
    GetApproveShiftsOrgSettingsQueryVariables
  >(GetApproveShiftsOrgSettingsDocument, options);
}
export type GetApproveShiftsOrgSettingsQueryHookResult = ReturnType<
  typeof useGetApproveShiftsOrgSettingsQuery
>;
export type GetApproveShiftsOrgSettingsLazyQueryHookResult = ReturnType<
  typeof useGetApproveShiftsOrgSettingsLazyQuery
>;
export type GetApproveShiftsOrgSettingsQueryResult = Apollo.QueryResult<
  GetApproveShiftsOrgSettingsQuery,
  GetApproveShiftsOrgSettingsQueryVariables
>;
export const GetClaimableShiftsDocument = gql`
  query GetClaimableShifts {
    eventsClaimable {
      id
      startAt
      endAt
      duration
      passive
      locality
      region
      client {
        id
        color
        ...PersonNames
      }
      member {
        id
        color
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClaimableShiftsQuery__
 *
 * To run a query within a React component, call `useGetClaimableShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimableShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimableShiftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClaimableShiftsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetClaimableShiftsQuery, GetClaimableShiftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClaimableShiftsQuery, GetClaimableShiftsQueryVariables>(
    GetClaimableShiftsDocument,
    options,
  );
}
export function useGetClaimableShiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClaimableShiftsQuery,
    GetClaimableShiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClaimableShiftsQuery, GetClaimableShiftsQueryVariables>(
    GetClaimableShiftsDocument,
    options,
  );
}
export type GetClaimableShiftsQueryHookResult = ReturnType<typeof useGetClaimableShiftsQuery>;
export type GetClaimableShiftsLazyQueryHookResult = ReturnType<
  typeof useGetClaimableShiftsLazyQuery
>;
export type GetClaimableShiftsQueryResult = Apollo.QueryResult<
  GetClaimableShiftsQuery,
  GetClaimableShiftsQueryVariables
>;
export const GetMyShiftsDocument = gql`
  query GetMyShifts($input: QueryEventsOwnAndRelatedInput!) {
    eventsOwnAndRelated(input: $input) {
      id
      startAt
      endAt
      duration
      passive
      locality
      region
      client {
        id
        color
        ...PersonNames
      }
      member {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMyShiftsQuery__
 *
 * To run a query within a React component, call `useGetMyShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyShiftsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyShiftsQuery(
  baseOptions: Apollo.QueryHookOptions<GetMyShiftsQuery, GetMyShiftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyShiftsQuery, GetMyShiftsQueryVariables>(GetMyShiftsDocument, options);
}
export function useGetMyShiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyShiftsQuery, GetMyShiftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyShiftsQuery, GetMyShiftsQueryVariables>(
    GetMyShiftsDocument,
    options,
  );
}
export type GetMyShiftsQueryHookResult = ReturnType<typeof useGetMyShiftsQuery>;
export type GetMyShiftsLazyQueryHookResult = ReturnType<typeof useGetMyShiftsLazyQuery>;
export type GetMyShiftsQueryResult = Apollo.QueryResult<
  GetMyShiftsQuery,
  GetMyShiftsQueryVariables
>;
export const GetShiftsMissingAnAttendeeDocument = gql`
  query GetShiftsMissingAnAttendee($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      duration
      locality
      region
      client {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetShiftsMissingAnAttendeeQuery__
 *
 * To run a query within a React component, call `useGetShiftsMissingAnAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftsMissingAnAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftsMissingAnAttendeeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShiftsMissingAnAttendeeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShiftsMissingAnAttendeeQuery,
    GetShiftsMissingAnAttendeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetShiftsMissingAnAttendeeQuery, GetShiftsMissingAnAttendeeQueryVariables>(
    GetShiftsMissingAnAttendeeDocument,
    options,
  );
}
export function useGetShiftsMissingAnAttendeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShiftsMissingAnAttendeeQuery,
    GetShiftsMissingAnAttendeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetShiftsMissingAnAttendeeQuery,
    GetShiftsMissingAnAttendeeQueryVariables
  >(GetShiftsMissingAnAttendeeDocument, options);
}
export type GetShiftsMissingAnAttendeeQueryHookResult = ReturnType<
  typeof useGetShiftsMissingAnAttendeeQuery
>;
export type GetShiftsMissingAnAttendeeLazyQueryHookResult = ReturnType<
  typeof useGetShiftsMissingAnAttendeeLazyQuery
>;
export type GetShiftsMissingAnAttendeeQueryResult = Apollo.QueryResult<
  GetShiftsMissingAnAttendeeQuery,
  GetShiftsMissingAnAttendeeQueryVariables
>;
export const GetSupportCoordinatorByIdDocument = gql`
  query GetSupportCoordinatorById($id: ID!) {
    supportCoordinatorById(id: $id) {
      id
      createdAt
      firstName
      middleName
      lastName
      preferredName
      email
      phone
      businessName
      businessEmail
      businessPhone
      abn
      salutation
      notes
      createdBy {
        id
        ...PersonNamesPartial
      }
      clients {
        id
      }
      archive {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
      delete {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetSupportCoordinatorByIdQuery__
 *
 * To run a query within a React component, call `useGetSupportCoordinatorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportCoordinatorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportCoordinatorByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSupportCoordinatorByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSupportCoordinatorByIdQuery,
    GetSupportCoordinatorByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSupportCoordinatorByIdQuery, GetSupportCoordinatorByIdQueryVariables>(
    GetSupportCoordinatorByIdDocument,
    options,
  );
}
export function useGetSupportCoordinatorByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupportCoordinatorByIdQuery,
    GetSupportCoordinatorByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSupportCoordinatorByIdQuery,
    GetSupportCoordinatorByIdQueryVariables
  >(GetSupportCoordinatorByIdDocument, options);
}
export type GetSupportCoordinatorByIdQueryHookResult = ReturnType<
  typeof useGetSupportCoordinatorByIdQuery
>;
export type GetSupportCoordinatorByIdLazyQueryHookResult = ReturnType<
  typeof useGetSupportCoordinatorByIdLazyQuery
>;
export type GetSupportCoordinatorByIdQueryResult = Apollo.QueryResult<
  GetSupportCoordinatorByIdQuery,
  GetSupportCoordinatorByIdQueryVariables
>;
export const GetSupportCoordinatorsDocument = gql`
  query GetSupportCoordinators($input: QuerySupportCoordinatorsInput!) {
    supportCoordinators(input: $input) {
      id
      abn
      salutation
      firstName
      middleName
      lastName
      preferredName
      email
      phone
      businessName
      businessEmail
      businessPhone
      archive {
        id
      }
      delete {
        id
      }
    }
  }
`;

/**
 * __useGetSupportCoordinatorsQuery__
 *
 * To run a query within a React component, call `useGetSupportCoordinatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportCoordinatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportCoordinatorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSupportCoordinatorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSupportCoordinatorsQuery,
    GetSupportCoordinatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSupportCoordinatorsQuery, GetSupportCoordinatorsQueryVariables>(
    GetSupportCoordinatorsDocument,
    options,
  );
}
export function useGetSupportCoordinatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupportCoordinatorsQuery,
    GetSupportCoordinatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSupportCoordinatorsQuery, GetSupportCoordinatorsQueryVariables>(
    GetSupportCoordinatorsDocument,
    options,
  );
}
export type GetSupportCoordinatorsQueryHookResult = ReturnType<
  typeof useGetSupportCoordinatorsQuery
>;
export type GetSupportCoordinatorsLazyQueryHookResult = ReturnType<
  typeof useGetSupportCoordinatorsLazyQuery
>;
export type GetSupportCoordinatorsQueryResult = Apollo.QueryResult<
  GetSupportCoordinatorsQuery,
  GetSupportCoordinatorsQueryVariables
>;
export const GetMeDocument = gql`
  query GetMe {
    me {
      id
      ...PersonNames
      member {
        id
        permissions
        color
        unavailableNotes
        unavailableMoMorn
        unavailableMoDay
        unavailableMoNight
        unavailableTuMorn
        unavailableTuDay
        unavailableTuNight
        unavailableWeMorn
        unavailableWeDay
        unavailableWeNight
        unavailableThMorn
        unavailableThDay
        unavailableThNight
        unavailableFrMorn
        unavailableFrDay
        unavailableFrNight
        unavailableSaMorn
        unavailableSaDay
        unavailableSaNight
        unavailableSuMorn
        unavailableSuDay
        unavailableSuNight
        ...PersonNames
        org {
          id
          membersCanSeeClockedTimes
          schedulingContactPerson {
            id
            phone
            ...PersonNames
          }
          schedulingContactPerson2 {
            id
            phone
            ...PersonNames
          }
        }
      }
      members {
        id
        admin
        org {
          id
          ...BusinessNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${BusinessNamesFragmentDoc}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetVisaTypesDocument = gql`
  query GetVisaTypes($input: QueryVisaTypesInput!) {
    visaTypes(input: $input) {
      id
      name
      description
      allowedFortnightlyWorkHours
      allowedWorkHoursWaived
    }
  }
`;

/**
 * __useGetVisaTypesQuery__
 *
 * To run a query within a React component, call `useGetVisaTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisaTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisaTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVisaTypesQuery(
  baseOptions: Apollo.QueryHookOptions<GetVisaTypesQuery, GetVisaTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVisaTypesQuery, GetVisaTypesQueryVariables>(
    GetVisaTypesDocument,
    options,
  );
}
export function useGetVisaTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVisaTypesQuery, GetVisaTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVisaTypesQuery, GetVisaTypesQueryVariables>(
    GetVisaTypesDocument,
    options,
  );
}
export type GetVisaTypesQueryHookResult = ReturnType<typeof useGetVisaTypesQuery>;
export type GetVisaTypesLazyQueryHookResult = ReturnType<typeof useGetVisaTypesLazyQuery>;
export type GetVisaTypesQueryResult = Apollo.QueryResult<
  GetVisaTypesQuery,
  GetVisaTypesQueryVariables
>;
