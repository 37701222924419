import { TextField, TextFieldProps } from '@timed/common';
import { numberRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validateMemberExternalId: RegisterOptions = {
  pattern: { value: numberRegex, message: 'Must contain only numbers' },
  maxLength: { value: 10, message: 'Too long' },
};

const MemberExternalIdInput = (props: TextFieldProps) => (
  <TextField label="MYOB Record ID" type="text" validation={validateMemberExternalId} {...props} />
);

export default MemberExternalIdInput;
