import { useRouter } from '@timed/common';
import { Crumb } from '@timed/app-bar';
import { useAuth } from '@timed/auth';
import { PageContext, PageMetadata, usePage } from '@timed/page';
import { useCallback } from 'react';

const PageProvider: React.FC = ({ children }) => {
  const { navigate, pathname } = useRouter();
  const { setCrumbs, setControls } = usePage();
  const { permissible } = useAuth();

  /**
   * Process a page's supplied metadata and create relevant menu items
   */
  const addPage = useCallback(
    (page: PageMetadata) => {
      // Don't do anything
      if (
        (page.admin || page.permissions) &&
        !permissible({ admin: page.admin, permissions: page.permissions })
      )
        return;

      if (page.redirect && page.path === pathname) {
        navigate(page.redirect, { replace: true, state: page.state });
      } else {
        page.controls && setControls(page.controls);

        const crumbs: Crumb[] = [];

        page.crumbs?.before && crumbs.push.apply(crumbs, page.crumbs.before);

        crumbs.push.apply(crumbs, [
          {
            label: page.label,
            path: page.path,
            redirect: page.redirect,
            isModule: page.isModule || page.level === 0,
          },
        ]);

        page.crumbs?.after && crumbs.push.apply(crumbs, page.crumbs.after);

        setCrumbs({
          reset: page.newPage,
          index: page.level,
          crumbs,
        });
      }
    },
    [setCrumbs, setControls, pathname, navigate, permissible],
  );

  /**
   * Returns page's redirect path if set, otherwise returns page's path.
   */
  const getDirectPagePath = ({ path, redirect }: Pick<PageMetadata, 'path' | 'redirect'>): string =>
    redirect || path;

  return (
    <PageContext.Provider value={{ addPage, getDirectPagePath }}>{children}</PageContext.Provider>
  );
};

export default PageProvider;
