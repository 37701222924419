import { Permission } from '@timed/gql';
import { createContext, isValidElement } from 'react';

export type DrawerControlText = {
  /**
   * Text to render
   */
  label: string;

  /**
   * Path to navigate to when clicked
   */
  path?: string;

  /**
   * Require the user to have admin-status to render control
   */
  admin?: boolean;

  /**
   * Required permission of user to render control
   */
  permissions?: Permission | Permission[];
};

export type DrawerControl = DrawerControlText | JSX.Element;

type DrawerContextType = {
  /**
   * Drawer visibility state
   */
  isVisible: boolean;

  /**
   * Toggle drawer visibility state
   */
  toggleDrawer: (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;

  /**
   * Array of controls to render in the control panel
   */
  controls: DrawerControl[] | undefined;

  /**
   * Set control panel items
   */
  setControls: (items: DrawerControl[]) => void;

  /**
   * Remove all rendered control panel items
   */
  clearControls: () => void;
};

/**
 * Type guard for control panel items
 */
export const isControlAnElement = (item: DrawerControl): item is JSX.Element =>
  isValidElement(item);

const DrawerContext = createContext<DrawerContextType>({
  isVisible: false,
  toggleDrawer: () => () => {},
  controls: [],
  setControls: () => {},
  clearControls: () => {},
});

export default DrawerContext;
