import { ApolloClient, ApolloLink, InMemoryCache, RequestHandler, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

// get the authentication token from local storage if it exists
const token = localStorage.getItem('token');

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // Accept: "application/json",
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log('[GraphQL Error]:');
      console.log('  [Message]:', message);
      console.log('  [Locations]:', locations);
      console.log('  [Path]:', path);
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = createUploadLink({
  uri:
    window.location.origin === process.env.REACT_APP_URL
      ? process.env.REACT_APP_API_URL!.toString()
      : process.env.REACT_APP_API_URL_DEV!.toString(),
  credentials: 'include',
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: 'ws://localhost:5000/graphql',
    // window.location.origin === process.env.REACT_APP_URL
    //   ? process.env.REACT_APP_API_WS_URL!.toString()
    //   : process.env.REACT_APP_API_WS_URL_DEV!.toString(),
    connectionParams: {
      authToken: token,
    },
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink as unknown as ApolloLink | RequestHandler,
    httpLink as unknown as ApolloLink | RequestHandler,
    // splitLink as unknown as ApolloLink | RequestHandler,
  ]),
  cache: new InMemoryCache({
    possibleTypes: {
      Address: ['Client', 'Member', 'Event', 'RedactedClient'],
      Business: ['Org'],
      Person: ['User', 'Client', 'Member', 'RedactedClient', 'RedactedMember'],
    },
  }),
  name: process.env.REACT_APP_NAME,
  version: process.env.REACT_APP_VERSION,
  queryDeduplication: true,
  defaultOptions: {
    query: { errorPolicy: 'all' },
    mutate: { errorPolicy: 'all' },
  },
  connectToDevTools: window.location.origin === process.env.REACT_APP_URL,
});

export default apolloClient;
