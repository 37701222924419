import { _reports } from '@timed/app';
import { Page } from '@timed/page';
import { ReportWizard } from '@timed/report';

const Reports = () => (
  <Page {..._reports} controls={[{ label: 'Reports' }]}>
    <ReportWizard />
  </Page>
);

export default Reports;
