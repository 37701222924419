import {
  Box,
  createStyles,
  Hidden,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import AlarmOnRoundedIcon from '@material-ui/icons/AlarmOnRounded';
import CakeIcon from '@material-ui/icons/Cake';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FaceRoundedIcon from '@material-ui/icons/FaceRounded';
import FlagIcon from '@material-ui/icons/Flag';
import HotelIcon from '@material-ui/icons/Hotel';
import { useAuth } from '@timed/auth';
import { contrastingColor, hexToRGB } from '@timed/common';
import { EventSummaryModal } from '@timed/event';
import { Permission, useGetEventTooltipLazyQuery } from '@timed/gql';
import { ScheduleContext, Shift } from '@timed/schedule';
import {
  differenceInMinutes,
  format,
  formatDuration,
  getHours,
  getMinutes,
  isBefore,
  isSameDay,
  startOfWeek,
} from 'date-fns';
import { isNumber } from 'lodash';
import { useModal } from 'mui-modal-provider';
import { useContext, useMemo } from 'react';

type ScheduleCalendarEventProps = Shift;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      zIndex: 1000,
      overflow: 'hidden',
      pointerEvents: 'all',
      textOverflow: 'ellipsis',
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      lineHeight: '13px',
      backgroundColor: '#fafafa',
      position: 'absolute',
      width: '100%',
    },
    passiveOverlay: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 8 8'%3E%3Cg fill='%23000000' fill-opacity='0.15'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E")`,
    },
    text: {
      position: 'relative',
      padding: theme.spacing(0.25),
      '& div:first-child': {
        fontWeight: 'bold',
      },
      '& div:nth-child(-n+3)': {
        fontSize: 12,
      },
      '& div:nth-child(n+3)': {
        fontSize: 10,
      },
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      border: '1px solid white',
      display: 'inline-block',
      backgroundColor: red[500],
      marginRight: theme.spacing(0.25),
      '&::after': {
        position: 'absolute',
        top: 5,
        left: 1,
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: red[500],
        animation: '$circle 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes circle': {
      '0%': {
        transform: 'scale(1)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(4)',
        opacity: 0,
      },
    },
  }),
);

const ScheduleCalendarEvent = (props: ScheduleCalendarEventProps) => {
  const classes = useStyles();
  const auth = useAuth();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { setEvent } = useContext(ScheduleContext);

  const [getTooltip, tooltipResponse] = useGetEventTooltipLazyQuery();

  const { showModal } = useModal();

  const [passiveDuration, durationBeforePassive] = useMemo(() => {
    if (!props.event.passiveStartAt || !props.event.passiveEndAt) return [];

    const passiveDuration = differenceInMinutes(
      props.event.passiveEndAt,
      props.event.passiveStartAt,
    );

    const durationBeforePassive = differenceInMinutes(props.event.passiveStartAt, props.startAt);

    return props.event.passiveStartAt && props.event.passiveEndAt
      ? [passiveDuration, durationBeforePassive]
      : [];
  }, [props.event.passiveEndAt, props.event.passiveStartAt, props.startAt]);

  const handleOpenModal = () => {
    const modal: { hide: () => void } = showModal(EventSummaryModal, {
      onClose: () => {
        modal.hide();
      },
      auth,
      eventId: props.event.id,
      color: props.event.color,
      selectedPayrollPeriod: startOfWeek(props.startAt, { weekStartsOn: 1 }),
    });
  };

  const calcTime = () => {
    const startMinutes = getMinutes(props.event.startAt);

    const sameAMPM =
      (getHours(props.event.startAt) < 12 && getHours(props.event.endAt) < 12) ||
      (getHours(props.event.startAt) > 12 && getHours(props.event.endAt) > 12);

    let prefix = '',
      suffix = '';

    if (startMinutes === 0) {
      if (sameAMPM && isSameDay(props.event.startAt, props.event.endAt)) {
        prefix = format(props.event.startAt, 'h').toLowerCase();
        suffix = format(props.event.endAt, smDown ? 'h' : 'ha').toLowerCase();
      } else {
        prefix = format(props.event.startAt, smDown ? 'h' : 'ha').toLowerCase();
        suffix = format(props.event.endAt, smDown ? 'h' : 'ha').toLowerCase();
      }
    } else {
      // Minutes === 01-59
      if (sameAMPM && isSameDay(props.event.startAt, props.event.endAt)) {
        prefix = format(props.event.startAt, 'h:mm').toLowerCase();
        suffix = format(props.event.endAt, smDown ? 'h:mm' : 'h:mma').toLowerCase();
      } else {
        prefix = format(props.event.startAt, smDown ? 'h:mm' : 'h:mma').toLowerCase();
        suffix = format(props.event.endAt, smDown ? 'h:mm' : 'h:mma').toLowerCase();
      }
    }

    return prefix + '-' + suffix;
  };

  const calcDuration = () => {
    return formatDuration(
      {
        hours: Math.floor(differenceInMinutes(props.event.endAt, props.event.startAt) / 60),
        minutes: differenceInMinutes(props.event.endAt, props.event.startAt) % 60,
      },
      { format: ['hours', 'minutes'], delimiter: ', ' },
    )
      .replace('hours', 'hrs')
      .replace('minutes', 'mins');
  };

  const calcPassiveStyle = (): React.CSSProperties => ({
    height: `calc(${(passiveDuration! / props.duration) * 100}% )`,
    top: isBefore(props.startAt, props.event.passiveStartAt)
      ? `calc(${(durationBeforePassive! / props.duration) * 100}%  )`
      : undefined,
    borderTop: durationBeforePassive ? '1px solid rgba(0, 0, 0, 0.3)' : undefined,
    borderBottom:
      props.event.passiveEndAt && isBefore(props.event.passiveEndAt, props.endAt)
        ? '1px solid rgba(0, 0, 0, 0.3)'
        : undefined,
    backgroundColor:
      contrastingColor(hexToRGB(props.event.style.backgroundColor || '#fff'))! + '1f',
  });

  const handleClick = () => handleOpenModal();

  const handleMouse = ({ type }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    switch (type) {
      case 'mouseover':
        // Set hovered event id
        setEvent({ type: 'over', value: { id: props.event.id } });
        break;
      case 'mouseout':
        // Unset hovered event id
        setEvent({ type: 'over' });
        break;
    }

    if (auth.permissible({ permissions: Permission.EVENT_READ }))
      getTooltip({ variables: { id: props.event.id } });
  };

  return (
    <div
      title={
        !!tooltipResponse.data?.eventById.scheduleNotes
          ? tooltipResponse.data.eventById.scheduleNotes
          : undefined
      }
      draggable
      className={classes.wrapper}
      style={{
        ...props.event.style,
        top: props.topOffset + '%',
        left: props.leftOffset + '%',
        width: props.width + '%',
        height: props.height + '%',
        cursor: 'pointer',
      }}
      onClick={handleClick}
      onMouseOver={handleMouse}
      onMouseOut={handleMouse}
    >
      {props.event.passive && !props.event.activeAssist && isNumber(durationBeforePassive) && (
        <div className={classes.passiveOverlay} style={calcPassiveStyle()}></div>
      )}
      <Box className={classes.text}>
        <div>
          <span>
            {props.event.conflictExists && <Box className={classes.circle}></Box>}
            {props.event.title}
          </span>
        </div>
        <div>
          <span>{calcTime()}</span>
        </div>
        <Hidden smDown>
          <div>
            <span>{calcDuration()}</span>
          </div>
        </Hidden>
        {props.event.cancelled && (
          <div>
            <span>Cancelled</span>
          </div>
        )}

        {(props.event.clockedOn ||
          props.event.passive ||
          props.event.hasNotes ||
          props.event.hasFiles ||
          props.event.publicHoliday ||
          props.event.memberAssignedAutomatically) && (
          <div>
            {props.event.clockedOn && <AlarmOnRoundedIcon style={{ fontSize: 12 }} />}
            {props.event.passive && !props.event.activeAssist && (
              <HotelIcon style={{ fontSize: 12 }} />
            )}
            {props.event.hasNotes && <FlagIcon style={{ fontSize: 12 }} />}
            {props.event.hasFiles && <DescriptionOutlinedIcon style={{ fontSize: 12 }} />}
            {props.event.publicHoliday && <CakeIcon style={{ fontSize: 12 }} />}
            {props.event.memberAssignedAutomatically && (
              <FaceRoundedIcon style={{ fontSize: 12 }} />
            )}
          </div>
        )}
      </Box>
    </div>
  );
};

export default ScheduleCalendarEvent;
