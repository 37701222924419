import { Select, SelectProps, validateUuid } from '@timed/common';
import { OrderBy, useGetPlanManagersQuery } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';

const ClientPlanManagerInput = ({ defaultValue, ...props }: Omit<SelectProps, 'items'>) => {
  const { data, loading } = useGetPlanManagersQuery({
    variables: { input: { orderBy: [{ name: OrderBy.ASC }] } },
  });

  useLoadingEffect(loading);

  return data?.planManagers && data?.planManagers.length === 0 ? (
    <>No plan managers currently exist</>
  ) : (
    <Select
      label="Plan Manager"
      validation={validateUuid}
      items={
        data?.planManagers &&
        Object.values(data.planManagers).map((p) => {
          return { value: p.id, label: p.name };
        })
      }
      {...props}
    />
  );
};

export default ClientPlanManagerInput;
