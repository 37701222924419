import { LoadingContext } from '@timed/loading';
import { useState } from 'react';

const LoadingProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>
  );
};
export default LoadingProvider;
