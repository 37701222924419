import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useAuth } from '@timed/auth';
import { ClientDeleteObservationFormModal } from '@timed/client';
import {
  addServerErrors,
  DateInput,
  FormModal,
  IconButton,
  ModalProps,
  numberRegex,
  ProfileInput,
  TextField,
  TimeInput,
  transformNumberToFixedFloat,
  transformStringToNumber,
} from '@timed/common';
import {
  Client,
  ClientObservation,
  Member,
  OrderBy,
  Permission,
  useUpdateClientObservationMutation,
} from '@timed/gql';
import { isValid, subMonths } from 'date-fns';
import _ from 'lodash';
import { useModal } from 'mui-modal-provider';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

type ClientUpdateObservationFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  observation: Pick<
    ClientObservation,
    | 'id'
    | 'date'
    | 'notes'
    | 'nursesName'
    | 'bloodSystolic'
    | 'bloodDiastolic'
    | 'heart'
    | 'temp'
    | 'spo2'
    | 'respiration'
  > & { client: Pick<Client, 'id'> } & { member?: Pick<Member, 'id'> | null };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    items: {
      display: 'grid',
      gridTemplateColumns: 'max-content max-content',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(4),
      alignItems: 'center',
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 60,
      },
    },
    dateInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 120,
      },
    },
    notesInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 248,
      },
    },
  }),
);

type FormData = {
  patch: {
    date?: Date;
    time?: Date;
    notes?: string | null;
    nursesName?: string | null;
    bloodSystolic?: number | null;
    bloodDiastolic?: number | null;
    heart?: number | null;
    temp?: number | null;
    spo2?: number | null;
    respiration?: number | null;
    member?: { id: string } | null;
  };
};

const ClientUpdateObservationFormModal = ({
  onClose,
  observation,
  ...modalProps
}: ClientUpdateObservationFormModalProps) => {
  const classes = useStyles();

  const { permissible } = useAuth();

  const { showModal } = useModal();

  const [updateClientObservation, response] = useUpdateClientObservationMutation();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      patch: {
        ..._.pick(observation, [
          'date',
          'notes',
          'nursesName',
          'bloodSystolic',
          'bloodDiastolic',
          'heart',
          'temp',
          'spo2',
          'respiration',
          'member',
        ]),
        time: new Date(observation.date),
      },
    },
  });

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = async ({ patch: { member, ...patch } }: FormData) => {
    patch = _.omit(patch, ['time']);

    updateClientObservation({
      variables: {
        input: {
          id: observation.id,
          patch: { ...patch, member: member?.id ? { id: member.id } : null },
        },
      },
    });
  };

  const handleChangeTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('patch.date')!);
        currentDate.setHours(date.getHours(), date.getMinutes());

        setValue('patch.date', currentDate);
      }
    },
    [getValues, setValue],
  );

  const handleOpenDeleteClientObservationModal = () => {
    const modal: { hide: () => void } = showModal(ClientDeleteObservationFormModal, {
      onClose: () => {
        modal.hide();
      },
      closePreviousModal: onClose,
      observation,
    });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Edit Observations"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      extraControls={
        permissible({ permissions: Permission.CLIENT_WRITE }) ? (
          <IconButton
            onClick={() => {
              handleOpenDeleteClientObservationModal();
            }}
          >
            <DeleteRoundedIcon />
          </IconButton>
        ) : undefined
      }
    >
      <Box className={classes.wrapper}>
        <Box className={classes.items}>
          <Typography variant="body1">Time</Typography>
          <Box className={classes.dateInput}>
            <DateInput
              required
              name="patch.date"
              control={control}
              inputVariant="outlined"
              size="small"
              inputProps={{ style: { textAlign: 'center' } }}
              error={!!errors.patch?.date}
              helperText={errors.patch?.date?.message}
            />
            <TimeInput
              required
              keyboard
              name="patch.time"
              control={control}
              inputVariant="outlined"
              size="small"
              onChange={(date) => {
                if (isValid(date)) handleChangeTime(date);
              }}
              inputProps={{ style: { textAlign: 'center' } }}
              error={!!errors.patch?.time}
              helperText={errors.patch?.time?.message}
            />
          </Box>

          <Typography variant="body1">BP</Typography>
          <Box className={classes.input}>
            <TextField
              name="patch.bloodSystolic"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.bloodSystolic}
              helperText={errors.patch?.bloodSystolic?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            {'/'}
            <TextField
              name="patch.bloodDiastolic"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.bloodDiastolic}
              helperText={errors.patch?.bloodDiastolic?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            mmHg
          </Box>

          <Typography variant="body1">Heart Rate</Typography>
          <Box className={classes.input}>
            <TextField
              name="patch.heart"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.heart}
              helperText={errors.patch?.heart?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            bpm
          </Box>

          <Typography variant="body1">Temperature</Typography>
          <Box className={classes.input}>
            <TextField
              name="patch.temp"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.temp}
              helperText={errors.patch?.temp?.message}
              transform={transformNumberToFixedFloat(1)}
              validation={{ pattern: /^-?\d+\.?\d*$/ }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            ºC
          </Box>

          <Typography variant="body1">SpO2</Typography>
          <Box className={classes.input}>
            <TextField
              name="patch.spo2"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.spo2}
              helperText={errors.patch?.spo2?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            %
          </Box>

          <Typography variant="body1">Respiration</Typography>
          <Box className={classes.input}>
            <TextField
              name="patch.respiration"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.respiration}
              helperText={errors.patch?.respiration?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            brpm
          </Box>

          {permissible({ permissions: Permission.MEMBER_READ }) && (
            <>
              <Typography variant="body1">Support worker</Typography>
              <Box className={classes.notesInput}>
                <ProfileInput
                  control={control}
                  name="patch.member.id"
                  chipProps={{ onDelete: () => setValue('patch.member.id', '') }}
                  formControlProps={{ variant: 'outlined', size: 'small' }}
                  watch={watch}
                  error={!!errors.patch?.member}
                  type="member"
                  orderBy={[{ lastName: OrderBy.ASC }]}
                  where={{
                    events: {
                      client: { id: { _eq: observation.client.id } },
                      startAt: { _gte: subMonths(new Date(), 3) },
                    },
                  }}
                />
              </Box>
            </>
          )}

          <Typography variant="body1">Nurse Name</Typography>
          <Box className={classes.notesInput}>
            <TextField
              name="patch.nursesName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.nursesName}
              helperText={errors.patch?.nursesName?.message}
              validation={{ maxLength: 255 }}
            ></TextField>
          </Box>

          <Typography variant="body1">Notes</Typography>
          <Box className={classes.notesInput}>
            <TextField
              multiline
              minRows={2}
              name="patch.notes"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.notes}
              helperText={errors.patch?.notes?.message}
              validation={{ maxLength: 255 }}
            ></TextField>
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientUpdateObservationFormModal;
