import { ClientFileType } from '@timed/gql';
import { ClientFileCategory } from '@timed/client/constants/ClientFileCategory';
import { FileMetadata } from '@timed/file';

export const clientFilesMetadata: FileMetadata<ClientFileType, ClientFileCategory>[] = [
  {
    id: ClientFileType.MISC,
    name: '00. Miscellaneous',
    hasDate: true,
    hasNote: true,
    hasExpiryDate: true,
  },
  {
    id: ClientFileType.NDIS_PLAN,
    category: ClientFileCategory.NDIS,
    name: '01. NDIS Plan',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.INITIAL_CONSULT,
    category: ClientFileCategory.NDIS,
    name: '02. Initial Consult',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.SERVICE_AGREEMENT,
    category: ClientFileCategory.NDIS,
    name: '03. Service Agreement',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.PRIVACY_CONSENT,
    category: ClientFileCategory.NDIS,
    name: '04. Privacy Consent',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.CONSENT_TO_SHARE,
    category: ClientFileCategory.NDIS,
    name: '05. Consent To Share',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.CONSENT_TO_SHARE_NDIS,
    category: ClientFileCategory.NDIS,
    name: '06. Consent To Share Emailed to NDIS',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.CONSENT_TO_ACT,
    category: ClientFileCategory.NDIS,
    name: '07. Consent To Act',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.CONSENT_TO_ACT_NDIS,
    category: ClientFileCategory.NDIS,
    name: '08. Consent To Act Emailed to NDIS',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.CAREPLAN,
    category: ClientFileCategory.NDIS,
    name: '09. Care Plan',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.PLAN_MANAGER,
    category: ClientFileCategory.NDIS,
    name: '10. Plan Manager',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.NDIS_SERVICE_BOOKING,
    category: ClientFileCategory.NDIS,
    name: '11. NDIS Service Booking',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.RISK_ASSESSMENT,
    category: ClientFileCategory.NDIS,
    name: '13. Risk Assessment',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.CONFLICT_OF_INTEREST,
    category: ClientFileCategory.NDIS,
    name: '14. Conflict Of Interest',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.AC_HANDBOOK,
    category: ClientFileCategory.NDIS,
    name: '15. AC Handbook',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.PRIVACY_STATEMENT,
    category: ClientFileCategory.NDIS,
    name: '16. Privacy Statement',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.PARTICIPANT_CHARTER,
    category: ClientFileCategory.NDIS,
    name: '17. Participant Charter',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.LETTER_TO_PARTICIPANT_NDIS,
    category: ClientFileCategory.NDIS,
    name: '18. Letter to participant (NDIS)',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.OCCUPATIONAL_THERAPIST,
    category: ClientFileCategory.MEDICAL,
    name: '30. Occupational Therapist',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.PHYSIOTHERAPIST,
    category: ClientFileCategory.MEDICAL,
    name: '31. Physiotherapist',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.GP,
    category: ClientFileCategory.MEDICAL,
    name: '32. GP',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.REFERRALS,
    category: ClientFileCategory.MEDICAL,
    name: '33. Referrals',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.TEST_RESULTS,
    category: ClientFileCategory.MEDICAL,
    name: '34. Test Results',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.PSYCHOLOGIST,
    category: ClientFileCategory.MEDICAL,
    name: '35. Psychologist',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.OTHER_SPECIALISTS,
    category: ClientFileCategory.MEDICAL,
    name: '36. Other Specialists',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.CURRENT_MEDICATION,
    category: ClientFileCategory.MEDICAL,
    name: '37. Current Medication',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.PROGRESS_REPORT,
    category: ClientFileCategory.NDIS,
    name: '38. Progress Report',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientFileType.FUNCTIONAL_ASSESMENT,
    category: ClientFileCategory.NDIS,
    name: '39. Functional Assessment',
    hasDate: true,
    hasNote: true,
  },
];
