import { Grid, Typography } from '@material-ui/core';
import { Protected } from '@timed/auth';
import {
  ClientAllowAutomaticEventPopulationCheckbox,
  ClientAllowMedicationCheckbox,
  ClientAllowObservationsCheckbox,
  ClientContext,
  ClientDefaultEventDurationInput,
} from '@timed/client';
import { addServerErrors, Block, IconButtonMulti } from '@timed/common';
import { Client, Permission, useUpdateClientsMutation } from '@timed/gql';
import { isEqual } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  patch: {
    defaultEventDuration: Client['defaultEventDuration'];
    allowObservations?: boolean;
    allowMedication?: boolean;
    allowAutomaticEventPopulation?: boolean;
  };
};

const ClientUpdateSchedulingForm = () => {
  const client = useContext(ClientContext);

  const [editing, setEditing] = useState<boolean>(false);

  const [updateClients, response] = useUpdateClientsMutation();

  const defaultValues: FormData = {
    patch: {
      defaultEventDuration: client.defaultEventDuration,
      allowObservations: client.allowObservations,
      allowMedication: client.allowMedication,
      allowAutomaticEventPopulation: client.allowAutomaticEventPopulation,
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) => {
    updateClients({ variables: { input: { ids: [client.id], ...values } } }).catch((e) => {});
    reset(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Schedule Settings"
        topRight={
          <Protected permissions={Permission.CLIENT_WRITE}>
            <IconButtonMulti
              enabled={editing}
              changed={!isEqual(currentValues, defaultValues)}
              setEditing={setEditing}
              loading={response.loading}
              success={!!response.data}
            />
          </Protected>
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Default event duration</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                client.defaultEventDuration && client.defaultEventDuration + ' hours'
              ) : (
                <ClientDefaultEventDurationInput
                  fullWidth
                  name="patch.defaultEventDuration"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.defaultEventDuration}
                  helperText={errors.patch?.defaultEventDuration?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Observations</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                client.allowObservations ? (
                  'Enabled'
                ) : (
                  'Disabled'
                )
              ) : (
                <ClientAllowObservationsCheckbox
                  name="patch.allowObservations"
                  label={undefined}
                  defaultChecked={client.allowObservations}
                  control={control}
                  disabled={!editing}
                  error={!!errors.patch?.allowObservations}
                  helperText={errors.patch?.allowObservations?.message}
                  formControlProps={{ size: 'small', variant: 'outlined' }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Medication</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                client.allowMedication ? (
                  'Enabled'
                ) : (
                  'Disabled'
                )
              ) : (
                <ClientAllowMedicationCheckbox
                  name="patch.allowMedication"
                  label={undefined}
                  defaultChecked={client.allowMedication}
                  control={control}
                  disabled={!editing}
                  error={!!errors.patch?.allowMedication}
                  helperText={errors.patch?.allowMedication?.message}
                  formControlProps={{ size: 'small', variant: 'outlined' }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>AI dates</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                client.allowAutomaticEventPopulation ? (
                  'Enabled'
                ) : (
                  'Disabled'
                )
              ) : (
                <ClientAllowAutomaticEventPopulationCheckbox
                  name="patch.allowAutomaticEventPopulation"
                  label={undefined}
                  defaultChecked={client.allowAutomaticEventPopulation}
                  control={control}
                  disabled={!editing}
                  error={!!errors.patch?.allowAutomaticEventPopulation}
                  helperText={errors.patch?.allowAutomaticEventPopulation?.message}
                  formControlProps={{ size: 'small', variant: 'outlined' }}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default ClientUpdateSchedulingForm;
