import { Button } from '@timed/common';
import { useUninviteMembersMutation } from '@timed/gql';
import { useAlert } from '@timed/alert';
import { useLoadingEffect } from '@timed/loading';
import { MemberContext } from '@timed/member';
import { useContext, useEffect } from 'react';

const MemberInviteCancelButton = () => {
  const alert = useAlert();

  const member = useContext(MemberContext);

  const [uninviteMembers, response] = useUninviteMembersMutation();

  useLoadingEffect(response.loading);

  useEffect(() => {
    if (!!response.data && !response.error) {
      if (response.data.uninviteMembers[0].invitationMostRecentlyReceived) {
        alert.push({ message: 'Invitation cancelled', severity: 'info' });

        const cache = response.client.cache;

        cache.evict({
          id: cache.identify({
            __typename: 'MemberInvitation',
            id: response.data.uninviteMembers[0].invitationMostRecentlyReceived?.id,
          }),
        });

        cache.gc();
      }
    }
  }, [response.data, response.error, alert, response.client.cache]);

  const handleClick = () => uninviteMembers({ variables: { input: { ids: [member.id] } } });

  return <Button onClick={handleClick}>Cancel</Button>;
};

export default MemberInviteCancelButton;
