import { Member, Permission } from '@timed/gql';

/**
 * Input type for permissible function
 */
export type IsPermissible = {
  admin?: boolean;
  permissions?: Permission | Permission[];
};

const isPermissible = ({
  member,
  admin = false,
  permissions = [],
}: IsPermissible & { member?: Pick<Member, 'admin' | 'permissions'> }): boolean => {
  // User has no member profiles.
  if (!member) return false;

  // Member is an admin - return early.
  if (member.admin) return true;

  // If not one already, convert 'permission' into an array.
  !Array.isArray(permissions) && (permissions = [permissions]);

  // If no requirements are set, the only requirement is for the user to have at least one member profile.
  if (!admin && !permissions.length) return true;

  // Member must be an admin.
  if (admin) return member.admin ?? false;

  // Member lacks any permissions.
  if (!member.permissions?.length) return false;

  return permissions.every(
    (p) =>
      // Check that member has every required permission
      member!.permissions!.includes(p) ||
      // Since write-access supercedes read-access, check if the member has been granted
      // write-access when they lack read-access. This code gets the Permission.DOMAIN_WRITE
      // enum value from the provided Permission.DOMAIN_READ enum value.
      member!.permissions!.includes(
        Object.entries(Permission).filter(
          ([key]) =>
            key ===
            Object.entries(Permission)
              .filter(([, value]) => value === p)
              .map(([key]) => key)[0]
              .replace('READ', 'WRITE'),
        )[0][1] as Permission,
      ),
  );
};

export default isPermissible;
