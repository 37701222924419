type IntersperseFn = {
  /**
   * Array of elements to seperate
   */
  array: any[];

  /**
   * Seperator
   */
  sep?: any;

  /**
   * Replease final instance of seperator with the string 'and'
   */
  and?: boolean;

  /**
   * Replease final instance of seperator with the string 'and'
   */
  finalSeperatorString?: string;
};

/**
 *  Seperate every element of an array with a comma
 */
export const intersperse = ({
  array,
  sep = ', ',
  and = true,
  finalSeperatorString = 'and',
}: IntersperseFn) =>
  array.length === 0
    ? undefined
    : array
        .slice(1)
        .reduce(
          (xs, x, i) =>
            xs.concat([and && i === array.length - 2 ? ' ' + finalSeperatorString + ' ' : sep, x]),
          [array[0]],
        );
