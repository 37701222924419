import { Activity } from '@timed/report';
import {
  addDays,
  eachDayOfInterval,
  isAfter,
  isBefore,
  isEqual,
  isSaturday,
  isSunday,
  isWithinInterval,
  set,
  startOfDay,
} from 'date-fns';

type CalculatePayrollActivityFn = {
  type: 'support' | 'travelTime';
  billable: boolean;
  eventStart: Date;
  eventEnd: Date;
  shiftStart: Date;
  shiftEnd: Date;
  passive?: boolean;
  publicHoliday?: boolean;
  cancelled?: boolean;
};

export const calculatePayrollActivityId = ({
  billable,
  eventEnd,
  eventStart,
  shiftEnd,
  shiftStart,
  type,
  passive = false,
  publicHoliday = false,
  cancelled = false,
}: CalculatePayrollActivityFn): Activity => {
  // Passive shift (ignore type)
  if (passive) return cancelled ? Activity.CANCELLED_OVERNIGHT : Activity.OVERNIGHT;

  // Public holiday
  if (publicHoliday)
    switch (type) {
      case 'support':
        return billable ? Activity.BILLABLE_PUBLIC_HOLIDAY : Activity.NON_BILLABLE_PUBLIC_HOLIDAY;
      case 'travelTime':
        return Activity.TRAVEL_PUBLIC_HOLIDAY;
    }

  const datesInEvent = eachDayOfInterval({ start: eventStart, end: eventEnd }).filter(
    (d) => !isEqual(eventEnd, d),
  );

  // Sunday
  if (datesInEvent.some((d) => isSunday(d)))
    switch (type) {
      case 'support':
        return billable ? Activity.BILLABLE_SUNDAY : Activity.NON_BILLABLE_SUNDAY;
      case 'travelTime':
        return Activity.TRAVEL_SUNDAY;
    }

  // Saturday
  if (datesInEvent.some((d) => isSaturday(d)))
    switch (type) {
      case 'support':
        return billable ? Activity.BILLABLE_SATURDAY : Activity.NON_BILLABLE_SATURDAY;
      case 'travelTime':
        return Activity.TRAVEL_SATURDAY;
    }

  // Night. Event spans multiple days or shift starts between the hours of 00:00 and 06:00.
  if (
    isBefore(shiftStart, set(eventStart, { hours: 6 })) ||
    isAfter(eventEnd, startOfDay(addDays(eventStart, 1)))
  )
    switch (type) {
      case 'support':
        return billable ? Activity.BILLABLE_NIGHT : Activity.NON_BILLABLE_NIGHT;
      case 'travelTime':
        return Activity.TRAVEL_NIGHT;
    }

  // Weekday. Event occurs between the hours of 6am and 8pm and does not span multiple days.
  if (
    isWithinInterval(shiftStart, {
      start: set(eventStart, { hours: 6, minutes: 0 }),
      end: set(eventStart, { hours: 20, minutes: 0 }),
    }) &&
    isWithinInterval(shiftEnd, {
      start: set(eventStart, { hours: 6, minutes: 0 }),
      end: set(eventStart, { hours: 20, minutes: 0 }),
    })
  )
    switch (type) {
      case 'support':
        return billable ? Activity.BILLABLE_WEEKDAY : Activity.NON_BILLABLE_WEEKDAY;
      case 'travelTime':
        return Activity.TRAVEL_WEEKDAY;
    }

  // Afternoon. Event is between 8pm and midnight on the same day
  switch (type) {
    case 'support':
      return billable ? Activity.BILLABLE_AFTERNOON : Activity.NON_BILLABLE_AFTERNOON;
    case 'travelTime':
      return Activity.TRAVEL_AFTERNOON;
  }
};
