import { Box, createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ScheduleContext } from '@timed/schedule';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
    },
    button: {
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
  }),
);

const ScheduleControlsDateSkipButtons = () => {
  const classes = useStyles();
  const {
    dates: { range },
    setFrom,
  } = useContext(ScheduleContext);

  return (
    <Box className={classes.wrapper}>
      <IconButton
        className={classes.button}
        size="small"
        aria-label="back"
        onClick={() => setFrom(Math.max(-range, -7))}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        className={classes.button}
        size="small"
        aria-label="forward"
        onClick={() => setFrom(Math.min(range, 7))}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};

export default ScheduleControlsDateSkipButtons;
