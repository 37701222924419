import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@material-ui/core';
import { validateRequired } from '@timed/common';
import { ChangeEvent, ReactNode } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

export type SelectMenuItem = {
  value: string | number;
  label: string | ReactNode;
};

export type SelectProps = MuiSelectProps & {
  control?: Control<any>;
  validation?: Omit<RegisterOptions, 'required'>;
  items: SelectMenuItem[] | undefined;
  name: string;
  formControlProps?: FormControlProps;
  helperText?: string;
};

const Select = ({
  control,
  name,
  items,
  label,
  validation,
  defaultValue,
  formControlProps,
  helperText,
  onChange,
  ...props
}: SelectProps) => (
  <FormControl {...formControlProps}>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={validation && props.required ? { ...validateRequired, ...validation } : validation}
      render={({ field: { name, onChange: controllerOnChange, ...field } }) => (
        <>
          {label && <InputLabel id={name + '-label'}>{label}</InputLabel>}
          <MuiSelect
            {...field}
            id={name}
            key={name}
            labelId={(label && name + '-label') || undefined}
            label={label}
            onChange={(
              event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
              child: ReactNode,
            ) => {
              controllerOnChange(event.target.value);
              onChange && onChange(event, child);
            }}
            {...props}
          >
            {items &&
              Object.values(items).map((item: SelectMenuItem) => (
                <MuiMenuItem key={item.value} value={item.value}>
                  {item.label}
                </MuiMenuItem>
              ))}
          </MuiSelect>
        </>
      )}
    />
    <FormHelperText error>{helperText}</FormHelperText>
  </FormControl>
);

export default Select;
