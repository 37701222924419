import { Box, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Block, formatPersonName, intersperse, Link } from '@timed/common';
import { OrderBy, useGetClientsQuery } from '@timed/gql';
import { _peopleClients } from '@timed/app';
import { useLoadingEffect } from '@timed/loading';
import { SupportCoordinatorContext } from '@timed/support-coordinator';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'grid',
      gridAutoRows: 'max-content',

      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
        marginRight: theme.spacing(8),
      },
    },
  }),
);

const SupportCoordinatorViewClients = () => {
  const classes = useStyles();
  const supportCoordinator = useContext(SupportCoordinatorContext);

  const { data, loading } = useGetClientsQuery({
    variables: {
      input: {
        where: { supportCoordinator: { id: { _eq: supportCoordinator.id } } },
        orderBy: [{ firstName: OrderBy.ASC }],
        limit: 50,
      },
    },
  });

  useLoadingEffect(loading);

  return (
    <Box className={classes.wrapper}>
      <Block title="Linked Participants">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Typography>
              {data?.clients.filter((c) =>
                supportCoordinator.clients?.map((p) => p.id).includes(c.id),
              )?.length === 0
                ? 'This support coordinator is not linked with any participants. Use the edit button to assign one.'
                : intersperse({
                    array:
                      data?.clients
                        .filter((c) => supportCoordinator.clients?.map((p) => p.id).includes(c.id))
                        .map((c, i) => (
                          <Link to={_peopleClients.path + '/' + c.id}>
                            {formatPersonName(c, { capitaliseLastName: true, lastNameFirst: true })}
                          </Link>
                        )) || [],
                  })}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </Box>
  );
};

export default SupportCoordinatorViewClients;
