import {
  Badge,
  Box,
  createStyles,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { _peopleMembers, _peopleMembersProfile } from '@timed/app';
import { useAuth } from '@timed/auth';
import { Avatar, formatPersonName, useRouter } from '@timed/common';
import { Permission } from '@timed/gql';
import { MemberContext, MemberViewActionMenuButton, MemberViewCloseButton } from '@timed/member';
import { Page } from '@timed/page';
import { differenceInMinutes } from 'date-fns';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

type TabItem = {
  label: string;
  path: string;
  permission?: Permission | Permission[];
  admin?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      flex: '0 1 auto',
      display: 'grid',
      gap: theme.spacing(4),
    },
    card: {
      display: 'grid',
      // gridAutoFlow: "column",
      // gridAutoColumns: "max-content",
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
        gridTemplateColumns: 'max-content auto max-content',
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
        gridTemplateColumns: 'max-content auto max-content',
      },
    },
    avatar: {
      width: theme.spacing(16),
      height: theme.spacing(16),
      fontSize: '175%',
      lineHeight: theme.spacing(8) + 'px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
    },
    typography: {
      lineHeight: '32px',
    },
    controls: {
      display: 'grid',
      alignSelf: 'flex-start',
      alignItems: 'center',

      gridAutoFlow: 'column',
      gridTemplateColumns: 'max-content',
      gap: theme.spacing(4),
    },
    tabRow: {
      display: 'grid',
      alignItems: 'center',

      gridAutoFlow: 'column',
      gridTemplateColumns: 'auto max-content',
    },
    tabs: {
      color: theme.palette.text.primary,
      '& .MuiTabs-flexContainer': {
        display: 'inline-grid',
        gridAutoFlow: 'column',
        [theme.breakpoints.down('md')]: {
          gap: theme.spacing(4),
        },
        [theme.breakpoints.up('md')]: {
          gap: theme.spacing(8),
        },
      },
    },
    tab: {
      textTransform: 'none',
      minWidth: 'inherit',
      '&.MuiTab-root': {
        padding: 0,
      },
      '& span': {
        padding: theme.spacing(1, 0),
      },
    },
    indicator: {
      height: theme.spacing(1),
    },
    section: {
      flex: '1 1 auto',
      display: 'grid',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        overflowY: 'auto',
        marginTop: theme.spacing(8),
        gap: theme.spacing(8),
      },
    },
  }),
);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
)(Badge);

const PeopleEmployeesProfile = () => {
  const classes = useStyles();
  const { pathname, navigate } = useRouter();
  const { permissible } = useAuth();

  const member = useContext(MemberContext);

  const theme = useTheme();

  const currentTab = pathname.split('/').pop();

  const menuItems: TabItem[] = [
    {
      label: 'Overview',
      path: 'overview',
    },
    // {
    //   label: "Shifts",
    //   path: "shifts",
    // },
    {
      label: 'Unavailability',
      path: 'unavailability',
      permission: Permission.MEMBER_READ,
    },
    {
      label: 'Notes',
      path: 'notes',
      admin: true,
    },
    {
      label: 'Files',
      path: 'files',
      permission: Permission.MEMBER_FILE_READ,
    },
    {
      label: 'Permissions',
      path: 'permissions',
      admin: true,
    },
    // {
    //   label: "History",
    //   path: "history",
    //   role: Role.ADMINISTRATOR,
    // },
  ];

  const handleClick = (link: string) => (_: React.KeyboardEvent | React.MouseEvent) =>
    link !== currentTab && navigate(link);

  const navigation: JSX.Element = false ? (
    <></>
  ) : (
    <Tabs
      value={currentTab}
      aria-label="Profile nav bar"
      className={classes.tabs}
      TabIndicatorProps={{ className: classes.indicator }}
    >
      {menuItems.map(
        (tab, index) =>
          permissible({ admin: tab.admin, permissions: tab.permission }) && (
            <Tab
              disableRipple
              key={index}
              label={tab.label}
              className={classes.tab}
              value={tab.path}
              onClick={handleClick(tab.path)}
            />
          ),
      )}
    </Tabs>
  );

  const avatar = (
    <Avatar
      content={[member.firstName, member.lastName]}
      variant="circular"
      color={
        // If profile.color is not set or is white set avatar color as
        // the same as paper background color
        !member.color ||
        member.color?.toLowerCase() === '#fff' ||
        member.color?.toLowerCase() === '#ffffff'
          ? theme.palette.type === 'light'
            ? '#fff'
            : theme.palette.background.paper
          : member.color
      }
      textColor={
        // If profile.color is not set or is white set avatar text color
        // as the same as typography primary color
        ((!member.color ||
          member.color?.toLowerCase() === '#fff' ||
          member.color?.toLowerCase() === '#ffffff') &&
          theme.palette.text.primary) ||
        undefined
      }
      className={classes.avatar}
    />
  );

  return (
    <Page
      {..._peopleMembersProfile}
      label={formatPersonName(member, { capitaliseLastName: true, lastNameFirst: true })!}
      path={`/people/employees/${member.id}`}
      redirect={`/people/employees/${member.id}/overview`}
      crumbs={{ before: [_peopleMembers] }}
    >
      <Box className={classes.top}>
        <Box className={classes.card}>
          {member.lastActiveAt &&
          differenceInMinutes(new Date(), new Date(member.lastActiveAt)) <= 15 ? (
            <StyledBadge
              overlap="circle"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              {avatar}
            </StyledBadge>
          ) : (
            avatar
          )}
          <Box>
            <Typography variant="body1" color="textPrimary" className={classes.typography}>
              {formatPersonName(member, {
                middle: true,
                capitaliseLastName: true,
                lastNameFirst: true,
              })}
            </Typography>
            <Typography variant="body1" color="textSecondary" className={classes.typography}>
              {member.archive ? 'Archived Employee' : 'Employee'}
            </Typography>
          </Box>
          <Box className={classes.controls}>
            {permissible({ permissions: Permission.MEMBER_WRITE }) && (
              <MemberViewActionMenuButton />
            )}
            <MemberViewCloseButton />
          </Box>
        </Box>
        <Box className={classes.tabRow}>
          {navigation}
          {/* <IconButton size="small">
            <MoreHorizIcon fontSize="small" />
          </IconButton> */}
        </Box>
      </Box>
      <Divider />
      <Box className={classes.section}>
        <Outlet />
      </Box>
    </Page>
  );
};

export default PeopleEmployeesProfile;
