import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAlert } from '@timed/alert';
import {
  ClientAllowAutomaticEventPopulationCheckbox,
  ClientAllowMedicationCheckbox,
  ClientAllowObservationsCheckbox,
  ClientBranchInput,
  ClientDefaultEventDurationInput,
  ClientNdisIdInput,
  ClientPlanManagerInput,
} from '@timed/client';
import {
  AddressInput,
  addServerErrors,
  EmailInput,
  FormModal,
  ModalProps,
  PersonNameFirstInput,
  PersonNameLastInput,
  PersonNameMiddleInput,
  PhoneInput,
} from '@timed/common';
import {
  Address,
  Branch,
  CreateClientsDocument,
  CreateClientsInput,
  useCreateClientsMutation,
} from '@timed/gql';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type ClientCreateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(6, 1fr)',
      gap: theme.spacing(8),
    },
    fullWidth: {
      gridColumn: 'span 6',
    },
    halfWidth: {
      gridColumn: 'span 3',
    },
    thirdWidth: {
      gridColumn: 'span 2',
    },
  }),
);

type FormData = {
  ndisId: string;
  defaultEventDuration: number | null;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  allowMedication: boolean;
  allowObservations: boolean;
  allowAutomaticEventPopulation: boolean;
  branch: {
    id: Branch['id'];
  };
  planManager?: {
    id: Branch['id'];
  };
  address?: Pick<
    Address,
    'unit' | 'street' | 'locality' | 'region' | 'postcode' | 'country' | 'latitude' | 'longitude'
  > | null;
};

const ClientCreateFormModal = ({ onClose, ...modalProps }: ClientCreateFormModalProps) => {
  const classes = useStyles();

  const alert = useAlert();

  const [createClients, response] = useCreateClientsMutation();

  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      ndisId: '',
      defaultEventDuration: undefined,
      allowMedication: false,
      allowObservations: false,
      planManager: undefined,
      email: '',
      phone: '',
      firstName: '',
      middleName: '',
      lastName: '',
      address: null,
      branch: {
        id: '',
      },
    },
  });

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    alert.push({
      message: 'Successfully created participant',
      severity: 'success',
    });
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clients(existing = []) {
          return [
            ...existing,
            cache.writeQuery({ data: response.data, query: CreateClientsDocument }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async (values: FormData) => {
    // To avoid graphql mutation errors, delete location object if it is empty
    (!values.address || Object.keys(values.address).length === 0) && delete values.address;
    !values.planManager?.id && delete values.planManager;

    // Delete nullish values
    Object.keys(values).forEach(
      (k) =>
        (values[k as keyof FormData] === null || values[k as keyof FormData] === 'undefined') &&
        delete values[k as keyof FormData],
    );

    !values.defaultEventDuration && (values.defaultEventDuration = null);

    // Format input for creating a single member entity
    let input: CreateClientsInput = {
      objects: [
        {
          ...values.address,
          ..._.omit(values, ['address']),
          // Assign "planManager" property to input object, if planManagerId was submitted
          // planManager: values.planManagerId ? { id: values.branchId } : null,
        },
      ],
    };

    createClients({ variables: { input } });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Add participant"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.grid}>
          <PersonNameFirstInput
            required
            name="firstName"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            className={classes.thirdWidth}
          />
          <PersonNameMiddleInput
            name="middleName"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.middleName}
            helperText={errors.middleName?.message}
            className={classes.thirdWidth}
          />
          <PersonNameLastInput
            name="lastName"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            className={classes.thirdWidth}
          />
          <EmailInput
            name="email"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.email}
            helperText={errors.email?.message}
            className={classes.fullWidth}
          />
          <PhoneInput
            name="phone"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.phone}
            helperText={errors.phone?.message}
            className={classes.fullWidth}
          />
          <AddressInput
            name="address"
            control={control}
            clearErrors={clearErrors}
            setValue={setValue}
            setError={setError}
            error={!!errors.address}
            // helperText={errors.address?.message}
            placeholder="Residential address"
            className={classes.fullWidth}
          />
          <ClientNdisIdInput
            name="ndisId"
            control={control}
            className={classes.halfWidth}
            variant="outlined"
            size="small"
            error={!!errors.ndisId}
            helperText={errors.ndisId?.message}
          />
          <ClientDefaultEventDurationInput
            fullWidth
            name="defaultEventDuration"
            control={control}
            variant="outlined"
            size="small"
            style={{ gridColumn: 'span 3' }}
            error={!!errors.defaultEventDuration}
            helperText={errors.defaultEventDuration?.message}
          />
          <ClientPlanManagerInput
            displayEmpty
            name="planManager.id"
            control={control}
            error={!!errors.planManager?.id}
            helperText={errors.planManager?.id?.message}
            formControlProps={{
              size: 'small',
              variant: 'outlined',
              className: classes.fullWidth,
            }}
          />
          <ClientBranchInput
            required
            displayEmpty
            name="branch.id"
            control={control}
            error={!!errors.branch?.id}
            helperText={errors.branch?.id?.message}
            formControlProps={{
              size: 'small',
              variant: 'outlined',
              className: classes.fullWidth,
            }}
          />
          <ClientAllowMedicationCheckbox
            id="allowMedication"
            name="allowMedication"
            control={control}
            error={!!errors.allowMedication}
            helperText={errors.allowMedication?.message}
          />
          <ClientAllowObservationsCheckbox
            id="allowObservations"
            name="allowObservations"
            control={control}
            error={!!errors.allowObservations}
            helperText={errors.allowObservations?.message}
          />
          <ClientAllowAutomaticEventPopulationCheckbox
            id="allowAutomaticEventPopulation"
            name="allowAutomaticEventPopulation"
            control={control}
            error={!!errors.allowAutomaticEventPopulation}
            helperText={errors.allowAutomaticEventPopulation?.message}
          />
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientCreateFormModal;
