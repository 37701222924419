import { ButtonAsync, ButtonProps } from '@timed/common';
import {
  Client,
  CreateClientObservationDocument,
  Event,
  Member,
  useCreateClientObservationMutation,
  useCreateClientObservationWithEventMutation,
} from '@timed/gql';
import { isAfter, isBefore } from 'date-fns';
import { useMemo, useRef, useState } from 'react';

type ClientCreateFoodButtonProps = ButtonProps & {
  clientId: Client['id'];
  event: Pick<Event, 'id' | 'startAt' | 'endAt'> & { member?: Pick<Member, 'id'> | null };
};

const ClientCreateFoodButton = ({
  clientId,
  event,
  disabled,
  ...buttonProps
}: ClientCreateFoodButtonProps) => {
  const timer = useRef<number>();

  const [disableButton, setDisableButton] = useState<boolean>(false);

  const [createObservation, responseWithoutEvent] = useCreateClientObservationMutation();
  const [createObservationWithEvent, responseWithEvent] =
    useCreateClientObservationWithEventMutation();

  const now: Date = useMemo(() => new Date(), []);
  let date: Date = now;

  // Recording obs before event has started
  if (!!event && isBefore(now, new Date(event.startAt))) {
    date = new Date(event.startAt);
  }

  // Recording obs after event has finished
  if (!!event && isAfter(now, new Date(event.endAt))) {
    date = new Date(event.endAt);
  }

  const response = useMemo(
    () => (!!event ? responseWithEvent : responseWithoutEvent),
    [event, responseWithoutEvent, responseWithEvent],
  );

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientObservations(existing = []) {
          return [
            ...existing,
            cache.writeQuery({ data: response.data, query: CreateClientObservationDocument }),
          ];
        },
      },
    });

    setDisableButton(true);

    timer.current = window.setTimeout(() => {
      // Timout for 5 minutes
      setDisableButton(false);
    }, 300000);
  };

  return (
    <ButtonAsync
      loading={response.loading}
      success={!!response.data}
      onSuccess={onSuccess}
      disabled={disabled || disableButton}
      onClick={() => {
        if (event)
          createObservationWithEvent({
            variables: {
              input: {
                date,
                event: { id: event.id },
                givenFood: {
                  objects: [{ description: 'Ensure', kilojoules: 1000 }],
                },
              },
            },
          });
        else
          createObservation({
            variables: {
              input: {
                date,
                client: { id: clientId! },
                givenFood: {
                  objects: [{ description: 'Ensure', kilojoules: 1000 }],
                },
              },
            },
          });
      }}
      {...buttonProps}
    >
      Ensure
    </ButtonAsync>
  );
};

export default ClientCreateFoodButton;
