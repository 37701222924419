import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Protected } from '@timed/auth';
import { Block, Chip, formatTimeDuration, IconButtonAsync } from '@timed/common';
import {
  GetMemberUnavailablesQuery,
  Permission,
  useDeleteMemberUnavailablesMutation,
} from '@timed/gql';
import * as DateFns from 'date-fns';
import { isEqual } from 'lodash';

type MemberUnavailabilityPeriodProps = {
  period: GetMemberUnavailablesQuery['memberUnavailables'][0];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      flex: '1 1 max-content',
      display: 'grid',
      gap: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(2),
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    firstRow: {
      display: 'grid',
      gridAutoColumns: 'max-content',
      gridAutoFlow: 'column',
      gap: theme.spacing(2),
      alignItems: 'center',
    },
  }),
);

const MemberUnavailabilityPeriod = ({ period }: MemberUnavailabilityPeriodProps) => {
  const classes = useStyles();

  const [deleteUnavailability, { data, loading, client }] = useDeleteMemberUnavailablesMutation({
    variables: { input: { ids: [period.id] } },
  });

  const onComplete = () => {
    const cache = client.cache;

    cache.evict({
      id: cache.identify({
        __typename: 'MemberUnavailable',
        id: data!.deleteMemberUnavailables[0].id,
      }),
    });

    cache.gc();
  };

  return (
    <Block
      titleProps={{ variant: 'h6' }}
      title={
        <Box className={classes.firstRow}>
          <Chip
            color="primary"
            label={DateFns.formatDistanceStrict(
              new Date(period.startAt),
              new Date(period.endAt),
            ).toUpperCase()}
          />
          {DateFns.isBefore(new Date(period.endAt), new Date()) && (
            <Chip color="default" label="Expired" />
          )}
        </Box>
      }
      paperProps={{ className: classes.paper }}
      topRight={
        DateFns.isAfter(new Date(period.endAt), new Date()) ? (
          <Protected requireAny permissions={[Permission.MEMBER_WRITE, Permission.EVENT_WRITE]}>
            <IconButtonAsync
              loading={loading}
              success={!!data}
              onComplete={onComplete}
              onClick={() => deleteUnavailability()}
            >
              <DeleteIcon />
            </IconButtonAsync>
          </Protected>
        ) : undefined
      }
    >
      <Typography className={classes.bold}>
        {DateFns.format(new Date(period.startAt), 'eeee, d MMM') +
          (isEqual(new Date(period.startAt), DateFns.startOfDay(new Date(period.startAt))) &&
          DateFns.differenceInMinutes(new Date(period.endAt), new Date(period.startAt)) === 1440
            ? ' (all day)'
            : ' - ' +
              DateFns.format(
                DateFns.isEqual(new Date(period.endAt), DateFns.startOfDay(new Date(period.endAt)))
                  ? DateFns.subDays(new Date(period.endAt), 1)
                  : new Date(period.endAt),
                'eeee, d MMM',
              ))}
      </Typography>
      <Typography>
        {!(
          DateFns.isEqual(new Date(period.startAt), DateFns.startOfDay(new Date(period.startAt))) &&
          DateFns.differenceInMinutes(new Date(period.endAt), new Date(period.startAt)) % 1440 === 0
        ) &&
          formatTimeDuration({
            start: new Date(period.startAt),
            end: new Date(period.endAt),
            options: { short: false, stateSpan: true },
          })}
      </Typography>
      {period.notes && <Typography variant="body2">{period.notes}</Typography>}
    </Block>
  );
};

export default MemberUnavailabilityPeriod;
