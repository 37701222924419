import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { _schedule } from '@timed/app';
import { useAuth } from '@timed/auth';
import { formatPersonName, formatTimeDuration, intersperse, useRouter } from '@timed/common';
import {
  OrderBy,
  useGetOrgUnfilledEventsDayRangeQuery,
  useGetUnfilledEventsLazyQuery,
} from '@timed/gql';
import { setProfile } from '@timed/schedule/helpers';
import clsx from 'clsx';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isEqual,
  isToday,
  startOfToday,
  startOfTomorrow,
  startOfWeek,
} from 'date-fns';
import { isTomorrow } from 'date-fns/esm';
import { useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    wrapper: {
      padding: theme.spacing(1),
      backgroundColor: green[50], // theme.palette.background.default,
      border: '1px solid ' + theme.palette.success.main,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      lineHeight: 'initial',
      '& .MuiTypography-root': {
        fontSize: 11,
      },
    },
    warning: {
      cursor: 'pointer',
      backgroundColor: yellow[50],
      border: '1px solid ' + theme.palette.warning.main,
    },
    top: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    text: {
      '& .MuiTypography-root:first-child': {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    events: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    event: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      '& .MuiTypography-root:first-child': {
        fontSize: 10,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  }),
);

const ScheduleUnfilledEvents = () => {
  const classes = useStyles();

  const defaultRange = 2;

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const [showEvents, setShowEvents] = useState<boolean>(false);

  const { org } = useAuth();

  const orgResponse = useGetOrgUnfilledEventsDayRangeQuery({ variables: { id: org!.id } });

  const [getEvents, { data, loading, error }] = useGetUnfilledEventsLazyQuery({
    pollInterval: 60000,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!!orgResponse.data && !data && !loading)
      getEvents({
        variables: {
          input: {
            where: {
              member: { id: { _eq: null } },
              startAt: {
                _gte: startOfToday(),
                _lte: addDays(
                  startOfToday(),
                  orgResponse.data.orgById.unfilledEventsDayRange ?? defaultRange,
                ),
              },
              endAt: { _gte: new Date() },
            },
            orderBy: [{ startAt: OrderBy.ASC }, { duration: OrderBy.ASC }],
          },
        },
      });
  }, [orgResponse.data, data, loading, getEvents]);

  const [unfilledToday, unfilledTomorrow, unfilledOtherDays] = useMemo<
    [number, number, number]
  >(() => {
    if (!data || loading) return [0, 0, 0];

    const today = data.events.filter((event) =>
      isBefore(new Date(event.startAt), startOfTomorrow()),
    );

    const tomorrow = data.events.filter(
      (event) =>
        (isAfter(new Date(event.startAt), startOfTomorrow()) ||
          isEqual(new Date(event.startAt), startOfTomorrow())) &&
        isBefore(new Date(event.startAt), addDays(startOfTomorrow(), 1)),
    );

    const otherDays = data.events.filter(
      (event) =>
        isAfter(new Date(event.startAt), addDays(startOfTomorrow(), 1)) ||
        isEqual(new Date(event.startAt), addDays(startOfTomorrow(), 1)),
    );

    return [today.length, tomorrow.length, otherDays.length];
  }, [data, loading]);

  const message = useMemo<string | undefined>(() => {
    if (!!data?.events.length) {
      let messages: string[] = [];

      if (!!unfilledToday) messages.push(`${unfilledToday} today`);
      if (!!unfilledTomorrow) messages.push(`${unfilledTomorrow} tomorrow`);
      if (!!unfilledOtherDays) messages.push(`${unfilledOtherDays} more`);

      return intersperse({ array: messages });
    }
  }, [data?.events, unfilledToday, unfilledTomorrow, unfilledOtherDays]);

  const handleClickLink = (clientId: string, startAt: Date) => {
    setProfile('client', searchParams, clientId);
    setProfile('member', searchParams, undefined);

    const formattedDate = format(startOfWeek(startAt, { weekStartsOn: 1 }), 'ddMMyyyy');

    if (searchParams.get('f') !== formattedDate) {
      searchParams.set('f', formattedDate);
    }

    navigate(_schedule.path + '?' + searchParams);
  };

  if (orgResponse.loading || loading) return <>Loading...</>;

  if (orgResponse.error || error)
    return <>Error loading unfilled shifts. Please refresh the page.</>;

  return (
    <Box
      className={!!data?.events.length ? clsx(classes.wrapper, classes.warning) : classes.wrapper}
      onClick={() => {
        if (!!data?.events.length) setShowEvents(!showEvents);
      }}
    >
      <Box className={classes.top}>
        {data?.events.length ? (
          <WarningRoundedIcon style={{ fill: yellow[800] }} />
        ) : (
          <CheckRoundedIcon style={{ fill: green[800] }} />
        )}

        <Box className={classes.text}>
          <Typography variant="body1">
            {!!data?.events.length
              ? 'Warning: Unfilled Shifts'
              : 'No unfilled shifts starting soon'}
          </Typography>
          <Typography variant="body2">
            {!!data?.events.length
              ? message
              : 'Checked ' +
                (orgResponse.data?.orgById.unfilledEventsDayRange || 2) +
                ' day' +
                (orgResponse.data?.orgById.unfilledEventsDayRange === 1 ? '' : 's')}
          </Typography>
        </Box>
      </Box>

      {showEvents && (
        <Box className={classes.events}>
          {data?.events.map((event) => (
            <Box
              className={classes.event}
              onClick={(e) => {
                handleClickLink(event.client.id, new Date(event.startAt));
                e.stopPropagation();
              }}
            >
              <Typography>
                {isToday(new Date(event.startAt))
                  ? 'Today'
                  : isTomorrow(new Date(event.startAt))
                  ? 'Tomorrow'
                  : format(new Date(event.startAt), 'EEEE')}
                {', '}
                {format(new Date(event.startAt), 'd MMM')}
                {', '}
                {formatTimeDuration({
                  start: new Date(event.startAt),
                  end: new Date(event.endAt),
                  options: {
                    militaryTime: true,
                    seperator: '-',
                  },
                })}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatPersonName(event.client, { lastNameFirst: true })}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ScheduleUnfilledEvents;
