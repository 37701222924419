// import { Route } from '@timed/common';
import { ClientProvider } from '@timed/client';
import { MemberProvider } from '@timed/member';
import {
  Authenticated,
  ErrorNotFound,
  Invite,
  Join,
  Notifications,
  People,
  PeopleEmployees,
  PeopleEmployeesProfile,
  PeopleEmployeesProfileCategory,
  PeopleParticipants,
  PeopleParticipantsProfile,
  PeopleParticipantsProfileCategory,
  PeoplePlanManagers,
  PeopleSupportCoordinators,
  PeopleSupportCoordinatorsProfile,
  PeopleSupportCoordinatorsProfileCategory,
  Reports,
  Schedule,
  Settings,
  SettingsOrganisation,
  SettingsOrganisationMedication,
  SettingsOrganisationPayroll,
  SettingsOrganisationScheduling,
  SettingsOrganisationVisaTypes,
  SettingsPersonal,
  SettingsPersonalAccount,
  Setup,
  Shifts,
  ShiftsAdmin,
  ShiftsAdminApprove,
  ShiftsAdminMissingNotes,
  ShiftsAdminMissingSupportWorker,
  ShiftsPersonal,
  ShiftsPersonalClaim,
  ShiftsPersonalUnavailability,
  ShiftsPersonalUpcoming,
  SignIn,
  Support,
  SupportContact,
  SupportDebug,
  VerifyProcessToken,
  VerifyResendToken,
} from '@timed/routes';
import { SupportCoordinatorProvider } from '@timed/support-coordinator';
import { Outlet, Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <Routes>
      <Route path="sign-in" element={<SignIn />} />
      <Route path="join" element={<Join />} />
      <Route path="invite/:token" element={<Invite />} />
      <Route path="verify/resend/:type" element={<VerifyResendToken />} />
      <Route path="verify/:token" element={<VerifyProcessToken />} />

      {/**
       * Authenticated route (child routes require the user to the logged in)
       */}
      <Route path="/" element={<Authenticated />}>
        <Route path="*" element={<ErrorNotFound />} />
        <Route path="setup" element={<Setup />} />

        {/**
         * Notifications route
         */}
        <Route path="notifications" element={<Notifications />} />

        {/**
         * Schedule route
         */}
        <Route path="schedule" element={<Schedule />} />

        {/**
         * Shifts route
         */}
        <Route path="shifts" element={<Shifts />}>
          <Route path="personal" element={<ShiftsPersonal />}>
            <Route path="upcoming" element={<ShiftsPersonalUpcoming />} />
            <Route path="claim" element={<ShiftsPersonalClaim />} />
            <Route path="unavailability" element={<ShiftsPersonalUnavailability />} />
          </Route>
          <Route path="admin" element={<ShiftsAdmin />}>
            <Route path="missing-notes" element={<ShiftsAdminMissingNotes />} />
            <Route path="missing-support-worker" element={<ShiftsAdminMissingSupportWorker />} />
            <Route path="approve" element={<ShiftsAdminApprove />} />
          </Route>
        </Route>

        {/**
         * People route
         */}
        <Route path="people" element={<People />}>
          <Route path="employees" element={<Outlet />}>
            <Route path="" element={<PeopleEmployees />} />
            <Route
              path=":member"
              element={
                <MemberProvider>
                  <PeopleEmployeesProfile />
                </MemberProvider>
              }
            >
              <Route path=":category" element={<PeopleEmployeesProfileCategory />} />
            </Route>
          </Route>

          <Route path="participants" element={<Outlet />}>
            <Route path="" element={<PeopleParticipants />} />
            <Route
              path=":client"
              element={
                <ClientProvider>
                  <PeopleParticipantsProfile />
                </ClientProvider>
              }
            >
              <Route path=":category" element={<PeopleParticipantsProfileCategory />} />
            </Route>
          </Route>

          <Route path="support-coordinators" element={<Outlet />}>
            <Route path="" element={<PeopleSupportCoordinators />} />
            <Route
              path=":supportCoordinator"
              element={
                <SupportCoordinatorProvider>
                  <PeopleSupportCoordinatorsProfile />
                </SupportCoordinatorProvider>
              }
            >
              <Route path=":category" element={<PeopleSupportCoordinatorsProfileCategory />} />
            </Route>
          </Route>

          <Route path="plan-managers" element={<Outlet />}>
            <Route path="" element={<PeoplePlanManagers />} />
          </Route>
        </Route>

        {/**
         * Reports route
         */}
        <Route path="reports" element={<Reports />}>
          <Route path=":category" element={<Reports />} />
        </Route>

        {/**
         * Recycling route
         */}
        {/* <Route path="recycling" element={<Recycling />} /> */}

        {/**
         * Settings route
         */}
        <Route path="settings" element={<Settings />}>
          <Route path="personal" element={<SettingsPersonal />}>
            <Route path="account" element={<SettingsPersonalAccount />} />
          </Route>
          <Route path="organisation" element={<SettingsOrganisation />}>
            <Route path="scheduling" element={<SettingsOrganisationScheduling />} />
            <Route path="visa-types" element={<SettingsOrganisationVisaTypes />} />
            <Route path="payroll" element={<SettingsOrganisationPayroll />} />
            <Route path="medication" element={<SettingsOrganisationMedication />} />
          </Route>
        </Route>

        {/**
         * Support route
         */}
        <Route path="support" element={<Support />}>
          <Route path="contact" element={<SupportContact />} />
          <Route path="debug" element={<SupportDebug />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
