import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  AddressInput,
  addServerErrors,
  Checkbox,
  DateInput,
  FormModal,
  ModalProps,
  ProfileInput,
  Textarea,
  TimeInput,
} from '@timed/common';
import {
  EventBillClientCheckbox,
  EventDurationInput,
  EventMemberInput,
  EventPayAttendeeCheckbox,
} from '@timed/event';
import {
  Address,
  CreateEventDocument,
  Event,
  OrderBy,
  QueryByIdInput,
  useCreateEventMutation,
  useGetClientsQuery,
} from '@timed/gql';
import { ScheduleContextType } from '@timed/schedule';
import { addHours, addMinutes, differenceInMinutes, isBefore, isEqual, isValid } from 'date-fns';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type EventCreateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  client: ScheduleContextType['client'];
  member: ScheduleContextType['member'];
  startAt: Date;
  endAt: Date;
};

type FormData = Pick<
  Event,
  | 'startAt'
  | 'endAt'
  | 'passiveStartAt'
  | 'activeAssist'
  | 'duration'
  | 'summary'
  | 'scheduleNotes'
  | 'passive'
  | 'billable'
  | 'payable'
  | 'publicHoliday'
> & {
  startAtTime?: Date | null;
  endAtTime?: Date | null;
  passiveStartAtTime?: Date | null;
  client: QueryByIdInput;
  member?: QueryByIdInput | null;
  address?: Pick<
    Address,
    'unit' | 'street' | 'locality' | 'region' | 'postcode' | 'country' | 'latitude' | 'longitude'
  > | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 322,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    address: {
      width: 322,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    select: {
      width: 322,
    },
    dates: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: '120px 90px 80px',
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    passive: {
      width: 322,
      backgroundColor: theme.palette.drawer.activeBackground,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 4),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    groups: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: theme.spacing(4),
    },
    group: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridAutoRows: 'max-content',
      gap: theme.spacing(1),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const EventCreateFormModal = ({
  client,
  member,
  startAt,
  endAt,
  onClose,
  ...modalProps
}: EventCreateFormModalProps) => {
  const classes = useStyles();

  const [passive, setPassive] = useState<boolean>(false);

  const [createEvents, response] = useCreateEventMutation();

  const clients = useGetClientsQuery({
    variables: { input: { orderBy: [{ lastName: OrderBy.ASC }] } },
  });

  const selectedClient = clients.data?.clients.find((p) => p.id === client.get());

  const [lastToggledCheckbox, setLastToggleCHeckbox] = useState<'billable' | 'payable' | null>();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      startAt,
      startAtTime: startAt,
      endAt: addMinutes(
        startAt,
        endAt && (differenceInMinutes(endAt, startAt) > 30 || !selectedClient)
          ? differenceInMinutes(endAt, startAt)
          : (selectedClient?.defaultEventDuration || 0) * 60,
      ),
      endAtTime: addMinutes(
        startAt,
        endAt && (differenceInMinutes(endAt, startAt) > 30 || !selectedClient)
          ? differenceInMinutes(endAt, startAt)
          : (selectedClient?.defaultEventDuration || 0) * 60,
      ),
      duration:
        endAt && (differenceInMinutes(endAt, startAt) > 30 || !selectedClient)
          ? differenceInMinutes(endAt, startAt) / 60
          : selectedClient?.defaultEventDuration || 0,
      member: { id: member.get() || '' },
      client: { id: client.get() || '' },
      passive: false,
      activeAssist: false,
      billable: true,
      payable: true,
      publicHoliday: false,
      address: _.pick(selectedClient, [
        'unit',
        'street',
        'locality',
        'region',
        'postcode',
        'country',
        'latitude',
        'longitude',
      ]),
    },
  });

  const participant = watch('client');
  const billable = watch('billable');
  const payable = watch('payable');
  const startAtWatched = watch('startAt');
  const endAtWatched = watch('endAt');

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    client.set(response.data!.createEvent.client.id, true);

    const cache = response.client.cache;

    cache.modify({
      fields: {
        events(existing = []) {
          const events = cache.writeQuery({ data: response.data, query: CreateEventDocument });
          return [...existing, events];
        },
      },
    });
  };

  const onSubmit = ({ address, member, endAt, ...values }: FormData) => {
    if (!values.passive) values.passiveStartAt = null;

    createEvents({
      variables: {
        input: {
          ..._.omit(values, ['duration', 'startAtTime', 'endAtTime', 'passiveStartAtTime']),
          ...address,
          member: member?.id ? member : null,
          duration: differenceInMinutes(endAt, values.startAt),
        },
      },
    });
  };

  const handleChangeStartAt = useCallback(
    (value: MaterialUiPickersDate) => {
      value ??= new Date();

      const endAt = getValues('endAt');
      const duration = getValues('duration');

      // Set new startAt value
      setValue('startAt', value);

      if (endAt) {
        if (isBefore(endAt, value)) {
          // Set endAt to a time in the future if it occurs before the new startAt
          setValue('endAt', addHours(endAt, duration));
          setValue('endAtTime', addHours(endAt, duration));
        } else {
          setValue('duration', (endAt.getTime() - value.getTime()) / 1000 / 60 / 60);
        }
      }
    },
    [getValues, setValue],
  );

  const handleChangeEndAt = useCallback(
    (value: MaterialUiPickersDate | string) => {
      if (typeof value === 'string') {
        if (value === '' || +value < 0) {
          setValue('endAt', getValues('startAt'));
          setValue('endAtTime', getValues('startAt'));
        } else if (!isNaN(parseFloat(value))) {
          const newEndAt = addMinutes(getValues('startAt') as Date, parseFloat(value) * 60);
          setValue('endAt', newEndAt);
          setValue('endAtTime', newEndAt);
        }
      } else {
        setValue('endAt', value || new Date());
        setValue('endAtTime', value || new Date());
      }

      if (
        getValues('startAt') &&
        getValues('endAt') &&
        isBefore(getValues('startAt'), getValues('endAt'))
      ) {
        setValue(
          'duration',
          typeof value === 'string' && value.slice(-1) === '.'
            ? parseFloat(value)
            : (getValues('endAt')!.getTime() - getValues('startAt')!.getTime()) / 1000 / 60 / 60,
        );
        if (
          value instanceof Date &&
          (!getValues('endAtTime') || !isEqual(value, new Date(getValues('endAtTime')!)))
        )
          setValue('endAtTime', new Date(value));
      } else {
        setValue('duration', 0);
      }
    },
    [getValues, setValue],
  );

  const handleTogglePassive = () => {
    if (!passive) {
      // If duration is less than 12 hours, set it to 12 hours
      if (differenceInMinutes(getValues('endAt'), getValues('startAt')) < 720)
        handleChangeEndAt(addHours(getValues('startAt'), 12));

      // Set a default value for passiveStartAt
      if (!getValues('passiveStartAt')) {
        setValue('passiveStartAt', addHours(getValues('startAt'), 2));
        setValue('passiveStartAtTime', addHours(getValues('startAt'), 2));
      }
    }

    setValue('passive', !passive);
    setPassive(!passive);
  };

  const handleChangePassiveStartAt = useCallback(
    (passiveStartAt) => {
      if (isBefore(passiveStartAt, getValues('startAt'))) {
        setValue('startAtTime', passiveStartAt);
        handleChangeStartAt(passiveStartAt);
      }
    },
    [getValues, setValue, handleChangeStartAt],
  );

  const handleChangeStartAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('startAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        handleChangeStartAt(currentDate);
      }
    },
    [getValues, handleChangeStartAt],
  );

  const handleChangeEndAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('endAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        handleChangeEndAt(currentDate);
      }
    },
    [getValues, handleChangeEndAt],
  );

  const handleChangePassiveStartAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('passiveStartAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        setValue('passiveStartAt', currentDate);
        handleChangePassiveStartAt(currentDate);
      }
    },
    [getValues, setValue, handleChangePassiveStartAt],
  );

  // Update fields when participant changes
  useEffect(() => {
    if (participant.id || clients.data) {
      const newClient = clients.data?.clients.find(
        (c) => c.id === (participant.id ?? client.get()),
      );

      // Overwrite address with the new client's address
      setValue(
        'address',
        _.pick(newClient, [
          'unit',
          'street',
          'locality',
          'region',
          'postcode',
          'country',
          'latitude',
          'longitude',
        ]),
      );

      // Set duration to client's default event duration
      if (newClient?.defaultEventDuration && differenceInMinutes(endAt, startAt) === 30)
        handleChangeEndAt(addMinutes(startAt, newClient.defaultEventDuration * 60));
    }
  }, [client, clients.data, participant.id, endAt, handleChangeEndAt, setValue, startAt]);

  useEffect(() => {
    if (lastToggledCheckbox === 'billable') {
      if (billable && !payable) {
        // Set payable to true whenever billable equals true.
        setValue('payable', true);
      }
    } else if (lastToggledCheckbox === 'payable') {
      if (billable && !payable) {
        // Set billable to false whenever payable equals false.
        setValue('billable', false);
      }
    }
  }, [billable, payable, setValue, lastToggledCheckbox]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Add shift"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.groups}>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Participant</Typography>
            <ProfileInput
              required
              control={control}
              name="client.id"
              chipProps={{ onDelete: () => setValue('client.id', '') }}
              formControlProps={{ variant: 'outlined', size: 'small' }}
              type={'client'}
              orderBy={[{ lastName: OrderBy.ASC }]}
              watch={watch}
              className={classes.select}
              error={!!errors.client?.id?.message}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Passive overnight</Typography>
            <Box className={classes.passive}>
              <Box className={classes.groups}>
                <Box className={classes.group}>
                  <Checkbox
                    control={control}
                    name="passive"
                    label="This is an overnight shift"
                    onChange={handleTogglePassive}
                    color="primary"
                    error={!!errors.passive}
                    helperText={errors.passive?.message}
                  />
                </Box>
                {passive && (
                  <>
                    <Box className={classes.group}>
                      <Typography className={classes.bold}>
                        Start of 8 hour passive period
                      </Typography>
                      <Box className={classes.dates}>
                        <DateInput
                          name="passiveStartAt"
                          control={control}
                          inputVariant="outlined"
                          size="small"
                          onChange={handleChangePassiveStartAt}
                          error={!!errors.passiveStartAt}
                          helperText={errors.passiveStartAt?.message}
                        />
                        <TimeInput
                          keyboard
                          name="passiveStartAtTime"
                          control={control}
                          inputVariant="outlined"
                          size="small"
                          onChange={(date) => {
                            if (isValid(date)) handleChangePassiveStartAtTime(date);
                          }}
                          inputProps={{ style: { textAlign: 'center' } }}
                          error={!!errors.passiveStartAt}
                        />
                      </Box>
                    </Box>
                    <Box className={classes.group}>
                      <Checkbox
                        control={control}
                        name="activeAssist"
                        label="Passive period is active-assist"
                        color="primary"
                        error={!!errors.activeAssist}
                        helperText={errors.activeAssist?.message}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>From</Typography>
            <Box className={classes.dates}>
              <DateInput
                required
                name="startAt"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={handleChangeStartAt}
                error={!!errors.startAt}
                helperText={errors.startAt?.message}
              />
              <TimeInput
                required
                keyboard
                name="startAtTime"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={(date) => {
                  if (isValid(date)) handleChangeStartAtTime(date);
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.startAt}
              />
            </Box>
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>To</Typography>
            <Box className={classes.dates}>
              <DateInput
                required
                name="endAt"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={handleChangeEndAt}
                error={!!errors.endAt}
                helperText={errors.endAt?.message}
              />
              <TimeInput
                required
                keyboard
                name="endAtTime"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={(date) => {
                  if (isValid(date)) handleChangeEndAtTime(date);
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.endAt}
              />
              <EventDurationInput
                name="duration"
                control={control}
                onChange={(e) => handleChangeEndAt(e.target.value)}
                variant="outlined"
                size="small"
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.duration}
                helperText={errors.duration?.message}
              />
            </Box>
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Support worker</Typography>
            <EventMemberInput
              control={control}
              name="member.id"
              chipProps={{ onDelete: () => setValue('member.id', '') }}
              formControlProps={{ variant: 'outlined', size: 'small' }}
              watch={watch}
              className={classes.select}
              clientId={getValues('client.id')}
              startAt={startAtWatched}
              endAt={endAtWatched}
              error={!!errors.member}
            />
          </Box>
        </Box>
        <Box className={classes.groups}>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Address</Typography>
            <AddressInput
              name="address"
              control={control}
              clearErrors={clearErrors}
              setValue={setValue}
              setError={setError}
              error={!!errors.address}
              className={classes.address}
              // helperText={errors.address?.message}
              placeholder="Address"
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Message for the support worker</Typography>
            <Textarea
              name="summary"
              control={control}
              minRows={3}
              placeholder="Short optional summary of the shift."
              className={classes.textarea}
              // error={!!errors.summary}
              // helperText={errors.summary?.message}
              validation={{ maxLength: { value: 255, message: 'Exceeding 255 characters' } }}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Scheduling notes</Typography>
            <Textarea
              name="scheduleNotes"
              control={control}
              minRows={3}
              placeholder="These notes are only visible to schedulers."
              className={classes.textarea}
              // error={!!errors.scheduleNotes}
              // helperText={errors.scheduleNotes?.message}
              validation={{ maxLength: { value: 1000, message: 'Exceeding 1000 characters' } }}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Options</Typography>
            <EventBillClientCheckbox
              id="billable"
              name="billable"
              control={control}
              error={!!errors.billable}
              helperText={errors.billable?.message}
              onClick={() => {
                setLastToggleCHeckbox('billable');
              }}
            />
            <EventPayAttendeeCheckbox
              id="payable"
              name="payable"
              control={control}
              error={!!errors.payable}
              helperText={errors.payable?.message}
              onClick={() => {
                setLastToggleCHeckbox('payable');
              }}
            />
            <Checkbox
              control={control}
              name="publicHoliday"
              label="Shift occurs during a public holiday"
              error={!!errors.publicHoliday}
              helperText={errors.publicHoliday?.message}
            />
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default EventCreateFormModal;
