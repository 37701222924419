import { TextField, TextFieldProps, transformNumberToFixedFloat } from '@timed/common';

const EventDurationInput = ({ inputProps, ...props }: TextFieldProps) => {
  return (
    <TextField
      label="Hours"
      inputProps={inputProps}
      transform={transformNumberToFixedFloat(2)}
      onClick={(event) => {
        (event.target as HTMLInputElement).select();
      }}
      {...props}
    />
  );
};

export default EventDurationInput;
