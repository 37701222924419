import { createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import {
  ReportActivitySlips,
  ReportMemberClockedPeriods,
  ReportMemberPaySlipForm,
  ReportMemberWorkedHours,
  ReportModule,
  ReportModuleInput,
} from '@timed/report';
import { useForm } from 'react-hook-form';

type FormData = {
  module: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: theme.spacing(4),
    },
  }),
);

const ReportWizard = () => {
  const classes = useStyles();

  const { watch, control } = useForm<FormData>();

  const watched = watch('module');

  let module!: JSX.Element;

  switch (watched) {
    case ReportModule.ACTIVITY_SLIPS:
      module = <ReportActivitySlips />;
      break;

    case ReportModule.EMPLOYEE_CLOCKED_SHIFTS:
      module = <ReportMemberClockedPeriods />;
      break;

    case ReportModule.EMPLOYEE_WORKED_HOURS:
      module = <ReportMemberWorkedHours />;
      break;

    case ReportModule.EMPLOYEE_PAY_SLIP:
      module = <ReportMemberPaySlipForm />;
      break;

    default:
      module = <></>;
      break;
  }

  return (
    <Paper className={classes.paper}>
      <Typography align="center" variant="h6">
        Export Data
      </Typography>
      <ReportModuleInput
        name="module"
        control={control}
        formControlProps={{ size: 'small', variant: 'outlined' }}
      />
      {module}
    </Paper>
  );
};

export default ReportWizard;
