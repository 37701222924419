import { _shiftsPersonal } from '@timed/app/constants/metadata';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const ShiftsPersonal = () => {
  return (
    <Page {..._shiftsPersonal}>
      <Outlet />
    </Page>
  );
};

export default ShiftsPersonal;
