import { TransformInput } from '@timed/common';

/**
 * Transform value into a number
 */
export const transformStringToNumber = (min?: number, max?: number): TransformInput => ({
  input: (value: string | undefined) => value,
  output: (event) => {
    // Return null if value is not set
    if (!event.target.value) return null;

    const output = parseInt(event.target.value);

    return isNaN(output)
      ? 0
      : min
      ? Math.max(min, max ? Math.min(max, output) : output)
      : max
      ? Math.min(max, output)
      : output;
  },
});
