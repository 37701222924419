import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { IconButton, intersperse, Select } from '@timed/common';
import { EventAttributes, ScheduleContext } from '@timed/schedule/context';
import { ChangeEvent, useContext } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  eventAttributes?: EventAttributes[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      backgroundColor: theme.palette.background.default,
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      height: 20,
    },
    values: {
      fontSize: '90%',
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    control: {
      flex: '0 0 auto',
    },
  }),
);

const ScheduleAttributesSelect = () => {
  const classes = useStyles();

  const { setEventAttributes } = useContext(ScheduleContext);

  const items: { label: string; value: EventAttributes }[] = [
    { label: 'No Notes', value: 'no-notes' },
    { label: 'Not Clocked', value: 'not-clocked' },
    { label: 'Occurs On Public Holiday', value: 'public-holiday' },
    { label: 'Participant Not Billable', value: 'client-not-billable' },
    { label: 'Employee Not Payable', value: 'member-not-payable' },
  ];

  const { control, setValue } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const where = {};

    const attributes = event.target.value as EventAttributes[];

    if (attributes.includes('no-notes')) Object.assign(where, { notes: { _eq: null } });

    if (attributes.includes('not-clocked'))
      Object.assign(where, {
        _or: [
          { clockedOnAt: { _eq: null } },
          { clockedOnAt: { _ne: null }, clockedOffAt: { _eq: null } },
        ],
      });

    if (attributes.includes('public-holiday'))
      Object.assign(where, { publicHoliday: { _eq: true } });

    if (attributes.includes('client-not-billable'))
      Object.assign(where, { billable: { _eq: false } });

    if (attributes.includes('member-not-payable'))
      Object.assign(where, { payable: { _eq: false } });

    setEventAttributes(where);
  };

  /**
   * Delete all attributes.
   */
  const handleClear = () => {
    setValue('eventAttributes', []);
    setEventAttributes();
  };

  return (
    <Select
      multiple
      name="eventAttributes"
      label="Attributes"
      control={control}
      defaultValue={[]}
      onChange={handleChange}
      className={classes.input}
      formControlProps={{
        variant: 'outlined',
        size: 'small',
      }}
      items={items}
      renderValue={(values) => (
        <Box className={classes.content}>
          <Typography variant="body1" className={classes.values}>
            {intersperse({
              array: items
                .filter((item) => (values as EventAttributes[]).includes(item.value))
                .map((item) => item.label),
              and: false,
            })}
          </Typography>
          <IconButton
            size="small"
            className={classes.control}
            onClick={handleClear}
            onMouseDown={(event) => event.stopPropagation()}
          >
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    />
  );
};

export default ScheduleAttributesSelect;
