import { Typography } from '@material-ui/core';
import { Block, Link } from '@timed/common';
import { _supportContact } from '@timed/app';
import { Page } from '@timed/page';

const SupportContact = () => {
  return (
    <Page {..._supportContact}>
      <Block title="Found a bug?">
        <Typography variant="body1">
          Please call Tim on 0401 557 775 or email{' '}
          <Link to="mailto:tim.cooper@workout.com.au">tim.cooper@workout.com.au</Link>.
        </Typography>
      </Block>
    </Page>
  );
};

export default SupportContact;
