import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  addServerErrors,
  DateInput,
  FormModal,
  ModalProps,
  numberRegex,
  ProfileInput,
  TextField,
  TimeInput,
  transformNumberToFixedFloat,
} from '@timed/common';
import { transformStringToNumber } from '@timed/common/transformations/stringToNumber';
import {
  Client,
  CreateClientObservationDocument,
  Event,
  Member,
  OrderBy,
  useCreateClientObservationMutation,
  useCreateClientObservationWithEventMutation,
} from '@timed/gql';
import { isAfter, isBefore, isValid, max, subMinutes, subMonths } from 'date-fns';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';

type ClientCreateObservationFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  clientId?: Client['id'];
  event?: Pick<Event, 'id' | 'startAt' | 'endAt'> & { member?: Pick<Member, 'id'> | null };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    items: {
      display: 'grid',
      gridTemplateColumns: 'max-content max-content',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(4),
      alignItems: 'center',
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 60,
      },
    },
    dateInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 120,
      },
    },
    notesInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 248,
      },
    },
  }),
);

type FormData = {
  date?: Date;
  time?: Date | null;
  notes?: string | null;
  nursesName?: string | null;
  bloodSystolic?: number | null;
  bloodDiastolic?: number | null;
  heart?: number | null;
  temp?: number | null;
  spo2?: number | null;
  respiration?: number | null;
  member?: { id: string } | null;
};

const ClientCreateObservationFormModal = ({
  onClose,
  clientId,
  event,
  ...modalProps
}: ClientCreateObservationFormModalProps) => {
  const classes = useStyles();

  const bpDiastolicRef = useRef<HTMLInputElement>(null);

  const [createClientObservation, responseWithoutEvent] = useCreateClientObservationMutation();
  const [createClientObservationWithEvent, responseWithEvent] =
    useCreateClientObservationWithEventMutation();

  const now: Date = useMemo(() => new Date(), []);
  let date: Date = !!event ? max([subMinutes(now, 10), new Date(event.startAt)]) : now;

  // Recording obs before event has started
  if (!!event && isBefore(now, new Date(event.startAt))) {
    date = new Date(event.startAt);
  }

  // Recording obs after event has finished
  if (!!event && isAfter(now, new Date(event.endAt))) {
    date = new Date(event.endAt);
  }

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      date,
      time: date,
      bloodDiastolic: null,
      bloodSystolic: null,
      heart: null,
      respiration: null,
      spo2: null,
      temp: null,
      notes: null,
      nursesName: null,
      member: event?.member ? { id: event?.member?.id } : null,
    },
  });

  const response = useMemo(
    () => (!!event ? responseWithEvent : responseWithoutEvent),
    [event, responseWithoutEvent, responseWithEvent],
  );

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientObservations(existing = []) {
          return [
            ...existing,
            cache.writeQuery({ data: response.data, query: CreateClientObservationDocument }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async ({ member, ...values }: FormData) => {
    if (!values.time || !isValid(values.time)) setError('time', { message: 'Invalid time' });
    else {
      values = _.omit(values, ['time']);

      if (event)
        createClientObservationWithEvent({
          variables: {
            input: {
              ...values,
              event: { id: event.id },
            },
          },
        });
      else
        createClientObservation({
          variables: {
            input: {
              ...values,
              client: { id: clientId! },
              member: member?.id ? { id: member.id } : null,
            },
          },
        });
    }
  };

  const handleChangeTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('date')!);
        currentDate.setHours(date.getHours(), date.getMinutes());

        setValue('date', currentDate);
      }
    },
    [getValues, setValue],
  );

  if (!clientId && !event)
    throw new Error('clientId and eventId properties cannot both be undefined');

  return (
    <FormModal
      modalProps={modalProps}
      title="Record Observation"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
      saveText="Record"
    >
      <Box className={classes.wrapper}>
        <Box className={classes.items}>
          <Typography variant="body1">Time</Typography>
          <Box className={classes.dateInput}>
            <DateInput
              required
              name="date"
              control={control}
              inputVariant="outlined"
              size="small"
              inputProps={{ style: { textAlign: 'center' } }}
              error={!!errors.date}
              helperText={errors.date?.message}
            />
            <TimeInput
              required
              keyboard
              name="time"
              control={control}
              inputVariant="outlined"
              size="small"
              onChange={(date) => {
                if (isValid(date)) handleChangeTime(date);
              }}
              inputProps={{ style: { textAlign: 'center' } }}
              error={!!errors.time}
              helperText={errors.time?.message}
            />
          </Box>

          <Typography variant="body1">BP</Typography>
          <Box className={classes.input}>
            <TextField
              name="bloodSystolic"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.bloodSystolic}
              helperText={errors.bloodSystolic?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
              onKeyDown={(event) => {
                if (event.key === '/') bpDiastolicRef.current?.focus();
              }}
            ></TextField>
            {'/'}
            <TextField
              name="bloodDiastolic"
              inputRef={bpDiastolicRef}
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.bloodDiastolic}
              helperText={errors.bloodDiastolic?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            mmHg
          </Box>

          <Typography variant="body1">Heart Rate</Typography>
          <Box className={classes.input}>
            <TextField
              name="heart"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.heart}
              helperText={errors.heart?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            bpm
          </Box>

          <Typography variant="body1">Temperature</Typography>
          <Box className={classes.input}>
            <TextField
              name="temp"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.temp}
              helperText={errors.temp?.message}
              transform={transformNumberToFixedFloat(1)}
              validation={{ pattern: /^-?\d+\.?\d*$/ }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            ºC
          </Box>

          <Typography variant="body1">SpO2</Typography>
          <Box className={classes.input}>
            <TextField
              name="spo2"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.spo2}
              helperText={errors.spo2?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            %
          </Box>

          <Typography variant="body1">Respiration</Typography>
          <Box className={classes.input}>
            <TextField
              name="respiration"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.respiration}
              helperText={errors.respiration?.message}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            ></TextField>
            brpm
          </Box>

          {!event && (
            <>
              <Typography variant="body1">Support worker</Typography>
              <Box className={classes.notesInput}>
                <ProfileInput
                  control={control}
                  name="member.id"
                  chipProps={{ onDelete: () => setValue('member.id', '') }}
                  formControlProps={{ variant: 'outlined', size: 'small' }}
                  watch={watch}
                  error={!!errors.member}
                  type="member"
                  orderBy={[{ lastName: OrderBy.ASC }]}
                  where={{
                    events: {
                      client: { id: { _eq: clientId } },
                      startAt: { _gte: subMonths(new Date(), 3) },
                    },
                  }}
                />
              </Box>
            </>
          )}

          <Typography variant="body1">Nurse Name</Typography>
          <Box className={classes.notesInput}>
            <TextField
              name="nursesName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.nursesName}
              helperText={errors.nursesName?.message}
            ></TextField>
          </Box>

          <Typography variant="body1">Notes</Typography>
          <Box className={classes.notesInput}>
            <TextField
              multiline
              minRows={2}
              name="notes"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.notes}
              helperText={errors.notes?.message}
            ></TextField>
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientCreateObservationFormModal;
