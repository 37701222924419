import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { ScheduleContext } from '@timed/schedule';
import clsx from 'clsx';
import { addDays, format, isSameDay, isToday, isWeekend } from 'date-fns';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dates: {
      flex: '1 1 100%',
      display: 'flex',
      flexFlow: 'row',
    },
    date: {
      alignSelf: 'center',
      overflow: 'hidden',
      padding: '4px 0 3px 0',
      textAlign: 'center',
      boxSizing: 'border-box',
      '& span': {
        fontSize: '16px',
        fontWeight: 'bold',
      },
    },
    notToday: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    today: {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    weekend: {
      backgroundColor: theme.palette.action.hover,
      '&:hover': {
        backgroundColor: theme.palette.action.focus,
      },
    },
    dateHighlight: {
      margin: '0 1px',
      padding: 1,
      borderRadius: 4,
      cursor: 'pointer',
    },
    autoMemberAssign: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  }),
);

const ScheduleCalendarDates = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    dates: { from, range, autoMemberAssign },
    setFrom,
  } = useContext(ScheduleContext);

  const dates = [...Array(range)].map((_, i) => {
    const currentDate = addDays(from, i);

    let formattedDay = format(
      currentDate,
      range > 3 ? (range >= 28 || smDown ? 'cccccc' : 'ccc') : 'cccc',
    );

    if (range >= 28 || (range > 3 && smDown)) {
      formattedDay = ['M', 'W', 'F'].includes(formattedDay.charAt(0))
        ? formattedDay.charAt(0)
        : formattedDay;
    }

    const formattedDate = format(currentDate, 'd');

    return (
      <div key={i} className={classes.date} style={{ flex: '0 1 ' + 100 / range + '%' }}>
        <div
          className={
            isToday(currentDate)
              ? clsx(classes.dateHighlight, classes.today)
              : isSameDay(currentDate, autoMemberAssign)
              ? clsx(classes.dateHighlight, classes.autoMemberAssign)
              : isWeekend(currentDate)
              ? clsx(classes.dateHighlight, classes.weekend)
              : clsx(classes.dateHighlight, classes.notToday)
          }
          onClick={() => {
            if (from !== currentDate) setFrom(currentDate);
          }}
        >
          <div>{formattedDay}</div>
          <span>{formattedDate}</span>
        </div>
      </div>
    );
  });

  return <>{dates}</>;
};

export default ScheduleCalendarDates;
