export enum Activity {
  BILLABLE_WEEKDAY = '01-011-0107-1-1',
  BILLABLE_AFTERNOON = '01-015-0107-1-1',
  BILLABLE_NIGHT = '01-002-0107-1-1',
  BILLABLE_SATURDAY = '01-013-0107-1-1',
  BILLABLE_SUNDAY = '01-014-0107-1-1',
  BILLABLE_PUBLIC_HOLIDAY = '01-012-0107-1-1',

  NON_BILLABLE_WEEKDAY = '00-011-0107-1-1',
  NON_BILLABLE_AFTERNOON = '00-015-0107-1-1',
  NON_BILLABLE_NIGHT = '00-002-0107-1-1',
  NON_BILLABLE_SATURDAY = '00-013-0107-1-1',
  NON_BILLABLE_SUNDAY = '00-014-0107-1-1',
  NON_BILLABLE_PUBLIC_HOLIDAY = '00-012-0107-1-1',

  OVERNIGHT = '01-010-0107-1-1',
  CANCELLED_OVERNIGHT = 'Cancelled Passive Overnight',
  KM = "KM'S",

  TRAVEL_PUBLIC_HOLIDAY = 'Travel Pub Holiday',
  TRAVEL_WEEKDAY = 'Travel Time Weekday',
  TRAVEL_AFTERNOON = 'Travel Time Afternoon',
  TRAVEL_NIGHT = 'Travel Time Night',
  TRAVEL_SATURDAY = 'Travel Time Saturday',
  TRAVEL_SUNDAY = 'Travel Time Sunday',
}
