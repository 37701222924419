import { ClientFileType, EventFileType, MemberFileType } from '@timed/gql';
import { RegisterOptions } from 'react-hook-form';

export const validateFileType = (fileType: 'member' | 'client' | 'event'): RegisterOptions => ({
  validate: {
    validFileType: (value) =>
      value in
        (fileType === 'client'
          ? ClientFileType
          : fileType === 'member'
          ? MemberFileType
          : EventFileType) || 'Invalid document type',
  },
});
