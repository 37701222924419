import { Notification } from '@timed/gql';
import { createContext } from 'react';

export type NotificationContextType = {
  totalCount: number;
  notifications: Pick<Notification, 'id' | 'message'>[];
};

const NotificationContext = createContext<NotificationContextType>({
  totalCount: 0,
  notifications: [],
});

export default NotificationContext;
