/**
 * Options for generating PersonName regexps.
 */
type PersonNameRegexFn = {
  /**
   * Permit numbers in person's name
   */
  allowNumbers?: boolean;
};

/**
 * Person Name Regex
 * - May contain spaces, hyphens, periods, parentheses, commas and single-quotation marks
 * - May be lowercase
 * - May be a single character in length
 * - May contain non-english alphabet characters
 */
export const personNameRegex = (options?: PersonNameRegexFn) =>
  new RegExp(`\\b([${options?.allowNumbers ? '0-9' : ''}A-ZÀ-ÿ][-,a-z. '()]*[ ]*)+$`, `i`);
