import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { _peopleMembers } from '@timed/app';
import { MemberList } from '@timed/member';
import { Page } from '@timed/page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gap: theme.spacing(4),
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
      },
    },
  }),
);

const PeopleEmployees = () => {
  const classes = useStyles();

  return (
    <Page {..._peopleMembers}>
      <Box className={classes.container}>
        <MemberList />
      </Box>
    </Page>
  );
};

export default PeopleEmployees;
