import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { _peopleClients, _peopleClientsProfile } from '@timed/app';
import { Protected, useAuth } from '@timed/auth';
import { ClientContext, ClientViewActionMenuButton, ClientViewCloseButton } from '@timed/client';
import { Avatar, formatPersonName, useRouter } from '@timed/common';
import { Permission } from '@timed/gql';
import { Page } from '@timed/page';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

type TabItem = {
  label: string;
  path: string;
  permission?: Permission | Permission[];
  admin?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      flex: '0 1 auto',
      display: 'grid',
      gap: theme.spacing(4),
    },
    card: {
      display: 'grid',
      // gridAutoFlow: "column",
      // gridAutoColumns: "max-content",
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
        gridTemplateColumns: 'max-content auto max-content',
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
        gridTemplateColumns: 'max-content auto max-content',
      },
    },
    avatar: {
      width: theme.spacing(16),
      height: theme.spacing(16),
      fontSize: '175%',
      lineHeight: theme.spacing(8) + 'px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
    },
    typography: {
      lineHeight: '32px',
    },
    controls: {
      display: 'grid',
      alignSelf: 'flex-start',
      alignItems: 'center',

      gridAutoFlow: 'column',
      gridTemplateColumns: 'max-content',
      gap: theme.spacing(4),
    },
    tabRow: {
      display: 'grid',
      alignItems: 'center',

      gridAutoFlow: 'column',
      gridTemplateColumns: 'auto max-content',
    },
    tabs: {
      color: theme.palette.text.primary,
      '& .MuiTabs-flexContainer': {
        display: 'inline-grid',
        gridAutoFlow: 'column',
        [theme.breakpoints.down('md')]: {
          gap: theme.spacing(4),
        },
        [theme.breakpoints.up('md')]: {
          gap: theme.spacing(8),
        },
      },
    },
    tab: {
      textTransform: 'none',
      minWidth: 'inherit',
      '&.MuiTab-root': {
        padding: 0,
      },
      '& span': {
        padding: theme.spacing(1, 0),
      },
    },
    indicator: {
      height: theme.spacing(1),
    },
    section: {
      flex: '1 1 auto',
      display: 'grid',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        overflowY: 'auto',
        marginTop: theme.spacing(8),
        gap: theme.spacing(8),
      },
    },
  }),
);

const PeopleParticipantsProfile = () => {
  const classes = useStyles();
  const { pathname, navigate } = useRouter();
  const { permissible } = useAuth();

  const client = useContext(ClientContext);

  const theme = useTheme();

  const currentTab = pathname.split('/').pop();

  const menuItems: TabItem[] = [
    {
      label: 'Overview',
      path: 'overview',
    },
    {
      label: 'Notes',
      path: 'notes',
      admin: true,
    },
    {
      label: 'Team',
      path: 'team',
      permission: [Permission.CLIENT_READ, Permission.MEMBER_READ],
    },
    {
      label: 'Files',
      path: 'files',
      permission: Permission.CLIENT_FILE_READ,
    },
    {
      label: 'Obs',
      path: 'obs',
      permission: Permission.CLIENT_READ,
    },
    {
      label: 'Meds',
      path: 'meds',
      permission: Permission.CLIENT_READ,
    },
    // {
    //   label: "History",
    //   path: "history",
    //   role: Role.ADMINISTRATOR,
    // },
  ];

  const handleClick = (link: string) => (_: React.KeyboardEvent | React.MouseEvent) =>
    link !== currentTab && navigate(link);

  const navigation: JSX.Element = false ? (
    <></>
  ) : (
    <Tabs
      value={currentTab}
      aria-label="Profile nav bar"
      className={classes.tabs}
      TabIndicatorProps={{ className: classes.indicator }}
    >
      {menuItems.map(
        (tab, index) =>
          permissible({ admin: tab.admin, permissions: tab.permission }) && (
            <Tab
              disableRipple
              key={index}
              label={tab.label}
              className={classes.tab}
              value={tab.path}
              onClick={handleClick(tab.path)}
            />
          ),
      )}
    </Tabs>
  );

  const avatar = (
    <Avatar
      content={[client.firstName, client.lastName]}
      variant="circular"
      color={
        // If profile.color is not set or is white set avatar color as
        // the same as paper background color
        !client.color ||
        client.color?.toLowerCase() === '#fff' ||
        client.color?.toLowerCase() === '#ffffff'
          ? theme.palette.type === 'light'
            ? '#fff'
            : theme.palette.background.paper
          : client.color
      }
      textColor={
        // If profile.color is not set or is white set avatar text color
        // as the same as typography primary color
        ((!client.color ||
          client.color?.toLowerCase() === '#fff' ||
          client.color?.toLowerCase() === '#ffffff') &&
          theme.palette.text.primary) ||
        undefined
      }
      className={classes.avatar}
    />
  );

  return (
    <Page
      {..._peopleClientsProfile}
      label={formatPersonName(client, { capitaliseLastName: true, lastNameFirst: true })!}
      path={`/people/participants/${client.id}`}
      redirect={`/people/participants/${client.id}/overview`}
      crumbs={{ before: [_peopleClients] }}
    >
      <Box className={classes.top}>
        <Box className={classes.card}>
          {avatar}
          <Box>
            <Typography variant="body1" color="textPrimary" className={classes.typography}>
              {formatPersonName(client, {
                middle: true,
                capitaliseLastName: true,
                lastNameFirst: true,
              })}
            </Typography>
            <Typography variant="body1" color="textSecondary" className={classes.typography}>
              Participant
            </Typography>
          </Box>
          <Box className={classes.controls}>
            <Protected permissions={Permission.CLIENT_WRITE}>
              <ClientViewActionMenuButton />
            </Protected>
            <ClientViewCloseButton />
          </Box>
        </Box>
        <Box className={classes.tabRow}>{navigation}</Box>
      </Box>
      <Divider />
      <Box className={classes.section}>
        <Outlet />
      </Box>
    </Page>
  );
};

export default PeopleParticipantsProfile;
