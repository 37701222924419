import { Theme } from '@material-ui/core/styles';
import { DeepPartial } from 'react-hook-form';

export const paletteLight: DeepPartial<Theme['palette']> = {
  type: 'light',
  avatar: {
    main: '#fafafa',
    text: '#9c27b0',
  },
  logo: {
    src: 'icon.white.svg',
  },
  topBar: {
    text: '#fafafa',
    main: '#9c27b0',
    dark: '#802090',
    light: '#b82ed0',
    hoverBackground: 'rgba(0,0,0,0.05)',
    activeBackground: 'rgba(0,0,0,0.1)',
  },
  drawer: {
    sideMenu: '#f1f2f5',
    hoverBackground: 'rgba(0,0,0,0.03)',
    activeBackground: 'rgba(0,0,0,0.06)',
  },
  primary: {
    main: '#9c27b0',
    dark: '#6d1b7b',
    light: '#af52bf',
  },
  secondary: {
    main: '#ffc400',
    dark: '#b28900',
    light: '#ffcf33',
  },
  error: {
    main: 'rgb(231, 76, 60)',
  },
  warning: {
    main: 'rgb(230, 126, 34)',
  },
  info: {
    main: 'rgb(26, 188, 156)',
  },
  success: {
    main: 'rgb(46, 204, 113)',
  },
  background: {
    default: '#fafafa',
    paper: '#f1f2f5',
  },
  text: {
    primary: '#172B4D',
    secondary: '#5E6C84',
  },
};
