import { _settingsOrganisationVisaTypes } from '@timed/app';
import { Page } from '@timed/page';
import { SettingsOrganisationVisaTypesForm } from '@timed/settings/comopnents/OrganisationVisaTypesForm';

const SettingsOrganisationVisaTypes = () => {
  return (
    <Page {..._settingsOrganisationVisaTypes}>
      <SettingsOrganisationVisaTypesForm />
    </Page>
  );
};

export default SettingsOrganisationVisaTypes;
