import {
  Box,
  Button,
  createStyles,
  FormControl,
  FormControlProps,
  makeStyles,
  OutlinedInput,
  OutlinedInputProps,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { IconButton, MIME, validateRequired } from '@timed/common';
import React, { ChangeEvent, useState } from 'react';
import {
  Control,
  Controller,
  RegisterOptions,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';

export type FileInputProps = OutlinedInputProps & {
  control?: Control<any>;
  helperText?: string;
  name: string;
  setError: UseFormSetError<any>;
  setValue: UseFormSetValue<any>;
  allowedMimeTypes: MIME[];
  formControlProps?: FormControlProps;
  validation?: Omit<RegisterOptions, 'required'>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      display: 'flex',
    },
    outlineWrapper: {
      flex: '1 1 auto',
    },
    outline: {
      width: '100%',
      '& .MuiInputBase-input': {
        cursor: 'default',
      },
      '& .MuiFormLabel-root.Mui-disabled': {
        color: theme.palette.text.secondary,
      },
    },
    contents: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(0, 2),
      position: 'absolute',
      display: 'grid',
      gridTemplateColumns: 'max-content auto min-content',
      gap: theme.spacing(2),
      alignItems: 'center',
      pointerEvents: 'none',
    },
    selectButton: {
      padding: theme.spacing(0, 2),
      pointerEvents: 'auto',
    },
    clearButton: {
      pointerEvents: 'auto',
    },
  }),
);

const FileInput = ({
  control,
  required,
  name,
  error,
  onChange,
  setError,
  setValue,
  formControlProps,
  allowedMimeTypes,
  helperText,
  validation,
  ...props
}: FileInputProps) => {
  const classes = useStyles();
  const { palette } = useTheme();
  const [attachment, setAttachment] = useState<any>();
  const anchorRef = React.useRef<HTMLInputElement>(null);

  const handleChange = ({
    target: {
      validity: { valid },
      files,
    },
  }: ChangeEvent<HTMLInputElement>) => {
    setAttachment(files![0]);
    valid ? setValue(name, files![0]) : setError(name, { message: 'Invalid file' });
  };

  const handleClear = () => {
    setAttachment(null);
    setValue(name, null);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={validation && required ? { ...validateRequired, ...validation } : validation}
      render={({ field: { value, ...field } }) => (
        <Box
          className={classes.wrapper}
          color={!!error ? palette.error.main : palette.background.paper}
          onKeyDown={(event) => event.keyCode === 32 && anchorRef?.current?.click()}
        >
          <Box className={classes.outlineWrapper}>
            <FormControl {...formControlProps} className={classes.outline}>
              <OutlinedInput error={error} {...props} />
              {/* <FormHelperText>{error && helperText}</FormHelperText> */}
            </FormControl>
          </Box>
          <Box className={classes.contents}>
            <Button
              component="label"
              variant="contained"
              size="small"
              color="primary"
              className={classes.selectButton}
            >
              Select File
              <input
                {...field}
                hidden
                required={required}
                name={name}
                ref={anchorRef}
                type="file"
                accept={allowedMimeTypes.join(',')}
                onChange={(event) => {
                  onChange && onChange(event);
                  handleChange(event);
                }}
              />
            </Button>
            <Typography color="textPrimary" noWrap>
              {attachment && attachment.name}
            </Typography>
            {value && (
              <IconButton
                size="small"
                className={classes.clearButton}
                onClick={handleClear}
                onMouseDown={(event: any) => event.stopPropagation()}
              >
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
    />
  );
};

export default FileInput;
