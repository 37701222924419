import { Grid, makeStyles, useTheme } from '@material-ui/core';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { addServerErrors, ButtonAsync, Checkbox, EmailInput, Link, Snackbar } from '@timed/common';
import { useLoginUserMutation } from '@timed/gql';
import { UserPasswordInput } from '@timed/user';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

type FormData = {
  input: {
    identifier: string;
    password: string;
  };
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    gap: theme.spacing(2),
  },
}));

const UserLoginForm = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [errorText, setErrorText] = useState<string>();
  const [loginUser, response] = useLoginUserMutation();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();
  const parsed = queryString.parse(location.search);
  const redirectTo = (parsed.ref as string) ? (parsed.ref as string) : '/';

  useEffect(
    () => response.error && addServerErrors(response.error, setError, setErrorText),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) => loginUser({ variables: values });

  const onComplete = () => navigate(redirectTo);

  const onSuccess = () => localStorage.setItem('token', response.data!.loginUser.token);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Snackbar
        open={!!errorText && response.error}
        severity="error"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {errorText}
      </Snackbar>
      <Grid container spacing={4} justify="center">
        <Grid item xs={12}>
          <EmailInput
            required
            autoFocus
            name="input.identifier"
            fullWidth
            autoComplete="email"
            variant="outlined"
            control={control}
            error={!!errors.input?.identifier}
            helperText={errors.input?.identifier?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <UserPasswordInput
            required
            fullWidth
            name="input.password"
            autoComplete="current-password"
            variant="outlined"
            control={control}
            error={!!errors.input?.password}
            helperText={errors.input?.password?.message}
          />
        </Grid>
        <Grid item>
          <Checkbox
            control={control}
            name="rememberMe"
            label="Remember me on this device"
            color="default"
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleOutlineRoundedIcon />}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonAsync
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            loading={response.loading}
            success={!!response.data}
            onComplete={onComplete}
            onSuccess={onSuccess}
          >
            Sign In
          </ButtonAsync>
        </Grid>
        <Grid item container justify="center" style={{ marginTop: theme.spacing(2) }}>
          <Link to={'/join' + (redirectTo !== '/' ? '?ref=' + redirectTo : '')} variant="body1">
            Don't have an account yet? Sign Up
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserLoginForm;
