import { TextField, TextFieldProps, validateAustralianBusinessName } from '@timed/common';

const OrgLegalNameInput = (props: TextFieldProps) => (
  <TextField
    name="legalName"
    label="Legal name of organisation"
    type="text"
    validation={validateAustralianBusinessName}
    {...props}
  />
);

export default OrgLegalNameInput;
