import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  ScheduleCalendarCell,
  ScheduleCalendarEvent,
  ScheduleCalendarNowIndicator,
  ScheduleContext,
} from '@timed/schedule';
import { addMinutes, differenceInDays, isToday } from 'date-fns';
import { isWeekend } from 'date-fns/esm';
import { useContext, useMemo } from 'react';

type ScheduleCalendarColumnProps = React.PropsWithChildren<{
  date: Date;
}>;

const ScheduleCalendarColumn = (props: ScheduleCalendarColumnProps) => {
  const {
    dates: { from, range },
    client,
    member,
    lists: { shifts },
  } = useContext(ScheduleContext);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      wrapper: {
        flex: '1 1 0px',
        height: '100%',
        borderLeft: 'thin solid ' + theme.palette.divider,
        [theme.breakpoints.up('md')]: {
          borderBottom: 'thin solid ' + theme.palette.divider,
        },
        '&:first-child': {
          // First Day
          borderLeft: '1px solid ' + theme.palette.divider,
        },
        '&:last-child': {
          // Last Day
          [theme.breakpoints.up('md')]: {
            borderRight: '1px solid ' + theme.palette.divider,
          },
        },
      },
      cells: {
        height: '100%',
        top: 0,
        left: 0,
        position: 'relative',
        zIndex: 1,
        flex: '1 1 auto',
        display: 'flex',
        flexFlow: 'column',
        // backgroundColor: "rgba(0,0,0,0.01)",
        backgroundColor: isWeekend(props.date) ? 'rgba(0,0,0,.1)' : 'rgba(0,0,0,0.01)',
        '& div': {
          flex: '1 1 auto',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)',
          },
        },
        '& div:nth-child(2n+1)': {
          flex: '1 1 auto',
          // borderTop: "thin solid rgba(0,0,0,0.2)",
          borderTop: 'thin solid ' + theme.palette.divider,
        },
        '& div:nth-child(2n+2)': {
          flex: '1 1 auto',
          // borderTop: "thin dotted rgba(0,0,0,0.2)",
          borderTop: 'thin dotted ' + theme.palette.divider,
        },
        '& div:nth-child(-n+40)': {
          backgroundColor: isWeekend(props.date) ? 'rgba(0,0,0,.05)' : 'rgba(0,0,0,.025)',
        },
        '& div:nth-child(-n+12)': {
          backgroundColor: 'rgba(0,0,0,0.0)',
        },
        '& div:last-child()': {
          borderBottom: '2px solid ' + theme.palette.grey[400],
        },
        '& div:nth-child(13)': {
          // borderTop: "thin solid " + theme.palette.grey[500],
        },
        '& div:nth-child(41)': {
          // borderTop: "thin solid " + theme.palette.grey[500],
        },
      },
      events: {
        height: '100%',
        zIndex: 2,
        top: '-100%',
        marginRight: '16%',
        position: 'relative',
        pointerEvents: 'none',
      },
    }),
  );

  const classes = useStyles();

  const cells = useMemo(() => {
    const cells: Array<JSX.Element> = [];
    for (let i = 0; i < 48; i++) {
      cells.push(<ScheduleCalendarCell key={i} date={addMinutes(props.date, 30 * i)} />);
    }
    return cells;
  }, [props.date]);

  const todaysShifts = useMemo(() => {
    if (shifts && (client.get() || member.get())) {
      const thisday = shifts[differenceInDays(props.date, from)];

      if (thisday) {
        const todaysShifts: Array<JSX.Element> = [];
        for (let i = 0; i < thisday.length; i++) {
          todaysShifts.push(<ScheduleCalendarEvent key={i} {...thisday[i]} />);
        }
        return todaysShifts;
      }
    }
  }, [shifts, client, member, from, props.date]);

  return (
    <div className={classes.wrapper} style={{ flex: '0 1 ' + 100 / range + '%' }}>
      <div className={classes.cells}>{cells}</div>
      {isToday(props.date) && <ScheduleCalendarNowIndicator date={props.date} />}
      <div
        className={classes.events}
        // Apply top offset to account for "current time" indicator
        style={isToday(props.date) ? { top: 'calc(-100% - 12.3px)' } : {}}
      >
        {todaysShifts}
      </div>
    </div>
  );
};

export default ScheduleCalendarColumn;
