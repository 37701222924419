import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import {
  addServerErrors,
  DateInput,
  formatPersonName,
  FormModal,
  ModalProps,
  Snackbar,
  Switch,
  Textarea,
  TimeInput,
} from '@timed/common';
import {
  CreateMemberUnavailablesDocument,
  CreateMemberUnavailablesMutationOptions,
  useCreateMemberUnavailablesMutation,
} from '@timed/gql';
import { MemberContextType } from '@timed/member';
import * as DateFns from 'date-fns';
import { addHours } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUnavailabilityPeriodCreateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  member: MemberContextType;
};

type FormData = {
  startAt: Date;
  endAt: Date;
  notes: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      height: 360,
      flex: '1 0 auto',
      overflowY: 'auto',
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 256,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        width: 384,
      },
    },
    dates: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 120,
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    row: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 'max-content',
      gap: theme.spacing(4),
    },
    days: {
      gridAutoFlow: 'column',
      display: 'grid',
      gridTemplateRows: 'auto auto auto auto auto',
      gap: theme.spacing(0),
      alignItems: 'center',
    },
    groups: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      // gridAutoFlow: "row",
      gap: theme.spacing(4),
    },
    group: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridAutoRows: 'max-content',
      gap: theme.spacing(1),
      '& .MuiInputBase-input': {
        textAlign: 'center',
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
    endDate: {
      display: 'grid',
      gap: 16,
      gridTemplateColumns: 'auto 120px',
    },
  }),
);

const MemberUnavailabilityPeriodCreateFormModal = ({
  onClose,
  member,
  ...modalProps
}: MemberUnavailabilityPeriodCreateFormModalProps) => {
  const classes = useStyles();

  const [createUnavailables, response] = useCreateMemberUnavailablesMutation();

  const [allDay, setAllDay] = useState<boolean>(true);

  const nextHour = DateFns.addHours(DateFns.startOfHour(new Date()), 1);

  const defaultValues: FormData = {
    startAt: nextHour,
    endAt: addHours(nextHour, 1),
    notes: '',
  };

  const { handleSubmit, control, setError, setValue, getValues, watch } = useForm<FormData>({
    defaultValues,
  });

  const startAt = watch('startAt');
  const endAt = watch('endAt');

  const onSubmit = (values: FormData) => {
    const variables: CreateMemberUnavailablesMutationOptions['variables'] = {
      input: {
        objects: [
          {
            owner: { id: member.id },
            startAt: allDay ? DateFns.startOfDay(values.startAt) : values.startAt,
            endAt: allDay ? DateFns.addDays(DateFns.startOfDay(values.endAt), 1) : values.endAt,
            notes: values.notes,
          },
        ],
      },
    };

    createUnavailables({ variables }).catch((e) => {});
  };

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        memberUnavailables(existing = []) {
          return [
            ...existing,
            cache.writeQuery({ data: response.data, query: CreateMemberUnavailablesDocument }),
          ];
        },
      },
    });
  };

  const handleToggleAllDay = () => {
    !allDay && setValue('endAt', addHours(getValues('startAt'), 1));
    setAllDay(!allDay);
  };

  const handleSetStartAt = (date: Date) => {
    setValue('startAt', date);

    if (DateFns.isEqual(date, getValues('endAt')) || DateFns.isAfter(date, getValues('endAt'))) {
      setValue('endAt', DateFns.addHours(date, 1));
    }
  };

  const handleSetEndAt = (date: Date) => {
    setValue('endAt', date);

    if (
      DateFns.isEqual(getValues('startAt'), date) ||
      DateFns.isAfter(getValues('startAt'), date)
    ) {
      setValue('startAt', DateFns.subHours(date, 1));
    }
  };

  /**
   * Display error messages from server response
   */
  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  return (
    <FormModal
      modalProps={modalProps}
      title="Add unavailability"
      description={`Record a period of unavailability for ${formatPersonName(member)}`}
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Snackbar
        open={!!response.error}
        severity="error"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {response.error?.message}
      </Snackbar>
      <Box className={classes.inputs}>
        <Box className={classes.groups}>
          <Box className={classes.group}>
            <Typography className={classes.bold}>All day</Typography>
            <Switch checked={allDay} name="allDay" onChange={handleToggleAllDay} color="primary" />
          </Box>

          <Box className={allDay ? classes.row : undefined}>
            <Box className={classes.group}>
              <Typography className={classes.bold}>From</Typography>
              <Box className={classes.dates}>
                <DateInput
                  disablePast
                  required
                  name="startAt"
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  onChange={(date) => date && handleSetStartAt(date)}
                />
                {!allDay && (
                  <TimeInput
                    required
                    name="startAt"
                    control={control}
                    inputVariant="outlined"
                    size="small"
                    labelFunc={(date) =>
                      date
                        ? DateFns.format(
                            date,
                            date.getMinutes() === 0 ? 'ha' : 'h:mma',
                          ).toLowerCase()
                        : ''
                    }
                    onChange={(date) => date && handleSetStartAt(date)}
                    value={startAt}
                  />
                )}
              </Box>
            </Box>
            <Box className={classes.group}>
              <Typography className={classes.bold}>To</Typography>
              <Box className={classes.dates}>
                <DateInput
                  disablePast
                  required
                  name="endAt"
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  onChange={(date) => date && handleSetEndAt(date)}
                />
                {!allDay && (
                  <TimeInput
                    required
                    name="endAt"
                    control={control}
                    inputVariant="outlined"
                    size="small"
                    labelFunc={(date) =>
                      date
                        ? DateFns.format(
                            date,
                            date.getMinutes() === 0 ? 'ha' : 'h:mma',
                          ).toLowerCase()
                        : ''
                    }
                    onChange={(date) => date && handleSetEndAt(date)}
                    value={endAt}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Notes</Typography>
            <Textarea
              name="notes"
              control={control}
              minRows={5}
              placeholder="Reason for unavailability"
              className={classes.textarea}
            />
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default MemberUnavailabilityPeriodCreateFormModal;
