import { Event, useRestoreEventsMutation } from '@timed/gql';
import { useEffect, useState } from 'react';

type EventUncancelProps = {
  onClose: () => void;
  eventId: Event['id'];
};

const EventUncancel = ({ eventId, onClose }: EventUncancelProps) => {
  const [restoreEvents, { data, client }] = useRestoreEventsMutation({
    variables: { input: { ids: [eventId] } },
  });

  const [uncancelled, setUncancelled] = useState<boolean>(false);

  useEffect(() => {
    if (!uncancelled)
      if (data?.restoreEvents) {
        const cache = client.cache;

        cache.modify({
          id: cache.identify({
            __typename: 'Event',
            id: eventId,
          }),
          fields: {
            cancel(_, { DELETE }) {
              return DELETE;
            },
          },
        });

        setUncancelled(true);
        onClose();
      } else {
        restoreEvents();
      }
  }, [uncancelled, data, client.cache, eventId, onClose, restoreEvents]);

  return null;
};

export default EventUncancel;
