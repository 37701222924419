import { _settingsOrganisation } from '@timed/app';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const SettingsOrganisation = () => (
  <Page {..._settingsOrganisation}>
    <Outlet />
  </Page>
);

export default SettingsOrganisation;
