import {
  Address,
  Client,
  EntityState,
  Event,
  EventsWhereInput,
  Member,
  Org,
  PersonNamesFragment,
} from '@timed/gql';
import { createContext, CSSProperties } from 'react';

export type MouseState = 'up' | 'down' | 'drag';

export type EventAttributes =
  | 'no-notes'
  | 'client-not-billable'
  | 'member-not-payable'
  | 'public-holiday'
  | 'not-clocked';

export type SelectedEvent = Pick<
  Event,
  'id' | 'startAt' | 'endAt' | 'passive' | 'passiveStartAt' | 'notes'
> & {
  member?: null | Pick<Member, 'id'>;
  address?: null | Pick<
    Address,
    'street' | 'locality' | 'region' | 'postcode' | 'country' | 'latitude' | 'longitude'
  >;
};

export type SetCellFn = {
  type?: 'down' | 'over';
  value?: number;
};

export type SetEventFn = {
  type?: 'selected' | 'over';
  value?: Pick<SelectedEvent, 'id'> | SelectedEvent;
};

export type Dates = {
  today: Date;
  now: Date;
  from: Date;
  to: Date;
  range: number;
  autoMemberAssign: Date;
};

export type Shift = {
  event: {
    id: Event['id'];
    createdAt: Event['createdAt'];
    startAt: Event['startAt']; // Event start
    endAt: Event['endAt']; // Event end
    duration: number; // Event minutes
    clockedOn?: boolean;
    color: string;
    passive: Event['passive'];
    passiveStartAt?: Event['passiveStartAt'];
    passiveEndAt?: Date;
    passiveDuration?: number;
    activeAssist?: boolean;
    hasNotes: Event['hasNotes'];
    hasFiles: Event['hasFiles'];
    conflictExists?: Event['conflictExists'] | null;
    publicHoliday: Event['publicHoliday'];
    memberAssignedAutomatically: Event['memberAssignedAutomatically'];
    style: CSSProperties;
    title: string;
    cancelled: boolean;
    client: Pick<Client, 'id'> & PersonNamesFragment;
    member?: (Pick<Client, 'id'> & PersonNamesFragment) | null;
  };

  startAt: Event['startAt']; // Shift start
  endAt: Event['endAt']; // Shift end
  duration: number; // Minutes

  width: number;
  height: number;
  leftOffset: number;
  topOffset: number;

  futureOverlaps: number;
  pastOverlaps: number;
  startOfOverlap: boolean;
  endOfOverlap: boolean;
  startOverlapsIndex: number;
};

export type ScheduleContextType = {
  refetch: () => void;
  setFrom: (value: number | Date, redirect?: boolean) => void;
  setRange: (value: number, redirect?: boolean) => void;
  setCell: (props?: SetCellFn) => void;
  setEvent: (props: SetEventFn) => void;
  setMouse: (state: MouseState) => void;
  client: {
    get: () => string | undefined;
    set: (id: string, redirect: boolean) => void;
  };
  member: {
    get: () => string | undefined;
    set: (id: string, redirect: boolean) => void;
  };
  eventStates: EntityState[];
  setEventStates: (states: EntityState[]) => void;
  eventAttributes?: EventsWhereInput;
  setEventAttributes: (where?: EventsWhereInput) => void;
  dates: Dates;
  mouse: MouseState;
  target: {
    event: {
      over?: Event['id'];
      selected?: SelectedEvent;
    };
    cells: {
      down?: number;
      over?: number;
      up?: number;
    };
  };
  lists: {
    shifts?: Array<Array<Shift>>;
  };
  settings: {
    assignMemberToEventWeeksInAdvance?: Org['assignMemberToEventWeeksInAdvance'];
  };
};

const ScheduleContext = createContext<ScheduleContextType>({
  refetch: () => {},
  setFrom: () => {},
  setRange: () => {},
  setCell: () => {},
  setEvent: () => {},
  setMouse: () => {},
  client: { get: () => '', set: () => {} },
  member: { get: () => '', set: () => {} },
  eventStates: [EntityState.NORMAL, EntityState.CANCELLED],
  setEventStates: () => {},
  setEventAttributes: () => {},
  dates: {
    today: new Date(),
    now: new Date(),
    from: new Date(),
    to: new Date(),
    range: 0,
    autoMemberAssign: new Date(),
  },
  mouse: 'up',
  target: { cells: {}, event: {} },
  lists: {
    // employees: undefined,
    // participants: undefined,
  },
  settings: {},
});

export default ScheduleContext;
