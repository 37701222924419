import { ButtonAsync } from '@timed/common';
import { InviteMembersDocument, useInviteMembersMutation } from '@timed/gql';
import { MemberContext } from '@timed/member';
import { differenceInSeconds } from 'date-fns';
import { useContext } from 'react';

const MemberInviteSendButton = () => {
  const member = useContext(MemberContext);

  const [createInvite, response] = useInviteMembersMutation({
    variables: { input: { ids: [member.id] } },
  });

  const handleClick = () => createInvite();

  const onSuccess = () => {
    if (response.data?.inviteMembers.length) {
      const cache = response.client.cache;

      cache.modify({
        fields: {
          getMemberInvite(existing = []) {
            const invite = cache.writeQuery({
              data: response.data,
              query: InviteMembersDocument,
            });
            return [...existing, invite];
          },
        },
      });

      cache.modify({
        id: cache.identify({
          __typename: 'Member',
          id: response.data!.inviteMembers[0].id,
        }),
        fields: {
          invitationsReceived() {
            return response.data!.inviteMembers[0].invitationMostRecentlyReceived;
          },
        },
      });
    }
  };

  return (
    <ButtonAsync
      loading={response.loading}
      success={!!response.data}
      onSuccess={onSuccess}
      onClick={handleClick}
      disabled={
        (!member.email && !member.phone) ||
        // Disabled if invitation was sent within the past 10 seconds
        (member.invitationMostRecentlyReceived?.lastSender.sentAt &&
          differenceInSeconds(
            new Date(),
            new Date(member.invitationMostRecentlyReceived?.lastSender.sentAt),
          ) < 10)
      }
    >
      {member.invitationMostRecentlyReceived && !member.invitationMostRecentlyReceived.deletedAt
        ? 'Resend'
        : 'Send'}
    </ButtonAsync>
  );
};

export default MemberInviteSendButton;
