import { _shiftsAdmin } from '@timed/app/constants/metadata';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const ShiftsAdmin = () => {
  return (
    <Page {..._shiftsAdmin}>
      <Outlet />
    </Page>
  );
};

export default ShiftsAdmin;
