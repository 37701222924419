import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { _schedule } from '@timed/app';
import { formatPersonName, formatTimeDuration, useRouter } from '@timed/common';
import { EntityState, OrderBy, useGetNonClockedEventsLazyQuery } from '@timed/gql';
import { ScheduleContext } from '@timed/schedule';
import { setProfile } from '@timed/schedule/helpers';
import { format, isToday, max, min, startOfWeek } from 'date-fns';
import { isTomorrow } from 'date-fns/esm';
import { useContext, useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    wrapper: {
      padding: theme.spacing(1),
      backgroundColor: blue[50],
      border: '1px solid ' + blue[800],
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      lineHeight: 'initial',
      cursor: 'pointer',
      '& .MuiTypography-root': {
        fontSize: 11,
      },
    },
    top: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    text: {
      '& .MuiTypography-root:first-child': {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    events: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    event: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      '& .MuiTypography-root:first-child': {
        fontSize: 10,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  }),
);

const ScheduleNonClockedEvents = () => {
  const classes = useStyles();

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const [showEvents, setShowEvents] = useState<boolean>(false);

  const {
    dates: { from, to, now },
  } = useContext(ScheduleContext);

  const [getEvents, { data, loading, error }] = useGetNonClockedEventsLazyQuery({
    pollInterval: 60000,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading)
      getEvents({
        variables: {
          input: {
            entityStates: [EntityState.NORMAL],
            where: {
              billable: { _eq: true },
              member: { id: { _ne: null } },
              startAt: { _gte: max([from, new Date('2022-08-29')]), _lt: min([now, to]) },
              _or: [
                { clockedOnAt: { _eq: null } },
                { clockedOffAt: { _eq: null }, endAt: { _lt: min([now, to]) } },
              ],
            },
            orderBy: [{ startAt: OrderBy.ASC }, { duration: OrderBy.ASC }],
          },
        },
      });
  }, [from, to, now, data, loading, getEvents]);

  const handleClickLink = (clientId: string, startAt: Date) => {
    setProfile('client', searchParams, clientId);
    setProfile('member', searchParams, undefined);

    const formattedDate = format(startOfWeek(startAt, { weekStartsOn: 1 }), 'ddMMyyyy');

    if (searchParams.get('f') !== formattedDate) {
      searchParams.set('f', formattedDate);
    }

    navigate(_schedule.path + '?' + searchParams);
  };

  return !data?.events.length ? null : (
    <Box
      className={classes.wrapper}
      onClick={() => {
        setShowEvents(!showEvents);
      }}
    >
      <Box className={classes.top}>
        <ErrorRoundedIcon style={{ fill: blue[800] }} />
        <Box className={classes.text}>
          <Typography variant="body1">Non-clocked Shifts</Typography>
          <Typography variant="body2">{data?.events.length} shifts in this date range</Typography>
        </Box>
      </Box>

      {showEvents && (
        <Box className={classes.events}>
          {data?.events.map((event) => (
            <Box
              className={classes.event}
              onClick={(e) => {
                handleClickLink(event.client.id, new Date(event.startAt));
                e.stopPropagation();
              }}
            >
              <Typography>
                {isToday(new Date(event.startAt))
                  ? 'Today'
                  : isTomorrow(new Date(event.startAt))
                  ? 'Tomorrow'
                  : format(new Date(event.startAt), 'EEEE')}
                {', '}
                {format(new Date(event.startAt), 'd MMM')}
                {', '}
                {formatTimeDuration({
                  start: new Date(event.startAt),
                  end: new Date(event.endAt),
                  options: {
                    militaryTime: true,
                    seperator: '-',
                  },
                })}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatPersonName(event.client, { lastNameFirst: true })}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ScheduleNonClockedEvents;
