import { _shiftsAdminMissingSupportWorker } from '@timed/app/constants/metadata';
import { Page } from '@timed/page';
import { ShiftsMissingSupportWorker } from '@timed/shifts';

const ShiftsAdminMissingSupportWorker = () => (
  <Page {..._shiftsAdminMissingSupportWorker}>
    <ShiftsMissingSupportWorker />
  </Page>
);

export default ShiftsAdminMissingSupportWorker;
