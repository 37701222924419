import {
  _settings,
  _settingsOrganisation,
  _settingsOrganisationMedication,
  _settingsOrganisationPayroll,
  _settingsOrganisationScheduling,
  _settingsOrganisationVisaTypes,
  _settingsPersonal,
  _settingsPersonalAccount,
} from '@timed/app';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const Settings = () => (
  <Page
    {..._settings}
    controls={[
      { label: _settingsPersonal.label },
      _settingsPersonalAccount,
      // _settingsPersonalAppearance,
      // _settingsPersonalNotifications,
      // _settingsPersonalSecurity,
      { label: _settingsOrganisation.label },
      _settingsOrganisationScheduling,
      _settingsOrganisationVisaTypes,
      _settingsOrganisationPayroll,
      _settingsOrganisationMedication,
      // _settingsOrganisationAccess,
      // _settingsOrganisationBilling,
      // _settingsOrganisationExports,
      // _settingsOrganisationOverview,
    ]}
  >
    <Outlet />
  </Page>
);

export default Settings;
