import { Snackbar } from '@timed/common';
import { AlertContext, AlertContextType, AlertPushFn } from '@timed/alert';
import { useState } from 'react';

const AlertProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AlertPushFn>();

  const push: AlertContextType['push'] = ({ message, severity }) => setData({ message, severity });

  return (
    <AlertContext.Provider
      value={{
        push,
      }}
    >
      <Snackbar open={!!data} severity={data?.severity}>
        {data?.message}
      </Snackbar>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
