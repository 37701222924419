import { Box, createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useAuth } from '@timed/auth';
import { Block, formatPersonName, Modal, ModalProps } from '@timed/common';
import {
  Event,
  HistoryType,
  Member,
  OrderBy,
  useGetEventCreatorLazyQuery,
  useGetEventHistoriesLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { format } from 'date-fns';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

type EventHistoryModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  eventId: Event['id'];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      overflow: 'auto',
      maxHeight: '500px',
      [theme.breakpoints.up('md')]: {
        width: 800,
        // maxWidth: 500,
        gap: theme.spacing(4),
      },
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    error: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.error.main,
      border: '1px solid ' + theme.palette.error.main,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      '& .MuiTypography-root': {
        fontSize: 13,
      },
    },
    items: {
      display: 'grid',
      gridTemplateColumns: 'auto max-content max-content max-content',
      gap: theme.spacing(4),
    },
    item: {},
  }),
);

const EventHistoryModal = ({ onClose, eventId, ...modalProps }: EventHistoryModalProps) => {
  const classes = useStyles();

  const { permissible } = useAuth();

  const [errorText, setErrorText] = useState<string | null>(null);

  const [getHistories, { data, loading, error }] = useGetEventHistoriesLazyQuery({
    variables: {
      input: {
        where: { event: { id: { _eq: eventId } } },
        orderBy: [{ date: OrderBy.DESC }],
        limit: 100,
      },
    },
  });

  console.log(data);

  const [getEventCreator, eventCreatorResponse] = useGetEventCreatorLazyQuery({
    variables: { id: eventId },
  });

  const isMember = (e?: any): e is Member => !!e && e.hasOwnProperty('firstName');

  const hasPermission = useMemo(() => {
    return permissible({ admin: true });
  }, [permissible]);

  useLoadingEffect(loading);

  // Fetch histories
  useEffect(() => {
    if (hasPermission) {
      if (!data && !loading) getHistories();
      if (!eventCreatorResponse.data && !eventCreatorResponse.loading) getEventCreator();
    }
  }, [
    hasPermission,
    getHistories,
    data,
    loading,
    getEventCreator,
    eventCreatorResponse.data,
    eventCreatorResponse.loading,
  ]);

  return (
    <Modal disablePortal onClose={onClose} {...modalProps}>
      <Block
        titleProps={{ variant: 'h3' }}
        title="Shift Update History"
        topRight={
          <Box className={classes.controls}>
            <IconButton onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        }
      >
        <Box className={classes.wrapper}>
          {!!errorText && (
            <Box className={classes.error}>
              <Typography>{errorText}</Typography>
            </Box>
          )}
          <Box className={classes.items}>
            <Typography className={classes.bold}></Typography>
            <Typography className={classes.bold}>Property</Typography>
            <Typography className={classes.bold}>Old Value</Typography>
            <Typography className={classes.bold}>New Value</Typography>
            {data?.histories
              ?.filter((history) => history.__typename !== 'HistoryRestorable')
              .map((history) => {
                let fieldName: string = '';
                let newValue: any = null;
                let oldValue: any = null;

                if (history.type === HistoryType.UPDATE) {
                  const values = Object.entries(
                    _.omit(history, ['__typename', 'id', 'date', 'actor', 'type', 'event']),
                  );

                  fieldName = history.__typename!.slice(12); // values[0][0].slice(5, -3);

                  if (!!values[0] && !!values[0][1]) newValue = values[0][1];
                  if (!!values[1] && !!values[1][1]) oldValue = values[1][1];
                }

                return (
                  <>
                    <Box>
                      <Typography variant="body1">
                        {format(new Date(history.date), 'dd/MM/yyyy HH:mm:ss')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {!!history.actor ? (
                          formatPersonName(history.actor)
                        ) : (
                          <span style={{ color: 'blue' }}>System</span>
                        )}
                      </Typography>
                    </Box>

                    <Typography>{fieldName}</Typography>
                    <Typography>
                      {!!oldValue
                        ? isMember(oldValue)
                          ? formatPersonName(oldValue)
                          : oldValue
                        : '-'}
                    </Typography>
                    <Typography>
                      {!!newValue
                        ? isMember(newValue)
                          ? formatPersonName(newValue)
                          : newValue
                        : '-'}
                    </Typography>
                  </>
                );
              })}
          </Box>
          {!!eventCreatorResponse.data && (
            <Typography>
              Created by {formatPersonName(eventCreatorResponse.data.eventById.createdBy)} at{' '}
              {format(new Date(eventCreatorResponse.data.eventById.createdAt), 'HH:mm dd/MM/yyyy')}.
            </Typography>
          )}
        </Box>
      </Block>
    </Modal>
  );
};

export default EventHistoryModal;
