import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { AuthContext } from '@timed/auth';
import { EventCreateFormModal } from '@timed/event';
import { Permission } from '@timed/gql';
import { ScheduleContext } from '@timed/schedule/context';
import { addMinutes } from 'date-fns';
import { useModal } from 'mui-modal-provider';
import { useContext, useMemo } from 'react';

type ScheduleCalendarCellProps = {
  date: Date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hovered: { backgroundColor: 'rgba(0,0,0,0.15) !important' },
  }),
);

const ScheduleCalendarCell = ({ date }: ScheduleCalendarCellProps) => {
  const classes = useStyles();

  const { permissible } = useContext(AuthContext);

  const {
    setCell,
    setMouse,
    client,
    member,
    target: { cells },
  } = useContext(ScheduleContext);

  const { showModal } = useModal();

  const className: string | undefined = useMemo(() => {
    const timestamp = date.getTime();
    return cells.down &&
      cells.over &&
      timestamp <= Math.max(cells.down, cells.over) &&
      timestamp >= Math.min(cells.down, cells.over)
      ? classes.hovered
      : undefined;
  }, [cells, date, classes.hovered]);

  const handleOpenModal = () => {
    const modal: { hide: () => void } = showModal(EventCreateFormModal, {
      client,
      member,
      startAt: new Date(Math.min(cells.down!, cells.over!)),
      endAt: addMinutes(new Date(Math.max(cells.down!, cells.over!)), 30),
      onClose: () => {
        setCell();
        modal.hide();
      },
    });
  };

  const handleMouseEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    switch (event.type) {
      case 'mousedown':
        setMouse('down');
        setCell({
          type: 'down',
          value: parseInt(event.currentTarget.getAttribute('data-date')!),
        });
        break;
      case 'mouseup':
        setMouse('up');
        // if (cells.down && cells.over && participants) {
        if (cells.down && cells.over) {
          handleOpenModal();
        }
        break;
      case 'mouseenter':
        if (cells.down) {
          setCell({
            type: 'over',
            value: parseInt(event.currentTarget.getAttribute('data-date')!),
          });
        }
        break;
    }
  };

  return permissible({ permissions: Permission.EVENT_WRITE }) ? (
    <div
      data-date={date.getTime()}
      className={className}
      onMouseDown={handleMouseEvent}
      onMouseUp={handleMouseEvent}
      onMouseEnter={handleMouseEvent}
      // onDragOver={handleMouse}
    ></div>
  ) : (
    <div className={className}></div>
  );
};

export default ScheduleCalendarCell;
