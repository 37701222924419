import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { ButtonAsync, TextField, validateRequired } from '@timed/common';
import {
  CreateMedicationDocument,
  OrderBy,
  useCreateMedicationMutation,
  useGetMedicationsQuery,
} from '@timed/gql';
import { useForm } from 'react-hook-form';

type FormData = { name: string };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      gap: theme.spacing(4),
    },
    form: {
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + theme.palette.divider,
      padding: theme.spacing(4),
    },
    medications: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    medication: {
      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + theme.palette.divider,
      padding: theme.spacing(2),
    },
  }),
);

const SettingsOrganisationMedicationForm = () => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>();

  const {
    data,
    loading,
    error,
    client: { cache },
  } = useGetMedicationsQuery({
    variables: {
      input: {
        orderBy: [{ name: OrderBy.ASC }],
      },
    },
  });

  const [createMedication, createResponse] = useCreateMedicationMutation();

  const onSubmit = (input: FormData) => {
    createMedication({ variables: { input } }).catch((e) => {});
  };

  const onSuccess = () => {
    cache.modify({
      fields: {
        medications(existing = []) {
          return [
            ...existing,
            cache.writeQuery({ data: createResponse.data, query: CreateMedicationDocument }),
          ];
        },
      },
    });
  };

  if (loading) return <Typography>Loading</Typography>;

  if (!!error) return <Typography>An error occured while fetching medication</Typography>;

  return (
    <Box className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Typography variant="h3" className={classes.bold}>
          Add Medication
        </Typography>
        <TextField
          required
          control={control}
          name="name"
          label="Name"
          variant="outlined"
          size="small"
          error={!!errors.name}
          helperText={errors.name?.message}
          validation={validateRequired}
        />
        <ButtonAsync
          type="submit"
          variant="contained"
          color="primary"
          loading={createResponse.loading}
          success={!!createResponse.data}
          onSuccess={onSuccess}
        >
          Save
        </ButtonAsync>
      </form>
      <Typography className={classes.bold} variant="h3">
        Medications
      </Typography>
      <Box className={classes.medications}>
        {data?.medications.map((med) => (
          <Box className={classes.medication}>{med.name}</Box>
        ))}
      </Box>
    </Box>
  );
};

export default SettingsOrganisationMedicationForm;
