import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useAuth } from '@timed/auth';
import {
  addServerErrors,
  DateInput,
  FormModal,
  ModalProps,
  TextField,
  TimeInput,
} from '@timed/common';
import {
  Client,
  CreateClientObservationDocument,
  Event,
  Member,
  OrderBy,
  Permission,
  QueryByIdsInput,
  useCreateClientObservationMutation,
  useCreateClientObservationWithEventMutation,
  useGetClientMedicationsLazyQuery,
  useGetRedactedClientMedicationsLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import clsx from 'clsx';
import { isAfter, isBefore, isValid, max, subMinutes } from 'date-fns';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

type ClientCreateMedicationFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  clientId: Client['id'];
  event: Pick<Event, 'id' | 'startAt' | 'endAt'> & { member?: Pick<Member, 'id'> | null };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    items: {
      display: 'grid',
      gridTemplateColumns: 'max-content max-content',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(4),
      alignItems: 'center',
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 60,
      },
    },
    dateInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 120,
      },
    },
    notesInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 248,
      },
    },
    medications: {
      gridColumn: 'span 2',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    medication: {
      cursor: 'pointer',
      padding: theme.spacing(1),
      backgroundColor: 'white',
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
    },
    selectedMedication: {
      border: '1px solid ' + green[500],
      backgroundColor: green[50],
      color: green[800],
    },
    medicationName: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
    },
  }),
);

type FormData = {
  date?: Date;
  time?: Date | null;
  notes?: string | null;
  nursesName?: string | null;
  medications?: QueryByIdsInput;
  member?: { id: string } | null;
  medicationQuantities: { [x: string]: string };
};

const ClientCreateMedicationFormModal = ({
  onClose,
  clientId,
  event,
  ...modalProps
}: ClientCreateMedicationFormModalProps) => {
  const classes = useStyles();

  const [selectedMedications, setSelectedMedications] = useState<string[]>([]);

  const { permissible } = useAuth();

  // const medications = useGetClientMedicationsQuery({
  //   variables: {
  //     id: clientId,
  //     input: {
  //       orderBy: [{ medication: { name: OrderBy.ASC } }],
  //     },
  //   },
  // });

  // const medications = useGetClientMedicationsQuery({
  //   variables: {
  //     id: clientId,
  //     input: {
  //       orderBy: [{ medication: { name: OrderBy.ASC } }],
  //     },
  //   },
  // });

  const [getMedications, medicationsResponse] = useGetClientMedicationsLazyQuery({
    variables: {
      id: clientId,
      input: {
        orderBy: [{ medication: { name: OrderBy.ASC } }],
      },
    },
  });

  const [getRedactedMedications, redactedMedicationsResponse] =
    useGetRedactedClientMedicationsLazyQuery({
      variables: {
        id: clientId,
        input: {
          orderBy: [{ medication: { name: OrderBy.ASC } }],
        },
      },
    });

  const canFetchNonRedactedClients = useMemo(() => {
    return permissible({ permissions: [Permission.CLIENT_READ] });
  }, [permissible]);

  const data = canFetchNonRedactedClients
    ? medicationsResponse.data?.clientById
    : redactedMedicationsResponse.data?.redactedClientById;

  const loading = canFetchNonRedactedClients
    ? medicationsResponse.loading
    : redactedMedicationsResponse.loading;

  useEffect(() => {
    if (!data) canFetchNonRedactedClients ? getMedications() : getRedactedMedications();
  }, [data, canFetchNonRedactedClients, getMedications, getRedactedMedications]);

  const [createObservation, responseWithoutEvent] = useCreateClientObservationMutation();
  const [createObservationWithEvent, responseWithEvent] =
    useCreateClientObservationWithEventMutation();

  useLoadingEffect(loading);

  const now: Date = useMemo(() => new Date(), []);
  let date: Date = max([subMinutes(now, 10), new Date(event.startAt)]);

  // Recording obs before event has started
  if (!!event && isBefore(now, new Date(event.startAt))) {
    date = new Date(event.startAt);
  }

  // Recording obs after event has finished
  if (!!event && isAfter(now, new Date(event.endAt))) {
    date = new Date(event.endAt);
  }

  const {
    control,
    handleSubmit,
    setError,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      date,
      time: date,
      notes: null,
      nursesName: null,
      member: event?.member ? { id: event?.member?.id } : null,
    },
  });

  const response = useMemo(
    () => (!!event ? responseWithEvent : responseWithoutEvent),
    [event, responseWithoutEvent, responseWithEvent],
  );

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientObservations(existing = []) {
          return [
            ...existing,
            cache.writeQuery({ data: response.data, query: CreateClientObservationDocument }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async ({ medicationQuantities, ...values }: FormData) => {
    if (!values.time || !isValid(values.time)) setError('time', { message: 'Invalid time' });
    else {
      values = _.omit(values, ['time']);

      if (event)
        createObservationWithEvent({
          variables: {
            input: {
              ...values,
              event: { id: event.id },
              givenMedications: {
                objects: Object.entries(medicationQuantities)
                  .filter(([id]) => selectedMedications.includes(id))
                  .map(([id, quantity]) => ({ quantity, medication: { id } })),
              },
            },
          },
        });
      else
        createObservation({
          variables: {
            input: {
              ...values,
              client: { id: clientId! },
              givenMedications: {
                objects: Object.entries(medicationQuantities)
                  .filter(([id]) => selectedMedications.includes(id))
                  .map(([id, quantity]) => ({ quantity, medication: { id } })),
              },
            },
          },
        });
    }
  };

  const handleChangeTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('date')!);
        currentDate.setHours(date.getHours(), date.getMinutes());

        setValue('date', currentDate);
      }
    },
    [getValues, setValue],
  );

  return (
    <FormModal
      modalProps={modalProps}
      title="Record Medication"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
      saveText="Save"
      disabled={!data?.medications?.length}
    >
      <Box className={classes.wrapper}>
        {!data?.medications?.length ? (
          'This participant has not been linked with any medication.'
        ) : (
          <Box className={classes.items}>
            <Typography variant="body1">Time</Typography>
            <Box className={classes.dateInput}>
              <DateInput
                required
                name="date"
                control={control}
                inputVariant="outlined"
                size="small"
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.date}
                helperText={errors.date?.message}
              />
              <TimeInput
                required
                keyboard
                name="time"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={(date) => {
                  if (isValid(date)) handleChangeTime(date);
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.time}
                helperText={errors.time?.message}
              />
            </Box>

            <Typography variant="body1">Nurse Name</Typography>
            <Box className={classes.notesInput}>
              <TextField
                name="nursesName"
                variant="outlined"
                size="small"
                control={control}
                error={!!errors.nursesName}
                helperText={errors.nursesName?.message}
              ></TextField>
            </Box>

            <Typography variant="body1">Notes</Typography>
            <Box className={classes.notesInput}>
              <TextField
                multiline
                minRows={2}
                name="notes"
                variant="outlined"
                size="small"
                control={control}
                error={!!errors.notes}
                helperText={errors.notes?.message}
              ></TextField>
            </Box>

            {loading && <Typography>Loading medications</Typography>}
            {!!data && (
              <>
                <Box className={classes.medications}>
                  {data.medications?.map(({ medication }) => (
                    <Box
                      className={
                        selectedMedications.includes(medication.id)
                          ? clsx(classes.medication, classes.selectedMedication)
                          : classes.medication
                      }
                      onClick={() => {
                        selectedMedications.includes(medication.id)
                          ? setSelectedMedications(
                              selectedMedications.filter((id) => id !== medication.id),
                            )
                          : setSelectedMedications([...selectedMedications, medication.id]);
                      }}
                    >
                      <Box>
                        <Box className={classes.medicationName}>
                          {selectedMedications.includes(medication.id) && (
                            <CheckRoundedIcon style={{ fill: green[800] }} />
                          )}
                          <Typography>{medication.name}</Typography>
                        </Box>
                        {selectedMedications.includes(medication.id) && (
                          <TextField
                            name={`medicationQuantities.${medication.id}`}
                            onClick={(event) => event.stopPropagation()}
                            control={control}
                            label="Quantity"
                            size="small"
                          />
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </FormModal>
  );
};

export default ClientCreateMedicationFormModal;
