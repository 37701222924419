import { Theme } from '@material-ui/core/styles';
import { DeepPartial } from 'react-hook-form';

export const paletteDark: DeepPartial<Theme['palette']> = {
  type: 'dark',
  avatar: {
    main: '#af52bf',
    text: '#fafafa',
  },
  logo: {
    src: 'icon.svg',
  },
  topBar: {
    text: '#fafafa',
    main: '#1c1c1c',
    dark: '#121212',
    light: '#262626',
    hoverBackground: 'rgba(255,255,255,0.05)',
    activeBackground: 'rgba(255,255,255,0.1)',
  },
  drawer: {
    sideMenu: '#0B0B0B',
    hoverBackground: 'rgba(255,255,255,0.03)',
    activeBackground: 'rgba(255,255,255,0.06)',
  },
  primary: {
    main: '#af52bf',
    dark: '#9c27b0',
    light: '#b660c4',
  },
  secondary: {
    main: '#af52bf',
    dark: '#9c27b0',
    light: '#b660c4',
  },
  error: {
    main: 'rgb(231, 76, 60)',
  },
  warning: {
    main: 'rgb(230, 126, 34)',
  },
  info: {
    main: 'rgb(26, 188, 156)',
  },
  success: {
    main: 'rgb(46, 204, 113)',
  },
  background: {
    default: '#121212',
    paper: '#1c1c1c',
  },
  text: {
    primary: '#ecf0f1',
    secondary: '#bdc3c7',
    disabled: 'rgba(255,255,255,0.3)',
  },
  action: {
    active: '#af52bf',
  },
  divider: 'rgba(255,255,255,0.12)',
};
