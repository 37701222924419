import { Container, createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import { OrgCreateform, OrgJoinForm } from '@timed/org';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '128px auto',
      display: 'flex',
      justifyContent: 'center',
      gap: 128,
      alignItems: 'center',
    },
    paper: {
      width: 350,
      padding: theme.spacing(2),
      color: theme.palette.grey[800],
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      boxShadow: 'none',
      borderRadius: 4,
      border: '1px solid ' + theme.palette.grey[400],
    },
  }),
);

const Setup = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <OrgJoinForm />
      </Paper>
      <Paper className={classes.paper}>
        <OrgCreateform />
      </Paper>
    </Container>
  );
};

export default Setup;
