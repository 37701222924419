import { Button } from '@timed/common';
import { MemberContext, MemberFileCreateFormModal } from '@timed/member';
import { useModal } from 'mui-modal-provider';
import React, { useContext } from 'react';

const MemberFileUploadButton = () => {
  const { showModal } = useModal();
  const member = useContext(MemberContext);

  const handleOpenModal = () => {
    const modal: { hide: () => void } = showModal(MemberFileCreateFormModal, {
      onClose: () => modal.hide(),
      member,
    });
  };

  return (
    <Button variant="contained" color="primary" onClick={handleOpenModal}>
      Upload
    </Button>
  );
};

export default MemberFileUploadButton;
