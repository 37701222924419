import { useMediaQuery, useTheme } from '@material-ui/core';
import { _schedule } from '@timed/app';
import { Crumb } from '@timed/app-bar';
import { Protected } from '@timed/auth';
import { DrawerControl } from '@timed/drawer';
import { Permission } from '@timed/gql';
import { Page } from '@timed/page';
import {
  ScheduleAttributesSelect,
  ScheduleCalendar,
  ScheduleClientInput,
  ScheduleConflictingEvents,
  ScheduleDateInput,
  ScheduleDateRangeInput,
  ScheduleMemberHours,
  ScheduleMemberInput,
  ScheduleStatesSelect,
  ScheduleUnfilledEvents,
} from '@timed/schedule';
import ScheduleNonClockedEvents from '@timed/schedule/components/NonClockedEvents/NonClockedEvents';

const Schedule = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const crumbs: Crumb[] = smDown
    ? [
        <>
          <ScheduleDateInput />
          <ScheduleDateRangeInput />
        </>,
      ]
    : [<ScheduleDateInput />, <ScheduleDateRangeInput />];

  const controls: DrawerControl[] = [
    <Protected permissions={Permission.EVENT_WRITE}>
      <ScheduleConflictingEvents />
      <ScheduleUnfilledEvents />
      <ScheduleNonClockedEvents />
    </Protected>,
    // { label: 'Filters' },
    // <Typography variant="body2">
    //   Select at least one profile to view. Refine scope by optionally specifying both participant
    //   and employee.
    // </Typography>,
    <ScheduleClientInput />,
    <Protected permissions={Permission.MEMBER_READ}>
      <ScheduleMemberInput />
    </Protected>,
    <Protected admin>
      <ScheduleStatesSelect />
      <ScheduleAttributesSelect />
    </Protected>,
    <Protected permissions={Permission.EVENT_WRITE}>
      <ScheduleMemberHours />
    </Protected>,
  ];

  return (
    <Page {..._schedule} controls={controls} crumbs={{ after: crumbs }}>
      <ScheduleCalendar />
    </Page>
  );
};

export default Schedule;
