import { Box } from '@material-ui/core';
import { _notifications } from '@timed/app';
import { NotificationContext } from '@timed/notifications';
import { Page } from '@timed/page';
import { useContext } from 'react';

const Schedule = () => {
  const { totalCount: count, notifications } = useContext(NotificationContext);

  return (
    <Page {..._notifications}>
      <Box>Count: {count}</Box>
      <Box>Notifications: {notifications.map((n) => n.message)}</Box>
    </Page>
  );
};

export default Schedule;
