import { _shifts } from '@timed/app';
import {
  _shiftsAdmin,
  _shiftsAdminApprove,
  _shiftsAdminMissingNotes,
  _shiftsAdminMissingSupportWorker,
  _shiftsPersonal,
  _shiftsPersonalClaim,
  _shiftsPersonalUnavailability,
  _shiftsPersonalUpcoming,
} from '@timed/app/constants/metadata';
import { useAuth } from '@timed/auth';
import { Permission } from '@timed/gql';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const Shifts = () => {
  const { permissible } = useAuth();

  let controls = [
    { label: permissible({ admin: true }) ? _shiftsPersonal.label : _shifts.label },
    _shiftsPersonalUpcoming,
    _shiftsPersonalClaim,
    _shiftsPersonalUnavailability,
  ];

  if (permissible({ permissions: Permission.EVENT_READ }))
    controls = controls.concat([{ label: _shiftsAdmin.label }, _shiftsAdminMissingSupportWorker]);

  if (permissible({ admin: true }))
    controls = controls.concat([_shiftsAdminMissingNotes, _shiftsAdminApprove]);

  return (
    <Page {..._shifts} controls={controls}>
      <Outlet />
    </Page>
  );
};

export default Shifts;
