import { Select, SelectProps } from '@timed/common';
import { ReportModule } from '@timed/report';

const ReportModuleInput = (props: Omit<SelectProps, 'items'>) => {
  return (
    <Select
      label="Report Type"
      items={Object.values(ReportModule).map((type) => {
        return {
          value: type,
          label: type[0] + type.toLowerCase().slice(1).replace(/_/g, ' '),
        };
      })}
      {...props}
    />
  );
};

export default ReportModuleInput;
