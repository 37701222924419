import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Link, useRouter } from '@timed/common';
import { useAuth } from '@timed/auth';
import { isControlAnElement } from '@timed/drawer';
import { usePage } from '@timed/page';
import clsx from 'clsx';
import { cloneElement } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flexGrow: 1,
      maxWidth: theme.spacing(75),
      padding: theme.spacing(4),
      display: 'grid',
      flexDirection: 'column',
      gridAutoRows: 'min-content',
      gap: theme.spacing(4),
      backgroundColor: theme.palette.drawer.sideMenu,
      lineHeight: theme.spacing(12) + 'px',
      overflowX: 'hidden',
    },
    title: {
      lineHeight: 'inherit',
      padding: theme.spacing(0, 4),
      fontWeight: theme.typography.fontWeightMedium,
    },
    typography: {
      display: 'flex',
      lineHeight: 'inherit',
    },
    link: {
      flex: '1 1 auto',
      padding: theme.spacing(0, 4),
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: theme.palette.drawer.hoverBackground,
        textDecoration: 'none',
      },
      '&:active': {
        backgroundColor: theme.palette.drawer.activeBackground,
        textDecoration: 'none',
        color: theme.palette.text.primary,
      },
    },
    active: {
      backgroundColor: theme.palette.drawer.activeBackground,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.drawer.activeBackground,
      },
    },
  }),
);

const DrawerControlPanel = () => {
  const { toggleDrawer, controls } = usePage();
  const { pathname } = useRouter();
  const classes = useStyles();
  const { permissible } = useAuth();

  return (
    <Box className={classes.wrapper}>
      {controls?.map((item, index) => {
        if (isControlAnElement(item)) return cloneElement(item, { key: index });

        if (!item.path) {
          return (
            <Typography key={index} variant="body1" color="textPrimary" className={classes.title}>
              {item.label}
            </Typography>
          );
        }

        if (!permissible({ admin: item.admin, permissions: item.permissions })) return null;

        return (
          <Typography
            key={index}
            variant="body1"
            color="textPrimary"
            className={classes.typography}
            onClick={toggleDrawer(false)}
          >
            <Link
              to={item.path}
              className={
                pathname.includes(item.path, 0) ? clsx(classes.link, classes.active) : classes.link
              }
            >
              {item.label}
            </Link>
          </Typography>
        );
      })}
    </Box>
  );
};

export default DrawerControlPanel;
