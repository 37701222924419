import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Button, formatPersonName, formatTimeDuration, Snackbar } from '@timed/common';
import { useClaimEventMutation, useGetClaimableShiftsLazyQuery } from '@timed/gql';
import { differenceInMinutes, format } from 'date-fns';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    title: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    shifts: {
      display: 'grid',

      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
        gridTemplateColumns: 'auto',
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
        gridTemplateColumns: 'repeat(7, auto)',
      },
    },
    shift: {
      display: 'grid',
      gridTemplateColumns: '48px auto',
      // gap: theme.spacing(4),
      // width: 'max-content',
      // padding: theme.spacing(4),

      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + theme.palette.divider,
    },
    date: {
      padding: theme.spacing(1.5),
      backgroundColor: grey[500],
      color: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0),
      alignItems: 'center',
      justifyItems: 'start',
      '& .MuiTypography-root': {
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& .MuiTypography-root:first-child': {
        fontSize: 24,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    info: {
      padding: theme.spacing(1.5),
      backgroundColor: grey[100],
    },
  }),
);

const ShiftsClaim = () => {
  const classes = useStyles();

  const [getEvents, { data, loading }] = useGetClaimableShiftsLazyQuery();

  const [claimEvent, claimEventResponse] = useClaimEventMutation();

  // Fetch events
  useEffect(() => {
    if (!data && !loading) getEvents();
  }, [getEvents, data, loading]);

  return loading ? (
    <Typography>Loading...</Typography>
  ) : (
    <Box className={classes.wrapper}>
      <Snackbar
        open={!!claimEventResponse.data}
        severity={claimEventResponse.data?.claimEvent.value ? 'success' : 'error'}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {claimEventResponse.data?.claimEvent.value
          ? 'Request received.'
          : 'Could not claim this shift at this time.'}
      </Snackbar>
      <Typography>Please only click a single "REQUEST" button once.</Typography>
      <Box className={classes.shifts}>
        {!data?.eventsClaimable.length ? (
          <Typography variant="body1">There are no claimable shifts at this time.</Typography>
        ) : (
          <>
            {/* <Typography variant="body1">
              If you are available to attend any of the following shifts please contact{' '}
              {auth.org?.schedulingContactPerson ? (
                <>
                  {formatPersonName(auth.org.schedulingContactPerson)}
                  {!!auth.org.schedulingContactPerson.phone ? (
                    <>
                      {' '}
                      on{' '}
                      <Link
                        href={'tel:' + auth.org.schedulingContactPerson.phone!.replace(/\s+/g, '')}
                      >
                        {auth.org.schedulingContactPerson?.phone}
                      </Link>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ' your employer'
              )}
              .
            </Typography> */}
            {data?.eventsClaimable.map((event) => {
              return (
                <Box className={classes.shift}>
                  <Box className={classes.date}>
                    <Typography>{format(new Date(event.startAt), 'd')}</Typography>
                    <Typography>{format(new Date(event.startAt), 'MMM')}</Typography>
                    <Typography>{format(new Date(event.startAt), 'EEE')}</Typography>
                  </Box>
                  <Box className={classes.info}>
                    <Typography variant="body2">
                      {formatTimeDuration({
                        start: new Date(event.startAt),
                        end: new Date(event.endAt),
                        options: { militaryTime: true },
                      })}{' '}
                      ({differenceInMinutes(new Date(event.endAt), new Date(event.startAt)) / 60}{' '}
                      hours)
                    </Typography>
                    <Typography variant="body2">
                      {formatPersonName(event.client)} • {event.locality + ' ' + event.region}
                    </Typography>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => claimEvent({ variables: { input: { id: event.id } } })}
                    >
                      Request
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ShiftsClaim;
