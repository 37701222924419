import {
  _people,
  _peopleSupportCoordinators,
  _peoplePlanManagers,
  _peopleMembers,
  _peopleClients,
} from '@timed/app';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const People = () => (
  <Page
    {..._people}
    controls={[
      { label: _people.label },
      _peopleMembers,
      _peopleClients,
      _peoplePlanManagers,
      _peopleSupportCoordinators,
    ]}
  >
    <Outlet />
  </Page>
);

export default People;
