import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  ButtonAsync,
  Checkbox,
  IconButton,
  TextField,
  transformNumberToFixedFloat,
  validateRequired,
} from '@timed/common';
import {
  CreateVisaTypeDocument,
  GetVisaTypesQuery,
  OrderBy,
  useCreateVisaTypeMutation,
  useGetVisaTypesQuery,
  VisaType,
} from '@timed/gql';
import { VisaTypeDeleteFormModal, VisaTypeUpdateFormModal } from '@timed/visa-type';
import { useModal } from 'mui-modal-provider';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type FormData = Pick<
  VisaType,
  'name' | 'description' | 'allowedFortnightlyWorkHours' | 'allowedWorkHoursWaived'
>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + theme.palette.divider,
      padding: theme.spacing(4),
    },
    visas: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      alignItems: 'start',
    },
    visa: {
      padding: theme.spacing(2),
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      gap: theme.spacing(4),
    },
    values: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    controls: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  }),
);

const SettingsOrganisationVisaTypesForm = () => {
  const classes = useStyles();

  const { showModal } = useModal();

  const { handleSubmit, control } = useForm<FormData>({
    defaultValues: { allowedFortnightlyWorkHours: 76 },
  });

  const {
    data,
    loading,
    client: { cache },
  } = useGetVisaTypesQuery({
    variables: { input: { orderBy: [{ name: OrderBy.ASC }] } },
  });

  const [createVisaType, createResponse] = useCreateVisaTypeMutation();

  const onSubmit = (input: FormData) => {
    createVisaType({ variables: { input } }).catch((e) => {});
  };

  /**
   * Add newly created VISA type to the cache
   */
  useEffect(() => {
    if (createResponse.data && !createResponse.loading) {
      cache.modify({
        fields: {
          visaTypes(existing = []) {
            return [
              ...existing,
              cache.writeQuery({ data: createResponse.data, query: CreateVisaTypeDocument }),
            ];
          },
        },
      });
    }
  }, [createResponse.data, createResponse.loading, cache]);

  const handleOpenUpdateModal = (visaType: GetVisaTypesQuery['visaTypes'][0]) => {
    const modal: { hide: () => void } = showModal(VisaTypeUpdateFormModal, {
      onClose: () => {
        modal.hide();
      },
      visaType,
    });
  };

  const handleOpenDeleteModal = (visaTypeId: VisaType['id']) => {
    const modal: { hide: () => void } = showModal(VisaTypeDeleteFormModal, {
      onClose: () => {
        modal.hide();
      },
      visaTypeId,
    });
  };

  if (loading) return <Typography>Loading</Typography>;

  if (!data) return <Typography>An error occured while fetching VISAs</Typography>;

  return (
    <Box className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Typography variant="h3" className={classes.bold}>
          Create VISA Type
        </Typography>
        <TextField
          required
          control={control}
          name="name"
          label="Name of VISA"
          variant="outlined"
          size="small"
          validation={validateRequired}
        />
        <TextField
          control={control}
          name="description"
          label="Description"
          variant="outlined"
          size="small"
        />
        <TextField
          required
          control={control}
          name="allowedFortnightlyWorkHours"
          label="Allowed fortnightly work hours"
          type="number"
          inputProps={{ min: 0, max: 336, step: 1 }}
          transform={transformNumberToFixedFloat(0)}
          onClick={(event) => {
            (event.target as HTMLInputElement).select();
          }}
          variant="outlined"
          size="small"
          validation={validateRequired}
        />
        <Checkbox
          control={control}
          name="allowedWorkHoursWaived"
          defaultChecked={false}
          label="Waive allowed work hours"
        />
        <ButtonAsync
          type="submit"
          variant="contained"
          color="primary"
          loading={createResponse.loading}
          success={!!createResponse.data}
        >
          Create
        </ButtonAsync>
      </form>
      <Typography variant="h3" className={classes.bold}>
        Current VISA Types
      </Typography>
      <Box className={classes.visas}>
        {data.visaTypes.map((visaType) => (
          <Box className={classes.visa}>
            <Box className={classes.values}>
              <Typography variant="body1" className={classes.bold}>
                {visaType.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {visaType.description}
              </Typography>
              {!!visaType.allowedFortnightlyWorkHours && (
                <Typography variant="body2" color="textSecondary">
                  {visaType.allowedFortnightlyWorkHours} hours / fortnight
                  {visaType.allowedWorkHoursWaived && (
                    <span style={{ color: 'red' }}> (waived)</span>
                  )}
                </Typography>
              )}
            </Box>
            <Box className={classes.controls}>
              <IconButton
                onClick={() => {
                  handleOpenUpdateModal(visaType);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleOpenDeleteModal(visaType.id);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SettingsOrganisationVisaTypesForm;
