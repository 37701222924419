import { PayrollCategory } from '@timed/report';
import {
  addDays,
  differenceInMinutes,
  eachDayOfInterval,
  isAfter,
  isBefore,
  isEqual,
  isSaturday,
  isSunday,
  isWithinInterval,
  set,
  startOfDay,
} from 'date-fns';

type CalculatePayrollActivityFn = {
  eventStart: Date;
  eventEnd: Date;
  shiftStart: Date;
  shiftEnd: Date;
  passive?: boolean;
  publicHoliday?: boolean;
  memberBonusEligible: boolean;
};

export const calculatePayrollCategory = ({
  eventEnd,
  eventStart,
  shiftEnd,
  shiftStart,
  passive,
  publicHoliday,
  memberBonusEligible,
}: CalculatePayrollActivityFn): PayrollCategory => {
  // Passive shift
  if (passive) return 'overnight allowance 2';

  let category: 'WKD' | 'AFT' | 'NHT' | 'SAT' | 'SUN' | 'PUB' | undefined;

  const datesInEvent = eachDayOfInterval({ start: eventStart, end: eventEnd }).filter(
    (d) => !isEqual(eventEnd, d),
  );

  // Public holiday
  if (publicHoliday) category = 'PUB';
  // Sunday
  else if (datesInEvent.some((d) => isSunday(d))) category = 'SUN';
  // Saturday
  else if (datesInEvent.some((d) => isSaturday(d))) category = 'SAT';
  // Night
  else if (
    isBefore(shiftStart, set(eventStart, { hours: 6 })) ||
    isAfter(eventEnd, startOfDay(addDays(eventStart, 1)))
  )
    category = 'NHT';
  // Weekday
  else if (
    isWithinInterval(shiftStart, {
      start: set(eventStart, { hours: 6, minutes: 0 }),
      end: set(eventStart, { hours: 20, minutes: 0 }),
    }) &&
    isWithinInterval(shiftEnd, {
      start: set(eventStart, { hours: 6, minutes: 0 }),
      end: set(eventStart, { hours: 20, minutes: 0 }),
    })
  )
    category = 'WKD';
  // Afternoon
  else if (!category) category = 'AFT';

  if (!memberBonusEligible) {
    switch (category) {
      case 'WKD':
        return 'Base Hourly';
      case 'AFT':
        return 'L1P1 Afternoon';
      case 'NHT':
        return 'L1P1 Night';
      case 'SAT':
        return 'L1P1 Sat';
      case 'SUN':
        return 'L1P1 Sun';
      case 'PUB':
        return 'L1P1 Pub';
    }
  }

  let bonus: number;

  const duration = differenceInMinutes(eventEnd, eventStart);

  if (duration <= 120) bonus = 2;
  else if (duration <= 180) bonus = 3;
  else if (duration <= 240) bonus = 4;
  else if (duration <= 300) bonus = 5;
  else if (duration <= 360) bonus = 6;
  else if (duration <= 420) bonus = 7;
  else if (duration <= 480) bonus = 8;
  else if (duration <= 540) bonus = 9;
  else if (duration <= 600) bonus = 10;
  else if (duration <= 660) bonus = 11;
  else bonus = 12;

  return ('L1P1 ' + category + ' BONUS ' + bonus) as PayrollCategory;
};
