import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAlert } from '@timed/alert';
import {
  AbnInput,
  addServerErrors,
  EmailInput,
  FormModal,
  ModalProps,
  PhoneInput,
  Textarea,
  TextField,
  validateAustralianBusinessName,
  validatePersonName,
} from '@timed/common';
import { useGetPlanManagerByIdQuery, useUpdatePlanManagersMutation } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type PlanManagerUpdateFormModalProps = Omit<ModalProps, 'children'> & {
  planManagerId: string;
  onClose: () => void;
};

type FormData = {
  name: string;
  businessName?: string;
  abn?: string;
  contactEmail?: string;
  billingEmail?: string;
  phone?: string;
  notes?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
  }),
);

const PlanManagerUpdateFormModal = ({
  planManagerId,
  onClose,
  ...modalProps
}: PlanManagerUpdateFormModalProps) => {
  const classes = useStyles();

  const alert = useAlert();

  const { data, loading } = useGetPlanManagerByIdQuery({
    variables: { id: planManagerId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'standby',
  });

  const [updatePlanManagers, response] = useUpdatePlanManagersMutation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  useLoadingEffect(loading || response.loading);

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = async (values: FormData) => {
    // To avoid graphql mutation errors, delete locationData object if it is empty
    // (!values.locationData || Object.keys(values).length === 0) && (values.locationData = null);

    updatePlanManagers({
      variables: { input: { ids: [planManagerId], patch: values } },
    }).catch(() => {});
  };

  const onSuccess = () => {
    alert.push({ message: 'Successfully updated plan manager', severity: 'success' });
  };

  const planManager = data?.planManagerById;

  return !planManager ? (
    <>Loading</>
  ) : (
    <FormModal
      modalProps={modalProps}
      title="Edit plan manager"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <TextField
          required
          label="Name"
          defaultValue={planManager.name}
          type="text"
          validation={validateAustralianBusinessName}
          name="name"
          variant="outlined"
          size="small"
          control={control}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          label="Business Name"
          defaultValue={planManager.businessName}
          type="text"
          validation={validateAustralianBusinessName}
          name="businessName"
          variant="outlined"
          size="small"
          control={control}
          error={!!errors.businessName}
          helperText={errors.businessName?.message}
        />
        <AbnInput
          name="abn"
          defaultValue={planManager.abn}
          control={control}
          variant="outlined"
          size="small"
          margin="dense"
          error={!!errors.abn}
          helperText={errors.abn?.message}
        />
        <EmailInput
          label="Contact Email"
          name="contactEmail"
          defaultValue={planManager.contactEmail}
          variant="outlined"
          size="small"
          control={control}
          error={!!errors.contactEmail}
          helperText={errors.contactEmail?.message}
        />
        <EmailInput
          name="billingEmail"
          label="Billing Email"
          defaultValue={planManager.billingEmail}
          variant="outlined"
          size="small"
          control={control}
          error={!!errors.billingEmail}
          helperText={errors.billingEmail?.message}
        />
        <PhoneInput
          name="phone"
          defaultValue={planManager.phone}
          variant="outlined"
          size="small"
          margin="dense"
          control={control}
          error={!!errors.phone}
          helperText={errors.phone?.message}
        />
        <Textarea
          name="notes"
          defaultValue={planManager.notes || undefined}
          control={control}
          minRows={10}
          placeholder="Notes"
        />
      </Box>
    </FormModal>
  );
};

export default PlanManagerUpdateFormModal;
