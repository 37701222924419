import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Checkbox } from '@timed/common';
import { Permission } from '@timed/gql';
import { Control, RegisterOptions } from 'react-hook-form';

type MemberPermissionsInputProps = {
  permissions?: Permission[] | null;
  control: Control<any>;
  disabled: boolean;
};

export const validateMemberPermission: RegisterOptions = {
  validate: {
    validPermission: (value) => value in Permission || 'Invalid permission',
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8),
      },
    },
    wrapper: {
      display: 'inline-grid',
      gridTemplateColumns: 'max-content auto auto',
      overflow: 'hidden',
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(4),
      alignItems: 'center',

      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
        marginRight: theme.spacing(8),
      },
    },
  }),
);

const MemberPermissionsInput = ({
  permissions,
  control,
  disabled,
}: MemberPermissionsInputProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box></Box>
      <Typography>Read</Typography>
      <Typography>Write</Typography>
      <Typography>Employees</Typography>
      <Checkbox
        defaultChecked={!!(permissions && permissions.includes(Permission.MEMBER_READ))}
        name="MEMBER_READ"
        control={control}
        disabled={disabled}
      ></Checkbox>
      <Checkbox
        defaultChecked={!!(permissions && permissions.includes(Permission.MEMBER_WRITE))}
        name="MEMBER_WRITE"
        control={control}
        disabled={disabled}
      ></Checkbox>
      <Typography>Participants</Typography>
      <Checkbox
        defaultChecked={!!(permissions && permissions.includes(Permission.CLIENT_READ))}
        name="CLIENT_READ"
        control={control}
        disabled={disabled}
      ></Checkbox>
      <Checkbox
        defaultChecked={!!(permissions && permissions.includes(Permission.CLIENT_WRITE))}
        name="CLIENT_WRITE"
        control={control}
        disabled={disabled}
      ></Checkbox>
      <Typography>Plan Managers</Typography>
      <Checkbox
        defaultChecked={!!(permissions && permissions.includes(Permission.PLANMANAGER_READ))}
        name="PLANMANAGER_READ"
        control={control}
        disabled={disabled}
      ></Checkbox>
      <Checkbox
        defaultChecked={!!(permissions && permissions.includes(Permission.PLANMANAGER_WRITE))}
        name="PLANMANAGER_WRITE"
        control={control}
        disabled={disabled}
      ></Checkbox>
      <Typography>Support Coordinators</Typography>
      <Checkbox
        defaultChecked={!!(permissions && permissions.includes(Permission.SUPPORTCOORDINATOR_READ))}
        name="SUPPORTCOORDINATOR_READ"
        control={control}
        disabled={disabled}
      ></Checkbox>
      <Checkbox
        defaultChecked={
          !!(permissions && permissions.includes(Permission.SUPPORTCOORDINATOR_WRITE))
        }
        name="SUPPORTCOORDINATOR_WRITE"
        control={control}
        disabled={disabled}
      ></Checkbox>
      <Typography>Schedule</Typography>
      <Checkbox
        defaultChecked={!!(permissions && permissions.includes(Permission.EVENT_READ))}
        name="EVENT_READ"
        control={control}
        disabled={disabled}
      ></Checkbox>
      <Checkbox
        defaultChecked={!!(permissions && permissions.includes(Permission.EVENT_WRITE))}
        name="EVENT_WRITE"
        control={control}
        disabled={disabled}
      ></Checkbox>
    </Box>
  );
};

export default MemberPermissionsInput;
