import {
  Snackbar as MuiSnackbar,
  SnackbarCloseReason,
  SnackbarProps as MuiSnackbarProps,
} from '@material-ui/core';
import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@material-ui/lab';
import { useEffect, useState } from 'react';

type SnackbarProps = React.PropsWithChildren<any> &
  MuiSnackbarProps & {
    open: boolean;
    severity: MuiAlertProps['color'];
  };

const Snackbar = ({ severity, children, open, ...props }: SnackbarProps) => {
  const [visible, setVisible] = useState<boolean>(open);

  useEffect(() => setVisible(open), [open]);

  const handleClose = (reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setVisible(false);
  };

  return (
    <MuiSnackbar
      key={children}
      autoHideDuration={6000}
      onClose={(_, reason) => handleClose(reason)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={visible}
      {...props}
    >
      <MuiAlert onClose={() => handleClose()} severity={severity} variant="filled">
        {children}
      </MuiAlert>
    </MuiSnackbar>
  );
};

export default Snackbar;
