import {
  createStyles,
  makeStyles,
  Modal as MuiModal,
  ModalProps as MuiModalProps,
  Paper,
  PaperProps,
  Theme,
  Zoom,
} from '@material-ui/core';
import clsx from 'clsx';

export type ModalProps = MuiModalProps & {
  paperProps?: PaperProps;
  onExited?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      boxSizing: 'border-box',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
        borderRadius: 'unset',
      },
    },
  }),
);

const Modal = ({ paperProps, children, open, onExited, ...props }: ModalProps) => {
  const classes = useStyles();

  return (
    <MuiModal
      {...props}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      className={classes.modal}
      BackdropProps={{ timeout: 250 }}
      open={open}
    >
      <Zoom mountOnEnter unmountOnExit in={open} onExited={onExited} timeout={250}>
        <Paper {...paperProps} className={clsx(classes.paper, paperProps?.className)}>
          {children}
        </Paper>
      </Zoom>
    </MuiModal>
  );
};

export default Modal;
