import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Permission } from '@timed/gql';
import { Protected } from '@timed/auth';
import { ScheduleClientInput, ScheduleMemberInput } from '@timed/schedule';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flex: '1 1 auto',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(4),
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        padding: theme.spacing(2),
        gap: theme.spacing(2),
        '& .MuiSelect-root': {
          fontSize: 12,
          minWidth: 120,
        },
      },
    },
  }),
);

const ScheduleCalendarFilters = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <ScheduleClientInput />
      <Protected permissions={Permission.MEMBER_READ}>
        <ScheduleMemberInput />
      </Protected>
    </Box>
  );
};

export default ScheduleCalendarFilters;
