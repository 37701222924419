import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuicheckboxProps,
  createStyles,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { validateRequired } from '@timed/common';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

export type CheckboxProps = MuicheckboxProps & {
  label?: string;
  control?: Control<any>;
  validation?: Omit<RegisterOptions, 'required'>;
  formControlProps?: FormControlProps;
  formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
  name: string;
  error?: boolean;
  helperText?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      '& .MuiFormControlLabel-label': {
        color: theme.palette.error.main,
      },
    },
  }),
);

const Checkbox = ({
  disabled,
  label,
  name,
  control,
  validation,
  defaultChecked,
  formControlProps,
  formControlLabelProps,
  error,
  helperText,
  className,
  ...props
}: CheckboxProps) => {
  const classes = useStyles();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={!!defaultChecked}
      rules={validation && props.required ? { ...validateRequired, ...validation } : validation}
      render={({ field }) => (
        <FormControl disabled={disabled} {...formControlProps}>
          {!!label ? (
            <FormControlLabel
              label={label}
              className={error ? classes.error : className}
              checked={field.value}
              control={
                <MuiCheckbox
                  {...field}
                  {...props}
                  key={field.name}
                  defaultChecked={!!defaultChecked}
                />
              }
              {...formControlLabelProps}
            />
          ) : (
            <MuiCheckbox {...field} {...props} key={field.name} defaultChecked={!!defaultChecked} />
          )}
          {error && (
            <FormHelperText style={{ marginLeft: 32 }} error>
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default Checkbox;
