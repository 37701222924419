import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Block, formatBusinessName, formatPersonName, intersperse, Link } from '@timed/common';
import { _supportDebug } from '@timed/app';
import { Protected, useAuth } from '@timed/auth';
import { Page } from '@timed/page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'grid',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
      },
    },
    data: {
      display: 'grid',
      gridTemplateColumns: 'max-content auto',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4, 8),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4, 16),
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
);

const SupportDebug = () => {
  const classes = useStyles();
  // const { role, profile, group } = useAuth();
  const { member, org } = useAuth();

  const environment =
    process.env.REACT_APP_URL === 'http://localhost:3000'
      ? 'Localhost'
      : window.location.origin === process.env.REACT_APP_URL_DEV
      ? 'Development'
      : 'Production';

  return (
    <Page {..._supportDebug}>
      <Box className={classes.wrapper}>
        <Block title="Application Info">
          <Box className={classes.data}>
            <Typography className={classes.bold}>Version</Typography>
            <Typography>{process.env.REACT_APP_VERSION}</Typography>
            <Typography className={classes.bold}>Environment</Typography>
            <Typography>{environment}</Typography>
            <Protected admin={true}>
              <Typography className={classes.bold}>API URL</Typography>
              <Typography>
                <Link
                  target="blank"
                  to={
                    environment === 'Localhost' || environment === 'Production'
                      ? process.env.REACT_APP_API_URL!
                      : process.env.REACT_APP_API_URL_DEV!
                  }
                >
                  {environment === 'Localhost' || environment === 'Production'
                    ? process.env.REACT_APP_API_URL!
                    : process.env.REACT_APP_API_URL_DEV!}
                </Link>
              </Typography>
              <Typography className={classes.bold}>Repository</Typography>
              <Typography>
                <Link target="blank" to={process.env.REACT_APP_REPOSITORY_URL!}>
                  workout-group/timed.git
                </Link>
              </Typography>
            </Protected>
          </Box>
        </Block>
        {member && org && (
          <Block title="Organisation Member Info">
            <Box className={classes.data}>
              <Typography className={classes.bold}>Organisation</Typography>
              <Typography>{formatBusinessName(org, { preferred: true })}</Typography>
              <Typography className={classes.bold}>Profile</Typography>
              <Typography>{formatPersonName(member)}</Typography>
              <Typography className={classes.bold}>Member ID</Typography>
              <Typography>{member.id}</Typography>
              <Protected admin>
                <Typography className={classes.bold}>Super administrator</Typography>
                <Typography>Yes</Typography>
              </Protected>
              {member.permissions?.length ? (
                <>
                  <Typography className={classes.bold}>Permissions</Typography>
                  <Typography>
                    {intersperse({ array: member.permissions || [], sep: <br></br>, and: false })}
                  </Typography>
                </>
              ) : null}
            </Box>
          </Block>
        )}
      </Box>
    </Page>
  );
};
export default SupportDebug;
