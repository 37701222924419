import { createContext } from 'react';

export type PaletteTypes = 'light' | 'dark';

type ThemeContextType = {
  palette: PaletteTypes;
  setPalette: (palette: PaletteTypes) => void;
};

const ThemeContext = createContext<ThemeContextType>({
  palette: 'light',
  setPalette: () => {},
});

export default ThemeContext;
