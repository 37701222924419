import { _support, _supportContact, _supportDebug } from '@timed/app';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const Support = () => (
  <Page {..._support} controls={[{ label: _support.label }, _supportContact, _supportDebug]}>
    <Outlet />
  </Page>
);

export default Support;
