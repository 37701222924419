import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Checkbox, CheckboxProps } from '@timed/common';

const ClientAllowAutomaticEventPopulationCheckbox = (props: CheckboxProps) => (
  <Checkbox
    defaultChecked={false}
    label="Allow the automatic population of data for unattended future events"
    icon={<RadioButtonUncheckedIcon />}
    checkedIcon={<CheckCircleOutlineRoundedIcon />}
    {...props}
  />
);

export default ClientAllowAutomaticEventPopulationCheckbox;
