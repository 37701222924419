import { _settingsPersonal } from '@timed/app';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const SettingsPersonal = () => (
  <Page {..._settingsPersonal}>
    <Outlet />
  </Page>
);

export default SettingsPersonal;
