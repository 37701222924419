import { TextField } from '@material-ui/core';

type SupportCoordinatorSearchBoxProps = {
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const SupportCoordinatorListSearch = (props: SupportCoordinatorSearchBoxProps) => {
  return (
    <TextField
      autoFocus
      fullWidth
      size="small"
      name="search"
      variant="outlined"
      label="Search"
      onChange={props.handleSearch}
    />
  );
};

export default SupportCoordinatorListSearch;
