import { Grid, Typography } from '@material-ui/core';
import { Protected, useAuth } from '@timed/auth';
import {
  AddressCountryInput,
  addServerErrors,
  Block,
  DateInput,
  IconButtonMulti,
  NullableBoolean,
  nullableBooleanStringToValue,
  nullableBooleanValueToString,
  roundNumber,
  TextField,
  transformNumberToFixedFloat,
} from '@timed/common';
import { DriversLicenceInput } from '@timed/common/components/DriversLicenceInput';
import {
  DriversLicense,
  HistoryRestorable,
  Member,
  MemberFileType,
  OrderBy,
  Permission,
  useGetMemberFilesLazyQuery,
  useUpdateMembersMutation,
} from '@timed/gql';
import {
  MemberBonusEligibleCheckbox,
  MemberCarDescriptionInput,
  MemberExternalIdInput,
  MemberHasCarInput,
  MemberSchedulableCheckbox,
} from '@timed/member';
import { format, isAfter } from 'date-fns';
import { formatDistance } from 'date-fns/esm';
import { camelCase, isEqual, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateExternalIdFormProps = {
  member: Pick<
    Member,
    | 'id'
    | 'externalId'
    | 'schedulable'
    | 'employmentStartDate'
    | 'employmentEndDate'
    | 'basePayRate'
    | 'bonusEligible'
    | 'driversLicense'
    | 'driversLicenseCountry'
    | 'driversLicenseExpiryDate'
    | 'hasCar'
    | 'carDescription'
  > & { archive?: Pick<HistoryRestorable, 'id'> | null };
};

type FormData = {
  patch: {
    externalId: Member['externalId'];
    schedulable: Member['schedulable'];
    bonusEligible: Member['bonusEligible'];
    employmentStartDate: Member['employmentStartDate'];
    employmentEndDate: Member['employmentEndDate'];
    basePayRate: Member['basePayRate'];
    driversLicense: Member['driversLicense'];
    driversLicenseCountry: Member['driversLicenseCountry'];
    driversLicenseExpiryDate: Member['driversLicenseExpiryDate'];
    hasCar: NullableBoolean;
    carDescription: Member['carDescription'];
  };
};

const MemberUpdateMemberDetailsForm = ({ member }: MemberUpdateExternalIdFormProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const { permissible } = useAuth();

  const [updateMember, response] = useUpdateMembersMutation();

  const [getMemberFiles, memberFilesResponse] = useGetMemberFilesLazyQuery({
    variables: {
      input: {
        limit: 1,
        where: {
          owner: { id: { _eq: member.id } },
          type: { _eq: MemberFileType.DRIVERS_LICENCE },
        },
        orderBy: [
          { dateOfFile: OrderBy.DESC_NULLS_LAST },
          { file: { createdAt: OrderBy.DESC_NULLS_LAST } },
        ],
      },
    },
  });

  const defaultValues: FormData = {
    patch: {
      externalId: member.externalId,
      schedulable: member.schedulable,
      bonusEligible: member.bonusEligible,
      employmentStartDate: member.employmentStartDate,
      employmentEndDate: member.employmentEndDate,
      basePayRate: !!member.basePayRate ? member.basePayRate / 100 : 0,
      driversLicenseCountry: member.driversLicenseCountry,
      driversLicenseExpiryDate: member.driversLicenseExpiryDate,
      hasCar: nullableBooleanValueToString(member.hasCar),
      carDescription: !!member.carDescription ? member.carDescription : '',
      driversLicense: !!member.driversLicense ? member.driversLicense : DriversLicense.UNKNOWN,
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  useEffect(() => {
    if (
      !memberFilesResponse.data &&
      !memberFilesResponse.loading &&
      permissible({ permissions: Permission.MEMBER_FILE_READ })
    )
      getMemberFiles();
  }, [getMemberFiles, memberFilesResponse.data, memberFilesResponse.loading, permissible]);

  const onSubmit = ({ patch: { basePayRate, ...values } }: FormData) => {
    if (permissible({ admin: true }) && !!basePayRate)
      Object.assign(values, {
        basePayRate: !basePayRate ? 0 : roundNumber(basePayRate * 100, 0),
      });

    updateMember({
      variables: {
        input: {
          ids: [member.id],
          patch: {
            ...values,
            hasCar: !!values.hasCar ? nullableBooleanStringToValue(values.hasCar) : undefined,
          },
        },
      },
    }).catch((e) => {});

    reset({ patch: { ...values, basePayRate } });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Employee Details"
        topRight={
          !member.archive ? (
            <Protected permissions={Permission.MEMBER_WRITE}>
              <IconButtonMulti
                size="small"
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            </Protected>
          ) : undefined
        }
      >
        <Grid container spacing={4} alignItems="center">
          {!editing && (
            <>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>
                  {(!!member.employmentStartDate && !!member.employmentEndDate) ||
                  (!member.employmentStartDate && !member.employmentEndDate)
                    ? 'Employment'
                    : !!member.employmentStartDate
                    ? 'Start'
                    : 'End'}
                </Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!!member.employmentStartDate && !!member.employmentEndDate && (
                    <>
                      {format(new Date(member.employmentStartDate), 'dd/MM/yyyy')} to{' '}
                      {format(new Date(member.employmentEndDate), 'dd/MM/yyyy')}
                      {!!member.employmentStartDate &&
                        !!member.employmentEndDate &&
                        ' (' +
                          formatDistance(
                            new Date(member.employmentEndDate),
                            new Date(member.employmentStartDate),
                          ) +
                          ')'}
                    </>
                  )}
                  {!!member.employmentStartDate && !member.employmentEndDate && (
                    <>
                      {format(new Date(member.employmentStartDate), 'dd/MM/yyyy')}
                      {' (' +
                        formatDistance(new Date(), new Date(member.employmentStartDate)) +
                        ')'}
                    </>
                  )}
                  {!member.employmentStartDate && !!member.employmentEndDate && (
                    <>{format(new Date(member.employmentEndDate), 'dd/MM/yyyy')}</>
                  )}
                </Typography>
              </Grid>
            </>
          )}

          {editing && (
            <>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Employment Start</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <DateInput
                  keyboard
                  clearable
                  disableTime
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  name="patch.employmentStartDate"
                  error={!!errors.patch?.employmentStartDate}
                  helperText={errors.patch?.employmentStartDate?.message}
                />
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Employment End</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <DateInput
                  keyboard
                  clearable
                  disableTime
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  name="patch.employmentEndDate"
                  error={!!errors.patch?.employmentEndDate}
                  helperText={errors.patch?.employmentEndDate?.message}
                />
              </Grid>
            </>
          )}

          <Grid item xs={4} md={3} lg={2}>
            <Typography>MYOB ID</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.externalId
              ) : (
                <MemberExternalIdInput
                  fullWidth
                  label=""
                  name="patch.externalId"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.externalId}
                  helperText={errors.patch?.externalId?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          {permissible({ admin: true }) && (
            <>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Base pay rate</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!editing ? (
                    !!member.basePayRate && '$' + (member.basePayRate / 100).toFixed(2)
                  ) : (
                    <TextField
                      control={control}
                      name="patch.basePayRate"
                      type="number"
                      variant="outlined"
                      size="small"
                      inputProps={{ min: 0, max: 10000, step: 0.01 }}
                      transform={transformNumberToFixedFloat(2)}
                      error={!!errors.patch?.basePayRate}
                      helperText={errors.patch?.basePayRate?.message}
                    />
                  )}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={4} md={3} lg={2}>
            <Typography>Bonus pay eligible</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.bonusEligible ? (
                  'Yes'
                ) : (
                  'No'
                )
              ) : (
                <MemberBonusEligibleCheckbox name="patch.bonusEligible" control={control} />
              )}
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={2}>
            <Typography>Schedulable</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.schedulable ? (
                  'Yes'
                ) : (
                  'No'
                )
              ) : (
                <MemberSchedulableCheckbox name="patch.schedulable" control={control} />
              )}
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={2}>
            <Typography>Drivers license</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!!memberFilesResponse.data?.memberFiles[0] ? (
                isAfter(new Date(), new Date(memberFilesResponse.data.memberFiles[0].expiresAt)) ? (
                  <span style={{ color: 'red' }}>Expired (file uploaded)</span>
                ) : (
                  'Valid (file uploaded)'
                )
              ) : !editing ? (
                member.driversLicense === null ? (
                  'Unknown'
                ) : (
                  startCase(camelCase(member.driversLicense))
                )
              ) : (
                <DriversLicenceInput
                  name="patch.driversLicense"
                  control={control}
                  label={undefined}
                />
              )}
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={2}>
            <Typography>DL country</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.driversLicenseCountry === null ? (
                  ''
                ) : (
                  member.driversLicenseCountry
                )
              ) : (
                <AddressCountryInput
                  control={control}
                  name="patch.driversLicenseCountry"
                  error={!!errors.patch?.driversLicenseCountry}
                  helperText={errors.patch?.driversLicenseCountry?.message}
                />
              )}
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={2}>
            <Typography>Drivers license expiry</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!memberFilesResponse.data?.memberFiles[0] &&
                !!memberFilesResponse.data.memberFiles[0].expiresAt ? (
                  <span
                    style={
                      isAfter(new Date(), new Date(member.driversLicenseExpiryDate))
                        ? { color: 'red' }
                        : undefined
                    }
                  >
                    {format(
                      new Date(memberFilesResponse.data.memberFiles[0].expiresAt),
                      'dd/MM/yyyy',
                    )}{' '}
                    (from uploaded file)
                  </span>
                ) : !!member.driversLicenseExpiryDate ? (
                  <span
                    style={
                      isAfter(new Date(), new Date(member.driversLicenseExpiryDate))
                        ? { color: 'red' }
                        : undefined
                    }
                  >
                    {format(new Date(member.driversLicenseExpiryDate), 'dd/MM/yyyy')}
                  </span>
                ) : (
                  ''
                )
              ) : (
                <DateInput
                  keyboard
                  clearable
                  disableTime
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  name="patch.driversLicenseExpiryDate"
                  error={!!errors.patch?.driversLicenseExpiryDate}
                  helperText={errors.patch?.driversLicenseExpiryDate?.message}
                />
              )}
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={2}>
            <Typography>Has a car</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.hasCar === null ? (
                  'Unknown'
                ) : member.hasCar ? (
                  'Yes'
                ) : (
                  'No'
                )
              ) : (
                <MemberHasCarInput name="patch.hasCar" control={control} />
              )}
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={2}>
            <Typography>Description of car</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.carDescription
              ) : (
                <MemberCarDescriptionInput
                  fullWidth
                  label=""
                  variant="outlined"
                  size="small"
                  error={!!errors.patch?.carDescription}
                  helperText={errors.patch?.carDescription?.message}
                  disabled={!editing}
                  name="patch.carDescription"
                  control={control}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default MemberUpdateMemberDetailsForm;
