import { useApolloClient } from '@apollo/client';
import { Link, useRouter } from '@timed/common';
import { PropsWithChildren } from 'react';

type AuthLogoutLinkProps = PropsWithChildren<{}>;

const AuthLogoutLink = ({ children }: AuthLogoutLinkProps) => {
  const client = useApolloClient();
  const { pathname } = useRouter();

  const handleClick = async () => {
    localStorage.clear();
    await client.clearStore();
  };

  return (
    <Link to={'sign-in' + (pathname === '/' ? '' : '?ref=' + pathname)} onClick={handleClick}>
      {children}
    </Link>
  );
};

export default AuthLogoutLink;
