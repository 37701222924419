import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useAuth } from '@timed/auth';
import { ButtonAsync, roundNumber, TextField, transformNumberToFixedFloat } from '@timed/common';
import {
  Maybe,
  Org,
  useGetOrgPayrollSettingsByIdQuery,
  useUpdateOrgPayrollMutation,
} from '@timed/gql';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  patch: Pick<
    Org,
    | 'basePayRate'
    | 'afternoonPayMultiplier'
    | 'nightPayMultiplier'
    | 'satPayMultiplier'
    | 'sunPayMultiplier'
    | 'pubPayMultiplier'
    | 'passiveAllowance'
    | 'kmAllowance'
    | 'bonus2PayRate'
    | 'bonus3PayRate'
    | 'bonus4PayRate'
    | 'bonus5PayRate'
    | 'bonus6PayRate'
    | 'bonus7PayRate'
    | 'bonus8PayRate'
    | 'bonus9PayRate'
    | 'bonus10PayRate'
    | 'bonus11PayRate'
    | 'bonus12PayRate'
  >;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    base: {
      alignItems: 'center',
      display: 'grid',
      gridTemplateColumns: '300px max-content',
      gap: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + theme.palette.divider,
      padding: theme.spacing(4),
    },
    bonus: {
      alignItems: 'center',
      display: 'grid',
      gridTemplateColumns: '300px repeat(6, max-content)',
      gap: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + theme.palette.divider,
      padding: theme.spacing(4),
    },
  }),
);

const SettingsOrganisationPayrollForm = () => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>();

  const { data, loading } = useGetOrgPayrollSettingsByIdQuery({
    variables: { id: useAuth().org!.id },
  });

  const [updateOrg] = useUpdateOrgPayrollMutation();

  const onSubmit = ({
    patch: {
      basePayRate,
      afternoonPayMultiplier,
      nightPayMultiplier,
      satPayMultiplier,
      sunPayMultiplier,
      pubPayMultiplier,
      passiveAllowance,
      kmAllowance,
      bonus2PayRate,
      bonus3PayRate,
      bonus4PayRate,
      bonus5PayRate,
      bonus6PayRate,
      bonus7PayRate,
      bonus8PayRate,
      bonus9PayRate,
      bonus10PayRate,
      bonus11PayRate,
      bonus12PayRate,
      ...patch
    },
  }: FormData) => {
    Object.assign(patch, {
      basePayRate: !basePayRate ? 0 : roundNumber(basePayRate * 100, 0),
      afternoonPayMultiplier: !afternoonPayMultiplier
        ? 0
        : roundNumber(afternoonPayMultiplier * 100, 0),
      nightPayMultiplier: !nightPayMultiplier ? 0 : roundNumber(nightPayMultiplier * 100, 0),
      satPayMultiplier: !satPayMultiplier ? 0 : roundNumber(satPayMultiplier * 100, 0),
      sunPayMultiplier: !sunPayMultiplier ? 0 : roundNumber(sunPayMultiplier * 100, 0),
      pubPayMultiplier: !pubPayMultiplier ? 0 : roundNumber(pubPayMultiplier * 100, 0),
      passiveAllowance: !passiveAllowance ? 0 : roundNumber(passiveAllowance * 100, 0),
      kmAllowance: !kmAllowance ? 0 : roundNumber(kmAllowance * 100, 0),
      bonus2PayRate: !bonus2PayRate ? 0 : roundNumber(bonus2PayRate * 100, 0),
      bonus3PayRate: !bonus3PayRate ? 0 : roundNumber(bonus3PayRate * 100, 0),
      bonus4PayRate: !bonus4PayRate ? 0 : roundNumber(bonus4PayRate * 100, 0),
      bonus5PayRate: !bonus5PayRate ? 0 : roundNumber(bonus5PayRate * 100, 0),
      bonus6PayRate: !bonus6PayRate ? 0 : roundNumber(bonus6PayRate * 100, 0),
      bonus7PayRate: !bonus7PayRate ? 0 : roundNumber(bonus7PayRate * 100, 0),
      bonus8PayRate: !bonus8PayRate ? 0 : roundNumber(bonus8PayRate * 100, 0),
      bonus9PayRate: !bonus9PayRate ? 0 : roundNumber(bonus9PayRate * 100, 0),
      bonus10PayRate: !bonus10PayRate ? 0 : roundNumber(bonus10PayRate * 100, 0),
      bonus11PayRate: !bonus11PayRate ? 0 : roundNumber(bonus11PayRate * 100, 0),
      bonus12PayRate: !bonus12PayRate ? 0 : roundNumber(bonus12PayRate * 100, 0),
    });

    updateOrg({ variables: { input: { patch } } }).catch((e) => {});
  };

  const calcPayRate = useCallback(
    (multiplier?: Maybe<number>): number | null =>
      data?.orgById.basePayRate && multiplier
        ? roundNumber((data?.orgById.basePayRate * (multiplier / 100)) / 100, 2)
        : null,
    [data?.orgById.basePayRate],
  );

  if (loading) return <Typography>Loading</Typography>;

  if (!data) return <Typography>An error occured while fetching settings</Typography>;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Box className={classes.base}>
        <Typography variant="h3" className={classes.bold}>
          Employee Pay Rates
        </Typography>
        <Typography variant="h3" className={classes.bold}>
          Calculated Rate
        </Typography>
        <TextField
          control={control}
          label="Base (dollar value)"
          name="patch.basePayRate"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.basePayRate}
          helperText={errors.patch?.basePayRate?.message}
          defaultValue={!!data.orgById.basePayRate ? data.orgById.basePayRate / 100 : 0}
        />
        <Typography>
          {!!data.orgById.basePayRate && '$' + data.orgById.basePayRate / 100 + ' / hr'}
        </Typography>
        <TextField
          control={control}
          label="Afternoon (multiplier)"
          name="patch.afternoonPayMultiplier"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 10, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.afternoonPayMultiplier}
          helperText={errors.patch?.afternoonPayMultiplier?.message}
          defaultValue={
            !!data.orgById.afternoonPayMultiplier ? data.orgById.afternoonPayMultiplier / 100 : 0
          }
        />
        <Typography>
          {!!data.orgById.afternoonPayMultiplier && data.orgById.basePayRate
            ? '$' + calcPayRate(data.orgById.afternoonPayMultiplier) + ' / hr'
            : ''}
        </Typography>

        <TextField
          control={control}
          label="Night (multiplier)"
          name="patch.nightPayMultiplier"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 10, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.nightPayMultiplier}
          helperText={errors.patch?.nightPayMultiplier?.message}
          defaultValue={
            !!data.orgById.nightPayMultiplier ? data.orgById.nightPayMultiplier / 100 : 0
          }
        />
        <Typography>
          {!!data.orgById.nightPayMultiplier && data.orgById.basePayRate
            ? '$' + calcPayRate(data.orgById.nightPayMultiplier) + ' / hr'
            : ''}
        </Typography>

        <TextField
          control={control}
          label="Saturday (multiplier)"
          name="patch.satPayMultiplier"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 10, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.satPayMultiplier}
          helperText={errors.patch?.satPayMultiplier?.message}
          defaultValue={!!data.orgById.satPayMultiplier ? data.orgById.satPayMultiplier / 100 : 0}
        />
        <Typography>
          {!!data.orgById.satPayMultiplier && data.orgById.basePayRate
            ? '$' + calcPayRate(data.orgById.satPayMultiplier) + ' / hr'
            : ''}
        </Typography>

        <TextField
          control={control}
          label="Sunday (multiplier)"
          name="patch.sunPayMultiplier"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 10, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.sunPayMultiplier}
          helperText={errors.patch?.sunPayMultiplier?.message}
          defaultValue={!!data.orgById.sunPayMultiplier ? data.orgById.sunPayMultiplier / 100 : 0}
        />
        <Typography>
          {!!data.orgById.sunPayMultiplier && data.orgById.basePayRate
            ? '$' + calcPayRate(data.orgById.sunPayMultiplier) + ' / hr'
            : ''}
        </Typography>
        <TextField
          control={control}
          label="Public Holiday (multiplier)"
          name="patch.pubPayMultiplier"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 10, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.pubPayMultiplier}
          helperText={errors.patch?.pubPayMultiplier?.message}
          defaultValue={!!data.orgById.pubPayMultiplier ? data.orgById.pubPayMultiplier / 100 : 0}
        />
        <Typography>
          {!!data.orgById.pubPayMultiplier && data.orgById.basePayRate
            ? '$' + calcPayRate(data.orgById.pubPayMultiplier) + ' / hr'
            : ''}
        </Typography>

        <TextField
          control={control}
          label="Passive-overnight (dollar value)"
          name="patch.passiveAllowance"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.passiveAllowance}
          helperText={errors.patch?.passiveAllowance?.message}
          defaultValue={!!data.orgById.passiveAllowance ? data.orgById.passiveAllowance / 100 : 0}
        />
        <Typography>
          {!!data.orgById.passiveAllowance
            ? '$' + roundNumber(data.orgById.passiveAllowance / 100, 2) + ' allowance'
            : ''}
        </Typography>

        <TextField
          control={control}
          label="Kilometres (dollar value)"
          name="patch.kmAllowance"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.kmAllowance}
          helperText={errors.patch?.kmAllowance?.message}
          defaultValue={!!data.orgById.kmAllowance ? data.orgById.kmAllowance / 100 : 0}
        />
        <Typography>
          {!!data.orgById.kmAllowance
            ? '$' + roundNumber(data.orgById.kmAllowance / 100, 2) + ' / km'
            : ''}
        </Typography>
      </Box>
      <Box className={classes.bonus}>
        <Typography variant="h3" className={classes.bold}>
          Employee Bonus Pay Rates
        </Typography>
        <Typography variant="h3" className={classes.bold}>
          Weekday
        </Typography>
        <Typography variant="h3" className={classes.bold}>
          Afternoon
        </Typography>
        <Typography variant="h3" className={classes.bold}>
          Night
        </Typography>
        <Typography variant="h3" className={classes.bold}>
          Saturday
        </Typography>
        <Typography variant="h3" className={classes.bold}>
          Sunday
        </Typography>
        <Typography variant="h3" className={classes.bold}>
          Public Holiday
        </Typography>
        <TextField
          control={control}
          label="2 hour shift"
          name="patch.bonus2PayRate"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.bonus2PayRate}
          helperText={errors.patch?.bonus2PayRate?.message}
          defaultValue={!!data.orgById.bonus2PayRate ? data.orgById.bonus2PayRate / 100 : 0}
        />
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.bonus2PayRate &&
            '$' +
              roundNumber(data.orgById.basePayRate / 100 + data.orgById.bonus2PayRate / 100, 2) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.afternoonPayMultiplier &&
            !!data.orgById.bonus2PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.afternoonPayMultiplier / 100) +
                  (data.orgById.bonus2PayRate / 100) * (data.orgById.afternoonPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.nightPayMultiplier &&
            !!data.orgById.bonus2PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.nightPayMultiplier / 100) +
                  (data.orgById.bonus2PayRate / 100) * (data.orgById.nightPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.satPayMultiplier &&
            !!data.orgById.bonus2PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.satPayMultiplier / 100) +
                  (data.orgById.bonus2PayRate / 100) * (data.orgById.satPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.sunPayMultiplier &&
            !!data.orgById.bonus2PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.sunPayMultiplier / 100) +
                  (data.orgById.bonus2PayRate / 100) * (data.orgById.sunPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.pubPayMultiplier &&
            !!data.orgById.bonus2PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.pubPayMultiplier / 100) +
                  (data.orgById.bonus2PayRate / 100) * (data.orgById.pubPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <TextField
          control={control}
          label="3 hour shift"
          name="patch.bonus3PayRate"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.bonus3PayRate}
          helperText={errors.patch?.bonus3PayRate?.message}
          defaultValue={!!data.orgById.bonus3PayRate ? data.orgById.bonus3PayRate / 100 : 0}
        />
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.bonus3PayRate &&
            '$' +
              roundNumber(data.orgById.basePayRate / 100 + data.orgById.bonus3PayRate / 100, 2) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.afternoonPayMultiplier &&
            !!data.orgById.bonus3PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.afternoonPayMultiplier / 100) +
                  (data.orgById.bonus3PayRate / 100) * (data.orgById.afternoonPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.nightPayMultiplier &&
            !!data.orgById.bonus3PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.nightPayMultiplier / 100) +
                  (data.orgById.bonus3PayRate / 100) * (data.orgById.nightPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.satPayMultiplier &&
            !!data.orgById.bonus3PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.satPayMultiplier / 100) +
                  (data.orgById.bonus3PayRate / 100) * (data.orgById.satPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.sunPayMultiplier &&
            !!data.orgById.bonus3PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.sunPayMultiplier / 100) +
                  (data.orgById.bonus3PayRate / 100) * (data.orgById.sunPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.pubPayMultiplier &&
            !!data.orgById.bonus3PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.pubPayMultiplier / 100) +
                  (data.orgById.bonus3PayRate / 100) * (data.orgById.pubPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <TextField
          control={control}
          label="4 hour shift"
          name="patch.bonus4PayRate"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.bonus4PayRate}
          helperText={errors.patch?.bonus4PayRate?.message}
          defaultValue={!!data.orgById.bonus4PayRate ? data.orgById.bonus4PayRate / 100 : 0}
        />
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.bonus4PayRate &&
            '$' +
              roundNumber(data.orgById.basePayRate / 100 + data.orgById.bonus4PayRate / 100, 2) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.afternoonPayMultiplier &&
            !!data.orgById.bonus4PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.afternoonPayMultiplier / 100) +
                  (data.orgById.bonus4PayRate / 100) * (data.orgById.afternoonPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.nightPayMultiplier &&
            !!data.orgById.bonus4PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.nightPayMultiplier / 100) +
                  (data.orgById.bonus4PayRate / 100) * (data.orgById.nightPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.satPayMultiplier &&
            !!data.orgById.bonus4PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.satPayMultiplier / 100) +
                  (data.orgById.bonus4PayRate / 100) * (data.orgById.satPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.sunPayMultiplier &&
            !!data.orgById.bonus4PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.sunPayMultiplier / 100) +
                  (data.orgById.bonus4PayRate / 100) * (data.orgById.sunPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.pubPayMultiplier &&
            !!data.orgById.bonus4PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.pubPayMultiplier / 100) +
                  (data.orgById.bonus4PayRate / 100) * (data.orgById.pubPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <TextField
          control={control}
          label="5 hour shift"
          name="patch.bonus5PayRate"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.bonus5PayRate}
          helperText={errors.patch?.bonus5PayRate?.message}
          defaultValue={!!data.orgById.bonus5PayRate ? data.orgById.bonus5PayRate / 100 : 0}
        />
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.bonus5PayRate &&
            '$' +
              roundNumber(data.orgById.basePayRate / 100 + data.orgById.bonus5PayRate / 100, 2) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.afternoonPayMultiplier &&
            !!data.orgById.bonus5PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.afternoonPayMultiplier / 100) +
                  (data.orgById.bonus5PayRate / 100) * (data.orgById.afternoonPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.nightPayMultiplier &&
            !!data.orgById.bonus5PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.nightPayMultiplier / 100) +
                  (data.orgById.bonus5PayRate / 100) * (data.orgById.nightPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.satPayMultiplier &&
            !!data.orgById.bonus5PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.satPayMultiplier / 100) +
                  (data.orgById.bonus5PayRate / 100) * (data.orgById.satPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.sunPayMultiplier &&
            !!data.orgById.bonus5PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.sunPayMultiplier / 100) +
                  (data.orgById.bonus5PayRate / 100) * (data.orgById.sunPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.pubPayMultiplier &&
            !!data.orgById.bonus5PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.pubPayMultiplier / 100) +
                  (data.orgById.bonus5PayRate / 100) * (data.orgById.pubPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <TextField
          control={control}
          label="6 hour shift"
          name="patch.bonus6PayRate"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.bonus6PayRate}
          helperText={errors.patch?.bonus6PayRate?.message}
          defaultValue={!!data.orgById.bonus6PayRate ? data.orgById.bonus6PayRate / 100 : 0}
        />
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.bonus6PayRate &&
            '$' +
              roundNumber(data.orgById.basePayRate / 100 + data.orgById.bonus6PayRate / 100, 2) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.afternoonPayMultiplier &&
            !!data.orgById.bonus6PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.afternoonPayMultiplier / 100) +
                  (data.orgById.bonus6PayRate / 100) * (data.orgById.afternoonPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.nightPayMultiplier &&
            !!data.orgById.bonus6PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.nightPayMultiplier / 100) +
                  (data.orgById.bonus6PayRate / 100) * (data.orgById.nightPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.satPayMultiplier &&
            !!data.orgById.bonus6PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.satPayMultiplier / 100) +
                  (data.orgById.bonus6PayRate / 100) * (data.orgById.satPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.sunPayMultiplier &&
            !!data.orgById.bonus6PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.sunPayMultiplier / 100) +
                  (data.orgById.bonus6PayRate / 100) * (data.orgById.sunPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.pubPayMultiplier &&
            !!data.orgById.bonus6PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.pubPayMultiplier / 100) +
                  (data.orgById.bonus6PayRate / 100) * (data.orgById.pubPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <TextField
          control={control}
          label="7 hour shift"
          name="patch.bonus7PayRate"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.bonus7PayRate}
          helperText={errors.patch?.bonus7PayRate?.message}
          defaultValue={!!data.orgById.bonus7PayRate ? data.orgById.bonus7PayRate / 100 : 0}
        />
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.bonus7PayRate &&
            '$' +
              roundNumber(data.orgById.basePayRate / 100 + data.orgById.bonus7PayRate / 100, 2) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.afternoonPayMultiplier &&
            !!data.orgById.bonus7PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.afternoonPayMultiplier / 100) +
                  (data.orgById.bonus7PayRate / 100) * (data.orgById.afternoonPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.nightPayMultiplier &&
            !!data.orgById.bonus7PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.nightPayMultiplier / 100) +
                  (data.orgById.bonus7PayRate / 100) * (data.orgById.nightPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.satPayMultiplier &&
            !!data.orgById.bonus7PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.satPayMultiplier / 100) +
                  (data.orgById.bonus7PayRate / 100) * (data.orgById.satPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.sunPayMultiplier &&
            !!data.orgById.bonus7PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.sunPayMultiplier / 100) +
                  (data.orgById.bonus7PayRate / 100) * (data.orgById.sunPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.pubPayMultiplier &&
            !!data.orgById.bonus7PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.pubPayMultiplier / 100) +
                  (data.orgById.bonus7PayRate / 100) * (data.orgById.pubPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <TextField
          control={control}
          label="8 hour shift"
          name="patch.bonus8PayRate"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.bonus8PayRate}
          helperText={errors.patch?.bonus8PayRate?.message}
          defaultValue={!!data.orgById.bonus8PayRate ? data.orgById.bonus8PayRate / 100 : 0}
        />
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.bonus8PayRate &&
            '$' +
              roundNumber(data.orgById.basePayRate / 100 + data.orgById.bonus8PayRate / 100, 2) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.afternoonPayMultiplier &&
            !!data.orgById.bonus8PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.afternoonPayMultiplier / 100) +
                  (data.orgById.bonus8PayRate / 100) * (data.orgById.afternoonPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.nightPayMultiplier &&
            !!data.orgById.bonus8PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.nightPayMultiplier / 100) +
                  (data.orgById.bonus8PayRate / 100) * (data.orgById.nightPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.satPayMultiplier &&
            !!data.orgById.bonus8PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.satPayMultiplier / 100) +
                  (data.orgById.bonus8PayRate / 100) * (data.orgById.satPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.sunPayMultiplier &&
            !!data.orgById.bonus8PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.sunPayMultiplier / 100) +
                  (data.orgById.bonus8PayRate / 100) * (data.orgById.sunPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.pubPayMultiplier &&
            !!data.orgById.bonus8PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.pubPayMultiplier / 100) +
                  (data.orgById.bonus8PayRate / 100) * (data.orgById.pubPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <TextField
          control={control}
          label="9 hour shift"
          name="patch.bonus9PayRate"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.bonus9PayRate}
          helperText={errors.patch?.bonus9PayRate?.message}
          defaultValue={!!data.orgById.bonus9PayRate ? data.orgById.bonus9PayRate / 100 : 0}
        />
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.bonus9PayRate &&
            '$' +
              roundNumber(data.orgById.basePayRate / 100 + data.orgById.bonus9PayRate / 100, 2) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.afternoonPayMultiplier &&
            !!data.orgById.bonus9PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.afternoonPayMultiplier / 100) +
                  (data.orgById.bonus9PayRate / 100) * (data.orgById.afternoonPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.nightPayMultiplier &&
            !!data.orgById.bonus9PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.nightPayMultiplier / 100) +
                  (data.orgById.bonus9PayRate / 100) * (data.orgById.nightPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.satPayMultiplier &&
            !!data.orgById.bonus9PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.satPayMultiplier / 100) +
                  (data.orgById.bonus9PayRate / 100) * (data.orgById.satPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.sunPayMultiplier &&
            !!data.orgById.bonus9PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.sunPayMultiplier / 100) +
                  (data.orgById.bonus9PayRate / 100) * (data.orgById.sunPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.pubPayMultiplier &&
            !!data.orgById.bonus9PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.pubPayMultiplier / 100) +
                  (data.orgById.bonus9PayRate / 100) * (data.orgById.pubPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <TextField
          control={control}
          label="10 hour shift"
          name="patch.bonus10PayRate"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.bonus10PayRate}
          helperText={errors.patch?.bonus10PayRate?.message}
          defaultValue={!!data.orgById.bonus10PayRate ? data.orgById.bonus10PayRate / 100 : 0}
        />
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.bonus10PayRate &&
            '$' +
              roundNumber(data.orgById.basePayRate / 100 + data.orgById.bonus10PayRate / 100, 2) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.afternoonPayMultiplier &&
            !!data.orgById.bonus10PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.afternoonPayMultiplier / 100) +
                  (data.orgById.bonus10PayRate / 100) * (data.orgById.afternoonPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.nightPayMultiplier &&
            !!data.orgById.bonus10PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.nightPayMultiplier / 100) +
                  (data.orgById.bonus10PayRate / 100) * (data.orgById.nightPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.satPayMultiplier &&
            !!data.orgById.bonus10PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.satPayMultiplier / 100) +
                  (data.orgById.bonus10PayRate / 100) * (data.orgById.satPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.sunPayMultiplier &&
            !!data.orgById.bonus10PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.sunPayMultiplier / 100) +
                  (data.orgById.bonus10PayRate / 100) * (data.orgById.sunPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.pubPayMultiplier &&
            !!data.orgById.bonus10PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.pubPayMultiplier / 100) +
                  (data.orgById.bonus10PayRate / 100) * (data.orgById.pubPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <TextField
          control={control}
          label="11 hour shift"
          name="patch.bonus11PayRate"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.bonus11PayRate}
          helperText={errors.patch?.bonus11PayRate?.message}
          defaultValue={!!data.orgById.bonus11PayRate ? data.orgById.bonus11PayRate / 100 : 0}
        />
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.bonus11PayRate &&
            '$' +
              roundNumber(data.orgById.basePayRate / 100 + data.orgById.bonus11PayRate / 100, 2) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.afternoonPayMultiplier &&
            !!data.orgById.bonus11PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.afternoonPayMultiplier / 100) +
                  (data.orgById.bonus11PayRate / 100) * (data.orgById.afternoonPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.nightPayMultiplier &&
            !!data.orgById.bonus11PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.nightPayMultiplier / 100) +
                  (data.orgById.bonus11PayRate / 100) * (data.orgById.nightPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.satPayMultiplier &&
            !!data.orgById.bonus11PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.satPayMultiplier / 100) +
                  (data.orgById.bonus11PayRate / 100) * (data.orgById.satPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.sunPayMultiplier &&
            !!data.orgById.bonus11PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.sunPayMultiplier / 100) +
                  (data.orgById.bonus11PayRate / 100) * (data.orgById.sunPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.pubPayMultiplier &&
            !!data.orgById.bonus11PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.pubPayMultiplier / 100) +
                  (data.orgById.bonus11PayRate / 100) * (data.orgById.pubPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <TextField
          control={control}
          label="12 hour shift"
          name="patch.bonus12PayRate"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1000, step: 0.01 }}
          transform={transformNumberToFixedFloat(2)}
          error={!!errors.patch?.bonus12PayRate}
          helperText={errors.patch?.bonus12PayRate?.message}
          defaultValue={!!data.orgById.bonus12PayRate ? data.orgById.bonus12PayRate / 100 : 0}
        />
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.bonus12PayRate &&
            '$' +
              roundNumber(data.orgById.basePayRate / 100 + data.orgById.bonus12PayRate / 100, 2) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.afternoonPayMultiplier &&
            !!data.orgById.bonus12PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.afternoonPayMultiplier / 100) +
                  (data.orgById.bonus12PayRate / 100) * (data.orgById.afternoonPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.nightPayMultiplier &&
            !!data.orgById.bonus12PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.nightPayMultiplier / 100) +
                  (data.orgById.bonus12PayRate / 100) * (data.orgById.nightPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.satPayMultiplier &&
            !!data.orgById.bonus12PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.satPayMultiplier / 100) +
                  (data.orgById.bonus12PayRate / 100) * (data.orgById.satPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.sunPayMultiplier &&
            !!data.orgById.bonus12PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.sunPayMultiplier / 100) +
                  (data.orgById.bonus12PayRate / 100) * (data.orgById.sunPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
        <Typography>
          {!!data.orgById.basePayRate &&
            !!data.orgById.pubPayMultiplier &&
            !!data.orgById.bonus12PayRate &&
            '$' +
              roundNumber(
                (data.orgById.basePayRate / 100) * (data.orgById.pubPayMultiplier / 100) +
                  (data.orgById.bonus12PayRate / 100) * (data.orgById.pubPayMultiplier / 100),
                2,
              ) +
              ' / hr'}
        </Typography>
      </Box>
      <ButtonAsync
        type="submit"
        variant="contained"
        color="primary"
        loading={loading}
        success={!!data}
      >
        Save
      </ButtonAsync>
    </form>
  );
};

export default SettingsOrganisationPayrollForm;
